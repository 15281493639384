import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Nodify from "../Common/ReactNotification";
import SearchRetain from "../Common/SearchRetain";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import { ParentTNAList, ChildTNAList } from "../Common/PageActionNumber";
import CommonService from '../../services/Common/CommonService';
import routeService from "../../services/Route/RouteService";
import { Redirect } from 'react-router-dom';
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { retrieveBuyerinfo } from "../../actions/buyerinfo"
import TnaService from "../../services/TNA/TNAService";
import { loadTNAList, loadTNADetailList, loadTNADetailActivateList, updateTNA, getNewPOStyleListNotMappedTNA, updateNewPOStyleINTNA } from "../../actions/tna";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import Reactselect from 'react-select';
import moment from "moment";
import StyleService from "../../services/Style/styleService";
import { retrieveStyle } from "../../actions/style";
import { DateRangePicker } from 'react-date-range';


const TNAList = (props) => {
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [TNAPopupID, setTNAPopupID] = useState({ showPopupConfirm: false, Params: {} });
    const [buttonLoader, setButtonLoader] = useState(false);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer / Brand / Season / Supplier");
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [expandedRows, setExpandedRows] = useState([]);
    //const [expandedCss, setExpandedCss] = useState('');
    const [expandState, setExpandState] = useState({});
    const [expandTnaList, setexpandTnaList] = useState({});

    const [tnaList, setTnaList] = useState({});
    const [CompletedPercentage, setCompletedPercentage] = useState(0);
    const [balancePercentage, setbalancePercentage] = useState(0);
    //const [getDeleteID, setDeleteID] = useState({ showPopupDelete: false, Params: {} });
    const [newPOStyleDetailsPopup, setNewPOStyleDetailsPopup] = useState({});
    const [newPOStyleListNotMappedTNA, setNewPOStyleListNotMappedTNA] = useState([]);
    const [POStyleIsAllChecked, setPOStyleIsAllChecked] = useState(false);
    const [ShowFilter, setShowFilter] = useState(true);
    const dispatch = useDispatch();

    const [SkuUnMappedList, setSkuUnMappedList] = useState('');
    const [getBuyerFilterlist, setBuyerFilterList] = useState();
    const [getBrandFilterlist, setBrandFilterlist] = useState();
    const [getSeasonFilterlist, setSeasonFilterList] = useState();
    const [getSupplierFilterlist, setSupplierFilterList] = useState();
    const [getStylenameFilterlist, setStylenameFilterList] = useState();
    const [getrefnoFilterlist, setrefnoFilterList] = useState();
    const [getQuarterFilterlist, setQuaterFilterList] = useState();
    const [getStylecodeFilterlist, setStylecodeFilterList] = useState();
    const [getidpoFilterlist, setidpoFilterList] = useState();
    const [getEndcustomerFilterlist, setEndcustomerFilterList] = useState();


    const [getIdPosearch, setIdPoSearch] = useState();
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getSuppliersearch, setSupplierSearch] = useState("");
    const [getStylenamesearch, setStylenameSearch] = useState("");
    const [getrefnosearch, setrefnoSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getStylecodesearch, setStylecodeSearch] = useState("");
    const [getEndcustomersearch, setEndcustomerSearch] = useState("");

    const [isDisabled, setDisabled] = useState(true);

    //reducer
    var defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 90);
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate,
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const AllRouteInfo = useSelector((state) => state.tna.TNADetailList);
    const { user: currentUser } = useSelector((state) => state.auth);
    const comments = useSelector((state) => state.tna.TNAList);
    let isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const [inputFields, setInputFields] = useState([]);
    const values = [...inputFields];

    const [MainFields, setMainFields] = useState([{
        TNAId: 0,
        CreationStatus: 2,
        TNARouteApplyDetails: [],
        TNADependencyDetails: [],
        TNAAdditionalTaskDetails: [],
        TNAChildInformation: [],
        TNASubChildInformation: [],
        TypTNAChildDependencyDetails: [],
        TNADocs: [],
        TNACommentsInformation: [],
        CreatedBy: currentUser.employeeinformationID,
    }]);

    const [subRow, setSubRow] = useState({
        TNAID: 0,
        BrandID: 0,
        SeasonID: 0,
        SupplierID: 0,
        StyleId: 0,
        idPoNos: 0,
        styleCode: 0,
        EndCustomer: 0,
        QuaterID: 0,
        RowNumber: 0,
    });
    const MainValues = [...MainFields];
    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End


    //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let TNAInfo;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        let info = IsProduction === 1 ? 'TNA' : (IsProduction === 2 ? 'ProductionTNA' : undefined);
        activeMenu = menulist.filter(i => i.menuName === info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (IsProduction === 1) {
                        if (activeMenuSub[index].menuName === 'TNAActivate') {
                            TNAInfo = activeMenuSub[index];
                        }
                    }
                    else if (IsProduction === 2) {
                        if (activeMenuSub[index].menuName === 'ProductionTNAActivate') {
                            TNAInfo = activeMenuSub[index];
                        }
                    }

                }
            }
        }
    }

    //if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "TNA"); }
    // End Enable Menu



    const Isconditoncheck = JSON.parse(localStorage.getItem("IsBackcnd"));
    useEffect(() => {
        if (IsProduction !== 0) {
            ;
            //    if(props.location.state !== undefined && props.location.state !== null && props.location.state.Message === undefined && props.location.state.params.buyerID !== undefined)
            //    {
            if (!!!Isconditoncheck) {

            } else {
                if (
                    props.location.state &&
                    props.location.state.params &&
                    props.location.state.Message === undefined &&
                    props.location.state.params.buyerID !== undefined
                ) {
                    subRow.BrandID = props.location.state.params.brandID;
                    subRow.SeasonID = props.location.state.params.seasonID;
                    subRow.SupplierID = props.location.state.params.supplierId;
                    subRow.StyleId = props.location.state.params.styleId;
                    subRow.RowNumber = props.location.state.params.rowNumber;
                }
            }
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }
            //dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0, supplierId: 0, IsProduction: IsProduction }));
            // handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.StyleId,
            //     subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate, subRow.RowNumber, 1);
            handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.RowNumber, 1, subRow.StyleId,
                subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate);
        }

    }, [IsProduction]);


    useEffect(() => {
        const state1 = props.location.state;
        ;
        if (state1 && state1.message) {
            let Msg;
            let Type = 'success'; // Default type is success, other options are info, warning
            let title = 'Success!';

            switch (state1.message) {
                case 'Edit':
                    Msg = 'TNA updated successfully.';
                    break;
                case 'Add':
                    Msg = 'Route mapped successfully.';
                    break;
                default:
                    Msg = 'An error occurred';
                    Type = 'warning';
                    title = 'Error!';
                    break;
            }

            window.history.replaceState(null, '');
            Nodify(title, Type, Msg);
        }
    }, [dispatch]);


    useEffect(() => {
        if (!isLoadingTNA) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA]);

    // useEffect(() => {
    //     dispatch(loadTNADetailList({ TNAId: dataParams.tnaid }));
    // }, []);

    // const AddTNA = useCallback(async (dataParams, redirect, page, seasonID, rowNumber) => {
    //     // don't send again while we are sending
    //     if (isSending) return
    //     // update state
    //     setIsSending(true)
    //     // send the actual request
    //     await API.sendRequest()
    //     // once the request is sent, update state again
    //     setIsSending(false)
    // }, [isSending]) // update the callback if the state changes



    function AddTNA(dataParams, redirect, page, seasonID, rowNumber) {
        showLoader();
        ;
        dispatch(loadTNADetailActivateList({ TNAId: dataParams.tnaid }))
            .then(data => {
                if (data !== undefined) {

                    //  CheckSkuMapforAllTasks(data, dataParams.creationStatus, dataParams);
                    let taskNameList = checkSubTask(data);

                    if (taskNameList !== '') {
                        // alert(taskNameList)
                        dataParams.Action = 'Edit';
                        dataParams.taskNameList = taskNameList;
                        props.history.push(`/TNAEdit`, { params: dataParams });
                    }
                    // else if (SkuUnMappedList !== '') {
                    //     dataParams.Action = 'Edit';
                    //     dataParams.SkuUnMappedList = SkuUnMappedList;
                    //     props.history.push(`/TNAEdit`, { params: dataParams });
                    // }
                    else {
                        hideLoader();
                        // dispatch(loadTNADetailList({ TNAId: dataParams.tnaid }))
                        setSubRow({ TNAID: dataParams.tnaid, BrandID: dataParams.brandID, SeasonID: seasonID, SupplierID: dataParams.supplierId, RowNumber: rowNumber })
                        let params = { tabPage: "AddTNA" };
                        setTNAPopupID({ showPopupConfirm: true, Params: params });
                    }
                }
            })
    }



    let BuyerListOptions = [] = [];
    const headers = [
        {
            name: "",
            field: "",
            sortable: false,
            widthsize: '15px',

        },
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: '25px',
        },
        {
            name: "Buyer",
            field: "buyerName",
            sortable: true,
            widthsize: '280px',
        },
        {
            name: "Brand",
            field: "brandName",
            sortable: true,
            widthsize: '280px',
        },
        {
            name: "Season",
            field: "season",
            sortable: true,
            widthsize: '280px',
        },
        {
            name: "Supplier",
            field: "supplierName",
            sortable: true,
            widthsize: '280px',
        },
        // {
        //     name: "Task Completion",
        //     field: "taskCompleted",
        //     sortable: false,
        // }
        // ,{
        //     name: "Action",
        //     field: "Action",
        //     sortable: false,
        // }
    ];

    const childHeaders = [
        {
            name: "#",
            field: "",
            sortable: false,
            // widthsize:'120px',
        },
        {
            name: "Created Date",
            sortable: false
        },
        {
            name: "Style Name",
            sortable: false
        },
        {
            name: "PO/ID No",
            sortable: false
        },
        {
            name: "Ref No",
            sortable: false
        },
        {
            name: "Mapped Route",
            sortable: false
        },
        {
            name: "Tasks Completed %",
            sortable: false
        },
        {
            name: "Target Date",
            sortable: false
        },
        {
            name: "Action",
            sortable: false,
            display: activeMenu && activeMenu[0]?.isEdit === 0 &&
                activeMenu[0]?.isDelete === 0 &&
                activeMenu[0]?.isView === 0 ? 'none' : ''
        },
    ];

    const LoadDropdownsBasedOnFilter = (data) => {

        var buyerlist = []; var seasonlist = [];
        var brandlist = []; var supplierList = [];


        data.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
            seasonlist.push({
                label: x.season === "" ? "-" : x.season,
                value: x.seasonID
            })
            brandlist.push({
                label: x.brandName === "" ? "-" : x.brandName,
                value: x.brandID
            })

            supplierList.push({
                label: x.supplierName === "" ? "-" : x.supplierName,
                value: x.supplierId
            })

        })
        setBuyerFilterList([...new Map(buyerlist.map(item =>
            [item['label'], item])).values()]);

        setSupplierFilterList([...new Map(supplierList.map(item =>
            [item['label'], item])).values()])

        setSeasonFilterList([...new Map(seasonlist.map(item =>
            [item['label'], item])).values()])

        setBrandFilterlist([...new Map(brandlist.map(item =>
            [item['label'], item])).values()])
    }
    const commentsData = useMemo(() => {

        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.buyerID === buyerId && o.brandID === brandId && isAllInfo === 0 && isMapped === 1));
        let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped, seasonID, cubeEmployeeIdList }) =>
            o.brandID === brandId && isAllInfo === 0 &&
            (isMapped === 1 && seasonID === o.seasonID && cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString()))));
        if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }
        let subdatas = tnaList;
        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.season.toLowerCase().includes(getSeasonsearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getIdPosearch) {
            const filteredData = subdatas.filter(item =>
                item.idPoNos.toLowerCase().includes(getIdPosearch.label.toLowerCase())
            );
            const uniqueIdentifiers = filteredData.map(item => ({
                brandID: item.brandID,
                seasonID: item.seasonID,
                supplierID: item.supplierId
            }));

            computedComments = computedComments.filter(comment =>
                uniqueIdentifiers.some(({ brandID, seasonID, supplierId }) =>
                    comment.brandID === brandID &&
                    comment.seasonID === seasonID &&
                    comment.supplierID === supplierId
                )
            );

            // Update totalItems after filtering
            setTotalItems(computedComments.length);

        }

        if (getStylenamesearch) {
            const filteredData = subdatas.filter(item =>
                item.styleNames.toLowerCase().includes(getStylenamesearch.label.toLowerCase())
            );

            const uniqueIdentifiers = filteredData.map(item => ({
                brandID: item.brandID,
                seasonID: item.seasonID,
                supplierID: item.supplierId
            }));

            computedComments = computedComments.filter(comment =>
                uniqueIdentifiers.some(({ brandID, seasonID, supplierId }) =>
                    comment.brandID === brandID &&
                    comment.seasonID === seasonID &&
                    comment.supplierID === supplierId
                )
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            const filteredData = subdatas.filter(item =>
                item.quarter.toLowerCase().includes(getQuartersearch.label.toLowerCase())
            );

            const uniqueIdentifiers = filteredData.map(item => ({
                brandID: item.brandID,
                seasonID: item.seasonID,
                supplierID: item.supplierId
            }));

            computedComments = computedComments.filter(comment =>
                uniqueIdentifiers.some(({ brandID, seasonID, supplierId }) =>
                    comment.brandID === brandID &&
                    comment.seasonID === seasonID &&
                    comment.supplierID === supplierId
                )
            );
            setTotalItems(computedComments.length);
        }

        if (getEndcustomersearch) {
            const filteredData = subdatas.filter(item =>
                item.endCustomer.toLowerCase().includes(getEndcustomersearch.label.toLowerCase())
            );

            const uniqueIdentifiers = filteredData.map(item => ({
                brandID: item.brandID,
                seasonID: item.seasonID,
                supplierID: item.supplierId
            }));

            computedComments = computedComments.filter(comment =>
                uniqueIdentifiers.some(({ brandID, seasonID, supplierId }) =>
                    comment.brandID === brandID &&
                    comment.seasonID === seasonID &&
                    comment.supplierID === supplierId
                )
            );
            setTotalItems(computedComments.length);
        }
        if (getStylecodesearch) {
            const filteredData = subdatas.filter(item =>
                item.styleCode.toLowerCase().includes(getStylecodesearch.label.toLowerCase())
            );

            const uniqueIdentifiers = filteredData.map(item => ({
                brandID: item.brandID,
                seasonID: item.seasonID,
                supplierID: item.supplierId
            }));

            computedComments = computedComments.filter(comment =>
                uniqueIdentifiers.some(({ brandID, seasonID, supplierId }) =>
                    comment.brandID === brandID &&
                    comment.seasonID === seasonID &&
                    comment.supplierID === supplierId
                )
            );
            setTotalItems(computedComments.length);
        }
        setTotalItems(computedComments.length);
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }

        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        LoadDropdownsBasedOnFilter(computedComments);
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        getBuyersearch,
        getBrandsearch,
        getSeasonsearch,
        getSuppliersearch,
        getIdPosearch,
        getStylenamesearch,
        getQuartersearch,
        getEndcustomersearch,
        getStylecodesearch,
        comments,
        tnaList, // Ensure tnaList is included
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);


    const FilterClear = (filterName) => {

        if (filterName === 'Buyer') {
            localStorage.removeItem('Search');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            //localStorage.removeItem('IdPo');
            setSeasonSearch('');
            setBrandSearch('');
        }

    }
    const handleDateSelect = (event, isShow) => {


        let getStateDate = [...stateDate];
        getStateDate[0].isShow = isShow;
        setStateDate(getStateDate);
        //let Data = { Searchvalue: getStateDate, Page: 'CreatedDate' }
        // localStorage.setItem('CreatedDate', JSON.stringify(Data));
    }
    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        // let params = { RouteId: 0, IsProduction: IsProduction, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveStyle(params));
        // }
        // handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate, subRow.RowNumber, 1);
        handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.RowNumber, 1, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate);
    }
    // function DateRangechange(item) {
    //     ;
    //     stateDate[0].startDate = item[0].startDate;
    //     stateDate[0].endDate = item[0].endDate
    //     setStateDate(item)
    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //         StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //         EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }
    //     localStorage.setItem('StylePodateStatedate', moment(stateDate[0].startDate).format('MM/DD/YYYY'))
    //     localStorage.setItem('StylePodateenddate', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
    //     handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID,subRow.StyleId,subRow.idPoNos,subRow.styleCode,subRow.EndCustomer,subRow.QuaterID ,StartDate,EndDate,subRow.RowNumber, 1);
    // }
    const DateRangechange = (item) => {
        const updatedStateDate = [
            {
                ...stateDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateDate(updatedStateDate);

        // Format dates for display or storage
        let StartDate = null;
        let EndDate = null;
        if (moment(updatedStateDate[0].startDate).isValid()) {
            StartDate = moment(updatedStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(updatedStateDate[0].endDate).isValid()) {
            EndDate = moment(updatedStateDate[0].endDate).format('MM/DD/YYYY');
        }

        // Store dates in localStorage
        localStorage.setItem('StylePodateStatedate', moment(updatedStateDate[0].startDate).format('MM/DD/YYYY'));
        localStorage.setItem('StylePodateenddate', moment(updatedStateDate[0].endDate).format('MM/DD/YYYY'));

        // Assuming handleExpandRow and subRow are defined elsewhere
        // handleExpandRow(
        //     subRow.BrandID,
        //     subRow.SeasonID,
        //     subRow.SupplierID,
        //     subRow.StyleId,
        //     subRow.idPoNos,
        //     subRow.styleCode,
        //     subRow.EndCustomer,
        //     subRow.QuaterID,
        //     StartDate,
        //     EndDate,
        //     subRow.RowNumber,
        //     1
        // );
        handleExpandRow(
            subRow.BrandID,
            subRow.SeasonID,
            subRow.SupplierID,

            subRow.RowNumber,
            1,
            subRow.StyleId,
            subRow.idPoNos,
            subRow.styleCode,
            subRow.EndCustomer,
            subRow.QuaterID,
            StartDate,
            EndDate
        );
    };
    // const handleChangeOrdStatus = (values) => {

    //     setCreatedbySearch(values)
    //     let Data = { Searchvalue: values, Page: 'CreatedBy' }
    //     localStorage.setItem('CreatedBy', JSON.stringify(Data));
    // }
    useMemo(() => {

        if (commentsData.length > 0) {
            StyleService.LoadBuyer(2)
                .then((response) => {
                    if (response.data) {
                        BuyerListOptions = response.data;
                        var BuyerBrandInfo = BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
                        if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
                            setBuyerFilterList(BuyerBrandInfo.filter(o => commentsData.some(({ buyerID }) => o.id === buyerID)));
                        }
                    }
                })
                .catch(() => { });
        }
    }, [commentsData])

    const updateQuarterFieldState = (buyer, brand, season, supplier) => {
        setDisabled(!buyer && !brand && !season && !supplier);
        if (!buyer && !brand && !season && !supplier) {
            setIdPoSearch('');
            setStylenameSearch('');
            setStylecodeSearch('');
            setEndcustomerSearch('');
            setQuarterSearch('');
        }
    };
    const handleChangeFilter = (values, filterName) => {

        if (filterName === 'Buyer') {
            setBuyerSearch(values);
            let Data = { Searchvalue: values, Page: 'Buyer' };
            localStorage.setItem('Buyer', JSON.stringify(Data));
            FilterClear(filterName);
        } else if (filterName === "Brand") {
            setBrandSearch(values);
            let Data = { Searchvalue: values, Page: 'Brand' };
            localStorage.setItem('Brand', JSON.stringify(Data));
            subRow.BrandID = values !== null ? values.value : 0;
        } else if (filterName === 'Season') {
            setSeasonSearch(values);
            let Data = { Searchvalue: values, Page: 'Season' };
            localStorage.setItem('Season', JSON.stringify(Data));
            subRow.SeasonID = values !== null ? values.value : 0;
        } else if (filterName === "Supplier") {
            setSupplierSearch(values);
            let Data = { Searchvalue: values, Page: 'Supplier' };
            localStorage.setItem('Supplier', JSON.stringify(Data));
            subRow.SupplierID = values !== null ? values.value : 0;
        } else if (filterName === "IdPo") {
            setIdPoSearch(values);
            let Data = { Searchvalue: values, Page: 'IdPo' };
            localStorage.setItem('IdPo', JSON.stringify(Data));
            subRow.idPoNos = values !== null ? values.value : 0;
        } else if (filterName === "Stylename") {
            setStylenameSearch(values);
            let Data = { Searchvalue: values, Page: 'Stylename' };
            localStorage.setItem('Stylename', JSON.stringify(Data));
            subRow.StyleId = values !== null ? values.value : 0;
        } else if (filterName === "Quarter") {
            setQuarterSearch(values);
            let Data = { Searchvalue: values, Page: 'Quarter' };
            localStorage.setItem('Quarter', JSON.stringify(Data));

            if (values !== null) {
                if (values.value === 'Q1') {
                    subRow.QuaterID = 1;
                } else if (values.value === 'Q2') {
                    subRow.QuaterID = 2;
                } else if (values.value === 'Q3') {
                    subRow.QuaterID = 3;
                } else if (values.value === 'Q4') {
                    subRow.QuaterID = 4;
                } else {
                    subRow.QuaterID = 0; // Default or other value handling
                }
            } else {
                subRow.QuaterID = 0;
            }
        }

        else if (filterName === "StyleCode") {
            setStylecodeSearch(values);
            let Data = { Searchvalue: values, Page: 'StyleCode' };
            localStorage.setItem('StyleCode', JSON.stringify(Data));
            subRow.styleCode = values !== null ? values.value : 0;
        } else if (filterName === "Endcustomer") {
            setEndcustomerSearch(values);
            let Data = { Searchvalue: values, Page: 'Endcustomer' };
            localStorage.setItem('Endcustomer', JSON.stringify(Data));
            subRow.EndCustomer = values !== null ? values.value : 0;
        }
        const operation = (subRow.StyleId || subRow.idPoNos || subRow.styleCode || subRow.EndCustomer || subRow.QuaterID) ? 3 : 2;
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        loadOptions(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate, operation);

        updateQuarterFieldState(
            filterName === 'Buyer' ? values : getBuyersearch,
            filterName === 'Brand' ? values : getBrandsearch,
            filterName === 'Season' ? values : getSeasonsearch,
            filterName === 'Supplier' ? values : getSuppliersearch
        );
    };

    const loadOptions = async (brandId, seasonId, supplierId, styleId, idPoNos, styleCode, EndCustomer, QuaterID, PoStartDate, PoEndDate, operationValue) => {

        try {
            const response = await TnaService.LoadTNAList({
                Operation: operationValue,
                brandId,
                seasonId,
                supplierId,
                styleId,
                idPoNos,
                styleCode,
                EndCustomer,
                QuaterID,
                PoStartDate,
                PoEndDate,
                IsProduction: IsProduction
            });

            if (response.data.outputTNAList && response.data.outputTNAList.length > 0) {
                setTnaList(response.data.outputTNAList);

                const idPoOptions = response.data.outputTNAList.map(item => ({
                    label: item.idPoNos,
                    value: item.idPoNos
                }));
                setidpoFilterList(idPoOptions);

                const styleOptions = response.data.outputTNAList.map(item => ({
                    label: item.styleNames,
                    value: item.styleId
                }));
                setStylenameFilterList(styleOptions);

                const quaterOptions = response.data.outputTNAList.map(item => ({
                    label: item.quarter,
                    value: item.quarter
                }));
                setQuaterFilterList(quaterOptions);

                const EndcustomerOptions = response.data.outputTNAList.map(item => ({
                    label: item.endCustomer,
                    value: item.endCustomer
                }));
                setEndcustomerFilterList(EndcustomerOptions);

                const stylecodeOptions = response.data.outputTNAList.map(item => ({
                    label: item.styleCode,
                    value: item.styleCode
                }));
                setStylecodeFilterList(stylecodeOptions);

                const refNoOptions = response.data.outputTNAList
                    .filter(item => item.focusPONO)
                    .map(item => ({
                        label: item.focusPONO,
                        value: item.focusPONO
                    }));
                setrefnoFilterList(refNoOptions);
            } else {
                setTnaList([]);
                setidpoFilterList([]);
                setStylenameFilterList([]);
                setrefnoFilterList([]);
            }
        } catch (error) {
            console.error('Error loading options:', error);
            setTnaList([]);
            setidpoFilterList([]);
            setStylenameFilterList([]);
            setrefnoFilterList([]);
        }
    };

    const resetStyle = () => {


        localStorage.removeItem('Buyer');

        localStorage.removeItem('Season');
        localStorage.removeItem('Brand');
        localStorage.removeItem('Supplier')
        localStorage.removeItem('Idpo')
        localStorage.removeItem('Stylename')
        localStorage.removeItem('StyleCode')
        localStorage.removeItem('Destination')
        localStorage.removeItem('Quarter')
        localStorage.removeItem('EndCustomer')

        setBuyerSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setSupplierSearch('');
        setIdPoSearch('');
        setStylenameSearch('');
        setStylecodeSearch('');
        setEndcustomerSearch('');
        setQuarterSearch('');
        setDisabled(true);

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        // handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate, subRow.RowNumber, 1);
        handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.RowNumber, 1, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, StartDate, EndDate);
        var Data = { Searchvalue: '', Page: '' }
        localStorage.setItem('Search', JSON.stringify(Data));
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    const pageRedirect = (dataParams, suppliername, redirect, page, CommentData) => {
        ;
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {

            props.history.push('/TNARegister', { params: [] });
        } else if (redirect === 'View') {
            dataParams.action = "View";
            dataParams.supplierName = suppliername;
            dataParams.rowNumber = CommentData.rowNumber;
            props.history.push(`/TNAView`, { params: dataParams });
        }
        else if (redirect === 'Edit') {
            dataParams.BuyerName = CommentData.buyerName
            dataParams.supplierName = suppliername;
            dataParams.Action = 'Edit';
            dataParams.rowNumber = CommentData.rowNumber;
            props.history.push(`/TNAEdit`, { params: dataParams });
        }
        else if (redirect === 'Export') {
            dataParams.Action = 'Export';
            dataParams.rowNumber = CommentData.rowNumber;
            props.history.push(`/TNAEdit`, { params: dataParams });
        }
        else if (redirect === 'ParentDelete') {
            dataParams.rowNumber = CommentData.rowNumber;
            setID({ showPopupDelete: true, Params: dataParams });
        }
        else if (redirect === 'NewPOStyle') {
            dispatch(getNewPOStyleListNotMappedTNA({
                BrandID: dataParams.brandID,
                BuyerID: dataParams.buyerID,
                SeasonID: dataParams.seasonID,
                SupplierId: dataParams.supplierId,
                StyleId: dataParams.styleId
            })).then(data => {
                if (data && data.length > 0) {
                    let params = {
                        IsPopup: true,
                        TNAId: dataParams.tnaid,
                        StyleId: dataParams.styleId
                    }
                    setNewPOStyleDetailsPopup(params);
                    setButtonLoader(false);
                    setNewPOStyleListNotMappedTNA(data);
                    setPOStyleIsAllChecked(false);
                } else {
                    let Msg = 'No new purchase order style';
                    let Type = 'warning';
                    let title = 'Warning!';

                    Nodify(title, Type, Msg);
                }
            });
        }
        else {
            dataParams.rowNumber = CommentData.rowNumber;
            dataParams.CommentData = CommentData;
            setID({ showPopupDelete: true, Params: dataParams });

        }

    };



    // const handleExpandRow = async (brandID, seasonID, supplierID, styleId, idPoNos, styleCode, EndCustomer, QuaterID, PoStartDate, PoEndDate, rowNumber, postBack) => {
    const handleExpandRow = async (brandID, seasonID, supplierID, rowNumber, postBack, styleId, idPoNos, styleCode, EndCustomer, QuaterID, PoStartDate, PoEndDate) => {
        //const handleExpandRow = (buyerID = 26,seasonID=5) => {

        setExpandedRows([]);
        styleId = idPoNos = styleCode = EndCustomer = QuaterID = 0;
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        //PoEndDate=PoStartDate=new Date();
        // const StartDate = localStorage.getItem("StylePodateStatedate")
        // const EndDate = localStorage.getItem("StylePodateenddate")
        let CompletedPercentage = 0;
        let balancePercentage = 0;
        //dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0 }));
        showLoader();
        //    {
        if (!!!Isconditoncheck) {

        } else { await dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0, supplierId: 0, styleId: 0, idPoNos: 0, styleCode: 0, EndCustomer: 0, QuaterID: 0, PoStartDate: PoStartDate, PoEndDate: PoEndDate, IsProduction: IsProduction })); }
        await TnaService.LoadTNAList({
            Operation: 2, brandId: brandID, seasonId: seasonID, supplierId: supplierID, styleId: styleId, idPoNos: idPoNos,
            styleCode: styleCode, EndCustomer: EndCustomer, QuaterID: QuaterID, PoStartDate: StartDate, PoEndDate: EndDate, IsProduction: IsProduction
        }).then((responseTnalist) => {
            //TnaService.LoadTNAList(2, buyerID, seasonID).then((response) => {
            ;
            if (responseTnalist.data.outputTNAList.length > 0) {
                // response.data[0].rowNumber = rowNumber;
                ;
                setexpandTnaList(responseTnalist.data.outputTNAList);
                // Expand row Start
                let tnaCount = responseTnalist.data.tnaResultProgressbar.length;
                let addedValue = 0;
                responseTnalist.data.tnaResultProgressbar.forEach((data, i) => {
                    // let val = parseInt(data.taskCompleted);
                    // addedValue = parseInt(addedValue + val);
                    let val = parseInt(data.taskCompleted);
                    addedValue = parseInt(addedValue + val);
                })

                CompletedPercentage = addedValue / tnaCount * 100;
                balancePercentage = 100 - CompletedPercentage;
                setCompletedPercentage(parseFloat(CompletedPercentage).toFixed(2));
                setbalancePercentage(parseFloat(balancePercentage).toFixed(2));
                let currentExpandedRows;
                let isRowExpanded = false;
                // if (postBack !== 1) {
                currentExpandedRows = expandedRows;
                isRowExpanded = currentExpandedRows.includes(rowNumber);
                //setExpandedCss(isRowExpanded ? '' : 'green');


                //  }
                // else {
                //     dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0, supplierId: 0 }));
                // }
                if (postBack === 1) {
                    isRowExpanded = false;
                }
                let obj = {};
                isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
                setExpandState(obj);
                currentExpandedRows = [];
                const newExpandedRows = isRowExpanded ?
                    currentExpandedRows.filter(id => id !== rowNumber) :
                    currentExpandedRows.concat(rowNumber);
                setExpandedRows(newExpandedRows);
                const Isconditoncheck = JSON.parse(localStorage.getItem("IsBackcnd"));
                if (Isconditoncheck) {
                    localStorage.removeItem("IsBackcnd");
                }
                hideLoader();
                // Expand row End
            }
            else {
                dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0, supplierId: 0, styleId: 0, idPoNos: 0, styleCode: 0, EndCustomer: 0, QuaterID: 0, PoStartDate: PoStartDate, PoEndDate: PoEndDate, IsProduction: IsProduction }));
                setExpandState(true);
            }
        });
    }



    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] })

        if (value !== false) {
            // handleExpandRow(value.brandID, value.seasonID, value.supplierId, value.StyleId, value.idPoNos, value.styleCode, value.EndCustomer, value.QuaterID, value.rowNumber, 1);
            handleExpandRow(value.brandID, value.seasonID, value.supplierId, value.rowNumber, 1, value.StyleId, value.idPoNos, value.styleCode, value.EndCustomer, value.QuaterID);
            // optional code
            dispatch(loadTNAList({ Operation: 1, seasonId: 0, brandId: 0, supplierId: 0, IsProduction: IsProduction }));
            //
        }
    }


    function checkSubTask(data) {

        let taskNameList = '';
        if (data.tnaDetailList !== undefined) {
            let routeInfo = data.tnaDetailList;
            let childList = data.tnaChildList;
            routeInfo.map((Info, i) => {
                let IsSingleTask = Info.detailTypeID === 1 ? true : !!!TNAMultiTask.find(element => element.toLowerCase() === Info.taskName.toLowerCase());

                Info.IsChildStatus = false;
                if (IsSingleTask === false) {
                    childList && childList.map(d => d.taskID === Info.taskNameID).map((Child, k) => {
                        if (Child) { Info.IsChildStatus = true; }
                    })
                    if (Info.IsChildStatus === false) {
                        if (taskNameList === "") {
                            taskNameList = Info.taskName;
                        } else {
                            taskNameList = taskNameList + ", " + Info.taskName;
                        }
                    }
                }
            })
        }
        return taskNameList;

    }

    const AddTNAConfirmation = (value) => {

        if (value) {
            if (AllRouteInfo.tnaDetailList !== undefined) {
                let routeInfo = AllRouteInfo.tnaDetailList;
                let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
                let additionalInfo = AllRouteInfo.tnaDetailTaskList;
                let parentChildList = AllRouteInfo.tnaParentChildList;
                let childList = AllRouteInfo.tnaChildList;
                // let Comments = AllRouteInfo.tnaCommentsList;
                // let Attachments = AllRouteInfo.tnaAttachmentsList;
                MainValues[0].TNAId = subRow.TNAID;
                MainValues[0].TNARouteApplyDetails = [];
                MainValues[0].TNADependencyDetails = [];
                MainValues[0].TNAAdditionalTaskDetails = [];
                MainValues[0].TNAChildInformation = [];
                MainValues[0].TNASubChildInformation = [];
                MainValues[0].TypTNAChildDependencyDetails = [];
                MainValues[0].TNADocs = [];
                MainValues[0].TNAComments = [];
                if (AllRouteInfo !== undefined) {
                    showLoader();
                    if (routeInfo !== undefined) {
                        if (routeInfo.length > 0) {
                            //setInputFields([]);

                            routeInfo.map((Info, i) => {
                                let StartDate = convertUTCDateToLocalDate(new Date(Info.planStartDate));
                                //StartDate.setMinutes(StartDate.getMinutes() + 370);
                                let EndDate = convertUTCDateToLocalDate(new Date(Info.endDate));
                                // EndDate.setMinutes(EndDate.getMinutes() + 370);
                                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === Info.taskName.toLowerCase());

                                if (Info.detailTypeID === 4) { // Ex-Factory  (Detail Type)
                                    IsSingleTask = false;
                                }

                                MainValues[0].TNARouteApplyDetails.push({
                                    TNARouteApplyID: Info.tnaRouteApplyID,
                                    TaskType: Info.taskType,
                                    TaskNameID: Info.taskNameID,
                                    Duration: Info.duration.toString(),
                                    PlanStartDate: StartDate,
                                    EndDate: EndDate,
                                    Dependency: Info.dependency,
                                    TaskOwner: Info.taskOwner,
                                    LeaveDays: Info.leaveDays,
                                    RouteApplyStatus: 1,
                                    TaskHolderID: Info.taskHolderID,
                                    TaskHolderIDList: Info.taskHolderIDList,
                                    FollowerIDList1: Info.followerIDList1,
                                    FollowerIDList2: Info.followerIDList2,
                                    IndexName: i.toString(),
                                    AWBStatus: '',
                                    DependencyStartstop: 0,
                                    ManualCloseComment: '',
                                    ManualCloseDate: '',
                                    SingleOrMultiTask: IsSingleTask ? 1 : 2
                                });

                                let TaskName = Info.taskName;
                                let ChildStatus = 0;
                                let isTwoTreeView = false;
                                if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                                    ChildStatus = 1;
                                }
                                else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                                    ChildStatus = 2;

                                } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                                    TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                                    ChildStatus = 3;

                                } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                                    TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                                    || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                    || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                    || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                    || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                                    || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED
                                    || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                                    ChildStatus = 4;
                                }
                                else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                                    ChildStatus = 5;
                                }
                                else if (Info.detailTypeID === 4) { // Ex-Factory  (Detail Type)
                                    ChildStatus = 6;
                                }
                                else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                                    ChildStatus = 7;
                                }
                                else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                    ChildStatus = 8;
                                }


                                if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER ||
                                    TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ||
                                    TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH ||
                                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL
                                    || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                                    isTwoTreeView = true;
                                }

                                if (IsSingleTask === false) {

                                    parentChildList && parentChildList.filter(d => d.taskID === Info.taskNameID).map((Sub, j) => {

                                        if (isTwoTreeView === false) {
                                            MainValues[0].TNAChildInformation.push({
                                                Id: 0,
                                                TaskNameID: Sub.taskID,
                                                ChildId: Sub.mainId,
                                                TaskIndexName: i.toString(),
                                                ChildIndexName: j.toString(),
                                                StrikeOffOrTrimLogoId: (Sub.strikeOffIdOrTrimInfoId === undefined || Sub.strikeOffIdOrTrimInfoId === null) ? 0 : parseInt(Sub.strikeOffIdOrTrimInfoId),
                                                ChildStatus: ChildStatus,
                                            });
                                        }

                                        let childDataList = childList && childList.filter(d => d.taskID === Info.taskNameID && d.mainId === Sub.mainId);
                                        childDataList = childDataList.sort((a, b) => b.date - a.date);

                                        childDataList && childDataList.map((Child, k) => {

                                            MainValues[0].TNASubChildInformation.push({
                                                Id: 0,
                                                TaskNameID: Child.taskID,
                                                SubChildId: Child.subId,
                                                FabricOrStrikeOffOrTrimId: (Child.strikeOffIdOrTrimInfoId === undefined || Child.strikeOffIdOrTrimInfoId === null) ? 0 : parseInt(Child.strikeOffIdOrTrimInfoId),
                                                Duration: Info.duration.toString(),
                                                PlanStartDate: StartDate,
                                                EndDate: EndDate,
                                                RouteApplyStatus: Child.routeApplyStatus,
                                                TaskIndexName: i.toString(),
                                                ChildIndexName: j.toString(),
                                                SubChildIndexName: k.toString(),
                                                SubChildStatus: ChildStatus,
                                                ManualCloseComment: '',
                                                ManualCloseDate: '',
                                                ActualEndDate: Child.actualEndDate,
                                                SKUIdList: Child.skuIdList,
                                                PurchaseOrderSkuID: Child.purchaseOrderSkuID,
                                                StyleStrikeOffGarmentSizeIdList: Child.styleStrikeOffGarmentSizeIdList
                                            });
                                        });
                                    });

                                }

                                if (additionalInfo && additionalInfo.length > 0) {
                                    additionalInfo.filter(x => x.indexName.toString() === i.toString()).map((element, l) => {
                                        MainValues[0].TNAAdditionalTaskDetails.push({
                                            TNAAddTaskID: element.tnaAddTaskID,
                                            AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                            AddtionalTaskName: element.addtionalTaskName,
                                            IsTaskSelected: element.isTaskSelected,
                                            IndexName: element.indexName.toString()
                                        })
                                    });
                                }

                            });

                            if (dependentInfo && dependentInfo.length > 0) {
                                dependentInfo.map((element, i) => {



                                    let DependencyId = 0;
                                    DependencyId = element.tnaDependencyID;
                                    try {
                                        MainValues[0].TNADependencyDetails.push({
                                            TNADependencyID: DependencyId,
                                            DependentTaskName: element.dependentTaskName,
                                            TNATaskName: element.tnaTaskName,
                                            DependencyID: element.dependencyID,
                                            LogDays: element.logDays,
                                            IndexName: element.indexName
                                        })


                                    } catch { }

                                })
                            }
                            dispatch(updateTNA(MainValues[0]))
                                .then(data => {
                                    if (data.outputResult === "1" || data.outputResult === "2") {
                                        dispatch(retrieveBuyerinfo());
                                        setTNAPopupID({ showPopupConfirm: false, Params: [] });
                                        // handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID, subRow.RowNumber, 1);
                                        handleExpandRow(subRow.BrandID, subRow.SeasonID, subRow.SupplierID, subRow.RowNumber, 1, subRow.StyleId, subRow.idPoNos, subRow.styleCode, subRow.EndCustomer, subRow.QuaterID);
                                        hideLoader();
                                    }
                                    else if (data.outputResult === "-1") {
                                        hideLoader();
                                        setTNAPopupID({ showPopupConfirm: false, Params: [] });
                                    }

                                })
                                .catch(e => {
                                    console.log(e);
                                    hideLoader();

                                });

                        }
                    }
                }
            }
        } else {
            setTNAPopupID({ showPopupConfirm: false, Params: [] });
        }

    }

    // New PO Style Details
    const hideNewPOStyleDetailsPopup = () => {
        let params = {
            IsPopup: false,
            TNAId: 0,
            StyleId: 0
        }
        setNewPOStyleDetailsPopup(params);
    }


    const AddNewPOStyleDetailsPopup = () => {

        if (newPOStyleListNotMappedTNA && newPOStyleListNotMappedTNA.filter(d => d.isChecked).length > 0) {
            setButtonLoader(true);

            let POStyleList = newPOStyleListNotMappedTNA.filter(d => d.isChecked === true);
            let styleList = POStyleList && POStyleList.map(x => x.purOrderStyleDetailID).join(",");

            dispatch(updateNewPOStyleINTNA({ TNAId: newPOStyleDetailsPopup.TNAId, POStyleList: styleList, Createdby: currentUser.employeeinformationID })
            ).then(data => {
                let Msg;
                let Type = 'success'; // default, info, warning
                let title = 'Success!';
                if (data.outputResult === "2") {
                    Msg = 'Purchase order style added successfully';
                } else {
                    Msg = 'Error occured';
                    Type = 'warning';
                    title = 'Error!';
                }
                Nodify(title, Type, Msg);
            });

            let params = {
                IsPopup: false,
                TNAId: 0,
                StyleId: 0
            }
            setNewPOStyleDetailsPopup(params);
            setPOStyleIsAllChecked(false);

        } else {
            let Msg = 'Select any one PO style';
            let Type = 'warning';
            let title = 'Warning!';

            Nodify(title, Type, Msg);
        }


    }

    const handlePOStyleSelect = (e, index, selectAll) => {
        let isChecked = false;
        let IsAllChecked = false;
        const newPOStyleList = [...newPOStyleListNotMappedTNA];
        if (e.target.checked) {
            isChecked = true;
        }

        if (selectAll) {
            newPOStyleList && newPOStyleList.map((item, SubIndex) => {
                newPOStyleList[SubIndex].isChecked = isChecked;
            });
            IsAllChecked = isChecked;
        } else {
            newPOStyleList[index].isChecked = isChecked;
            if (isChecked) {
                let styleList = newPOStyleList.filter(d => d.isChecked === false);
                if (styleList && styleList.length > 0) {
                    IsAllChecked = false;
                } else {
                    IsAllChecked = true;
                }

            } else {
                IsAllChecked = false;
            }
        }
        setPOStyleIsAllChecked(IsAllChecked);
        setNewPOStyleListNotMappedTNA(newPOStyleList);


    }

    // Commom  convert UTCDateToLocalDate
    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    function CheckSkuMapforAllTasks(AllRouteInfo, isLastEdit, dataParams) {

        let routeInfo = AllRouteInfo.tnaDetailList;
        let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
        let additionalInfo = AllRouteInfo.tnaDetailTaskList;
        let parentChildList = AllRouteInfo.tnaParentChildList;
        let childList = AllRouteInfo.tnaChildList;

        let routeInformation = [];
        let ChildIndex = 1;

        if (AllRouteInfo) {

            if (routeInfo) {
                if (routeInfo.length > 0) {
                    //setInputFields([]);

                    routeInfo.map((route, i) => {

                        let employee1 = "";
                        let employee2 = "";
                        let employee3 = "";

                        if (route.taskHolderName && route.taskHolderName) {
                            let TaskHolderName = route.taskHolderName.split('-');
                            if (TaskHolderName[0] !== undefined) {
                                employee1 = TaskHolderName[0].trim();

                                if (TaskHolderName[1] !== undefined) {
                                    let FollowerName = TaskHolderName[1].split(',');
                                    if (FollowerName[0] !== undefined) {
                                        employee2 = FollowerName[0].trim();

                                        if (FollowerName[1] !== undefined) {
                                            employee3 = FollowerName[1].trim();
                                        } else {
                                            employee3 = "";
                                        }
                                    } else {
                                        employee2 = "";
                                        employee3 = "";
                                    }

                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }
                            }
                        }


                        let StartDate = new Date(route.planStartDate);
                        // StartDate.setMinutes(StartDate.getMinutes() + 370);
                        let EndDate = new Date(route.endDate);
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                        let ImageUrl = "";

                        if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                            ImageUrl = 'Images/Style/Strikeoff/';
                        } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                            ImageUrl = 'Images/Style/Trim/';
                        }


                        let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
                        if (route.detailTypeID === 4) { // Ex-Factory  (Detail Type)
                            IsSingleTask = false;
                        }
                        routeInformation.push({
                            TNARouteApplyID: route.tnaRouteApplyID,
                            TaskFieldEnable: 0,
                            TaskType: route.taskType,
                            TaskTypeTxt: route.taskTypeName,
                            TaskID: route.taskNameID,
                            strTaskName: route.taskName,
                            EndDate: EndDate,
                            StartDate: StartDate,
                            PreStartDate: StartDate,
                            PreEndDate: EndDate,
                            Duration: route.duration,
                            intDependency: route.dependencyStartstop,
                            hasAllDependencyStart: 0,
                            hasAllDependencyStartPopup: false,
                            arrDependency: [],
                            arrComment: [],
                            arrAttachment: [],
                            arrManual: [],
                            ManualComments: route.manualCloseComment,
                            //ManualCloseDate: route.ManualCloseDate,
                            CommentsCount: route.taskCommentsCount,
                            AttachmentCount: route.taskDocumentsCount,
                            TaskHolderList: '',
                            TaskHolderOrFollower: route.taskHolderID,
                            TaskHolderOrFollowerTxt: route.taskHolderName && route.taskHolderName.replace(" - -", ""),
                            TaskHolderOwnerTxtList: route.taskOwnerNames,
                            TaskHolderIDList: route.taskHolderIDList,
                            FollowerIDList1: route.followerIDList1,
                            FollowerIDList2: route.followerIDList2,
                            TaskHolderBuyer: route.taskHolderIDList,
                            FollowerCube: route.followerIDList1,
                            FollowerSupplier: route.followerIDList2,
                            TaskHolderOwnerIdList: route.taskOwner,
                            titleEmployee1: employee1,
                            titleEmployee2: employee2,
                            titleEmployee3: employee3,
                            LeaveDays: route.leaveDays,
                            IsTHFirstTime: 0,
                            LeaveDaysTxt: route.leaveDayName,
                            AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                            IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                            DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                            IndexName: route.indexName,
                            //PreferenceId: route.preferenceId,
                            IsPopupOpen: 0,
                            IsDependencyOpen: 0,
                            IsTHLeaveDaysOpen: 0,
                            IsComment: 0,
                            IsAttachment: 0,
                            IsSelectAll: route.isSelectAll,
                            FollowupDetails: [],
                            SelectedDependency: route.dependency,
                            arrTaskHolderBuyer: [],
                            arrFollowerCube: [],
                            arrFollowerSupplier: [],
                            TaskHolderBuyerTxt: '',
                            FollowerCubeTxt: '',
                            FollowerSupplierTxt: '',
                            IsSingleTask: IsSingleTask,
                            IsNewRow: true,
                            StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
                            AWBStatus: route.awbStatus,
                            ActualEndDate: route.actualEndDate,
                            ManualCloseDate: route.actualEndDate === null || route.actualEndDate === "" ? '' : new Date(route.actualEndDate),
                            SubTaskList: [],
                            EndDateChange: 0,
                            IDuration: route.duration,
                            IEndDate: EndDate,
                            IStartDate: StartDate,
                            RevicePopup: 0,
                            IsReviced: route.isReviced,
                            DependencyDate: route.dependencyDate,
                            DependencyComment: route.dependencyComment,
                            DetailTypeID: route.detailTypeID,
                            IsExpanded: false,
                            ReviceAdded: 0,
                            RescheduleAdded: 0,
                            IsNotMatchSku: false
                            // IsRescheduled: route.IsRescheduled
                        });


                        if (IsSingleTask === false) {
                            parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
                                routeInformation[i].SubTaskList.push({
                                    ParentId: element.parentId,
                                    TaskID: element.taskID,
                                    MainId: element.mainId,
                                    Code: element.code,
                                    Color: element.color,
                                    ColorOrName: element.colorOrName,
                                    ImagePath: element.imagePath,
                                    ImageUrl: ImageUrl,
                                    StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                                    IsExpanded: false,
                                    hasAutoOpen: false,
                                    ChildTaskList: []
                                })

                                childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId).map((child, c) => {
                                    let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
                                    // childStartDate.setMinutes(childStartDate.getMinutes() + 370);
                                    let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
                                    // childEndDate.setMinutes(childEndDate.getMinutes() + 370);

                                    routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                        ChildId: child.childId,
                                        SubId: child.subId,
                                        Name: child.name,
                                        // TaskName: child.taskName,
                                        TaskName: route.taskName,
                                        TaskID: child.taskID,
                                        MainId: child.mainId,
                                        Duration: isLastEdit ? child.duration : route.duration,
                                        EndDate: childEndDate,
                                        StartDate: childStartDate,
                                        PreStartDate: childStartDate,
                                        PreEndDate: childEndDate,
                                        // EndDate: isLastEdit ? moment(child.endDate).toDate() : moment(child.endDate).toDate(),
                                        // StartDate: isLastEdit ? moment(child.planStartDate).toDate() : moment(child.planStartDate).toDate(),
                                        // arrDependency: [],
                                        TaskHolderList: '',
                                        TaskHolderOrFollower: route.taskHolderID,
                                        TaskHolderOrFollowerTxt: route.taskHolderName && route.taskHolderName.replace(" - -", ""),
                                        TaskHolderOwnerTxtList: route.taskOwnerNames,
                                        TaskHolderIDList: route.taskHolderIDList,
                                        FollowerIDList1: route.followerIDList1,
                                        FollowerIDList2: route.followerIDList2,
                                        TaskHolderBuyer: route.taskHolderIDList,
                                        FollowerCube: route.followerIDList1,
                                        FollowerSupplier: route.followerIDList2,
                                        arrTaskHolderBuyer: [],
                                        arrFollowerCube: [],
                                        arrFollowerSupplier: [],
                                        TaskHolderBuyerTxt: '',
                                        FollowerCubeTxt: '',
                                        FollowerSupplierTxt: '',
                                        TaskHolderOwnerIdList: '',
                                        titleEmployee1: employee1,
                                        titleEmployee2: employee2,
                                        titleEmployee3: employee3,
                                        IsTHFirstTime: 0,
                                        LeaveDays: '',
                                        LeaveDaysTxt: '',
                                        StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                        AWBStatus: '',
                                        IsAddChildTaskOpen: 0,
                                        StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                        // ParentIndex: p,
                                        // ChildIndex: c,
                                        drpChildTaskList: [],
                                        IsManualClose: 0,
                                        IsExFactoryForm: 0,
                                        ManualCloseComment: child.manualCloseComment,
                                        //ManualCloseDate: child.manualCloseDate,
                                        IsShowSubmitButton: child.isShowSubmitButton,
                                        IsShowApprovalButton: child.isShowApprovalButton,
                                        ManualCloseTaskwise: child.ManualClose,
                                        ChildActualEndDate: child.actualEndDate,
                                        ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                        EndDateChange: 0,
                                        ManualApprovalType: 0,
                                        ExistingApprovalType: 0,
                                        RevicePopup: 0,
                                        IsReviced: child.isReviced,
                                        intDependency: child.dependencyStartstop,
                                        hasAllDependencyStart: 0,
                                        hasAllDependencyStartPopup: false,
                                        DependencyDate: child.dependencyDate,
                                        DependencyComment: child.dependencyComment,
                                        MultiDependencyDate: "",
                                        MultiDependencyComment: "",
                                        IsRescheduled: child.isRescheduled,
                                        IsDependencyStartPopup: false,
                                        IDuration: isLastEdit ? child.duration : route.duration,
                                        IEndDate: childEndDate,
                                        IStartDate: childStartDate,

                                        SwatchStatus: child.swatchStatus,
                                        SKUIdList: child.skuIdList,
                                        PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                        StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                        hasAutoOpen: false,
                                        IsChildDepandencyOpen: 0,
                                        ChildIndex: ChildIndex,
                                        arrDependency: [
                                        ],
                                        DependencyCount: child.depandencyCount,
                                        SelectedDependency: child.selectedDependency,
                                        AssignedDepandency: child.assignedDepandency,
                                        AssignedStartDate: '',
                                        AssignedEndDate: '',
                                        hasSKUNotMatch: false


                                        // IsRescheduled: route.IsRescheduled

                                    });
                                    ChildIndex++;
                                });
                            });
                        }
                    });
                }
                setInputFields(routeInformation);
                if (routeInformation.length > 0) {
                    commonCalculation(routeInformation, dataParams)
                }

            }

        }


    }




    //Main Calculation for Sku Map check

    function commonCalculation(Data, dataParams) {

        Data.map((Item, i) => {
            // Parent Calculation call
            commonCalculationParentAndChild(Item, Item, i, 0, 0, true, false, false, i, Data, dataParams);
            Item.SubTaskList && Item.SubTaskList.map((Item2, p) => {
                Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                    // Child Calculation call
                    commonCalculationParentAndChild(Item, Item3, i, p, c, false, false, false, i, Data, dataParams);
                });
            });
        });


    }

    // useMemo(
    //     () => commonCalculation(getCALCParams.isDuration, getCALCParams.isEndDate, getCALCParams.index),
    //     [getCALCParams.isDuration, getCALCParams.isEndDate, getCALCParams.index]
    // );

    function commonCalculationParentAndChild(parentItem, commonItem, i, p, c, isParent, isDuration, isEndDate, index, Data, dataParams) {

        let dependencyCount = parentItem.DependencyCount;
        let parentdependencyCount = parentItem.DependencyCount;
        let DetailTypeID = parentItem.DetailTypeID;
        let parentDependency = parentItem.SelectedDependency;
        let SelectedDependency = parentItem.SelectedDependency;
        let UnMappedList = '';

        let values = Data;
        if (!isParent) {
            SelectedDependency = commonItem.SelectedDependency;
            dependencyCount = commonItem.SelectedDependency;
        }


        let Duration = (commonItem.Duration && commonItem.Duration) ? parseInt(commonItem.Duration) > 0 ? parseInt(commonItem.Duration) - 1 : 0 : 0;
        // try {


        //Dependency Calculation

        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let total = 0;
        if (!isParent) { //------** Child Only ***------------------
            //------------------ *** No Dependency (SKU base map Calculation) ***------------

            if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------
                let SkuId = commonItem.SubId;

                if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                    //---------- **** Single  Dependent Calculation ******----------------
                    //Get Parent Dependent Name and Index
                    let parentSplitVal = parentDependency.split('-');
                    let parentIndexVal = parentSplitVal[0].trim();
                    let parentDependencyVal = parentSplitVal[1].trim();

                    // Dependent base compare to  DetailTypeID
                    let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                    if (parentDetailTypeID === 1) { // DetailType => Common

                        //  commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null)
                    } else if (parentDetailTypeID === 2) { // DetailType => Sku

                        let preChildDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];
                        if (preChildDetail) {
                            // commonItem.hasSKUNotMatch = false;
                            //   commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            if (SkuUnMappedList === '') {
                                UnMappedList = UnMappedList + parentItem.strTaskName;
                            }
                            else {
                                if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                    UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                }
                            }

                        }


                    } else if (parentDetailTypeID === 3) { // DetailType => Item

                        let preChildDetailList = [];
                        values[parentIndexVal - 1].SubTaskList.map((item) => {
                            let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                            TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                        });
                        //Order by descending ( -1 : 1)
                        let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                        if (preChildDetail) {
                            // commonItem.hasSKUNotMatch = false;
                            //  commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            if (SkuUnMappedList === '') {
                                UnMappedList = UnMappedList + parentItem.strTaskName;
                            }
                            else {
                                if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                    UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                }
                            }
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }


                    } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
                        let preChildDetailList = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                        //Order by ASC a - b
                        let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                        if (preChildDetail) {
                            // commonItem.hasSKUNotMatch = false;
                            // commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            if (SkuUnMappedList === '') {
                                UnMappedList = UnMappedList + parentItem.strTaskName;
                            }
                            else {
                                if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                    UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                }
                            }
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }
                    }

                } else if (parentdependencyCount > 1) {
                    //---------- **** Multiple  Dependent Calculation ******----------------
                    let oldDuration = commonItem.Duration;
                    let dependencyMult = [];
                    let splitDepList = parentDependency.split(',');
                    splitDepList.map((depItem, d) => {
                        addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                    });

                    if (dependencyMult.length > 0) {
                        const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                        let rowSorted = sorted[0];
                        dbEndDate = sorted[0].Date;
                        let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                        if (lastRow.length > 0) {
                            dbStartDate = lastRow[0].Date;
                        } else {
                            dbStartDate = dbEndDate;
                        }

                        // Get Dependency and Index
                        let splitVal = lastRow[0].depItem.split('-');
                        let indexVal = splitVal[0].trim();
                        let dependencyVal = splitVal[1].trim();
                        commonItem.StartDate = dbStartDate;
                        commonItem.EndDate = dbEndDate;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        // commonItem.hasSKUNotMatch = false;
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        if (SkuUnMappedList === '') {
                            UnMappedList = UnMappedList + parentItem.strTaskName;
                        }
                        else {
                            if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                            }
                        }
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }


                }


            } else if (DetailTypeID === 3) { // DetailType => Item-------------------------------

                let SkuId = commonItem.SubId;
                if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                    //---------- **** Single  Dependent Calculation ******----------------
                    //Get Parent Dependent Name and Index
                    let parentSplitVal = parentDependency.split('-');
                    let parentIndexVal = parentSplitVal[0].trim();
                    let parentDependencyVal = parentSplitVal[1].trim();

                    // Dependent base compare to  DetailTypeID
                    let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                    if (parentDetailTypeID === 1) { // DetailType => Common

                        // commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null)
                    } else if (parentDetailTypeID === 2) { // DetailType => Sku
                        let preChildDetailList = [];
                        let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                        arySkuIdList.map((item) => {
                            let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                            preSkuDetail && preChildDetailList.push(preSkuDetail);
                        });
                        //Order by descending ( -1 : 1)
                        let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                        if (preChildDetail) {
                            // commonItem.hasSKUNotMatch = false;
                            //  commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            if (SkuUnMappedList === '') {
                                UnMappedList = UnMappedList + parentItem.strTaskName;
                            }
                            else {
                                if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                    UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                }
                            }
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (parentDetailTypeID === 3) { // DetailType => Item
                        let TaskName = values[index].strTaskName;
                        let preTaskName = values[parentIndexVal - 1].strTaskName;
                        let hasSameItem = false;

                        if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                            && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                            hasSameItem = true;

                        } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                            && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                            hasSameItem = true;

                        } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                            && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                            hasSameItem = true;
                        }

                        if (hasSameItem) { //Same Item Match
                            let SubId = commonItem.SubId;
                            let MainId = commonItem.MainId;
                            let preChildTaskList = values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                            let preChildDetail = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId)[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                //   commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                if (SkuUnMappedList === '') {
                                    UnMappedList = UnMappedList + parentItem.strTaskName;
                                }
                                else {
                                    if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                        UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                    }
                                }
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            }
                        } else { //Other Item Match Sku
                            let preChildDetailList = [];
                            let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');

                            arySkuIdList && arySkuIdList.map((SkuId) => {
                                values[parentIndexVal - 1].SubTaskList.map((item) => {
                                    let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                                    TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                                    // if (TNASkuIdList && TNASkuIdList.length > 0) {

                                    //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                                    // }

                                });
                                //Order by descending ( -1 : 1)
                                let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                                if (preChildDetail) {
                                    // commonItem.hasSKUNotMatch = false;
                                    //   commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                                } else {
                                    commonItem.hasSKUNotMatch = true;
                                    if (SkuUnMappedList === '') {
                                        UnMappedList = UnMappedList + parentItem.strTaskName;
                                    }
                                    else {
                                        if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                            UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                        }
                                    }
                                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                }
                            });

                        }

                    } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                        let preChildDetailList = [];
                        let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                        arySkuIdList.map((item) => {
                            let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                            preSkuDetail && preChildDetailList.push(preSkuDetail);
                        });
                        //Order by ASC a - b
                        let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                        if (preChildDetail) {
                            // commonItem.hasSKUNotMatch = false;
                            //  commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            if (SkuUnMappedList === '') {
                                UnMappedList = UnMappedList + parentItem.strTaskName;
                            }
                            else {
                                if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                    UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                                }
                            }
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }
                    }


                } else if (parentdependencyCount > 1) {
                    //---------- **** Multiple  Dependent Calculation ******----------------
                    let oldDuration = commonItem.Duration;
                    let dependencyMult = [];
                    let splitDepList = parentDependency.split(',');
                    splitDepList.map((depItem, d) => {
                        addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                    });

                    if (dependencyMult.length > 0) {
                        const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                        let rowSorted = sorted[0];
                        dbEndDate = sorted[0].Date;
                        let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                        if (lastRow.length > 0) {
                            dbStartDate = lastRow[0].Date;
                        } else {
                            dbStartDate = dbEndDate;
                        }

                        // Get Dependency and Index
                        let splitVal = lastRow[0].depItem.split('-');
                        let indexVal = splitVal[0].trim();
                        let dependencyVal = splitVal[1].trim();
                        commonItem.StartDate = dbStartDate;
                        commonItem.EndDate = dbEndDate;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        // commonItem.hasSKUNotMatch = false;
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        if (SkuUnMappedList === '') {
                            UnMappedList = UnMappedList + parentItem.strTaskName;
                        }
                        else {
                            if (!SkuUnMappedList.includes(parentItem.strTaskName.toString())) {
                                UnMappedList = SkuUnMappedList + parentItem.strTaskName;
                            }
                        }
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }



                }


            } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------

            }

            // setSkuUnMappedList(UnMappedList);

        }

        if (UnMappedList !== '') {
            dataParams.Action = 'Edit';
            dataParams.SkuUnMappedList = UnMappedList;
            props.history.push(`/TNAEdit`, { params: dataParams });
        }

        // } catch (e) {
        //     console.log(e);
        // }
    }




    function commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, dependencyVal, parentIndexVal, parentDetailTypeID, preChildDetail) {

        let commonPreStartDate = new Date();
        let commonPreEndDate = new Date();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let titleEmployee1 = parentItem.titleEmployee1;

        if (parentDetailTypeID === 1) { // DetailType => Common
            commonPreStartDate = values[parentIndexVal - 1].StartDate;
            commonPreEndDate = values[parentIndexVal - 1].EndDate;
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;

        } else if (parentDetailTypeID === 3) { // DetailType => Item
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        }

        try {

            if (dependencyVal === "SS") {
                //---- Start-Start ----

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "SE") {
                //---- Start-End ----

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "ES") {
                //---- End-Start ----

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "EE") {
                //---- End-End ----


                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });
            }
        }

        catch (e) {
            console.log(e);
        }

    }

    //SKU Base Multiple Dependency Date add
    function addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId) {

        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let hasSKUNotMatch = false;


        // Dependent base compare to  DetailTypeID
        let parentDetailTypeID = values[indexVal - 1].DetailTypeID;
        if (parentDetailTypeID === 1) { // DetailType => Common
            commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, dependencyVal, indexVal, parentDetailTypeID, null)
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            let preChildDetail = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail)
            }
            else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        } else if (parentDetailTypeID === 3) { // DetailType => Item
            let preChildDetailList = [];
            values[indexVal - 1].SubTaskList.map((item) => {
                let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                TNASkuIdList && preChildDetailList.push(TNASkuIdList);
            });
            //Order by descending ( -1 : 1)
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail)
            } else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            let preChildDetailList = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
            //Order by ASC a - b
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail)
            }
            else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }


    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">TNA List</span>
                            </div>
                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    {/* <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }

                                            placeholder={getPlaceholder} props={props} />
                                    </div> */}
                                    <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                        {/* <div className="table-toolbar pull-left"> */}
                                        {/* <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} /> &nbsp;&nbsp; */}
                                        {
                                            ShowFilter === true ?
                                                <div className="row">
                                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                                        <div className="widget">
                                                            <div className="widget-body no-padding">
                                                                <div className="widget-main ">
                                                                    <div className="panel-group accordion" id="accordion">
                                                                        <div className="panel panel-default">
                                                                            <div className="panel-heading ">
                                                                                <h4 className="panel-title">
                                                                                    <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                                    </a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseOne" className="panel-collapse collapse">
                                                                                <div className="panel-body border-red">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            {/* //<h4 className="primarydata">Primary data</h4> */}
                                                                                            {/* <fieldset className="filter-options">
                                                                                                <legend >Primary Filter</legend> */}

                                                                                            <div className="col-md-2">
                                                                                                <label>Buyer</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="Buyer"
                                                                                                        options={getBuyerFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Buyer')}
                                                                                                        value={getBuyersearch}
                                                                                                        placeholder='Select Buyer'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className="col-md-2" >
                                                                                                <label>Brand</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" name="Brand"
                                                                                                        options={getBrandFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Brand')}
                                                                                                        value={getBrandsearch}
                                                                                                        placeholder='Select Brand'

                                                                                                    ></Reactselect>
                                                                                                </span>

                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <label>Season</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="Season"
                                                                                                        options={getSeasonFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Season')}
                                                                                                        value={getSeasonsearch}
                                                                                                        placeholder='Select Season'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 ">
                                                                                                <label>Supplier Name</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="Supplier"
                                                                                                        options={getSupplierFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Supplier')}
                                                                                                        value={getSuppliersearch}
                                                                                                        placeholder='Select Supplier Name'
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            {/* </fieldset> */}

                                                                                            {/* <fieldset className="filter-options">
                                                                                        <legend >Secondary Filter</legend> */}
                                                                                            <div className="col-md-2">
                                                                                                <label>TNA Created Date</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <input

                                                                                                        value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                        //onBlur={event => handleDateBlur()}
                                                                                                        //onChange={e => handleChange(e, 'CreatedDate')}
                                                                                                        type="text"
                                                                                                        className={'form-control styledatapicker'}
                                                                                                        placeholder="Select Date"

                                                                                                    />
                                                                                                    <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                                </span>


                                                                                                <span className='input-icon icon-right mb-5'>
                                                                                                    <br />
                                                                                                    {stateDate[0].isShow && (
                                                                                                        <DateRangePicker
                                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                                            showSelectionPreview={true}
                                                                                                            moveRangeOnFirstSelection={false}
                                                                                                            months={2}
                                                                                                            ranges={stateDate.map(date => ({
                                                                                                                ...date,
                                                                                                                startDate: date.startDate instanceof Date ? date.startDate : new Date(date.startDate),
                                                                                                                endDate: date.endDate instanceof Date ? date.endDate : new Date(date.endDate)
                                                                                                            }))}
                                                                                                            showDateDisplay={false}
                                                                                                            direction="vertical"
                                                                                                            className={'StyleDateRangePicker'}
                                                                                                        />
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className="col-md-2">
                                                                                                <label>Quarter</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single" name="quarter"
                                                                                                        options={getQuarterFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Quarter')}
                                                                                                        value={getQuartersearch}
                                                                                                        placeholder='Select Quarter'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="col-md-2">
                                                                                                <label>PO/ID No.</label>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <Reactselect className="basic-single" name="IdPo"
                                                                                                        options={getidpoFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'IdPo')}
                                                                                                        value={getIdPosearch}
                                                                                                        placeholder='Select PO/ID No'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2">
                                                                                                <label>Ref No.</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="Ref"
                                                                                                        options={getrefnoFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Ref')}
                                                                                                        value={getrefnosearch}
                                                                                                        placeholder='Select Ref No'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 ">
                                                                                                <label>Style Name</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="Stylename"
                                                                                                        options={getStylenameFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Stylename')}
                                                                                                        value={getStylenamesearch}
                                                                                                        placeholder='Select Style Name'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-2 ">
                                                                                                <label>Style Code</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="StyleCode"
                                                                                                        options={getStylecodeFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'StyleCode')}
                                                                                                        value={getStylecodesearch}
                                                                                                        placeholder='Select Style Code'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>

                                                                                            <div className="col-md-2 " >
                                                                                                <label>End Customer</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="Endcustomer"
                                                                                                        options={getEndcustomerFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'Endcustomer')}
                                                                                                        value={getEndcustomersearch}
                                                                                                        placeholder='Select End Customer'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>


                                                                                            <div className="col-md-2 " >
                                                                                                <label>TNA Work Status</label>
                                                                                                <span className="input-icon icon-right">

                                                                                                    <Reactselect className="basic-single"
                                                                                                        name="TNA Work Status"
                                                                                                        //options={getdestinationFilterlist}
                                                                                                        isClearable={true}
                                                                                                        onChange={e => handleChangeFilter(e, 'TNA Work Status')}
                                                                                                        //value={getdestinationsearch}
                                                                                                        placeholder='Select TNA Work Status'
                                                                                                        isDisabled={isDisabled}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* </fieldset> */}
                                                                                    &nbsp;

                                                                                    <div className="row">
                                                                                        <div className="col-md-12">

                                                                                            <div className="col-md-2">
                                                                                                <span className="col-md-1 input-icon  icon-right ">
                                                                                                    <button type="button" className="btn btn-danger"
                                                                                                        style={{ marginLeft: '-15px' }}
                                                                                                        title="Reset"
                                                                                                        onClick={() => resetStyle()}
                                                                                                    >
                                                                                                        <i className="fa fa-close"> Reset</i>
                                                                                                    </button>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    &nbsp;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        <div />
                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0]?.isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', '', 'Add', getPlaceholder, 0)} title="Add TNA">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {


                                                commentsData.map((comment, index1) => (
                                                    <Fragment key={index1}>
                                                        <tr>
                                                            <td style={{ width: '15px' }}>
                                                                <i
                                                                    className={
                                                                        expandState[comment.rowNumber] ?
                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand greenHighlight' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                    }
                                                                    // onClick={event => handleExpandRow(comment.brandID, comment.seasonID, comment.supplierId, comment.styleId, comment.idPoNos, comment.styleCode, comment.EndCustomer, comment.QuaterID, comment.PoStartDate, comment.PoEndDate, comment.rowNumber, 0)}
                                                                    onClick={event => handleExpandRow(comment.brandID, comment.seasonID, comment.supplierId, comment.rowNumber, 0, comment.styleId, comment.idPoNos, comment.styleCode, comment.EndCustomer, comment.QuaterID, comment.PoStartDate, comment.PoEndDate)}
                                                                ></i>

                                                            </td>
                                                            <td style={{ width: '25px' }}>
                                                                <span className={expandState[comment.rowNumber] ? 'greenHighlight' : ''}>
                                                                    {
                                                                        index1 + 1
                                                                    }
                                                                </span>

                                                            </td>
                                                            <td style={{ width: '280px' }}>


                                                                <span className={expandState[comment.rowNumber] ? 'BuyerPaddingLeft greenHighlight' : 'BuyerPaddingLeft'}>
                                                                    {comment.buyerName}
                                                                </span>
                                                            </td>
                                                            <td style={{ width: '280px' }}>
                                                                <span className={expandState[comment.rowNumber] ? 'BuyerPaddingLeft greenHighlight' : 'BuyerPaddingLeft'}>
                                                                    {
                                                                        comment.brandName
                                                                    }
                                                                </span>

                                                            </td>
                                                            <td style={{ width: '280px' }}>
                                                                <span className={expandState[comment.rowNumber] ? 'BuyerPaddingLeft greenHighlight' : 'BuyerPaddingLeft'}>
                                                                    {
                                                                        comment.season
                                                                    }
                                                                </span>

                                                            </td>
                                                            <td style={{ width: '280px' }}>
                                                                <span className={expandState[comment.rowNumber] ? 'BuyerPaddingLeft greenHighlight' : 'BuyerPaddingLeft'}>
                                                                    {
                                                                        comment.supplierName
                                                                    }
                                                                </span>

                                                            </td>

                                                            {/* <td>
                                                                <span className="BuyerPaddingLeft">
                                                                    {
                                                                        comment.taskCompleted
                                                                    }
                                                                </span>
                                                            </td> */}
                                                            {/* <td>

                                                                <span title='Delete TNA' onClick={() => pageRedirect(comment, 'ParentDelete', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td> */}

                                                        </tr>
                                                        <>
                                                            {
                                                                expandedRows.includes(comment.rowNumber) ?


                                                                    <tr>
                                                                        <td colSpan='6'>
                                                                            {/* <ProgressBar>
                                                                                <ProgressBar striped variant="success" animated now={CompletedPercentage} key={1} label={`${CompletedPercentage}%`} />
                                                                                <ProgressBar striped variant="danger" animated now={balancePercentage} key={2} label={`${balancePercentage === "100.00" ? 0 : balancePercentage}%`} />
                                                                            </ProgressBar> */}

                                                                            <ProgressBar>
                                                                                <ProgressBar
                                                                                    striped
                                                                                    variant="success"
                                                                                    animated
                                                                                    now={CompletedPercentage}
                                                                                    key={1}
                                                                                    label={`${parseFloat(CompletedPercentage).toFixed(2)}%`}
                                                                                />
                                                                                <ProgressBar
                                                                                    striped
                                                                                    variant="danger"
                                                                                    animated
                                                                                    now={balancePercentage}
                                                                                    key={2}
                                                                                    label={`${balancePercentage === 100 ? '0.00' : parseFloat(balancePercentage).toFixed(2)}%`}
                                                                                />
                                                                            </ProgressBar>
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">

                                                                                <TableHeader headers={childHeaders}
                                                                                    onSorting={
                                                                                        (field, order) => setSorting({ field, order })
                                                                                    } />
                                                                                <tbody>

                                                                                    {


                                                                                        expandTnaList.map((tnaChildList, index) => (
                                                                                            <Fragment key={index}>


                                                                                                <tr>
                                                                                                    <td style={{ width: '120px' }}>
                                                                                                        {index + 1}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.createddate}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.styleNames}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.idPoNos}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.focusPONO}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.routeInfo}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.taskCompleted + ' % '}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {tnaChildList.targetDate}

                                                                                                    </td>
                                                                                                    <td style={{
                                                                                                        display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                                                            activeMenu[0].isDelete === 0 &&
                                                                                                            activeMenu[0].isView === 0 ? 'none' : ''
                                                                                                    }}>


                                                                                                        {
                                                                                                            tnaChildList.creationStatus === 1 ?

                                                                                                                <span style={{ display: TNAInfo && TNAInfo.isAdd === 1 ? '' : 'none' }} onClick={() => AddTNA(tnaChildList, 'Edit', getPlaceholder, comment.seasonID, comment.rowNumber)} title="Activate" className="btn btn-success"> <i className="fa fa-power-off"></i></span>
                                                                                                                :
                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} onClick={() => pageRedirect(tnaChildList, comment.supplierName, 'Edit', getPlaceholder, comment)} title="Edit TNA" className="btn btn-info btn-xs edit"><i className="fa fa-edit"></i></span>
                                                                                                        }
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            tnaChildList.creationStatus === 1 ? '' :
                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} title='View TNA' onClick={() => pageRedirect(tnaChildList, comment.supplierName, 'View', getPlaceholder, comment)}
                                                                                                                    className="btn btn-white btn-xs eye">
                                                                                                                    <i className="fa fa-eye "></i>
                                                                                                                </span>
                                                                                                        }
                                                                                                        &nbsp;
                                                                                                        {tnaChildList.tnaNotMappedPOCount > 0 &&
                                                                                                            <span title='Exiting TNA'
                                                                                                                onClick={() => pageRedirect(tnaChildList, comment.supplierName, 'NewPOStyle', getPlaceholder, comment)}
                                                                                                                className="btn btn-info active btn-xs NewPOStyle">
                                                                                                                <i className="fa fa-plus"></i>
                                                                                                            </span>
                                                                                                        }

                                                                                                        &nbsp;
                                                                                                        <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete TNA' onClick={() => pageRedirect(tnaChildList, comment.supplierName, 'ChildDelete', getPlaceholder, comment)}
                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </span>


                                                                                                    </td>
                                                                                                </tr>

                                                                                            </Fragment>
                                                                                        ))

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                        </>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={getID.Params}
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={ChildTNAList}
                                    Msg={"TNA deleted successfully."} /> : null
                            }

                            {
                                TNAPopupID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                    TNAPopupID.Params
                                }
                                    deleteCallback={AddTNAConfirmation}
                                    showpopup={true}
                                /> : null
                            }
                            {
                                newPOStyleDetailsPopup &&
                                <Modal show={newPOStyleDetailsPopup.IsPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideNewPOStyleDetailsPopup()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            New PO Style Details
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Purchase Order No</th>
                                                    <th>
                                                        <div className="checkbox" style={{ marginTop: "0px" }}>
                                                            <label>
                                                                <input type="checkbox"
                                                                    isMulti
                                                                    onChange={event => handlePOStyleSelect(event, 0, true)}
                                                                    value={POStyleIsAllChecked}
                                                                    checked={POStyleIsAllChecked}
                                                                    // checked={inputField.IsSelectAll === 1 ? true : false}
                                                                    className="colored-blue clearOpacity"
                                                                />
                                                                <span className="text"></span>
                                                            </label>
                                                        </div></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    newPOStyleListNotMappedTNA && newPOStyleListNotMappedTNA.length !== 0 ?
                                                        newPOStyleListNotMappedTNA.map((item, itemIndex) => {
                                                            return (
                                                                <>

                                                                    <tr>
                                                                        <td>{item.no}</td>
                                                                        <td>{item.purchaseOrderNo}</td>
                                                                        <td>
                                                                            <label>
                                                                                <input type="checkbox" name="isChecked"
                                                                                    Name
                                                                                    onChange={event => handlePOStyleSelect(event, itemIndex, false)}
                                                                                    value={item.isChecked}
                                                                                    checked={item.isChecked}
                                                                                    className="clearOpacity textdesign" />

                                                                                <span className="text"></span>
                                                                            </label>

                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        <tr><td colSpan="3" className='norecordfound'><span>No Records Found</span></td></tr>

                                                }
                                            </tbody>
                                        </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" className="btn btn-info" disabled={buttonLoader} onClick={AddNewPOStyleDetailsPopup}>
                                            <i className="fa fa-plus"></i>&nbsp;
                                            Add
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            }


                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )

};
export default TNAList;