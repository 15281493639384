import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Nodify from "../Common/ReactNotification";
import SearchRetain from "../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import { RouteActionID } from "../Common/PageActionNumber";
import CommonService from '../../services/Common/CommonService';
import { Redirect } from 'react-router-dom';
import { retrieveRoute } from "../../actions/route";
import SweetAlertPopup from '../Common/SweetAlertPopup';

const RouteList = (props) => {
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer Name / Route Name");
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.route.routeList;
    let isLoadingRoute = reducerState.route.isLoadingRoute;
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End
    //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let routeInfo;
    let info;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        info = IsProduction === 1 ? 'Route' : (IsProduction === 2 ? 'ProductionRoute' : undefined);
        activeMenu = menulist.filter(i => i.menuName ===  info );
        
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    routeInfo = activeMenuSub[index];
                }
            }
        }
        
    }
    // End Enable Menu

    
    useEffect(() => {
        let RouteId = 0;
        if (IsProduction !== 0) {
            dispatch(retrieveRoute(RouteId, IsProduction));
        }
        const state1 = props.location.state;

        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Route updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Route added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch, IsProduction]);

    useEffect(() => {
        if (!isLoadingRoute) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingRoute])

    const headers = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: "5%"
        },
        {
            name: "Buyer Name",
            field: "buyerName",
            sortable: true,
            widthsize: "15%"
        }, {
            name: "Route Name",
            field: "routeName",
            sortable: true,
            widthsize: "15%"
        }, {
            name: "Created Date",
            field: "createddate",
            sortable: true,
            widthsize: "15%",

        }, {
            name: "Action",
            field: "",
            sortable: false,
            widthsize: "10%",
            display: activeMenu && activeMenu[0]?.isEdit === 0 &&
                activeMenu[0].isDelete === 0 &&
                activeMenu[0].isView === 0 &&
                routeInfo && routeInfo.isAdd === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        //let computedComments = comments;
        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ buyerId, isAllInfo, isMapped }) =>
            o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        if (search) {
            computedComments = computedComments.filter(comment =>
                comment.routeName.toLowerCase().includes(search.toLowerCase())
                || comment.buyerName.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
    }

    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/RouteRegister', { params: [] });
        } else if (redirect === 'Edit') {
            dataParams.Action = 'Edit';
            props.history.push(`/RouteEdit`, { params: dataParams });
        }
        else if (redirect === 'View') {
            dataParams.Action = "View";
            props.history.push(`/RouteEdit`, { params: dataParams });
        }
        else if (redirect === 'Export') {
            dataParams.Action = 'Export';
            props.history.push(`/RouteEdit`, { params: dataParams });
        }
        else {
            let Params = {
                Operation: RouteActionID,
                Id: dataParams.routeInformationID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This route is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };


    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Route List</span>
                            </div>
                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }

                                            placeholder={getPlaceholder} props={props} />
                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0]?.isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Route">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map( (comment, index) => (
                                                    // <Fragment key={index1}>
                                                    <tr key={ 
                                                        comment.routeInformationID
                                                       }>
                                                       <td> 
                                                        {index + 1}
                                                       </td>
                                                        <td>{
                                                            comment.buyerName
                                                        }</td>
                                                        <td>{
                                                            comment.routeName
                                                        }</td>
                                                        <td>{
                                                            comment.createdDateStr
                                                        }</td>
                                                        {/* style={{
                                                            display: activeMenu.isEdit === 0 && activeMenu.isDelete === 0
                                                                && activeMenu.isView === 0 ? 'none' : ''
                                                        }} */}
                                                        <td style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                            activeMenu[0].isDelete === 0 &&
                                                            activeMenu[0].isView === 0 
                                                             && routeInfo && routeInfo.isAdd === 0 ? 'none' : ''
                                                        }}>
                                                            <span style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} title='View Route' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                className="btn btn-warning btn-xs eye">
                                                                <i className="fa fa-eye "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: routeInfo && routeInfo.isAdd === 1 ? '' : 'none' }} title='Export Route' onClick={() => pageRedirect(comment, 'Export', getPlaceholder)}
                                                                className="btn btn-primary btn-xs delete">
                                                                <i class="fa fa-cloud-upload"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Route' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Route' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>

                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={RouteActionID}
                                    Msg={"Route deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
};
export default RouteList;