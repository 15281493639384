
import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableHeader from '../../Datatable/Header/Testingheader';
import StyleService from "../../../services/Style/styleService";
import TNAService from "../../../services/TNA/TNAService";
import { error } from "jquery";
import { Pagination, Search, PageCount, ReactSearch } from "../../Datatable"
import { GetTestDetailsReportList } from "../../../actions/tna";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { DateRangePicker } from 'react-date-range';
import axios from "axios";
import Nodify from "../../Common/ReactNotification";
import $ from "jquery";
import Lightbox from "react-image-lightbox";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { TestingComment, TestingMainlist } from "../../Common/PageActionNumber";
import SweetAlertPopup from "../../Common/SweetAlertPopup";
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import NewTesting from "../../Production/Inspection_Quality Control/InspectionQC/NewTesting";
import TestingPopup from '../../Production/Inspection_Quality Control/InspectionQC/TestingPopupList';
import ReTesting from "../../Production/Inspection_Quality Control/InspectionQC/ReTesting";
import ReportService from "../../../services/Report/ReportService";
import { UpdateTestCommentsreportViewAllCheckBox, UpdateTestCommentsreportViewCheckBox, GetTestingMultExFactorySKUList } from "../../../actions/tna";
import { input } from "react-validation/build/input";

const TestingDetails = ({ props, Taskcallback }) => {

    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;

    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    var defaultStartDate = new Date();
    const [getExFacStartDate, setExFacStartDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            // startDate: moment().subtract(90, "days").format("MM/DD/YYYY"),
            //endDate: moment().subtract(1, "days").add(1, "days").format("MM/DD/YYYY"),
            key: 'selection',
            isShow: false
        }
    ]);
    const [InspectionAndApprovalpopup, SetinspectionAndApprovalpopup] = useState(false);
    const [statusType, Setstatustype] = useState(false);
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    const [sizeqtylist, setsizeqtylist] = useState([]);
    //const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    const [getCmtExFactoryList, setCmtExFactoryList] = useState([])
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            // startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            // startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultTNAStartDate = new Date();
    const [stateTNADate, setStateTNADate] = useState([
        {

            startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    //Testing
    const [getnewTestingReportlist, setnewTestingReportlist] = useState([]);
    const [getTestingheaderData, setTestingheaderdata] = useState([]);
    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getTestingReportPageView, setTestingReportPageView] = useState('AddInspection');
    const [getTNAPurchaseOrderID, setTNAPurchaseOrderID] = useState();
    const [gettestBuyerbrandListParam, settestBuyerbrandListParam] = useState({});
    const [getAddTestInspectionListParam, setTestAddInspectionListParam] = useState([]);
    const [getTestinspectionMulti, setTestinspectionMulti] = useState([]);
    const [getIDTestTask, setIDTestTask] = useState({ showPopupDelete: false, Params: {} });
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [getTestInspectionInfo, setTestInspectionInfo] = useState({
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0,
        Logopath: ''
    });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    const EmptyInputFields = {
        BuyerID: ""
        , BuyerIDForEdit: []
        , SeasonID: ""
        , SeasonIDForEdit: []
        , BrandID: ""
        , BrandIDForEdit: []
        , QuarterID: ""
        , QuarterIDForEdit: []
        , SupplierID: ""
        , SupplierIDForEdit: []
        , IDNo: ""
        , IDNoForEdit: []
        , StyleID: ""
        , StyleIDForEdit: []
        , SkuID: ""
        , SkuIDForEdit: []
        , TestApprovalFilter: ""
        , TestApprovalFilterForEdit: []
        , ExFacDate: ""
        , TestDate: ""
        , TNSDate: ""
        , OrderStatusID: ""
        , OrderStatusIDForEdit: []
    };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const Input = { ...inputFields };
    const [getBrandOptions, setBrandOptions] = useState([]);
    //const [getFiles, setFiles] = useState([])
    const [getSupplierCommentID, setSupplierCommentID] = useState(0);
    const [getCommentValues, setCommentValues] = useState([{ Comments: "", SupplierFactoryID: 0, SupplierFactoryIDForEdit: "" }]);
    const [getIsCommentSubmited, setIsCommentSubmited] = useState(false);
    const [getIDPONOOptions, setIDPONOOptions] = useState([]);
    const [getCommentGridList, setCommentGridList] = useState([]);
    const [getCommentAttachmentGridList, setCommentAttachmentGridList] = useState([]);
    const [getSKUOptions, setSKUOptions] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    //const [statusType, Setstatustype] = useState(false);
    const [approvalOrInspectionList, setapprovalOrInspectionList] = useState([]);
    const [getQCfinalinspectionMulti, setQCfinalinspectionMulti] = useState([]);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [Submitted, setSubmitted] = useState(false);
    const [isInspectionStarted, setisInspectionStarted] = useState([]);
    //const [InspectionAndApprovalpopup, SetinspectionAndApprovalpopup] = useState(false);
    const [selectedCommentExfactory, setSelectedCommentExfactory] = useState()
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [Commentpopup, setCommentpopup] = useState(false);
    const [Ecomment, setEcomment] = useState();
    const [getordindex, setOrdindex] = useState(0);
    const [show, setShow] = useState(false);
    const [HideShow, setHideShow] = useState(false);
    const [ReportTypeList, setReportTypeList] = useState([]);
    const [getQAInspectionPageView, setQAInspectionPageView] = useState('AddInspection');
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [reportcomments, setreportcomments] = useState([])
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getFiles, setFiles] = useState([])
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getReportStatussearch, setReportStatussearch] = useState("");
    const [getReportAndApprovalFilter, setReportAndApprovalFilter] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    const [getpurchaseId, setPurchaseId] = useState("0");
    const [getSupplierID, setSupplierID] = useState("0")
    const [getindex, setindex] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [checkboxStates, setCheckboxStates] = useState({

        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        inspectionStatus: true,
        approvalStatus: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        TNAID: true,
        StrikeoffName: true,
        Strieoffcode: true,
        Strkeimage: true,
        category: true,
        PlanEndDate: true,
        //  Factory: true,
        FOPONo: true,
        BalQtyforNewInspection: true,
        BalQtyforReNewInspection: true

    });

    // let activeMenu;
    // const menulist = useSelector((state) => state.common.MenuList.item3);
    // if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'Testing'); }

        //Enable Menu
        let activeMenu;
        let activeMenuSub;
        let testingInfo;
        const menulist = useSelector((state) => state.common.MenuList.item3);
        const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
        if (menulist !== undefined) {
            activeMenu = menulist.filter(i => i.menuName === "Testing");
            if (activeMenu.length > 0) {
                activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
                if (activeMenuSub.length > 0) {

                    for (let index = 0; index < activeMenuSub.length; index++) {
                        if (activeMenuSub[index].menuName === 'TestingComments') {
                            testingInfo = activeMenuSub[index];
                        }
                    }
                }

               
            }
        }
       // End Enable Menu


    
     


    const [getTestStatusOptions, setTestStatusOptions] = useState([
        { id: 1, value: 1, name: "Draft", label: "Draft" }
        , { id: 2, value: 2, name: "Satisfied", label: "Satisfied" }
        , { id: 3, value: 3, name: "Not Satisfied", label: "Not Satisfied" }
    ]);
    const [getQuarterOptions, setQuarterOptions] = useState([
        { id: 1, value: 1, label: "Q1", name: "Q1" }
        , { id: 2, value: 2, label: "Q2", name: "Q2" }
        , { id: 3, value: 3, label: "Q3", name: "Q3" }
        , { id: 4, value: 4, label: "Q4", name: "Q4" }]);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const tableHeaderStrioff = [
        {
            name: "#",
            field: "",
            sortable: false,
            left: 0,
            padding: "30px !important"
        }
        ,
        checkboxStates.PlanEndDate && ({
            name: "Plan End Date",
            field: "planEndDate",
            sortable: true,
            widthsize: "140px",
            left: 0,
            padding: "30px !important"
        })
        ,



        checkboxStates.TNAID && (
            {
                name: "TNA ID",
                field: "tnaid",
                sortable: true,
                widthsize: "100px",
                //position: "sticky",
                left: "120px",
                //background: "#222d32",
            }
        )
        ,
        checkboxStates.styleName && (
            {
                name: "Style Name",
                field: "styleName",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.StrikeoffName && (
            {
                name: "Strike Off Name",
                field: "strikeoffTrimName",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.Strieoffcode && (
            {
                name: "Strike Off Code",
                field: "strikeoffTrimCode",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.idNo && (
            {
                name: "PO/ID No",
                field: "idpono",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.FOPONo && (
            {
                name: "Focus PO No",
                field: "foidpono",
                sortable: true,
                width: "4%",
            }
        ),
        checkboxStates.Strkeimage && (
            {
                name: "Image",
                field: "strikeoffTrimLogo",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.supplier && (
            {
                name: "Supplier Name",
                field: "supplierName",
                sortable: true,
                width: "4%",
            }
        ),
        checkboxStates.inspectionStatus && (
            {
                name: "Test Status",
                field: "reportStatus",
                className: "text-center",
                sortable: false,
                width: "5%",
                minWidth: "150px"
            }
        ),
        checkboxStates.approvalStatus && (
            {
                name: "Approval Status",
                field: "reviewStatus",
                className: "text-center",
                sortable: false,
                width: "5%",
                minWidth: "200px"
            }
        ),
        // checkboxStates.orderStatus && (
        //     {
        //         name: "Order Status",
        //         field: "orderStatus",
        //         sortable: true,
        //         width: "5%",
        //     }
        // ),
        // checkboxStates.stsCreated && (
        //     {
        //         name: "	Status Created By",
        //         field: "statusCreatedBy",
        //         sortable: true,
        //         width: "2%",
        //         minWidth: "160px"
        //     }
        // ),

        // checkboxStates.currentStatus && (
        //     {
        //         name: "Current Status Comment",
        //         field: "currentComments",
        //         sortable: true,
        //         width: "7%",
        //         minWidth: "210px"
        //     }
        // ),
        // checkboxStates.cmtCreated && (
        //     {
        //         name: "Comment Created By",
        //         field: "commentsCreatedBy",
        //         sortable: true,
        //         width: "3%",
        //         minWidth: "185px"
        //     }
        // ),
        {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            width: "6%",
            display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''
            // display: activeMenu && activeMenu[0].isEdit === 0 &&
            // activeMenu[0].isDelete === 0 ? 'none' : ''
            //widthsize: "7%",
            //left: "736px",
        }
    ]
    const tableHeaderTrim = [
        {
            name: "#",
            field: "",
            sortable: false,

            left: 0,
            padding: "30px !important"
        }
        ,
        checkboxStates.PlanEndDate && ({
            name: "Plan End Date",
            field: "planEndDate",
            sortable: true,
            widthsize: "140px",
            left: 0,
            padding: "30px !important"
        })
        ,
        checkboxStates.TNAID && (
            {
                name: "TNA ID",
                field: "tnaid",
                sortable: true,
                widthsize: "100px",
                //position: "sticky",
                left: "120px",
                //background: "#222d32",
            }
        )
        ,
        checkboxStates.styleName && (
            {
                name: "Style Name",
                field: "styleName",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.StrikeoffName && (
            {
                name: "Trim Name",
                field: "strikeoffTrimName",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.Strieoffcode && (
            {
                name: "Trim Code",
                field: "strikeoffTrimCode",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.idNo && (
            {
                name: "PO/ID No",
                field: "idpono",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.FOPONo && (
            {
                name: "Focus PO No",
                field: "foidpono",
                sortable: true,
                width: "4%",
            }
        ),

        checkboxStates.Strkeimage && (
            {
                name: "Image",
                field: "strikeoffTrimLogo",
                sortable: true,
                width: "6%",
            }
        ),
        checkboxStates.supplier && (
            {
                name: "Supplier Name",
                field: "supplierName",
                sortable: true,
                width: "4%",
            }
        ),



        checkboxStates.inspectionStatus && (
            {
                name: "Test Status",
                field: "reportStatus",
                className: "text-center",
                sortable: false,
                width: "5%",
                minWidth: "150px"
            }
        ),
        checkboxStates.approvalStatus && (
            {
                name: "Approval Status",
                field: "reviewStatus",
                className: "text-center",
                sortable: false,
                width: "5%",
                minWidth: "200px"
            }
        ),
        // checkboxStates.orderStatus && (
        //     {
        //         name: "Order Status",
        //         field: "orderStatus",
        //         sortable: true,
        //         width: "5%",
        //     }
        // ),
        // checkboxStates.stsCreated && (
        //     {
        //         name: "	Status Created By",
        //         field: "statusCreatedBy",
        //         sortable: true,
        //         width: "2%",
        //         minWidth: "160px"
        //     }
        // ),

        // checkboxStates.currentStatus && (
        //     {
        //         name: "Current Status Comment",
        //         field: "currentComments",
        //         sortable: true,
        //         width: "7%",
        //         minWidth: "210px"
        //     }
        // ),
        // checkboxStates.cmtCreated && (
        //     {
        //         name: "Comment Created By",
        //         field: "commentsCreatedBy",
        //         sortable: true,
        //         width: "3%",
        //         minWidth: "185px"
        //     }
        // ),
        {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            width: "6%",
            display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''
            //  display: activeMenu && activeMenu[0].isEdit === 0 &&
            // activeMenu[0].isDelete === 0 ? 'none' : ''
            //widthsize: "7%",
            //left: "736px",
        }
    ]

    let size = '110px';
    const tableHeader = [
        {
            name: "#",
            field: "",
            sortable: false,
            left: 0,
            padding: "30px !important",
            widthsize: '2%',
        }
        ,
        checkboxStates.PlanEndDate && ({
            name: "Plan End Date",
            field: "planEndDate",
            sortable: true,
            widthsize: '130px',
            left: 0,
            //padding: "30px !important"
        })
        ,



        checkboxStates.skuID && (
            {
                name: "PO SKU ID",
                field: "purchaseOrderSkuID",
                sortable: true,
                widthsize: size,
                //position: "sticky",
                //left: "120px",
                //background: "#222d32",
            }
        ),
        checkboxStates.styleName && (
            {
                name: "Style Name",
                field: "styleName",
                sortable: true,
                widthsize: '120px',
                // width: "6%",
            }
        ),


        checkboxStates.skuName && (
            {
                name: "SKU Name",
                field: "skuName",
                sortable: true,
                // width: "5%",
                // widthsize: "120px",
                //position: "sticky",
                left: "736px",
                widthsize: size,
                //background: "#222d32",
            }
        ),
        checkboxStates.idNo && (
            {
                name: "PO/ID No",
                field: "idpono",
                sortable: true,
                widthsize: size,
                // width: "6%",
            }
        ),
        checkboxStates.FOPONo && (
            {
                name: "Focus PO No",
                field: "foidpono",
                sortable: true,
                widthsize: '130px',
                // width: "4%",
            }
        ),
        checkboxStates.skuImg && (
            {
                name: "SKU Image",
                field: "skuImage",
                sortable: true,
                // width: "4%",
                // widthsize: "120px",
                // position: "sticky",
                left: "858px",
                widthsize: size,
                //background: "#222d32",
            }
        ),
        checkboxStates.supplier && (
            {
                name: "Supplier Name",
                field: "supplierName",
                sortable: true,
                widthsize: size,
                // width: "4%",
            }
        ),
        // checkboxStates.Factory && (
        //     {
        //         name: "Factory",
        //         field: "factory",
        //         sortable: true,
        //         width: "4%",
        //     }
        // ),



        // checkboxStates.styleNo && (
        //     {
        //         name: "Style No",
        //         field: "styleNo",
        //         sortable: true,
        //         width: "6%",
        //     }
        // ),

        checkboxStates.orderQty && (
            {
                name: "Order Qty",
                field: "quantity",
                sortable: true,
                widthsize: size,
                // width: "5%",
            }
        ),

        checkboxStates.exFac && (
            {
                name: "Ex-Fac Date",
                field: "exFacDate",
                sortable: true,
                widthsize: '120px',
                // width: "4%",
            }
        ),

        checkboxStates.inspectionStatus && (
            {
                name: "Test Status",
                field: "reportStatus",
                className: "text-center",
                sortable: false,
                widthsize: '160px',
                // width: "5%",
                // minWidth: "150px"
            }
        ),
        checkboxStates.approvalStatus && (
            {
                name: "Approval Status",
                field: "reviewStatus",
                className: "text-center",
                sortable: false,
                widthsize: '160px',
                // width: "5%",
                // minWidth: "200px"
            }
        ),


        // checkboxStates.orderStatus && (
        //     {
        //         name: "Order Status",
        //         field: "orderStatus",
        //         sortable: true,
        //         widthsize: '120px',
        //        // width: "5%",
        //     }
        // ),
        // checkboxStates.stsCreated && (
        //     {
        //         name: "	Status Created By",
        //         field: "statusCreatedBy",
        //         sortable: true,
        //         width: "2%",
        //         minWidth: "160px"
        //     }
        // ),

        // checkboxStates.currentStatus && (
        //     {
        //         name: "Current Status Comment",
        //         field: "currentComments",
        //         sortable: true,
        //         width: "7%",
        //         minWidth: "210px"
        //     }
        // ),
        // checkboxStates.cmtCreated && (
        //     {
        //         name: "Comment Created By",
        //         field: "commentsCreatedBy",
        //         sortable: true,
        //         width: "3%",
        //         minWidth: "185px"
        //     }
        // ),
        {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            widthsize: size,
             display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''
            // width: "6%",
            //widthsize: "7%",
            //left: "736px",
        }
    ];
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    useEffect(() => {
        showLoader();
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');

        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(GetTestDetailsReportList(params))
        //console.log(params)
        // dispatch(GetTestDetailsReportList(
        //     (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
        //     , IsProduction
        //     , Taskcallback
        //     , moment(getExFacStartDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
        //     , moment(getExFacStartDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
        // ));
        TNAService.LoadSKUNames().then((response) => {
            setSKUOptions(response.data);
            hideLoader();
        }).catch(() => { hideLoader(); });
    }, [IsProduction, Taskcallback]);

    //const comments = reducerState.tna.testingDetailsList.item1;
    const comments = reducerState.tna.testingDetailsList.item1 !== undefined ? reducerState.tna.testingDetailsList.item1 : reducerState.tna.testingDetailsList;

    if (comments.length > 0) {
        for (let index = 0; index < comments.length; index++) {
            let Parent = comments[index];
            for (let i = 0; i < reducerState.tna.testingDetailsList.item2.length; i++) {
                let Child = reducerState.tna.testingDetailsList.item2[i];
                if (Child.purchaseOrderID === Parent.purchaseOrderID && Child.indexName === Parent.indexName) {
                    Parent.styleID = Child.styleID; Parent.styleName = Child.styleName; Parent.styleNo = Child.styleNo; Parent.categoryName = Child.categoryName;
                    Parent.buyerEmployee = Child.buyerEmployee; Parent.cubeEmployee = Child.cubeEmployee;
                }
            }
        }
    }
    const handleBuyerChange = (e) => {
        let Values = [...inputFields];
        if (e !== null) {
            Values[0].BuyerID = e.value;
            Values[0].BuyerIDForEdit = e;
            StyleService.LoadBrandThemeStoryEndCustomer(5, e.value).then((response) => {
                if (response.data) {
                    setBrandOptions(response.data);
                }
            }).catch(() => { });
        }
        else if (e === null) {
            Values[0].BuyerID = 0;
            Values[0].BuyerIDForEdit = [];
            Values[0].BrandID = 0;
            Values[0].BrandIDForEdit = [];
            setBrandOptions([]);
        }
        setInputFields(Values);
    }

    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateTNADate = [...stateTNADate];
        getStateExDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateDate(getStateDate);
        setStateExDate(getStateExDate);
    }
    const datePickerRefEx = useRef(null);
    const datePickerEntryDate = useRef(null);
    const datePickerTnaDate = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRefEx.current && !datePickerRefEx.current.contains(event.target)) {
                setStateExDate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerEntryDate.current && !datePickerEntryDate.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerTnaDate.current && !datePickerTnaDate.current.contains(event.target)) {
                setStateTNADate(prev => [{ ...prev[0], isShow: false }]);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const clearExDateSelect = () => {
        debugger
        var defaultStartDate = new Date();
        const emptyStateExDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateExDate(emptyStateExDate);
        setStateExDate([{ startDate: null, endDate: null, isShow: false }]);
        var defaultStartDate = new Date();
        const newemptyStateExDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];

        let StartExDate = null;
        let EndExDate = null;
        if (moment(newemptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(newemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(newemptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(newemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            // , ExfacStartDate: '01/01/1970'
            // , ExfacEndDate: formattedDate
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            // , TNAStartDate: TNAStartDate
            // , TNAEndDate: TNAEndDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
        }
        dispatch(GetTestDetailsReportList(params))
    }
    const handleTNADateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateTNADate = [...stateTNADate];
        let getStateExDate = [...stateExDate];
        getStateTNADate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateExDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateTNADate(getStateTNADate);
        setStateExDate(getStateExDate)
    }
    function DateTNAchange(item) {
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('');
        setStateDate(stateDate)
        // stateTNADate[0].startDate = item[0].startDate;
        // stateTNADate[0].endDate = item[0].endDate;
        // setStateTNADate(stateTNADate)
        const updatedStateTNADate = [
            {
                ...stateTNADate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateTNADate(updatedStateTNADate);

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(updatedStateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(updatedStateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(updatedStateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(updatedStateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(GetTestDetailsReportList(params))
    }
    const resetStyle = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');
        setReportStatussearch('');
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        const emptyStateDate = ([
            {

                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        const emptyStateTnaDate = ([
            {

                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        if (props.location.state && props.location.state.params.localStorage === true) {
            props.location.state.params.localStorage = false;
        }
        localStorage.removeItem("PodateExStatedate");
        localStorage.removeItem("PodateExenddate");
        setStateTNADate(emptyStateExDate);
        setStateExDate(emptyStateTnaDate);
        setStateDate(emptyStateDate);
        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);

        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            // , ExfacStartDate: '01/01/1970'
            // , ExfacEndDate: formattedDate
            // , TestStartDate: '01/01/1970',
            // TestEndDate: formattedDate
            // , TNAStartDate: TNAStartDate
            // , TNAEndDate: TNAEndDate
            , ExfacStartDate: StartExDate || '01/01/1970'
            , ExfacEndDate: EndExDate || formattedDate
            , TestStartDate: StartDate || '01/01/1970',
            TestEndDate: EndDate || formattedDate
            , TNAStartDate: TNAStartDate || '01/01/1970'
            , TNAEndDate: TNAEndDate || formattedDate
        }
        dispatch(GetTestDetailsReportList(params));
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        stateExDate[0].isShow = false
        setStateExDate(stateExDate)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('');
        stateDate[0].isShow = false
        setStateDate(stateDate)
    }
    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]
    const handleChangeOrdStatus = (value, field) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
        if (field == 'ReportApprovalFilter' && value != null) {
            setReportStatussearch(value);
            setReportStatussearch(value);
        }
        else {
            setReportStatussearch('');
            setOrderStatusSearch(value);
        }
        //setInspectionAndApprovalSearch(value);

    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateTNADate = [...stateTNADate];
        getStateDate[0].isShow = isShow;
        getStateExDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
    }
    const handleclick = () => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);

    }
    function DateExfaconchange(item) {

        // stateExDate[0].startDate = item[0].startDate;
        // stateExDate[0].endDate = item[0].endDate
        // setStateExDate(stateExDate)
        const updatedstateExDate = [
            {
                ...stateExDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateExDate(updatedstateExDate);
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('');
        setStateDate(stateDate)
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)
        let StartExDate = null;
        let EndExDate = null;
        if (moment(updatedstateExDate[0].startDate).isValid()) {
            StartExDate = moment(updatedstateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(updatedstateExDate[0].endDate).isValid()) {
            EndExDate = moment(updatedstateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
        }
        dispatch(GetTestDetailsReportList(params))
    }

    const clearTNADateSelect = () => {
        debugger
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateTNADate(emptyStateDate);
        setStateTNADate([{ startDate: null, endDate: null, isShow: false }]);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            TNAStartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            TNAEndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(GetTestDetailsReportList(params))
    }
    const handleExFacDateSelect = (event, isShow) => {
        let getStateDate = [...getExFacStartDate];
        getStateDate[0].isShow = isShow;
        setExFacStartDate(getStateDate);
    }

    const clearDateSelect = () => {
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        setStateDate([{ startDate: null, endDate: null, isShow: false }]);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('DD/MM/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('DD/MM/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('DD/MM/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('DD/MM/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('DD/MM/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('DD/MM/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('DD/MM/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            // , TestStartDate: '01/01/1970',
            // TestEndDate: formattedDate
            , TestStartDate: StartDate,
            TestEndDate: EndDate
            // , TNAStartDate: TNAStartDate
            // , TNAEndDate: TNAEndDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
        }
        dispatch(GetTestDetailsReportList(params))
    }

    const handleIDPOChange = (e) => {
        const values = [...inputFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        values[0].IDNoForEdit = inputText;
        setInputFields(values);
    }

    const ResetTestDetails = () => {
        setInputFields([EmptyInputFields]);
        clearDateSelect();
    }

    function DateRangechange(item) {
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        // stateDate[0].startDate = item[0].startDate;
        // stateDate[0].endDate = item[0].endDate;
        // setStateDate(stateDate)
        const updatedStateDate = [
            {
                ...stateDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateDate(updatedStateDate);
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(updatedStateDate[0].startDate).isValid()) {
            StartDate = moment(updatedStateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(updatedStateDate[0].endDate).isValid()) {
            EndDate = moment(updatedStateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TestStartDate: StartDate,
            TestEndDate: EndDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
        }
        dispatch(GetTestDetailsReportList(params))
    }

    const OpenCommentPopup = async (e, inspectionID, TNAID, supplierID, purchaseOrderSkuID, Feildname) => {
        let isChecked = false;
        let indexes = comments.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        setindex(indexes);
        if (Feildname === 'Comments') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateTestCommentsreportViewCheckBox(params));
        await FinalinspectionService.GetFLCommentsReportExFactory(supplierID, 1, 0).then((res) => {
            if (res.data) {
                setCmtExFactoryList(res.data.flexFacCommon);
            }
        })
        await TNAService.GetTestPurchaseOrderSkuReportComments(parseInt(purchaseOrderSkuID), Taskcallback).then((res) => {
            if (res.data) {
                setCommentGridList(res.data.flReportCommentDetails);
                setCommentAttachmentGridList(res.data.flReportfileupload);
            }
        })
        setSupplierCommentID(supplierID);
        setCommentpopup(true);
    }

    function handleDeleteTestinspection(item) {
        let PackingParams = {
            Operation: 126,
            Id: item.tnaTestingInspectionID,
        }
        setIDTestTask({ showPopupDelete: true, Params: PackingParams });
    }

    function handleTestInspecViewDetails(item, Type) {
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })
        getTestInspectionInfo.buyerName = gettestBuyerbrandListParam.buyerName;
        getTestInspectionInfo.buyerID = gettestBuyerbrandListParam.buyerID;
        getTestInspectionInfo.brandID = gettestBuyerbrandListParam.brandID;
        getTestInspectionInfo.brandName = gettestBuyerbrandListParam.brandName;
        getTestInspectionInfo.seasonID = gettestBuyerbrandListParam.seasonID;
        getTestInspectionInfo.seasonName = gettestBuyerbrandListParam.seasonName;
        getTestInspectionInfo.supplierID = getTNAPurchaseOrderID.supplierID;
        getTestInspectionInfo.supplierName = getTNAPurchaseOrderID.supplierName;
        getTestInspectionInfo.styleID = gettestBuyerbrandListParam.styleID;
        getTestInspectionInfo.styleName = getTNAPurchaseOrderID.styleName;
        getTestInspectionInfo.styleNo = getTNAPurchaseOrderID.styleNo
        getTestInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getTestInspectionInfo.quantityinfo = { tnaid: getTNAPurchaseOrderID.tnaid, purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID, TaskId: getTNAPurchaseOrderID.taskDetailsID }// For Binding Selected checkbox values in quantity inforamtion
        getTestInspectionInfo.details = getAddTestInspectionListParam;
        getTestInspectionInfo.TnaID = getTNAPurchaseOrderID.tnaid;
        getTestInspectionInfo.type = item.typeInternorExtern;//For InterNal Or External
        getTestInspectionInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
        getTestInspectionInfo.Isproduction = IsProduction;
        getTestInspectionInfo.TestingInspectionID = item.tnaTestingInspectionID;
        getTestInspectionInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
        getTestInspectionInfo.pageType = Type;//For HeaderName Add,view or edit
        getTestInspectionInfo.ParentTask = "TestingProd";//Task Based back or save redirect function
        getTestInspectionInfo.tabName = "Testing Details";
        debugger;
        props.history.push(`/createTesting`, { params: getTestInspectionInfo });
    }

    async function handleFinalInspecViewOpen(comment, n, operation) {
        debugger
        setTNAPurchaseOrderID(comment)
        showLoader();
        getTestInspectionInfo.Logopath = comment.strikeoffTrimLogo
        Input.Finalview = 1;
        // Input.Finalview = 0
        setInputFields(Input);
        await StyleService.GetStyleList(comment.styleID, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('DD/MM/YYYY')).then((response) => {
            if (response.data) {
                settestBuyerbrandListParam(response.data.item1[0])
            }
        }).catch(() => { });
        await TNAService.TestingreportList(comment.tnaExFactoryId, operation, parseInt(comment.tnaid)).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewTestingReportlist(res.data)
            setTestingheaderdata(uniquevalues);
            hideLoader();
        })

        getTestInspectionInfo.TnaID = parseInt(comment.tnaid);
        getTestInspectionInfo.TaskStatus = 2;
        getTestInspectionInfo.TaskType = Taskcallback;
        setTestInspectionInfo(getTestInspectionInfo);

        let params = {
            Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3,
            BuyerId: comment.buyerID,
            BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID, TaskId: comment.taskDetailsID,
            StyleId: comment.styleID,
            SkuId: 0,
            TaskOwnerIdList: comment.taskOwner,
            TaskHolderId: 0,
            tnaExFactoryId: comment.tnaExFactoryId,
            status: 0,
            tnaid: parseInt(comment.tnaid),
            selectedDependency: '',
            //NewID is subID
            NewID: comment.skuSubID,
            Isproduction: IsProduction
        };
        setTestAddInspectionListParam(params);
        dispatch(GetTestingMultExFactorySKUList(params));
    }
    // async function handleExFactoryFormOpen(comment, newss, Taskid) {
    //     debugger
    //     setTestingReportPageView('AddInspection');
    //     setTNAPurchaseOrderID(comment)
    //     const params = {
    //         StyleIDListMulti: props.location.state.params.styleId.toString(),
    //         CheckProgramSkuIDList: comment.purchaseOrderSkuID.toString()
    //     };
    //     let Willallow = false;
    //     const res = await TNAService.GetCheckProgramSKUList(params);

    //     if (res.data.outputResult === "1") {
    //         Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
    //         Willallow = false;
    //         return;
    //     }
    //     await StyleService.GetStyleList(comment.styleID, IsProduction, 0, '01/01/1949', moment(getExFacStartDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
    //         if (response.data) {
    //             settestBuyerbrandListParam(response.data.item1[0])
    //         }
    //         else if (Willallow) {
    //             let params = {
    //                 Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3,
    //                 BuyerId: comment.buyerID, BrandId: comment.brandID, SeasonId: comment.seasonID,
    //                 SupplierId: comment.supplierID, TaskId: Taskid, StyleId: comment.styleID,
    //                 SkuId: 0, TaskOwnerIdList: comment.taskOwner, TaskHolderId: 0,
    //                 tnaExFactoryId: 0, status: 1, selectedDependency: '', NewID: comment.subId,
    //                 Isproduction: IsProduction, tnaid: parseInt(comment.tnaid), PurchaseOrderSkuid: (Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? comment.strikeoffTrimID : comment.purchaseOrderSkuID

    //             };
    //             setTestAddInspectionListParam(params);
    //             Input.IsTestinsectionFactory = 1;
    //             getTestInspectionInfo.TnaID = parseInt(comment.tnaid);
    //             getTestInspectionInfo.TaskStatus = 1;
    //             getTestInspectionInfo.TaskType = Taskcallback;//is for Midline or pilotrun or set sizecheck
    //             setInputFields(Input);
    //             setTestInspectionInfo(getTestInspectionInfo);
    //         }
    //     }).catch((error) => { console.log(error) });
    // }
    async function handleExFactoryFormOpen(comment, newss, Taskid) {
        debugger
        try {
            debugger;
            // Update view state
            setTestingReportPageView('AddInspection');
            setTNAPurchaseOrderID(comment);

            // Prepare parameters for API call
            const params = {
                StyleIDListMulti: comment.styleID.toString(),
                CheckProgramSkuIDList: comment.skuSubID.toString()
            };

            // Check if program task exists
            let Willallow = true
            const checkProgramResponse = await TNAService.GetCheckProgramSKUList(params);
            if (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST') {
                if (checkProgramResponse.data.outputResult === "1") {
                    Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
                    Willallow = false;
                    return;
                }
            }

            // Fetch style list
            const startDate = moment(getExFacStartDate[0].endDate).format('MM/DD/YYYY');
            const styleResponse = await StyleService.GetStyleList(comment.styleID, IsProduction, 0, '01/01/1949', startDate);

            if (styleResponse.data) {
                settestBuyerbrandListParam(styleResponse.data.item1[0]);
            }

            // Prepare parameters for inspection if allowed
            if (Willallow) {
                const taskOperation = getTaskOperation(Taskcallback);
                const params = {
                    Operation: taskOperation,
                    BuyerId: comment.buyerID,
                    BrandId: comment.brandID,
                    SeasonId: comment.seasonID,
                    SupplierId: comment.supplierID,
                    TaskId: Taskid,
                    StyleId: comment.styleID,
                    SkuId: 0,
                    TaskOwnerIdList: comment.taskOwner,
                    TaskHolderId: 0,
                    tnaExFactoryId: 0,
                    status: 1,
                    selectedDependency: '',
                    NewID: comment.subId,
                    Isproduction: IsProduction,
                    TnaId: parseInt(comment.tnaid),
                    PurchaseOrderSkuid: getPurchaseOrderSkuId(Taskcallback, comment)
                };

                setTestAddInspectionListParam(params);

                const input = {
                    IsTestinsectionFactory: 1
                };
                setInputFields(input);

                const testInspectionInfo = {
                    TnaID: parseInt(comment.tnaid),
                    TaskStatus: 1,
                    TaskType: Taskcallback
                };
                setTestInspectionInfo(testInspectionInfo);
            }
        } catch (error) {
            console.error('Error handling ExFactory form open:', error);
        }
    }

    function getTaskOperation(taskCallback) {
        switch (taskCallback) {
            case 'FABRIC TEST':
            case 'YARN TEST':
            case 'GARMENT TEST':
                return 1;
            case 'Strike off Test':
                return 2;
            default:
                return 3;
        }
    }

    function getPurchaseOrderSkuId(taskCallback, comment) {
        return (taskCallback === 'Strike off Test' || taskCallback === 'Trims Test') ? comment.strikeoffTrimID : comment.purchaseOrderSkuID;
    }

    const handleCloseTestingTaskFormPopup = (index, subIndex, childIndex) => {
        Input.IsTestinsectionFactory = 0;
        setInputFields(Input);
    };

    function pageRedirectForTestingInspection(val) {
        setTestingReportPageView(val)
    }
    const handleFullClose = () => {
        $('#FileUpload').val("");
        setFiles([]);
        setCommentpopup(false);
        setSupplierCommentID(0);
    }
    function CreateTestInspection(type, taskCallback) {
        debugger
        let isvalid = true;
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let StrikeoffORTrimID = '';
            let purchaseOrderSkuID = '';
            let NewArr = [];
            NewArr = getTestinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.forEach((Purchaseorder) => {
                const { refInspecSKUID, purchaseOrderSkuID: skuID, strikeOffID, trimInfoID, tnaTestingInspectionID, tnaExFactoryId } = Purchaseorder;

                if (["FABRIC TEST", "YARN TEST", "GARMENT TEST"].includes(Taskcallback)) {
                    sizeandqtyfordisplay = sizeandqtyfordisplay ? `${sizeandqtyfordisplay},${refInspecSKUID}` : refInspecSKUID.toString();
                    purchaseOrderSkuID = purchaseOrderSkuID ? `${purchaseOrderSkuID},${skuID}` : skuID.toString();
                } else if (Taskcallback === 'Strike off Test') {
                    // StrikeoffORTrimID = StrikeoffORTrimID ? `${StrikeoffORTrimID},${strikeOffID}` : strikeOffID.toString();
                    StrikeoffORTrimID = StrikeoffORTrimID ? `${StrikeoffORTrimID},${tnaExFactoryId}` : tnaExFactoryId.toString();
                } else {
                    // StrikeoffORTrimID = StrikeoffORTrimID ? `${StrikeoffORTrimID},${trimInfoID}` : trimInfoID.toString();
                    StrikeoffORTrimID = StrikeoffORTrimID ? `${StrikeoffORTrimID},${tnaExFactoryId}` : tnaExFactoryId.toString();
                }
            });
            if (getTestingReportPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = x.tnaTestingInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaTestingInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getTestInspectionInfo.multiselecteddatas = uniquevalues;
                getTestInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getTestInspectionInfo.multiselecteddatas = getTestinspectionMulti;
            }
            getTestInspectionInfo.PurchaseSkuID = purchaseOrderSkuID;
            getTestInspectionInfo.buyerName = gettestBuyerbrandListParam.buyerName;
            getTestInspectionInfo.buyerID = gettestBuyerbrandListParam.buyerID;
            getTestInspectionInfo.brandID = gettestBuyerbrandListParam.brandID;
            getTestInspectionInfo.brandName = gettestBuyerbrandListParam.brandName;
            getTestInspectionInfo.seasonID = gettestBuyerbrandListParam.seasonID;
            getTestInspectionInfo.seasonName = gettestBuyerbrandListParam.seasonName;
            getTestInspectionInfo.supplierID = getTNAPurchaseOrderID.supplierID;
            getTestInspectionInfo.supplierName = getTNAPurchaseOrderID.supplierName;
            getTestInspectionInfo.styleID = gettestBuyerbrandListParam.styleID;
            getTestInspectionInfo.styleName = getTNAPurchaseOrderID.styleName;
            getTestInspectionInfo.styleNo = getTNAPurchaseOrderID.styleNo
            getTestInspectionInfo.multiselecteddatas = getTestinspectionMulti;
            getTestInspectionInfo.quantityinfo = { tnaid: parseInt(getTNAPurchaseOrderID.tnaid), purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID, TaskId: getTNAPurchaseOrderID.taskDetailsID };// For Binding Selected checkbox values in quantity inforamtion
            getTestInspectionInfo.details = getAddTestInspectionListParam;
            // getTestInspectionInfo.TnaID = getTNAPurchaseOrderID.tnaid;
            getTestInspectionInfo.type = type;//For InterNal Or External
            getTestInspectionInfo.StrikeoffTrimIDlist = StrikeoffORTrimID;
            getTestInspectionInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestInspectionInfo.Isproduction = IsProduction;
            getTestInspectionInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
            getTestInspectionInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getTestInspectionInfo.ParentTask = "TestingProd";//Task Based back or save redirect function
            props.history.push(`/createTesting`, { params: getTestInspectionInfo });
        }
    }
    function QCTestingCallback(value) {
        setTestinspectionMulti(value)
    }
    function handleCloseInspectionConfirmationPopup() {
        setIsAddFinalInspection(false)
    }
    function AddTestingExFactoryForm() {
        let Multiselected = [];
        const Values = [...getTestinspectionMulti]
        let isvalid = true;
        isvalid = !!getTestinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getTestinspectionMulti.filter(x => x.isSelected === 1))
            setIsAddFinalInspection(true);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

    }

    function handleCloseTestingView() {
        Input.Finalview = 0;
        setInputFields(Input);
    }
    const handleCommentChange = (e) => {
        const commentvalues = [...getCommentValues];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        commentvalues[0].Comments = inputText;
        setCommentValues(commentvalues);
    }

    function handleChange(e, filterName) {

        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }

        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }

        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSkuSearch(inputText);
        }
        // else if (filterName === 'Factory') {
        //     if (e.target.value.trim()) {
        //         inputText = e.target.value;
        //     }
        //     setFactorySearch(inputText);
        // }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            setIsProductionStatussearch(inputText);
        }


        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        }
    };
    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }

    const GetInspectionAndApprovalStatus = (PurchaseOrderSkuIDOrInspectionID, statusType) => {
        debugger
        setskuid('');
        showLoader();
        TNAService.GetInspectionandApprovalList(statusType, PurchaseOrderSkuIDOrInspectionID, Taskcallback).then((response) => {
            if (response.data && (statusType == 1 && response.data.inspectionInfo.length > 0 || statusType == 2 && response.data.approvalInfo
                .length > 0)) {
                setisInspectionStarted(1);
                statusType == 1 ? setapprovalOrInspectionList(response.data.inspectionInfo) : setapprovalOrInspectionList(response.data.approvalInfo);
            }
            else {
                setisInspectionStarted(0);
            }
            hideLoader();
        }).catch(() => {
            hideLoader();
        });
        setskuid({ skuID: PurchaseOrderSkuIDOrInspectionID, qrty: 0 })
        Setstatustype(statusType);
        SetinspectionAndApprovalpopup(true);
    }
    const GetSizeandQty = (PurchaseOrderSkuID, orderQty) => {
        setskuid('');
        ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
            if (response.data) {
                setsizeqtylist(response.data)
            }

        }).catch(() => { });
        setsizeqtypopup(true);
        setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }
    const filevals = [...getFiles]
    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TestingDetailsComments");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    let Index = 0;
                    //   let Index = comments.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
                    filevals.push({
                        reportManualFileId: 0,
                        fileName: res.data + "_" + file.name,
                        filePath: "Images/TestingDetailsComments/" + file.name,
                        fileType: file.type,
                        reportIndexName: Index.toString(),
                        reportFileIndexName: getFiles.length.toString(),
                        IsDeleted: 0
                    });
                    setFiles(filevals);
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const handleSaveComment = (e, comment) => {

        if (!getCommentValues[0].Comments || !getCommentValues[0].SupplierFactoryIDForEdit) {
            setIsCommentSubmited(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            var CommentInformationData = [];
            var ManualFileInformationData = [];
            let Selecteditems = commentsData.filter(x => x.supplierID === getSupplierCommentID && x.isSelected === true);
            let len = 0;
            Selecteditems.map((item, index) => {
                if (item && item !== 0) {
                    len = len + 1;
                    CommentInformationData.push({
                        CommentIndex: len,
                        PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
                        CommentDetail: getCommentValues[0].Comments,
                        IsStatus: 3,
                        SupplierInfo: getCommentValues[0].SupplierFactoryIDForEdit.value,
                        Supplierfactory: getCommentValues[0].SupplierFactoryIDForEdit.label

                    })
                    getFiles.filter(x => x.reportManualFileId === 0).map((x, indexs) => {
                        ManualFileInformationData.push({
                            ReportManualFileId: x.reportManualFileId,
                            PurchaseOrderSkuID: parseInt(item.purchaseOrderSkuID),
                            FileType: x.fileType,
                            FileName: x.fileName,
                            FilePath: x.filePath,
                            ReportIndexName: x.reportIndexName,
                            ReportFileIndexName: x.reportFileIndexName,
                            IsDeleted: x.IsDeleted
                        })
                    })
                }
            })
            var Data = { CreatedBy: currentUser.employeeinformationID, userLat: '0', userLong: '0', TaskType: Taskcallback, CommentInformation: CommentInformationData, ReportManualFileInformation: ManualFileInformationData };
            TNAService.InsertUpdateTestreportComment(Data).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added Successfully");
                    setCommentpopup(false);
                    setFiles([]);
                    let Selecteditems = commentsData.filter(x => getSupplierCommentID === x.supplierID);
                    setCommentValues([{ Comments: "", SupplierFactoryID: 0, SupplierFactoryIDForEdit: "" }]);
                    let StartExDate = null;
                    let EndExDate = null;
                    if (moment(stateExDate[0].startDate).isValid()) {
                        StartExDate = moment(stateExDate[0].startDate).format('DD/MM/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateExDate[0].endDate).isValid()) {
                        EndExDate = moment(stateExDate[0].endDate).format('DD/MM/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('DD/MM/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('DD/MM/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    let TNAStartDate = null;
                    let TNAEndDate = null;
                    if (moment(stateTNADate[0].startDate).isValid()) {
                        TNAStartDate = moment(stateTNADate[0].startDate).format('DD/MM/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateTNADate[0].endDate).isValid()) {
                        TNAEndDate = moment(stateTNADate[0].endDate).format('DD/MM/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    // Get the current date
                    const currentDate = new Date();

                    // Create a moment object from the current date and add one year
                    const futureDate = moment(currentDate).add(1, 'year');

                    // Format the future date
                    const formattedDate = futureDate.format('MM/DD/YYYY');
                    let params = {
                        IsProduction: IsProduction,
                        Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
                        , TaskDetailsName: Taskcallback
                        , ExfacStartDate: '01/01/1970'
                        , ExfacEndDate: formattedDate
                        , TestStartDate: '01/01/1970',
                        TestEndDate: formattedDate
                        , TNAStartDate: TNAStartDate
                        , TNAEndDate: TNAEndDate
                    }
                    dispatch(GetTestDetailsReportList(params))
                }
            }, (error) => {
            })
            setIsCommentSubmited(false);
        }
    }

    const handleExFactoryChange = (e) => {
        const commentvalues = [...getCommentValues];
        if (e != null) {
            commentvalues[0].SupplierFactoryID = e.value;
            commentvalues[0].SupplierFactoryIDForEdit = e;
        }
        else {
            commentvalues[0].SupplierFactoryID = 0;
            commentvalues[0].SupplierFactoryIDForEdit = [];
        }
        setCommentValues(commentvalues);
    }

    const RemoveImages = async (index, files, ReportManualFileId) => {

        if (ReportManualFileId !== 0) {
            getFiles[index].IsDeleted = 1;
        } else {
            filevals.splice(index, 1);
        }
        setFiles(filevals);
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }


    function RemoveComments(Comments) {
        let Param = {
            Operation: TestingComment,
            Id: Comments.commentsID,
        };
        setID({ showPopupDelete: true, Params: Param });
    }
    function delTestCallback(value) {
        if (value) {
            setIDTestTask({ showPopupDelete: false, Params: {} })
            Input.Finalview = 0;
            setInputFields(Input);
            let StartExDate = null;
            let EndExDate = null;
            if (moment(stateExDate[0].startDate).isValid()) {
                StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateExDate[0].endDate).isValid()) {
                EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();

            // Create a moment object from the current date and add one year
            const futureDate = moment(currentDate).add(1, 'year');

            // Format the future date
            const formattedDate = futureDate.format('MM/DD/YYYY');

            let params = {
                IsProduction: IsProduction,
                Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
                , TaskDetailsName: Taskcallback
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TestStartDate: '01/01/1970',
                TestEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
            }
            dispatch(GetTestDetailsReportList(params))
        }
        else {
            setIDTestTask({ showPopupDelete: false, Params: {} })
            Input.Finalview = 0;
            setInputFields(Input);
        }
    }
    function delCallback(value) {
        if (value) {
            setCommentpopup(false);
            let StartExDate = null;
            let EndExDate = null;
            if (moment(stateExDate[0].startDate).isValid()) {
                StartExDate = moment(stateExDate[0].startDate).format('DD/MM/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateExDate[0].endDate).isValid()) {
                EndExDate = moment(stateExDate[0].endDate).format('DD/MM/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('DD/MM/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('DD/MM/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('DD/MM/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('DD/MM/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('DD/MM/YYYY');
            let params = {
                IsProduction: IsProduction,
                Operation: (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 1 : Taskcallback === 'Strike off Test' ? 2 : 3
                , TaskDetailsName: Taskcallback
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TestStartDate: '01/01/1970',
                TestEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
            }
            dispatch(GetTestDetailsReportList(params))
        }
        else {
            setCommentpopup(false);
        }
    }

    const handleAllOnSelect = (event, FieldName, page, total) => {

        let Pagecount = parseInt(page);
        if (event.target.checked) {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = true;
                })
                setIsAllSelected(true);
            }
            dispatch(UpdateTestCommentsreportViewAllCheckBox(true));
        } else {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = false;
                })
                setIsAllSelected(false);
            }
            dispatch(UpdateTestCommentsreportViewAllCheckBox(false));
        }
    }

    const handleOnSelect = (event, FieldName, purchaseOrderSkuID) => {

        let isChecked = false;
        let indexes = comments.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateTestCommentsreportViewCheckBox(params));
    }

    const handleShow = () => setHideShow(true);

    const handleCloseHideShow = () => setHideShow(false);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };
    comments.map((Element) => {
        Element.purchaseOrderSkuID = Element.purchaseOrderSkuID.toString();
        if (Element.tnaid) {
            Element.tnaid = Element.tnaid.toString();
        }
        if (Element.foidpono === null || Element.foidpono === '') {
            Element.foidpono = '';
        }
    })
    const commentsData = useMemo(() => {
        
        //let computedComments = comments;
        debugger;
        let computedComments = comments.filter(o => 
            BuyerBrandDetails.some(({  brandId, isAllInfo, isMapped }) =>
              o.brandID === brandId && isAllInfo === 1 && isMapped === 0
            )
          );
          
        // let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        // // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        // //     o.brandID === brandId && isAllInfo === 0 && isMapped === 1 && o.cubeEmployeeIdList != undefined
        // //     && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));


        if (comments != undefined && comments.length > 0) {
            if (getBuyersearch !== null && getBuyersearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            if (getSeasonsearch !== null && getSeasonsearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            if (getBrandsearch !== null && getBrandsearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            if (getQuartersearch !== null && getQuartersearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            if (getSuppliersearch !== null && getSuppliersearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            // if (getIdPosearch !== null && getIdPosearch != "") {
            //     computedComments = computedComments.filter(comment =>
            //         comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            //     );
            //     setTotalItems(computedComments.length);
            // }
            if (getIdPosearch !== null && getIdPosearch !== "") {
                computedComments = computedComments.filter(comment =>
                    comment.idpono && comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }
            if (getStyleNamesearch !== null && getStyleNamesearch != "") {
                computedComments = computedComments.filter(comment =>
                    comment.styleName != null ? comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase()) : ""
                );
                setTotalItems(computedComments.length);
            }

            if (getSkuSearch !== null && getSkuSearch !== "") {

                computedComments = computedComments.filter(comment =>
                    comment.skuName && comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
                );
                setTotalItems(computedComments.length);
            }

            if (getOrderStatussearch) {
                if (getOrderStatussearch.value === 1) {
                    computedComments = computedComments.filter(comment =>
                        comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                        // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                    );
                } else {
                    computedComments = computedComments.filter(comment =>
                        parseInt(comment.orderStatus) === getOrderStatussearch.value
                    );
                }

                setTotalItems(computedComments.length);
            }
            // if (getReportStatussearch) {
            //     debugger
            //     if (getReportStatussearch.value === 1) {
            //         computedComments = computedComments.filter(comment =>
            //             comment.reportApprovalFilter === null || comment.ReportApprovalFilter === "" || comment.ReportApprovalFilter === undefined
            //             // || parseInt(comment.orderStatus) === getOrderStatussearch.value
            //         );
            //     } else {
            //         computedComments = computedComments.filter(comment =>
            //             parseInt(comment.reportApprovalFilter) === getReportStatussearch.value
            //         );
            //     }

            //     setTotalItems(computedComments.length);
            // }
            if (sorting.field) {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
            }
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage !== 1) {
                setCurrentPage(currentPage - 1);
            }
            ;
            setTotalItems(computedComments.length);
            return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        }
    }, [comments, inputFields, getBuyersearch, getSeasonsearch, getBrandsearch, getQuartersearch, getSuppliersearch,
        getIdPosearch, getStyleNamesearch, getSkuSearch, getReportStatussearch, getOrderStatussearch, currentPage, sorting,
        ITEMS_PER_PAGE
    ])

    return (
        <>
            <div className="page-body">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="">
                                <div className="widget">
                                    <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-xs-12">
                                                <div className="widget">
                                                    <div className="widget-body no-padding">
                                                        <div className="widget-main ">
                                                            <div className="panel-group accordion" id="accordion">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading ">
                                                                        <h4 className="panel-title">
                                                                            <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                                Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                            </a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id="collapseOne" className="panel-collapse collapse in">
                                                                        <div className="panel-body border-red">
                                                                            <div className="row">
                                                                                <div className="col-md-12">

                                                                                    <div className="col-md-2">
                                                                                        <label>Buyer</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="StyleList form-control"
                                                                                                title={'Buyer'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Buyer'}
                                                                                                onChange={e => handleChange(e, 'Buyer')}
                                                                                                value={getBuyersearch}
                                                                                            />

                                                                                        </span>
                                                                                    </div>


                                                                                    <div className="col-md-2">
                                                                                        <label>Season</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" StyleList form-control"
                                                                                                title={'Season'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Season'}
                                                                                                onChange={e => handleChange(e, 'Season')}
                                                                                                value={getSeasonsearch}
                                                                                            />

                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label>Brand</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" StyleList form-control"
                                                                                                title={'Brand'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Brand'}
                                                                                                onChange={e => handleChange(e, 'Brand')}
                                                                                                value={getBrandsearch}
                                                                                            />
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label>Quarter</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="StyleList form-control"
                                                                                                title={'Quarter'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Quarter'}
                                                                                                onChange={e => handleChange(e, 'Quarter')}
                                                                                                value={getQuartersearch}
                                                                                            />
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label>Supplier</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" StyleList form-control"
                                                                                                title={'Supplier'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Supplier'}
                                                                                                onChange={e => handleChange(e, 'Supplier')}
                                                                                                value={getSuppliersearch}
                                                                                            />
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label>PO/ID No.</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className=" StyleList form-control"
                                                                                                title={'PO/ID No'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'PO/ID No'}
                                                                                                onChange={e => handleChange(e, 'ID/NO')}
                                                                                                value={getIdPosearch}
                                                                                            />

                                                                                        </span>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            &nbsp;
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="col-md-2">
                                                                                        <label>&nbsp;</label>
                                                                                        <label>Style Name</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="StyleList form-control"
                                                                                                title={'Style Name'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'Style Name'}
                                                                                                onChange={e => handleChange(e, 'StyleName')}
                                                                                                value={getStyleNamesearch}
                                                                                            />
                                                                                        </span>

                                                                                    </div>


                                                                                    <div className="col-md-2">
                                                                                        <label>&nbsp;</label>
                                                                                        <label>SKU Name</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="StyleList form-control"
                                                                                                title={'SKU Name'}
                                                                                                maxlength={"50"}
                                                                                                placeholder={'SKU Name'}
                                                                                                onChange={e => handleChange(e, 'SKUName')}
                                                                                                value={getSkuSearch}
                                                                                            />
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="col-md-2" ref={datePickerRefEx}>
                                                                                        <label>Ex-Fac Date</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                value={(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                    ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                                                        moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                onClick={event => handleExDateSelect(event, !stateExDate[0].isShow)}
                                                                                                //onBlur={event => handleDateBlur()}
                                                                                                type="text"
                                                                                                className={'form-control adjustabledatapicker'}
                                                                                                placeholder="Select Date"

                                                                                            />
                                                                                            <i className="fa fa-times" onClick={clearExDateSelect} ></i>
                                                                                        </span>


                                                                                        <span className='input-icon icon-right mb-5'>
                                                                                            <br />
                                                                                            {stateExDate[0].isShow &&
                                                                                                <DateRangePicker
                                                                                                    //onChange={item => setStateExDate([item.selection])}
                                                                                                    onChange={item => DateExfaconchange([item.selection])}
                                                                                                    showSelectionPreview={true}
                                                                                                    moveRangeOnFirstSelection={false}
                                                                                                    months={2}
                                                                                                    ranges={stateExDate}
                                                                                                    showDateDisplay={false}
                                                                                                    direction="vertical"
                                                                                                    className={'StyleDateRangePicker'}

                                                                                                />

                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-2" ref={datePickerEntryDate}>
                                                                                        <label>Test Entry Date Filter</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                                type="text"
                                                                                                className={'form-control adjustabledatapicker'}
                                                                                                placeholder="Select Date"

                                                                                            />
                                                                                            <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                        </span>


                                                                                        <span className='input-icon icon-right mb-5'>
                                                                                            <br />
                                                                                            {stateDate[0].isShow &&
                                                                                                <DateRangePicker
                                                                                                    // onChange={item => setStateDate([item.selection])}
                                                                                                    onChange={item => DateRangechange([item.selection])}
                                                                                                    showSelectionPreview={true}
                                                                                                    moveRangeOnFirstSelection={false}
                                                                                                    months={2}
                                                                                                    ranges={stateDate}
                                                                                                    showDateDisplay={false}
                                                                                                    direction="vertical"
                                                                                                    className={'StyleDateRangePicker'}

                                                                                                />

                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-2" ref={datePickerTnaDate}>
                                                                                        <label>TNA Date Range</label>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input
                                                                                                value={(stateTNADate[0].startDate === null ? '' : moment(stateTNADate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                    ((stateTNADate[0].endDate === null || !moment(stateTNADate[0].endDate).isValid()) ? '' :
                                                                                                        moment(stateTNADate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                onClick={event => handleTNADateSelect(event, !stateTNADate[0].isShow)}
                                                                                                //onBlur={event => handleDateBlur()}
                                                                                                type="text"
                                                                                                className={'form-control adjustabledatapicker'}
                                                                                                placeholder="Select Date"

                                                                                            />
                                                                                            <i className="fa fa-times" onClick={clearTNADateSelect} ></i>
                                                                                        </span>


                                                                                        <span className='input-icon icon-right mb-5'>
                                                                                            <br />
                                                                                            <div className="date-picker-wrapper"></div>
                                                                                            {stateTNADate[0].isShow &&
                                                                                                <DateRangePicker
                                                                                                    // onChange={item => setStateExDate([item.selection])}
                                                                                                    onChange={item => DateTNAchange([item.selection])}
                                                                                                    showSelectionPreview={true}
                                                                                                    moveRangeOnFirstSelection={false}
                                                                                                    months={2}
                                                                                                    ranges={stateTNADate}
                                                                                                    showDateDisplay={false}
                                                                                                    direction="vertical"
                                                                                                    className={'StyleDateRangePicker TNADateRangeStyle'}

                                                                                                />

                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label>Test Filter</label>
                                                                                        <span className='input-icon icon-right' onClick={handleclick}>
                                                                                            <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                                options={getTestStatusOptions}
                                                                                                isClearable={true}
                                                                                                onChange={e => handleChangeOrdStatus(e, 'ReportApprovalFilter')}

                                                                                                value={getReportStatussearch}
                                                                                            ></Reactselect>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            &nbsp;
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="col-md-2">
                                                                                        <label>Order Status</label>
                                                                                        <span className='input-icon icon-right' onClick={handleclick} style={{ zIndex: 5 }}>
                                                                                            <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                                options={orderStatus}
                                                                                                isClearable={true}
                                                                                                onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}
                                                                                                value={getOrderStatussearch}
                                                                                            ></Reactselect>
                                                                                        </span>
                                                                                    </div>
                                                                                    {/* <div className="col-md-8">
                                                                                        &nbsp;
                                                                                    </div> */}
                                                                                    <div className="col-md-2" style={{ marginTop: '28px' }}
                                                                                    >
                                                                                        <span className="input-icon icon-right">
                                                                                            <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                            >
                                                                                                <i className="fa fa-close"> Reset</i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>

                                                                                    {/* <div className="col-md-3">
                                                                            </div> */}
                                                                                    {/* <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="col-md-9">

                                                                                </div>
                                                                                            <span className="input-icon icon-right">
                                                                                                <button type="button" style={{float:"right"}} className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                </button>
                                                                                            </span>

                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row_left10" style={{ width: '100%' }}>
                                    {Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test' ? <div className="card" style={{ float: 'left' }}>
                                        <label className="alert alert-info comment-report-note"><b>Note : </b>Comment was temporarily stopped for Strikeoff Test and Trim Test</label>
                                    </div> : ''}
                                    <div style={{ float: 'right' }}>
                                        <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                                        <label style={{ marginTop: '7px', marginLeft: "8px" }} title={isAllSelected ? "Un Select All" : "Select All"} >
                                            <input type="checkbox" name="IsAllSelected"
                                                checked={isAllSelected ? true : false}
                                                onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                                className="selectallcheckbox" value={isAllSelected ? true : false}
                                            />
                                        </label>
                                        {commentsData != undefined && commentsData.length !== 0 && (
                                            <ReactHTMLTableToExcel
                                                id="test-table-s-button-final"
                                                className="fa fa-file-excel-o  btn btn-success excelcomt"
                                                table={"table-to-xls"}
                                                filename={"TestingDetails"}
                                                sheet="TestingDetails"
                                                buttonText=""
                                            />
                                        )}&nbsp;
                                    </div>
                                </div>
                                <div style={{ overflow: 'auto', marginTop: '1%' }}>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer table-responsive" data-role="table" data-mode="columntoggle" id="table-to-xls" style={{ width: '110vw' }}>
                                        <TableHeader headers={Taskcallback === 'Strike off Test' ? tableHeaderStrioff : Taskcallback === 'Trims Test' ? tableHeaderTrim : tableHeader} onSorting={(field, order) => setSorting({ field, order })} />
                                        {<tbody>
                                            {
                                                commentsData != undefined && commentsData.map((comment, index) => (
                                                    <Fragment key={index}>
                                                        <>
                                                            {Taskcallback === 'Strike off Test' ?
                                                                <tr>

                                                                    <td style={{ width: '25px' }}>{index + 1}</td>
                                                                    {checkboxStates.PlanEndDate && <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        // className="stickyone "
                                                                        title={comment.planEndDate}>
                                                                        {comment.planEndDate}
                                                                    </td>}


                                                                    {checkboxStates.TNAID && <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.tnaid}>
                                                                        {comment.tnaid}
                                                                    </td>}

                                                                    {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}
                                                                    {checkboxStates.StrikeoffName && <td className="cut_textCommentsreport " title={comment.strikeoffTrimName}>{comment.strikeoffTrimName}</td>}
                                                                    {checkboxStates.Strieoffcode && <td className="cut_textCommentsreport " title={comment.strikeoffTrimCode}>{comment.strikeoffTrimCode}</td>}

                                                                    {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                                        {comment.idpono}
                                                                    </td>}
                                                                    {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                                        {/* {comment.foidpono} */}

                                                                        {comment.foidpono === "" ? "-" : comment.foidpono}
                                                                    </td>}
                                                                    {checkboxStates.Strkeimage && <td className="cut_textCommentsreport " title={comment.strikeoffTrimLogo}>
                                                                        <img src={comment.strikeoffTrimLogo === '' ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffTrimLogo}
                                                                            alt="Picture" style={{ marginBottom: '5px', height: '30px', width: '40%', border: "1px solid #d5d5d5" }}
                                                                        // onClick={
                                                                        //     event => ViewFile1(event, Input.StrikoffTrimImagepath)}
                                                                        />
                                                                    </td>}
                                                                    {/* {checkboxStates.Strieoffcode && <td className="text-center"
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                >{
                                                                        < SimpleReactLightbox >
                                                                            <SRLWrapper options={options}>
                                                                                {
                                                                                    < img className="width_manual"
                                                                                        src={comment.strikeoffTrimLogo === '' ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffTrimLogo}
                                                                                    // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                    />

                                                                                }

                                                                            </SRLWrapper>
                                                                        </SimpleReactLightbox>
                                                                        // comment.skuImage
                                                                        // comment.skuImagePath
                                                                    }</td>} */}
                                                                    {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                                    {/* {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.supplierName}> - </td>} */}

                                                                    {/* <td>Test Status</td> */}
                                                                    {/* <td className="text-danger">Approval hfghfghfg</td> */}
                                                                    {checkboxStates.inspectionStatus && <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                                        <table style={{ margin: "auto" }}>
                                                                            <tr>
                                                                                <td>
                                                                                    <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.draftCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td >
                                                                                    <span title='Pass' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.passCount}
                                                                                    </span>

                                                                                </td>
                                                                                <td>
                                                                                    <span title='Fail' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.failCount}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    }</td>}
                                                                    {checkboxStates.approvalStatus && <td className="cut_textCommentsreport " title={comment.approvalStatus}>{
                                                                        // comment.approvalStatus
                                                                        <table style={{ margin: "auto" }}>
                                                                            <tr>
                                                                                <td>
                                                                                    <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.pendingCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td >
                                                                                    <span title='Approval' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.approvalCount}
                                                                                    </span>

                                                                                </td>
                                                                                <td>
                                                                                    <span title='Commercial Approval' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.commercialApprovalCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <span title='Rejected' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.rejectedCount}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    }</td>}

                                                                    {/* {
                                                                    checkboxStates.orderStatus && <td className={(comment.orderStatus == '1' || comment.orderStatus == '') ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                                        comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                            'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                                    'Yet to Start'}>
                                                                        {(comment.orderStatus == '1' || comment.orderStatus == '') ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                            comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                                'Yet to Start'
                                                                        }
                                                                    </td>
                                                                } */}

                                                                    {/* {
                                                                    checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                                        comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                                    }</td>
                                                                }
                                                                {
                                                                    checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                                        comment.currentComments === "" ? "-" : comment.currentComments
                                                                    }</td>
                                                                }
                                                                {
                                                                    checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                                        comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                                    }</td>
                                                                } */}
                                                                    <td className="text-center fixed-column-header-CommentReport" style={{display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''}}>
                                                                        {/* <span style={{ padding: '2px' }}>
                                                                        <span className="btn" title="Comments" onClick={event => { OpenCommentPopup(event, comment.tnaTestingInspectionID, comment.tnaid, comment.supplierID, comment.purchaseOrderSkuID) }}
                                                                            style={{
                                                                                background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                                color: 'white',
                                                                                padding: '3px 6px',
                                                                                borderRadius: '1px',
                                                                                border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-comment"></i>
                                                                        </span>
                                                                    </span>*/}
                                                                        <button className="btn btn-xs btn-primary" title="Comment" 
                                                                            style={{
                                                                                background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                                color: 'white',
                                                                                padding: '3px 6px',
                                                                                borderRadius: '1px',
                                                                                border: comment.currentComments === '' ? '#427fed' : 'red',
                                                                                display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none'
                                                                            }}
                                                                            onClick={event => { OpenCommentPopup(event, comment.tnaTestingInspectionID, comment.tnaid, comment.supplierID, comment.purchaseOrderSkuID) }}><i className="fa fa-comment"></i></button>
                                                                        &nbsp;
                                                                        < span style={{ padding: '2px', display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                            <button className="btn btn-xs btn-Report" type="button" title="Report Create" onClick={() => handleExFactoryFormOpen(comment, 1, comment.taskDetailsID)}>
                                                                                <i class="fa fa-external-link"></i>
                                                                            </button>
                                                                        </span>&nbsp;
                                                                        {
                                                                            (Taskcallback === "FABRIC TEST" || Taskcallback === "YARN TEST" || Taskcallback === "GARMENT TEST") && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px' ,display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 1)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }

                                                                        {
                                                                            Taskcallback === 'Strike off Test' && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 2)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }
                                                                        {
                                                                            Taskcallback === 'Trims Test' && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px' ,display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none'  }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 3)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }
                                                                        <label style={{ marginTop: '8px',display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none'  }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                onChange={event => (handleOnSelect(event, 'IsSelected', comment,))}
                                                                                value={comment.isSelected ? true : false}
                                                                                checked={comment.isSelected ? true : false}
                                                                                className="clearOpacity textdesign" />
                                                                            <span className="text"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr> :
                                                                Taskcallback === 'Trims Test' ? <tr>
                                                                    <td style={{ width: '25px' }}>{index + 1}</td>
                                                                    {checkboxStates.PlanEndDate && <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        // className="stickyone "
                                                                        title={comment.planEndDate}>
                                                                        {comment.planEndDate}
                                                                    </td>}


                                                                    {checkboxStates.TNAID && <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.tnaid}>
                                                                        {comment.tnaid}
                                                                    </td>}

                                                                    {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}
                                                                    {checkboxStates.StrikeoffName && <td className="cut_textCommentsreport " title={comment.strikeoffTrimName}>{comment.strikeoffTrimName}</td>}
                                                                    {checkboxStates.Strieoffcode && <td className="cut_textCommentsreport " title={comment.strikeoffTrimCode}>{comment.strikeoffTrimCode}
                                                                    </td>}

                                                                    {/* {checkboxStates.Strieoffcode && <td className="text-center"
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                >{
                                                                        < SimpleReactLightbox >
                                                                            <SRLWrapper options={options}>
                                                                                {
                                                                                    < img className="width_manual"
                                                                                        src={(comment.strikeoffTrimLogo === '' || comment.strikeoffTrimLogo === null) ? "-" : window.$APIBaseURL + "Images/Style/Trim/" + comment.strikeoffTrimLogo}
                                                                                    // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                    />

                                                                                }

                                                                            </SRLWrapper>
                                                                        </SimpleReactLightbox>
                                                                        // comment.skuImage
                                                                        // comment.skuImagePath
                                                                    }</td>} */}
                                                                    {/* {checkboxStates.strikeoffTrimLogo && <td className="cut_textCommentsreport " title={comment.strikeoffTrimLogo}>{comment.strikeoffTrimLogo}</td>} */}
                                                                    {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                                        {comment.idpono}
                                                                    </td>}
                                                                    {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                                        {/* {comment.foidpono} */}

                                                                        {comment.foidpono === "" ? "-" : comment.foidpono}
                                                                    </td>}
                                                                    {checkboxStates.Strkeimage && <td className="cut_textCommentsreport " title={comment.strikeoffTrimLogo}>
                                                                        <img src={(comment.strikeoffTrimLogo === '' || comment.strikeoffTrimLogo === null) ? "-" : window.$APIBaseURL + "Images/Style/Trim/" + comment.strikeoffTrimLogo}
                                                                            style={{ marginBottom: '5px', height: '30px', width: '40%', border: "1px solid #d5d5d5" }}
                                                                        // onClick={
                                                                        //     event => ViewFile1(event, Input.StrikoffTrimImagepath)}
                                                                        />
                                                                    </td>}
                                                                    {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                                    {/* {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.supplierName}> - </td>} */}


                                                                    {/* <td>Test Status</td>
                                                                    <td className="text-danger">Approval Status</td> */}
                                                                    {checkboxStates.inspectionStatus && <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                                        <table style={{ margin: "auto" }}>
                                                                            <tr>
                                                                                <td>
                                                                                    <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.draftCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td >
                                                                                    <span title='Pass' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.passCount}
                                                                                    </span>

                                                                                </td>
                                                                                <td>
                                                                                    <span title='Fail' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                        {comment.failCount}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    }</td>}
                                                                    {checkboxStates.approvalStatus && <td className="cut_textCommentsreport " title={comment.approvalStatus}>{
                                                                        // comment.approvalStatus
                                                                        <table style={{ margin: "auto" }}>
                                                                            <tr>
                                                                                <td>
                                                                                    <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.pendingCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td >
                                                                                    <span title='Approval' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.approvalCount}
                                                                                    </span>

                                                                                </td>
                                                                                <td>
                                                                                    <span title='Commercial Approval' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.commercialApprovalCount}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <span title='Rejected' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                        {comment.rejectedCount}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    }</td>}


                                                                    {/* {
                                                                    checkboxStates.orderStatus && <td className={(comment.orderStatus == '1' || comment.orderStatus == '') ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                                        comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                            'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                                    'Yet to Start'}>
                                                                        {(comment.orderStatus == '1' || comment.orderStatus == '') ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                            comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :

                                                                            'Yet to Start'
                                                                        }
                                                                    </td>
                                                                } */}

                                                                    {/* {
                                                                    checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                                        comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                                    }</td>
                                                                }
                                                                {
                                                                    checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                                        comment.currentComments === "" ? "-" : comment.currentComments
                                                                    }</td>
                                                                }
                                                                {
                                                                    checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                                        comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                                    }</td>
                                                                } */}
                                                                    <td className="text-center fixed-column-header-CommentReport" style={{display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''}}> 
                                                                        <button className="btn btn-xs btn-primary" title="Comment" style={{
                                                                            background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                            color: 'white',
                                                                            padding: '3px 6px',
                                                                            borderRadius: '1px',
                                                                            border: comment.currentComments === '' ? '#427fed' : 'red',
                                                                            display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none'
                                                                        }} onClick={event => { OpenCommentPopup(event, comment.tnaTestingInspectionID, comment.tnaid, comment.supplierID, comment.purchaseOrderSkuID) }}><i className="fa fa-comment"></i></button>&nbsp;
                                                                        <span style={{ padding: '2px',display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none' }}>
                                                                            <span className="btn" title="Comments" onClick={event => { OpenCommentPopup(event, comment.tnaTestingInspectionID, comment.tnaid, comment.supplierID, comment.purchaseOrderSkuID) }}
                                                                            >
                                                                                <i className="fa fa-comment"></i>
                                                                            </span>
                                                                        </span>
                                                                        < span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                            <button className="btn btn-xs btn-Report" type="button" title="Report Create" onClick={() => handleExFactoryFormOpen(comment, 1, comment.taskDetailsID)}>
                                                                                <i class="fa fa-external-link"></i>
                                                                            </button>
                                                                        </span>&nbsp;
                                                                        {
                                                                            (Taskcallback === "FABRIC TEST" || Taskcallback === "YARN TEST" || Taskcallback === "GARMENT TEST") && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 1)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }

                                                                        {
                                                                            Taskcallback === 'Strike off Test' && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 2)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }
                                                                        {
                                                                            Taskcallback === 'Trims Test' && comment.isDraft === 1 ?
                                                                                <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                    <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                        onClick={() => handleFinalInspecViewOpen(comment, 1, 3)}
                                                                                    >
                                                                                        <i class="fa fa-check-square"></i>
                                                                                    </button>

                                                                                </span>
                                                                                : ''

                                                                        }
                                                                        <label style={{ marginTop: '8px',display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none' }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                onChange={event => (handleOnSelect(event, 'IsSelected', comment.purchaseOrderSkuID,))}
                                                                                value={comment.isSelected ? true : false}
                                                                                checked={comment.isSelected ? true : false}
                                                                                className="clearOpacity textdesign" />
                                                                            <span className="text"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr> :
                                                                    <tr>
                                                                        <td style={{ width: '25px' }}>{index + 1}</td>
                                                                        {checkboxStates.PlanEndDate && <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            // className="stickyone "
                                                                            title={comment.planEndDate}>
                                                                            {comment.planEndDate}
                                                                        </td>}


                                                                        {checkboxStates.skuID && <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.purchaseOrderSkuID}>
                                                                            {comment.purchaseOrderSkuID}
                                                                        </td>}

                                                                        {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}

                                                                        {checkboxStates.skuName && < td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            // className="stickyseven "
                                                                            title={comment.skuName}> {
                                                                                comment.skuName
                                                                            }</td>}

                                                                        {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                                            {comment.idpono}
                                                                        </td>}

                                                                        {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                                            {/* {comment.foidpono} */}

                                                                            {comment.foidpono === "" ? "-" : comment.foidpono}
                                                                        </td>}



                                                                        {checkboxStates.skuImg && <td className="text-center"
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        >{
                                                                                < SimpleReactLightbox >
                                                                                    <SRLWrapper options={options}>
                                                                                        {
                                                                                            < img className="width_manual"
                                                                                                src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath}
                                                                                            // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                            />

                                                                                        }

                                                                                    </SRLWrapper>
                                                                                </SimpleReactLightbox>
                                                                                // comment.skuImage
                                                                                // comment.skuImagePath
                                                                            }</td>}
                                                                        {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                                        {/* {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.supplierName}> - </td>} */}



                                                                        {/* {checkboxStates.styleNo && <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>} */}

                                                                        {/* {checkboxStates.size && <td className="cut_textCommentsreport " title={comment.size}>{
                                                    comment.size
                                                }</td>} */}
                                                                        {checkboxStates.orderQty &&
                                                                            <td className="cut_textCommentsreport " title={comment.quantity} >
                                                                                <span>{comment.quantity}</span>
                                                                                <span>
                                                                                    <span className="btn btn-success" title="View Sizes" style={{ float: "right" }}
                                                                                        onClick={() => GetSizeandQty(comment.purchaseOrderSkuID, comment.quantity)}
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </span>
                                                                                </span>


                                                                            </td>}

                                                                        {
                                                                            checkboxStates.exFac && <td className="cut_textCommentsreport " title={comment.exFacDate}>{
                                                                                comment.exFacDate
                                                                            }</td>
                                                                        }
                                                                        {checkboxStates.inspectionStatus && <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                                            <table style={{ margin: "auto" }}>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                            {comment.draftCount}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td >
                                                                                        <span title='Pass' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                            {comment.passCount}
                                                                                        </span>

                                                                                    </td>
                                                                                    <td>
                                                                                        <span title='Fail' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                                            {comment.failCount}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        }</td>}
                                                                        {checkboxStates.approvalStatus && <td className="cut_textCommentsreport " title={comment.approvalStatus}>{
                                                                            // comment.approvalStatus
                                                                            <table style={{ margin: "auto" }}>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                            {comment.pendingCount}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td >
                                                                                        <span title='Approval' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                            {comment.approvalCount}
                                                                                        </span>

                                                                                    </td>
                                                                                    <td>
                                                                                        <span title='Commercial Approval' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                            {comment.commercialApprovalCount}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span title='Rejected' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                            {comment.rejectedCount}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        }</td>}


                                                                        {/* {
                                                                        checkboxStates.orderStatus && <td className={(comment.orderStatus == '1' || comment.orderStatus == '') ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                                'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                                    comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                                        'Yet to Start'}>
                                                                            {(comment.orderStatus == '1' || comment.orderStatus == '') ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                                comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                                    'Yet to Start'
                                                                            }
                                                                        </td>
                                                                    } */}

                                                                        {/* {
                                                                        checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                                        }</td>
                                                                    }
                                                                    {
                                                                        checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                                            comment.currentComments === "" ? "-" : comment.currentComments
                                                                        }</td>
                                                                    }
                                                                    {
                                                                        checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                                        }</td>
                                                                    } */}
                                                                        <td className="text-center fixed-column-header-CommentReport" style={{display: activeMenu && activeMenu[0].isAdd === 0 && testingInfo && 
             testingInfo.isAdd === 0 ? 'none' : ''}}>
                                                                            <span style={{ padding: '2px' }}>
                                                                                <span className="btn" title="Comments" onClick={event => { OpenCommentPopup(event, comment.tnaTestingInspectionID, comment.tnaid, comment.supplierID, comment.purchaseOrderSkuID, 'Comments') }}
                                                                                    style={{
                                                                                        background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                                        color: 'white',
                                                                                        padding: '3px 6px',
                                                                                        borderRadius: '1px',
                                                                                        border: comment.currentComments === '' ? '#427fed' : 'red',
                                                                                        display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none'
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-comment"></i>
                                                                                </span>
                                                                            </span>&nbsp;
                                                                            < span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                <button className="btn btn-xs btn-Report" type="button" title="Report Create" onClick={() => handleExFactoryFormOpen(comment, 1, comment.taskDetailsID)}>
                                                                                    <i class="fa fa-external-link"></i>
                                                                                </button>
                                                                            </span>&nbsp;
                                                                            {
                                                                                (Taskcallback === "FABRIC TEST" || Taskcallback === "YARN TEST" || Taskcallback === "GARMENT TEST") && comment.isDraft === 1 ?
                                                                                    <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                        <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                            onClick={() => handleFinalInspecViewOpen(comment, 1, 1)}
                                                                                        >
                                                                                            <i class="fa fa-check-square"></i>
                                                                                        </button>

                                                                                    </span>
                                                                                    : ''

                                                                            }

                                                                            {
                                                                                Taskcallback === 'Strike off Test' && comment.isDraft === 1 ?
                                                                                    <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                        <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                            onClick={() => handleFinalInspecViewOpen(comment, 1, 2)}
                                                                                        >
                                                                                            <i class="fa fa-check-square"></i>
                                                                                        </button>

                                                                                    </span>
                                                                                    : ''

                                                                            }
                                                                            {
                                                                                Taskcallback === 'Trims Test' && comment.isDraft === 1 ?
                                                                                    <span style={{ padding: '2px',display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                                                                        <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                                            onClick={() => handleFinalInspecViewOpen(comment, 1, 3)}
                                                                                        >
                                                                                            <i class="fa fa-check-square"></i>
                                                                                        </button>

                                                                                    </span>
                                                                                    : ''

                                                                            }
                                                                            <label style={{ marginTop: '8px',display: testingInfo && testingInfo.isAdd === 1 ? '' : 'none' }}>
                                                                                <input type="checkbox" name="IsSelected"
                                                                                    onChange={event => (handleOnSelect(event, 'IsSelected', comment.purchaseOrderSkuID,))}
                                                                                    value={comment.isSelected ? true : false}
                                                                                    checked={comment.isSelected ? true : false}
                                                                                    className="clearOpacity textdesign" />
                                                                                <span className="text"></span>
                                                                            </label>
                                                                        </td>
                                                                    </tr>}
                                                            {
                                                                Input.Finalview === 1 ?
                                                                    <Modal show={(Input.Finalview === 1)}
                                                                        //  size="lg" 
                                                                        dialogClassName="keyinfowidth"
                                                                        dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingView()} >
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                {`${Taskcallback === 'FABRIC TEST' ? 'Fabric Test' :
                                                                                    Taskcallback === 'GARMENT TEST' ? 'Garment Test' : Taskcallback === 'YARN TEST' ? 'Yarn Test' :
                                                                                        Taskcallback === 'Strike off Test' ? 'Strike Off Test' : 'Trims Test'} List`}
                                                                            </Modal.Title>

                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            {
                                                                                getTestingheaderData.length !== 0 && getTestingheaderData.map((item) => {
                                                                                    return (
                                                                                        <>
                                                                                            {/* <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('MM/DD/YYYY')}</h5> */}

                                                                                            <div className="col-lg-12">
                                                                                                <div className="row style-row no_padding">
                                                                                                    <div className="col-lg-11 style-itemRe">
                                                                                                        {
                                                                                                            Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                                                <div className="title-bg keyinfodata">
                                                                                                                    <label><b>SKU ID</b></label><br />
                                                                                                                    <span >{item.skuID}</span>
                                                                                                                </div> : ''
                                                                                                        }
                                                                                                        {
                                                                                                            (Taskcallback === 'Trims Test' || Taskcallback === 'Strike off Test') ?
                                                                                                                <div className="title-bg keyinfodata">
                                                                                                                    <label><b>TNA ID</b></label><br />
                                                                                                                    <span >{item.tnaid}</span>
                                                                                                                </div> : ''

                                                                                                        }
                                                                                                        {
                                                                                                            Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>PO/ID No.</b></label><br />
                                                                                                                    <span >{item.idno}</span>

                                                                                                                </div> : ''
                                                                                                        }
                                                                                                        {(getTNAPurchaseOrderID.buyerName === 'Focus International') && (Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ?

                                                                                                            <>
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>Ref No.</b></label><br />
                                                                                                                    <span >{item.pono}</span>

                                                                                                                </div>
                                                                                                            </> : ''}
                                                                                                        <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                            <label htmlFor="BuyerInfo"><b>Style Name</b></label><br />
                                                                                                            <span >{item.styleName}</span>

                                                                                                        </div>
                                                                                                        <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                            <label htmlFor="BuyerInfo"><b>Style No.</b></label><br />
                                                                                                            <span >{item.styleNo}</span>

                                                                                                        </div>

                                                                                                        <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                            <label htmlFor="BuyerInfo"><b>SKU Name</b></label><br />
                                                                                                            <span >{item.skuName}</span>

                                                                                                        </div>
                                                                                                        {
                                                                                                            Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>PO Qty</b></label><br />
                                                                                                                    <span >{item.poQty}</span>

                                                                                                                </div> : ''
                                                                                                        }

                                                                                                        <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                            <label htmlFor="BuyerInfo"><b>Supplier</b></label><br />
                                                                                                            <span >{item.supplierName}</span>

                                                                                                        </div>
                                                                                                        {
                                                                                                            Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>Ex-Fac</b></label><br />
                                                                                                                    <span >{moment(item.exFacdate).format('DD/MM/YYYY')}</span>

                                                                                                                </div> : ''}
                                                                                                        {Taskcallback === 'Trims Test' ?
                                                                                                            <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                <label htmlFor="BuyerInfo"><b>Trim Code</b></label><br />
                                                                                                                <span >{item.trimCode}</span>

                                                                                                            </div> : ''

                                                                                                        }
                                                                                                        {Taskcallback === 'Trims Test' ?
                                                                                                            <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                <label htmlFor="BuyerInfo"><b>Trim Name</b></label><br />
                                                                                                                <span >{item.trimName}</span>

                                                                                                            </div> : ''

                                                                                                        }
                                                                                                        {
                                                                                                            Taskcallback === 'Strike off Test' ?
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>Strike Off Name</b></label><br />
                                                                                                                    <span >{item.strikeOffName}</span>

                                                                                                                </div> : ''
                                                                                                        }
                                                                                                        {
                                                                                                            Taskcallback === 'Strike off Test' ?
                                                                                                                <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                    <label htmlFor="BuyerInfo"><b>Strike Off Code</b></label><br />
                                                                                                                    <span >{item.strikeOffcode}</span>

                                                                                                                </div> : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        Taskcallback === 'Strike off Test' ?
                                                                                                        <div className="col-lg-1" >
                                                                                                        <td className="cut_textCommentsreport " title={comment.strikeoffTrimLogo} >
                                                                                                            <img src={comment.strikeoffTrimLogo === '' ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + getTestInspectionInfo.Logopath}
                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '45px', width: '85%', border: "1px solid #d5d5d5" }}
                                                                                                            // onClick={
                                                                                                            //     event => ViewFile1(event, Input.StrikoffTrimImagepath)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </div> :''
                                                                                                    }
                                                                                                     {
                                                                                                        Taskcallback === 'Trims Test' ?
                                                                                                        <div className="col-lg-1" >
                                                                                                        <td className="cut_textCommentsreport " title={comment.strikeoffTrimLogo} >
                                                                                                            <img src={comment.strikeoffTrimLogo === '' ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + getTestInspectionInfo.Logopath}
                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '45px', width: '85%', border: "1px solid #d5d5d5" }}
                                                                                                            // onClick={
                                                                                                            //     event => ViewFile1(event, Input.StrikoffTrimImagepath)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </div> :''
                                                                                                    }
                                                                                                    
                                                                                                </div>

                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            &nbsp;
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {/* <th>Report No. & Date</th> */}
                                                                                        <th>Entry No. & Created Date</th>
                                                                                        {/* <th>Test Type</th> */}
                                                                                        {Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                            <th>Test Order Qty</th> : ''
                                                                                        }

                                                                                        <th>Created By</th>
                                                                                        {/* <th>Report Conclusion</th> */}
                                                                                        <th>Test Result</th>
                                                                                        <th>Approval Result</th>
                                                                                        {/* <th>Factory</th>
                                                                                        <th>Product Qty</th> */}
                                                                                        <th>Action</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        getnewTestingReportlist.length !== 0 && getnewTestingReportlist.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td >
                                                                                                            {item.reportNo}
                                                                                                        </td>

                                                                                                        {/* <td>
                                                                                                            {item.testType}
                                                                                                        </td> */}
                                                                                                        {/* <td>
                                                                                                            {/* {item.offerQty} */}
                                                                                                        {/* </td> */}
                                                                                                        {Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST' ?
                                                                                                            <td>
                                                                                                                {item.testOrderQty}
                                                                                                            </td> : ''}
                                                                                                        <td>
                                                                                                            {item.createdBy}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {item.reportConclusion}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {item.approvalResult}
                                                                                                        </td>
                                                                                                        <td style={{ width: "18%" }}>
                                                                                                            <button type="button"
                                                                                                                className="btn btn-xs btn-warning" title='Approval'
                                                                                                            // className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                                                            //</td>onClick={() => GetComments(comment, 'Comments', comment.approvalResult)}
                                                                                                            >
                                                                                                                <i className="fa fa-check"></i>
                                                                                                            </button>
                                                                                                            &nbsp;
                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'ViewInspection')}>
                                                                                                                <i className="fa fa-eye "></i>
                                                                                                            </button>
                                                                                                            &nbsp;
                                                                                                            {item.approvalResult !== "Approved" && (<button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'EditInspection')}>
                                                                                                                <i className="fa fa-edit"></i>
                                                                                                            </button>)}
                                                                                                            &nbsp;
                                                                                                            {getnewTestingReportlist.length === (index + 1) && item.approvalResult !== "Approved" && (
                                                                                                                <button type="button" className="btn btn-xs btn-danger" title="Delete FinalList"
                                                                                                                    onClick={() => handleDeleteTestinspection(item, 'Delete')}>
                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                </button>
                                                                                                            )}

                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </>

                                                                                            )

                                                                                        })
                                                                                    }
                                                                                </tbody>

                                                                            </table>


                                                                        </Modal.Body>
                                                                    </Modal>
                                                                    : ""

                                                            }
                                                            {
                                                                <Modal show={getIsAddFinalInspection === true} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseInspectionConfirmationPopup()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            CONFIRM INSPECTION TYPE
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row">
                                                                            <div className="col-md-6 btn-action-center">
                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateTestInspection(1, comment)}>Internal</button>
                                                                            </div>
                                                                            <div className="col-md-6 btn-action-center">
                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateTestInspection(2, comment)}>External</button>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            }
                                                            {
                                                                Input.IsTestinsectionFactory === 1 ?
                                                                    <Modal show={Input.IsTestinsectionFactory === 1} dialogClassName="CreatePopup" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingTaskFormPopup()}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                {/* ADD &nbsp;{Taskcallback} */}
                                                                                Add&nbsp;{`${Taskcallback === 'FABRIC TEST' ? 'Fabric Test' :
                                                                                    Taskcallback === 'GARMENT TEST' ? 'Garment Test' : Taskcallback === 'YARN TEST' ? 'Yarn Test' :
                                                                                        Taskcallback === 'Strike off Test' ? 'Strike Off Test' : 'Trims Test'}`}&nbsp;
                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                <li className="active">
                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForTestingInspection('AddInspection')}>
                                                                                        <h5>New Testing</h5>
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForTestingInspection('ReInspection')}>
                                                                                        <h5>Re Testing </h5>
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                            <div className="tab-content">
                                                                                {getTestingReportPageView === 'AddInspection' ? <>
                                                                                    {(Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST') ? <NewTesting props={props} QCfinalInspectionList={getAddTestInspectionListParam} Taskname={Taskcallback}
                                                                                        QCFinalInspectionCallback={QCTestingCallback} /> : <TestingPopup props={props} QCfinalInspectionList={getAddTestInspectionListParam} Taskname={Taskcallback}
                                                                                            QCFinalInspectionCallback={QCTestingCallback} />}
                                                                                </> :
                                                                                    <><ReTesting props={props}
                                                                                        Reinspectionparams={getAddTestInspectionListParam}
                                                                                        QCFinalInspectionCallback={QCTestingCallback} Taskname={Taskcallback}
                                                                                    /></>
                                                                                }
                                                                            </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button variant="success" onClick={() => AddTestingExFactoryForm()}>
                                                                                ADD
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal> : ''
                                                            }
                                                        </>
                                                    </Fragment>

                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody >
                                        }
                                        {
                                            getID.showPopupDelete ? <SweetAlertPopup data={
                                                getID.Params
                                            }
                                                deleteCallback={delCallback}
                                                showpopup={true}
                                                pageActionId={TestingComment}
                                                Msg={"Comments deleted successfully"} /> : null
                                        }
                                    </table >

                                    {
                                        sizeqtypopup === true ?

                                            <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                <Modal.Header closeButton>
                                                    <Modal.Title>Size and Qty
                                                    </Modal.Title>


                                                </Modal.Header>
                                                <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                    <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                    <div className="col-lg-6">

                                                    </div>
                                                    <div className="col-lg-2">
                                                        <h5>Total : {getskuid.qrty}</h5>
                                                    </div>

                                                </div>

                                                <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                    <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "350px" }}>Size</th>
                                                                <th style={{ width: "160px" }}>Qty</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                sizeqtylist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="input-icon icon-right">
                                                                                        {item.sizes}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="input-icon icon-right">
                                                                                        {(item.qtys != null && item.qtys != undefined && item.qtys != "" ? item.qtys : '-')}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className="btn btn-xs btn-danger" onClick={() => setsizeqtypopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                </Modal.Footer>

                                            </Modal> : ''
                                    }
                                    {
                                        InspectionAndApprovalpopup === true ?

                                            <Modal show={InspectionAndApprovalpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => SetinspectionAndApprovalpopup(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>{statusType == 1 ? 'Inspection Status' : 'Approval Status'}
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <div className="row" style={{ padding: "0px 15px 0px 20px" }}>
                                                    <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                </div>

                                                {statusType == 1 && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                    {isInspectionStarted == 1 &&
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "160px" }}>Report No.</th>
                                                                    <th style={{ width: "160px" }}>Inspection Status</th>
                                                                    <th style={{ width: "160px" }}>Offer Qty</th>
                                                                    {/* <th style={{ width: "350px" }}>Created By</th> */}
                                                                    <th style={{ width: "700px" }}>Created On</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    approvalOrInspectionList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.tnaFinalInspectionID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {/* {item.inspectionStatus} */}
                                                                                            {<span className={item.inspectionStatus == "Draft" ? "warning" : (item.inspectionStatus == "Fail") ? "danger" : "success"}><b>{item.inspectionStatus}</b></span>}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.offeredQuantity}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {/* {item.createdBy} */}
                                                                                            {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                            {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>}
                                                    {isInspectionStarted == 0 &&
                                                        <h4 className="text-info"><b>Inspection not yet started</b></h4>
                                                    }
                                                </Modal.Body>
                                                }
                                                {statusType == 2 && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                    {isInspectionStarted == 1 &&
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th style={{ width: "350px" }}>Created Date</th> */}
                                                                    <th style={{ width: "160px" }}>Report No.</th>
                                                                    <th style={{ width: "160px" }}>Approval Status</th>
                                                                    <th style={{ width: "160px" }}>Quantity</th>
                                                                    <th style={{ width: "700px" }}>Created On</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    approvalOrInspectionList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    {/* <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.createdDate != null ? item.createdDate : '-'}
                                                                                            </span>
                                                                                        </td> */}
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.finalInspectionID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {/* {item.inspectionStatus} */}
                                                                                            {<span className={item.inspectionStatus == "Approved" ? "success" : (item.inspectionStatus == "Pending") ? "warning" : (item.inspectionStatus == "Rejected") ? "danger" : "primary"}><b>{item.inspectionStatus}</b></span>}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.offeredQuantity}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                            {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                            {/* {item.createdDate != null ? item.createdDate : '-'} {item.createdBy != null ? item.createdBy : '-'} */}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>}
                                                    {isInspectionStarted == 0 &&
                                                        <h4 className="text-info"><b>Approval not yet started</b></h4>
                                                    }
                                                </Modal.Body>}
                                                <Modal.Footer>
                                                    <button className="btn btn-sm btn-danger"
                                                        onClick={() => SetinspectionAndApprovalpopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                </Modal.Footer>
                                            </Modal> : ""
                                    }
                                    {
                                        Commentpopup === true ?
                                            <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose()}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        Comments
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                <th>Supplier Factory<span className="text-danger">*</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <textarea
                                                                        rows="4"
                                                                        placeholder="Enter comments here..."
                                                                        autoFocus
                                                                        //onFocus={onFocus}
                                                                        autoComplete="off"
                                                                        maxLength="500" name="EnterComment"
                                                                        id={"EnterComment"}
                                                                        value={getCommentValues[0].Comments}
                                                                        onChange={event => { handleCommentChange(event) }}
                                                                        style={{ width: "100%", border: getIsCommentSubmited && (getCommentValues[0].Comments === undefined || getCommentValues[0].Comments === '') ? '1px solid red' : '' }}
                                                                    >
                                                                    </textarea>
                                                                </td>
                                                                <td colSpan='2'>
                                                                    <Reactselect
                                                                        options={getCmtExFactoryList}
                                                                        value={getCommentValues[0].SupplierFactoryIDForEdit}
                                                                        onChange={event => { handleExFactoryChange(event) }}
                                                                        isClearable={true}
                                                                        styles={getIsCommentSubmited && (!getCommentValues[0].SupplierFactoryIDForEdit) ? styles : styles1}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                        <tbody>
                                                                            <tr>
                                                                                <div className="col-sm-12 col-lg-12">
                                                                                    <div style={{ paddingLeft: 0 }}>
                                                                                        <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                                                                    </div>
                                                                                </div>
                                                                                < div className="col-sm-12 col-lg-12">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-8 col-lg-8">
                                                                                            <div className='form-group'>
                                                                                                <tr>
                                                                                                    <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png" ></input></td>
                                                                                                </tr>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 col-sm-12">
                                                                                    <div className="row no-margin">
                                                                                        {
                                                                                            getFiles && getFiles.length !== 0 ?
                                                                                                getFiles.filter(x => x.IsDeleted !== 1 && x.reportManualFileId === 0).map((files, fileindex) => (
                                                                                                    <>
                                                                                                        {
                                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                <tr title={files.fileName}>
                                                                                                                    <td className="cut_textInTNA">
                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                            {
                                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                            }
                                                                                                                        </span>  {files.fileName.substr(33)}
                                                                                                                    </td>
                                                                                                                    <td className="button_style_uploda">

                                                                                                                        <p title='Delete File' onClick={(e) => RemoveImages(fileindex, files, files.reportManualFileId)}
                                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                                            <i className="fa fa-trash-o"></i>

                                                                                                                        </p>

                                                                                                                        &nbsp;
                                                                                                                        {
                                                                                                                            files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Image"

                                                                                                                                    onClick={
                                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                                    }></i>
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                {

                                                                                                                    <div> {
                                                                                                                        isOpenimg && (

                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/TestDetailsComments/' + getImagename}
                                                                                                                                alt="bg image"
                                                                                                                                onCloseRequest={
                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                } />
                                                                                                                        )
                                                                                                                    } </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                )) : ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Image</th>
                                                                                <th>Comments</th>
                                                                                <th>Supplier Factory</th>
                                                                                <th>Date</th>
                                                                                <th>Created By</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                getCommentGridList != undefined && getCommentGridList.length > 0 ? getCommentGridList.map((testComment, index) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            {/* <td>{Image}</td> */}
                                                                                            <td>
                                                                                                {

                                                                                                    (testComment.fileName && testComment.fileName !== '') && testComment.length !== 0 ?
                                                                                                        <div>
                                                                                                            <tr title={testComment.fileName}>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        testComment.fileName !== null && testComment.fileName !== '' ?
                                                                                                                            < SimpleReactLightbox >
                                                                                                                                <SRLWrapper>
                                                                                                                                    {
                                                                                                                                        getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).map((x, index) => {
                                                                                                                                            return (
                                                                                                                                                < img className="width_manual" title={x.flFileName.substr(33)} style={{ display: index === 0 ? '' : 'none' }}
                                                                                                                                                    src={x.flFileName === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/TestDetailsComments/' + x.flFileName}
                                                                                                                                                    alt={x.flFileName.substr(33)} />
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </SRLWrapper>
                                                                                                                            </SimpleReactLightbox> : ""
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <span className={getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length === 2 ? "label label-success" :
                                                                                                                    getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length === 3 ? "label label-danger" :
                                                                                                                        getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length === 4 ? "label label-info" :
                                                                                                                            getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length === 5 ? "label label-warning" :
                                                                                                                                getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length === 6 ? "label label-primary" :
                                                                                                                                    getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length > 6 ? "label label-default" : ''

                                                                                                                } style={{ display: 'inline-block' }}>{getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length > 1 ?
                                                                                                                    getCommentAttachmentGridList.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === testComment.commentsID).length : ''}</span>
                                                                                                            </tr>
                                                                                                        </div>
                                                                                                        : 'No file selected'

                                                                                                }
                                                                                            </td>
                                                                                            <td>{testComment.flCommentsDetail}</td>
                                                                                            <td>{testComment.factoryName}</td>
                                                                                            <td>{testComment.commentsDate}</td>
                                                                                            <td>{testComment.createdBy}</td>
                                                                                            <td>
                                                                                                <span style={{ padding: '2px', textAlign: "center" }}>
                                                                                                    <span>
                                                                                                        <span className="btn btn-danger" title="Delete Comment"
                                                                                                            onClick={() => RemoveComments(testComment)}
                                                                                                        >
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan={6} style={{ textAlign: "center" }}>
                                                                                        <span className="text-danger">No Records Found</span>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className="btn btn-xs btn-success" type="button" onClick={event => { handleSaveComment(event) }}><i className="fa fa-save"></i>&nbsp;Save</button>
                                                    <button className="btn btn-xs btn-danger" type="button" onClick={event => { handleFullClose() }}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                </Modal.Footer>
                                            </Modal> : ""
                                    }
                                    {
                                        HideShow && <Modal show={HideShow} onHide={handleCloseHideShow} backdrop="static" dialogClassName='customdialog'>
                                            <Modal.Header closeButton >
                                                <Modal.Title>
                                                    Show and Hide
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <li className="listmodal">
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="planEndDate"
                                                                        checked={checkboxStates.PlanEndDate}
                                                                        disabled
                                                                    />
                                                                    &nbsp;<label htmlFor="podate">PO Date</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                >
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="purchaseOrderSkuID"
                                                                        checked={checkboxStates.skuID}
                                                                        disabled
                                                                    />
                                                                    &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="skuName"
                                                                        checked={checkboxStates.skuName}
                                                                    />
                                                                    &nbsp;<label htmlFor="skuName">SKU Name</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="skuImg"
                                                                        checked={checkboxStates.skuImg}
                                                                    />
                                                                    &nbsp;<label htmlFor="skuImg">SKU Image</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="supplier"
                                                                        checked={checkboxStates.supplier}
                                                                    />
                                                                    &nbsp;<label htmlFor="supplier">Supplier </label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="idNo"
                                                                        checked={checkboxStates.idNo}
                                                                    />
                                                                    &nbsp;<label htmlFor="idNo">PO/ID No</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="FOPONo"
                                                                        checked={checkboxStates.FOPONo}
                                                                    />
                                                                    &nbsp;<label htmlFor="FOPONo">FO PO No</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="styleName"
                                                                        checked={checkboxStates.styleName}
                                                                    />
                                                                    &nbsp;<label htmlFor="styleName">Style Name</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="styleNo"
                                                                        checked={checkboxStates.styleNo}
                                                                    />
                                                                    &nbsp;<label htmlFor="styleNo">Style No</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="orderQty"
                                                                        checked={checkboxStates.orderQty}
                                                                    />
                                                                    &nbsp;<label htmlFor="orderQty">Order Qty</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="exFac"
                                                                        checked={checkboxStates.exFac}
                                                                    />
                                                                    &nbsp;<label htmlFor="exFac">Ex-Fac Date</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="orderStatus"
                                                                        checked={checkboxStates.orderStatus}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor="orderStatus">Order Status</label>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <li
                                                                    className="listmodal"
                                                                    onChange={handleCheckboxChange}>
                                                                    <input
                                                                        className="hidecheckbox"
                                                                        type="checkbox"
                                                                        name="stsCreated"
                                                                        checked={checkboxStates.stsCreated}
                                                                    />
                                                                    &nbsp;
                                                                    <label htmlFor="stsCreated">Status Created By</label>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-xs btn-danger" type="button" onClick={event => { handleCloseHideShow() }}><i className="fa fa-times"></i>&nbsp;Close</button>
                                            </Modal.Footer>
                                        </Modal>
                                    }
                                </div >

                                {
                                    getIDTestTask.showPopupDelete ? <SweetAlertPopup data={
                                        getIDTestTask.Params
                                    }
                                        deleteCallback={delTestCallback}
                                        showpopup={true}
                                        pageActionId={TestingMainlist}
                                        Msg={"Test Inspection deleted successfully"} /> : null
                                }
                            </div >

                        </div >
                    </div >
                    <PageCount onPageCount={
                        value => {
                            setITEMS_PER_PAGE(value);
                            setCurrentPage(1);
                        }
                    } />
                    <Pagination total={totalItems}
                        itemsPerPage={
                            parseInt(ITEMS_PER_PAGE)
                        }
                        currentPage={currentPage}
                        onPageChange={
                            page => setCurrentPage(page)
                        } />
                </div >

                {loader}
            </div >
        </>
    )
}

export default TestingDetails