import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import { getOrderLapdipDetailsViewList, clearLapdipDetailsViewSubRow } from "../../../../actions/orderLabdip";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../../components/Common/ReactNotification";
import strikeoffService from "../../../../services/Style/StrikeoffService";

const LabDipViewDetailTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const labdipViewDetailList = useSelector((state) => state.orderLabdip.orderLabdipViewDetailList);
    const labdipViewDetailParams = useSelector((state) => state.orderLabdip.orderLabdipViewDetailParams);
    let isLoadingOrderLabdip = useSelector((state) => state.orderLabdip.isLoadingOrderLabdip);

    let OLDStatusList = useSelector((state) => state.orderLabdip.OLDStatusList);

    //state
    const [getSearch, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [getPlaceholder] = useState("Buyer / Season / Brand");
    const [getApprovalList, setApprovalList] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");

    const [getSearchvalues, setSearchvalues] = useState({
        Status: 0,
        ApprovalTypeId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });
    const Searchvalues = { ...getSearchvalues };

    useEffect(() => {
        if (!isLoadingOrderLabdip) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderLabdip])
    useEffect(() => {
        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });
    }, [])


    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
     let info;
    let activeMenu;
    let activeMenuSub;
    let OrderLabdipDetailTab;
   

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
       // info = IsProduction === 1 ? 'Samples' : 'ProductionSample';
         info = IsProduction === 1 ? 'OrderLabDip' : (IsProduction === 2 ? 'ProductionOrderLabDip' : 'ProductionOrderLabDip');
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                 if (menuName === getMenuName("OrderLabdipDetails")) {
                    OrderLabdipDetailTab = menuItem;
                    }
                    
                }
            }
        }
    }


    //#region  ------------------  search details --------------------------------
    const searchLabDip = () => {
        Searchvalues.stateDate[0].isShow = false;
        setSearchvalues(Searchvalues);

        let StartDate = null;
        let EndDate = null;
        if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = {
            BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId,
            SeasonId: labdipViewDetailParams.SeasonId,
            BuyerName: labdipViewDetailParams.BuyerName,
            BrandName: labdipViewDetailParams.BrandName, SeasonName: labdipViewDetailParams.SeasonName,
            Operation: 1, PantoneId: 0, Index: 0,
            SearchText: Searchvalues.SearchTxt, Status: Searchvalues.Status ? Searchvalues.Status : null,
            ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
            StyleName: Searchvalues.StyleName, StyleNo: Searchvalues.StyleNo, IdPoNo: Searchvalues.IdPoNo,
            IsProduction: IsProduction
        };
        dispatch(getOrderLapdipDetailsViewList(params));
        commonParentExpand();
    }




    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandViewTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandViewTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    // useEffect(() => {
    //    ;

    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             searchLabDip();
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };


    // }, []);


    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, pantoneIdOrSwatchId) => {
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');

        let StartDate = null;
        let EndDate = null;
        if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            let params = {
                BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId,
                SeasonId: labdipViewDetailParams.SeasonId, PantoneId: pantoneIdOrSwatchId, Index: index,
                SearchText: (getSearch && getSearch.trim()), Operation: 1,
                StyleName: labdipViewDetailParams.StyleName, StyleNo: labdipViewDetailParams.StyleNo, IdPoNo: labdipViewDetailParams.IdPoNo,
                Status: Searchvalues.Status ? Searchvalues.Status : null, ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };
            setcurrentExpand({ currindex: index, currpantoneIdOrSwatchId: pantoneIdOrSwatchId })
            dispatch(getOrderLapdipDetailsViewList(params));
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (pantoneIdOrSwatchId) {
                dispatch(clearLapdipDetailsViewSubRow(index));

            }
        }
    };

    const resetLabDip = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        Searchvalues.Status = "";
        Searchvalues.SearchTxt = "";
        Searchvalues.ApprovalTypeId = "";
        Searchvalues.StyleName = "";
        Searchvalues.StyleNo = "";
        Searchvalues.IdPoNo = "";
        setSearchvalues(Searchvalues);
        searchLabDip();

    }


    const clearDateSelect = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        setSearchvalues(Searchvalues);
    }
    const handleDateSelect = (event, isShow) => {

        Searchvalues.stateDate[0].isShow = isShow;
        setSearchvalues(Searchvalues);
    }

    const handlePopupDateselection = (item) => {

        Searchvalues.stateDate = [item.selection];
        setSearchvalues(Searchvalues);
    }

    const searchText = () => {

        let params = {
            BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId,
            SeasonId: labdipViewDetailParams.SeasonId, PantoneId: 0, Index: 0,
            SearchText: (getSearch && getSearch.trim()), Operation: 1,
            IsProduction: IsProduction
        };
        dispatch(getOrderLapdipDetailsViewList(params));
    }

    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             alert('test');
    //             searchText();
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);




    const handleChange = (e, FieldName) => {
        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'ApprovalType') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            Searchvalues.Status = inputText;
        } else if (FieldName === 'ApprovalType') {
            Searchvalues.ApprovalTypeId = inputText;
        } else if (FieldName === 'SearchTxt') {
            Searchvalues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            Searchvalues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            Searchvalues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            Searchvalues.IdPoNo = inputText;
        }

        setSearchvalues(Searchvalues);


    }


    const handleserach = () => {

    }

    const ViewFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">
                            
                            <div className="col-md-2 min-height">
                                <div className="form-group">
                                    <label>Pantone Code / Swatch Name </label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" name="SearchTxt" value={Searchvalues.SearchTxt} onChange={event => handleChange(event, "SearchTxt")} placeholder="Pantone Code / Swatch Name" className="form-control" />
                                        {/* <span title='Search' onClick={() => searchText()}
                                        className="btn btn-danger btn-xs search"> */}
                                        {/* <i onClick={() => searchText()} className="fa fa-search"></i> */}
                                        {/* </span> */}
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-2 min-height">
                                <div className="form-group">
                                    <label htmlFor="ApprovalType">
                                        Approval Type
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="ApprovalType"
                                            id={
                                                "ApprovalType"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleChange(event, 'ApprovalType')}
                                            value={getApprovalList.filter(function (option) {
                                                return option.value === Searchvalues.ApprovalTypeId;
                                            })}
                                            options={getApprovalList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-2 min-height">
                                <label htmlFor="Status">
                                    Style Name
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style Name'}
                                        placeholder={'Style Name'}
                                        value={Searchvalues.StyleName}
                                        onChange={e => handleChange(e, 'StyleName')}
                                    />
                                </span>
                            </div>

                            <div className="col-md-2 min-height">
                                <label htmlFor="Status">
                                    Style No
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style No'}
                                        placeholder={'Style No'}
                                        value={Searchvalues.StyleNo}
                                        onChange={e => handleChange(e, 'StyleNo')}
                                    />
                                </span>
                            </div>

                       


                      
                            <div className="col-md-2 min-height">
                                <label htmlFor="Status">
                                       ID/PO NO
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'ID/PO No'}
                                        placeholder={'ID/PO NO'}
                                        value={Searchvalues.IdPoNo}
                                        onChange={e => handleChange(e, 'IdPoNo')}
                                    />
                                </span>

                            </div>


                            <div className="col-md-2 min-height">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleChange(event, 'Status')}
                                            value={OLDStatusList.filter(function (option) {
                                                return option.value === Searchvalues.Status;
                                            })}
                                            options={OLDStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
        
                       
                            <div className="col-md-2 min-height">

                                <div className="form-group">
                                    <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(Searchvalues.stateDate[0].startDate === null ? '' : moment(Searchvalues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((Searchvalues.stateDate[0].endDate === null || !moment(Searchvalues.stateDate[0].endDate).isValid()) ? '' : moment(Searchvalues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !Searchvalues.stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span> 
                                </div>


                                {Searchvalues.stateDate[0].isShow &&
                                    <DateRangePicker
                                        //onChange={item => setStateDate([item.selection])}
                                        onChange={item => handlePopupDateselection(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={Searchvalues.stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}

                                    />

                                }

                            </div>

                            <div className="col-md-2 min-height" style={{ paddingTop: '29px' }}>
                                <span className="input-icon icon-right">
                                    <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                        disabled={Searchvalues.Buyer === 0 || Searchvalues.Brand === 0 ||
                                            Searchvalues.Season === 0 || Searchvalues.Supplier === 0 ? true : false}
                                    >
                                        <i className="fa fa-search"></i>
                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-danger" title="Reset" onClick={resetLabDip}
                                    >
                                        <i className="fa fa-close"></i>
                                    </button>
                                    <br />
                                </span>
                            </div>
                            <br />
                        
                    </div>

                        <div className="row"> </div>
                        <div className="row">
                            <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <thead>
                                            <tr>
                                                <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                                <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                                <th>Date</th>
                                                <th>Style Name</th>
                                                <th>Style No</th>
                                                <th>ID/PO NO</th>
                                                <th >Item (Fabric / Yarn)</th>
                                                <th >Process</th>
                                                <th >Approval Type</th>
                                                <th>Task Holder / Follower</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                labdipViewDetailList && labdipViewDetailList.length > 0 ?
                                                    <>
                                                        {
                                                            labdipViewDetailList && labdipViewDetailList.map((inputField, index) => (
                                                                <Fragment key={`${inputField}~${index}`}>
                                                                    <tr class="parent" data-child={"parent_" + index}>
                                                                        <td className="fixed-column-first-tna">

                                                                            {
                                                                                <span id={"btn-toggle_" + index} class="btn-toggle parentExpandViewTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.pantoneIdOrSwatchId)} aria-labelledby="btn-toggle id-master">
                                                                                    <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandViewTNA"></i>
                                                                                </span>
                                                                            }

                                                                        </td>
                                                                        <td>{index+1}</td>
                                                                        <td>
                                                                            <span className='input-icon icon-right'>
                                                                                {
                                                                                    inputField.pantoneCode
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td colSpan="8">
                                                                            {inputField.imagePath ?
                                                                                <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                                    <img className="width_manual"
                                                                                        src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath}
                                                                                        alt="Logo"
                                                                                        onClick={
                                                                                            () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath)
                                                                                        }
                                                                                    />
                                                                                </div> :
                                                                                <div style={{ background: inputField.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                    {
                                                                        inputField.pantoneSubList && inputField.pantoneSubList.map((subField, subIndex) => (

                                                                            <Fragment key={`${subIndex}~${subIndex}`}>
                                                                                <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                    <td></td>
                                                                                    <td className="fixed-column-first-tna" style={{paddingLeft:"17px"}}>
                                                                                        {subIndex + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {moment(subField.recievedDate).format('DD/MM/YYYY')} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {subField.styleName} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {subField.styleNo} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {(subField.idpono != null && subField.idpono != undefined && subField.idpono != "")
                                                                                                ? subField.idpono : <span className="text-danger">NA</span>} </span>
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        {
                                                                                            <span> {subField.itemDetails} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {subField.processTypeName} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            <span> {subField.approvalTypeName} </span>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                        {
                                                                                            <span> {subField.taskHolderFollowerNames} </span>
                                                                                        }
                                                                                    </td>

                                                                                </tr>
                                                                                {

                                                                                    inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                        subField.styleProgramIndexName === Crit.indexName
                                                                                            && subField.styleID === Crit.styleID
                                                                                            ?
                                                                                            <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                                <td colSpan="10">
                                                                                                    <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                                    <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                                    <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                                </td>

                                                                                            </tr>

                                                                                            : ''
                                                                                    ))
                                                                                }

                                                                                {



                                                                                    inputField.manualCommentList && subField.approvalTypeID === 2 && inputField.manualCommentList.map((Comment) => (
                                                                                        subField.styleProgramIndexName === Comment.programIndexName && subField.styleID === Comment.styleID ?

                                                                                            <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={Comment.manualCloseComment} >
                                                                                                <td colSpan="12">
                                                                                                    <div className="row-1">
                                                                                                        <span><b>Manual Comment{inputField.approvalTypeID} :</b> {Comment.manualCloseComment}

                                                                                                        </span>
                                                                                                    </div>

                                                                                                    {
                                                                                                        inputField.manualImageList && inputField.manualImageList.map((files, fileindex) => (
                                                                                                            Comment.programIndexName === files.programIndexName
                                                                                                                && Comment.styleID === files.styleID
                                                                                                                ?
                                                                                                                <div className="col-md-2 file_list_upload" >
                                                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={files.fileName}  >
                                                                                                                        File :
                                                                                                                        <td className="cut_textInTNA"  >
                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                {
                                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                }
                                                                                                                            </span>  {files.fileName}
                                                                                                                        </td>

                                                                                                                        <td >

                                                                                                                            {
                                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                    //  LapDipViewDetailTab && LapDipViewDetailTab.isView !== 0 &&
                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                        onClick={
                                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                                        }></i>

                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    {

                                                                                                                        <div> {
                                                                                                                            isOpenimg && (

                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                    alt="bg image"
                                                                                                                                    onCloseRequest={
                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                    } />
                                                                                                                            )
                                                                                                                        } </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                : ''
                                                                                                        ))

                                                                                                    }
                                                                                                </td>
                                                                                            </tr>




                                                                                            : ''
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    inputField.existingList && subField.approvalTypeID === 3 && inputField.existingList.map((exist) => (
                                                                                        subField.styleProgramId === exist.styleProgramId && subField.styleID === exist.styleID ?
                                                                                            <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex}>
                                                                                                <td colSpan="10">
                                                                                                    <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                                    <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                                    <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                                    <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                                </td>
                                                                                            </tr>
                                                                                            : ''
                                                                                    ))
                                                                                }



                                                                            </Fragment>
                                                                        ))
                                                                    }

                                                                </Fragment>
                                                            ))
                                                        }
                                                    </>
                                                    :

                                                    <tr><td colSpan="10" className='norecordfound'><span>No Records Found </span></td></tr>

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            {/* <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span> */}
                            {/* <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span> */}
                            {/* <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button> */}
                        </div>
                    </div>
                </div>
            </div>

            {loader}
        </Fragment>

    )
}

export default LabDipViewDetailTab;