import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import DatePicker from "react-datepicker";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import ProgramService from "../../services/Style/ProgramService";
import FabricService from "../../services/Master/FabricService";
import moment from "moment";
import {
    GetGeneralLapdipSubmissionApprovalList, GetGeneralLapdipApprovalViewList,
    clearLapdipSubmissionSubRow, getGenLabdipTNASubmissionAndApproval, UpdateGenralLabdipTNA,
    GetGeneralLapdipApprovalList, loadGLDTNATaskOwnerList
} from "../../actions/generalLabdip";
import { GetGeneralStrikeOffSubmissionApprovalGridList, clearStrikeOffSubmissionSubRow, GetGeneralStrikeOffApprovalViewList, getGenStrikeOffTNASubmissionAndApproval, UpdateGenralStrikeOffTNA, GetGeneralStrikeOffApprovalList, UpdateIsApprovalManualClosePopup } from "../../actions/generalStrikeOff";
import { loadGenTNAReportList, updateGenTNAReportIsExpanded, updateGenTNAReportParentIsExpanded, clearGenTNAReportList } from "../../actions/generalTNAReport";
import Lightbox from "react-image-lightbox";
import GeneralService from "../../services/General/GeneralService";
import { TableHeader, Pagination, Search, PageCount } from "../../components/Datatable";
import axios from "axios";
import ReactNotification from 'react-notifications-component';
import $, { get } from "jquery";
import { retrieveSeason } from "../../actions/season";
import { loadSupplierList, loadActiveBuyerList } from "../../actions/tna";
import TnaService from "../../services/TNA/TNAService";
import StyleService from "../../services/Style/styleService";
// import { FALSE } from "node-sass";
import { GetGeneralTrimSubmissionApprovalGridList, GetGeneralTrimApprovalViewList, GetGeneralTrimApprovalList } from "../../actions/generalTrim";
import { LoadSizeList } from "../../actions/size";
import { retrievegeneralLabdip } from "../../actions/generalLabdip";
import { UpdateIsApprovalManualClosePopup as TrimManualpopup } from '../../actions/generalTrim';
import '../SamplingGeneral/customGenSamPopup.css';



const SamGenlTNAReport = (props) => {
    //reducer
    //Main TNA Report load data
    const StrikeOffSubmissionList = useSelector((state) => state.generalStrikeOff.generalStrikeOffSubmissionList);
    const labdipApprovalList = useSelector((state) => state.generalLabdip.generalLabdipApprovalList);
    const genTNAReportList = useSelector((state) => state.generalTNAReport.genTNAReportList);
    const labdipSubmissionList = useSelector((state) => state.generalLabdip.generalLabdipSubmissionList);
    const sizeList = useSelector((state) => state.size.drpSizeList);

    const isLoadingTNA = useSelector((state) => state.generalTNAReport.isLoadingGenTNA);
    const getTNAStatusList = useSelector((state) => state.tna.TNAStatusList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);

    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    
    const StrikeOffApprovalList = useSelector((state) => state.generalStrikeOff.generalStrikeOffApprovalList);
    const [expandState, setExpandState] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [showApprovaldiv, setshowApprovaldiv] = useState();
    const [showApproval, setshowApproval] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [StrikeoffManualMnfo, setStrikeoffManualMnfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [trimManualInfo, setTrimManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [showSOManual, setShowSOManual] = useState(false);
    //popup
    const [tab, setTab] = useState(0);
    const [showawb, setshowawb] = useState(false);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const [showMultiClosediv, setshowMultiClosediv] = useState();
    const [showSubmissiondiv, setshowSubmissiondiv] = useState();
    const [getheader, setheader] = useState();
    const [submittedoption, setsubmittedoption] = useState([]);
    const [FabricDetails, setFabricDetails] = useState([]);
    const [getTaskholder, setTaskholder] = useState([]);
    const [showSubmission, setshowSubmission] = useState(false);
    const [showstrikeoffSubmission, setshowStrikeoffSubmission] = useState(false);
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [ShowPopup, setShowPopup] = useState(false);
    const [getsetShowTrimsub, setShowTrimsub] = useState(false);
    const [ShowTrimPopup, setShowTrimPopup] = useState(false);
    const [ShowlabdipPopup, setShowlabdipPopup] = useState(false);
    const [ShowStrikePopup, setShowStrikePopup] = useState(false);
    const [showManual, setshowManual] = useState(false);
    const [showladappmanual, setshowladappmanual] = useState(false);
    const [showSubmitManual, setSubmitshowManual] = useState(false);
    const [getStrikeOffAppManual, setStrikeOffAppManual] = useState(false);
    const [getTrimAppManual, setTrimAppManual] = useState(false);
    const [ApprovalManualInfo, setApprovalManualInfo] = useState([{ approvalManualCloseDate: '', approvalManualCloseComment: '' }]);
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [showSizePopup, setShowSizePopup] = useState({ isPopup: false, detail: '' });
    const [StrikeoffDimension, setStrikeoffDimension] = useState([]);
    const [getSubImageList, setSubImageList] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getSizeList, setSizeList] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandList, setexpandList] = useState({});
    const [getHeader, setHeader] = useState("Add");
    const [showstrikeoffShowApproval, setstrikeoffshowApproval] = useState(false);
    const [showtrimSubmission, setshowTrimSubmission] = useState(false);
    const [TrimDimension, setTrimDimension] = useState([]);
    const [showtrimApproval, setshowTrimApproval] = useState(false);
    const [View, setView] = useState(false);
    const [minApprovalDate, setMinApprovalDate] = useState({});
    const [firstsubapprid, setfirstsubapprid] = useState({
        SubApprId: 0,
        GenLapdipProgramInfoId: 0

    });
    const [getindex, setindex] = useState(0);
    const [getindexLabdip, setindexLabdip] = useState({ index: 0, subIndex: 0, ID: 0 });
    const [submittedoptionAll, setsubmittedoptionAll] = useState([]);
    // const [isLastEdit, setLastEdit] = useState(true);
    const [GenlabdipId, setGenlabdipId] = useState();
    // const [StyleId, setStyleId] = useState();
    // const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    // const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    // const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    // const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    // const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    // const [TNAProtoFitId, setTNAProtoFitId] = useState();
    // const [getSearchvaluestoSub, setSearchvaluesToSub] = useState([]);
    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    const SearchReqValues = { ...getSearchReqValues };
    const [AppareanceTestId, setAppareanceTestId] = useState();
    const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const [showTaskOwnerPopup, setShowTaskOwnerPopup] = useState({ isPopup: false, detail: '' });
    // const activeMenus = useSelector((state) => state.auth.activeMenuList);
    // let IsProduction = activeMenus.IsProduction;
    const [getSearchvalues, setSearchvalues] = useState({
        Buyer: 0, Brand: 0, Season: 0,
        Supplier: 0, TaskType: 0,
        TaskName: 0, Status: 4,
        lstTaskType: [],
        lstTaskName: [],
        TodoDelay: 0, TodoUpcoming: 0, TodoToday: 0, TodoAll: 0,
        CompletedEarly: 0, CompletedOntime: 0, CompletedDelay: 0, CompletedAll: 0,
        OverAllCount: 0,
        Operation: 1,
        CurrentIndex: 0,
        CurrentChildIndex: 0,
    });

    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]
    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }
    //Enable Menu

    let activeMenu;
    let activeMenuSub;
    let TaskCloseCompleteInfo;
    let StartStopInfo;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === "TNAReport");
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === "TNAReportCloseComplate") {
                        TaskCloseCompleteInfo = activeMenuSub[index];
                    }
                    else if (activeMenuSub[index].menuName === "TNAReportStartStop") {
                        StartStopInfo = activeMenuSub[index];

                    }
                }
            }
        }
    }


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

    // End Enable Menu



    const Searchvalues = { ...getSearchvalues };

    const [getBuyerList, setBuyerList] = useState([]);
    const getSeasonList = useSelector((state) => state.season.seasonList.filter(season => season.activeStatus === 1));
    const [getBrandList, setBrandList] = useState([]);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [OrderTrimInfoMulticloseMainDetails, setTrimOrderInfoMulticloseMainDetails] = useState([]);
    const [OrderStrikeoffInfoMulticloseMainDetails, setStrikeoffOrderInfoMulticloseMainDetails] = useState([]);
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});

    const [selectall, setselectall] = useState(false);

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    // let styles = {
    //     control: styles => ({ ...styles, border: '1px solid red' }),
    // }

    const [inputFieldReport, setInputFieldReport] = useState([{

    }]);
    const GenRTep = [...inputFieldReport];
    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [emptyfeilds, setemptyfeilds] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmitDate: '',
        SubmitDateEdit: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralLabdipTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNALabdibSubmittedOption: []
    }]);
    const [labdipinputFields, setlabdipInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmittedDate: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        SubmitStatus: '', IsAllFabricLoad: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GenralLabdipTNASubmissionAndApprovalId: 0,
        GeneralTNALabdibSubmittedOption: []
    }]);
    const Labdipvalues = [...labdipinputFields]
    const [strikeoffInputFields, setstrikeoffInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmittedDate: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        StrikeOffId: 0, StrikeoffCode: 0, StrikeoffName: "", Quality: "",
        StrikeoffDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        SubmitStatus: '',
        GenralStrikeOffTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNAStrikeOffSubmittedOption: [],
        ColorGroupId: 0
    }]);
    const strikeoffvalues = [...strikeoffInputFields]
    const [TriminputFields, setTrimInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmittedDate: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        TrimId: 0, TrimCode: 0, TrimName: "", TechnicalSpecification: "",
        TrimDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        SubmitStatus: '',
        GenralTrimTNASubmissionAndApprovalId: 0,
        GeneralTNATrimSubmittedOption: [],
        ApprovalDate: '', ApprovalRemark: '',
        ColorGroupId: 0
    }]);
    const TrimValues = [...TriminputFields];
    const [submitted, setSubmitted] = useState(false);
    // const [buttonLoader, setButtonLoader] = useState(false);
    // const [getTaskHolderList, setTaskHolderList] = useState([]);
    // const [getHeaderName, addHeaderName] = useState("Edit");
    // const [101AllRouteInfo, setAllRouteInfo] = useState([]);

    const [getComment, setComment] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    // const [getFiles, setFiles] = useState([]);
    // const [isOpenimg, setIsOpenimg] = useState(false);
    // const [getImagename, setImagename] = useState();
    // const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    // const [getTNAtaskIdFileupload, setTNAtaskIdFileupload] = useState();
    // const [getCurrentChildTask, setCurrentChildTask] = useState({ value: 0, lablel: '' });
    const [ParentTask, setParentTask] = useState('');
    const [ChildTask, setChildTask] = useState('');
    const [TaskStatus, setTaskStatus] = useState();
    const [LabdipApprovalList, setLabdipApprovalList] = useState([]);
    const [MultiManualCloseFeilds, setMultiManualCloseFeilds] = useState({ IdList: '', TaskStatus: 0, ManualCloseDate: '', ManualCloseComment: '', Createdby: 1 });
    const values = [...inputFields];

    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [manualApprovalData, setmanualApprovalData] = useState({ manualCloseComment: '', files: [] });
    const [existingApprovalData, setexistingApprovalData] = useState([]);

    //////////////////**************searchTNA  *////////////////////////////////
    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        {
            name: "Action",
            field: "",
            sortable: false,
            // display: LapDipRequestTab && LapDipRequestTab.isEdit === 0 && LapDipRequestTab.isDelete === 0 ? 'none' : ''
        },
    ];
    const headerss = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        // { name: "Action", field: "", sortable: false },
    ];
    const handleLinkClick = (childTask) => {
       ;
        localStorage.removeItem('childTask');
        childTask.IsProduction = 1;
        localStorage.setItem('childTask', JSON.stringify(childTask));
     };
    const searchLabDip = () => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        if (Searchvalues.Status) { }

        let params = {
            BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
            TaskType: Searchvalues.TaskType, TaskNameId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status,
            StartDate: StartDate, EndDate: EndDate, Operation: 1, Index: 0, ChildIndex: 0, MainId: 0, ColorImageId: 0, IsProduction: IsProduction
        };

        // setSearchvaluesToSub(params);

        dispatch(loadGenTNAReportList(params));
        dispatch(LoadSizeList());
        //Searchvalues.Status = 4;
        setSearchvalues(Searchvalues);
        commonParentExpand();
    }
    // useEffect(() => {

    //         dispatch(loadSupplierList());
    //         searchTrim();

    //     }, [])
    const resetTNA = () => {
        Searchvalues.Buyer = 0;
        Searchvalues.Brand = 0;
        Searchvalues.Season = 0;
        Searchvalues.Supplier = 0;
        Searchvalues.Status = 4;

        Searchvalues.TaskType = 0;
        Searchvalues.TaskName = 0;
        setSearchvalues(Searchvalues);
        let params = {
            BuyerId: 0, BrandId: 0, SeasonId: 0, SupplierId: 0, TaskType: 0, TaskNameId: 0, TaskTodoInProgressCompletedStatus: 4,
            StartDate: null, EndDate: null, Operation: 1, Index: 0, ChildIndex: 0, MainId: 0, ColorImageId: 0
        };
        dispatch(clearGenTNAReportList(params));

        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

    }

    function LoadBuyerBased(BuyerIDDefault, setBrandList) {
        StyleService.LoadBrandThemeStoryEndCustomer(5, BuyerIDDefault).then((response) => {
            if (response.data) {
                let BuyerInfoList = response.data;
                let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                setBrandList(respected_Brand);
            }
        }).catch(() => { });
    }

    useEffect(() => {
        if (!isLoadingTNA) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])



    useEffect(() => {
        // showLoader();
        resetTNA();

        dispatch(retrieveSeason(0));
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {

                   // setBuyerList(response.data);
                    setBuyerList(response.data.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                }
            })
            .catch(() => { });

        dispatch(loadSupplierList());
        dispatch(loadActiveBuyerList(2));
    }, [IsProduction]);

    useEffect(() => {
        if (getSupplierList.length === 1) {
            Searchvalues.Supplier = getSupplierList[0].value;
        }
        else {
            Searchvalues.Supplier = 0;
        }
        setSearchvalues(Searchvalues);
    }, [getSupplierList.length === 1])







    // Main Task load
    useMemo(
        () => {
            if (genTNAReportList) {

                let TaskTypeList = genTNAReportList.tnaTaskTypeList;
                let TaskNameList = genTNAReportList.tnaTaskNameList;
                let TodoInprogressList = genTNAReportList.tnaTodoInprogressList;
                let CompletedList = genTNAReportList.tnaCompletedList;
                Searchvalues.lstTaskType = TaskTypeList;
                Searchvalues.lstTaskName = TaskNameList;

                if (TodoInprogressList && TodoInprogressList.length !== 0) {
                    Searchvalues.TodoDelay = TodoInprogressList[0].todoInProgressDelayCount;
                    Searchvalues.TodoUpcoming = TodoInprogressList[0].todoInProgressUpComingCount;
                    Searchvalues.TodoToday = TodoInprogressList[0].todoInProgressTodayCount;
                    Searchvalues.TodoAll = TodoInprogressList[0].todoInProgressAllCount;
                } else {
                    Searchvalues.TodoDelay = 0;
                    Searchvalues.TodoUpcoming = 0;
                    Searchvalues.TodoToday = 0;
                    Searchvalues.TodoAll = 0;
                }
                if (CompletedList && CompletedList.length !== 0) {
                    Searchvalues.CompletedDelay = CompletedList[0].completedDelayCount;
                    Searchvalues.CompletedEarly = CompletedList[0].completedEarlierCount;
                    Searchvalues.CompletedOntime = CompletedList[0].completedOnTimeCount;
                    Searchvalues.CompletedAll = CompletedList[0].completedAllCount;
                    Searchvalues.OverAllCount = CompletedList[0].overAllCount;
                } else {
                    Searchvalues.CompletedDelay = 0;
                    Searchvalues.CompletedEarly = 0;
                    Searchvalues.CompletedOntime = 0;
                    Searchvalues.CompletedAll = 0;
                    Searchvalues.OverAllCount = 0;
                }
                setSearchvalues(Searchvalues);
            }
        },
        [genTNAReportList.genTNADetailList]
    );
    //#region LABDIPAPPROVAL
    const handleLabAppManualclose = (Id, approvalStatus, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index) => {
        showLoader();
        setindex(index);
        let Operation;
        setComment(false);
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Add');

        }
        else {
            Operation = 4;
            setheader('View');

        }
        if (approvalStatus !== "Completed") {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        setshowladappmanual(true);
                        hideLoader();
                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        else {

            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        // let isSelectAll = 0;
                        // if (data) {
                        //     isSelectAll = !!!data.find(d => d.isSelected === 0);
                        //     isSelectAll = isSelectAll ? 1 : 0;
                        // }
                        // setselectall(isSelectAll);
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        setshowladappmanual(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }


    }
    const LabApphandleChange = (event, feild) => {
        let input = '';

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(Labdipvalues[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                }
                else {
                    Labdipvalues[0].ApprovalDate = event;
                }
            }
            else {
                Labdipvalues[0].ApprovalDate = '';
            }

        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            Labdipvalues[0].ApprovalRemark = input;
        }

        setlabdipInputFields(Labdipvalues);
    }
    const handleLabAppSubmitoptionchange = (event, feild, index) => {
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                const newValue = event.target.value;

                const isDuplicate = newValue !== "" && submittedoption.some(option => option.SupplierReferenceNo === newValue);

                if (!isDuplicate) {
                    submittedoption[index].SupplierReferenceNo = newValue;
                } else {

                    console.error(newValue === "" ? "Value cannot be empty!" : "Duplicate value in SupplierReferenceNo field!");
                }
            }


            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {

            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }
    const LapdipAppSelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const LapdipAppOnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }
    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: '',
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        let approvalviewparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralLapdipApprovalList(params));
        dispatch(GetGeneralLapdipApprovalViewList(approvalviewparams));
    }
    const searchLabDipApp = () => {

        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralLapdipApprovalList(params));

        // let params = {
        //     BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId, SeasonId: labdipViewDetailParams.SeasonId,
        //     BuyerName: labdipViewDetailParams.BuyerName, BrandName: labdipViewDetailParams.BrandName, SeasonName: labdipViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralLapdipDetailsViewList(params));
        commonParentExpand();
    }
    const SaveApproval = () => {
        let IsValid = true;
        if (Labdipvalues[0].ApprovalDate === '') {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== null &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            Labdipvalues[0].ApprovalDate = new Date(Labdipvalues[0].ApprovalDate.setMinutes(Labdipvalues[0].ApprovalDate.getMinutes() + 370))
            Labdipvalues[0].Operation = 2;
            Labdipvalues[0].SendThrough = parseInt(Labdipvalues[0].SendThrough)
            Labdipvalues[0].GeneralTNALabdibOrderInfo = getOrderinfo;
            Labdipvalues[0].GeneralTNALabdibSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    Labdipvalues[0].GeneralTNALabdibSubmittedOption.push({
                        GeneralLabdibSubmittedOptionId: 0,
                        GenralLabdipTNASubmissionAndApprovalId: ordinf.genralLabdipTNASubmissionAndApprovalId,
                        GenLapdipProgramInfoId: submittedoption[0].genLapdipProgramInfoId,
                        OptionName: opt.optionName,
                        SupplierReferenceNo: opt.supplierReferenceNo,
                        ApprovedOptionName: opt.approvedOptionName
                    })
                })
            })
            GeneralService.InsertUpdateGeneralLabdipSubmissionApproval(Labdipvalues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Approval Added Successfully');
                    searchLabDipApp();
                    setshowApproval(false);
                    handleToggle(getindex);
                    ReloadLabdipApproval();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    searchLabDipApp();
                    setshowApproval(false);
                    handleToggle(getindex);
                    ReloadLabdipApproval();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const ReloadLabdipApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: '',
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        let approvalviewparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralLapdipApprovalList(params));
        dispatch(GetGeneralLapdipApprovalViewList(approvalviewparams));
    }
    const OpenLabdipApprovalpopup = async (issubmission, Id, approvalStatus, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index, subIndex, childIndex) => {
       ;

        let Optionvalue = [];
        let Operation;


        if (issubmission === 1) {
            setTab(0);
            setindex(index);
            Labdipvalues[0].ApprovalDate = '';
            Labdipvalues[0].ApprovalRemark = '';
            if (approvalStatus === "Pending") {
                Operation = 2;
                /// setshowMultiClosediv(true);
                // setshowApprovaldiv(false);

                setshowMultiClosediv(false);
                setshowApprovaldiv(true);

                setheader('Add');
            }
            else {
                Operation = 4;
                setshowMultiClosediv(false);
                setshowApprovaldiv(true);
                setheader('View');

            }
            ApprovedOption.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }
                else if (element.value === 'Hold') {
                    LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
                }


                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setApprovedOptions(Optionvalue);
            GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
                if (response.data) {
                    setFabricDetails(response.data);
                }
            }).catch(() => { });
            GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
                if (response.data) {
                    setTaskholder(response.data);
                }
            }).catch(() => { });
            // var Main = labdipApprovalList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
            var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
            Sub.forEach(element => {
                if (element.submissionAndApprovalId === Id) {
                    element.isSelected = 1;
                }
            })

            Labdipvalues[0].SupplierName = Sub[0].supplierName;
            Labdipvalues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
            Labdipvalues[0].StoryName = Sub[0].storyName;
            Labdipvalues[0].ThemeName = Sub[0].themeName;
            Labdipvalues[0].EndCustomer = Sub[0].endCustomerNameIdList;
            Labdipvalues[0].PantoneCode = Sub[0].pantoneCode;
            Labdipvalues[0].RefColorName = Sub[0].pantoneReferColorName;
            Labdipvalues[0].PantoneID = Sub[0].pantoneID;
            Labdipvalues[0].PantoneNameOrSwatchName = Sub[0].pantoneNameOrSwatchName;
            Labdipvalues[0].PantoneColorOrOrSwatchImage = Sub[0].pantoneColorOrOrSwatchImage;
            Labdipvalues[0].FabricID = Sub[0].fabricID;
            if (Sub[0].isAllFabricLoad === 1) {
                await FabricService.GetFabricList(Sub[0].fabricID, "F").then((response) => {
                    ;
                    if (response.data) {
                        setFabricDetails(response.data)
                    }
                })
                // Labdipvalues[0].FabricID = Sub[0].fabricID;
            }
            Labdipvalues[0].PantoneTypeName = Sub[0].pantoneTypeName;
            Labdipvalues[0].GenralLabdipTNASubmissionAndApprovalId = Sub[0].submissionAndApprovalId;
            Labdipvalues[0].ApprovalStatus = Sub[0].approvalStatus;
            Labdipvalues[0].SubmitStatus = Sub[0].submitStatus;
            Labdipvalues[0].Remarks = Sub[0].remarks;

            let getDate = new Date(Sub[0].awbDate);
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = dd + '/' + mm + '/' + yyyy;



            let awbdate = Sub[0].awbDate === null ? '' : coverted;
            Labdipvalues[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
            Labdipvalues[0].SendTo = Sub[0].sendTo;
            Labdipvalues[0].SubmittedDate = new Date(Sub[0].submitDate);
            Labdipvalues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            Labdipvalues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            if (Sub[0].awbName === '') {//Sub for labdipValues
                setshowawb(true);
                Labdipvalues[0].AWBDateandNo = Sub[0].awbDate + ' / ' + Sub[0].awbName;
            }
            if (approvalStatus !== "Completed") {
                GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand,
                    Searchvalues.Season, Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                        if (response.data) {
                            let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                                if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            setMultiCloseDatas(data);
                            setOrderinfo([]);
                            setOrderinfo(data);
                            setsubmittedoption(response.data.generalTNALabdibSubmittedOption);


                        }
                    }).catch(() => { });
                setshowApproval(true);
            }
            else {
                Labdipvalues[0].ApprovalDate = new Date(Sub[0].approvalDate);
                Labdipvalues[0].ApprovalRemark = Sub[0].approvalRemark;

                GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand,
                    Searchvalues.Season, Searchvalues.Supplier, Follower,
                    TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                        if (response.data.generalLabdipPantoneSubInfo) {
                            let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                                if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });

                            setOrderinfo(data);

                            setsubmittedoption(response.data.generalTNALabdibSubmittedOption);
                            setshowApproval(true);
                        }
                    }).catch(() => { });

            }
            setlabdipInputFields(Labdipvalues);
        }
        else {
            Nodify('Warning!', 'warning', "Submission not yet added for this task");
            return false;
        }


    }
    const ViewApprovalLAb = (Id, approvalStatus, Follower, TaskId, PantoneId,
        GenLabdipId, GenLapdipProgramInfoId, index, Type, childIndex) => {
        setTab(0);
        let Optionvalue = [];
        let Operation;
        Labdipvalues[0].ApprovalDate = '';
        Labdipvalues[0].ApprovalRemark = '';
        Labdipvalues[0].ApprovalDateEdit = '';
        if (approvalStatus === "Pending") {
            Operation = 2;
            setshowMultiClosediv(true);
            setshowApprovaldiv(false);
            setheader('Add');
        }
        else {
            Operation = 4;
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            setheader('View');

        }
        if (Type === 'View') { setView(true) }
        else { setView(false) }
        setheader('View');
        ApprovedOption.forEach(element => {
            let LabelColor = '';
            if (element.value === 'Approved') {
                LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
            }
            else if (element.value === 'Approved with Comments') {
                LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
            }
            else if (element.value === 'Rejected') {
                LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
            }
            else if (element.value === 'Hold') {
                LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
            }


            Optionvalue.push({
                value: element.key,
                label: LabelColor,
                subLabel: element.value,
            })
        });

        setApprovedOptions(Optionvalue);
        GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });
        GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });
        var Main = labdipApprovalList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        // var Sub = labdipApprovalList[index].pantoneSubList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        // Sub.forEach(element => {
        //     if (element.genralLabdipTNASubmissionAndApprovalId === Id) {
        //         element.isSelected = 1;
        //     }
        // })
        var Sub = '';

        Labdipvalues[0].SupplierName = Sub[0].supplierName;
        Labdipvalues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        Labdipvalues[0].StoryName = Sub[0].storyName;
        Labdipvalues[0].ThemeName = Sub[0].themeName;
        Labdipvalues[0].EndCustomer = Sub[0].endCustomerNameIdList;
        Labdipvalues[0].PantoneCode = Sub[0].pantoneCode;
        Labdipvalues[0].RefColorName = Sub[0].pantoneReferColorName;
        Labdipvalues[0].PantoneID = Sub[0].pantoneID;
        Labdipvalues[0].PantoneNameOrSwatchName = Sub[0].pantoneNameOrSwatchName;
        Labdipvalues[0].PantoneColorOrOrSwatchImage = Sub[0].pantoneColorOrOrSwatchImage;
        Labdipvalues[0].FabricID = Sub[0].fabricID;
        Labdipvalues[0].PantoneTypeName = Sub[0].pantoneTypeName;
        Labdipvalues[0].GenralLabdipTNASubmissionAndApprovalId = Sub[0].genralLabdipTNASubmissionAndApprovalId;
        Labdipvalues[0].ApprovalStatus = Sub[0].approvalStatus;
        Labdipvalues[0].Remarks = Sub[0].remarks;

        let getDate = new Date(Sub[0].awbDate);
        let ddd = getDate.getDate();
        let mmm = getDate.getMonth() + 1;
        let yyyyy = getDate.getFullYear();

        if (ddd < 10) {
            ddd = '0' + ddd;
        }
        if (mmm < 10) {
            mmm = '0' + mmm;
        }
        let coverted = ddd + '/' + mmm + '/' + yyyyy;

        let awbdate = Sub[0].awbDate === null ? '' : coverted;
        Labdipvalues[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
        Labdipvalues[0].SendTo = '0';
        Labdipvalues[0].SubmittedDate = '';
        Labdipvalues[0].SwatchCardImageName = '';
        Labdipvalues[0].SwatchCardIamgePath = '';
        if (approvalStatus !== "Completed") {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand,
                Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (data) {
                            isSelectAll = !!!data.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        setMultiCloseDatas(data);
                        setsubmittedoption([]);
                        setsubmittedoption(response.data.generalTNALabdibSubmittedOption);
                        setshowApproval(true);

                    }
                }).catch(() => { });
        }
        else {
            let getDateValue = new Date(Sub[0].approvalDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = yyyy + '-' + mm + '-' + dd;
            Labdipvalues[0].ApprovalDate = '';
            Labdipvalues[0].ApprovalRemark = '';
            Labdipvalues[0].ApprovalDateEdit = coverted;

            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Follower,
                TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data.generalLabdipPantoneSubInfo) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });

                        setOrderinfo(data);
                        setsubmittedoption([]);
                        setsubmittedoption(response.data.generalTNALabdibSubmittedOption.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Labdipvalues[0].GenralLabdipTNASubmissionAndApprovalId));
                        setsubmittedoption(response.data.generalTNALabdibSubmittedOption)
                        setshowApproval(true);
                    }
                }).catch(() => { });
        }
        setlabdipInputFields(Labdipvalues);


    }
    //#endregion
    //#region LABDIPSUBMISSION
    const searchLabDipsub = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 1, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralLapdipSubmissionApprovalList(params));

        // let params = {
        //     BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId, SeasonId: labdipViewDetailParams.SeasonId,
        //     BuyerName: labdipViewDetailParams.BuyerName, BrandName: labdipViewDetailParams.BrandName, SeasonName: labdipViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralLapdipDetailsViewList(params));
        commonParentExpand();
    }
    const handleLabdipsubManualclose = (Id, SubmitStatus, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index) => {
        showLoader();
        setindex(index);
        let Operation;
        setComment(false);

        if (SubmitStatus === "Pending") {
            Operation = 1;
            setheader('Add');
        }
        else {
            Operation = 3;
            setheader('View');

        }
        if (SubmitStatus !== "Completed") {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season
                , Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, 0).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        // let isSelectAll = 0;
                        // if (data) {
                        //     isSelectAll = !!!data.find(d => d.isSelected === 0);
                        //     isSelectAll = isSelectAll ? 1 : 0;
                        // }
                        // setselectall(isSelectAll);
                        setManualInfo(data);
                        setMultiCloseDatas(data);
                        setshowManual(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }


                }).catch(() => {
                    hideLoader();
                });
        }
        else {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season
                , Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        // let isSelectAll = 0;
                        // if (data) {
                        //     isSelectAll = !!!data.find(d => d.isSelected === 0);
                        //     isSelectAll = isSelectAll ? 1 : 0;
                        // }
                        // setselectall(isSelectAll);
                        setshowManual(true);
                        setManualInfo(data);
                        setMultiCloseDatas(data);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { setshowManual(true); hideLoader(); });
        }
    }
    const ViewSubmission = (Id, SubmitStatus, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index, Type, childIndex) => {
        setTab(0);
        setInputFields(emptyfeilds);
        let Operation;
        Operation = 3;
        if (Type === 'View') { setView(true) }
        else { setView(false) }
        setheader('View');

        GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });
        GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = labdipSubmissionList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        // var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[childIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
        // Sub.forEach(element => {
        //     if (element.SubmissionAndApprovalId === props.SubmissionAndApprovalId) {
        //         element.isSelected = 1;
        //     }
        // })
        //clear and fetch new while popup open
        Labdipvalues[0].SubmittedDateEdit = '';
        Labdipvalues[0].SendTo = '0';
        Labdipvalues[0].SendThrough = 0;
        Labdipvalues[0].SwatchCardImageName = '';
        Labdipvalues[0].SwatchCardIamgePath = '';
        Labdipvalues[0].Remarks = '';
        Labdipvalues[0].AWBDateandNo = '';
        Labdipvalues[0].AWBDate = '';
        Labdipvalues[0].AWBName = '';
        Labdipvalues[0].AWBDateEdit = '';
        setshowawb(false);


        Labdipvalues[0].SupplierName = '';
        Labdipvalues[0].BuyerBrandSeasonName = '';
        Labdipvalues[0].StoryName = '';
        Labdipvalues[0].ThemeName = '';
        Labdipvalues[0].EndCustomer = '';
        Labdipvalues[0].PantoneCode = '';;
        Labdipvalues[0].RefColorName = '';
        Labdipvalues[0].PantoneID = '';
        Labdipvalues[0].PantoneNameOrSwatchName = '';
        Labdipvalues[0].PantoneColorOrOrSwatchImage = '';
        Labdipvalues[0].FabricID = '';
        Labdipvalues[0].PantoneTypeName = '';
        Labdipvalues[0].GenralLabdipTNASubmissionAndApprovalId = '';
        Labdipvalues[0].SubmitStatus = '';

        Labdipvalues[0].SubmittedDate = '';
        // Sub[0].submitDate
        let getDateValue = new Date();
        var dd = getDateValue.getDate();
        var mm = getDateValue.getMonth() + 1;
        var yyyy = getDateValue.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = yyyy + '-' + mm + '-' + dd;
        Labdipvalues[0].SubmittedDateEdit = coverted;
        Labdipvalues[0].SendTo = '';
        Labdipvalues[0].SendThrough = '';
        Labdipvalues[0].SwatchCardImageName = '';
        Labdipvalues[0].SwatchCardIamgePath = '';
        Labdipvalues[0].Remarks = '';

        //if (parseInt(Sub[0].sendThrough) === 2 && Sub[0].awbName !== '') {
        if (parseInt(Labdipvalues[0].SendThrough) === 2 && Labdipvalues[0].awbName !== '') {
            setshowawb(true);
            Labdipvalues[0].AWBDateandNo = '' + ' / ' + '';
            Labdipvalues[0].AWBDate = '';
            Labdipvalues[0].AWBName = '';
            // Sub[0].awbDate
            let getDate = new Date();
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = yyyy + '-' + mm + '-' + dd;
            Labdipvalues[0].AWBDateEdit = coverted;
        }
        GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Follower,
            TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                if (response.data.generalLabdipPantoneSubInfo) {
                    let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                        if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });

                    setOrderinfo(data);

                    var optios = [];
                    //
                    response.data.generalTNALabdibSubmittedOption.forEach(element => {
                        optios.push({

                            GeneralLabdibSubmittedOptionId: element.generalLabdibSubmittedOptionId,
                            GenralLabdipTNASubmissionAndApprovalId: element.genralLabdipTNASubmissionAndApprovalId,
                            GenLapdipProgramInfoId: element.genLapdipProgramInfoId,
                            OptionName: element.optionName,
                            SupplierReferenceNo: element.supplierReferenceNo
                        })
                    })

                    setfirstsubapprid(
                        {
                            SubApprId: response.data.generalTNALabdibSubmittedOption[0].genralLabdipTNASubmissionAndApprovalId,
                            GenLapdipProgramInfoId: response.data.generalTNALabdibSubmittedOption[0].genLapdipProgramInfoId

                        })
                    setsubmittedoption(optios);
                    setshowSubmission(true);
                }
            }).catch(() => { });

        setlabdipInputFields(Labdipvalues);
    }

    const OpenLabdipSubmssionpopup = async (Id, SubmitStatus, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index, subIndex, childIndex) => {

        setTab(0);
        setindex(index);
        setGenlabdipId(GenLabdipId)
        setindexLabdip({ index: index, subIndex: subIndex, ID: Id })
        //  var empty = [...emptyfeilds]
        //   setInputFields(empty);
        Labdipvalues[0].AWBDateandNo = ''
        Labdipvalues[0].SubmittedDate = '';
        Labdipvalues[0].SendTo = '';
        Labdipvalues[0].SendToEdit = '';
        Labdipvalues[0].SendThrough = 0;
        Labdipvalues[0].SwatchCardImageName = '';
        Labdipvalues[0].SwatchCardIamgePath = '';
        Labdipvalues[0].Remarks = '';
        setshowawb(false);
        setSubmittedpopup(false);
        let Operation;
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setshowMultiClosediv(true);
            setshowSubmissiondiv(false);
            setheader('Add');
        }
        else {
            Operation = 3;
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            setheader('View');

        }
        if (SubmitStatus === "Pending") {
            setsubmittedoption([]);
            var options = [];
            options.push(
                { OptionName: "A", SupplierReferenceNo: "", GeneralLabdibSubmittedOptionId: 0 },
                { OptionName: "B", SupplierReferenceNo: "", GeneralLabdibSubmittedOptionId: 0 },
                { OptionName: "C", SupplierReferenceNo: "", GeneralLabdibSubmittedOptionId: 0 },
                { OptionName: "D", SupplierReferenceNo: "", GeneralLabdibSubmittedOptionId: 0 },
            )
            setsubmittedoption(options);
        }
        await GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });
        await GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = labdipSubmissionList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        //var Sub = labdipSubmissionList[index].pantoneSubList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.submissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })

        Labdipvalues[0].SupplierName = Sub[0].supplierName;
        Labdipvalues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        Labdipvalues[0].StoryName = Sub[0].storyName;
        Labdipvalues[0].ThemeName = Sub[0].themeName;
        Labdipvalues[0].EndCustomer = Sub[0].endCustomerNameIdList;
        Labdipvalues[0].PantoneCode = Sub[0].pantoneCode;
        Labdipvalues[0].RefColorName = Sub[0].pantoneReferColorName;
        Labdipvalues[0].PantoneID = Sub[0].pantoneID;
        Labdipvalues[0].PantoneNameOrSwatchName = Sub[0].pantoneNameOrSwatchName;
        Labdipvalues[0].PantoneColorOrOrSwatchImage = Sub[0].pantoneColorOrOrSwatchImage;
        if (getheader === "Add") {
            Labdipvalues[0].FabricID = Sub[0].fabricID;
        } else {
            await FabricService.GetFabricList(Sub[0].fabricIDSub, "F").then((response) => {
                ;
                if (response.data) {
                    setFabricDetails(response.data)
                }
            })
            Labdipvalues[0].FabricID = Sub[0].fabricIDSub;
        }
        Labdipvalues[0].IsAllFabricLoad = Sub[0].isAllFabricLoad;
        Labdipvalues[0].PantoneTypeName = Sub[0].pantoneTypeName;
        Labdipvalues[0].GenralLabdipTNASubmissionAndApprovalId = Sub[0].submissionAndApprovalId;
        Labdipvalues[0].SubmitStatus = Sub[0].submitStatus;
        if (SubmitStatus !== "Completed") {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, 0).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (data) {
                            isSelectAll = !!!data.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        setMultiCloseDatas(data);
                        setshowSubmission(true);

                    }
                }).catch(() => { });
        }
        else {
            Labdipvalues[0].SubmittedDate = new Date(Sub[0].submitDate);
            Labdipvalues[0].SendTo = Sub[0].sendTo;
            Labdipvalues[0].SendToEdit = Sub[0].lapdipSendToNames;
            Labdipvalues[0].SendThrough = parseInt(Sub[0].sendThrough);
            Labdipvalues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            Labdipvalues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            Labdipvalues[0].Remarks = Sub[0].remarks;
            if (parseInt(Sub[0].sendThrough) === 2) {
                setshowawb(true);
                let getDate = new Date(Sub[0].awbDate);
                let dd = getDate.getDate();
                let mm = getDate.getMonth() + 1;
                let yyyy = getDate.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                let coverted = dd + '/' + mm + '/' + yyyy;
                Labdipvalues[0].AWBDateandNo = Sub[0].awbDate === null ? '' : coverted + ' / ' + Sub[0].awbName;
                Labdipvalues[0].AWBDate = new Date(Sub[0].awbDate);
                Labdipvalues[0].AWBName = Sub[0].awbName;
            }
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data.generalLabdipPantoneSubInfo) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });

                        setOrderinfo(data);

                        var optios = [];
                        //
                        response.data.generalTNALabdibSubmittedOption.forEach(element => {
                            optios.push({
                                GeneralLabdibSubmittedOptionId: element.generalLabdibSubmittedOptionId,
                                OptionName: element.optionName,
                                SupplierReferenceNo: element.supplierReferenceNo
                            })
                        })
                        setsubmittedoption(optios);
                        setshowSubmission(true);
                    }
                }).catch(() => { });
        }

        setlabdipInputFields(Labdipvalues);
    }
    const labdipSaveAwbnoDate = () => {

        Labdipvalues[0].AWBDateandNo = Labdipvalues[0].AWBName + " / " + new Date(Labdipvalues[0].AWBDate).toLocaleDateString('en-GB');
        setlabdipInputFields(Labdipvalues);
        setShowlabdipPopup(false);


    }
    const labdiphandlepopupchange = (event, feild) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "AWBName") {
            Labdipvalues[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            Labdipvalues[0].AWBDate = input;
        }
        setlabdipInputFields(Labdipvalues);
    }

    const ReloadSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: '',
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        let approvalparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralLapdipApprovalList(approvalparams));
        dispatch(GetGeneralLapdipSubmissionApprovalList(params));


    }
    const AddRows = () => {

        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setOrderinfo(FileteredRecords);
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
        }
    }
    const handleRemoveOrderInfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }
    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    // if (props.location.state !== undefined) {

    //     BuyerId = props.location.state.params.buyerId === undefined ? 0 : props.location.state.params.buyerId;
    //     BrandId = props.location.state.params.brandId === undefined ? 0 : props.location.state.params.brandId;
    //     SeasonId = props.location.state.params.seasonId === undefined ? 0 : props.location.state.params.seasonId;

    // }
    const SaveSubmission = () => {
        ;
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })

        if (Labdipvalues[0].SubmittedDate === '' || Labdipvalues[0].SendTo === "" || Labdipvalues[0].SendThrough === 0 ||
            Labdipvalues[0].FabricID === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }
        // else if ((parseInt(Labdipvalues[0].SendThrough) === 2 && Labdipvalues[0].AWBDateandNo === '')) {
        //     Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        // }
        else {
            Labdipvalues[0].SubmittedDate = new Date(Labdipvalues[0].SubmittedDate.setMinutes(Labdipvalues[0].SubmittedDate.getMinutes() + 370))
            Labdipvalues[0].Operation = 1;
            Labdipvalues[0].GeneralTNALabdibOrderInfo = getOrderinfo;
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    Labdipvalues[0].GeneralTNALabdibSubmittedOption.push({
                        GeneralLabdibSubmittedOptionId: opt.GeneralLabdibSubmittedOptionId,
                        GenralLabdipTNASubmissionAndApprovalId: ordinf.genralLabdipTNASubmissionAndApprovalId,
                        GenLapdipProgramInfoId: ordinf.genLapdipProgramInfoId,
                        OptionName: opt.OptionName,
                        SupplierReferenceNo: opt.SupplierReferenceNo

                    })
                })
            })

            GeneralService.InsertUpdateGeneralLabdipSubmissionApproval(Labdipvalues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Added Successfully');
                    setshowSubmission(false);
                    searchLabDipsub();
                    handleToggle(getindex);
                    ReloadSubmission();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowSubmission(false);
                    searchLabDipsub();
                    handleToggle(getindex);
                    ReloadSubmission();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const OnChangeLabdipOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].selectedDetail = 1;
            }
        }
        else {
            orderinfo[index].selectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedGenLabdipId = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedDetail = element.fabricID;
                SelectedGenLabdipId = element.genLabdipId;
            }
        })

        GeneralService.LoadGeneralLabdipFabricDetailsList(SelectedGenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });

        Labdipvalues[0].FabricID = SelectedDetail;

        setlabdipInputFields(Labdipvalues);
    }
    const OnChangeOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].selectedDetail = 1;
            }
        }
        else {
            orderinfo[index].selectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedGenLabdipId = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedDetail = element.fabricID;
                SelectedGenLabdipId = element.genLabdipId;
            }
        })

        GeneralService.LoadGeneralLabdipFabricDetailsList(SelectedGenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });

        TrimValues[0].FabricID = SelectedDetail;

        setTrimInputFields(TrimValues);
    }
    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowSubmissiondiv(false);
    }
    const UploadImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    Labdipvalues[0].SwatchCardImageName = file.name;
                    Labdipvalues[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setlabdipInputFields(Labdipvalues);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const UploadstrikeImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    strikeoffvalues[0].SwatchCardImageName = file.name;
                    strikeoffvalues[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setstrikeoffInputFields(strikeoffvalues);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const UploadTrimImages = async (e) => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    TrimValues[0].SwatchCardImageName = file.name;
                    TrimValues[0].SwatchCardIamgePath = "Images/TNAImages/" + file.name;
                    setTrimInputFields(TrimValues);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    let styles1s = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2s = {
        control: styles2 => ({ ...styles2, width: "230px", border: '1px solid red' }),
    }
    const handlemultidd = (event, index) => {
        if (event.length === 0) {
            Labdipvalues[0].SendTo = "";
            Labdipvalues[0].SendToEdit = '';
            // Labdipvalues[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            Labdipvalues[0].SendTo = strValue;
            Labdipvalues[0].SendToEdit = event;
        }

        setlabdipInputFields(Labdipvalues);
    }
    const Strkehandlemultidd = (event, index) => {
        if (event.length === 0) {
            strikeoffvalues[0].SendTo = "";
            strikeoffvalues[0].SendToEdit = '';
            // strikeoffvalues[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            strikeoffvalues[0].SendTo = strValue;
            strikeoffvalues[0].SendToEdit = event;
        }

        setstrikeoffInputFields(strikeoffvalues);
    }
    const trimhandlemultidd = (event, index) => {
        if (event.length === 0) {
            TrimValues[0].SendTo = "";
            TrimValues[0].SendToEdit = '';
            // TrimValues[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            TrimValues[0].SendTo = strValue;
            TrimValues[0].SendToEdit = event;
        }

        setTrimInputFields(TrimValues);
    }
    const ontransfer = async (event, FabricID) => {

        setFabricDetails([]);
        if (event.target.checked === true) {
            Labdipvalues[0].IsAllFabricLoad = 1;
            await FabricService.GetFabricList(0, "F").then((response) => {
                ;
                if (response.data) {
                    setFabricDetails(response.data)
                }
            })
        }
        if (event.target && event.target.checked === false) {
            Labdipvalues[0].IsAllFabricLoad = 0;
            //Labdipvalues[0].FabricID = '0';
            await GeneralService.LoadGeneralLabdipFabricDetailsList(GenlabdipId).then((response) => {
                if (response.data) {
                    setFabricDetails(response.data);
                }
            }).catch(() => { });
            var Sub = genTNAReportList && genTNAReportList.genTNADetailList[getindexLabdip.index].genTNAParentChildList[getindexLabdip.subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === getindexLabdip.ID);
            Labdipvalues[0].FabricID = Sub[0].fabricID;
        }

        setlabdipInputFields(Labdipvalues)
    }
    const handleChange = (event, feild) => {

        let input = '';

        if (feild === "SubmittedDate") {
            Labdipvalues[0].SubmittedDate = event;
        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            Labdipvalues[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            Labdipvalues[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            Labdipvalues[0].Remarks = input;
        }
        else if (feild === "FabricID") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            Labdipvalues[0].FabricID = parseInt(input);
        }
        setlabdipInputFields(Labdipvalues);
    }
    const TabChange = (val) => {
        setTab(val);
    }
    const handleSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            submittedoption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            submittedoption[index].SupplierReferenceNo = input;
        }
        setInputFields(values);

    }
    const handleRemoveFields = index => {
        submittedoption.splice(index, 1);
        setInputFields(values);
    };
    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (submittedoption[index].OptionName === '' || submittedoption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            submittedoption.push({ OptionName: '', SupplierReferenceNo: '', GeneralLabdibSubmittedOptionId: 0 });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }
    }
    const openFileUpload = () => {
        $('#upload-button').trigger('click');
    }
    var ImgClassName = "upload-btn-wrapper2"
    const handleCloseManualPopup = () => {
        setshowManual(false);
    }
    const handleChangeIsManualCloseComments = (e, feild) => {
        var Manual = [...ManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].manualCloseComment = input;
        }
        else {
            Manual[0].manualCloseDate = e;
        }
        setManualInfo(Manual);

    }
    const handleChangeIsTrimAppManualCloseComments = (e, feild) => {
        var Manual = [...ApprovalManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].approvalManualCloseComment = input;
        }
        else {
            Manual[0].approvalManualCloseDate = e;
        }
        setApprovalManualInfo(Manual);

    }
    const handleCloseManualTrimPopup = () => {
        setShowTrimsub(false);
    }
    const handleChangeIsTrimsubManualCloseComments = (e, feild) => {
        var Manual = [...trimManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].manualCloseComment = input;
        }
        else {
            Manual[0].manualCloseDate = e;
        }
        setTrimManualInfo(Manual);

    }

    const handleChangeIsStrikeappManualCloseComments = (e, feild) => {
        var Manual = [...ApprovalManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].approvalManualCloseComment = input;
        }
        else {
            Manual[0].approvalManualCloseDate = e;
        }
        setApprovalManualInfo(Manual);

    }
    const handleChangeStrikeSubIsManualCloseComments = (e, feild) => {
        var Manual = [...StrikeoffManualMnfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].manualCloseComment = input;
        }
        else {
            Manual[0].manualCloseDate = e;
        }
        setStrikeoffManualMnfo(Manual);

    }
    const handleLabappManualCloseComments = (e, feild, Id) => {
        let Manual = [...ApprovalManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].approvalManualCloseComment = input;
        }
        else {
            Manual[0].approvalManualCloseDate = e;
        }
        setApprovalManualInfo(Manual);

    }
    const ManualCloseCustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || ManualInfo[0].manualCloseDate ? '' : '1px solid red' }}
            />
        )

    }
    const ManualStrikeCloseCustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || StrikeoffManualMnfo[0].manualCloseDate ? '' : '1px solid red' }}
            />
        )

    }
    const ManualTrimCloseCustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || trimManualInfo[0].manualCloseDate ? '' : '1px solid red' }}
            />
        )

    }
    const SaveChildlabappManulaCloseComments = () => {
        if ((!ApprovalManualInfo[0].approvalManualCloseComment) || (!ApprovalManualInfo[0].approvalManualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genralLabdipTNAProgramDetailId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genralLabdipTNAProgramDetailId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                var ManualCloseData = [...ApprovalManualInfo];

                ManualCloseData[0].ManualCloseDate = new Date(ManualCloseData[0].approvalManualCloseDate);
                ManualCloseData[0].ManualCloseDate.setMinutes(ManualCloseData[0].approvalManualCloseDate.getMinutes() + 370);

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ManualCloseData[0].approvalManualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 2;
                GeneralService.UpdateGeneralLabdipManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Approval");
                        setshowladappmanual(false);
                        setSubmitshowManual(false);
                        //searchText();
                        searchLabDip();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Approval");
                        setshowladappmanual(false);
                        setSubmitshowManual(false);
                        // searchText();
                        searchLabDip();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }
    const SaveChildManulaCloseCommentss = (index, subIndex) => {
        if ((!ApprovalManualInfo[0].approvalManualCloseComment) || (!ApprovalManualInfo[0].approvalManualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            // for (let data of MultiCloseDatas) {
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genStrikeOffDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genStrikeOffDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                let ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];
                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(ApprovalManualInfo[0].approvalManualCloseDate));

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ApprovalManualInfo[0].approvalManualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 2;
                GeneralService.UpdateGeneralStrikeOffManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        //dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setStrikeOffAppManual(false);
                        //searchText();
                        // searchStrikeOff();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        //dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setStrikeOffAppManual(false);
                        // searchText();
                        // searchStrikeOff();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }
    const SaveChildTrimManulaCloseComments = () => {
        if ((!trimManualInfo[0].manualCloseComment) || (!trimManualInfo[0].manualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genTrimDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genTrimDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");

            } else {

                var ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];

                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(trimManualInfo[0].manualCloseDate));
                // ManualCloseData[0].ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);

                ManualCloseData[0].ManualCloseComment = trimManualInfo[0].manualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 1;

                GeneralService.UpdateGeneralTrimManualInfo(ManualCloseData[0]).then((res) => {
                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        setShowTrimsub(false);
                        //searchTrim();
                        handleToggle(getindex);
                        ReloadSubmission();
                        hideLoader();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        setShowTrimsub(false);
                        //searchTrim();
                        handleToggle(getindex);
                        //ReloadSubmission();
                        hideLoader();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                        hideLoader();
                    }
                }).catch(e => {
                    console.log(e);
                    hideLoader();

                });
            }

        }

    }

    const SaveChildManulaCloseComments = () => {
        if ((!ManualInfo[0].manualCloseComment) || (!ManualInfo[0].manualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genralLabdipTNAProgramDetailId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genralLabdipTNAProgramDetailId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                var ManualCloseData = [...ManualInfo];

                ManualCloseData[0].manualCloseDate = new Date(ManualCloseData[0].manualCloseDate);
                ManualCloseData[0].manualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ManualCloseData[0].manualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 1;
                GeneralService.UpdateGeneralLabdipManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Submission");
                        setshowManual(false);
                        searchLabDip();
                        handleToggle(getindex);
                        ReloadSubmission();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Submission");
                        setshowManual(false);
                        searchLabDip();
                        handleToggle(getindex);
                        ReloadSubmission();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }
    //----------------------------------------------------END SUBMISSION-------------------------------------------------------------------------------
    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index) => {
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            }
            let taskRow = genTNAReportList && genTNAReportList.genTNADetailList[index];
            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskTypeId: taskRow.taskTypeId, TaskId: taskRow.taskId, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate, Operation: 2, Index: index,
                ChildIndex: 0, MainId: 0, ColorImageId: 0, IsProduction: IsProduction

            };
            // setSearchvaluesToSub(params);
            dispatch(loadGenTNAReportList(params));
            Searchvalues.Operation = 2;
            Searchvalues.CurrentIndex = index;
            setSearchvalues(Searchvalues);
        }
        else {
            let expandedParams = { index: index, isExpanded: false };
            dispatch(updateGenTNAReportParentIsExpanded(expandedParams));
            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        }
    };


    const handleChildToggle = (index, childIndex, mainId, colorImageId) => {
        let btnToggle = document.getElementById("btn-toggle_parentChild_" + index + "_" + childIndex);
        let iconToggle = document.getElementById("icon-toggle_parentChild_" + index + "_" + childIndex);
        let rowHiddens = document.querySelectorAll(".parentChild_" + index + "_" + childIndex);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            let taskRow = genTNAReportList.genTNADetailList[index];
            let expandedParams = { index: index, childIndex: childIndex, isExpanded: true };
            dispatch(updateGenTNAReportIsExpanded(expandedParams));
            // values[index].SubTaskList[childIndex].isExpanded = true;

            for (let row of rowHiddens) {
                row.className = row.className.replace("hidden", " ");
            }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");


            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
            }

            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskTypeId: taskRow.taskTypeId, TaskId: taskRow.taskId, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate,
                Operation: 3, Index: index, ChildIndex: childIndex, MainId: mainId, ColorImageId: colorImageId, IsProduction: IsProduction
            };

            // setSearchvaluesToSub(params);
            dispatch(loadGenTNAReportList(params));

            // Searchvalues.Operation = 3;
            // Searchvalues.CurrentIndex = index;
            // Searchvalues.CurrentChildIndex = childIndex;
            // setSearchvalues(Searchvalues);
        }
        else {
            for (let row of rowHiddens) {
                if (!row.className.includes("hidden")) {
                    row.className = row.className + ' hidden';
                }
            }

            // values[index].SubTaskList[childIndex].IsExpanded = false;
            let expandedParams = { index: index, childIndex: childIndex, isExpanded: false };
            dispatch(updateGenTNAReportIsExpanded(expandedParams));

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFields(values);
    };


    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }
    // #endregion  ------------------  Expanded Toggle End --------------------------------


    // const handleChangeTNAComments = (event, index, feild) => {
    //     //  const values = { ...inputFields };

    //     if (feild === 'Comment') {
    //         values[index].Comments = event.target.value;
    //     }
    //     else {
    //         values[index].ManualCloseDate = event;
    //     }
    //     setInputFields(values);
    // }

    // const handleSave = (index, Childindex, assignValue, TaskID, TaskName, Id) => {
    //     let IsValid = true;
    //     if (assignValue === '' || assignValue === undefined) {
    //         setComment(true); IsValid = false;
    //         Nodify('Warning!', 'warning', "Please enter comments");
    //     }
    //     if (IsValid) {
    //         if (values[index].Comments.length !== 0) {
    //             if (values[index].Comments !== '') {
    //                 if (EditedCommentIndex !== '') {
    //                     values[index].arrComment[Childindex - 1].TNAComments = assignValue;
    //                 }
    //                 else {
    //                     values[index].arrComment.push({
    //                         TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                         Date: new Date(),

    //                     });
    //                 }
    //             }
    //             else {
    //                 values[index].arrComment = {
    //                     TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                     Date: new Date(),
    //                 };
    //             }
    //         }
    //         else {
    //             values[index].arrComments.push({
    //                 TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
    //                 Date: new Date(),
    //             });
    //         }
    //         values[index].Comments = '';
    //         values[index].IsComment = 0;
    //         values[index].CommentsCount = values[index].arrComment.length;
    //         setInputFields(values);
    //         setEditedCommentIndex('');
    //     }

    //     let ChildStatus = 0;
    //     if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
    //         ChildStatus = 1;
    //     }
    //     else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
    //         TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //         ChildStatus = 2;

    //     } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
    //         TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

    //         ChildStatus = 3;

    //     } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
    //         TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
    //         ChildStatus = 4;
    //     }
    //     else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
    //         TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //         ChildStatus = 5;
    //     }

    //     var ManualClose = { Id: 0, TaskStatus: 0, ManualCloseComment: '' }
    //     ManualClose.Id = Id;
    //     ManualClose.TaskStatus = ChildStatus;
    //     ManualClose.ManualCloseComment = assignValue;
    //     TnaService.UpdateTaskManualCloseDetails(ManualClose).then(res => {
    //         if (res.data.outputResult === "1") {
    //             Nodify('Success!', 'success', 'Task Manually Closed Successfully');
    //         }
    //         else if (res.data.outputResult === "0") {
    //             Nodify("Error!", "Error Occured!");
    //         }
    //     });


    // };









    const handleDependent = (index, value) => {

        values[index].intDependency = value === 0 ? 1 : 0;
        setInputFields(values);
    }





    //#endregion ------------------  Dependency End --------------------------------


    // #region  ------------------  Child Start --------------------------------


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                style={{ width: "100%", border: getComment && props.value === '' ? '1px solid red' : '' }}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }




    //#region STRIKEOFFSUBMISSION
    const SaveChildStrikesubManulaCloseComments = () => {
        if ((!StrikeoffManualMnfo[0].manualCloseComment) || (!StrikeoffManualMnfo[0].manualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genStrikeOffDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genStrikeOffDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");

            } else {

                var ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];

                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(StrikeoffManualMnfo[0].manualCloseDate));
                // ManualCloseData[0].ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);

                ManualCloseData[0].ManualCloseComment = StrikeoffManualMnfo[0].manualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 1;

                GeneralService.UpdateGeneralStrikeOffManualInfo(ManualCloseData[0]).then((res) => {
                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        setShowSOManual(false);
                        //searchStrikeOff();
                        handleToggle(getindex);
                        ReloadSubmission();
                        hideLoader();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        setShowSOManual(false);
                        //searchStrikeOff();
                        handleToggle(getindex);
                        //ReloadSubmission();
                        hideLoader();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                        hideLoader();
                    }
                }).catch(e => {
                    console.log(e);
                    hideLoader();

                });
            }

        }

    }
    const AddStrikeoffsubRows = () => {
        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            // let data = [...new Map(FileteredRecords.map(item =>
            //     [item['strikeoffId'], item])).values()];
            let index = 0;
            FileteredRecords.forEach(element => {
                index = index + 1;
                element.index = index;
            })

            let data = [...new Map(FileteredRecords.map(item =>
                [item['strikeOffLogoId'], item])).values()];

            setOrderInfoMainDetails(data);
            setOrderinfo(FileteredRecords);
            handleExpandRow(FileteredRecords[0].strikeoffLogoPath, FileteredRecords);

            var StrikeoffDim = [];
            FileteredRecords.map(Fr => {
                StrikeoffDimension.map(Td => {
                    if (Fr.strikeOffRefernceId === Td.id) {
                        StrikeoffDim.push({
                            id: Td.id,
                            name: Td.name,
                            value: Td.value,
                            label: Td.label
                        })
                    }

                })
            })

            let uniqueStrikeoffDim = [...new Map(StrikeoffDim.map((item) => [item["id"], item])).values()];

            setStrikeoffDimension(uniqueStrikeoffDim);
        }
    }
    const SaveStrikeoffAwbnoDate = () => {
       
        strikeoffvalues[0].AWBDateandNo = strikeoffvalues[0].AWBName + " / " + new Date(strikeoffvalues[0].AWBDate).toLocaleDateString('en-GB');
        setstrikeoffInputFields(strikeoffvalues);
        setShowStrikePopup(false);


    }
    const handleSizePopupClose = () => {

        setShowSizePopup({ isPopup: false, detail: '' });

    }
    const handleStrikeoffpopupchange = (event, feild) => {
       
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "AWBName") {
            strikeoffvalues[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            strikeoffvalues[0].AWBDate = input;
        }
        setstrikeoffInputFields(strikeoffvalues);
    }
    const ReloadStrikeoffSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let reqparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: '',
            SearchText: SearchReqValues.SearchTxt, Operation: 1, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralStrikeOffSubmissionApprovalGridList(reqparams));

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralStrikeOffApprovalList(params));
    }
    const SaveStrikeoffSubmission = () => {
       ;
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })
        if (parseInt(strikeoffvalues[0].SendThrough) === 1) {
            strikeoffvalues[0].AWBName = '';
            strikeoffvalues[0].AWBDate = ''
        }

        if (strikeoffvalues[0].SubmittedDate === '' || strikeoffvalues[0].SendTo === "" || strikeoffvalues[0].SendThrough === 0 ||
            strikeoffvalues[0].StrikeoffDimensionId === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }
        // else if ((parseInt(strikeoffvalues[0].SendThrough) === 2 && strikeoffvalues[0].AWBDateandNo === '')) {
        //     Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        // }

        else {

            strikeoffvalues[0].SubmittedDate = new Date(strikeoffvalues[0].SubmittedDate.setMinutes(strikeoffvalues[0].SubmittedDate.getMinutes() + 370))
            strikeoffvalues[0].Operation = 1;
            strikeoffvalues[0].GeneralTNAStrikeoffOrderInfo = getOrderinfo;
            strikeoffvalues[0].GeneralTNAStrikeOffSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    strikeoffvalues[0].GeneralTNAStrikeOffSubmittedOption.push({
                        GeneralStrikeOffSubmittedOptionId: opt.GeneralStrikeOffSubmittedOptionId,
                        GenralStrikeOffTNASubmissionAndApprovalId: ordinf.genStrikeOffTNASubmissionAndApprovalId,
                        GenStrikeOffId: ordinf.genStrikeOffId,
                        GenStrikeOffLogoId: ordinf.genStrikeOffLogoID,
                        GenStrikeOffRefernceId: ordinf.genStrikeOffRefernceID,
                        OptionName: opt.OptionName,
                        SupplierReferenceNo: opt.SupplierReferenceNo

                    })
                })
            })

            GeneralService.InsertUpdateGeneralStrikeOffSubmissionApproval(strikeoffvalues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Added Successfully');
                    setshowStrikeoffSubmission(false);
                    // searchStrikeOff();
                    handleToggle(getindex);
                    ReloadStrikeoffSubmission();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowStrikeoffSubmission(false);
                    // searchStrikeOff();
                    handleToggle(getindex);
                    ReloadStrikeoffSubmission();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const OnChangeStrikeoffOrderinfo = (event, index) => {
        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        let SelectedColorGroup = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].SelectedDetail = 1;
            }
        }
        else {
            orderinfo[index].SelectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let GenStrikeOffMainId = 0;
        orderinfo.forEach(element => {
            if (element.SelectedDetail === 1) {
                SelectedDetail = element.strikeOffRefernceId;
                GenStrikeOffMainId = element.genStrikeOffMainId;
                SelectedColorGroup = element.colorGroupId;
            }
        })

        GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setStrikeoffDimension(response.data);
            }
        }).catch(() => { });

        strikeoffvalues[0].StrikeoffDimensionId = SelectedDetail;
        strikeoffvalues[0].ColorGroupId = SelectedColorGroup;

        setstrikeoffInputFields(strikeoffvalues);
    }
    const handleStrikeSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            submittedoption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            submittedoption[index].SupplierReferenceNo = input;
        }
        setstrikeoffInputFields(strikeoffvalues);

    }
    const handleStrikeStyleDetails = (detail, strikeOffGarmentSize) => {
        const sizeFilter = sizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        setSizeList(sizeFilter);
        setShowSizePopup({ isPopup: true, detail: detail });

    }
    const StrikeoffSelectAll = (event) => {
        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const StrikeoffOnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }
    const handleExpandRow = (strikeoffLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.strikeoffLogoPath === strikeoffLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(strikeoffLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeoffLogoPath] = false) : (obj[strikeoffLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeoffLogoPath) :
            currentExpandedRows.concat(strikeoffLogoPath);
        setExpandedRows(newExpandedRows);


    }

    const handleRemoveOrderinfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }
    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getSubImageList.length - 1) {
            setCurrimageindex(getSubImageList.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }
    const handleChangeStrikeoffSubmissionMain = (event, feild) => {

        let input = '';

        if (feild === "SubmittedDate") {
            strikeoffvalues[0].SubmittedDate = event;
        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            strikeoffvalues[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            strikeoffvalues[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            strikeoffvalues[0].Remarks = input;
        }
        else if (feild === "StrikeoffDimensionId") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            strikeoffvalues[0].StrikeoffDimensionId = input;
        }
        setstrikeoffInputFields(strikeoffvalues);
    }
    const handleStrikeoffStyleDetails = (detail, strikeOffGarmentSize) => {
        const sizeFilter = sizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        setSizeList(sizeFilter);
        setShowSizePopup({ isPopup: true, detail: detail });

    }
    const handleStrikeManualclose = (Id, SubmitStatus, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, GenStrikeOffRefernceID, index) => {
        showLoader();
        setindex(index);
        let Operation;
        setComment(false);
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setheader('Add');
        }
        else {
            Operation = 3;
            setheader('View');
        }
        setShowSOManual(true);
        if (SubmitStatus !== 'Completed') {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, 0).then((response) => {
                    if (response.data) {
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                return item;
                            }
                        });

                        setStrikeoffManualMnfo(data);
                        setMultiCloseDatas(data);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        else {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                    if (response.data) {
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                return item;
                            }
                        });

                        setStrikeoffManualMnfo(data);
                        setMultiCloseDatas(data);
                        setShowSOManual(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { setShowSOManual(true); hideLoader(); });
        }

    }

    const OpenStrikeOffSubmssionpopup = (Id, SubmitStatus, Follower, TaskId,
        strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, GenStrikeOffRefernceID, index, subIndex, Childindex) => {

        setTab(0);
        setindex(index);
        showLoader();
        setSubmittedpopup(false);
        strikeoffvalues[0].AWBDateandNo = ''
        strikeoffvalues[0].SubmittedDate = '';
        strikeoffvalues[0].SendTo = '';
        strikeoffvalues[0].SendToEdit = '';
        strikeoffvalues[0].SendThrough = 0;
        strikeoffvalues[0].SwatchCardImageName = '';
        strikeoffvalues[0].SwatchCardIamgePath = '';
        strikeoffvalues[0].Remarks = '';
        setshowawb(false);

        let Operation;
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setshowMultiClosediv(true);
            setshowSubmissiondiv(false);
            setheader('Add');
        }
        else {
            Operation = 3;
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            setheader('View');

        }
        if (SubmitStatus === "Pending") {
            setsubmittedoption([]);
            var options = [];
            options.push(
                { OptionName: "A", SupplierReferenceNo: "", GeneralStrikeOffSubmittedOptionId: 0 },
                { OptionName: "B", SupplierReferenceNo: "", GeneralStrikeOffSubmittedOptionId: 0 },
                { OptionName: "C", SupplierReferenceNo: "", GeneralStrikeOffSubmittedOptionId: 0 },
                { OptionName: "D", SupplierReferenceNo: "", GeneralStrikeOffSubmittedOptionId: 0 },
            )
            setsubmittedoption(options);
        }

        GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setStrikeoffDimension(response.data);
            }
        }).catch(() => { });
        GeneralService.GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = StrikeOffSubmissionList.filter(x => x.genralStrikeOffTNASubmissionAndApprovalId === Id);
        var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.submissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })
        strikeoffvalues[0].SupplierName = Sub[0].supplierName;
        strikeoffvalues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        strikeoffvalues[0].StoryName = Sub[0].storyName;
        strikeoffvalues[0].ThemeName = Sub[0].themeName;
        strikeoffvalues[0].EndCustomer = Sub[0].endCustomerNameIdList;
        strikeoffvalues[0].StrikeoffCode = Sub[0].strikeOffCode;
        strikeoffvalues[0].StrikeOffId = Sub[0].strikeOffId;
        strikeoffvalues[0].StrikeoffName = Sub[0].strikeOffName;
        strikeoffvalues[0].Quality = Sub[0].quality;
        strikeoffvalues[0].StrikeoffDimensionId = parseInt(Sub[0].strikeOffRefernceId);
        strikeoffvalues[0].GenralStrikeOffTNASubmissionAndApprovalId = Sub[0].submissionAndApprovalId;
        strikeoffvalues[0].SubmitStatus = Sub[0].submitStatus;
        strikeoffvalues[0].ColorGroupId = Sub[0].colorGroupID;
        let images = [];
        images = Sub[0].strikeOffImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);



        if (SubmitStatus !== "Completed") {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, 0).then((response) => {
                    if (response.data) {
                        let index = 0;
                        let result = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            index = index + 1;
                            item.index = index;
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (result) {
                            isSelectAll = !!!result.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        setMultiCloseDatas(result);
                        let data = [...new Map(response.data.strikeOffSubmitApprovalReferenceList.map(item =>
                            [item['strikeOffLogoId'], item])).values()];


                        setStrikeoffOrderInfoMulticloseMainDetails(data);
                        handleStrikeExpandRowMultiSelect(response.data.strikeOffSubmitApprovalReferenceList[0].strikeoffLogoPath, response.data.strikeOffSubmitApprovalReferenceList);
                        setshowStrikeoffSubmission(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }

                }).catch(() => { hideLoader(); });
        }
        else {
            strikeoffvalues[0].SubmittedDate = new Date(Sub[0].submitDate);
            strikeoffvalues[0].SendTo = Sub[0].sendTo;
            strikeoffvalues[0].SendToEdit = Sub[0].strikeoffSendToNames;
            strikeoffvalues[0].SendThrough = parseInt(Sub[0].sendThrough);
            strikeoffvalues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            strikeoffvalues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            strikeoffvalues[0].Remarks = Sub[0].remarks;
            if (parseInt(Sub[0].sendThrough) === 2) {
                setshowawb(true);
                let getDate = new Date(Sub[0].awbDate);
                let dd = getDate.getDate();
                let mm = getDate.getMonth() + 1;
                let yyyy = getDate.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                let coverted = dd + '/' + mm + '/' + yyyy;
                strikeoffvalues[0].AWBDateandNo = Sub[0].awbDate === null ? '' : coverted + ' / ' + Sub[0].awbName;
                strikeoffvalues[0].AWBDate = new Date(Sub[0].awbDate);
                strikeoffvalues[0].AWBName = Sub[0].awbName;
            }
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                    if (response.data.strikeOffSubmitApprovalReferenceList) {
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {

                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let maindata = [...new Map(data.map(item =>
                            [item['strikeOffLogoId'], item])).values()];

                        setOrderInfoMainDetails(maindata);

                        handleStrikeExpandRowMultiSelect(data[0].strikeoffLogoPath, data);
                        setOrderinfo(data);
                        GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((result) => {
                            if (result.data) {
                                // setStrikeoffDimension(response.data);
                                var StrikeoffDim = [];
                                response.data.strikeOffSubmitApprovalReferenceList.map(Fr => {
                                    result.data.map(Td => {
                                        if (Fr.strikeOffRefernceId === Td.id) {
                                            StrikeoffDim.push({
                                                id: Td.id,
                                                name: Td.name,
                                                value: Td.value,
                                                label: Td.label
                                            })
                                        }

                                    })
                                })
                                setStrikeoffDimension(StrikeoffDim);
                            }
                        }).catch(() => { });
                        var optios = [];
                        //
                        response.data.generalTNAStrikeOffSubmittedOption.forEach(element => {
                            optios.push({
                                GeneralStrikeOffSubmittedOptionId: element.generalStrikeoffSubmittedOptionId,
                                GenralStrikeOffTNASubmissionAndApprovalId: element.genStrikeOffTNASubmissionAndApprovalId,
                                GenStrikeOffId: element.genStrikeOffId,
                                GenStrikeOffLogoId: element.genStrikeOffLogoId,
                                GenStrikeOffRefernceId: element.genStrikeOffRefernceId,
                                OptionName: element.optionName,
                                SupplierReferenceNo: element.supplierReferenceNo
                            })
                        })
                        setsubmittedoption(optios);
                        setshowStrikeoffSubmission(true);
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        setstrikeoffInputFields(strikeoffvalues);
    }
    const handleStrikeExpandRowMultiSelect = (strikeoffLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeoffLogoPath === strikeoffLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeoffLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeoffLogoPath] = false) : (obj[strikeoffLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeoffLogoPath) :
            currentExpandedRows.concat(strikeoffLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }
    //#endregion
    // STRIKEOFFAPPROVAL----------------------------------------------------------------------------------
    const ReloadStrikeoffappApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        let submitparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralStrikeOffApprovalList(submitparams));
        dispatch(GetGeneralStrikeOffApprovalViewList(params));
    }
    const SaveStrikeoffsApproval = () => {
        let IsValid = true;
        if (strikeoffvalues[0].ApprovalDate === '') {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== null &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            strikeoffvalues[0].ApprovalDate = new Date(strikeoffvalues[0].ApprovalDate.setMinutes(strikeoffvalues[0].ApprovalDate.getMinutes() + 370))
            strikeoffvalues[0].Operation = 2;
            strikeoffvalues[0].GeneralTNAStrikeOffOrderInfo = getOrderinfo;
            strikeoffvalues[0].GeneralTNAStrikeOffSubmittedOption = [];
            strikeoffvalues[0].SendThrough = parseInt(strikeoffvalues[0].SendThrough)
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    strikeoffvalues[0].GeneralTNAStrikeOffSubmittedOption.push({
                        GeneralStrikeOffSubmittedOptionId: 0,
                        GenralStrikeOffTNASubmissionAndApprovalId: ordinf.genStrikeOffTNASubmissionAndApprovalId,
                        GenStrikeOffId: ordinf.genStrikeOffId,
                        GenStrikeOffLogoId: ordinf.genStrikeOffLogoID,
                        GenStrikeOffRefernceId: ordinf.genStrikeOffRefernceID,
                        OptionName: opt.optionName,
                        SupplierReferenceNo: opt.supplierReferenceNo,
                        ApprovedOptionName: opt.approvedOptionName
                    })
                })
            })
            GeneralService.InsertUpdateGeneralStrikeOffSubmissionApproval(strikeoffvalues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Approval Added Successfully');
                    searchStrikeOffApp();
                    setstrikeoffshowApproval(false);
                    handleToggle(getindex);
                    ReloadStrikeoffappApproval();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    searchStrikeOffApp();
                    setstrikeoffshowApproval(false);
                    handleToggle(getindex);
                    ReloadStrikeoffappApproval();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const searchStrikeOffApp = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralStrikeOffApprovalList(params));

        // let params = {
        //     BuyerId: StrikeOffViewDetailParams.BuyerId, BrandId: StrikeOffViewDetailParams.BrandId, SeasonId: StrikeOffViewDetailParams.SeasonId,
        //     BuyerName: StrikeOffViewDetailParams.BuyerName, BrandName: StrikeOffViewDetailParams.BrandName, SeasonName: StrikeOffViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralStrikeOffDetailsViewList(params));
        commonParentExpand();
    }
    const StrikeoffApphandleChange = (event, feild) => {
        let input = '';

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(strikeoffvalues[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                }
                else {
                    strikeoffvalues[0].ApprovalDate = event;
                }
            }
            else {
                strikeoffvalues[0].ApprovalDate = ''
            }

        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            strikeoffvalues[0].ApprovalRemark = input;
        }

        setstrikeoffInputFields(strikeoffvalues);
    }
    const handleStrikeoffAppSubmitoptionchange = (event, feild, index) => {
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                submittedoption[index].SupplierReferenceNo = event.target.value;
            }
            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {

            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }

    const handleStrikeAppManualclose = (Id, approvalStatus, Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffRefernceId, index, subIndex) => {
        setindex(subIndex)
        showLoader();
        let Operation;
        setComment(false);
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Add');
        }
        else {
            Operation = 4;
            setheader('View');

        }
        if (approvalStatus !== 'Completed') {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceId).then((response) => {
                    if (response.data) {
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                item.isSelected = 0;
                                return item;
                            }
                        });
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        setStrikeOffAppManual(true);
                        hideLoader();
                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        } else {
            GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceId).then((response) => {
                    if (response.data) {
                        let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                            if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                item.isSelected = 0;
                                return item;
                            }
                        });
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        hideLoader();
                        setStrikeOffAppManual(true);

                    } else {
                        hideLoader();
                        setStrikeOffAppManual(true);
                    }
                }).catch(() => { hideLoader(); setStrikeOffAppManual(true); });
        }
        // setshowManual(true);
        // dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, true));

    }
    const handleCloseManualPopupTrim = () => {
        setTrimAppManual(false);
    }
    const handleCloseManualPopups = (index, subIndex, isOpen) => {

        //dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, isOpen));
        setStrikeOffAppManual(false);
    }
    const OpenStrikeOffApprovalpopup = (isFormSubmission, Id, approvalStatus, Follower, TaskId,
        strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, GenStrikeOffMainId, GenStrikeOffRefernceID, index, subIndex) => {

        let Optionvalue = [];
        let Operation;


        if (isFormSubmission === 1) {

            setTab(0);
            setindex(index);
            showLoader();



            strikeoffvalues[0].ApprovalDate = '';
            strikeoffvalues[0].ApprovalRemark = '';

            if (approvalStatus === "Pending") {
                Operation = 2;
                // setshowMultiClosediv(true);
                // setshowApprovaldiv(false);
                setshowMultiClosediv(false);
                setshowApprovaldiv(true);
                setheader('Add');
            }
            else {
                Operation = 4;
                setshowMultiClosediv(false);
                setshowApprovaldiv(true);
                setheader('View');

            }
            ApprovedOption.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }
                else if (element.value === 'Hold') {
                    LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
                }


                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setApprovedOptions(Optionvalue);
            GeneralService.GetGeneralStrikeoffDimensionList(GenStrikeOffMainId).then((response) => {
                if (response.data) {
                    setStrikeoffDimension(response.data);
                }
            }).catch(() => { });
            GeneralService.GetGeneralStrikeoffTaskHolderList(GenStrikeOffMainId).then((response) => {
                if (response.data) {
                    setTaskholder(response.data);
                }
            }).catch(() => { });
            // var Main = StrikeOffApprovalList.filter(x => x.genralStrikeOffTNASubmissionAndApprovalId === Id);
            var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
            Sub.forEach(element => {
                if (element.submissionAndApprovalId === Id) {
                    element.isSelected = 1;
                }
            })

            strikeoffvalues[0].SupplierName = Sub[0].supplierName;
            strikeoffvalues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
            strikeoffvalues[0].StoryName = Sub[0].storyName;
            strikeoffvalues[0].ThemeName = Sub[0].themeName;
            strikeoffvalues[0].EndCustomer = Sub[0].endCustomerNameIdList;
            strikeoffvalues[0].StrikeoffCode = Sub[0].strikeOffCode;
            strikeoffvalues[0].StrikeOffId = Sub[0].strikeOffId;
            strikeoffvalues[0].StrikeoffName = Sub[0].strikeOffName;
            strikeoffvalues[0].Quality = Sub[0].quality;
            strikeoffvalues[0].StrikeoffDimensionId = parseInt(Sub[0].strikeOffRefernceId);
            strikeoffvalues[0].GenralStrikeOffTNASubmissionAndApprovalId = Sub[0].genralStrikeOffTNASubmissionAndApprovalId;
            strikeoffvalues[0].ApprovalStatus = Sub[0].approvalStatus;
            strikeoffvalues[0].SubmitStatus = Sub[0].submitStatus;
            strikeoffvalues[0].Remarks = Sub[0].remarks;


            let getDate = new Date(Sub[0].awbDate);
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = dd + '/' + mm + '/' + yyyy;



            let awbdate = Sub[0].awbDate === null ? '' : coverted;
            strikeoffvalues[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
            strikeoffvalues[0].SendTo = Sub[0].sentTo;
            strikeoffvalues[0].SubmittedDate = new Date(Sub[0].submitDate);
            strikeoffvalues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            strikeoffvalues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            let images = [];
            images = Sub[0].strikeOffImageList.split(',');
            setSubImageList(images);
            setCurrimageindex(0);

            if (approvalStatus !== "Completed") {
                GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier,
                    Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                        if (response.data.strikeOffSubmitApprovalReferenceList) {
                            let index = 0;
                            let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {
                                index = index + 1;
                                item.index = index;
                                if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            setStrikeoffOrderInfoMulticloseMainDetails(data);
                            //setsubmittedoption([]);
                            setsubmittedoption(response.data.generalTNAStrikeOffSubmittedOption);
                            let maindata = [...new Map(response.data.strikeOffSubmitApprovalReferenceList.map(item =>
                                [item['strikeOffLogoId'], item])).values()];

                            // setMultiCloseDatas(data);
                            // setTrimOrderInfoMulticloseMainDetails(maindata);
                            // handleExpandRowMultiSelect(response.data.strikeOffSubmitApprovalReferenceList[0].strikeoffLogoPath, response.data.strikeOffSubmitApprovalReferenceList);

                            setOrderInfoMainDetails(maindata);
                            setOrderinfo(data);
                            handleStrikeExpandRowMultiSelect(response.data.strikeOffSubmitApprovalReferenceList[0].strikeoffLogoPath,
                                response.data.strikeOffSubmitApprovalReferenceList);
                            setstrikeoffshowApproval(true);
                            hideLoader();
                        } else {
                            hideLoader();
                        }

                    }).catch(() => { hideLoader(); });
            } else {
                strikeoffvalues[0].ApprovalDate = new Date(Sub[0].approvalDate);
                strikeoffvalues[0].ApprovalRemark = Sub[0].approvalRemark;

                GeneralService.GetGeneralStrikeOffOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier,
                    Follower, TaskId, strikeOffId, GenStrikeOffLogoID, GenStrikeOffId, Operation, GenStrikeOffRefernceID).then((response) => {
                        if (response.data.strikeOffSubmitApprovalReferenceList) {
                            let data = response.data.strikeOffSubmitApprovalReferenceList.map((item) => {

                                if (item.genStrikeOffTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });

                            let index = 0;
                            data.forEach(element => {
                                index = index + 1;
                                element.index = index;
                            })

                            let maindata = [...new Map(data.map(item =>
                                [item['strikeOffLogoId'], item])).values()];

                            setOrderInfoMainDetails(maindata);
                            setOrderinfo([]);
                            setOrderinfo(data);
                            handleExpandRow(data[0].strikeoffLogoPath, data);
                            setsubmittedoptionAll([]);
                            setsubmittedoption(response.data.generalTNAStrikeOffSubmittedOption.filter(x => x.genStrikeOffTNASubmissionAndApprovalId === Sub[0].submissionAndApprovalId));
                            setsubmittedoptionAll(response.data.generalTNAStrikeOffSubmittedOption);
                            setstrikeoffshowApproval(true);
                            hideLoader();
                        } else {
                            hideLoader();
                        }
                    }).catch(() => { hideLoader(); });


            }
        }

        else {
            Nodify('Warning!', 'warning', "Submission not yet added for this task");
            return false;
        }








    }
    //-------------------------------------------------------STRIKEOFFEND------------------------------------------------------

    //#region TRIMSUBMISSION
    const handleTrimSubSubmitoptionchange = (event, feild, index) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "OptionName") {
            submittedoption[index].OptionName = input;
        }
        else if (feild === "SupplierReferenceNo") {
            submittedoption[index].SupplierReferenceNo = input;
        }
        setTrimInputFields(TrimValues);

    }
    const SaveAwbnoDate = () => {

        TrimValues[0].AWBDateandNo = TrimValues[0].AWBName + " / " + new Date(TrimValues[0].AWBDate).toLocaleDateString('en-GB');
        setTrimInputFields(TrimValues);
        setShowTrimPopup(false);

    }
    const handleTrimpopupchange = (event, feild) => {
        let input = '';
        if (event.target.value !== '') {
            input = event.target.value;
        }
        if (feild === "AWBName") {
            TrimValues[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            TrimValues[0].AWBDate = input;
        }
        setTrimInputFields(TrimValues);
    }
    const SaveTrimSubSubmission = () => {
       
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })

        if (TrimValues[0].SubmittedDate === '' || TrimValues[0].SendTo === "" || TrimValues[0].SendThrough === 0 ||
            TrimValues[0].TrimDimensionId === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }
        // else if ((parseInt(TrimValues[0].SendThrough) === 2 && TrimValues[0].AWBDateandNo === '')) {
        //     Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        // }

        else {

            TrimValues[0].SubmittedDate = new Date(TrimValues[0].SubmittedDate.setMinutes(TrimValues[0].SubmittedDate.getMinutes() + 370))

            TrimValues[0].Operation = 1;
            TrimValues[0].GeneralTNATrimOrderInfo = getOrderinfo;
            TrimValues[0].GeneralTNATrimSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    TrimValues[0].GeneralTNATrimSubmittedOption.push({
                        GeneralTrimSubmittedOptionId: opt.GeneralTrimSubmittedOptionId,
                        GenralTrimTNASubmissionAndApprovalId: ordinf.genTrimTNASubmissionAndApprovalId,
                        GenTrimId: ordinf.genTrimId,
                        GenTrimLogoId: ordinf.genTrimLogoID,
                        GenTrimRefernceId: ordinf.genTrimRefernceID,
                        OptionName: opt.OptionName,
                        SupplierReferenceNo: opt.SupplierReferenceNo

                    })
                })
            })

            GeneralService.InsertUpdateGeneralTrimSubmissionApproval(TrimValues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Added Successfully');
                    setshowTrimSubmission(false);
                    searchTrim();
                    handleToggle(getindex);
                    ReloadTrimsSubmission();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowTrimSubmission(false);
                    searchTrim();
                    handleToggle(getindex);
                    ReloadTrimsSubmission();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const ReloadTrimsSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        let reqparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 2
            SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralTrimApprovalList(params));
        dispatch(GetGeneralTrimSubmissionApprovalGridList(reqparams));

    }
    const handleTrimChangeSubmissionMain = (event, feild) => {

        let input = '';

        if (feild === "SubmittedDate") {
            TrimValues[0].SubmittedDate = event;
        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            TrimValues[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            TrimValues[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            TrimValues[0].Remarks = input;
        }
        else if (feild === "TrimDimensionId") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            TrimValues[0].TrimDimensionId = input;
        }
        setTrimInputFields(TrimValues);
    }
    const TrimSubSelectAll = (event) => {
        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }
    const SaveChildManulaTrimAppCloseComments = (index, subIndex) => {
        if ((!ApprovalManualInfo[0].approvalManualCloseComment) || (!ApprovalManualInfo[0].approvalManualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            showLoader();
            let strValue = "";
            let selected = 0;
            // for (let data of MultiCloseDatas) {
            MultiCloseDatas.map((data) => {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genTrimDetailTNAId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genTrimDetailTNAId;
                    }
                }
            });
            if (selected === 0) {
                hideLoader();
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                let ManualCloseData = [{ IdList: '', ManualCloseDate: '', ManualCloseComment: '', Operation: 0 }];
                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(ApprovalManualInfo[0].approvalManualCloseDate));

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ApprovalManualInfo[0].approvalManualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 2;
                GeneralService.UpdateGeneralTrimManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully");
                        //dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setTrimAppManual(false);
                        //searchText();
                        searchTrim();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Updated Successfully");
                        //dispatch(UpdateIsApprovalManualClosePopup(index, subIndex, false));
                        setTrimAppManual(false);
                        // searchText();
                        searchTrim();
                        handleToggle(getindex);
                        ReloadApproval();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }

    const TrimSubOnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }
    const handleTrimExpandRow = (trimLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRows(newExpandedRows);


    }
    const handleTrimExpandRowMultiSelect = (trimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);


    }
    const handlemanualTrimSub = (Id, SubmitStatus, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, GenTrimRefernceID, index) => {
        showLoader();
        setindex(index);
        let Operation;
        setComment(false);
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setheader('Add');
        }
        else {
            Operation = 3;
            setheader('View');
        }
        setShowTrimsub(true);
        if (SubmitStatus !== 'completed') {
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand
                , Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, 0).then((response) => {
                    if (response.data) {
                        let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                return item;
                            }
                        });

                        setTrimManualInfo(data);
                        setMultiCloseDatas(data);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        else {
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand
                , Searchvalues.Season, Searchvalues.Supplier, Follower,
                TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                    if (response.data) {
                        let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                return item;
                            }
                        });

                        setTrimManualInfo(data);
                        setMultiCloseDatas(data);
                        setShowTrimsub(true);
                        hideLoader();
                    } else {
                        hideLoader();
                    }
                }).catch(() => { setShowTrimsub(true); hideLoader(); });
        }

    }
    const OpenTrimSubmssionpopup = (Id, SubmitStatus, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, GenTrimRefernceID, index, subIndex) => {
        setTab(0);
        setindex(index);
        showLoader();
        setSubmittedpopup(false);
        let Operation;
        TrimValues[0].AWBDateandNo = ''
        TrimValues[0].SubmittedDate = '';
        TrimValues[0].SendTo = '';
        TrimValues[0].SendToEdit = '';
        TrimValues[0].SendThrough = 0;
        TrimValues[0].SwatchCardImageName = '';
        TrimValues[0].SwatchCardIamgePath = '';
        TrimValues[0].Remarks = '';
        setshowawb(false);
        if (SubmitStatus === "Pending") {
            Operation = 1;
            setshowMultiClosediv(true);
            setshowSubmissiondiv(false);
            setheader('Add');
        }
        else {
            Operation = 3;
            setshowMultiClosediv(false);
            setshowSubmissiondiv(true);
            setheader('View');

        }
        if (SubmitStatus === "Pending") {
            setsubmittedoption([]);
            var options = [];
            options.push(
                { OptionName: "A", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "B", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "C", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
                { OptionName: "D", SupplierReferenceNo: "", GeneralTrimSubmittedOptionId: 0 },
            )
            setsubmittedoption(options);
        }

        GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }
        }).catch(() => { });
        GeneralService.GetGeneralTrimTaskHolderList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = TrimSubmissionList.filter(x => x.genralTrimTNASubmissionAndApprovalId === Id);
        var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.submissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })

        TrimValues[0].SupplierName = Sub[0].supplierName;
        TrimValues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        TrimValues[0].StoryName = Sub[0].storyName;
        TrimValues[0].ThemeName = Sub[0].themeName;
        TrimValues[0].EndCustomer = Sub[0].endCustomerNameIdList;
        TrimValues[0].TrimCode = Sub[0].trimCode;
        TrimValues[0].TrimId = Sub[0].mainId;
        TrimValues[0].TrimName = Sub[0].trimName;
        TrimValues[0].TechnicalSpecification = Sub[0].technicalSpecification;
        TrimValues[0].TrimDimensionId = parseInt(Sub[0].trimRefernceId);
        TrimValues[0].GenralTrimTNASubmissionAndApprovalId = Sub[0].submissionAndApprovalId;
        TrimValues[0].SubmitStatus = Sub[0].submitStatus;
        TrimValues[0].ColorGroupId = Sub[0].colorGroupID;
        let images = [];
        images = Sub[0].trimImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);



        if (SubmitStatus !== "Completed") {
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier,
                Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                    if (response.data) {
                        let index = 0;
                        let result = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            index = index + 1;
                            item.index = index;
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (result) {
                            isSelectAll = !!!result.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        setMultiCloseDatas(result);

                        let data = [...new Map(response.data.trimSubmitApprovalReferenceList.map(item =>
                            [item['trimLogoId'], item])).values()];
                        setOrderInfoMainDetails(data);
                        setOrderinfo(data);
                        setTrimOrderInfoMulticloseMainDetails(data);
                        handleTrimExpandRowMultiSelect(response.data.trimSubmitApprovalReferenceList[0].trimLogoPath, response.data.trimSubmitApprovalReferenceList);
                        setshowTrimSubmission(true);
                        hideLoader();

                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        // setshowTrimSubmission(true);
        else {
            TrimValues[0].SubmittedDate = new Date(Sub[0].submitDate);
            setMinApprovalDate((moment(TrimValues[0].SubmittedDate).format("YYYY-MM-DD")).toString());
            TrimValues[0].SendTo = Sub[0].sendTo;
            TrimValues[0].SendToEdit = Sub[0].trimSendToNames;
            TrimValues[0].SendThrough = Sub[0].sendThrough;
            TrimValues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            TrimValues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            TrimValues[0].Remarks = Sub[0].remarks;
            TrimValues[0].ColorGroupId = Sub[0].colorGroupId;
            if (parseInt(Sub[0].sendThrough) === 2) {
                setshowawb(true);
                let getDate = new Date(Sub[0].awbDate);
                let dd = getDate.getDate();
                let mm = getDate.getMonth() + 1;
                let yyyy = getDate.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                let coverted = dd + '/' + mm + '/' + yyyy;
                TrimValues[0].AWBDateandNo = Sub[0].awbDate === null ? '' : coverted + ' / ' + Sub[0].awbName;
                TrimValues[0].AWBDate = new Date(Sub[0].awbDate);
                TrimValues[0].AWBName = Sub[0].awbName;
            }
            let images = [];
            images = Sub[0].trimImageList.split(',');
            setSubImageList(images);
            setCurrimageindex(0);
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season, Searchvalues.Supplier,
                Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                    if (response.data.trimSubmitApprovalReferenceList) {
                        let data = response.data.trimSubmitApprovalReferenceList.map((item) => {

                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }

                        });

                        let maindata = [...new Map(data.map(item =>
                            [item['trimLogoId'], item])).values()];

                        setOrderInfoMainDetails(maindata);

                        handleExpandRow(data[0].trimLogoPath, data);

                        setOrderinfo(data);
                        var TrimDim = [];
                        GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((result) => {
                            if (result.data) {

                                data.map(Fr => {
                                    result.data.map(Td => {
                                        if (Fr.trimRefernceId === Td.id) {
                                            TrimDim.push({
                                                id: Td.id,
                                                name: Td.name,
                                                value: Td.value,
                                                label: Td.label
                                            })
                                        }

                                    })
                                })
                                setTrimDimension(TrimDim);
                            }
                        }).catch(() => { });


                        var optios = [];

                        response.data.generalTNATrimSubmittedOption.forEach(element => {
                            optios.push({
                                GeneralTrimSubmittedOptionId: element.generalTrimSubmittedOptionId,
                                GenralTrimTNASubmissionAndApprovalId: element.genralTrimTNASubmissionAndApprovalId,
                                GenTrimId: element.genTrimId,
                                GenTrimLogoId: element.genTrimLogoId,
                                GenTrimRefernceId: element.genTrimRefernceId,
                                OptionName: element.optionName,
                                SupplierReferenceNo: element.supplierReferenceNo
                            })
                        })
                        setsubmittedoption(optios);

                        setfirstsubapprid(
                            {
                                SubApprId: response.data.generalTNATrimSubmittedOption[0].genralTrimTNASubmissionAndApprovalId,
                                GenTrimId: response.data.generalTNATrimSubmittedOption[0].genTrimId,
                                GenTrimLogoId: response.data.generalTNATrimSubmittedOption[0].genTrimLogoId,
                                GenTrimRefernceId: response.data.generalTNATrimSubmittedOption[0].genTrimRefernceId

                            })

                        setshowTrimSubmission(true);
                        hideLoader();
                    } else {
                        hideLoader();
                    }
                }).catch(() => { hideLoader(); });
        }
        setTrimInputFields(TrimValues);
    }
    //#endregion
    //#region TRIMAPPROVAL
    const TrimApphandleSubmitoptionchange = (event, feild, index) => {
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                submittedoption[index].SupplierReferenceNo = event.target.value;
            }
            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {

            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }

    const TrimApphandleChange = (event, feild) => {
       
        let input = '';

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(TrimValues[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                }
                else {
                    TrimValues[0].ApprovalDate = event;
                }
            }
            else {
                TrimValues[0].ApprovalDate = ''
            }



        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            TrimValues[0].ApprovalRemark = input;
        }

        setTrimInputFields(TrimValues);
    }
    const searchTrim = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };
        dispatch(GetGeneralTrimApprovalList(params));

        // let params = {
        //     BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
        //     BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralTrimDetailsViewList(params));
        commonParentExpand();
    }
    const SaveTrimApproval = () => {
        let IsValid = true;
        if (TrimValues[0].ApprovalDate === '') {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== null &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            TrimValues[0].ApprovalDate = new Date(TrimValues[0].ApprovalDate.setMinutes(TrimValues[0].ApprovalDate.getMinutes() + 370))
            TrimValues[0].Operation = 2;
            TrimValues[0].GeneralTNATrimOrderInfo = getOrderinfo;
            TrimValues[0].GeneralTNATrimSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    TrimValues[0].GeneralTNATrimSubmittedOption.push({
                        GeneralTrimSubmittedOptionId: 0,
                        GenralTrimTNASubmissionAndApprovalId: ordinf.genTrimTNASubmissionAndApprovalId,
                        GenTrimId: ordinf.genTrimId,
                        GenTrimLogoId: ordinf.genTrimLogoID,
                        GenTrimRefernceId: ordinf.genTrimRefernceID,
                        OptionName: opt.optionName,
                        SupplierReferenceNo: opt.supplierReferenceNo,
                        ApprovedOptionName: opt.approvedOptionName
                    })
                })
            })
            GeneralService.InsertUpdateGeneralTrimSubmissionApproval(TrimValues[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Added Successfully');
                    searchTrim();
                    setshowTrimApproval(false);
                    handleToggle(getindex);
                    ReloadTrimaApproval();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    searchTrim();
                    setshowTrimApproval(false);
                    handleToggle(getindex);
                    ReloadTrimaApproval();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const ReloadTrimaApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        let approvalparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };

        dispatch(GetGeneralTrimApprovalViewList(params));
        dispatch(GetGeneralTrimApprovalList(approvalparams));
    }
    const handletirmappmanual = (Id, approvalStatus, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, GenTrimRefernceID, index, subIndex) => {
        showLoader();
        let Operation;
        setindex(index);
        setComment(false);
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Add');
        }
        else {
            Operation = 4;
            setheader('View');

        }
        if (approvalStatus !== 'Completed') {
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                    if (response.data) {
                        let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                item.isSelected = 0;
                                return item;
                            }
                        });
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        setTrimAppManual(true);
                        hideLoader();

                    } else { hideLoader(); }
                }).catch(() => { hideLoader(); });
        }
        else {
            GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                Searchvalues.Supplier, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                    if (response.data) {
                        let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                            if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;
                                return item;
                            } else {
                                item.isSelected = 0;
                                return item;
                            }
                        });
                        setApprovalManualInfo(data);
                        setMultiCloseDatas(data);
                        hideLoader();
                        setTrimAppManual(true);
                    } else {
                        hideLoader();
                        setTrimAppManual(true);
                    }
                }).catch(() => { hideLoader(); setTrimAppManual(true); });
        }
        // setshowManual(true);
        // dispatch(TrimManualpopup(index, subIndex, true));

    }
    const OpenTrimApprovalpopup = (isFormSubmission, Id, approvalStatus, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, GenTrimRefernceID, index, subIndex) => {

        let Optionvalue = [];
        let Operation;

        if (isFormSubmission === 1) {
            showLoader();
            setTab(0);
            setindex(index);
            TrimValues[0].ApprovalDate = '';
            TrimValues[0].ApprovalRemark = '';

            if (approvalStatus === "Pending") {
                Operation = 2;
                // setshowMultiClosediv(true);
                // setshowApprovaldiv(false);
                setshowMultiClosediv(false);
                setshowApprovaldiv(true);
                setheader('Add');
            }
            else {
                Operation = 4;

                setshowMultiClosediv(false);
                setshowApprovaldiv(true);
                setheader('View');

            }
            ApprovedOption.forEach(element => {
                let LabelColor = '';
                if (element.value === 'Approved') {
                    LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
                }
                else if (element.value === 'Approved with Comments') {
                    LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
                }
                else if (element.value === 'Rejected') {
                    LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
                }
                else if (element.value === 'Hold') {
                    LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
                }


                Optionvalue.push({
                    value: element.key,
                    label: LabelColor,
                    subLabel: element.value,
                })
            });

            setApprovedOptions(Optionvalue);
            GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((response) => {
                if (response.data) {
                    setTrimDimension(response.data);
                }
            }).catch(() => { });
            GeneralService.GetGeneralTrimTaskHolderList(GenTrimMainId).then((response) => {
                if (response.data) {
                    setTaskholder(response.data);
                }
            }).catch(() => { });
            // var Main = TrimApprovalList.filter(x => x.genralTrimTNASubmissionAndApprovalId === Id);
            var Sub = genTNAReportList && genTNAReportList.genTNADetailList[index].genTNAParentChildList[subIndex].genTNAChildList.filter(x => x.submissionAndApprovalId === Id);
            Sub.forEach(element => {
                if (element.submissionAndApprovalId === Id) {
                    element.isSelected = 1;
                }
            })


            TrimValues[0].SupplierName = Sub[0].supplierName;
            TrimValues[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
            TrimValues[0].StoryName = Sub[0].storyName;
            TrimValues[0].ThemeName = Sub[0].themeName;
            TrimValues[0].EndCustomer = Sub[0].endCustomerNameIdList;
            TrimValues[0].TrimCode = Sub[0].trimCode;
            TrimValues[0].TrimId = Sub[0].trimId;
            TrimValues[0].TrimName = Sub[0].trimName;
            TrimValues[0].TechnicalSpecification = Sub[0].technicalSpecification;
            TrimValues[0].TrimDimensionId = parseInt(Sub[0].trimRefernceId);
            TrimValues[0].GenralTrimTNASubmissionAndApprovalId = Sub[0].submissionAndApprovalId;
            TrimValues[0].ApprovalStatus = Sub[0].approvalStatus;
            TrimValues[0].SubmitStatus = Sub[0].submitStatus;
            TrimValues[0].Remarks = Sub[0].remarks;
            let getDate = new Date(Sub[0].awbDate);
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = dd + '/' + mm + '/' + yyyy;
            let awbdate = Sub[0].awbDate === null ? '' : coverted;
            TrimValues[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
            TrimValues[0].SendTo = Sub[0].sentTo;
            TrimValues[0].SubmittedDate = new Date(Sub[0].submitDate);
            setMinApprovalDate((moment(TrimValues[0].SubmittedDate).format("YYYY-MM-DD")).toString());
            TrimValues[0].SwatchCardImageName = Sub[0].swatchCardImageName;
            TrimValues[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
            let images = [];
            images = Sub[0].trimImageList.split(',');
            setSubImageList(images);
            setCurrimageindex(0);

            if (approvalStatus !== "Completed") {
                GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                    Searchvalues.Supplier, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                        if (response.data.trimSubmitApprovalReferenceList) {
                            let index = 0;
                            let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                                index = index + 1;
                                item.index = index;
                                if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            setTrimOrderInfoMulticloseMainDetails(data);

                            setsubmittedoption([]);
                            setsubmittedoption(response.data.generalTNATrimSubmittedOption);
                            let maindata = [...new Map(response.data.trimSubmitApprovalReferenceList.map(item =>
                                [item['trimLogoId'], item])).values()];

                            //setMultiCloseDatas(data);
                            // setOrderInfoMulticloseMainDetails(maindata);
                            //  handleExpandRowMultiSelect(response.data.trimSubmitApprovalReferenceList[0].trimLogoPath, response.data.trimSubmitApprovalReferenceList);
                            setOrderInfoMainDetails(maindata);
                            setOrderinfo(response.data.trimSubmitApprovalReferenceList);
                            setOrderinfo(data);
                            handleTrimExpandRowMultiSelect(response.data.trimSubmitApprovalReferenceList[0].trimLogoPath, response.data.trimSubmitApprovalReferenceList);
                            setshowTrimApproval(true);
                            hideLoader();
                        } else {
                            hideLoader();
                        }
                    }).catch(() => { hideLoader(); });
            }
            else {
                TrimValues[0].ApprovalDate = new Date(Sub[0].approvalDate);
                TrimValues[0].ApprovalRemark = Sub[0].approvalRemark;
                GeneralService.GetGeneralTrimOrderInfoList(Id, Searchvalues.Buyer, Searchvalues.Brand, Searchvalues.Season,
                    Searchvalues.Supplier, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                        if (response.data.trimSubmitApprovalReferenceList) {
                            let data = response.data.trimSubmitApprovalReferenceList.map((item) => {

                                if (item.genTrimTNASubmissionAndApprovalId === Id) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });

                            let index = 0;
                            data.forEach(element => {
                                index = index + 1;
                                element.index = index;
                            })

                            let maindata = [...new Map(data.map(item =>
                                [item['trimLogoId'], item])).values()];

                            setOrderInfoMainDetails(maindata);
                            setOrderinfo(data);
                            handleTrimExpandRow(data[0].trimLogoPath, data);
                            setsubmittedoption([]);
                            setsubmittedoptionAll([]);
                            setsubmittedoption(response.data.generalTNATrimSubmittedOption.filter(x => x.genralTrimTNASubmissionAndApprovalId === TrimValues[0].GenralTrimTNASubmissionAndApprovalId));
                            setsubmittedoptionAll(response.data.generalTNATrimSubmittedOption)
                            setshowTrimApproval(true);
                            hideLoader();
                        } else {
                            hideLoader();
                        }
                    }).catch(() => { hideLoader(); });

            }
        }

        else {
            Nodify('Warning!', 'warning', "Submission not yet added for this task");
            return false;
        }



    }
    //#endregion

    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = isShow;
        setStateDate(getStateDate);
    }





    const clearDateSelect = () => {
        const emptyStateDate = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
    }

    const handlechange = (e, field) => {
        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);

        if (e === null) {
            Searchvalues[field] = 0;

        }
        else {
            if (e.length === 0) {
                Searchvalues[field] = 0;
            }
            else {
                Searchvalues[field] = e.value;
            }
        }

        if (field === 'TaskType' || field === 'TaskName' || field === '1' || field === '2' || field === '3' || field === '4' || field === '5' || field === '6' || field === '7' || field === '8' || field === '9') {
            if (field === '1' || field === '2' || field === '3' || field === '4' || field === '5' || field === '6' || field === '7' || field === '8' || field === '9') { Searchvalues.Status = parseInt(field) }
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(stateDate[0].startDate));
                //  StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(stateDate[0].endDate));
                // EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            let params = {
                BuyerId: Searchvalues.Buyer, BrandId: Searchvalues.Brand, SeasonId: Searchvalues.Season, SupplierId: Searchvalues.Supplier,
                TaskTypeId: Searchvalues.TaskType, TaskId: Searchvalues.TaskName, TaskTodoInProgressCompletedStatus: Searchvalues.Status, StartDate: StartDate, EndDate: EndDate,
                Operation: 1, Index: 0, ChildIndex: 0, MainId: 0, ColorImageId: 0, IsProduction: IsProduction

            };

            // setSearchvaluesToSub(params);
            dispatch(loadGenTNAReportList(params));
            commonParentExpand();

        }
        else if (field === 'Buyer') {
            if (e !== null)
                LoadBuyerBased(e.value, setBrandList)
            Searchvalues.Brand = 0
        }


        setSearchvalues(Searchvalues);
    }



    const LapdipsubSelectAll = (event) => {
        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const LapdipsubOnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }


    const handleTaskOwnerDetails = (taskHolderFollowerNamesIds) => {
        const employeeList = genTNAReportList && genTNAReportList.tnaEmployeeList.filter(d => taskHolderFollowerNamesIds.includes(parseInt(d.value)));
        let strTxt = '';
        employeeList && employeeList.map((val) => {
            if (strTxt === '') { strTxt = val.label.toString(); }
            else {
                strTxt = strTxt + ', ' + val.label.toString();
            }
        });
        setShowTaskOwnerPopup({ isPopup: true, detail: strTxt });

    }
    const handleTaskOwnerPopupClose = () => {

        setShowTaskOwnerPopup({ isPopup: false, detail: '' });
    }


    const CustomInputsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }

    const CustomInputstrikeoffsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }


    const CustomInputtrimsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }


    const CustomInputtrimApprovalsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color: props.value === "" ? "#bfdea1" : "#000",
                    border: submitted && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }

    const CustomInputlabdipApprovalsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color: props.value === "" ? "#bfdea1" : "#000",
                    border: submitted && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }

    const CustomInputstrikeoffApprovalsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color: props.value === "" ? "#bfdea1" : "#000",
                    border: submitted && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }





    return (

        <Fragment>
            <ReactNotification />
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="row">
                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">

                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {<span style={{ marginLeft: "15px", color: "gray" }}><b>Note: Select below information, and get TNA Report!</b></span>}

                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="Buyer">
                                                            Buyer
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <span className="input-icon icon-right">
                                                            <Reactselect className="basic-single" name="Buyer"
                                                                id={
                                                                    "Buyer"
                                                                }
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handlechange(event, 'Buyer')}
                                                                value={getBuyerList.filter(function (option) {
                                                                    return option.value === Searchvalues.Buyer;
                                                                })}
                                                                options={getBuyerList}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="SupplierID">
                                                            Brand
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <span className="input-icon icon-right">
                                                            <Reactselect className="basic-single" name="Brand"
                                                                id={
                                                                    "Brand"
                                                                }
                                                                classNamePrefix="brand"
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handlechange(event, 'Brand')}
                                                                value={getBrandList.filter(function (option) {
                                                                    return option.value === Searchvalues.Brand;
                                                                })}
                                                                options={getBrandList}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor="Name">
                                                            Season
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <span className="input-icon icon-right">
                                                            <Reactselect className="basic-single" name="Season"
                                                                id={
                                                                    "Season"
                                                                }
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handlechange(event, 'Season')}
                                                                value={getSeasonList.filter(function (option) {
                                                                    return option.value === Searchvalues.Season;
                                                                })}
                                                                styles={{
                                                                    // Fixes the overlapping problem of the component
                                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                                }}
                                                                options={getSeasonList}
                                                            ></Reactselect>

                                                        </span>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label htmlFor={"Supplier"}>Supplier
                                                            <span className="text-danger">*</span></label>
                                                        <span className="input-icon icon-right">
                                                            <Reactselect className="basic-single " name="Supplier"
                                                                id={
                                                                    "Supplier"
                                                                }

                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handlechange(event, 'Supplier')}
                                                                value={getSupplierList.filter(function (option) {
                                                                    return option.value ===
                                                                        (getSupplierList.length === 1 ? getSupplierList[0].value :
                                                                            Searchvalues.Supplier);

                                                                })}
                                                                options={getSupplierList}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">

                                                    <div className="form-group">
                                                        <label >Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause date picker appear and disappear)</span> </label>
                                                        <span className="input-icon icon-right">
                                                            <input
                                                                value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' : moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                //onBlur={event => handleDateBlur()}
                                                                type="text"
                                                                className={'form-control'}
                                                                placeholder="Select Date"
                                                            />
                                                            <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                        </span>
                                                    </div>


                                                    {stateDate[0].isShow &&
                                                        <DateRangePicker
                                                            onChange={item => setStateDate([item.selection])}
                                                            showSelectionPreview={true}
                                                            moveRangeOnFirstSelection={false}
                                                            months={2}
                                                            ranges={stateDate}
                                                            showDateDisplay={false}
                                                            direction="vertical"
                                                            className={'TNAReportDatePicker'}

                                                        />

                                                    }



                                                </div>

                                                <div className="col-sm-1">
                                                    <label >&nbsp;</label>
                                                    <span className="input-icon icon-right">
                                                        <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                                            // style={{ width: "230px" }}
                                                            disabled={Searchvalues.Buyer === 0 || Searchvalues.Brand === 0 ||
                                                                Searchvalues.Season === 0 || Searchvalues.Supplier === 0 ? true : false}
                                                        >
                                                            <i className="fa fa-search"></i>
                                                            {/* &nbsp;Search */}
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={resetTNA}
                                                        //style={{ width: "150px" }}
                                                        >
                                                            <i className="fa fa-close"></i>
                                                            {/* &nbsp;Reset */}
                                                        </button>
                                                    </span>

                                                </div>

                                            </div>

                                            {genTNAReportList && (genTNAReportList.tnaTodoInprogressList || genTNAReportList.tnaCompletedList) && genTNAReportList.tnaTaskNameList && Searchvalues &&

                                                <div style={{ padding: "5px" }}>
                                                    <div className="row">


                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="Buyer">
                                                                    Task Type
                                                                    {/* <span className="text-danger">*</span> */}
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <Reactselect className="basic-single rptDrpTaskType" name="Task Type"
                                                                        id={
                                                                            "TaskType"
                                                                        }
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={event => handlechange(event, 'TaskType')}
                                                                        value={Searchvalues.lstTaskType && Searchvalues.lstTaskType.filter(function (option) {
                                                                            return option.value === Searchvalues.TaskType;
                                                                        })}
                                                                        styles={{
                                                                            // Fixes the overlapping problem of the component
                                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                                        }}
                                                                        options={Searchvalues && Searchvalues.lstTaskType}
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label htmlFor="SupplierID">
                                                                    Task Name
                                                                    {/* <span className="text-danger">*</span> */}
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <Reactselect className="basic-single" name="TaskName"
                                                                        id={
                                                                            "TaskName"
                                                                        }
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={event => handlechange(event, 'TaskName')}
                                                                        value={Searchvalues.lstTaskName && Searchvalues.lstTaskName.filter(function (option) {
                                                                            return option.value === Searchvalues.TaskName;
                                                                        })}
                                                                        options={Searchvalues && Searchvalues.lstTaskName}
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        <br></br>
                                                        <div className="btn-toolbar rptBtnGroup" role="toolbar" aria-label="Toolbar with button groups ">
                                                            <div className="btn-group mr-2" role="group" aria-label="First group">

                                                                <button type="button" className="btn btn-xs btn-danger "
                                                                    onClick={event => handlechange(event, '1')} disabled={Searchvalues.TodoDelay > 0 ? false : true} >
                                                                    Delay
                                                                    &nbsp;
                                                                    <b> {Searchvalues.TodoDelay}</b>
                                                                    {Searchvalues.Status === 1 &&
                                                                        // <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }
                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-success"
                                                                    onClick={event => handlechange(event, '2')} disabled={Searchvalues.TodoUpcoming > 0 ? false : true} >
                                                                    Upcoming
                                                                    &nbsp;
                                                                    <b> {Searchvalues.TodoUpcoming} </b>
                                                                    {Searchvalues.Status === 2 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }
                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-primary"
                                                                    onClick={event => handlechange(event, '3')} disabled={Searchvalues.TodoToday > 0 ? false : true}>
                                                                    Today
                                                                    &nbsp;
                                                                    <b> {Searchvalues.TodoToday} </b>
                                                                    {Searchvalues.Status === 3 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-warning "
                                                                    onClick={event => handlechange(event, '4')} disabled={Searchvalues.TodoAll > 0 ? false : true} >
                                                                    To Do & In-progress All
                                                                    &nbsp;
                                                                    <b> {Searchvalues.TodoAll} </b>
                                                                    {Searchvalues.Status === 4 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }


                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-success "
                                                                    onClick={event => handlechange(event, '5')} disabled={Searchvalues.CompletedEarly > 0 ? false : true}>
                                                                    Completed Early
                                                                    &nbsp;
                                                                    <b> {Searchvalues.CompletedEarly} </b>
                                                                    {Searchvalues.Status === 5 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-primary "
                                                                    onClick={event => handlechange(event, '6')} disabled={Searchvalues.CompletedOntime > 0 ? false : true} >
                                                                    Completed Ontime
                                                                    &nbsp;
                                                                    <b> {Searchvalues.CompletedOntime} </b>
                                                                    {Searchvalues.Status === 6 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-danger "
                                                                    onClick={event => handlechange(event, '7')} disabled={Searchvalues.CompletedDelay > 0 ? false : true} >
                                                                    Completed Delay
                                                                    &nbsp;
                                                                    <b> {Searchvalues.CompletedDelay} </b>
                                                                    {Searchvalues.Status === 7 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>



                                                                <button type="button" className="btn btn-xs btn-warning "
                                                                    onClick={event => handlechange(event, '8')} disabled={Searchvalues.CompletedAll > 0 ? false : true} >
                                                                    Completed All
                                                                    &nbsp;
                                                                    <b> {Searchvalues.CompletedAll} </b>
                                                                    {Searchvalues.Status === 8 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>


                                                                <button type="button" className="btn btn-xs btn-success"
                                                                    onClick={event => handlechange(event, '9')}
                                                                // disabled={Searchvalues.OverAllCount > 0 ? false : true}
                                                                >
                                                                    Overall
                                                                    &nbsp;
                                                                    <b> {Searchvalues.OverAllCount} </b>
                                                                    {Searchvalues.Status === 9 &&
                                                                        <img className="FilterIcon" src="assets/img/Gif/tick.gif" alt=""></img>
                                                                    }

                                                                </button>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>

                                            }



                                        </div>
                                        <br />
                                        {genTNAReportList && genTNAReportList.genTNADetailList && genTNAReportList.genTNADetailList.length !== 0 ?
                                            <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "130%" }}>
                                                    <thead>
                                                        <tr >
                                                            <th style={{ width: "125px" }} className="fixed-column-header-first-tna"> #</th>
                                                            {/* <th style={{ width: "250px" }}>TNA No</th> */}
                                                            <th style={{ width: "450px" }}>Task Type</th>
                                                            <th className="borderRightLeftNone" style={{ width: "1000px" }}>Task Name</th>
                                                            <th className="borderRightLeftNone" style={{ width: "400px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "300px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "300px" }}></th>
                                                            <th style={{ width: "200px" }}>Plan End</th>
                                                            <th style={{ width: "200px" }}>Actual End</th>
                                                            <th className="borderRightLeftNone" style={{ width: "320px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "320px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "280px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "360px" }}></th>
                                                            <th className="borderRightLeftNone" style={{ width: "620px" }}></th>
                                                            <th style={{ width: "300px" }} className="text-center fixed-column-header-Route">Action</th>

                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {

                                                            genTNAReportList.genTNADetailList.map((inputField, index) => (
                                                                <Fragment key={`${inputField}~${index}`}>

                                                                    <tr class="parent" data-child={"parent_" + index}>
                                                                        <td className="fixed-column-first-tna">
                                                                            <b> {index + 1}&nbsp;</b>
                                                                            {
                                                                                <span id={"btn-toggle_" + index} class="btn-toggle parentExpand" aria-expanded="false" onClick={() => handleToggle(index)} aria-labelledby="btn-toggle id-master">
                                                                                    <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                                </span>
                                                                            }

                                                                        </td>

                                                                        <td>
                                                                            <span className='input-icon icon-right'>

                                                                                {
                                                                                    inputField.taskTypeName
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td colSpan="4">
                                                                            <span className='input-icon icon-right'>
                                                                                {
                                                                                    inputField.taskName
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                inputField.endDate === null ? '' : moment(inputField.endDate).format('DD/MM/YYYY')
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                (inputField.actualEndDate === null || inputField.actualEndDate === '') ? '' : moment(inputField.actualEndDate).format('DD/MM/YYYY')
                                                                            }
                                                                        </td>
                                                                        <td colSpan="5">
                                                                            {/* style={{ display: StartStopInfo.isAdd === 0 ? 'none' : '' }} */}
                                                                        </td>
                                                                        <td className="fixed-column-Route btn-action-center">
                                                                            {StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                <button type="button" className={inputField.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Depedency)"
                                                                                    onClick={() => handleDependent(index, inputField.intDependency)}
                                                                                >


                                                                                    <i className={inputField.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                </button>
                                                                            }




                                                                        </td>
                                                                    </tr>
                                                                    {

                                                                        inputField.genTNAParentChildList && inputField.genTNAParentChildList.map((subTask, subIndex) => (

                                                                            <Fragment key={
                                                                                `${subTask}~${subIndex}`
                                                                            }>

                                                                                <tr class={"parentChild parent_" + index + " "} data-child={"parentChild_" + index + "_" + subIndex} >
                                                                                    <td className="fixed-column-first-tna">
                                                                                        <span id={"btn-toggle_parentChild_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.isExpanded} onClick={() => handleChildToggle(index, subIndex, subTask.mainId, subTask.colorImageId)} >
                                                                                            <i id={"icon-toggle_parentChild_" + index + "_" + subIndex} class={subTask.isExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className="borderRightLeftNone" colSpan={2}>
                                                                                        {
                                                                                            subTask.mainId > 0 ? <span> {subTask.code} </span> : <span> {inputField.taskName} </span>
                                                                                        }

                                                                                    </td>
                                                                                    <td className="borderRightLeftNone" colSpan="10">
                                                                                        {
                                                                                            (!subTask.colorOrName) ?
                                                                                                <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                                                    <img className="width_manual"
                                                                                                        src={subTask.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.imagePath}
                                                                                                        alt="Logo"
                                                                                                        onClick={
                                                                                                            () => setIsOpen(subTask.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.imagePath)
                                                                                                        }
                                                                                                    />
                                                                                                </div> :
                                                                                                <div style={{ background: subTask.colorOrName, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                                        }
                                                                                    </td>

                                                                                    <td className="fixed-column-Route"></td>

                                                                                </tr>
                                                                                {

                                                                                    subTask.genTNAChildList && subTask.genTNAChildList.map((childTask, childIndex) => (

                                                                                        <Fragment key={
                                                                                            `${childTask}~${childIndex}`
                                                                                        }>
                                                                                            {childIndex === 0 &&
                                                                                                <tr style={{ whiteSpace: "nowrap" }} class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " TNAReportChildHeader " : "child_" + index + " parentChild_" + index + "_" + subIndex + " TNAReportChildHeader "}>
                                                                                                    <th className="fixed-column-first-tna TNAReportChildHeader">#</th>
                                                                                                    <th colSpan="2">Details</th>
                                                                                                    <th>Story</th>
                                                                                                    <th>Theme</th>
                                                                                                    <th>Supplier</th>
                                                                                                    <th>Plan End</th>
                                                                                                    <th>Actual End</th>
                                                                                                    <th>Dependency</th>
                                                                                                    <th>Task Holder / Follower</th>
                                                                                                    <th>Leave Days</th>
                                                                                                    <th>Status</th>
                                                                                                    <th>AWB & Status</th>
                                                                                                    <th className="fixed-column-Route btn-action-center" style={{ background: "#222d32" }}>Action</th>
                                                                                                </tr>
                                                                                            }


                                                                                            <tr class={subTask.isExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " : "child_" + index + " parentChild_" + index + "_" + subIndex + " "} >

                                                                                                <td className="fixed-column-first-tna">{childIndex + 1}</td>

                                                                                                <td colSpan="2">{childTask.details}</td>

                                                                                                <td>
                                                                                                    {childTask.storyName}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {childTask.themeName}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {childTask.supplierName}
                                                                                                </td>
                                                                                                <td>

                                                                                                    <span className="input-icon icon-right">
                                                                                                        {
                                                                                                            childTask.endDate === null ? '' : moment(childTask.endDate).format('DD/MM/YYYY')
                                                                                                        }

                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>

                                                                                                    <span className="input-icon icon-right">
                                                                                                        {
                                                                                                            (childTask.actualEnd === null || childTask.actualEnd === '') ? '' : moment(childTask.actualEnd).format('DD/MM/YYYY')
                                                                                                        }

                                                                                                    </span>
                                                                                                </td>

                                                                                                <td>

                                                                                                    {
                                                                                                        childTask.dependencyID === 1 ? "ES" : childTask.dependencyID === 2 ? "SS" : childTask.dependencyID === 3 ? "SE" : childTask.dependencyID === 4 ? "EE" : ""

                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        <span className="fa fa-eye">
                                                                                                            {
                                                                                                                childTask.taskHolderFollowerNamesIds &&
                                                                                                                <span onClick={() => handleTaskOwnerDetails(childTask.taskHolderFollowerNamesIds)} className="text-primary"> {"Task Owner Details"} </span>
                                                                                                            }
                                                                                                        </span>
                                                                                                    }

                                                                                                </td>
                                                                                                <td title={childTask.leaveDaysName} className="TNAReport_LeaveDays_textoverflow">
                                                                                                    {
                                                                                                        childTask.leaveDaysName
                                                                                                    }

                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name="StatusID"
                                                                                                            // id={
                                                                                                            //     "Status" + index
                                                                                                            // }
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            // onChange={event => handleChangeChild(event, index, subIndex, childIndex, "StatusID")}
                                                                                                            value={getTNAStatusList.filter(function (option) {
                                                                                                                return option.value === childTask.taskStatus;
                                                                                                            })}
                                                                                                            options={getTNAStatusList}
                                                                                                            isDisabled={true}
                                                                                                            menuPosition="fixed"
                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input type="text"
                                                                                                            placeholder="AWB & Status"
                                                                                                            // id={"AWBStatus" + index}
                                                                                                            // name="AWBStatus"
                                                                                                            value={childTask.awbName}
                                                                                                            //onChange={event => handleChangeChild(event, index, subIndex, childIndex, "AWBStatus")}
                                                                                                            className={'form-control'}
                                                                                                            readOnly={true}
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td className="fixed-column-Route">
                                                                                                    {/* {
                                                                                                        <>
                                                                                                            {(childTask.isShowSubmitButton === 1 || childTask.isShowSubmitButton === 2) && childTask.status !== 3 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                                                // onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.taskName, childTask.ChildId, childTask.status)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                </button>
                                                                                                                </Fragment>
                                                                                                                : (childTask.manualCloseComment !== '' && childTask.manualCloseComment !== null && childTask.manualCloseComment !== undefined) && childTask.status === 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                                                    //onClick={() => handleChildManualclose(index, subIndex, childIndex, childTask.taskName, childTask.ChildId, childTask.status)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>
                                                                                                                    : ''
                                                                                                            }
                                                                                                        </>} */}
                                                                                                    {

                                                                                                        childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.manualCloseComment === '' && childTask.submitStatus === "Pending" ?
                                                                                                            <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                                                onClick={() => handleLabdipsubManualclose(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                    childTask.refernceId, index
                                                                                                                )}
                                                                                                            >
                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                <i className="fa fa-close"></i>
                                                                                                            </button>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.manualCloseComment !== '' && childTask.submitStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Submitted Manual Close"
                                                                                                                    onClick={() => handleLabdipsubManualclose(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                        childTask.refernceId, index
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''
                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {

                                                                                                        <div className="tooltip-container">
                                                                                                            <button type="button" className="btn btn-xs btn-primary tooltip-button"><i className="fa fa-calendar"></i></button>

                                                                                                            <div className="tooltip-content">
                                                                                                                {/* Attach onClick event handler to call redirectToEditPage */}
                                                                                                                <a
  href="/SamGenlLabdipList"
  target="_blank"
  rel="noopener noreferrer"
  onClick={() => handleLinkClick(childTask)}
>
  {genTNAReportList && (genTNAReportList.genTNADetailList[index].taskId === 15 || genTNAReportList.genTNADetailList[index].taskId === 14)
    ? 'Lab Dip Edit'
    : (
        genTNAReportList.genTNADetailList[index].taskId === 17 || genTNAReportList.genTNADetailList[index].taskId === 16
        ? 'Strike off Edit'
        : (
            genTNAReportList.genTNADetailList[index].taskId === 19 || genTNAReportList.genTNADetailList[index].taskId === 18
            ? 'Trim Edit'
            : 'Unknown Edit'
          )
      )
}
</a>

                                                                                                                <span> &nbsp; / &nbsp; </span>
                                                                                                                <a
                                                                                                                    href="/TNAView"
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    onClick={() => handleLinkClick(childTask)}
                                                                                                                >
                                                                                                                    View
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {

                                                                                                        childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.isShowSubmitButton === 0 &&
                                                                                                            childTask.manualCloseComment === '' ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                onClick={() => OpenLabdipSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId, childTask.refernceId,
                                                                                                                    index, subIndex, childIndex)}
                                                                                                            >
                                                                                                                <i className="fa fa-send"></i>
                                                                                                            </button> :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.submitStatus === "Completed" &&
                                                                                                                childTask.isFormSubmission === 1 ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View LabDipSubmission'
                                                                                                                    onClick={() => OpenLabdipSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.genMainId,
                                                                                                                        childTask.refernceId, index, subIndex, childIndex)}>

                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> :
                                                                                                                ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {
                                                                                                        //LapDipSubmissionTab && LapDipSubmissionTab.isAdd !== 0 &&
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                            && childTask.approvalManualCloseComment === ''
                                                                                                            && childTask.approvalStatus === "Pending"
                                                                                                            && childTask.isManualSubmission === 1
                                                                                                            ?
                                                                                                            <Fragment>&nbsp;
                                                                                                                <button type="button" className="btn btn-xs btn-darkorange" title="Add Approval Manual Close"
                                                                                                                    onClick={() => handleLabAppManualclose(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                        childTask.refernceId, index)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                </button>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.manualCloseComment !== '' && childTask.approvalStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                                                    onClick={() => handleLabAppManualclose(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        //childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                        childTask.refernceId, index)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''

                                                                                                    }
                                                                                                    {
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.isShowApprovalButton === 0 &&
                                                                                                            childTask.manualCloseComment === ''

                                                                                                            ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                onClick={() => OpenLabdipApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                    childTask.refernceId, index, subIndex, childIndex)}
                                                                                                            >
                                                                                                                <i className="fa fa-check"></i>
                                                                                                            </button> :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.isShowApprovalButton === 1 && childTask.manualCloseComment === '' ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View LabDipApproval'
                                                                                                                    onClick={() => OpenLabdipApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.pantoneID, childTask.genMainId,
                                                                                                                        childTask.refernceId, index, subIndex, childIndex)}
                                                                                                                > <i className="fa fa-eye "></i>
                                                                                                                </button>


                                                                                                                : ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {

                                                                                                        // StrikeOffRequestTab !== undefined && StrikeOffRequestTab.isEdit !== 0 &&
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.manualCloseComment === '' && childTask.submitStatus === "Pending" ?
                                                                                                            <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                                                onClick={() => handleStrikeManualclose(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index
                                                                                                                )}
                                                                                                            >
                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                <i className="fa fa-close"></i>
                                                                                                            </button>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.manualCloseComment !== '' && childTask.submitStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Submitted Manual Close"
                                                                                                                    onClick={() => handleStrikeManualclose(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT &&
                                                                                                            childTask.isShowSubmitButton === 0 && childTask.manualCloseComment === '' ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                onClick={() => OpenStrikeOffSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    //subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index, subIndex, childIndex
                                                                                                                )}
                                                                                                            >
                                                                                                                <i className="fa fa-send"></i>
                                                                                                            </button> :


                                                                                                            childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT &&
                                                                                                                childTask.submitStatus === "Completed" && childTask.isFormSubmission === 1 ?

                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                    onClick={() => OpenStrikeOffSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        //subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index, subIndex, childIndex
                                                                                                                    )}>

                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> :
                                                                                                                ''
                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {

                                                                                                        childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.approvalManualCloseComment === ''
                                                                                                            && childTask.approvalStatus === "Pending" && childTask.isManualSubmission === 1 ?
                                                                                                            <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Add Approval Manual Close"
                                                                                                                onClick={() => handleStrikeAppManualclose(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index, childIndex)}

                                                                                                            >
                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                <i className="fa fa-close"></i>
                                                                                                            </button>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.manualCloseComment !== '' && childTask.approvalStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                                                    onClick={() => handleStrikeAppManualclose(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index, childIndex)}

                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''



                                                                                                    }
                                                                                                    {
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.isShowApprovalButton === 0 &&
                                                                                                            childTask.manualCloseComment === '' ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                onClick={() => OpenStrikeOffApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index, subIndex)}

                                                                                                            >
                                                                                                                <i className="fa fa-check"></i>
                                                                                                            </button> :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.isShowApprovalButton === 1 && childTask.manualCloseComment === ''  ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                    onClick={() => OpenStrikeOffApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genStrikeOffId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index, subIndex)}>
                                                                                                                    <i className="fa fa-eye "></i>
                                                                                                                </button>
                                                                                                                : ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {


                                                                                                        // TrimRequestTab !== undefined && TrimRequestTab.isEdit !== 0 &&
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.manualCloseComment === '' && childTask.submitStatus === "Pending" ?
                                                                                                            <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                                                onClick={() => handlemanualTrimSub(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId, childTask.genMainId,
                                                                                                                    childTask.refernceId, index
                                                                                                                )}
                                                                                                            >
                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                <i className="fa fa-close"></i>
                                                                                                            </button>
                                                                                                            </Fragment>
                                                                                                            : childTask.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.manualCloseComment !== '' && childTask.submitStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Submitted Manual Close"
                                                                                                                    onClick={() => handlemanualTrimSub(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId, childTask.genMainId,
                                                                                                                        childTask.refernceId, index
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''
                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.isShowSubmitButton === 0 && childTask.manualCloseComment === '' ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                onClick={() => OpenTrimSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId, childTask.genMainId,
                                                                                                                    childTask.refernceId, index, subIndex
                                                                                                                )}
                                                                                                            >
                                                                                                                <i className="fa fa-send"></i>
                                                                                                            </button> :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.submitStatus === "Completed" && childTask.isFormSubmission === 1 ?

                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                    onClick={() => OpenTrimSubmssionpopup(childTask.submissionAndApprovalId, childTask.submitStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId, childTask.genMainId,
                                                                                                                        childTask.refernceId, index, subIndex
                                                                                                                    )}>

                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> :
                                                                                                                ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {
                                                                                                        /* {
                                                                                                            TrimSubmissionTab !== undefined && TrimSubmissionTab.isAdd !== 0 && */
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.approvalManualCloseComment === '' && childTask.approvalStatus === "Pending" && childTask.isManualSubmission === 1 ?
                                                                                                            <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Add Approval Manual Close"
                                                                                                                onClick={() => handletirmappmanual(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index)}

                                                                                                            >
                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                <i className="fa fa-close"></i>
                                                                                                            </button>
                                                                                                            </Fragment>
                                                                                                            :
                                                                                                            childTask.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.manualCloseComment !== '' && childTask.approvalStatus === "Completed" ?
                                                                                                                <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                                                    onClick={() => handletirmappmanual(childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-eye"></i>
                                                                                                                </button> : ''

                                                                                                    }
                                                                                                    &nbsp;
                                                                                                    {
                                                                                                        childTask.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.isShowApprovalButton === 0 && childTask.manualCloseComment === '' ?
                                                                                                            <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                onClick={() => OpenTrimApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                    // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                    childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId,
                                                                                                                    childTask.genMainId, childTask.refernceId, index, subIndex)}
                                                                                                            >
                                                                                                                <i className="fa fa-check"></i>
                                                                                                            </button> :

                                                                                                            childTask.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.isShowApprovalButton === 1 && childTask.manualCloseComment === ''  ?
                                                                                                                // TrimApprovalTab && TrimApprovalTab.isView !== 0 &&
                                                                                                                <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                    onClick={() => OpenTrimApprovalpopup(childTask.isFormSubmission, childTask.submissionAndApprovalId, childTask.approvalStatus,
                                                                                                                        // childTask.buyerId, childTask.brandId, childTask.seasonId, childTask.supplierId,
                                                                                                                        childTask.follower, childTask.taskID, childTask.mainId, childTask.logoId, childTask.genTrimId,
                                                                                                                        childTask.genMainId, childTask.refernceId, index, subIndex)}>
                                                                                                                    <i className="fa fa-eye "></i>
                                                                                                                </button>
                                                                                                                : ''


                                                                                                    }
                                                                                                    &nbsp;


                                                                                                </td>
                                                                                            </tr>
                                                                                        </Fragment>
                                                                                    ))
                                                                                }
                                                                            </Fragment>

                                                                        ))

                                                                    }

                                                                </Fragment>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div> : <div className="row">
                                                <div className="col-sm-5">
                                                </div>
                                                <div className="col-sm-3">
                                                    <span className="danger">No records found</span>
                                                </div>
                                            </div>
                                        }


                                    </form>

                                </div>
                            </div>

                            {/* LABDPSUBMISSION*/}
                            {
                                showSubmission === true ?
                                    <Modal 
                                    show={showSubmission === true} 
                                    size="xl"  
                                    dragable 
                                    backdrop="static" 
                                    keyboard={false} 
                                    onHide={() => setshowSubmission(false)}
                                   
                                    >
                                        <Modal.Header closeButton >
                                            <Modal.Title>
                                                {getheader} Lab Dip Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?

                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                    {
                                                                        MultiCloseDatas.length !== 0 ?

                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Story</th>
                                                                                        <th>Theme</th>
                                                                                        <th>Details </th>
                                                                                        <th>Task Holder / Follower</th>
                                                                                        <th>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                    onChange={event => (LapdipsubSelectAll(event))}
                                                                                                    checked={selectall === 1 ? true : false}
                                                                                                    className="colored-blue clearOpacity" />
                                                                                                <span className="text"></span>
                                                                                            </label>
                                                                                        </th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {

                                                                                        MultiCloseDatas.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>{item.storyName}</td>
                                                                                                        <td>{item.themeName}</td>
                                                                                                        <td>{item.itemDetails}</td>
                                                                                                        <td>{item.taskHolderFollowerNames}</td>
                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                                        onChange={event => (LapdipsubOnSelectRecords(event, index))}
                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                    <span className="text"></span>
                                                                                                                </label>

                                                                                                            </div>

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                                    <i className="fa fa-check right"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showSubmissiondiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="BuyerID">
                                                                                Buyer-Season-Brand
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].BuyerBrandSeasonName}
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={Labdipvalues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="SupplierID">
                                                                                Supplier
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].SupplierName}
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={Labdipvalues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="Name">
                                                                                Submit Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    Labdipvalues[0].SubmitStatus === "Completed" ?
                                                                                        // <input
                                                                                        //     placeholder="Supplier"
                                                                                        //     className="form-control"
                                                                                        //     title={Labdipvalues[0].SubmittedDate}
                                                                                        //     name="SupplierID"
                                                                                        //     id={"SupplierID"}
                                                                                        //     value={Labdipvalues[0].SubmittedDate}
                                                                                        //     disabled

                                                                                        // ></input>
                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                Labdipvalues[0].SubmittedDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                        :
                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                Labdipvalues[0].SubmittedDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => handleChange(event, 'SubmittedDate')}
                                                                                            customInput={<CustomInputsubmit />}

                                                                                        />
                                                                                    // <input
                                                                                    //     id="SubmittedDate"
                                                                                    //     name="SubmittedDate"
                                                                                    //     value={Labdipvalues[0].SubmittedDate}
                                                                                    //     placeholder="DD/MM/YYYY"
                                                                                    //     type="date"
                                                                                    //     autoComplete="off"
                                                                                    //     className={"form-control"}
                                                                                    //     onKeyDown={(e) => e.preventDefault()}
                                                                                    //     style={{
                                                                                    //         border: submittedpopup && Labdipvalues[0].SubmittedDate === '' ? '1px solid red' : ''
                                                                                    //     }}
                                                                                    //     onChange={event => handleChange(event, 'SubmittedDate')}
                                                                                    // />

                                                                                }

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">

                                                                                {
                                                                                    getheader === 'View' ?
                                                                                        <span>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                title={labdipinputFields[0].SendToEdit}
                                                                                                value={labdipinputFields[0].SendToEdit} />
                                                                                        </span>
                                                                                        :
                                                                                        <span className=''>
                                                                                            <Reactselect
                                                                                                className="basic-single"
                                                                                                name={"Sku"}
                                                                                                id={
                                                                                                    "Sent To"
                                                                                                }
                                                                                                // value={item.MeasurementPoints}
                                                                                                //isDisabled={false}
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                options={getTaskholder}
                                                                                                value={labdipinputFields[0].SendToEdit}
                                                                                                isMulti
                                                                                                onChange={event => handlemultidd(event, 'SendTo')}
                                                                                                menuPosition="fixed"
                                                                                                styles={submittedpopup && labdipinputFields[0].SendTo === "" ? styles2s : styles1s}
                                                                                            // readOnly
                                                                                            // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                }
                                                                                {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                                                    value={Labdipvalues[0].SendTo}
                                                                                    onChange={(event) => handleChange(event, 'SendTo')}
                                                                                    style={{ border: submittedpopup && Labdipvalues[0].SendTo === "0" ? '1px solid red' : '' }}
                                                                                    disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                >
                                                                                    <option value="0">-Select SendTo-</option>
                                                                                    {
                                                                                        getTaskholder.map(item => (
                                                                                            <option key={item.id} value={item.value}>
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">


                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={Labdipvalues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={Labdipvalues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={Labdipvalues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                                            <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                                                value={Labdipvalues[0].SendThrough}
                                                                                onChange={event => handleChange(event, 'Sendthrough')}
                                                                                style={{ border: submittedpopup && Labdipvalues[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                                                disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                            >
                                                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Sendthrough- </option>
                                                                                <option value="1" key="1">Hand Carried</option>
                                                                                <option value="2" key="2">Courier - DHL</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        showawb === true ?
                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="Awbdate" >AWB/Date</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder="AWB/Date"
                                                                                            className="form-control"
                                                                                            name="AWBDateandNo"
                                                                                            id={
                                                                                                "AWBDateandNo"
                                                                                            }
                                                                                            value={Labdipvalues[0].AWBDateandNo}
                                                                                            //style={{ border: submittedpopup && Labdipvalues[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                                            onClick={() => setShowlabdipPopup(true)}
                                                                                            disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div> : ''
                                                                    }
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab1"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a
                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">

                                                                            <div id="idSubmitInformation1" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                                                <div className="row">
                                                                                    <div className="col-sm-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    {
                                                                                                        Labdipvalues[0].PantoneTypeName !== "Others" ?
                                                                                                            <>
                                                                                                                <label htmlFor="BuyerID">Pantone #</label>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input
                                                                                                                        placeholder="Pantone #"
                                                                                                                        className="form-control"

                                                                                                                        name="PantoneCodeandType"
                                                                                                                        id={
                                                                                                                            "PantoneCodeandType"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            Labdipvalues[0].PantoneTypeName + "-" + Labdipvalues[0].PantoneCode + "-" + Labdipvalues[0].PantoneNameOrSwatchName
                                                                                                                        }
                                                                                                                        disabled

                                                                                                                    ></input>
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <label htmlFor="SwatchName">Swatch Name<span className="text-danger">*</span></label>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input
                                                                                                                        placeholder="Swatch Name"
                                                                                                                        className="form-control"
                                                                                                                        title={Labdipvalues[0].PantoneNameOrSwatchName}
                                                                                                                        name="SwatchName"
                                                                                                                        id={
                                                                                                                            "SwatchName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            Labdipvalues[0].PantoneNameOrSwatchName
                                                                                                                        }
                                                                                                                        disabled

                                                                                                                    ></input>
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Ref.ColorName</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="Ref.ColorName"
                                                                                                            className="form-control"
                                                                                                            title={Labdipvalues[0].RefColorName}
                                                                                                            name="RefColorName"
                                                                                                            id={
                                                                                                                "RefColorName"
                                                                                                            }
                                                                                                            value={
                                                                                                                Labdipvalues[0].RefColorName
                                                                                                            }
                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    {
                                                                                                        Labdipvalues[0].PantoneTypeName !== "Others" ?
                                                                                                            <>
                                                                                                                <label htmlFor={"Quarter"}>Color/Image</label>
                                                                                                                {
                                                                                                                    Labdipvalues[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                                                        <div style={{ background: Labdipvalues[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                        :
                                                                                                                        <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                }
                                                                                                            </> :
                                                                                                            <>
                                                                                                                <img src={Labdipvalues[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                />
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"FabricID"}>

                                                                                                        Fabric Details<span className="text-danger">*</span></label>


                                                                                                    <span className="input-icon icon-right">
                                                                                                        <select id={"FabricID"} name="FabricID" className="form-select"
                                                                                                            value={Labdipvalues[0].FabricID}
                                                                                                            onChange={(event) => handleChange(event, 'FabricID')}
                                                                                                            style={{ border: submittedpopup && Labdipvalues[0].FabricID === "0" ? '1px solid red' : '' }}
                                                                                                            disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                        >
                                                                                                            <option value="0">-Select Fabric-</option>
                                                                                                            {
                                                                                                                FabricDetails.map(item => (
                                                                                                                    <option key={item.id} value={item.value}>
                                                                                                                        {item.label}
                                                                                                                    </option>
                                                                                                                ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>


                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3">

                                                                                                <label style={{ marginTop: '32px' }}>
                                                                                                    <input type="checkbox" name="SelectedDetail"
                                                                                                        className="colored-blue clearOpacity"
                                                                                                        onChange={event => (ontransfer(event))}
                                                                                                        checked={Labdipvalues[0].IsAllFabricLoad === 1 ? 1 : 0}
                                                                                                        disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                        style={{ border: "1px solid blue" }}
                                                                                                    />

                                                                                                    <span className="text"></span>
                                                                                                    <label htmlFor="">Load All Fabric</label>
                                                                                                </label>


                                                                                            </div>
                                                                                        </div>


                                                                                        <div>
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Swatch Card (Image Upload)</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td> <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "500px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th >Action</th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption.map((item, index) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span className="input-icon icon-right">
                                                                                                                                                <input
                                                                                                                                                    placeholder="Option Name"
                                                                                                                                                    className="form-control"
                                                                                                                                                    title={item.OptionName}
                                                                                                                                                    name="OptionName"
                                                                                                                                                    id={
                                                                                                                                                        "OptionName"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.OptionName
                                                                                                                                                    }
                                                                                                                                                    onChange={event => (handleLabAppSubmitoptionchange(event, 'OptionName', index))}
                                                                                                                                                    style={{ width: "250px", border: submittedpopup && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                                                    disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>


                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                <input
                                                                                                                                                    placeholder=""
                                                                                                                                                    className="form-control cut_text"
                                                                                                                                                    title={item.SupplierReferenceNo}
                                                                                                                                                    name="SupplierReferenceNo"
                                                                                                                                                    id={
                                                                                                                                                        "SupplierReferenceNo"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.SupplierReferenceNo
                                                                                                                                                    }
                                                                                                                                                    onChange={event => (handleLabAppSubmitoptionchange(event, 'SupplierReferenceNo', index))}


                                                                                                                                                    disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>
                                                                                                                                            </span>

                                                                                                                                        </td>
                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                            {" "}
                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                {submittedoption.length !== 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                                                        title="Delete option"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleRemoveFields(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                                &nbsp;
                                                                                                                                                {submittedoption.length === index + 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                                                        title="Add option"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleAddField(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div></td>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={Labdipvalues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + Labdipvalues[0].SwatchCardIamgePath}

                                                                                                                onClick={openFileUpload}
                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                                                                                disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                            />
                                                                                                            {
                                                                                                                Labdipvalues[0].SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                                                    <div style={
                                                                                                                        {
                                                                                                                            marginTop: '8px',
                                                                                                                            float: 'right',
                                                                                                                            bottom: '10px',
                                                                                                                            position: 'relative',
                                                                                                                        }
                                                                                                                    }>


                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            <div id="idOrderInformation1" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                                                <div className="row">
                                                                                    <div className="col-sm-12" align={"right"}>
                                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                            disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}>
                                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                            {
                                                                                                getOrderinfo.length !== 0 ?

                                                                                                    <table className="table table-bordered">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            {

                                                                                                                getOrderinfo.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td>{item.storyName}</td>
                                                                                                                                <td>{item.themeName}</td>
                                                                                                                                <td>{item.itemDetails}</td>
                                                                                                                                <td>{item.taskHolderFollowerNames}</td>
                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                            <input type="checkbox" name="SelectedDetail"
                                                                                                                                                disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                onChange={event => (OnChangeLabdipOrderinfo(event, index))}
                                                                                                                                                checked={item.selectedDetail === 1 ? true : false}
                                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                                            <span className="text"></span>
                                                                                                                                        </label>
                                                                                                                                        &nbsp;&nbsp;
                                                                                                                                        {
                                                                                                                                            getOrderinfo.length !== 1 &&
                                                                                                                                            (
                                                                                                                                                <button
                                                                                                                                                    disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    type="button"
                                                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                                                    title="Delete"
                                                                                                                                                    onClick={() =>
                                                                                                                                                        handleRemoveOrderInfo(index)
                                                                                                                                                    }
                                                                                                                                                >
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </button>
                                                                                                                                            )
                                                                                                                                        }


                                                                                                                                    </div>

                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="mt-10">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="300" name="Remarks" value={Labdipvalues[0].Remarks}
                                                                                onChange={event => handleChange(event, 'Remarks')}
                                                                                disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                rows="4" style={{ width: "100%" }}></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                                                    disabled={Labdipvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                >
                                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {
                                                            ShowlabdipPopup === true ?
                                                                <Modal show={ShowlabdipPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowlabdipPopup(false)}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            AWB and Date
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                                                    <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                                                    {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>

                                                                                <tr>
                                                                                    <td>
                                                                                        <span className='input-icon icon-right'>
                                                                                            <input type="text"
                                                                                                placeholder="AWB Name"
                                                                                                id={"AWBName"}
                                                                                                name="AWBName"
                                                                                                value={Labdipvalues[0].AWBName}
                                                                                                style={{ border: submittedpopup && !Labdipvalues[0].AWBName ? '1px solid red' : '' }}
                                                                                                autoFocus
                                                                                                maxLength="25"
                                                                                                className={'form-control'}
                                                                                                onChange={event => labdiphandlepopupchange(event, 'AWBName')}
                                                                                                menuPosition="fixed"
                                                                                            // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input id={"AWBDate"} name="AWBDate"
                                                                                                value={Labdipvalues[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                className={'form-control'}
                                                                                                style={{ border: submittedpopup && Labdipvalues[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                                onChange={event => labdiphandlepopupchange(event, 'AWBDate')}
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="success" onClick={() => labdipSaveAwbnoDate()}>
                                                                            <i className="fa fa-check right"></i>&nbsp;
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                : ''
                                                        }
                                                    </>
                                                    : ''
                                            }

                                        </Modal.Body>

                                    </Modal> : ''

                            }
                            {/* SUBMISSIONMANUAL */}
                            {
                                showManual === true ?
                                    <Modal show={showManual === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Lab Dip Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments<span className="text-danger">*</span></th>
                                                        <th>Close Date<span className="text-danger">*</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style={{ width: "600px" }} >
                                                            <textarea
                                                                rows="3"
                                                                placeholder="Enter Comments here..."
                                                                autoFocus onFocus={""} autoComplete="off"
                                                                maxLength="500" name="EnterRemarks"
                                                                id={"EnterRemarks"}
                                                                value={ManualInfo[0].manualCloseComment}
                                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                                style={{ width: "100%", border: getComment && ManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}

                                                            //  disabled={subField.submitStatus === "Completed" ? true : false}
                                                            >

                                                            </textarea>
                                                        </td>
                                                        <td >
                                                            {

                                                                //  getheader !== "Completed" ?
                                                                <DatePicker className="form-control" name="HolidayDate"
                                                                    id={
                                                                        "HolidayDate"
                                                                    }
                                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                    selected={Date.parse(moment(ManualInfo[0].manualCloseDate, 'MM/DD/YYYY').toISOString())}
                                                                    onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth
                                                                    showMonthDropdown

                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    minDate={new Date()}
                                                                    maxDate={new Date()}
                                                                    customInput={<ManualCloseCustomInput />}


                                                                // disabled={getheader !== "Add" ? true : false}
                                                                />


                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"

                                                                        onChange={event => (LapdipsubSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity"
                                                                        disabled={getheader !== "Add" ? true : false}
                                                                    />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{item.itemDetails}</td>
                                                                                <td>{item.taskHolderFollowerNames}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                onChange={event => (LapdipsubOnSelectRecords(event, index))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity"
                                                                                                disabled={getheader !== "Add" ? true : false}
                                                                                            />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                getheader === "Add" &&
                                                <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }

                            {   /* LABDIPAPPROVAL */}

                            {


                                showladappmanual === true ?
                                    <Modal show={showladappmanual === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowladappmanual(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Lab Dip Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments <span className="text-danger">*</span></th>
                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>


                                                        <td style={{ width: "600px" }} >
                                                            {
                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].approvalManualCloseComment}
                                                                        onChange={event => handleLabappManualCloseComments(event, "Comment")}
                                                                        //style={{ width: "100%", border: (!getComment) || (!ApprovalManualInfo[0].approvalManualCloseComment) ? '' : '1px solid red' }}
                                                                        style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseComment ? '' : '1px solid red' }}
                                                                    //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                    >

                                                                    </textarea>
                                                                    :
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].manualCloseComment}

                                                                        style={{ width: "100%", border: getComment && ApprovalManualInfo[0].approvalManualCloseComment === '' ? '1px solid red' : '' }}
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                    >

                                                                    </textarea>
                                                            }
                                                        </td>

                                                        <td >
                                                            {

                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <DatePicker className="form-control" name="HolidayDate1"
                                                                        id={
                                                                            "HolidayDate1"
                                                                        }
                                                                        selected={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                        onChange={event => handleLabappManualCloseComments(event, "CommentDate")}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        // showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        minDate={new Date()}
                                                                        maxDate={new Date()}
                                                                        customInput={<CustomInput />}

                                                                    //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                    />

                                                                    :
                                                                    <DatePicker className="form-control" name="StoryName"
                                                                        id={
                                                                            "StoryName"
                                                                        }
                                                                        selected={new Date(ApprovalManualInfo[0].manualCloseDate)}

                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        disabled
                                                                    // showYearDropdown

                                                                    //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                    />
                                                                // <input
                                                                //     placeholder="Enter the StoryName"
                                                                //     className="form-control"
                                                                //     title={ApprovalManualInfo[0].manualCloseDate}
                                                                //     name="StoryName"
                                                                //     id={"StoryName"}
                                                                //     value={ApprovalManualInfo[0].manualCloseDate}
                                                                //     disabled

                                                                // ></input>
                                                            }


                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                        onChange={event => (LapdipsubSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{item.itemDetails}</td>
                                                                                <td>{item.taskHolderFollowerNames}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                                                onChange={event => (LapdipsubOnSelectRecords(event, index))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity" />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                ApprovalManualInfo[0].approvalStatus !== "Completed" &&
                                                <Button variant="success" onClick={() => SaveChildlabappManulaCloseComments()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                            {


                                showApproval === true ?
                                    <Modal show={showApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowApproval(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {getheader} Lab Dip Approval
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?

                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                    {
                                                                        MultiCloseDatas.length !== 0 ?

                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Story</th>
                                                                                        <th>Theme</th>
                                                                                        <th>Details </th>
                                                                                        <th>Task Holder / Follower</th>
                                                                                        <th>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                    onChange={event => (LapdipAppSelectAll(event))}
                                                                                                    checked={selectall === 1 ? true : false}
                                                                                                    className="colored-blue clearOpacity" />
                                                                                                <span className="text"></span>
                                                                                            </label>
                                                                                        </th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {

                                                                                        MultiCloseDatas.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>{item.storyName}</td>
                                                                                                        <td>{item.themeName}</td>
                                                                                                        <td>{item.itemDetails}</td>
                                                                                                        <td>{item.taskHolderFollowerNames}</td>
                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                                        onChange={event => (LapdipAppOnSelectRecords(event, index))}
                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                    <span className="text"></span>
                                                                                                                </label>

                                                                                                            </div>

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                                    <i className="fa fa-check right"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showApprovaldiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="BuyerID">
                                                                                Buyer-Season-Brand

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={Labdipvalues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="SupplierID">
                                                                                Supplier
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={Labdipvalues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={Labdipvalues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={Labdipvalues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">



                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={Labdipvalues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={Labdipvalues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="Name">
                                                                                Submit Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                    id={
                                                                                        "SubmittedDate"
                                                                                    }
                                                                                    selected={
                                                                                        Labdipvalues[0].SubmittedDate
                                                                                    }
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    peekNextMonth
                                                                                    // customInput={<CustomInput />}
                                                                                    disabled
                                                                                />
                                                                                {/* <input
                                                                                    placeholder="SubmittedDate"
                                                                                    className="form-control"
                                                                                    name="SubmittedDate"
                                                                                    id={"SubmittedDate"}
                                                                                    value={Labdipvalues[0].SubmittedDate}
                                                                                    disabled

                                                                                ></input> */}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendTo"}>
                                                                                Approval Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    Labdipvalues[0].ApprovalStatus !== "Completed" ?

                                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                                            id={
                                                                                                "ApprovalDate"
                                                                                            }
                                                                                            selected={
                                                                                                Labdipvalues[0].ApprovalDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => LabApphandleChange(event, 'ApprovalDate')}
                                                                                            customInput={<CustomInputlabdipApprovalsubmit />}

                                                                                        />
                                                                                        // <input
                                                                                        //     id="ApprovalDate"
                                                                                        //     name="ApprovalDate"
                                                                                        //     value={Labdipvalues[0].ApprovalDate}
                                                                                        //     placeholder="DD/MM/YYYY"
                                                                                        //     type="date"
                                                                                        //     autoComplete="off"
                                                                                        //     className={"form-control"}
                                                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                                                        //     style={{
                                                                                        //         color:
                                                                                        //             Labdipvalues[0].ApprovalDate === ""
                                                                                        //                 ? "#bfdea1"
                                                                                        //                 : "#000",
                                                                                        //         border:
                                                                                        //             submitted &&
                                                                                        //                 Labdipvalues[0].ApprovalDate === ""
                                                                                        //                 ? "1px solid red"
                                                                                        //                 : "",
                                                                                        //     }}
                                                                                        //     onChange={event => LabApphandleChange(event, 'ApprovalDate')}
                                                                                        // />
                                                                                        :

                                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                                            id={
                                                                                                "ApprovalDate"
                                                                                            }
                                                                                            selected={
                                                                                                Labdipvalues[0].ApprovalDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                    // <input
                                                                                    //     placeholder="ApprovalDate"
                                                                                    //     className="form-control"
                                                                                    //     name="ApprovalDate"
                                                                                    //     id={"ApprovalDate"}
                                                                                    //     value={Labdipvalues[0].ApprovalDate}
                                                                                    //     disabled

                                                                                    // ></input>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder="Submit Sent Details"
                                                                                    className="form-control"
                                                                                    name="SubmitSentDetails"
                                                                                    id={
                                                                                        "SubmitSentDetails"
                                                                                    }
                                                                                    value={Labdipvalues[0].SubmitSentDetails}
                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row">

                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab2"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a


                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-10">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    {
                                                                                                        Labdipvalues[0].PantoneTypeName !== "Others" ?
                                                                                                            <>
                                                                                                                <label htmlFor="BuyerID">Pantone #</label>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input
                                                                                                                        placeholder="Pantone #"
                                                                                                                        className="form-control"

                                                                                                                        name="PantoneCodeandType"
                                                                                                                        id={
                                                                                                                            "PantoneCodeandType"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            Labdipvalues[0].PantoneTypeName + "-" + Labdipvalues[0].PantoneCode + "-" + Labdipvalues[0].PantoneNameOrSwatchName
                                                                                                                        }
                                                                                                                        disabled

                                                                                                                    ></input>
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <label htmlFor="SwatchName">Swatch Name</label>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input
                                                                                                                        placeholder="Swatch Name"
                                                                                                                        className="form-control"
                                                                                                                        title={Labdipvalues[0].PantoneNameOrSwatchName}
                                                                                                                        name="SwatchName"
                                                                                                                        id={
                                                                                                                            "SwatchName"
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            Labdipvalues[0].PantoneNameOrSwatchName
                                                                                                                        }
                                                                                                                        disabled

                                                                                                                    ></input>
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Ref.ColorName</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="Ref.ColorName"
                                                                                                            className="form-control"
                                                                                                            title={Labdipvalues[0].RefColorName}
                                                                                                            name="RefColorName"
                                                                                                            id={
                                                                                                                "RefColorName"
                                                                                                            }
                                                                                                            value={
                                                                                                                Labdipvalues[0].RefColorName
                                                                                                            }
                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    {
                                                                                                        Labdipvalues[0].PantoneTypeName !== "Others" ?
                                                                                                            <>
                                                                                                                <label htmlFor={"Quarter"}>Color/Image</label>
                                                                                                                {
                                                                                                                    Labdipvalues[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                                                        <div style={{ background: Labdipvalues[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                        :
                                                                                                                        <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                }
                                                                                                            </> :
                                                                                                            <>
                                                                                                                <img src={Labdipvalues[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + Labdipvalues[0].PantoneColorOrOrSwatchImage}
                                                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                />
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"FabricID"}>

                                                                                                        Fabric Details<span className="text-danger">*</span></label>

                                                                                                    <span className="input-icon icon-right">
                                                                                                        <select id={"FabricID"} name="FabricID" className="form-select"
                                                                                                            value={Labdipvalues[0].FabricID}
                                                                                                            disabled
                                                                                                        >
                                                                                                            <option value="0">-Select Fabric-</option>
                                                                                                            {
                                                                                                                FabricDetails.map(item => (
                                                                                                                    <option key={item.id} value={item.value}>
                                                                                                                        {item.label}
                                                                                                                    </option>
                                                                                                                ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-9 col-lg-9">
                                                                                                    <div className='form-group'>
                                                                                                        <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                                        <textarea maxLength="300" name="Remarks" value={Labdipvalues[0].Remarks}
                                                                                                            rows="4" style={{ width: "100%" }}
                                                                                                            disabled
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>


                                                                                        <div>
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Submission Card</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td> <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "700px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption && (
                                                                                                                            submittedoption.map((item, index) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <input
                                                                                                                                                        placeholder="Option Name"
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.optionName}
                                                                                                                                                        name="OptionName"
                                                                                                                                                        id={
                                                                                                                                                            "OptionName"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.optionName
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>


                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                                    <input
                                                                                                                                                        placeholder=""
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.supplierReferenceNo}
                                                                                                                                                        name="Sendto"
                                                                                                                                                        id={
                                                                                                                                                            "Sendto"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.supplierReferenceNo
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>
                                                                                                                                                </span>

                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>


                                                                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                                        id={
                                                                                                                                                            "TaskHolderOrFollower"
                                                                                                                                                        }
                                                                                                                                                        isLoading={false}
                                                                                                                                                        isClearable={true}
                                                                                                                                                        isSearchable={true}
                                                                                                                                                        onChange={event => handleLabAppSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                                        value={getApprovedOptions.filter(function (option) {
                                                                                                                                                            return option.value === item.approvedOptionName;
                                                                                                                                                        })}
                                                                                                                                                        options={getApprovedOptions}
                                                                                                                                                        styles={
                                                                                                                                                            submitted && item.approvedOptionName === '0' ? styles2 : styles1
                                                                                                                                                        }


                                                                                                                                                        menuPosition="fixed"
                                                                                                                                                        // disabled={Labdipvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                        isDisabled={Labdipvalues[0].ApprovalStatus === "Completed"}

                                                                                                                                                    ></Reactselect>
                                                                                                                                                </span>

                                                                                                                                            </td>

                                                                                                                                        </tr>
                                                                                                                                    </>

                                                                                                                                )
                                                                                                                            }))
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div></td>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={Labdipvalues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + Labdipvalues[0].SwatchCardIamgePath}

                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                {/* <div className="row">
                                                                                                                                        <div className="col-sm-12" align={"right"}>
                                                                                                                                            <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}>
                                                                                                                                                <i className="fa fa-plus"></i> &nbsp;Add
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                    </div> */}
                                                                                <br />
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                            {
                                                                                                getOrderinfo.length !== 0 ?

                                                                                                    <table className="table table-bordered">
                                                                                                        <TableHeader headers={headerss} />
                                                                                                        <tbody>
                                                                                                            {

                                                                                                                getOrderinfo.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <tr>
                                                                                                                                <td>{item.storyName}</td>
                                                                                                                                <td>{item.themeName}</td>
                                                                                                                                <td>{item.itemDetails}</td>
                                                                                                                                <td>{item.taskHolderFollowerNames}</td>

                                                                                                                                {/* <td className="Actionbutton fixed-column">
                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                         disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                                                                                                    checked={item.selectedDetail === 1 ? true : false}
                                                                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                                            </label>
                                                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                                                            <button
                                                                                                                                                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                                                                type="button"
                                                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                                                title="Delete"
                                                                                                                                                                                                onClick={() =>
                                                                                                                                                                                                    handleRemoveOrderInfo(index)
                                                                                                                                                                                                }
                                                                                                                                                                                            >
                                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                                            </button>


                                                                                                                                                                                        </div>

                                                                                                                                                                                    </td> */}
                                                                                                                            </tr>
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="mt-10">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="400" name="Remarks" value={Labdipvalues[0].ApprovalRemark}
                                                                                onChange={event => LabApphandleChange(event, 'Remarks')}
                                                                                rows="5" style={{ width: "100%" }}
                                                                                disabled={Labdipvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}
                                                                    disabled={Labdipvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                >
                                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </>
                                                    : ''
                                            }

                                        </Modal.Body>

                                    </Modal> : ''

                            }
                            {/* STRIKEOFFSUBMISSION */}
                            {


                                showSOManual === true ?
                                    <Modal show={showSOManual === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setShowSOManual(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Strike Off Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments <span className="text-danger">*</span></th>
                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style={{ width: "600px" }} >
                                                            <textarea
                                                                rows="3"
                                                                placeholder="Enter Comments here..."
                                                                autoFocus onFocus={""} autoComplete="off"
                                                                maxLength="500" name="EnterRemarks"
                                                                id={"EnterRemarks"}
                                                                value={StrikeoffManualMnfo[0].manualCloseComment}
                                                                onChange={event => handleChangeStrikeSubIsManualCloseComments(event, "Comment")}
                                                                style={{ width: "100%", border: getComment && StrikeoffManualMnfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                                            // disabled={subField.submitStatus === "Completed" ? true : false}
                                                            >

                                                            </textarea>
                                                        </td>
                                                        <td >
                                                            {

                                                                //  getheader !== "Completed" ?
                                                                <DatePicker className="form-control" name="CloseDate"
                                                                    id={
                                                                        "CloseDate"
                                                                    }
                                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                    selected={Date.parse(moment(StrikeoffManualMnfo[0].manualCloseDate, 'MM/DD/YYYY').toISOString())}
                                                                    onChange={event => handleChangeStrikeSubIsManualCloseComments(event, "CommentDate")}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    minDate={new Date()}
                                                                    maxDate={new Date()}
                                                                    customInput={<ManualStrikeCloseCustomInput />}
                                                                    //style={{ width: "100%", border: getComment && ManualInfo[0].manualCloseDate === '' ? '1px solid red' : '' }}
                                                                    disabled={getheader !== "Add" ? true : false}
                                                                />


                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"

                                                                        onChange={event => (StrikeoffSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity"
                                                                        disabled={getheader !== "Add" ? true : false}
                                                                    />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{
                                                                                    item.details}
                                                                                    {
                                                                                        item.strikeOffGarmentSize &&
                                                                                        <span onClick={() => handleStrikeoffStyleDetails(item.details, item.strikeOffGarmentSize)} className="text-primary"> {"Size Details"} </span>
                                                                                    }</td>
                                                                                <td>{item.followerName}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                onChange={event => (StrikeoffOnSelectRecords(event, index))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity"
                                                                                                disabled={getheader !== "Add" ? true : false}
                                                                                            />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                getheader === "Add" &&
                                                <Button variant="success" onClick={() => SaveChildStrikesubManulaCloseComments()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                            {


                                showstrikeoffSubmission === true ?
                                    <Modal show={showstrikeoffSubmission === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowStrikeoffSubmission(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {getheader} Strike Off Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?
                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer">
                                                                    {
                                                                        OrderStrikeoffInfoMulticloseMainDetails.length !== 0 ?

                                                                            <table className="table table-bordered">

                                                                                <tbody>
                                                                                    {
                                                                                        OrderStrikeoffInfoMulticloseMainDetails.map(comment => (
                                                                                            <Fragment>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-8">
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        expandStateMultiSelect[comment.strikeoffLogoPath] ?
                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                    }
                                                                                                                    onClick={() => handleStrikeExpandRowMultiSelect(comment.strikeoffLogoPath, MultiCloseDatas)}
                                                                                                                ></i>
                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    {
                                                                                                                        comment.strikeOffCode
                                                                                                                    }
                                                                                                                    -
                                                                                                                    {
                                                                                                                        comment.strikeOffName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <span className="Actionbutton">
                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                                <>
                                                                                                    {
                                                                                                        expandedRowsMultiSelect.includes(comment.strikeoffLogoPath) ?

                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Story</th>
                                                                                                                                <th>Theme</th>
                                                                                                                                <th>Details </th>
                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                <th>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (StrikeoffSelectAll(event, comment.strikeoffLogoPath))}
                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </th>

                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                                    <Fragment>

                                                                                                                                        <tr>
                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                            <td>{item.details}
                                                                                                                                                {
                                                                                                                                                    item.strikeOffGarmentSize &&
                                                                                                                                                    <span onClick={() => handleStrikeoffStyleDetails(item.details, item.strikeOffGarmentSize)} className="text-primary"> {"Size Details"} </span>
                                                                                                                                                }</td>
                                                                                                                                            <td>{item.followerName}</td>
                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (StrikeoffOnSelectRecords(event, item.index - 1))}
                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>

                                                                                                                                                </div>

                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </Fragment>
                                                                                                                                ))

                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr> : null
                                                                                                    }
                                                                                                </>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }






                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success" onClick={() => AddStrikeoffsubRows()}>

                                                                    <i className="fa fa-check right"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showSubmissiondiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].BuyerBrandSeasonName}
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={strikeoffvalues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="SupplierID" >Supplier</label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].SupplierName}
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={strikeoffvalues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                                                            <span className='input-icon icon-right'>
                                                                                {
                                                                                    strikeoffvalues[0].SubmitStatus !== "Completed" ?
                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                strikeoffvalues[0].SubmittedDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => handleChangeStrikeoffSubmissionMain(event, 'SubmittedDate')}
                                                                                            customInput={<CustomInputstrikeoffsubmit />}

                                                                                        />
                                                                                        // < input id="SubmittedDate" name="SubmittedDate"
                                                                                        //     value={strikeoffvalues.SubmittedDate} placeholder="DD/MM/YYYY"

                                                                                        //     type="date" autoComplete="off"
                                                                                        //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                        //     style={{
                                                                                        //         border: submittedpopup && strikeoffvalues[0].SubmittedDate === '' ? '1px solid red' : ''
                                                                                        //     }}
                                                                                        //     onChange={event => handleChangeStrikeoffSubmissionMain(event, 'SubmittedDate')}
                                                                                        // />
                                                                                        :
                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                strikeoffvalues[0].SubmittedDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                    // <input
                                                                                    //     placeholder="SubmittedDate"
                                                                                    //     className="form-control"
                                                                                    //     title={strikeoffvalues[0].SubmittedDate}
                                                                                    //     name="SubmittedDate"
                                                                                    //     id={"SubmittedDate"}
                                                                                    //     value={strikeoffvalues[0].SubmittedDate}
                                                                                    //     disabled

                                                                                    // ></input>
                                                                                }

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    getheader === 'View' ?
                                                                                        <span>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                title={strikeoffvalues[0].SendToEdit}
                                                                                                value={strikeoffvalues[0].SendToEdit} />
                                                                                        </span>
                                                                                        :
                                                                                        <span className=''>
                                                                                            <Reactselect
                                                                                                className="basic-single"
                                                                                                name={"Sku"}
                                                                                                id={
                                                                                                    "Sent To"
                                                                                                }
                                                                                                // value={item.MeasurementPoints}
                                                                                                //isDisabled={false}
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                options={getTaskholder}
                                                                                                value={strikeoffvalues[0].SendToEdit}
                                                                                                isMulti
                                                                                                onChange={event => Strkehandlemultidd(event, 'SendTo')}
                                                                                                menuPosition="fixed"
                                                                                                styles={submittedpopup && strikeoffvalues[0].SendTo === "" ? styles2s : styles1s}
                                                                                            // readOnly
                                                                                            // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                }

                                                                                {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                                                    value={strikeoffvalues[0].SendTo}
                                                                                    onChange={(event) => handleChangeStrikeoffSubmissionMain(event, 'SendTo')}
                                                                                    style={{ border: submittedpopup && strikeoffvalues[0].SendTo === "0" ? '1px solid red' : '' }}
                                                                                    disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                >
                                                                                    <option value="0">-Select SendTo-</option>
                                                                                    {
                                                                                        getTaskholder.map(item => (
                                                                                            <option key={item.id} value={item.value}>
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">


                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={strikeoffvalues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={strikeoffvalues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={strikeoffvalues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                                            <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                                                value={strikeoffvalues[0].SendThrough}
                                                                                onChange={event => handleChangeStrikeoffSubmissionMain(event, 'Sendthrough')}
                                                                                disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                style={{ border: submittedpopup && strikeoffvalues[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                                            >
                                                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Sendthrough- </option>
                                                                                <option value="1" key="1">Hand Carried</option>
                                                                                <option value="2" key="2">Courier - DHL</option>

                                                                            </select>


                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        showawb === true ?
                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="Awbdate" >AWB/Date</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder="AWB/Date"
                                                                                            className="form-control"
                                                                                            name="AWBDateandNo"
                                                                                            id={
                                                                                                "AWBDateandNo"
                                                                                            }
                                                                                            value={strikeoffvalues[0].AWBDateandNo}
                                                                                            //style={{ border: submittedpopup && strikeoffvalues[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                                            onClick={() => setShowStrikePopup(true)}
                                                                                            disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div> : ''
                                                                    }

                                                                </div>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab1"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a
                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div id="idSubmitInformation" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="BuyerID">Strike Off Code</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="StikeoffCode"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.StrikeoffCode}
                                                                                                            name="StrikeoffCode"
                                                                                                            id={
                                                                                                                "StrikeoffCode"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].StrikeoffCode
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Name</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder=" Name"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.StrikeoffName}
                                                                                                            name="StrikeoffName"
                                                                                                            id={
                                                                                                                "StrikeoffName"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].StrikeoffName
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Quarter"}>Quality</label>
                                                                                                    <span className="input-icon icon-right">

                                                                                                        <input
                                                                                                            placeholder="Quality"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.Quality}
                                                                                                            name="StrikeOffQuality"
                                                                                                            id={
                                                                                                                "StrikeOffQuality"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].Quality
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">

                                                                                                <label htmlFor={"Quarter"}>Strike Off Image</label>
                                                                                                <br />
                                                                                                <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                                                                {
                                                                                                    getSubImageList.length !== 0 ?
                                                                                                        <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                                        :
                                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                                            No Images Found
                                                                                                        </div>
                                                                                                }
                                                                                                <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>




                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">

                                                                                                    <label htmlFor={"StrikeoffDimensionId"}>Strike Off Dimension <span className="text-danger">*</span></label>
                                                                                                    <select id={"StrikeoffDimensionId"} name="StrikeoffDimensionId" className="form-select"
                                                                                                        value={strikeoffvalues[0].StrikeoffDimensionId}
                                                                                                        onChange={(event) => handleChangeStrikeoffSubmissionMain(event, 'StrikeoffDimensionId')}
                                                                                                        style={{ border: submittedpopup && strikeoffvalues[0].StrikeoffDimensionId === "0" ? '1px solid red' : '' }}
                                                                                                        disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                    >
                                                                                                        <option value="0">-Select Strike Off-</option>
                                                                                                        {
                                                                                                            StrikeoffDimension.map(item => (
                                                                                                                <option key={item.id} value={item.value}>
                                                                                                                    {item.label}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }

                                                                                                    </select>




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Swatch Card (Image Upload)</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "500px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th >Action</th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption.map((item, index) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span className="input-icon icon-right">
                                                                                                                                                <input
                                                                                                                                                    placeholder=""
                                                                                                                                                    className="form-control cut_text"
                                                                                                                                                    title={item.OptionName}
                                                                                                                                                    name="OptionName"
                                                                                                                                                    id={
                                                                                                                                                        "OptionName"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.OptionName
                                                                                                                                                    }
                                                                                                                                                    style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                                                    onChange={event => handleStrikeSubmitoptionchange(event, 'OptionName', index)}
                                                                                                                                                    disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>



                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                <input
                                                                                                                                                    placeholder=""
                                                                                                                                                    className="form-control cut_text"
                                                                                                                                                    title={item.SupplierReferenceNo}
                                                                                                                                                    name="SupplierReferenceNo"
                                                                                                                                                    id={
                                                                                                                                                        "SupplierReferenceNo"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.SupplierReferenceNo
                                                                                                                                                    }
                                                                                                                                                    style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                                                                                                                    onChange={event => handleStrikeSubmitoptionchange(event, 'SupplierReferenceNo', index)}
                                                                                                                                                    disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>
                                                                                                                                            </span>

                                                                                                                                        </td>
                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                            {" "}
                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                {submittedoption.length !== 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                                                        title="Delete Strike Off"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleRemoveFields(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                                &nbsp;
                                                                                                                                                {submittedoption.length === index + 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                                                        title="Add Strike Off"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleAddField(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={strikeoffvalues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + strikeoffvalues[0].SwatchCardIamgePath}
                                                                                                                // src={"assets/img/emptyImage.jpg"}
                                                                                                                onClick={openFileUpload}
                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadstrikeImages(event)} value=''
                                                                                                                disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                            />
                                                                                                            {
                                                                                                                strikeoffvalues.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                                                    <div style={
                                                                                                                        {
                                                                                                                            marginTop: '8px',
                                                                                                                            float: 'right',
                                                                                                                            bottom: '10px',
                                                                                                                            position: 'relative',
                                                                                                                        }
                                                                                                                    }>

                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="idOrderInformation" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12" align={"right"}>
                                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                            disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}>
                                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer">
                                                                                            {
                                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                                    <table className="table table-bordered">

                                                                                                        <tbody>

                                                                                                            {
                                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                                    <Fragment>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-lg-8">
                                                                                                                                        <i
                                                                                                                                            className={
                                                                                                                                                expandState[comment.strikeoffLogoPath] ?
                                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                            }
                                                                                                                                            onClick={() => handleExpandRow(comment.strikeoffLogoPath, getOrderinfo)}
                                                                                                                                        ></i>
                                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                                            {
                                                                                                                                                comment.strikeOffCode
                                                                                                                                            }
                                                                                                                                            -
                                                                                                                                            {
                                                                                                                                                comment.strikeOffName
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-lg-4">
                                                                                                                                        <span className="Actionbutton">
                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                                        </span>
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                expandedRows.includes(comment.strikeoffLogoPath) ?
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Story</th>
                                                                                                                                                        <th>Theme</th>
                                                                                                                                                        <th>Details </th>
                                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                                        <th>Action </th>
                                                                                                                                                    </tr>
                                                                                                                                                </thead>

                                                                                                                                                <tbody>
                                                                                                                                                    {
                                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                                            <Fragment>

                                                                                                                                                                <tr>
                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                                                        {" "}
                                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                                    onChange={event => (OnChangeStrikeoffOrderinfo(event, item.index - 1))}
                                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                                    disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false} />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                                            <button
                                                                                                                                                                                type="button"
                                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                                title="Delete Strike Off"
                                                                                                                                                                                onClick={() =>
                                                                                                                                                                                    handleRemoveOrderinfo(index)
                                                                                                                                                                                }
                                                                                                                                                                                disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                                            >
                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                            </button>

                                                                                                                                                                        </div>
                                                                                                                                                                    </td>

                                                                                                                                                                </tr>

                                                                                                                                                            </Fragment>
                                                                                                                                                        ))

                                                                                                                                                    }

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                        </td>
                                                                                                                                    </tr> : null
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    </Fragment>
                                                                                                                ))
                                                                                                            }


                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>


                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-sm-8 col-lg-8">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="300" name="Remarks" value={strikeoffvalues[0].Remarks}
                                                                                onChange={event => handleChangeStrikeoffSubmissionMain(event, 'Remarks')}
                                                                                rows="4" style={{ width: "100%" }}
                                                                                disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveStrikeoffSubmission()}
                                                                    disabled={strikeoffvalues[0].SubmitStatus === "Completed" ? true : false}>

                                                                    <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {
                                                            ShowStrikePopup === true ?
                                                                <Modal show={ShowStrikePopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowStrikePopup(false)}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            AWB and Date
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                                                    <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                                                    {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>

                                                                                <tr>
                                                                                    <td>
                                                                                        <span className='input-icon icon-right'>
                                                                                            <input type="text"
                                                                                                placeholder="AWB Name"
                                                                                                id={"AWBName"}
                                                                                                name="AWBName"
                                                                                                value={strikeoffvalues[0].AWBName}
                                                                                                style={{ border: submittedpopup && !strikeoffvalues[0].AWBName ? '1px solid red' : '' }}
                                                                                                autoFocus
                                                                                                maxLength="25"
                                                                                                className={'form-control'}
                                                                                                onChange={event => handleStrikeoffpopupchange(event, 'AWBName')}
                                                                                                menuPosition="fixed"
                                                                                            // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input id={"AWBDate"} name="AWBDate"
                                                                                                value={strikeoffvalues[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                className={'form-control'}
                                                                                                style={{ border: submittedpopup && strikeoffvalues[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                                onChange={event => handleStrikeoffpopupchange(event, 'AWBDate')}
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="success" onClick={() => SaveStrikeoffAwbnoDate()}>
                                                                            <i className="fa fa-check right"></i>&nbsp;
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                : ''
                                                        }

                                                    </>
                                                    : ''


                                            }
                                            {
                                                //View Size
                                                showSizePopup.isPopup === true ?
                                                    <Modal show={showSizePopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleSizePopupClose()} >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                View Size
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="col-md-12">

                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Detail</th>
                                                                            <th>Size Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            getSizeList && getSizeList.map((inputField) => (
                                                                                <Fragment>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span>
                                                                                                {showSizePopup.detail}

                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>
                                                                                                {inputField.label}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </Fragment>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                                                <Button variant="success" onClick={() => handleSizePopupClose()}>
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal> : ''
                                            }
                                        </Modal.Body>

                                    </Modal> : ''

                            }
                            {/* STRIKEOFFAPPROVAL */}
                            {


                                getStrikeOffAppManual === true ?
                                    <Modal show={getStrikeOffAppManual === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopups(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Strike Off Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments <span className="text-danger">*</span></th>
                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style={{ width: "600px" }} >
                                                            {
                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].approvalManualCloseComment}
                                                                        onChange={event => handleChangeIsStrikeappManualCloseComments(event, "Comment")}
                                                                        style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseComment ? '' : '1px solid red' }}
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                    >

                                                                    </textarea> :
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].manualCloseComment}

                                                                        style={{ width: "100%", border: getComment && ApprovalManualInfo[0].approvalManualCloseComment === '' ? '1px solid red' : '' }}
                                                                        disabled
                                                                    >

                                                                    </textarea>

                                                            }
                                                        </td>
                                                        <td >
                                                            {

                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <DatePicker className="form-control" name="HolidayDate1"

                                                                        selected={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                        onChange={event => handleChangeIsStrikeappManualCloseComments(event, "CommentDate")}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        // showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        minDate={new Date()}
                                                                        maxDate={new Date()}
                                                                        customInput={<CustomInput />}
                                                                        //style={{ width: "100%", border: getComment && ApprovalManualInfo[0].approvalManualCloseDate === '' ? '1px solid red' : '' }}
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                    />

                                                                    :

                                                                    <DatePicker className="form-control" name="StoryName"
                                                                        id={
                                                                            "StoryName"
                                                                        }
                                                                        selected={new Date(ApprovalManualInfo[0].manualCloseDate)}

                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        disabled
                                                                    // showYearDropdown

                                                                    //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                    />
                                                                // <input
                                                                //     placeholder="Enter the StoryName"
                                                                //     className="form-control"
                                                                //     title={ApprovalManualInfo[0].manualCloseDate}
                                                                //     name="StoryName"
                                                                //     id={"StoryName"}
                                                                //     value={ApprovalManualInfo[0].manualCloseDate}
                                                                //     disabled

                                                                // ></input>
                                                            }


                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                        onChange={event => (StrikeoffSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{item.details}</td>
                                                                                <td>{item.followerName}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                                                onChange={event => (StrikeoffOnSelectRecords(event))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity" />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                ApprovalManualInfo[0].approvalStatus !== "Completed" &&
                                                <Button variant="success" onClick={() => SaveChildManulaCloseCommentss()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                            {
                                showstrikeoffShowApproval === true ?
                                    <Modal show={showstrikeoffShowApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setstrikeoffshowApproval(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {getheader} Strikeoff Approval
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?

                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer">
                                                                    {
                                                                        OrderStrikeoffInfoMulticloseMainDetails.length !== 0 ?

                                                                            <table className="table table-bordered">

                                                                                <tbody>
                                                                                    {
                                                                                        OrderStrikeoffInfoMulticloseMainDetails.map(comment => (
                                                                                            <Fragment>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-8">
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        expandStateMultiSelect[comment.strikeoffLogoPath] ?
                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                    }
                                                                                                                    onClick={() => handleStrikeExpandRowMultiSelect(comment.strikeoffLogoPath, MultiCloseDatas)}
                                                                                                                ></i>
                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    {
                                                                                                                        comment.strikeOffCode
                                                                                                                    }
                                                                                                                    -
                                                                                                                    {
                                                                                                                        comment.strikeOffName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <span className="Actionbutton">
                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                                <>
                                                                                                    {
                                                                                                        expandedRowsMultiSelect.includes(comment.strikeoffLogoPath) ?

                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Story</th>
                                                                                                                                <th>Theme</th>
                                                                                                                                <th>Details </th>
                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                <th>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (StrikeoffSelectAll(event, comment.strikeoffLogoPath))}
                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </th>

                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                                    <Fragment>

                                                                                                                                        <tr>
                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                            <td>{item.details}</td>
                                                                                                                                            <td>{item.followerName}</td>
                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (StrikeoffOnSelectRecords(event, item.index - 1))}
                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>

                                                                                                                                                </div>

                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </Fragment>
                                                                                                                                ))

                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr> : null
                                                                                                    }
                                                                                                </>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }






                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        {/* <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                    <i className="fa fa-check right"></i> Add
                                                </button>
                                            </div>
                                        </div> */}
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showApprovaldiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="BuyerID">
                                                                                Buyer-Season-Brand

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={strikeoffvalues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="SupplierID">
                                                                                Supplier
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={strikeoffvalues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="Name">
                                                                                Submit Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {/* <input
                                                                                    placeholder="SubmittedDate"
                                                                                    className="form-control"
                                                                                    name="SubmittedDate"
                                                                                    id={"SubmittedDate"}
                                                                                    value={strikeoffvalues[0].SubmittedDate}
                                                                                    disabled

                                                                                ></input> */}
                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                    id={
                                                                                        "SubmittedDate"
                                                                                    }
                                                                                    selected={
                                                                                        strikeoffvalues[0].SubmittedDate
                                                                                    }
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    peekNextMonth
                                                                                    // customInput={<CustomInput />}
                                                                                    disabled
                                                                                />

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendTo"}>
                                                                                Approved Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    strikeoffvalues[0].ApprovalStatus !== "Completed" ?


                                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                                            id={
                                                                                                "ApprovalDate"
                                                                                            }
                                                                                            selected={
                                                                                                strikeoffvalues[0].ApprovalDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => StrikeoffApphandleChange(event, 'ApprovalDate')}
                                                                                            customInput={<CustomInputstrikeoffApprovalsubmit />}

                                                                                        />
                                                                                        // <input
                                                                                        //     id="ApprovalDate"
                                                                                        //     name="ApprovalDate"
                                                                                        //     value={strikeoffvalues[0].ApprovalDate}
                                                                                        //     placeholder="DD/MM/YYYY"
                                                                                        //     type="date"
                                                                                        //     autoComplete="off"
                                                                                        //     className={"form-control"}
                                                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                                                        //     style={{
                                                                                        //         color:
                                                                                        //             strikeoffvalues[0].ApprovalDate === ""
                                                                                        //                 ? "#bfdea1"
                                                                                        //                 : "#000",
                                                                                        //         border:
                                                                                        //             submitted &&
                                                                                        //                 strikeoffvalues[0].ApprovalDate === ""
                                                                                        //                 ? "1px solid red"
                                                                                        //                 : "",
                                                                                        //     }}
                                                                                        //     onChange={event => StrikeoffApphandleChange(event, 'ApprovalDate')}
                                                                                        // />
                                                                                        :
                                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                                            id={
                                                                                                "ApprovalDate"
                                                                                            }
                                                                                            selected={
                                                                                                strikeoffvalues[0].ApprovalDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                    // <input
                                                                                    //     placeholder="ApprovalDate"
                                                                                    //     className="form-control"
                                                                                    //     name="ApprovalDate"
                                                                                    //     id={"ApprovalDate"}
                                                                                    //     value={strikeoffvalues[0].ApprovalDate}
                                                                                    //     disabled

                                                                                    // ></input>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">


                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={strikeoffvalues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={strikeoffvalues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={strikeoffvalues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={strikeoffvalues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder="Submit Sent Details"
                                                                                    className="form-control"
                                                                                    name="SubmitSentDetails"
                                                                                    id={
                                                                                        "SubmitSentDetails"
                                                                                    }
                                                                                    value={strikeoffvalues[0].SubmitSentDetails}
                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab2"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a


                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="BuyerID">Strike Off Code</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="StikeoffCode"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.StrikeoffCode}
                                                                                                            name="StrikeoffCode"
                                                                                                            id={
                                                                                                                "StrikeoffCode"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].StrikeoffCode
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Name</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder=" Name"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.StrikeoffName}
                                                                                                            name="StrikeoffName"
                                                                                                            id={
                                                                                                                "StrikeoffName"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].StrikeoffName
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Quarter"}>Quality</label>
                                                                                                    <span className="input-icon icon-right">

                                                                                                        <input
                                                                                                            placeholder="Quality"
                                                                                                            className="form-control cut_text"
                                                                                                            title={strikeoffvalues.Quality}
                                                                                                            name="StrikeOffQuality"
                                                                                                            id={
                                                                                                                "StrikeOffQuality"
                                                                                                            }
                                                                                                            value={
                                                                                                                strikeoffvalues[0].Quality
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">

                                                                                                <label htmlFor={"Quarter"}>Strike Off Image</label>
                                                                                                <br />
                                                                                                <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                                                                {
                                                                                                    getSubImageList.length !== 0 ?
                                                                                                        <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                                        :
                                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                                            No Images Found
                                                                                                        </div>
                                                                                                }
                                                                                                <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>




                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">

                                                                                                    <label htmlFor={"StrikeoffDimensionId"}>Strike Off Dimension <span className="text-danger">*</span></label>
                                                                                                    <select id={"StrikeoffDimensionId"} name="StrikeoffDimensionId" className="form-select"
                                                                                                        value={strikeoffvalues[0].StrikeoffDimensionId}
                                                                                                        // onChange={(event) => handleTrimChangeSubmissionMain(event, 'StrikeoffDimensionId')}
                                                                                                        style={{ border: submittedpopup && strikeoffvalues[0].StrikeoffDimensionId === "0" ? '1px solid red' : '' }}
                                                                                                        disabled
                                                                                                    >
                                                                                                        <option value="0">-Select Strike Off-</option>
                                                                                                        {
                                                                                                            StrikeoffDimension.map(item => (
                                                                                                                <option key={item.id} value={item.value}>
                                                                                                                    {item.label}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }

                                                                                                    </select>




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-9 col-lg-9">
                                                                                                    <div className='form-group'>
                                                                                                        <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                                        <textarea maxLength="300" name="Remarks" value={strikeoffvalues[0].Remarks}
                                                                                                            rows="5" style={{ width: "100%" }}
                                                                                                            disabled
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="row">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Submission Card</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td> <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "700px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption && (
                                                                                                                            submittedoption.map((item, index) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <input
                                                                                                                                                        placeholder="Option Name"
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.optionName}
                                                                                                                                                        name="OptionName"
                                                                                                                                                        id={
                                                                                                                                                            "OptionName"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.optionName
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>


                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                                    <input
                                                                                                                                                        placeholder=""
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.supplierReferenceNo}
                                                                                                                                                        name="Sendto"
                                                                                                                                                        id={
                                                                                                                                                            "Sendto"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.supplierReferenceNo
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>
                                                                                                                                                </span>

                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>


                                                                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                                        id={
                                                                                                                                                            "TaskHolderOrFollower"
                                                                                                                                                        }
                                                                                                                                                        isLoading={false}
                                                                                                                                                        isClearable={true}
                                                                                                                                                        isSearchable={true}
                                                                                                                                                        onChange={event => handleStrikeoffAppSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                                        value={getApprovedOptions.filter(function (option) {
                                                                                                                                                            return option.value === item.approvedOptionName;
                                                                                                                                                        })}
                                                                                                                                                        options={getApprovedOptions}
                                                                                                                                                        styles={
                                                                                                                                                            submitted && item.approvedOptionName === '0' ? styles2 : styles1
                                                                                                                                                        }


                                                                                                                                                        menuPosition="fixed"
                                                                                                                                                        // disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                        isDisabled={strikeoffvalues[0].ApprovalStatus === "Completed"}

                                                                                                                                                    ></Reactselect>
                                                                                                                                                </span>

                                                                                                                                            </td>

                                                                                                                                        </tr>
                                                                                                                                    </>

                                                                                                                                )
                                                                                                                            }))
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div></td>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={strikeoffvalues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + strikeoffvalues[0].SwatchCardIamgePath}

                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                {/* <div className="row">
                                                                    <div className="col-sm-12" align={"right"}>
                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                            disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false}>
                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <br /> */}
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer">
                                                                                            {
                                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                                    <table className="table table-bordered">

                                                                                                        <tbody>

                                                                                                            {
                                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                                    <Fragment>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-lg-8">
                                                                                                                                        <i
                                                                                                                                            className={
                                                                                                                                                expandState[comment.strikeoffLogoPath] ?
                                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                            }
                                                                                                                                            onClick={() => handleExpandRow(comment.strikeoffLogoPath, getOrderinfo)}
                                                                                                                                        ></i>
                                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                                            {
                                                                                                                                                comment.strikeOffCode
                                                                                                                                            }
                                                                                                                                            -
                                                                                                                                            {
                                                                                                                                                comment.strikeOffName
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-lg-4">
                                                                                                                                        <span className="Actionbutton">
                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeoffLogoPath} height="50px" width="50px"></img>
                                                                                                                                        </span>
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                expandedRows.includes(comment.strikeoffLogoPath) ?
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Story</th>
                                                                                                                                                        <th>Theme</th>
                                                                                                                                                        <th>Details </th>
                                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                                    </tr>
                                                                                                                                                </thead>

                                                                                                                                                <tbody>
                                                                                                                                                    {
                                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                                            <Fragment>

                                                                                                                                                                <tr>
                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                                    {/* <td className="Actionbutton fixed-column">
                                                                                                                                                        {" "}
                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, item.index - 1))}
                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                    disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false} />
                                                                                                                                                                <span className="text"></span>
                                                                                                                                                            </label>
                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                            <button
                                                                                                                                                                type="button"
                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                title="Delete Strike Off"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleRemoveOrderinfo(index)
                                                                                                                                                                }
                                                                                                                                                                disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>

                                                                                                                                                        </div>
                                                                                                                                                    </td> */}

                                                                                                                                                                </tr>

                                                                                                                                                            </Fragment>
                                                                                                                                                        ))

                                                                                                                                                    }

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                        </td>
                                                                                                                                    </tr> : null
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    </Fragment>
                                                                                                                ))
                                                                                                            }


                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>


                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="300" name="Remarks" value={strikeoffvalues[0].ApprovalRemark}
                                                                                onChange={event => StrikeoffApphandleChange(event, 'Remarks')}
                                                                                rows="4" style={{ width: "100%" }}
                                                                                disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveStrikeoffsApproval()}
                                                                    disabled={strikeoffvalues[0].ApprovalStatus === "Completed" ? true : false}
                                                                >
                                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </>
                                                    : ''
                                            }

                                        </Modal.Body>

                                    </Modal> : ''

                            }
                            {/* TRIMSUBMISSION */}
                            {


                                getsetShowTrimsub === true ?
                                    <Modal show={getsetShowTrimsub === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualTrimPopup(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Trim Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments <span className="text-danger">*</span></th>
                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style={{ width: "600px" }} >
                                                            <textarea
                                                                rows="3"
                                                                placeholder="Enter Comments here..."
                                                                autoFocus onFocus={""} autoComplete="off"
                                                                maxLength="500" name="EnterRemarks"
                                                                id={"EnterRemarks"}
                                                                value={trimManualInfo && trimManualInfo[0].manualCloseComment}
                                                                onChange={event => handleChangeIsTrimsubManualCloseComments(event, "Comment")}
                                                                style={{ width: "100%", border: getComment && trimManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                                            // disabled={ManualInfo[0].submitStatus === "Completed" ? true : false}
                                                            >

                                                            </textarea>
                                                        </td>
                                                        <td >
                                                            {

                                                                //  getheader !== "Completed" ?
                                                                <DatePicker className="form-control" name="CloseDate"
                                                                    id={
                                                                        "CloseDate"
                                                                    }
                                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                    selected={Date.parse(moment(trimManualInfo && trimManualInfo[0].manualCloseDate, 'MM/DD/YYYY').toISOString())}
                                                                    onChange={event => handleChangeIsTrimsubManualCloseComments(event, "CommentDate")}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    minDate={new Date()}
                                                                    maxDate={new Date()}
                                                                    customInput={<ManualTrimCloseCustomInput />}
                                                                    disabled={getheader !== "Add" ? true : false}
                                                                />


                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"

                                                                        onChange={event => (TrimSubSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity"
                                                                        disabled={getheader !== "Add" ? true : false}
                                                                    />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{item.details}</td>
                                                                                <td>{item.followerName}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                onChange={event => (TrimSubOnSelectRecords(event, index))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity"
                                                                                                disabled={getheader !== "Add" ? true : false}
                                                                                            />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                getheader === "Add" &&
                                                <Button variant="success" onClick={() => SaveChildTrimManulaCloseComments()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                            {


                                showtrimSubmission === true ?
                                    <Modal show={showtrimSubmission === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowTrimSubmission(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {getheader} Trim Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?
                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer">
                                                                    {
                                                                        OrderTrimInfoMulticloseMainDetails.length !== 0 ?

                                                                            <table className="table table-bordered">

                                                                                <tbody>
                                                                                    {
                                                                                        OrderTrimInfoMulticloseMainDetails.map(comment => (
                                                                                            <Fragment>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-8">
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        expandStateMultiSelect[comment.trimLogoPath] ?
                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                    }
                                                                                                                    onClick={() => handleTrimExpandRowMultiSelect(comment.trimLogoPath, MultiCloseDatas)}
                                                                                                                ></i>
                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    {
                                                                                                                        comment.trimCode
                                                                                                                    }
                                                                                                                    -
                                                                                                                    {
                                                                                                                        comment.trimName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <span className="Actionbutton">
                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                                <>
                                                                                                    {
                                                                                                        expandedRowsMultiSelect.includes(comment.trimLogoPath) ?

                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Story</th>
                                                                                                                                <th>Theme</th>
                                                                                                                                <th>Details </th>
                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                <th>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (TrimSubSelectAll(event, comment.trimLogoPath))}
                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </th>

                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                                    <Fragment>

                                                                                                                                        <tr>
                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                            <td>{item.details}</td>
                                                                                                                                            <td>{item.followerName}</td>
                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (TrimSubOnSelectRecords(event, item.index - 1))}
                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>

                                                                                                                                                </div>

                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </Fragment>
                                                                                                                                ))

                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr> : null
                                                                                                    }
                                                                                                </>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }






                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                                    <i className="fa fa-check right"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showSubmissiondiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="BuyerID">Buyer-Season-Brand</label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].BuyerBrandSeasonName}
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={TrimValues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="SupplierID" >Supplier</label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].SupplierName}
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={TrimValues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Name" >Submit Date<span className="text-danger">*</span></label>
                                                                            <span className='input-icon icon-right'>
                                                                                {
                                                                                    TrimValues[0].SubmitStatus !== "Completed" ?

                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                TrimValues[0].SubmittedDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => handleTrimChangeSubmissionMain(event, 'SubmittedDate')}
                                                                                            customInput={<CustomInputtrimsubmit />}

                                                                                        />
                                                                                        // < input id="SubmittedDate" name="SubmittedDate"
                                                                                        //     value={TrimValues.SubmittedDate} placeholder="DD/MM/YYYY"

                                                                                        //     type="date" autoComplete="off"
                                                                                        //     className={'form-control'} onKeyDown={(e) => e.preventDefault()}
                                                                                        //     style={{
                                                                                        //         border: submittedpopup && TrimValues[0].SubmittedDate === '' ? '1px solid red' : ''
                                                                                        //     }}
                                                                                        //     onChange={event => handleTrimChangeSubmissionMain(event, 'SubmittedDate')}
                                                                                        // />
                                                                                        :

                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                TrimValues[0].SubmittedDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                    // <input
                                                                                    //     placeholder="SubmittedDate"
                                                                                    //     className="form-control"
                                                                                    //     title={TrimValues[0].SubmittedDate}
                                                                                    //     name="SubmittedDate"
                                                                                    //     id={"SubmittedDate"}
                                                                                    //     value={TrimValues[0].SubmittedDate}
                                                                                    //     disabled

                                                                                    // ></input>
                                                                                }

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                                            <span className="input-icon icon-right">

                                                                                {
                                                                                    getheader === 'View' ?
                                                                                        <span>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                title={TrimValues[0].SendToEdit}
                                                                                                value={TrimValues[0].SendToEdit} />
                                                                                        </span>
                                                                                        :
                                                                                        <span className=''>
                                                                                            <Reactselect
                                                                                                className="basic-single"
                                                                                                name={"Sku"}
                                                                                                id={
                                                                                                    "Sent To"
                                                                                                }
                                                                                                // value={item.MeasurementPoints}
                                                                                                //isDisabled={false}
                                                                                                isLoading={false}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                options={getTaskholder}
                                                                                                value={TrimValues[0].SendToEdit}
                                                                                                isMulti
                                                                                                onChange={event => trimhandlemultidd(event, 'SendTo')}
                                                                                                menuPosition="fixed"
                                                                                                styles={submittedpopup && TrimValues[0].SendTo === "" ? styles2s : styles1s}
                                                                                            // readOnly
                                                                                            // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                }
                                                                                {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                                                    value={TrimValues[0].SendTo}
                                                                                    onChange={(event) => handleTrimChangeSubmissionMain(event, 'SendTo')}
                                                                                    style={{ border: submittedpopup && TrimValues[0].SendTo === "0" ? '1px solid red' : '' }}
                                                                                    disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                >
                                                                                    <option value="0">-Select SendTo-</option>
                                                                                    {
                                                                                        getTaskholder.map(item => (
                                                                                            <option key={item.id} value={item.value}>
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">


                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={TrimValues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={TrimValues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={TrimValues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                                            <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                                                value={TrimValues[0].SendThrough}
                                                                                onChange={event => handleTrimChangeSubmissionMain(event, 'Sendthrough')}
                                                                                disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                style={{ border: submittedpopup && TrimValues[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                                            >
                                                                                <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Sendthrough- </option>
                                                                                <option value="1" key="1">Hand Carried</option>
                                                                                <option value="2" key="2">Courier - DHL</option>

                                                                            </select>


                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        showawb === true ?
                                                                            <div className="col-sm-3">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor="Awbdate" >AWB/Date</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <input
                                                                                            placeholder="AWB/Date"
                                                                                            className="form-control"
                                                                                            name="AWBDateandNo"
                                                                                            id={
                                                                                                "AWBDateandNo"
                                                                                            }
                                                                                            value={TrimValues[0].AWBDateandNo}
                                                                                            //style={{ border: submittedpopup && TrimValues[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                                            onClick={() => setShowTrimPopup(true)}
                                                                                            disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                        ></input>
                                                                                    </span>
                                                                                </div>
                                                                            </div> : ''
                                                                    }

                                                                </div>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab1"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a
                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div id="idSubmitInformation" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="BuyerID">Trim Code</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="StikeoffCode"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TrimCode}
                                                                                                            name="TrimCode"
                                                                                                            id={
                                                                                                                "TrimCode"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TrimCode
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Name</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder=" Name"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TrimName}
                                                                                                            name="TrimName"
                                                                                                            id={
                                                                                                                "TrimName"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TrimName
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                                                    <span className="input-icon icon-right">

                                                                                                        <input
                                                                                                            placeholder="Quality"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TechnicalSpecification}
                                                                                                            name="TrimQuality"
                                                                                                            id={
                                                                                                                "TrimQuality"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TechnicalSpecification
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">

                                                                                                <label htmlFor={"Quarter"}>Trim Image</label>
                                                                                                <br />
                                                                                                <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                                                                {
                                                                                                    getSubImageList.length !== 0 ?
                                                                                                        <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                                        :
                                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                                            No Images Found
                                                                                                        </div>
                                                                                                }
                                                                                                <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>




                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">

                                                                                                    <label htmlFor={"TrimDimensionId"}>Trim Dimension <span className="text-danger">*</span></label>
                                                                                                    <select id={"TrimDimensionId"} name="TrimDimensionId" className="form-select"
                                                                                                        value={TrimValues[0].TrimDimensionId}
                                                                                                        onChange={(event) => handleTrimChangeSubmissionMain(event, 'TrimDimensionId')}
                                                                                                        style={{ border: submittedpopup && TrimValues[0].TrimDimensionId === "0" ? '1px solid red' : '' }}
                                                                                                        disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                    >
                                                                                                        <option value="0">-Select Trim-</option>
                                                                                                        {
                                                                                                            TrimDimension.map(item => (
                                                                                                                <option key={item.id} value={item.value}>
                                                                                                                    {item.label}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }

                                                                                                    </select>




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Swatch Card (Image Upload)</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "500px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th >Action</th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption.map((item, index) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span className="input-icon icon-right">
                                                                                                                                                <input
                                                                                                                                                    placeholder=""
                                                                                                                                                    className="form-control cut_text"
                                                                                                                                                    title={item.OptionName}
                                                                                                                                                    name="OptionName"
                                                                                                                                                    id={
                                                                                                                                                        "OptionName"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.OptionName
                                                                                                                                                    }
                                                                                                                                                    style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                                                    onChange={event => handleTrimSubSubmitoptionchange(event, 'OptionName', index)}
                                                                                                                                                    disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>



                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                <input
                                                                                                                                                    placeholder=""
                                                                                                                                                    className="form-control cut_text"
                                                                                                                                                    title={item.SupplierReferenceNo}
                                                                                                                                                    name="SupplierReferenceNo"
                                                                                                                                                    id={
                                                                                                                                                        "SupplierReferenceNo"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        item.SupplierReferenceNo
                                                                                                                                                    }
                                                                                                                                                    style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                                                                                                                    onChange={event => handleTrimSubSubmitoptionchange(event, 'SupplierReferenceNo', index)}
                                                                                                                                                    disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                ></input>
                                                                                                                                            </span>

                                                                                                                                        </td>
                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                            {" "}
                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                {submittedoption.length !== 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                                                        title="Delete Trim"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleRemoveFields(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                                &nbsp;
                                                                                                                                                {submittedoption.length === index + 1 && (
                                                                                                                                                    <button
                                                                                                                                                        type="button"
                                                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                                                        title="Add Trim"
                                                                                                                                                        onClick={() =>
                                                                                                                                                            handleAddField(index)
                                                                                                                                                        }
                                                                                                                                                        disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                    >
                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                    </button>
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={TrimValues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + TrimValues[0].SwatchCardIamgePath}
                                                                                                                // src={"assets/img/emptyImage.jpg"}
                                                                                                                onClick={openFileUpload}
                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                                            <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadTrimImages(event)} value=''
                                                                                                                disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                                                            />
                                                                                                            {
                                                                                                                TrimValues.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                                                    <div style={
                                                                                                                        {
                                                                                                                            marginTop: '8px',
                                                                                                                            float: 'right',
                                                                                                                            bottom: '10px',
                                                                                                                            position: 'relative',
                                                                                                                        }
                                                                                                                    }>

                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/SwatchImages/' + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="idOrderInformation" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12" align={"right"}>
                                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                            disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}>
                                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer">
                                                                                            {
                                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                                    <table className="table table-bordered">

                                                                                                        <tbody>

                                                                                                            {
                                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                                    <Fragment>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-lg-8">
                                                                                                                                        <i
                                                                                                                                            className={
                                                                                                                                                expandState[comment.trimLogoPath] ?
                                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                            }
                                                                                                                                            onClick={() => handleTrimExpandRow(comment.trimLogoPath, getOrderinfo)}
                                                                                                                                        ></i>
                                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                                            {
                                                                                                                                                comment.trimCode
                                                                                                                                            }
                                                                                                                                            -
                                                                                                                                            {
                                                                                                                                                comment.trimName
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-lg-4">
                                                                                                                                        <span className="Actionbutton">
                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                                        </span>
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                expandedRows.includes(comment.trimLogoPath) ?
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Story</th>
                                                                                                                                                        <th>Theme</th>
                                                                                                                                                        <th>Details </th>
                                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                                        <th>Action </th>
                                                                                                                                                    </tr>
                                                                                                                                                </thead>

                                                                                                                                                <tbody>
                                                                                                                                                    {
                                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                                            <Fragment>

                                                                                                                                                                <tr>
                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                                                        {" "}
                                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, item.index - 1))}
                                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                                    disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false} />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                            &nbsp;&nbsp;



                                                                                                                                                                        </div>
                                                                                                                                                                    </td>

                                                                                                                                                                </tr>

                                                                                                                                                            </Fragment>
                                                                                                                                                        ))

                                                                                                                                                    }

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                        </td>
                                                                                                                                    </tr> : null
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    </Fragment>
                                                                                                                ))
                                                                                                            }


                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>


                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-sm-8 col-lg-8">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="300" name="Remarks" value={TrimValues[0].Remarks}
                                                                                onChange={event => handleTrimChangeSubmissionMain(event, 'Remarks')}
                                                                                rows="4" style={{ width: "100%" }}
                                                                                disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveTrimSubSubmission()}
                                                                    disabled={TrimValues[0].SubmitStatus === "Completed" ? true : false}>

                                                                    <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {
                                                            ShowTrimPopup === true ?
                                                                <Modal show={ShowTrimPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowTrimPopup(false)}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            AWB and Date
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                                                    <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                                                    {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>

                                                                                <tr>
                                                                                    <td>
                                                                                        <span className='input-icon icon-right'>
                                                                                            <input type="text"
                                                                                                placeholder="AWB Name"
                                                                                                id={"AWBName"}
                                                                                                name="AWBName"
                                                                                                value={TrimValues[0].AWBName}
                                                                                                style={{ border: submittedpopup && !TrimValues[0].AWBName ? '1px solid red' : '' }}
                                                                                                autoFocus
                                                                                                maxLength="25"
                                                                                                className={'form-control'}
                                                                                                onChange={event => handleTrimpopupchange(event, 'AWBName')}
                                                                                                menuPosition="fixed"
                                                                                            // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                            />
                                                                                        </span>

                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            <input id={"AWBDate"} name="AWBDate"
                                                                                                value={TrimValues[0].AWBDate} placeholder="DD/MM/YYYY"
                                                                                                type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                className={'form-control'}
                                                                                                style={{ border: submittedpopup && TrimValues[0].AWBDate === '' ? '1px solid red' : '' }}
                                                                                                onChange={event => handleTrimpopupchange(event, 'AWBDate')}
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                                                            <i className="fa fa-check right"></i>&nbsp;
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                : ''
                                                        }
                                                    </>
                                                    : ''
                                            }

                                        </Modal.Body>

                                    </Modal> : ''

                            }
                            {/* TRIMAPPROVAL */}
                            {


                                getTrimAppManual === true ?
                                    <Modal show={getTrimAppManual === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopupTrim()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Manual Close : Trim Submission
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Comments <span className="text-danger">*</span></th>
                                                        <th>Close Date <span className="text-danger">*</span></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style={{ width: "600px" }} >
                                                            {
                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].approvalManualCloseComment}
                                                                        onChange={event => handleChangeIsTrimAppManualCloseComments(event, "Comment")}
                                                                        style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseComment ? '' : '1px solid red' }}
                                                                    //disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                    >

                                                                    </textarea> :
                                                                    <textarea
                                                                        rows="3"
                                                                        placeholder="Enter Comments here..."
                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                        maxLength="500" name="EnterRemarks"
                                                                        id={"EnterRemarks"}
                                                                        value={ApprovalManualInfo[0].manualCloseComment}

                                                                        style={{ width: "100%", border: getComment && ApprovalManualInfo[0].approvalManualCloseComment === '' ? '1px solid red' : '' }}
                                                                        disabled
                                                                    >

                                                                    </textarea>

                                                            }
                                                        </td>
                                                        <td >
                                                            {

                                                                ApprovalManualInfo[0].approvalStatus !== "Completed" ?
                                                                    <DatePicker className="form-control" name="HolidayDate1"

                                                                        selected={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                        onChange={event => handleChangeIsTrimAppManualCloseComments(event, "CommentDate")}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        // showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        minDate={new Date()}
                                                                        maxDate={new Date()}
                                                                        customInput={<CustomInput />}
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                    />

                                                                    :

                                                                    <DatePicker className="form-control" name="StoryName"
                                                                        id={
                                                                            "StoryName"
                                                                        }
                                                                        selected={new Date(ApprovalManualInfo[0].manualCloseDate)}

                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        disabled
                                                                    // showYearDropdown

                                                                    //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                    />
                                                                // <input
                                                                //     placeholder="Enter the StoryName"
                                                                //     className="form-control"
                                                                //     title={ApprovalManualInfo[0].manualCloseDate}
                                                                //     name="StoryName"
                                                                //     id={"StoryName"}
                                                                //     value={ApprovalManualInfo[0].manualCloseDate}
                                                                //     disabled

                                                                // ></input>
                                                            }


                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                            {
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Story</th>
                                                            <th>Theme</th>
                                                            <th>Details </th>
                                                            <th>Task Holder / Follower</th>
                                                            <th>
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                        onChange={event => (TrimSubSelectAll(event))}
                                                                        checked={selectall === 1 ? true : false}
                                                                        className="colored-blue clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            MultiCloseDatas.length !== 0 ?
                                                                MultiCloseDatas.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{item.storyName}</td>
                                                                                <td>{item.themeName}</td>
                                                                                <td>{item.details}</td>
                                                                                <td>{item.followerName}</td>

                                                                                <td className="Actionbutton fixed-column">
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                disabled={ApprovalManualInfo[0].approvalStatus === "Completed" ? true : false}
                                                                                                onChange={event => (TrimSubOnSelectRecords(event, index))}
                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity" />
                                                                                            <span className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                        </>

                                                                    )
                                                                })
                                                                :
                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                        }

                                                    </tbody>
                                                </table>


                                            }

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                ApprovalManualInfo[0].approvalStatus !== "Completed" &&
                                                <Button variant="success" onClick={() => SaveChildManulaTrimAppCloseComments()}>
                                                    Save
                                                </Button>


                                            }


                                        </Modal.Footer>
                                    </Modal> : ''

                            }
                            {


                                showtrimApproval === true ?
                                    <Modal show={showtrimApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowTrimApproval(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {getheader} Trim Approval
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                showMultiClosediv === true ?

                                                    <>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-info">
                                                                    Note : If you want to submit multiple, you can select multiple items.
                                                                </div>
                                                                <div className="dataTables_wrapper no-footer">
                                                                    {
                                                                        OrderTrimInfoMulticloseMainDetails.length !== 0 ?

                                                                            <table className="table table-bordered">

                                                                                <tbody>
                                                                                    {
                                                                                        OrderTrimInfoMulticloseMainDetails.map(comment => (
                                                                                            <Fragment>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-8">
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        expandStateMultiSelect[comment.trimLogoPath] ?
                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                    }
                                                                                                                    onClick={() => handleTrimExpandRowMultiSelect(comment.trimLogoPath, MultiCloseDatas)}
                                                                                                                ></i>
                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    {
                                                                                                                        comment.trimCode
                                                                                                                    }
                                                                                                                    -
                                                                                                                    {
                                                                                                                        comment.trimName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <span className="Actionbutton">
                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                                <>
                                                                                                    {
                                                                                                        expandedRowsMultiSelect.includes(comment.trimLogoPath) ?

                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Story</th>
                                                                                                                                <th>Theme</th>
                                                                                                                                <th>Details </th>
                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                <th>
                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                            onChange={event => (TrimSubSelectAll(event, comment.trimLogoPath))}
                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                        <span className="text"></span>
                                                                                                                                    </label>

                                                                                                                                </th>

                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                expandListMultiSelect.map((item, index) => (
                                                                                                                                    <Fragment>

                                                                                                                                        <tr>
                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                            <td>{item.details}</td>
                                                                                                                                            <td>{item.followerName}</td>
                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (TrimSubOnSelectRecords(event, item.index - 1))}
                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>

                                                                                                                                                </div>

                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </Fragment>
                                                                                                                                ))

                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr> : null
                                                                                                    }
                                                                                                </>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }






                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        {/* <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                    <i className="fa fa-check right"></i> Add
                                                </button>
                                            </div>
                                        </div> */}
                                                    </>
                                                    : ''
                                            }
                                            {
                                                showApprovaldiv === true ?
                                                    <>
                                                        <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="BuyerID">
                                                                                Buyer-Season-Brand

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Buyer-Season-Brand"
                                                                                    className="form-control"
                                                                                    name=" Buyer-Season-Brand"
                                                                                    id={" Buyer-Season-Brand"}
                                                                                    value={TrimValues[0].BuyerBrandSeasonName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="SupplierID">
                                                                                Supplier
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Supplier"
                                                                                    className="form-control"
                                                                                    name="SupplierID"
                                                                                    id={"SupplierID"}
                                                                                    value={TrimValues[0].SupplierName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="Name">
                                                                                Submit Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {/* <input
                                                                                    placeholder="SubmittedDate"
                                                                                    className="form-control"
                                                                                    name="SubmittedDate"
                                                                                    id={"SubmittedDate"}
                                                                                    value={TrimValues[0].SubmittedDate}
                                                                                    disabled

                                                                                ></input> */}

                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                    id={
                                                                                        "SubmittedDate"
                                                                                    }
                                                                                    selected={
                                                                                        TrimValues[0].SubmittedDate
                                                                                    }
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    peekNextMonth
                                                                                    // customInput={<CustomInput />}
                                                                                    disabled
                                                                                />

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"SendTo"}>
                                                                                Approval Date
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                {
                                                                                    TrimValues[0].ApprovalStatus !== "Completed" ?

                                                                                        <DatePicker className="form-control" name="ApprovalDate"
                                                                                            id={
                                                                                                "ApprovalDate"
                                                                                            }
                                                                                            selected={
                                                                                                TrimValues[0].ApprovalDate
                                                                                            }

                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            onChange={event => TrimApphandleChange(event, 'ApprovalDate')}
                                                                                            customInput={<CustomInputtrimApprovalsubmit />}

                                                                                        />
                                                                                        // <input
                                                                                        //     id="ApprovalDate"
                                                                                        //     name="ApprovalDate"
                                                                                        //     value={TrimValues[0].ApprovalDate}
                                                                                        //     placeholder="DD/MM/YYYY"
                                                                                        //     type="date"
                                                                                        //     min={minApprovalDate}
                                                                                        //     autoComplete="off"
                                                                                        //     className={"form-control"}
                                                                                        //     onKeyDown={(e) => e.preventDefault()}
                                                                                        //     style={{
                                                                                        //         color:
                                                                                        //             TrimValues[0].ApprovalDate === ""
                                                                                        //                 ? "#bfdea1"
                                                                                        //                 : "#000",
                                                                                        //         border:
                                                                                        //             submitted &&
                                                                                        //                 TrimValues[0].ApprovalDate === ""
                                                                                        //                 ? "1px solid red"
                                                                                        //                 : "",
                                                                                        //     }}
                                                                                        //     onChange={event => TrimApphandleChange(event, 'ApprovalDate')}
                                                                                        // />
                                                                                        :

                                                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                                                            id={
                                                                                                "SubmittedDate"
                                                                                            }
                                                                                            selected={
                                                                                                TrimValues[0].ApprovalDate
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            // customInput={<CustomInput />}
                                                                                            disabled
                                                                                        />
                                                                                    // <input
                                                                                    //     placeholder="ApprovalDate"
                                                                                    //     className="form-control"
                                                                                    //     name="ApprovalDate"
                                                                                    //     id={"ApprovalDate"}
                                                                                    //     value={TrimValues[0].ApprovalDate}
                                                                                    //     disabled

                                                                                    // ></input>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">


                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"StoryName"}>
                                                                                Story Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the StoryName"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].StoryName}
                                                                                    name="StoryName"
                                                                                    id={"StoryName"}
                                                                                    value={TrimValues[0].StoryName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={" ThemeName"}>
                                                                                Theme Name
                                                                                <span className="text-danger">*</span>
                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the  ThemeName"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].ThemeName}
                                                                                    name=" ThemeName"
                                                                                    id={" ThemeName"}
                                                                                    value={TrimValues[0].ThemeName}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Endcustomer"}>
                                                                                End Customer

                                                                            </label>
                                                                            <span className="input-icon icon-right">
                                                                                <input
                                                                                    placeholder="Enter the End Customer"
                                                                                    className="form-control"
                                                                                    title={TrimValues[0].EndCustomer}
                                                                                    name="End Customer No"
                                                                                    id={"End Customer"}
                                                                                    value={TrimValues[0].EndCustomer}
                                                                                    disabled

                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder="Submit Sent Details"
                                                                                    className="form-control"
                                                                                    name="SubmitSentDetails"
                                                                                    id={
                                                                                        "SubmitSentDetails"
                                                                                    }
                                                                                    value={TrimValues[0].SubmitSentDetails}
                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="tabbable">
                                                                        <ul
                                                                            className="nav nav-tabs nav-justified"
                                                                            id="myTab2"
                                                                        >
                                                                            <li
                                                                                className={tab === 0 ? "active" : ""} >

                                                                                <a
                                                                                    onClick={event => TabChange(0)}

                                                                                >
                                                                                    <h5>Submit Information</h5>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                className={tab === 1 ? "active" : " "}

                                                                            >
                                                                                <a


                                                                                    onClick={event => TabChange(1)}
                                                                                >
                                                                                    <h5>Order Information</h5>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-12">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="BuyerID">Trim Code</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder="StikeoffCode"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TrimCode}
                                                                                                            name="TrimCode"
                                                                                                            id={
                                                                                                                "TrimCode"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TrimCode
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor="SeasonID" >Name</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input
                                                                                                            placeholder=" Name"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TrimName}
                                                                                                            name="TrimName"
                                                                                                            id={
                                                                                                                "TrimName"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TrimName
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="col-sm-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                                                    <span className="input-icon icon-right">

                                                                                                        <input
                                                                                                            placeholder="TechnicalSpecification"
                                                                                                            className="form-control cut_text"
                                                                                                            title={TrimValues.TechnicalSpecification}
                                                                                                            name="TechnicalSpecification"
                                                                                                            id={
                                                                                                                "TechnicalSpecification"
                                                                                                            }
                                                                                                            value={
                                                                                                                TrimValues[0].TechnicalSpecification
                                                                                                            }

                                                                                                            disabled
                                                                                                        ></input>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-sm-3">

                                                                                                <label htmlFor={"Quarter"}>Trim Image</label>
                                                                                                <br />
                                                                                                <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                                                                {
                                                                                                    getSubImageList.length !== 0 ?
                                                                                                        <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                                        :
                                                                                                        <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                                            No Images Found
                                                                                                        </div>
                                                                                                }
                                                                                                <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>




                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-sm-6">
                                                                                                <div className="form-group">

                                                                                                    <label htmlFor={"TrimDimensionId"}>Trim Dimension <span className="text-danger">*</span></label>
                                                                                                    <select id={"TrimDimensionId"} name="TrimDimensionId" className="form-select"
                                                                                                        value={TrimValues[0].TrimDimensionId}
                                                                                                        // onChange={(event) => handleTrimChangeSubmissionMain(event, 'TrimDimensionId')}
                                                                                                        style={{ border: submittedpopup && TrimValues[0].TrimDimensionId === "0" ? '1px solid red' : '' }}
                                                                                                        disabled
                                                                                                    >
                                                                                                        <option value="0">-Select Trim-</option>
                                                                                                        {
                                                                                                            TrimDimension.map(item => (
                                                                                                                <option key={item.id} value={item.value}>
                                                                                                                    {item.label}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }

                                                                                                    </select>




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12 col-lg-12">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-9 col-lg-9">
                                                                                                    <div className='form-group'>
                                                                                                        <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                                        <textarea maxLength="300" name="Remarks" value={TrimValues[0].Remarks}
                                                                                                            rows="4" style={{ width: "100%" }}
                                                                                                            disabled
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="row">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td><label>Submit Options</label> </td>
                                                                                                    <td><label>Submission Card</label></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td> <div className="col-sm-6">
                                                                                                        <div className="form-group">


                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                style={{ width: "700px" }}>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th >Options <span className="text-danger">*</span></th>
                                                                                                                        <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                        <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                                    </tr>

                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        submittedoption && (
                                                                                                                            submittedoption.map((item, index) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                                    <input
                                                                                                                                                        placeholder="Option Name"
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.optionName}
                                                                                                                                                        name="OptionName"
                                                                                                                                                        id={
                                                                                                                                                            "OptionName"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.optionName
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>


                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                                    <input
                                                                                                                                                        placeholder=""
                                                                                                                                                        className="form-control"
                                                                                                                                                        title={item.supplierReferenceNo}
                                                                                                                                                        name="Sendto"
                                                                                                                                                        id={
                                                                                                                                                            "Sendto"
                                                                                                                                                        }
                                                                                                                                                        value={
                                                                                                                                                            item.supplierReferenceNo
                                                                                                                                                        }
                                                                                                                                                        disabled
                                                                                                                                                    ></input>
                                                                                                                                                </span>

                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <span className='input-icon icon-right'>


                                                                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                                        id={
                                                                                                                                                            "TaskHolderOrFollower"
                                                                                                                                                        }
                                                                                                                                                        isLoading={false}
                                                                                                                                                        isClearable={true}
                                                                                                                                                        isSearchable={true}
                                                                                                                                                        onChange={event => TrimApphandleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                                        value={getApprovedOptions.filter(function (option) {
                                                                                                                                                            return option.value === item.approvedOptionName;
                                                                                                                                                        })}
                                                                                                                                                        options={getApprovedOptions}
                                                                                                                                                        styles={
                                                                                                                                                            submitted && item.approvedOptionName === '0' ? styles2 : styles1
                                                                                                                                                        }


                                                                                                                                                        menuPosition="fixed"
                                                                                                                                                        // disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                        isDisabled={TrimValues[0].ApprovalStatus === "Completed"}

                                                                                                                                                    ></Reactselect>
                                                                                                                                                </span>

                                                                                                                                            </td>

                                                                                                                                        </tr>
                                                                                                                                    </>

                                                                                                                                )
                                                                                                                            }))
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div></td>
                                                                                                    <td>
                                                                                                        <div className={ImgClassName}
                                                                                                            htmlFor="upload-button"
                                                                                                        >
                                                                                                            <img
                                                                                                                src={TrimValues[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + TrimValues[0].SwatchCardIamgePath}

                                                                                                                alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </table>




                                                                                            <div> {
                                                                                                isOpen && (

                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                        alt="bg image"
                                                                                                        onCloseRequest={
                                                                                                            () => setIsOpen(false)
                                                                                                        } />
                                                                                                )
                                                                                            } </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                {/* <div className="row">
                                                                    <div className="col-sm-12" align={"right"}>
                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                            disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false}>
                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <br /> */}
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="dataTables_wrapper no-footer">
                                                                                            {
                                                                                                OrderInfoMainDetails.length !== 0 ?

                                                                                                    <table className="table table-bordered">

                                                                                                        <tbody>

                                                                                                            {
                                                                                                                OrderInfoMainDetails.map(comment => (
                                                                                                                    <Fragment>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-lg-8">
                                                                                                                                        <i
                                                                                                                                            className={
                                                                                                                                                expandState[comment.trimLogoPath] ?
                                                                                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                            }
                                                                                                                                            onClick={() => handleTrimExpandRow(comment.trimLogoPath, getOrderinfo)}
                                                                                                                                        ></i>
                                                                                                                                        <span className="BuyerPaddingLeft">
                                                                                                                                            {
                                                                                                                                                comment.trimCode
                                                                                                                                            }
                                                                                                                                            -
                                                                                                                                            {
                                                                                                                                                comment.trimName
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-lg-4">
                                                                                                                                        <span className="Actionbutton">
                                                                                                                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                                        </span>
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                expandedRows.includes(comment.trimLogoPath) ?
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Story</th>
                                                                                                                                                        <th>Theme</th>
                                                                                                                                                        <th>Details </th>
                                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                                    </tr>
                                                                                                                                                </thead>

                                                                                                                                                <tbody>
                                                                                                                                                    {
                                                                                                                                                        expandList.map((item, index) => (
                                                                                                                                                            <Fragment>

                                                                                                                                                                <tr>
                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                    <td>{item.details}</td>
                                                                                                                                                                    <td>{item.followerName}</td>
                                                                                                                                                                    {/* <td className="Actionbutton fixed-column">
                                                                                                                                                        {" "}
                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                <input type="checkbox" name="SelectedDetail"

                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, item.index - 1))}
                                                                                                                                                                    checked={item.SelectedDetail === 1 ? true : false}
                                                                                                                                                                    className="colored-blue clearOpacity"
                                                                                                                                                                    disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false} />
                                                                                                                                                                <span className="text"></span>
                                                                                                                                                            </label>
                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                            <button
                                                                                                                                                                type="button"
                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                title="Delete Trim"
                                                                                                                                                                onClick={() =>
                                                                                                                                                                    handleRemoveOrderinfo(index)
                                                                                                                                                                }
                                                                                                                                                                disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>

                                                                                                                                                        </div>
                                                                                                                                                    </td> */}

                                                                                                                                                                </tr>

                                                                                                                                                            </Fragment>
                                                                                                                                                        ))

                                                                                                                                                    }

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                        </td>
                                                                                                                                    </tr> : null
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    </Fragment>
                                                                                                                ))
                                                                                                            }


                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    :
                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                        <TableHeader headers={headers} />
                                                                                                        <tbody>
                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                        </tbody>
                                                                                                    </table>


                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br />


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-sm-9 col-lg-9">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="Remarks">Remarks</label>
                                                                            <textarea maxLength="300" name="Remarks" value={TrimValues[0].ApprovalRemark}
                                                                                onChange={event => TrimApphandleChange(event, 'Remarks')}
                                                                                rows="4" style={{ width: "100%" }}
                                                                                disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12" align="right">
                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveTrimApproval()}
                                                                    disabled={TrimValues[0].ApprovalStatus === "Completed" ? true : false}
                                                                >
                                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </>
                                                    : ''
                                            }

                                        </Modal.Body>

                                    </Modal> : ''

                            }

                            <div> {
                                isOpenimg && (

                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                        alt="bg image"
                                        onCloseRequest={
                                            () => setIsOpenimg(false)
                                        } />
                                )
                            } </div>
                            {/* </div> */}


                        </div>
                    </div>
                </div>
            </div >
            {
                //View Size
                showTaskOwnerPopup.isPopup === true ?
                    <Modal show={showTaskOwnerPopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleTaskOwnerPopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title style={{ marginLeft: "11px" }}>
                                Task Owner View
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Task Owner Details</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            <Fragment>
                                                <tr>
                                                    <td>
                                                        <span>
                                                            {showTaskOwnerPopup.detail}

                                                        </span>
                                                    </td>

                                                </tr>
                                            </Fragment>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="danger" onClick={() => handleTaskOwnerPopupClose()}>
                                    <i class="fa fa-close"></i>&nbsp;Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }
            {loader}
        </Fragment >


    );
}
export default SamGenlTNAReport;
