import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FinalInspectionMainInformation from "./MainInformation";
import FinalInspectionMeasurement from "./Measurement";
import FinalInspectionDefects from "./Defects";
import FinalInspectionPackingImages from "./PackingImages";
import FinalInspectionConclusion from "./Conclusion";
import ReactNotification from 'react-notifications-component';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import Nodify from "../../../Common/ReactNotification";
import StyleCopyconfirmation from '../../../Common/StylecopyList';
import SweetAlert from 'react-bootstrap-sweetalert';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import QcNotes from "./QcNotes";
//import CommonStyle from "../../../Style/CommonStyle";
import CommonStyleInfo from "../../../Style/CommonStyleinfo";
import StyleService from "../../../../services/Style/styleService";
import moment from "moment";
const CreateFinalInspection = (props) => {
    const getInspectionType = props.location.state && props.location.state.params.type;
    const [getCurrentTab, setCurrentTab] = useState('Tab_MainInformation');
    const [getInspectionID, setInspectionID] = useState(0);
    const [getMainInputField, setMainInputField] = useState({});
    const [getInspectionQtyInfo, setInspectionQtyInfo] = useState([]);
    const [getQuantysubDetails, setQuantysubDetails] = useState({})
    const [getnewconfirmdata, setnewconfirmdata] = useState([]);
    const [getcolorsandvalue, setcolorsandvalue] = useState([]);
    const [getCsrValue, setCsrValue] = useState([]);
    const [getreportCheckValue, setreportCheckValue] = useState([]);
    const [gettrimValue, settrimValue] = useState([]);
    const [getartWorksValue, setartWorksValue] = useState([]);
    const [getWorkmanshipValue, setWorkmanshipValue] = useState([]);
    const [getMajorminlvl, setMajorminlvl] = useState({});
    const [getConclusionFindingRemarks, ConclusionFindingRemarks] = useState('');
    const [getnewimagevalue, setnewimagevalue] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(true);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0, offqty: 0, offerper: 0 });
    const [addStyleConfirmation, setAddStyleConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const [getDefectvalue, setdefectTab] = useState([]);
    const [getRefreah, setRefreash] = useState(true);
    const [getexternalfile, setexternalfile] = useState([]);
    const [getPackingplanvalue, setPackingplanvalue] = useState([]);
    const [getSummaryvalue, setSummaryvalue] = useState([]);
    const [getMeasurementFileList, setMeasurementFileList] = useState();
    const [getFindingRemarks, setFindingRemarks] = useState();
    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsSaveValidation, setIsSaveValidation] = useState(false);
    const [IsSaveValidationDefect, setIsSaveValidationDefect] = useState(false);
    const [PackingImageValue, setPackingImageValue] = useState([]);
    const [getStyleInfo, setStyleInfo] = useState([]);
    const [getInspectionInfo, setInspectionInfo] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [refershstate, setrefershstate] = useState(0)
    const [Isconfirmdata, setconfirmdata] = useState(false);
    var defaultStartDate = new Date();
    const [getkeyinfo, setkeyinfo] = useState(0)
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 190),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const TabRedirection = (tab) => {
        setCurrentTab(tab);
    }
    const { user: currentUser } = useSelector((state) => state.auth);
    const InpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    useEffect(() => {

        //setCurrentTab("Tab_MainInformation")
        StyleService.GetStyleList(props.location.state.params.styleID, props.location.state.params.Isproduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                setStyleInfo(response.data.item1[0]);
            }
        }).catch(() => { });
        StyleService.GetStyleInspectionList(
            props.location.state.params.styleID,
            props.location.state.params.Isproduction,
            props.location.state.params.TNAInspectionID || InpsectionId,
            // props.location.state.params.details.tnaid,
            1
        ).then((response) => {
            debugger
            if (response.data) {
                console.log("Full Response Data: ", response.data);
                setInspectionInfo(response.data[0]);
                // if (response.data.item1 && response.data.item1.length > 0) {
                //     console.log("responsedata item1[0]:", response.data.item1[0]);
                //     setInspectionInfo(response.data.item1[0]);
                // } else {
                //     console.error("Item1 is empty or undefined.");
                // }
            }
        }).catch((error) => {
            console.error("API call failed:", error);
        });
        const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
        if (props.location.state.params.TaskStatus === 2) {
            setButtonLoader(props.location.state.params.TNAInspectionID === 0 ? true : false)
        } else {
            setButtonLoader(inpsectionId === null ? true : false)
        }
        if (props.location.state.params.TaskStatus === 1) {
            FinalinspectionService.GetTNAFinalinspecMainInfo(getInspectionID !== 0 ? getInspectionID : inpsectionId !== null ? inpsectionId : 0, props.location.state.params.details.tnaid).then((res) => {
                if (res.data.finalInsMainList[0]) {
                    let TnaInspcID = res.data.finalInsMainList[0].tnaFinalInspectionID === undefined ? 0 : res.data.finalInsMainList[0].tnaFinalInspectionID
                    setInspectionID(TnaInspcID)
                }
            })
        }
    }, [getInspectionID ,refershstate])

    function ResetOperation(e) {
        if (e === "Back") {
            if (props.location.state.params.ParentTask === "TNAEdit") {
                props.history.push('/TNAEdit', { params: props.location.state.params.details });
            } else if (props.location.state.params.ParentTask === 'TNAView') {
                //localStorage.removeItem("InspectionID");
                props.history.push('/TNAView', { params: props.location.state.params.details });
                setRefreash(true)
            } else if (props.location.state.params.ParentTask === 'TNAReport') {
                props.history.push('/TNAReport')
            }
            else {
                let params = { localStorage: true, PageName: props.location.state.params.PageName };

                props.history.push('/FinalInspection', { params: params })
            }
            localStorage.removeItem("InspectionID");
        } else {
            window.location.reload();
        }
    }

    function Inspectioncallback(value) {

        let isvalid = true;
        setInspectionID(value)
        isvalid = value !== '' ? false : true
        setButtonLoader(isvalid);
    }

    function Mainfomacallback(value) {

        setMainInputField(value)
    }

    function QuantityCallback(value) {
        setInspectionQtyInfo(value)
    }

    function QuantitySubInfoCallback(value) {
        setQuantysubDetails(value)
        // setRefreash(true);
    }

    function Confocallback(value) {
        setnewconfirmdata(value)
    }

    function StylecolorCallback(value) {
        setcolorsandvalue(value)
    }

    function ArtworkCallback(value) {
        setartWorksValue(value)
    }

    function TrimInfoCallback(value) {
        settrimValue(value)
    }

    function workmanshipCallback(value) {
        setWorkmanshipValue(value)
    }

    function TestRepotcallback(Value) {
        setreportCheckValue(Value)
    }

    function CSRCallback(Value) {
        setCsrValue(Value)
    }

    function imagevaluecallback(value) {
        setnewimagevalue(value)
    }

    function Totalvalcallback(value) {
        setTotalValues(value)
    }
    function packingcallback(value) {
        setPackingplanvalue(value)
    }
    function ExternalFilecallback(value) {
        setexternalfile(value)
    }
    function SaveMainInformationCallback(value) {
        if (value === true) {
            localStorage.removeItem("InspectionID");
            setRefreash(true)
        } else {
            setRefreash(false)
        }
    }

    function DefectCallback(value) {
        setdefectTab(value)
    }

    function Messurecallback(value) {
        setMeasurementFileList(value)
    }
    function Summarycallback(value) {
        setSummaryvalue(value)
    }
    function PackingImagecallback(value) {
        setPackingImageValue(value)
    }
    function FindingRemarksCallback(value) {
        setFindingRemarks(value)
    }

    function MajorminorLvlcallback(value) {

        setMajorminlvl(value)
    }
    function ConclusionCallback(value) {
        ConclusionFindingRemarks(value)
    }
    const copystyle = () => {
        if (props.location.state.params.ParentTask === 'TNAEdit') {
            localStorage.removeItem("InspectionID");
            props.history.push('/TNAEdit', { params: props.location.state.params.details });
            setRefreash(true)
        } else if (props.location.state.params.ParentTask === 'TNAView') {
            localStorage.removeItem("InspectionID");
            props.history.push('/TNAView', { params: props.location.state.params.details });
            setRefreash(true)
        } else if (props.location.state.params.ParentTask === 'TNAReport') {
            props.history.push('/TNAReport')
            localStorage.removeItem("InspectionID");
        } else if (props.location.state.params.ParentTask === 'Dashboard') {
            props.history.push('/Dashboard')
            localStorage.removeItem("InspectionID");
        } else {
            let params = { localStorage: true };
            props.history.push('/FinalInspection', { params: params })
            localStorage.removeItem("InspectionID");
        }
    }
    const handleClose = (value) => {
        //props.location.state.params.Mode = 'InspectionAdd';
        setAddStyleConfirmation({ showPopupConfirm: false, Params: [] });
        let x = Math.floor((Math.random() * 100) + 1);
        setrefershstate(x);
        setRefreash(false)
        setkeyinfo(1);
    }
    let confirmBtnText = 'Yes,do it!'
    let cancelBtnText = 'Cancel'
    function SaveMainInformation(e) {

        let isvalid = true;
        let OperationID = 0;
        if (e === "Draft") {
            OperationID = 3
        } else {
            OperationID = 3
        }
        let ExternalFileUploads = []
        let SkuImageInfo = []
        let ConfirmAvailInfo = []
        let Qualityinfo = []
        let styleAndColorInfo = []
        let styleAndColorsubInfo = []
        let styleAndColorImage = []
        let WorkmanshipsubInfo = []
        let WorkmanshipInfo = []
        let ArtWorkInfo = []
        let ArtWorkSubInfo = []
        let ArtWorkImages = []
        let TrimInfo = []
        let TrimSubInfo = []
        let TrimImages = []
        let TestReportInfo = []
        let TestReportSubInfo = []
        let CSRInfo = []
        let CSRSubInfo = []
        let Packing = []
        let PackingSub = []
        let PackingFile = []
        let DefectTabInfo = []
        let DefectSubInfo = []
        let DefectSummaryInfo = []
        let PackingImage = []
        let PackingImageSub = []
        let packingImagetab = [];
        let MeasurmentFiles = []
        let MeasurmentCategory = []
        let MeasurmentPoints = []
        const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
        if ((getMainInputField.ReportDate === undefined || getMainInputField.ReportDate === "") || (getMainInputField.FactoryLocation === undefined || getMainInputField.FactoryLocation === "")) {
            Nodify('Warning!', 'warning', "Please fill mandatory fields");
            setIsParentCallback(true)
            isvalid = false
        }
        if (getInspectionQtyInfo.length === 0) {
            isvalid = false
            setIsParentCallback(true)
            Nodify('Warning!', 'warning', 'Please fill atleast one OfferQuantity in Quantity Information Tab.');
            return false;
        }

        if (props.location.state.params.type === 2) {
            if ((getMainInputField.ExAgencyName === undefined || getMainInputField.ExAgencyName === "") || (getMainInputField.ExReportNo === undefined || getMainInputField.ExReportNo === "")) {
                Nodify('Warning!', 'warning', "Please fill mandatory fields");
                setIsParentCallback(true)
                isvalid = false
            }
            else if ((getMainInputField.ExAqlResultID === 2 && (getMainInputField.ExRemarks === undefined || getMainInputField.ExRemarks === "")) || (props.location.state.params.type === 2 &&(getMainInputField.ExAqlResult ==='' || getMainInputField.ExAqlResult === undefined))) {
                Nodify('Warning!', 'warning', "Please fill mandatory fields");
                setIsParentCallback(true)
                setIsSaveValidation(true)
                isvalid = false
            }

            else if (getexternalfile[0] === undefined) {
                Nodify('Warning!', 'warning', "Upload  minimum one file.");
                setIsParentCallback(true)
                isvalid = false
            }
        }
        if (getInspectionQtyInfo.length > 0) {
            for (var i = 0; i < getInspectionQtyInfo.length; i++) {
                if (getInspectionQtyInfo[i] === undefined || getInspectionQtyInfo[i].offerqty === '' || getInspectionQtyInfo[i].offerqty === 0 || getInspectionQtyInfo[i].offerqty === null) {
                    setIsParentCallback(true)
                    isvalid = false
                    Nodify('Warning!', 'warning', 'Please fill The OfferQuantity in Quantity Information Tab.');
                    return false;
                }
            }

        }
        if ((getQuantysubDetails.quantityavailable === undefined || getQuantysubDetails.quantityavailable === '') && props.location.state.params.type !== 2) {
            setIsParentCallback(true)
            isvalid = false
            Nodify('Warning!', 'warning', 'Please fill The Quantity Available for Inspection in Quantity Information Tab.');
            return false;
        }
        if (getQuantysubDetails.Result === 2 && getQuantysubDetails.FindingsRemarks === "") {
            setIsParentCallback(true)
            isvalid = false
            Nodify('Warning!', 'warning', 'Please fill The Finding Remarks in Quantity Information Tab.');
            return false;
        }
        if (e !== "Draft") {
            if (getnewconfirmdata.length > 0) {
                for (var i = 0; i < getnewconfirmdata.length; i++) {
                    if (getnewconfirmdata[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        setconfirmdata(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Confirmation on Available Information.');
                        return false;
                    } else if (getnewconfirmdata[i].ResultID === 2 && getnewconfirmdata[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        setconfirmdata(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Confirmation on Available Information.');
                        return false;
                    }
                }
            }
            if (getcolorsandvalue.length > 0) {
                for (var i = 0; i < getcolorsandvalue.length; i++) {
                    if (getcolorsandvalue[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Style And Colors Information.');
                        return false;
                    } else if (getcolorsandvalue[i].ResultID === 2 && getcolorsandvalue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Style And Colors Information.');
                        return false;
                    }

                }
            }
            if (getartWorksValue.length > 0) {
                for (var i = 0; i < getartWorksValue.length; i++) {
                    if (getartWorksValue[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Strike Off - Artworks Information.');
                        return false;
                    } else if (getartWorksValue[i].ResultID === 2 && getartWorksValue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Strike Off - Artworks Information.');
                        return false;
                    }
                }
            }
            if (gettrimValue.length > 0) {
                for (var i = 0; i < gettrimValue.length; i++) {
                    if (gettrimValue[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Trims Information.');
                        return false;
                    } else if (gettrimValue[i].ResultID === 2 && gettrimValue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Trims Information.');
                        return false;
                    }
                }
            }
            if (getWorkmanshipValue.length > 0) {
                for (var i = 0; i < getWorkmanshipValue.length; i++) {
                    if (getWorkmanshipValue[i].WorkmanResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Workmanship Information.');
                        return false;
                    } else if (getWorkmanshipValue[i].WorkmanResultID === 2 && getWorkmanshipValue[i].WorkmanFindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Workmanship Information.');
                        return false;
                    }

                }
            }
            if (getreportCheckValue.length > 0) {
                for (var i = 0; i < getreportCheckValue.length; i++) {
                    if (getreportCheckValue[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Test Report check Information.');
                        return false;
                    }
                    else if (getreportCheckValue[i].ResultID === 2 && getreportCheckValue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Test Report check Information.');
                        return false;
                    }

                }
            }
            if (getCsrValue.length > 0) {

                for (var i = 0; i < getCsrValue.length; i++) {
                    if (getCsrValue[i].ResultID === 0 && getCsrValue[i].ResultName === '') {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in CSR Information.');
                        return false;
                    } else if (getCsrValue[i].ResultName === 'Not Ok' && getCsrValue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in CSR Information.');
                        return false;
                    }
                }
            }

            if (getPackingplanvalue.length > 0) {
                for (var i = 0; i < getPackingplanvalue.length; i++) {
                    if (getPackingplanvalue[i].ResultID === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Packing Information.');
                        return false;
                    }
                    else if (getPackingplanvalue[i].ResultID === 2 && getPackingplanvalue[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Packing Information.');
                        return false;
                    }
                }
            }
            // if (inpsectionId !== null) {
            if (getDefectvalue.length > 0) {
                for (var i = 0; i < getDefectvalue.length; i++) {
                    if (getDefectvalue[i].DefectID === 0 || getDefectvalue[i].DefectType === 0) {
                        setIsSaveValidationDefect(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Defect Information.');
                        return false;
                    }
                }
            }

            // }
            ;
            if (getMeasurementFileList !== undefined && getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices === '') {
                setIsSaveValidation(true)
                isvalid = false
                Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
                return false;
            }
            if (getMeasurementFileList !== undefined && getMeasurementFileList.Files.length > 0) {
                for (var i = 0; i < getMeasurementFileList.Files.length; i++) {
                    if (getMeasurementFileList.Files[i].Result === 0) {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
                        return false;
                    }
                    else if (getMeasurementFileList.Files[i].Result === 2 && getMeasurementFileList.Files[i].FindingsRemarks === "") {
                        setIsSaveValidation(true)
                        isvalid = false
                        Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
                        return false;
                    }
                }
            }
            if(props.location.state.params.type === 1 && getMeasurementFileList.Files[0].FileName==='')
            {setIsSaveValidation(false)
                debugger
                setIsSaveValidation(true)
                isvalid = false
                Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields.');
                return false;
            }

        } 
        if (isvalid) {
            showLoader();

            getnewimagevalue.map(x => {
                SkuImageInfo.push({
                    TNAFinalInspectionSkuID: x.TNAFinalInspectionSkuID === undefined ? 0 : x.TNAFinalInspectionSkuID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuFileName: x.skuName,
                    SkuFilePath: x.skuFrontPicPath
                })
            })

            getexternalfile.map(x => {
                ExternalFileUploads.push({
                    ExternalFileupID: x.ExternalFileupID,
                    TNAID: props.location.state.params.details.tnaid,
                    FileType: x.FileType,
                    FileName: x.FileName,
                    Filepath: x.Filepath,
                    IsDeleted: x.IsDeleted
                })
            })

            getnewconfirmdata.map(data => {
                ConfirmAvailInfo.push({
                    TNAFinalConfmAvailinfoID: data.Itemid === 0 ? 0 : data.Itemid,
                    TNAID: props.location.state.params.details.tnaid,
                    Details: data.ItemName,
                    Result: data.ResultID,
                    FindingsRemarks: data.FindingsRemarks

                })
            })
            getInspectionQtyInfo.map(x => {
                Qualityinfo.push({
                    TNAFinalinspcQualityinfoID: x.TNAFinalinspcQualityinfoID === undefined ? 0 : x.TNAFinalinspcQualityinfoID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuID: (props.location.state.params.TaskStatus === 1 && inpsectionId === null) ? parseInt(x.refInspecSKUID) : parseInt(x.skuID),
                    PurchaseOrderSkuID: parseInt(x.purchaseOrderSkuID),
                    SubTaskID: x.tnaExFactoryId,
                    StyleName: x.styleNo,
                    SkuName: x.skuName,
                    EndCustomer: x.endCustomer,
                    IDNO: x.idNo,
                    FocusPo: '0',
                    BalanceQuantity: props.location.state.params.Mode === 'InspectionAdd' && getRefreah === false ?
                        (parseInt(x.existofferqty) + parseInt(x.balanceQuantity)) - parseInt(x.offerqty) :
                        props.location.state.params.Mode === 'InspectionView' && x.isclosed === 1 ?
                            parseInt(x.existqty) - parseInt(x.offerqty) :
                            props.location.state.params.Mode === 'InspectionView' && x.IscheckReportSelect === 2 ?
                                (parseInt(x.orderQuantity) + parseInt(x.Tolerance)) - (parseInt(x.offerqty)) :
                                props.location.state.params.Mode === 'InspectionView' ?
                                    (parseInt(x.orderQuantity) + parseInt(x.Tolerance)) - (parseInt(x.offerqty) + (parseInt(x.totofferQty) - parseInt(x.existqty))) :
                                    parseInt(x.balanceQuantity) - parseInt(x.offerqty),
                    //if(pro)
                    // BalanceQuantity: props.location.state.params.Mode === 'InspectionAdd' && getRefreah === true ?
                    //     (parseInt(x.orderQuantity) + parseInt(x.Tolerance)) - parseInt(x.offerqty) :
                    //     props.location.state.params.Mode === 'InspectionAdd' && getRefreah === false ?


                    //         //BalanceQuantity : x.isDraft === 1 && props.location.state.params.Mode === 'InspectionAdd' ?
                    //         (parseInt(x.balanceQuantity) - parseInt(x.offerqty))
                    //         : (parseInt(x.existofferqty) + parseInt(x.Tolerance)) - parseInt(x.offerqty),
                    POQty: x.orderQuantity === undefined ? '' : x.orderQuantity.toString(),
                    OfferedQty: x.offerqty === undefined ? '' : x.offerqty.toString(),
                    Tolerance: x.Tolerance,
                    Offeredpercent: x.OfferPercentage === undefined ? '' : x.OfferPercentage.toString(),
                    TotalPOQty: getTotalValues.PoQty === undefined ? '' : getTotalValues.PoQty.toString(),
                    TotalOfferedQty: getTotalValues.offqty === undefined ? '' : getTotalValues.offqty.toString(),
                    TotalOfferedpercent: getTotalValues.offerper === undefined ? '' : getTotalValues.offerper.toString(),
                    IsInpsectionStatus: e === "Draft" ? 1 : (e === 'Save' && getSummaryvalue[1] === undefined) ? 2 : (e === 'Save' && getSummaryvalue[1].result === "Within AQL") ? 3 : 4,
                    IsDeleted: x.IsDeleted
                    //NOTE:
                    // IsClosed Entered full quantity means 1 else we show as 0
                    //IscheckReportSelect popup selected values based..
                })
            })
            //colors
            getcolorsandvalue.map((x, i) => {
                styleAndColorInfo.push({
                    TNAFinalInspecStylecolorID: x.TNAFinalInspecStylecolorID,
                    TNAID: props.location.state.params.details.tnaid,
                    StylecolorDetails: x.skuName,
                    Result: x.ResultID,
                    FindingsRemarks: x.FindingsRemarks,
                    Mainindex: i
                })
                x.ImagesList.map((y, subindex) => {
                    styleAndColorsubInfo.push({
                        TNAFinalInspecStylecolorSubID: y.TNAFinalInspecStylecolorSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        ExpectedFilename: x.skuName,
                        ExpectedFilepath: y.skuFrontPicPath,
                        stylecolorFileName: y.UploadedImageName === undefined ? "" : y.UploadedImageName,
                        stylecolorFilePath: y.UploadedImagePath,
                        Skubackpicpath: y.skuPicPathposition,
                        Mainindex: i,
                        Subindex: subindex
                    })
                    y.StylecolorImagesList.map((child, childindex) => {
                        styleAndColorImage.push({
                            TNAFinalcStylecolorImageID: child.TNAFinalcStylecolorImageID,
                            TNAID: props.location.state.params.details.tnaid,
                            MainIndex: i,
                            UploadedImagePath: child.UploadedImagePath,
                            ImageIndex: childindex,
                            Subindex: subindex
                        })
                    })
                })
            })
            //Workmanship

            getWorkmanshipValue.map((x, i) => {
                WorkmanshipInfo.push({
                    TNAFinalInspecworkmanshipID: x.TNAFinalInspecworkmanshipID,
                    TNAID: props.location.state.params.details.tnaid,
                    workmanshipName: x.workmanshipName,
                    Result: x.WorkmanResultID,
                    FindingsRemarks: x.WorkmanFindingsRemarks,
                    workmanshipMainindex: i
                })
                x.ImagesList.map(y => {
                    WorkmanshipsubInfo.push({
                        TNAFinalInspecworkmanshipSubID: y.TNAFinalInspecworkmanshipSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        UploadedImagePath1: y.UploadedImagePath1,
                        UploadedImagePath2: y.UploadedImagePath2,
                        workmanshipMainindex: i
                    })
                })
            })

            getartWorksValue.map((x, i) => {
                ArtWorkInfo.push({
                    TNAFinalInspecArtworkID: x.TNAFinalInspecArtworkID,
                    TNAID: props.location.state.params.details.tnaid,
                    ArtworkDetails: x.StrikeOffName,
                    SkuName: x.SkuName,
                    Result: x.ResultID,
                    FindingsRemarks: x.FindingsRemarks,
                    ArtMainindex: i
                })

                x.ImagesList.map((y, Subindex) => {
                    ArtWorkSubInfo.push({
                        TNAFinalInspecArtworkSubID: y.TNAFinalInspecArtworkSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        ExpectedFilename: y.ExpectedFilename,
                        ExpectedFilepath: y.skuFrontPicPath,
                        ArtMainindex: i,
                        SubIndex: Subindex

                    })

                    y.StrikeoffImagesList.map((child, childindex) => {
                        ArtWorkImages.push({
                            TNAFinalInspecArtworkImagesID: child.TNAFinalInspecArtworkImagesID,
                            TNAID: props.location.state.params.details.tnaid,
                            UploadedImagePath: child.UploadedImagePath,
                            ArtWorkMainindex: i,
                            ImageIndex: childindex,
                            SubIndex: Subindex
                        })
                    })
                })

            })


            gettrimValue.map((x, i) => {
                TrimInfo.push({
                    TNAFinalInspecTrimID: x.TNAFinalInspecTrimID,
                    TNAID: props.location.state.params.details.tnaid,
                    TrimDetails: x.TrimName,
                    SkuName: x.SkuName,
                    Result: x.ResultID,
                    FindingsRemarks: x.FindingsRemarks,
                    TrimMainindex: i
                })
                x.ImagesList.map((y, Subindex) => {
                    TrimSubInfo.push({
                        TNAFinalInspecTrimSubID: y.TNAFinalInspecTrimSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        //TrimFilePath: y.UploadedImagePath,
                        ExpectedFilename: y.ExpectedFilename,
                        ExpectedFilepath: y.skuFrontPicPath,
                        TrimMainindex: i,
                        SubIndex: Subindex
                    })
                    y.TrimimagesList.map((child, childindex) => {
                        TrimImages.push({
                            TNAFinalInspecTrimImagesID: child.TNAFinalInspecTrimImagesID,
                            TNAID: props.location.state.params.details.tnaid,
                            TrimMainindex: i,
                            UploadedImagePath: child.UploadedImagePath,
                            ImageIndex: childindex,
                            SubIndex: Subindex
                        })
                    })
                })
            })

            getreportCheckValue.map((x, i) => {
                TestReportInfo.push({
                    TNAFinalInspecTestReportID: x.TNAFinalInspecTestReportID,
                    TNAID: props.location.state.params.details.tnaid,
                    TestReportDetails: x.ItemName,
                    Result: x.ResultID,
                    FindingsRemarks: x.FindingsRemarks,
                    TestReportMainindex: i
                })
                x.ImagesList.map(y => {
                    TestReportSubInfo.push({
                        TNAFinalInspecTestReportSubID: y.TNAFinalInspecTestReportSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        UploadedImagePath1: y.UploadedImagePath1,
                        UploadedImagePath2: y.UploadedImagePath2,
                        TestReportMainindex: i

                    })

                })
            })

            getCsrValue.map((x, i) => {

                CSRInfo.push({
                    TNAFinalInspecCSRID: x.TNAFinalInspecCSRID,
                    TNAID: props.location.state.params.details.tnaid,
                    CSRDetails: x.ItemName,
                    Result: x.ResultName.toLowerCase().split(" ").join("") === 'yes' || x.ResultName.toLowerCase().split(" ").join("") === 'ok'
                        || x.ResultName.toLowerCase().split(" ").join("") === 'available' ? 1 : x.ResultName.toLowerCase().split(" ").join("") === 'notok' || x.ResultName.toLowerCase().split(" ").join("") === 'no'
                            || x.ResultName.toLowerCase().split(" ").join("") === 'notavailable' ? 2 : 0,
                    ResultID: x.ResultID,
                    ResultName: x.ResultName,
                    InputType: x.InputType,
                    FindingsRemarks: x.FindingsRemarks,
                    CSRMainindex: i
                })
                x.ImagesList.map(y => {
                    CSRSubInfo.push({
                        TNAFinalInspecCSRSubID: y.TNAFinalInspecCSRSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        UploadedImagePath1: y.UploadedImagePath1,
                        UploadedImagePath2: y.UploadedImagePath2,
                        CSRMainindex: i

                    })

                })
            })
            //Packing

            getPackingplanvalue.map((x) => {
                Packing.push({
                    TNAFinalInspecPackingID: x.TNAFinalInspecPackingID,
                    TNAID: props.location.state.params.details.tnaid,
                    PackageItemID: x.packageItemID,
                    PackingMainIndex: x.packingMainIndex,
                    PackingName: x.packingName,
                    Result: x.ResultID,
                    FindingsRemarks: x.packingFindingsRemarks
                })
                x.UploadImagesList.map((y, indexs) => {
                    PackingSub.push({
                        TNAFinalInspecpackingSubID: y.TNAFinalInspecpackingSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        PackingMainindex: y.MainIndex,
                        ImageIndex: indexs,
                        UploadedImagePath: y.UploadedImagePath,
                    })
                })
                x.ExpectedImages.map((xy) => {
                    PackingFile.push({
                        TNAFinalInspecpackingFileID: xy.TNAFinalInspecpackingFileID,
                        TNAID: props.location.state.params.details.tnaid,
                        PackageItemID: xy.packageItemID,
                        PackingFileName: xy.packingFileName,
                        PackingFilepath: xy.packingFilepath
                    })
                })

            })

            getDefectvalue.map((x, i) => {
                DefectTabInfo.push({
                    TNAFinalInspecDefectID: x.TNAFinalInspecDefectID,
                    TNAID: props.location.state.params.details.tnaid,
                    DefectID: x.DefectID,
                    DefectName: x.DefectName,
                    FoundArea: x.FoundArea,
                    DefectType: x.DefectType,
                    CountID: x.CountID,
                    DefectMainindex: i,
                    DefectFindingRemarks: getFindingRemarks
                })
                x.ImageList.map((y, xi) => {
                    DefectSubInfo.push({
                        TNAFinalInspecDefectSubID: y.TNAFinalInspecDefectSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        DefectMainindex: i,
                        DefectChildindex: xi,
                        UploadedImagePath1: y.UploadedImagePath1,
                        UploadedImagePath2: y.UploadedImagePath2
                    })
                })
            })
            getSummaryvalue.map((x, i) => {
                DefectSummaryInfo.push({
                    TNAFinalInspecDefectSummaryID: x.TNAFinalInspecDefectSummaryID,
                    TNAID: props.location.state.params.details.tnaid,
                    Details: x.details,
                    Critical: x.critical,
                    Major: x.major,
                    Minor: x.minor,
                    Result: x.result
                })
            })

            PackingImageValue.map((x, i) => {
                PackingImage.push({
                    TNAFinalInspecPackingImageID: x.TNAFinalInspecPackingImageID,
                    TNAID: props.location.state.params.details.tnaid,
                    ProductPictureName: x.ProductPictureName,
                    PackingImageIndex: i,
                    PurchaseOrderSkuID: x.PurchaseOrderSkuID
                })
                x.ImagesList.map((y, yindex) => {
                    PackingImageSub.push({
                        TNAFinalInspecPacimageSubID: y.TNAFinalInspecPacimageSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        PackingImageIndex: i,
                        SkuFrontPicPath: y.skuFrontPicPath,
                        ImageUniqueID: y.ImageUniqueID,
                        UploadedImagePath: y.UploadedImagePath,
                        SkuName: y.DisplaySkuName,
                        SkuBackPicPath: y.skuBackPicPath
                    })
                    y.PackingUploadImageList.map((chl, chlindex) => {
                        packingImagetab.push({
                            TNAFinalInspecpackingImageID: chl.TNAFinalInspecpackingImageID,
                            TNAID: props.location.state.params.details.tnaid,
                            PackingImageIndex: i,
                            SubIndex: yindex,
                            ImageIndex: chlindex,
                            UploadedImagePath: chl.UploadedImagePath
                        })
                    })
                })
            })
            if (getMeasurementFileList !== undefined && getMeasurementFileList.Measurementdata.length > 0 &&
                getMeasurementFileList.Measurementdata[0].MeasurementSpec !== '') {
                getMeasurementFileList.Measurementdata.map(md => {
                    md.Category.map(c => {
                        MeasurmentCategory.push({
                            TNAFinalinsMeasurementCategoryId: c.TNAFinalinsMeasurementCategoryId,
                            StyleMeasurementCategoryID: c.StyleMeasurementCategoryID,
                            MeasurementCategoryID: c.MeasurementCategoryID,
                            CategoryTypeName: c.CategoryTypeName,
                            CategoryIndexName: c.CategoryIndexName.toString(),
                            SkuName: c.SkuName,
                            SizeHeaderNames: '',
                            StyleMeasurementID: c.StyleMeasurementID,
                            CategoryRowNo: c.CategoryRowNo,
                            Remark: c.Remark,
                        })

                        let currcatindex = MeasurmentCategory.length - 1;
                        let headernames = '';
                        c.SizeHeaderNames.map(s => {
                            if (headernames === '') {
                                headernames = s.HeaderName;
                            }
                            else {
                                headernames = headernames + "," + s.HeaderName;
                            }
                        })

                        MeasurmentCategory[currcatindex].SizeHeaderNames = headernames;

                        c.Point.map((point, pindex) => {
                            MeasurmentPoints.push({
                                TNAFinalinsMeasurementPointsId: point.TNAFinalinsMeasurementPointsId,
                                StyleMeasurementID: point.StyleMeasurementID,
                                MeasurementPoints: point.MeasurementPoints,
                                MeasurementGranding: point.MeasurementGranding,
                                TolerancePlus: point.TolerancePlus,
                                ToleranceMinus: point.ToleranceMinus,
                                GradingSizes: headernames,
                                GradingSizesValue: '',
                                CategoryIndexName: point.CategoryIndexName.toString(),
                                SkuName: point.SkuName,
                            })

                            let currpointindex = MeasurmentPoints.length - 1;
                            let sizevalues = '';
                            let headers = headernames.split(',')
                            headers.map(val => {
                                if (sizevalues === '') {
                                    point.arrGradingSizes[0][val] = (point.arrGradingSizes[0][val] === undefined || point.arrGradingSizes[0][val] === '') ? 0 : point.arrGradingSizes[0][val];
                                    sizevalues = point.arrGradingSizes[0][val];
                                }
                                else {
                                    point.arrGradingSizes[0][val] = (point.arrGradingSizes[0][val] === undefined || point.arrGradingSizes[0][val] === '') ? 0 : point.arrGradingSizes[0][val];
                                    sizevalues = sizevalues + "," + point.arrGradingSizes[0][val];
                                }
                            })

                            MeasurmentPoints[currpointindex].GradingSizesValue = sizevalues
                        })


                    })

                })
            }


            getMeasurementFileList !== undefined && getMeasurementFileList.Files.map((x, index) => {

                MeasurmentFiles.push({
                    TNAFinalInsMeasurementFileupID: x.TNAFinalInsMeasurementFileupID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuName: x.SkuName,
                    MeasurementIndex: index,
                    FileType: x.FileType,
                    FileName: x.FileName,
                    Filepath: x.FilePath,
                    Result: x.Result,
                    FindingsRemarks: x.FindingsRemarks,
                    IsDeleted: x.IsDeleted
                })
            })
            debugger
            let Inputmaster = {
                CalculationType: (getMeasurementFileList != undefined && getMeasurementFileList.Measurementdata != undefined) ? getMeasurementFileList.Measurementdata[0].CalculationType : 0,
                Operation: props.location.state.params.page === "ReInspection" ? 4 : OperationID,
                TNAFinalInspectionID:
                    props.location.state.params.pageType !== "AddInspection" ? props.location.state.params.TNAInspectionID :
                        inpsectionId !== null ? inpsectionId : 0,
                TNAID: props.location.state.params.details.tnaid,
                PurchaseOrderID: props.location.state.params.details.purchaseOrderID,
                BuyerID: props.location.state.params.buyerID,
                BuyerName: props.location.state.params.buyerName,
                SeasonID: props.location.state.params.seasonID,
                SeasonName: props.location.state.params.seasonName,
                BrandID: props.location.state.params.brandID,
                BrandName: props.location.state.params.brandName,
                SupplierID: props.location.state.params.supplierID,
                SupplierName: props.location.state.params.supplierName,
                FactoryLocationID: getMainInputField.FactoryLocationID,
                FactoryLocation: getMainInputField.FactoryLocation,
                //  ReportType: props.location.state.params.page === 'AddInspection' ? 1 : 2,
                ReportType: props.location.state.params.page === "ReInspection" ? 2 : getMainInputField.ReportType,
                StyleName: props.location.state.params.styleName,
                InspectionStandard: getMainInputField.InspectionStandardID,
                InspectionStandardName: getMainInputField.InspectionStandard.toString(),
                InspectionLevel: getMainInputField.InspectionLevelID,
                InspectionLevelName: getMainInputField.InspectionLevel.toString(),
                AQLLevel: getMainInputField.MajorMinAQLID,
                AQLLevelName: getMainInputField.MajorMinorAql.toString(),
                CustomerShipmentRefNo: getMainInputField.CustomerShipmentRefNo,
                InspectorConclusion: getConclusionFindingRemarks === '' ? '' : getConclusionFindingRemarks,
                ReportDate: getMainInputField.ReportDate,
                SamplingSize: getQuantysubDetails.SamplingSize === undefined ? '' : getQuantysubDetails.SamplingSize,
                QuantityAvailableInspection: getQuantysubDetails.quantityavailable === undefined || getQuantysubDetails.quantityavailable === '' ? 0 : parseInt(getQuantysubDetails.quantityavailable),
                TotalCartonBox: getQuantysubDetails.cartonbox === undefined || getQuantysubDetails.cartonbox === "" ? 0 : parseInt(getQuantysubDetails.cartonbox),
                SampleInspectedPieces: getQuantysubDetails.SampleInspectedPieces === undefined ? '' : getQuantysubDetails.SampleInspectedPieces,
                Result: (getQuantysubDetails.Result === undefined || getQuantysubDetails.Result === '') ? 0 : getQuantysubDetails.Result,
                FindingsRemarks: getQuantysubDetails.FindingsRemarks === undefined ? '' : getQuantysubDetails.FindingsRemarks,
                TNAFinalQualityinfoDetailsID: getQuantysubDetails.TNAFinalQualityinfoDetailsID === undefined ? 0 : getQuantysubDetails.TNAFinalQualityinfoDetailsID,
                inspectedCartonBox: getQuantysubDetails.inspectedCarton === undefined || getQuantysubDetails.inspectedCarton === '' ? 0 : parseInt(getQuantysubDetails.inspectedCarton),
                FinalinspecSKUInfomation: SkuImageInfo,
                FinalinspecQualityinfomation: Qualityinfo,
                FinalinspecConfmAvailinfomation: ConfirmAvailInfo,
                FinalinspecStyleandColorfomation: styleAndColorInfo,
                FinalinspecStyleandColorSubfomation: styleAndColorsubInfo,
                FinalinsStyleColorImageinfomation: styleAndColorImage,
                FinalinspecWorkmanshipinfomation: WorkmanshipInfo,
                FinalinspecWorkmanshipSubfomation: WorkmanshipsubInfo,
                FinalinspecArtWorkfomation: ArtWorkInfo,
                FinalinspecArtWorkSubfomation: ArtWorkSubInfo,
                FinalinspecArtworkImageInfomation: ArtWorkImages,
                FinalinspecTrimInfomation: TrimInfo,
                FinalinspecTrimSubfomation: TrimSubInfo,
                FinalinspecTrimImageInfomation: TrimImages,
                FinalinspecPackingInfomation: Packing,
                FinalinspecPackingSubInfomation: PackingSub,
                FinalinspecPackingFileinfomation: PackingFile,
                FinalinspecTestingReportinfomation: TestReportInfo,
                FinalinspecTestingReportSubfomation: TestReportSubInfo,
                FinalinspecCSRinfomation: CSRInfo,
                FinalinspecCSRSubfomation: CSRSubInfo,
                FinalinspecDefectinfomation: DefectTabInfo,
                FinalinspecDefectSubfomation: DefectSubInfo,
                FinalinspecDefectSummaryfomation: DefectSummaryInfo,
                FinalinspecPackingImageinfomation: PackingImage,
                FinalinspecPackingImageSubinfomation: PackingImageSub,
                ExFileUploadinformation: ExternalFileUploads,
                FinalinspecPackingImagesinfomation: packingImagetab,
                MeasurementCheckedPeices: getMeasurementFileList !== undefined &&
                    getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices !== '' ?
                    parseInt(getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices) : 0,
                TNAFinalinsMeasurementMainId: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].TNAFinalinsMeasurementMainId : 0,
                StyleMeasurementID: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].StyleMeasurementID : 0,
                MeasurementSpec: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].MeasurementSpec : '',
                FinalinspecMeasurementCategoryinformation: MeasurmentCategory,
                FinalinspecMeasurementPointsformation: MeasurmentPoints,
                FinalinspecMeasurementFilesinformation: MeasurmentFiles,
                CreatedBY: currentUser.employeeinformationID,
                Isproduction: props.location.state.params.Isproduction,
                StyleID: props.location.state.params.styleID,
                SaveStatus: props.location.state.params.type === 1 ?
                    e === "Draft" ? 1 : (e === 'Save' && getSummaryvalue[1] === undefined) ? 2 : (e === 'Save' && getSummaryvalue[1].result === "Within AQL") ? 3 : 4 :
                    e === "Draft" ? 1 : (e === 'Save' && getMainInputField.ExAqlResultID === 0) ? 2 : (e === 'Save' && getMainInputField.ExAqlResultID === 1) ? 3 : 4,
                TypeInterOrExtern: props.location.state.params.type,
                ExAgencyName: getMainInputField.ExAgencyName,
                ExReportNo: getMainInputField.ExReportNo,
                ExAqlResultID: getMainInputField.ExAqlResultID,
                ExAqlResult: getMainInputField.ExAqlResult,
                ExRemarks: getMainInputField.ExRemarks,
                ExInspectorConclusion: getMainInputField.ExInspectorConclusion,
                FinalInspectionIds: props.location.state.params.FinalInspectionIds !== undefined ?
                    props.location.state.params.FinalInspectionIds : '',
            }
            FinalinspectionService.InsertFinalInspectionGrid(Inputmaster).then((res) => {
                if (res.data.outputResult === "1") {

                    Nodify('Success!', 'success', 'Draft saved Successfully');
                    let params = { tabPage: "MainInfo", Parentask: props.location.state.params.ParentTask };
                    setAddStyleConfirmation({ showPopupConfirm: true, Params: params });
                    setInspectionID(parseInt(res.data.outputResultStyleID))
                    if ((props.location.state.params.Mode === 'InspectionAdd' ||
                        props.location.state.params.Mode === 'ReInspection')) {
                        localStorage.setItem('InspectionID', (res.data.outputResultStyleID));
                    }
                    localStorage.removeItem("Exfacconfirm");
                    setRefreash(true);
                    hideLoader();
                } else if (res.data.outputResult === "2") {

                }
                else if (res.data.outputResult === "0") {
                    //setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }
    return (
        <Fragment>
            <div className="page-body">
                <ReactNotification />
                {/* {getCurrentTab !== "Tab_MainInformation" ? <div className="row">
                    <div className="col-md-12">
                        <div className="widget-body" style={{ paddingBottom: "0" }}>
                            {

                                <>
                                    <div className="">
                                        <CommonStyle props={props} Style={getStyleInfo} />
                                        {/* <CommonInspectionStyle props={props} Style={getStyleInfo} /> */}
                                    {/* </div>
                                </>
                            }
                        </div>
                    </div>
                </div> : ''}
                <br /> */} 
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">
                                    {
                                        props.location.state.params.Mode === 'ReInspection' ?
                                            <>
                                                {
                                                    props.location.state.params.IsAddorEditReinspection + ' Re Inspection'
                                                } - {getInspectionType == 1 ? "Internal" : "External"}
                                            </> :
                                            <>
                                                {
                                                    (props.location.state.params.pageType === 'AddInspection' && getkeyinfo === 1) ? "Edit " :
                                                    props.location.state.params.pageType === 'AddInspection' ? "ADD " :
                                                        props.location.state.params.pageType === "ViewInspection" ? 'View ' :
                                                            'Edit '}
                                                Inspection - {getInspectionType == 1 ? "Internal" : "External"}
                                            </>
                                    }

                                </h3>
                                <div className="buttons-preview" align="right" style={{ marginTop: "0rem" }}>
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>
                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        }>
                                        <i className="fa fa-refresh"></i>
                                        &nbsp;Reset</span>
                                    <span className="btn btn-warning" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        onClick={
                                            () => SaveMainInformation("Draft")
                                        }>
                                        <i className="fa fa-rocket"></i>
                                        &nbsp;Draft</span>
                                    {!buttonLoader && <span className="btn btn-success" disabled={buttonLoader}
                                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        onClick={
                                            () => SaveMainInformation("Save")
                                        }>
                                        <i className="fa fa-check"></i>
                                        &nbsp;Save</span>}
                                </div>
                            </div>

                            <div className="widget-body StyleHistoryCard">
                                <div className="row">
                                    {(props.location.state.params.pageType !== "AddInspection" || getkeyinfo ===1) ? <div className="row">
                                        <div className="col-md-12">
                                            <div className="widget-body" style={{ paddingBottom: "0" }}>
                                                {

                                                    <>
                                                        <div className="">
                                                            {/* <CommonStyle props={props} Style={getStyleInfo} /> */}
                                                            <CommonStyleInfo props={props} Style={getInspectionInfo} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div> : ''}
                                    <br />
                                    <div className="col-md-12">
                                        <div className="tabbable">
                                            <ul className="nav nav-tabs nav-justified" id="myTab5">
                                                <li className="active">
                                                    <a data-toggle="tab" href="#idMainInformation" onClick={() => TabRedirection('Tab_MainInformation')}>
                                                        <h5>Main Information </h5>
                                                    </a>
                                                </li>
                                                {
                                                    getInspectionType === 1 ?
                                                        <li>
                                                            <a data-toggle="tab" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} href="#idDefects" onClick={() => TabRedirection('Tab_Defects')}>
                                                                <h5>Defects </h5>
                                                            </a>
                                                        </li> : ""
                                                }

                                                {
                                                    getInspectionType === 1 ?
                                                        <li>
                                                            <a data-toggle="tab" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} href="#idMeasurement" onClick={() => TabRedirection('Tab_Measurement')}>
                                                                <h5>Measurement </h5>
                                                            </a>
                                                        </li> : ""
                                                }
                                                {
                                                    getInspectionType === 1 ?
                                                        <li>
                                                            <a data-toggle="tab" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} href="#idPackingImages" onClick={() => TabRedirection('Tab_PackingImages')}>
                                                                <h5>Packing Images </h5>
                                                            </a>
                                                        </li> : ""
                                                }
                                                {
                                                    getInspectionType === 1 ?
                                                        <li>
                                                            <a data-toggle="tab" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} href="#idConclusion" onClick={() => TabRedirection('Tab_Conclusion')}>
                                                                <h5>Conclusion </h5>
                                                            </a>
                                                        </li> : ""
                                                }

                                                <li>
                                                    <a data-toggle="tab"
                                                        //style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }}
                                                        href="#idQcNotes"
                                                        onClick={() => TabRedirection('Tab_QC')}>
                                                        <h5>QA Notes </h5>
                                                    </a>
                                                </li>

                                            </ul>
                                            <div className="tab-content">
                                                <div id="idMainInformation" className="tab-pane active" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_MainInformation" && <FinalInspectionMainInformation props={props} BasicDetails={props.location.state.params}
                                                        Inspectioncallback={Inspectioncallback} MainInfoMation={Mainfomacallback} Quantityinfo={QuantityCallback}
                                                        QuantitySubInfo={QuantitySubInfoCallback} Confomationinfo={Confocallback} Stylecolor={StylecolorCallback}
                                                        ArtworkInfo={ArtworkCallback} TrimInfo={TrimInfoCallback} Workmainshipinfo={workmanshipCallback} TestRepotCheck={TestRepotcallback}
                                                        CSRInfo={CSRCallback} Imagevalue={imagevaluecallback} totalvalue={Totalvalcallback} MajorminorLvl={MajorminorLvlcallback} InspectionID={getInspectionID}
                                                        IsValidationCallback={IsParentCallback} Savevalidationcallback={IsSaveValidation} PackingClback={packingcallback} RefreshState={getRefreah} ExternalFiles={ExternalFilecallback}
                                                        rerenderdep={refershstate} conformdata={Isconfirmdata}
                                                    />}
                                                </div>
                                                <div id="idDefects" className="tab-pane" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_Defects" ? <><FinalInspectionDefects props={props}
                                                        InspectionID={getInspectionID} majorminlvl={getMajorminlvl} DefectCallback={DefectCallback} Summarycallback={Summarycallback}
                                                        FindingRemarksCalls={FindingRemarksCallback} Savevalidationcallback={IsSaveValidationDefect} rerenderdep={refershstate} /></> : ''}
                                                </div>
                                                <div id="idMeasurement" className="tab-pane" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_Measurement" ? <><FinalInspectionMeasurement props={props} Messurecallback={Messurecallback} Savevalidationcallback={IsSaveValidation} rerenderdep={refershstate} /></> : ''}
                                                </div>
                                                <div id="idPackingImages" className="tab-pane" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_PackingImages" ? <><FinalInspectionPackingImages props={props}
                                                        InspectionID={getInspectionID} PackingImageCallback={PackingImagecallback} BasicDetails={props.location.state.params} rerenderdep={refershstate} /></> : ''}
                                                </div>
                                                <div id="idConclusion" className="tab-pane" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_Conclusion" ? <><FinalInspectionConclusion props={props} Conclusioncallback={ConclusionCallback} rerenderdep={refershstate} /></> : ''}
                                                </div>
                                                <div id="idQcNotes" className="tab-pane" style={{ width: "100%" }}>
                                                    {getCurrentTab === "Tab_QC" ? <><QcNotes props={props} /></> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    addStyleConfirmation.showPopupConfirm ?
                        <SweetAlert
                            show={addStyleConfirmation.showPopupConfirm}
                            //warning
                            info
                            showCancel
                            showConfirm={true}
                            confirmBtnText={confirmBtnText}
                            cancelBtnText={cancelBtnText}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="primary"
                            closeOnClickOutside={true}
                            inputType={'new'}
                            // closeOnConfirm
                            // closeOnCancel
                            title={addStyleConfirmation.Params.Parentask === "TNAEdit" ? "Do you want to Redirect to TNA Edit" : "Do you want to Redirect to Final Inspection - List Page"}
                            onConfirm={copystyle}
                            onCancel={handleClose}
                            showCloseButton
                            focusCancelBtn
                        // style={{ backgroundColor: 'blue', color: 'white' }}
                        /> : ''

                }
                <div className="buttons-preview" align="right" style={{ marginTop: "4rem" }}>
                    <span className="btn btn-primary"
                        onClick={
                            () => ResetOperation("Back")
                        }>
                        <i className="fa fa-arrow-circle-left"></i>
                        &nbsp;Back</span>
                    <span className="btn btn-danger"
                        onClick={
                            () => ResetOperation()
                        }>

                        <i className="fa fa-refresh"></i>
                        &nbsp;Reset</span>
                    <span className="btn btn-warning"
                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                        onClick={
                            () => SaveMainInformation("Draft")
                        }>
                        <i className="fa fa-rocket"></i>
                        &nbsp;Draft</span>
                    {!buttonLoader && <span className="btn btn-success" disabled={buttonLoader}
                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                        onClick={
                            () => SaveMainInformation("Save")
                        }>
                        <i className="fa fa-check"></i>
                        &nbsp;Save</span>}
                </div>
            </div>
            {loader}
        </Fragment>
    )
};
export default CreateFinalInspection;