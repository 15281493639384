import React, { useState, useEffect, useMemo, useRef,Fragment } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import { LoadReFinalInspectionList, LoadFlDetailsreport, UpdateCommentsreportViewAllCheckBox, UpdateCommentsreportViewCheckBox } from "../../../../actions/finalInspection";
import Nodify from "../../../Common/ReactNotification";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import { Pagination, Search, PageCount, ReactSearch } from "../../../Datatable";
import TableHeader from '../../../Datatable/Header/commetstyle'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReportService from "../../../../services/Report/ReportService";

import * as XLSX from 'xlsx';
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FLReportSkuComments, FLReportSkuOrderStatus, FinalinspectionID, FinalReinspectioncmt, FLApprovalComment } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import AddInspection from "../../../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "../../../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadTNADetailList, GetSubChildMultExFactoryFormSKUList } from "../../../../actions/tna";

const ReFinalInspection = ({ props }) => {
    //)

    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [getheaderName, setheaderName] = useState("");
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
    const [selectstatus, setselectstatus] = useState({ value: 3, label: "Minor" });
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
    const [getInspectionInfo, setInspectionInfo] = useState({});
    // const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getIDfinalEntry, setIDfinalEntry] = useState({ showPopupDelete: false, Params: {} });
    const [getIDcomment, setIDcomment] = useState({ showPopupDelete: false, Params: {} });
    const [checkboxStates, setCheckboxStates] = useState({
        CreatedDate: true,
        EntryNo: true,
        InspectionType: true,
        ReInspectionDoneAgainst: true,
        Brand: true,
        Season: true,
        Supplier: true,
        Factory: true,
        CustomerShipmentRefNo: true,
        IDNo: true,
        StyleName: true,
        StyleNo: true,
        SKUName: true,
        InspectionQuantity: true,
        InspectionResult: true,
        CreatedBy: true,
        ApprovalResult: true,
        TypeInterOrExtern: true
    });


    const [getHideID, setHideID] = useState({
        Po: true,
        season: true,
        Quarter: true,
        brand: true,
        customer: true,
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,

    });
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [checked, setchecked] = useState('0');
    const [table, settable] = useState('0');
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(true);
    const [seasoncheck, setseasoncheck] = useState(true)
    const [Quartercheck, setQuartercheck] = useState(true)

    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [skuqtylist, setskuqtylist] = useState([]);
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    // FOR COMMENTS
    const [Commentpopup, setCommentpopup] = useState(false);
    const [getComment, setComment] = useState([])
    //const [getHeaderName, setHeaderName] = useState("");
    const [getpurchaseId, setPurchaseId] = useState("0");
    const [getSupplierID, setSupplierID] = useState("0")
    const [getindex, setindex] = useState(0);
    const [getExfacindex, setExfacindex] = useState(0);
    const [getFIRemark, setFIRemark] = useState([])
    const [getExFactoryList, setExFactoryList] = useState([])
    const [getApprovalList, setApprovalList] = useState([])
    const [Ecomment, setFIRemarks] = useState();
    const [Approvallist, setExfacList] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    //End Comments
    const [getFiles, setFiles] = useState([])
    //For OrderStatus
    const [selectedOptionStatus, setSelectedOptionsStatus] = useState({ value: 0, label: 'Select Order Status' })
    const [selectedExfactory, setSelectedExfactory] = useState()
    const [getSelectedApproval, setSelectedApproval] = useState()
    const [getExfactoryModal, setExfactoryModal] = useState(false);
    const [getorderStatus, setorderStatus] = useState([]);
    const [getordindex, setOrdindex] = useState(0);
    //End OrdStatus

    const EmptyInputFields = [];

    const [inputFields, setInputFields] = useState(EmptyInputFields);

    const Input = { ...inputFields };

    // PO/season/Quarter
    //const [getHideID, setHideID] = useState({ Po: true, season: true, Quarter: true });
    const Values = { ...getComment }
    const OrdStatus = { ...showmodal }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

         //Enable Menu
         let activeMenu;
         let activeMenuSub;
         let InternalComments;
         let info;
         const menulist = useSelector((state) => state.common.MenuList.item3);
         const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
         if (menulist !== undefined) {
             info = IsProduction === 1 ? 'InternalFinalInspection' : (IsProduction === 2 ? 'InternalFinalInspection' : undefined);
             activeMenu = menulist.filter(i => i.menuName ===  info );
             
             if (activeMenu.length > 0) {
                 activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
                 if (activeMenuSub.length > 0) {
                     for (let index = 0; index < activeMenuSub.length; index++) {
                        if (activeMenuSub[index].menuName === 'InspectionReIns') 
                            { InternalComments = activeMenuSub[index];}
                         //InternalComments = activeMenuSub[index];
                     }
                 }
             }
             
         }
         // End Enable Menu


    const [openReInspopup, setopenReInspopup] = useState(false);
    const [ReInspectionDetailList, setReInspectionDetailList] = useState([]);
    const [selectall, setselectall] = useState(false);

    let InspectionandApproval = [
        {
            value: 1,
            label: 'Re Inspection Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'Partially Completed',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Re Inspection Completed',
            selected: false,

        },
        {
            value: 5,
            label: 'Balance for Re Inspection',
            selected: false,

        }
    ]



    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultApStartDate = new Date();
    const [stateApDate, setStateApDate] = useState([
        {

            startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);



    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    let IsProductionStatus = [
        {
            value: 1,
            label: 'Sampling',
            selected: true,

        },
        {
            value: 2,
            label: 'Production',
            selected: false,

        }
    ]
    const datePickerRefIns= useRef(null);
  
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRefIns.current && !datePickerRefIns.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
           
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    async function delApprovalCallback(value) {

        if (value) {
            let StartDate = null;
            let EndDate = null;

            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            if (moment(stateApDate[0].startDate).isValid()) {
                StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

            FinalinspectionService.GetFIApprovalHistory(getheaderName[0].FinalInspectionID).then(async (response) => {
                if (response.data) {
                    setFIRemark(response.data)
                }
                hideLoader();
            }).catch(() => { hideLoader(); });
            await FinalinspectionService.LoadGetFLApproval().then((res) => {
                if (res.data) {
                    res.data.map((x) => {
                        Approvallist.push({
                            value: x.value,
                            label: x.label
                        })
                    })
                    setApprovalList(Approvallist);
                }
                hideLoader();
            })
            setCommentpopup(false)
            dispatch(LoadReFinalInspectionList(params));
        }
        else {
            setIDcomment({ showPopupDelete: false, Params: [] });
        }
    }


    const delCallbackfinalins = (value) => {

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        if (value) {
            //dispatch(LoadFlDetailsreport(params));
            dispatch(LoadReFinalInspectionList(params));
            setCommentpopup(false)
        } else {
            setIDfinalEntry({ showPopupDelete: false, Params: [] });
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }

    async function delCallback(value) {

        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartExDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndExDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        if (value) {

            await FinalinspectionService.GetFLPurchaseOrderSkuReportComments(getpurchaseId, 1).then(async (response) => {
                if (response.data) {
                    setFIRemark(response.data.flReportCommentDetails)
                    //setFiles(response.data.flReportfileupload)
                }
                if (response.data.flReportCommentDetails.length === 0) {
                    setCommentpopup(false)
                }
            }).catch(() => { });

            dispatch(LoadFlDetailsreport(params));
        } else {
            setID({ showPopupDelete: false, Params: [] });
        }
    }


    const handleSaveApproval = (e, comment, FinalInspectionID) => {
        let len = 0;
        if (comment === undefined || comment === '') {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // // var Data = [];
            var CommentInformationData = [];
            len = len + 1;
            CommentInformationData.push({
                ReinpsectionCommentsID: 0,
                TNAID: 0,
                InspectionId: parseInt(FinalInspectionID),
                CommentDetail: Ecomment,
                //IsLatest : 1
                CommentIndex: len,
            });
            var data = { CreatedBy: currentUser.employeeinformationID, ReinspectionCommentInformation: CommentInformationData };

            FinalinspectionService.InsertUpdateReinspectionComment(data).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added for " + FinalInspectionID + " Successfully");
                    setCommentpopup(false);
                    setFIRemarks('');
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }

                    let StartApDate = null;
                    let EndApDate = null;
                    if (moment(stateApDate[0].startDate).isValid()) {
                        StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApDate[0].endDate).isValid()) {
                        EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    let Params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
                    setSelectedApproval("");
                    dispatch(LoadReFinalInspectionList(Params));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

        //setEditedCommentIndex('');
    };

    useEffect(() => {

//////////////// multi Filter


            const buyer = localStorage.getItem("Buyer2")
            const season = localStorage.getItem("Season2")
            const brand = localStorage.getItem("Brand2")
            const quarter = localStorage.getItem("Quarter2")
            const supplier = localStorage.getItem("Supplier2")
            const poidno = localStorage.getItem("ID/NO2")
            const endCustomer = localStorage.getItem("EndCustomer2")
            const category = localStorage.getItem("Category2")
            const stylename = localStorage.getItem("StyleName2")
            const styleno = localStorage.getItem("StyleNo2")
            const skuname = localStorage.getItem("SKUName2")
            const factory = localStorage.getItem("Factory2")
            const orderStatus = localStorage.getItem("OrderStatus2")

            if (skuname !== null && skuname !== "") {
                if (skuname) {
                    setSkuSearch(skuname);
                }
                else {
                    localStorage.removeItem('SKUName2');
                    setSkuSearch("");
                }
            }
            if(buyer !== null && buyer !== ""){
                if (buyer) {
                    setBuyerSearch(buyer)
                }
                else {
                    localStorage.removeItem('Buyer2');
                    setBuyerSearch("");
                }
            }
            if(season !== null && season !== ""){
                
                if (season) {
                    setSeasonSearch(season)
                }
                else {
                    localStorage.removeItem('Season2');
                    setSeasonSearch("");
                }
            }
            if(brand !== null && brand !== ""){
                
                if (brand) {
                    setBrandSearch(brand)
                }
                else {
                    localStorage.removeItem('Brand2');
                    setBrandSearch("");
                }
            }
            if(quarter !== null && quarter !== ""){
                
                if (quarter) {
                    setQuarterSearch(quarter)
                }
                else {
                    localStorage.removeItem('Quarter2');
                    setQuarterSearch("");
                }
            }
            if(supplier !== null && supplier !== ""){
                
                if (supplier) {
                    setsupplierSearch(supplier)
                }
                else {
                    localStorage.removeItem('Supplier2');
                    setsupplierSearch("");
                }
            }
            if(poidno !== null && poidno !== ""){
                
                if (poidno) {
                    setIdPoSearch(poidno)
                }
                else {
                    localStorage.removeItem('ID/NO2');
                    setIdPoSearch("");
                }
            }
            if(endCustomer !== null && endCustomer !== ""){

                if (endCustomer) {
                    setEndCustomerSearch(endCustomer)
                }
                else {
                    localStorage.removeItem('EndCustomer2');
                    setEndCustomerSearch("");
                }
            }
            if(category !== null && category !== ""){
                
                if (category) {
                    setCategorySearch(category)
                }
                else {
                    localStorage.removeItem('Category2');
                    setCategorySearch("");
                }
            }
            if(stylename !== null && stylename !== ""){
                
                if (stylename) {
                    setStyleNameSearch(stylename)
                }
                else {
                    localStorage.removeItem('StyleName2');
                    setStyleNameSearch("");
                }
            }
            if(styleno !== null && styleno !== ""){
                
                if (styleno) {
                    setStyleNoSearch(styleno)
                }
                else {
                    localStorage.removeItem('StyleNo2');
                    setStyleNoSearch("");
                }
            }
            if(factory !== null && factory !== ""){
                
                if (factory) {
                    setFactorySearch(factory)
                }
                else {
                    localStorage.removeItem('Factory2');
                    setFactorySearch("");
                }
            }
            if(orderStatus !== null && orderStatus !== ""){
                
                if (orderStatus) {
                    setOrderStatusSearch(orderStatus)
                }
                else {
                    localStorage.removeItem('OrderStatus2');
                    setOrderStatusSearch("");
                }
            }
        ///////////////////////

        if (props.location.state !== null) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            if (moment(stateApDate[0].startDate).isValid()) {
                StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
            dispatch(LoadReFinalInspectionList(params));
        }
    }, [dispatch]);

    const FinalInspectionList = reducerState.fldetails.ReFinalInspectionList !== undefined ? reducerState.fldetails.ReFinalInspectionList : reducerState.fldetails.ReFinalInspectionList;
    const isLoadingFlDetailsReport = reducerState.fldetails.isLoadingFlDetailsReport;
    // loader
    useEffect(() => {

        if (!isLoadingFlDetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingFlDetailsReport]);


    const handleChanges = (value) => {
        setSelectedOptions(value);
        // setComment(Values)
    };


    // User Location
    const [getIsShowGeoLocModal, setgetIsShowGeoLocModal] = useState(false);
    const [getCurrentLocation, setCurrentLocation] = useState({
        Latitude: '',
        Longitude: ''
    });

    function RemoveOrderStatus(Commentitem) {

        let Param = {
            Operation: FLReportSkuOrderStatus,
            Id: Commentitem.statusId,
        };
        setOrdStatusID({ showPopupDelete: true, Params: Param });
    }

    const handleOnSelect = (event, FieldName, purchaseOrderSkuID) => {
        let isChecked = false;
        let indexes = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));
    }


    const GetStatus = (PurchaseOrderSkuID, Feildname) => {
        showLoader();
        setPurchaseId('');
        setOrdindex(0);
        setShowmodal(true);
        setPurchaseId(PurchaseOrderSkuID);
        let isChecked = false;
        let indexes = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
        setOrdindex(indexes)
        let ordStatus = { ...selectedOptionStatus }

        if (Feildname === 'OrderStatus') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));

        FinalinspectionService.GetFLPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 2).then((response) => {
            if (response.data) {
                setorderStatus(response.data.flReportStatus)
                ordStatus.value = 0;
                ordStatus.label = '-Select Order Status-';
                setSelectedOptionsStatus(ordStatus)
            }
            else {
                setSelectedOptionsStatus(ordStatus)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });

    }



    const GetComments = async (comment) => {
       ;
        showLoader();
        let Approvallist = [];
        let Approval = [{ FinalInspectionID: comment.finalInspectionID, InsCreatedDate: comment.insCreatedDate }]
        setheaderName(Approval);
        setCommentpopup(true);
        FinalinspectionService.GetReinspectionCommentsList(comment.finalInspectionID).then(async (response) => {
            if (response.data) {
                setFIRemark(response.data)
                hideLoader();
            }
        }).catch(() => { hideLoader(); });
    }


    // const StatusClose = (Feildname) => {
    //     let isChecked = false;
    //     if (Feildname === 'ordStatus') {
    //         isChecked = false;
    //     } else {
    //         setIsAllSelected(false);
    //     }
    //     let params = {
    //         isChecked: isChecked, index: getordindex
    //     };
    //     dispatch(UpdateCommentsreportViewCheckBox(params));
    //     setShowmodal(false);
    //     setSubmitted(false);
    // }
    let Size = '115px';
    const tableHeader = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize:'3%',
            left: 0,

        },
        checkboxStates.CreatedDate && (
            {
                name: "Entry No / Date",
                field: "insCreatedDate",
                sortable: true,
                widthsize: Size,
                left: 0,

            }
        )
        // ,
        // checkboxStates.EntryNo && ({
        //     name: "Entry No",
        //     field: "finalInspectionID",
        //     sortable: true,
        //     widthsize: "3%",
        //     left: "120px",

        // })

        // , checkboxStates.InspectionType && ({
        //     name: "Inspection Type",
        //     field: "inspectionType",
        //     sortable: true,

        //     widthsize: "4%",
        //     left: "240px",

        // })
        // ,
        // checkboxStates.TypeInterOrExtern && ({
        //     name: "Internal/External",
        //     field: "typeInterOrExtern",
        //     sortable: true,
        //     widthsize: "5%",
        //     left: "120px",

        // })
        // , checkboxStates.ReInspectionDoneAgainst && ({
        //     name: "Re Inspection Done Against",
        //     field: "",
        //     sortable: true,
        //     width: "10%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "360px",
        //     //background: "#222d32",
        // })
        // , checkboxStates.Brand && ({
        //     name: "Brand",
        //     field: "brandName",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "480px",
        //     //background: "#222d32",
        // })
        // , checkboxStates.Season && ({
        //     name: "Season",
        //     field: "seasonName",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "600px",
        //     //background: "#222d32",
        // })
        , checkboxStates.Supplier && ({
            name: "Supplier / Factory",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: Size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        })
        // , checkboxStates.Factory && ({
        //     name: "Factory",
        //     field: "factory",
        //     sortable: true,
        //     //width: "4%",
        //     widthsize: "120px",
        //     // position: "sticky",
        //     left: "858px",
        //     //background: "#222d32",
        // })
        , checkboxStates.CustomerShipmentRefNo && ({
            name: "Ship Ref No",
            field: " {comment.factory}",
            sortable: true,
            //width: "5%",
            widthsize: Size,
        })
        // , checkboxStates.IDNo && ({
        //     name: "ID No",
        //     field: "idpono",
        //     sortable: true,
        //     widthsize: "120px",
        //     //width: "4%",
        // })
        // , checkboxStates.IDNo && ({
        //     name: "FO PO No",
        //     field: "foidpono",
        //     sortable: true,
        //     widthsize: "120px",
        //     //width: "4%",
        // })
        , checkboxStates.StyleName && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: Size,
            //width: "7%",
        })
        // , checkboxStates.StyleNo && ({
        //     name: "Style No",
        //     field: "styleNo",
        //     sortable: true,
        //     widthsize: "120px",
        //     // width: "7%",
        // })
        , checkboxStates.SKUName && ({
            name: "SKU Name",
            field: "skuName",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.IDNo && ({
            name: "ID No",
            field: "idpono",
            sortable: true,
            widthsize: Size,
            //width: "4%",
        })
        //  , checkboxStates.InspectionQuantity && ({
        //     name: "Order Qty",
        //     field: "inspectionQty",
        //     sortable: true,
        //     widthsize: Size,
        //     // width: "5%",
        // })


        , checkboxStates.InspectionQuantity && ({
            name: "Ins Qty",
            field: "inspectionQty",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.InspectionResult && ({
            name: "Ins Result",
            field: "inspectionResult",
            sortable: true,
            widthsize: Size,
            // width: "4%",
        })

        // , checkboxStates.CreatedBy && ({
        //     name: "Created By",
        //     field: "createdBy",
        //     sortable: true,
        //     widthsize: "4%",
        //     // width: "2%",
        // })

        , checkboxStates.ApprovalResult && ({
            name: "App Result",
            field: "approvalResult",
            sortable: true,
            widthsize: Size,
            // width: "2%",
        }),
        {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            //width: "120px",
            widthsize: Size,
             display: activeMenu && activeMenu[0].isEdit === 0 &&
            activeMenu[0].isDelete === 0 ? 'none' : ''
            //left: "736px",
        }
    ];

    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };








    const handleAllOnSelect = (event, FieldName, page, total) => {

        let Pagecount = parseInt(page);
        if (event.target.checked) {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = true;
                })
                setIsAllSelected(true);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(true));

        } else {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = false;
                })
                setIsAllSelected(false);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(false));
        }
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateDate[0].isShow = isShow;
        getStateApDate[0].isShow = false;
        setStateApDate(getStateApDate);
        setStateDate(getStateDate);
    }
    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateApDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateApDate(getStateApDate);
    }

    // const handleInsDateSelect = (event, isShow) => {

    //     let getStateDate = [...stateDate];
    //     let getStateInsDate = [...stateInsDate];
    //     getStateInsDate[0].isShow = isShow;
    //     getStateDate[0].isShow = false;
    //     setStateDate(getStateDate);
    //     setStateInsDate(getStateInsDate);
    // }

    // const handleTNADateSelect = (event, isShow) => {

    //     let getStateDate = [...stateDate];
    //     let getStateTNADate = [...stateTNADate];
    //     getStateTNADate[0].isShow = isShow;
    //     getStateDate[0].isShow = false;
    //     setStateDate(getStateDate);
    //     setStateTNADate(getStateTNADate);
    // }





    function DateExfaconchange(item) {

        stateApDate[0].startDate = item[0].startDate;
        stateApDate[0].endDate = item[0].endDate
        setStateApDate(stateApDate)

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReFinalInspectionList(params));
    }

    function DateRangechange(item) {

        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReFinalInspectionList(params));
    }

    // function DateInsfaconchange(item) {


    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //         StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //         EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }


    // }

    // function DateTNAchange(item) {



    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //         StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //         EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }



    // }




    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }


        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReFinalInspectionList(params));
    }
    const clearExDateSelect = () => {
        // var defaultApStartDate = new Date();
        // const emptyStateExDate = ([
        //     {
        //         startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
        //         endDate: new Date(),
        //         key: 'selection',
        //         isShow: false
        //     }
        // ]);
        //setStateApDate(emptyStateExDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(NewemptyStateExDate[0].startDate).isValid()) {
            StartApDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(NewemptyStateExDate[0].endDate).isValid()) {
            EndApDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReFinalInspectionList(params));
    }

    // const clearInsDateSelect = () => {
    //     const emptyStateDate = [
    //         {
    //             startDate: null,
    //             endDate: new Date(""),
    //             key: 'selection',
    //             isShow: false
    //         }];
    //     setStateInsDate(emptyStateDate);
    // }

    // const clearTNADateSelect = () => {
    //     const emptyStateDate = [
    //         {
    //             startDate: null,
    //             endDate: new Date(""),
    //             key: 'selection',
    //             isShow: false
    //         }];
    //     setStateTNADate(emptyStateDate);
    // }


    const commentsData = useMemo(() => {
       ;
        let computedComments = FinalInspectionList;
        let drpvalue = orderStatus;

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment => {
                comment.customerName == null ? comment.customerName = "" : comment.customerName = comment.customerName;
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase());
            }
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        // if (getBuyerEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }
        // if (getCubeEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }

        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                    // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            } else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }
            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.insCreatedDate, format)) >= stateDate[0].startDate && new Date(moment(comment.insCreatedDate, format)) <= stateDate[0].endDate
            )
        }

        setTotalItems(computedComments.length);
        // // let Exformat = "DD/MM/YYYY";
        // // let StartExDate = null;
        // // let EndExDate = null;
        // // if (moment(stateApDate[0].startDate).isValid()) {
        // //     StartExDate = new Date(moment(stateApDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //StartDate.setMinutes(StartDate.getMinutes() + 370);
        // // }
        // // if (moment(stateApDate[0].endDate).isValid()) {
        // //     EndExDate = new Date(moment(stateApDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //EndDate.setMinutes(EndDate.getMinutes() + 370);
        // // }

        // // if (stateApDate[0].startDate && stateApDate[0].endDate) {
        // //     computedComments = computedComments.filter(comment =>
        // //         new Date(moment(comment.exFacDate, Exformat)) >= stateApDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateApDate[0].endDate
        // //     )
        // // }
        // // setTotalItems(computedComments.length);

        //sorting table
        if (sorting.field === "typeInterOrExtern") {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field] - (b[sorting.field])));
        }
        else if (sorting.field) {
            computedComments.map((x) => {
                x.finalInspectionID = x.finalInspectionID.toString();
            })
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            FinalInspectionList,
            currentPage,
            // search,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateApDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);



    const handleChangeRemarks = (index, e, FieldName) => {
        //  setSubmitted(false);
        let inputText = '';
        if (FieldName === "Remarks") {

        }
        else if (FieldName === "ReceivedDate") {

        }
        // setRemarksList(values);
        // remarkslist(values);// call back to parent

    }

    const resetFinalIns = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultInspectionStartDate = new Date();
        const emptyStateInsDate = ([
            {

                startDate: defaultInspectionStartDate.setDate(defaultInspectionStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultTNAStartDate = new Date();
        const emptyStateTNADate = ([
            {

                startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        setStateDate(emptyStateDate);
        setStateApDate(emptyStateExDate);

        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(emptyStateExDate[0].startDate).isValid()) {
            StartApDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(emptyStateExDate[0].endDate).isValid()) {
            EndApDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReFinalInspectionList(params));
        //EmptyCheck();
    }
    const handleChangeOrdStatus = (value) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);
        setOrderStatusSearch(value);
        //setIsProductionStatussearch(value);
    }
    const handleclick = () => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const ViewFile = (event, filename) => { // event.preventDefault();

        // let arr;
        // arr = filename.split(',')
        // for (let sku of arr) {
        //
        // }
        setIsOpenimg(true);
        setImagename(filename);
    }
    function RemoveComments(Commentitem) {

        let Param = {
            Operation: FLReportSkuComments,
            Id: Commentitem.commentsID,
        };
        setID({ showPopupDelete: true, Params: Param });
    }

    const filevals = [...getFiles]
    const AppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "ReportStatus");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    let Index = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
                    filevals.push({
                        reportManualFileId: 0,
                        fileName: res.data + "_" + file.name,
                        filePath: "Images/Report/" + file.name,
                        fileType: file.type,
                        reportIndexName: Index.toString(),
                        reportFileIndexName: getFiles.length.toString(),
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    // let Split = getFiles[0].FileName.split("_");
                    // getFiles[0].FileName = Split[1];
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveImages = async (index, files, ReportManualFileId) => {

        if (ReportManualFileId !== 0) {
            getFiles[index].IsDeleted = 1;
        } else {
            filevals.splice(index, 1);
        }
        setFiles(filevals);
    }

    const handleFullClose = (Feildname, comment) => {

        // if (getSelectedApproval) {
        //     getSelectedApproval.value = 0;
        //     getSelectedApproval.label = '';
        //     setSelectedApproval(getSelectedApproval);
        // }
        // let isChecked = false;
        // if (Feildname === 'reportcmt') {
        //     isChecked = false;
        // } else {
        //     setIsAllSelected(false);
        // }
        // let params = {
        //     isChecked: isChecked, index: getindex
        // };
        // if (comment === undefined || comment === '') {
        //     setSubmitted(false);
        // }
        // if (getFiles.reportManualFileId === 0) {
        //     setFiles([]);
        // }
        // dispatch(UpdateCommentsreportViewCheckBox(params));
        setCommentpopup(false);
        setFIRemark([]);
        setFIRemarks('');


    }
    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setFIRemarks(inputtext);

    }
    function handleDeleteRefinalinspection(comment) {

        let PackingParams = {
            Operation: 128,
            Id: comment.reinpsectionCommentsID,
        }
        setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
    }
    function handleCmtEXFactoryChanges(value) {

        setSelectedApproval(value)
    }

    const handleChangeIsProductionStatus = (value) => {
        ;
        if (value === null) {
            value = { label: "Sampling", selected: true, value: 1 }
        }

        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartExDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndExDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadFlDetailsreport(params));
    }
    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer2' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            localStorage.setItem('Buyer2', inputText)
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleNo2', inputText)
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleName2', inputText)
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Season2', inputText)
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Brand2', inputText)
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('ID/NO2', inputText)
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Category2', inputText)
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Supplier2', inputText)
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('EndCustomer2', inputText)
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }

            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('SKUName2', inputText)
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Factory2', inputText)
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            setIsProductionStatussearch(inputText);
        }


        else if (filterName === 'Quarter2') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Quarter2', inputText)
            setQuarterSearch(inputText);
        }
    };



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }

    // const GetSKUandQty = (PurchaseOrderSkuID,) => {
    //     ;
    //     ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
    //         ;
    //         if (response.data) {
    //             setskuqtylist(response.data)
    //         }

    //     }).catch(() => { });
    //     setsizeqtypopup(true);

    // }
    const GetSKUandQty = (finalInspectionID) => {
        //setskuid('');
        FinalinspectionService.GetPurchaseOrderSKUQty(finalInspectionID).then((response) => {
            if (response.data) {

                setskuqtylist(response.data)
            }
        }).catch(() => { });
        setsizeqtypopup(true);
        //setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }
    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    const handleCloseExFactoryFormPopup = () => {
        Input.IsExFactoryForm = 0;
        setInputFields(Input);
    };

    const AddReInspection = () => {
        const Values = [...ReInspectionDetailList]

        let selectedInspIds = '';
        Values.map(x => {
            if (x.isSelected === 1) {
                if (selectedInspIds === '') {
                    if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                        selectedInspIds = x.tnaFinalInspectionID.toString();
                    }
                }
                else {
                    if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                        selectedInspIds = selectedInspIds + "," + x.tnaFinalInspectionID.toString();
                    }
                }
            }
        })

        let uniquevalues = [...new Map(ReInspectionDetailList.map(item =>
            [item['skuName'], item])).values()];

        uniquevalues.map(x => {
            let totqty = 0;
            if (Values.filter(v => v.skuName === x.skuName && v.isSelected === 1).length !== 0) {
                let sameskudetails = Values.filter(v => v.skuName === x.skuName);
                sameskudetails.map(s => {
                    if (totqty === 0) {
                        totqty = s.balanceQuantity;
                    }
                    else {
                        totqty = totqty + s.balanceQuantity;
                    }
                })
            }
            x.balanceQuantity = totqty;
            x.FinalInspectionIds = selectedInspIds;
            totqty = 0;
        })

        let Finaldata = uniquevalues;

        let isvalid = true;
        isvalid = !!ReInspectionDetailList.find(x => x.isSelected === 1)
        if (isvalid) {
            let PurchaseOrderSkuID = "";
            let NewArr = [];
            NewArr = Values.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (PurchaseOrderSkuID === '') {
                    PurchaseOrderSkuID = Purchaseorder.purchaseOrderSkuID;
                } else {
                    PurchaseOrderSkuID = PurchaseOrderSkuID + ',' + Purchaseorder.purchaseOrderSkuID
                }

            })
            Values.filter(x => x.isSelected === 1).map((x) => {
                if (x.isSelected === 1) {
                    x.IscheckReportSelect = 1;
                } else {
                    x.IscheckReportSelect = 0;
                }

            })
            var maindetails = {
                supplierId: Finaldata[0].supplierId
                , supplierName: Finaldata[0].supplierName
                , tnaid: Finaldata[0].tnaid
                , purchaseOrderID: Finaldata[0].purchaseOrderID

            }
            getInspectionInfo.multiselecteddatas = Finaldata;
            // getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = maindetails;
            getInspectionInfo.TnaID = Finaldata[0].tnaid
            getInspectionInfo.type = Finaldata[0].typeInterOrExtern;
            getInspectionInfo.PurchaseOrderSkuID = PurchaseOrderSkuID;
            getInspectionInfo.Isproduction = IsProduction;
            getInspectionInfo.page = getFinalInspectionPageView;
            getInspectionInfo.ParentTask = "ReInspection";
            getInspectionInfo.Mode = "ReInspection";
            getInspectionInfo.page = "ReInspection";
            getInspectionInfo.pageType = "AddInspection";
            getInspectionInfo.IsAddorEditReinspection = 'Add';
            getInspectionInfo.buyerName = Finaldata[0].buyerName;
            getInspectionInfo.seasonName = Finaldata[0].seasonName;
            getInspectionInfo.brandName = Finaldata[0].brandName;
            getInspectionInfo.buyerID = Finaldata[0].buyerID;
            getInspectionInfo.brandID = Finaldata[0].brandID;
            getInspectionInfo.seasonID = Finaldata[0].seasonID;
            getInspectionInfo.styleID = Finaldata[0].styleID;
            getInspectionInfo.styleName = Finaldata[0].styleName;
            getInspectionInfo.TaskStatus = 1;
            // getInspectionInfo.TNAInspectionID = Values[0].tnaFinalInspectionID;
            getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                ? uniquevalues[0].reInspectionAgainst : Finaldata[0].FinalInspectionIds;



            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;

    };


    const handleFinalInspecViewOpen = (comment, Isfinal, TNAExFactoryId) => {
        Input.Finalview = Isfinal;
        // Input.Finalview = 0
        setInputFields(Input);
        let balanceqty = 0;
        FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            res.data.map((inspc) => {
                if (balanceqty === 0) {
                    balanceqty = parseInt(inspc.poQty) - parseInt(inspc.inspectedQty);
                    inspc.balanceQuantity = balanceqty;
                }
                else {
                    inspc.balanceQuantity = parseInt(balanceqty) - parseInt(inspc.inspectedQty);
                    balanceqty = inspc.balanceQuantity;
                }

            })

            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })
        getInspectionInfo.TaskStatus = 2;
        setInspectionInfo(getInspectionInfo);


    }

    const handleFinalInspecViewclose = () => {
        Input.Finalview = 0;
        setInputFields(Input);
    };


    const openReInspectionDetailpopup = (FinalInspectionID, TnaId) => {
        FinalinspectionService.GetReInspectionDetailList(2, FinalInspectionID, TnaId).then((response) => {
            if (response.data) {
                setopenReInspopup(true);
                setReInspectionDetailList(response.data)
            }
            else {
            }
            hideLoader();
        }).catch(() => { hideLoader(); });

    };

    const closeReInspectionDetailpopup = () => {
        let IsSelected = 0;
        ReInspectionDetailList.forEach(element => {
            element.isSelected = 0;
        })

        setselectall(IsSelected);
        setopenReInspopup(false);
    }

    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('ReInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);
    const [commonSubmitApprovalParams, setCommonSubmitApprovalParams] = useState({ SelectedDependency: '', TaskID: 0, IsSingleTask: true, hasAllDependencyStart: 0 });
    const pageRedirectForFinalInspection = (Page) => {
        setFinalInspectionPageView(Page);
    }

    // function FinalInspectionCallback(value) {
    //     setfinalinspectionMulti(value)
    // }

    // const AddExFactoryForm = () => {

    //     let Multiselected = [];
    //     const Values = [...getfinalinspectionMulti]
    //     let isvalid = true;
    //     isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
    //     if (isvalid) {
    //         Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
    //         setIsAddFinalInspection(true);
    //     } else {
    //         Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
    //         return false;
    //     }

    //     //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;

    // };
    // const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
    //     setIsAddFinalInspection(false);
    // };

    // const handleFinalInspecView = (comment, Action, inpectionpage) => {


    //     let params = {
    //         Operation: 2,
    //         BuyerId: comment.buyerID,
    //         BrandId: comment.brandID, SeasonId: comment.seasonID,
    //         SupplierId: comment.supplierID,
    //         TaskId: comment.taskDetailsID,
    //         StyleId: comment.styleID,
    //         SkuId: 0,
    //         TaskOwnerIdList: '',
    //         TaskHolderId: 0,
    //         tnaExFactoryId: comment.tnaExFactoryId,
    //         status: 0,
    //         selectedDependency: '',
    //         //NewID is subID
    //         NewID: comment.skuSubID,
    //         Isproduction: IsProduction
    //     };
    //     setAddInspectionListParam(params);
    //     dispatch(GetSubChildMultExFactoryFormSKUList(params));


    //     const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
    //     let sizeandqtyfordisplay = "";
    //     let NewArr = [];
    //     NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(comment.skuID))
    //     NewArr.map((Purchaseorder) => {
    //         if (sizeandqtyfordisplay === '') {
    //             sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
    //         } else {
    //             sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
    //         }
    //     })
    //     getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
    //     getInspectionInfo.quantityinfo = getAddInspectionListParam
    //     getInspectionInfo.details = { tnaid: comment.tnaid };;
    //     getInspectionInfo.type = comment.typeInterOrExtern;
    //     //getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay
    //     getInspectionInfo.TNAInspectionID = comment.finalInspectionID;
    //     getInspectionInfo.Isproduction = IsProduction
    //     getInspectionInfo.page = inpectionpage;
    //     getInspectionInfo.ParentTask = "ProductionFLDetails";
    //     getInspectionInfo.Mode = "InspectionView";
    //     getInspectionInfo.NewDetails = comment;
    //     getInspectionInfo.Action = Action;
    //     getInspectionInfo.TaskStatus = 2;
    //     getInspectionInfo.PageName = 'Inspection Entry';
    //     props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    // };

    //PopUP
    function handleDeletefinalinsPopup(TNAInspectionID) {

        let commentparams = {
            Operation: 105,
            Id: TNAInspectionID,
        }
        setIDcomment({ showPopupDelete: true, Params: commentparams });
    }

    //Main Grid List
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        setIDfinalEntry({ showPopupDelete: true, Params: PackingParams });
    }

    const handleFinalInspecViewDetails = (type, TNAInspectionID, comment) => {

        // let sizeandqtyfordisplay = "";
        // let NewArr = [];
        // NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
        // NewArr.map((Purchaseorder) => {
        //     if (sizeandqtyfordisplay === '') {
        //         sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
        //     } else {
        //         sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
        //     }
        // })
        getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = { tnaid: comment.tnaid };;
        getInspectionInfo.type = type;
        //getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay
        getInspectionInfo.TNAInspectionID = TNAInspectionID;
        getInspectionInfo.Isproduction = IsProduction
        getInspectionInfo.page = getFinalInspectionPageView;
        getInspectionInfo.ParentTask = "ProductionFLDetails";
        getInspectionInfo.NewDetails = comment;
        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    };












    const CreateFinalInspection = (type, comment) => { // type=1=> internal, 2=> external

        getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = { tnaid: comment.tnaid };
        getInspectionInfo.type = type;
        getInspectionInfo.Isproduction = IsProduction;
        getInspectionInfo.page = getFinalInspectionPageView;
        getInspectionInfo.ParentTask = "ProductionFLDetails";
        getInspectionInfo.NewDetails = comment;
        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    }



    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...ReInspectionDetailList];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setReInspectionDetailList(value);
    }



    return (
        <div className="page-body" style={{ background: "none" }}>
            {/* <ReactNotification /> */}
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div className="widget">
                        {/* <div className="widget-header" style={{ boxShadow: "none", padding: "0px" }}> */}
                        {/* <span className="widget-caption" style={{ padding: "15px 0px 0px" }}>Final Inspection Entry List (91 Days)
                                &nbsp;

                            </span> */}
                        {/* {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o  btn btn-darkorange excelcomt"
                                    table={"table-to-xls"}
                                    filename={"TNADetailsReportList"}
                                    sheet="TNADetailsReport"
                                    buttonText="  Export to Excel"

                                />
                            )} */}
                        {/* </div> */}
                        <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                            <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="widget">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion1">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne12">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne12" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        onChange={e => handleChange(e, 'Buyer2')}
                                                                                        value={getBuyersearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                            <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        onChange={e => handleChange(e, 'Season2')}
                                                                                        value={getSeasonsearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        onChange={e => handleChange(e, 'Brand2')}
                                                                                        value={getBrandsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        onChange={e => handleChange(e, 'Quarter2')}
                                                                                        value={getQuartersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        onChange={e => handleChange(e, 'Supplier2')}
                                                                                        value={getSuppliersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>PO/ID No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'PO/ID No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'PO/ID No'}
                                                                                        onChange={e => handleChange(e, 'ID/NO2')}
                                                                                        value={getIdPosearch}
                                                                                    />

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2">
                                                                            <label>End Customer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'End Customer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'End Customer'}
                                                                                        onChange={e => handleChange(e, 'EndCustomer2')}
                                                                                        value={getEndCustomersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Category</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Category'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Category'}
                                                                                        onChange={e => handleChange(e, 'Category2')}
                                                                                        value={getCategorysearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                            <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Style Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        onChange={e => handleChange(e, 'StyleName2')}
                                                                                        value={getStyleNamesearch}
                                                                                    />
                                                                                </span>

                                                                            </div>

                                                                            <div className="col-md-2">
                                                                            <label>Style No</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Style No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style No'}
                                                                                        onChange={e => handleChange(e, 'StyleNo2')}
                                                                                        value={getStyleNosearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>SKU Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'SKU Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'SKU Name'}
                                                                                        onChange={e => handleChange(e, 'SKUName2')}
                                                                                        value={getSkuSearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Factory</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Factory'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Factory'}
                                                                                        onChange={e => handleChange(e, 'Factory2')}
                                                                                        value={getFactorySearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">


                                                                            <div className="col-md-2">
                                                                                <label>Re Inspection Status Filter</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={InspectionandApproval}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus2')}

                                                                                    //value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>



                                                                            <div className="col-md-2"  ref={datePickerRefIns}>
                                                                                <label>Inspection Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                        type="text"
                                                                                        className={'form-control styledatapickerwidth100'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times stylemleft" onClick={clearDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateDate([item.selection])}
                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="col-md-1 margin-top-30">
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-3">
                                                                                <label>Approval Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleExDateSelect(event, !stateApDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearExDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateApDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateExfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateApDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div> */}

                                                                            {/* <div className="col-md-3">
                                                                                <label>Status</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={orderStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                        value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-3">
                                                                                <label>Inspection Date Filter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateInsDate[0].startDate === null ? '' : moment(stateInsDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateInsDate[0].endDate === null || !moment(stateInsDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateInsDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleInsDateSelect(event, !stateInsDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearInsDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateInsDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateInsfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateInsDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-md-3">
                                                                                <label>TNA Date Range</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateTNADate[0].startDate === null ? '' : moment(stateTNADate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateTNADate[0].endDate === null || !moment(stateTNADate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateTNADate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleTNADateSelect(event, !stateTNADate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearTNADateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateTNADate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateTNAchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateTNADate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="col-md-1 margin-top-30" style={{ marginLeft: "250px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    {/* <div className="col-md-3">
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="col-md-1 margin-top-30" style={{ marginLeft: "250px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                      </div> */}
                                                                </div>

                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header>
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(1)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.insCreatedDate}
                                                        disabled
                                                    />
                                                    &nbsp;<label htmlFor="podate">PO Date</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(21)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.finalInspectionID}
                                                    />
                                                    &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(2)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="season"
                                                        checked={getHideID.season}
                                                    />
                                                    &nbsp;<label htmlFor="season">Season</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(3)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.Quarter}
                                                    />
                                                    &nbsp;<label htmlFor="quarter">Quarter</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(4)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.brand}
                                                    />
                                                    &nbsp;<label htmlFor="brand">Brand</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(5)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.customer}
                                                    />
                                                    &nbsp;<label htmlFor="customer">Customer</label>
                                                </li>
                                            </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedDate"
                                                            checked={checkboxStates.CreatedDate}

                                                        />
                                                        &nbsp;<label htmlFor="CreatedDate">Created Date </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="EntryNo"
                                                            checked={checkboxStates.EntryNo}
                                                        />
                                                        &nbsp;<label htmlFor="EntryNo">Entry No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="TypeInterOrExtern"
                                                            checked={checkboxStates.TypeInterOrExtern}
                                                        />
                                                        &nbsp;<label htmlFor="TypeInterOrExtern">Internal/External</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionType"
                                                            checked={checkboxStates.InspectionType}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionType">Inspection Type</label>
                                                    </li>
                                                </div>
                                                {/* <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ReInspectionDoneAgainst"
                                                            checked={checkboxStates.ReInspectionDoneAgainst}
                                                        />
                                                        &nbsp;<label htmlFor="ReInspectionDoneAgainst">ReInspection Done</label>
                                                    </li>
                                                </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Brand"
                                                            checked={checkboxStates.Brand}
                                                        />
                                                        &nbsp;<label htmlFor="Brand">Brand</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Season"
                                                            checked={checkboxStates.Season}
                                                        />
                                                        &nbsp;<label htmlFor="Season">Season</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Supplier"
                                                            checked={checkboxStates.Supplier}
                                                        />
                                                        &nbsp;<label htmlFor="Supplier">Supplier</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Factory"
                                                            checked={checkboxStates.Factory}
                                                        />
                                                        &nbsp;<label htmlFor="Factory">Factory</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CustomerShipmentRefNo"
                                                            checked={checkboxStates.CustomerShipmentRefNo} />
                                                        &nbsp;<label htmlFor="CustomerShipmentRefNo">CustomerShipmentRefNo</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="IDNo"
                                                            checked={checkboxStates.IDNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="IDNo">PO/ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleName"
                                                            checked={checkboxStates.StyleName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleName">Style Name</label>
                                                    </li>
                                                </div>


                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleNo"
                                                            checked={checkboxStates.StyleNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="SKUName"
                                                            checked={checkboxStates.SKUName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="SKUName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionQuantity"
                                                            checked={checkboxStates.InspectionQuantity}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionQuantity">Inspection Quantity</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionResult"
                                                            checked={checkboxStates.InspectionResult}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionResult">Inspection Result</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedBy"
                                                            checked={checkboxStates.CreatedBy}
                                                        />
                                                        &nbsp;<label htmlFor="CreatedBy">CreatedBy</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ApprovalResult"
                                                            checked={checkboxStates.ApprovalResult}
                                                        />
                                                        &nbsp;<label htmlFor="ApprovalResult">ApprovalResult</label>
                                                    </li>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    <div className="row">
                        <div className="col-lg-11">
                        </div>
                        <button style={{ marginTop: '8px', marginBottom: '7px' }} title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                        {/* <label style={{ marginTop: '7px', marginLeft: "34px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                            <input type="checkbox" name="IsAllSelected"
                                onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                value={isAllSelected ? true : false}
                                checked={isAllSelected ? true : false}
                                className="selectallcheckbox"
                            />
                        </label> */}
                        {commentsData.length !== 0 && (
                            <ReactHTMLTableToExcel
                                id="test-table-s-button-final"
                                className="fa fa-file-excel-o  btn btn-success excelcomt"
                                table={"table-to-xlsentry"}
                                filename={"ToDoReInspectionList"}
                                sheet="ToDoReInspection"
                                buttonText=""
                            />
                        )}
                        <div className="col-md-12">
                            <div style={{ overflow: "auto" }}>
                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "160vm" }}>
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment,i) => (
                                                <Fragment key={i}>
                                                <tr key={comment.finalInspectionID}>
                                                    <td>{i+1}</td>
                                                    {
                                                        checkboxStates.CreatedDate && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                // className="stickyone "
                                                                title={`${comment.typeInterOrExtern === 1 ? 'IN' : 'EX'} -  ${comment.inspectionType === 'Re Inspection' ? 'Re' : 'New'} - ${comment.finalInspectionID} - ${comment.insCreatedDate} `}>
                                                                {comment.typeInterOrExtern === 1 ? 'IN' : 'EX'} -  {comment.inspectionType === 'Re Inspection' ? 'Re' : 'New'} -  {comment.finalInspectionID} - {comment.insCreatedDate}

                                                            </td>
                                                        )
                                                    }
                                                    {/* {
                                                        checkboxStates.EntryNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.finalInspectionID}>
                                                                {comment.finalInspectionID}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.InspectionType && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionType}>
                                                                {comment.inspectionType}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.TypeInterOrExtern && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.typeInterOrExtern}>
                                                                {comment.typeInterOrExtern === 1 ? 'Internal' : 'External'}
                                                            </td>
                                                        )
                                                    } */}

                                                    {/* {
                                                        checkboxStates.Brand && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.brandName}>
                                                                {comment.brandName}
                                                            </td>
                                                        )

                                                    }
                                                    {
                                                        checkboxStates.Season && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.seasonName}>
                                                                {comment.seasonName}
                                                            </td>
                                                        )
                                                    } */}

                                                    {
                                                        checkboxStates.Supplier && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.supplierName + ' / ' + comment.factory}>
                                                                {comment.supplierName + ' / ' + comment.factory}
                                                            </td>

                                                        )
                                                    }
                                                    {/* {
                                                        checkboxStates.Factory && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'Factory'}>
                                                                {comment.factory}
                                                            </td>
                                                        )
                                                    } */}
                                                    {
                                                        checkboxStates.CustomerShipmentRefNo && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'Ship Ref No'}>
                                                                {comment.customerShipmentRefNo}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.StyleName && (
                                                            <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.SKUName && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.skuName}>
                                                                {comment.skuName}
                                                                <span>
                                                                    <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                    >
                                                                        <i className="fa fa-eye"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.IDNo && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'PO/ID No'}>
                                                                {comment.idpono}
                                                            </td>
                                                        )
                                                    }
                                                    {/* {
                                                        checkboxStates.IDNo && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'FO PO No'}>
                                                                {comment.foidpono === '0' ? '-' : comment.foidpono}
                                                            </td>
                                                        )
                                                    } */}

                                                    {/* {
                                                        checkboxStates.StyleNo && (
                                                            <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                        )
                                                    } */}

                                                    {/* {
                                                        checkboxStates.InspectionQuantity && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionQty}>
                                                                {comment.inspectionQty}
                                                            </td>
                                                        )
                                                    } */}

                                                    {/* {
                                                        checkboxStates.InspectionQuantity && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.orderqty}>
                                                                {comment.orderqty}
                                                            </td>
                                                        )
                                                    } */}
                                                    {
                                                        checkboxStates.InspectionQuantity && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionQty}>
                                                                {comment.inspectionQty}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.InspectionResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionResult}>
                                                                 <b className={comment.inspectionResult === 'Pass' ? 'success' : comment.inspectionResult === 'Fail' ? 'danger' : comment.inspectionResult === 'Saved' || comment.inspectionResult === 'Saved Without Defect' ? 'blue' : 'warning'}>
                                                                    {comment.inspectionResult}
                                                                </b>
                                                            </td>
                                                        )
                                                    }
                                                    {/* {
                                                        checkboxStates.CreatedBy && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.createdBy}>
                                                                {comment.createdBy}
                                                            </td>
                                                        )
                                                    } */}
                                                    {
                                                        checkboxStates.ApprovalResult && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.approvalResult}>
                                                                <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                            </td >
                                                        )
                                                    }



                                                    {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%",display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}>
                                                        {/* <span style={{ padding: '2px' }}>
                                                            <span className="btn" title="Approved" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                style={{
                                                                    background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                }}
                                                            >
                                                                <i className="fa fa-check"></i>
                                                            </span>
                                                        </span> */}

                                                        < span >
                                                            <button type="button"
                                                                style={{
                                                                    background: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red'
                                                                }}
                                                                //className={comment.approvalResult === null || comment.approvalResult === ""  ? "btn btn-xs btn-primary" : "btn btn-xs btn-danger"} title='Comment'
                                                                onClick={() => GetComments(comment, 'Comments')}>
                                                                <i className="fa fa-comment"></i>
                                                            </button>
                                                            &nbsp;
                                                            <button type="button" className="btn btn-xs btn-success" title='Add Re Inspection'
                                                                // onClick={() => handleFinalInspecView(comment, 'Edit', '')}
                                                                onClick={() => openReInspectionDetailpopup(comment.finalInspectionID, comment.tnaid)}

                                                            >
                                                                <i className="fa fa-plus "></i>
                                                            </button>
                                                            {/* &nbsp;
                                                            <button type="button"  className={comment.approvalResult === null ? "btn btn-xs btn-success" : "DPNone"} title='Edit FinalList'
                                                              onClick={() => handleFinalInspecView(comment, 'Edit', 'EditInspection')}>
                                                              <i className="fa fa-edit"></i>
                                                              </button>
                                                              &nbsp;
                                                              <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-danger" : "DPNone"} title='Delete FinalList'
                                                             onClick={() => handleDeletefinalinspection(comment.inspectionType, comment.finalInspectionID, 'delete')}
                                                              >
                                                             <i className="fa fa-trash-o"></i>
                                                              </button> */}

                                                        </span >



                                                    </td>}
                                                </tr>
                                                </Fragment>
                                            ))

                                        }


                                        {
                                            sizeqtypopup === true ?

                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                    {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                    <Modal.Header closeButton>
                                                        <Modal.Title>SKU and Qty
                                                        </Modal.Title>


                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                        {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                    </div>

                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>PO-SKUID</th>
                                                                    <th style={{ width: "350px" }}>SKU</th>
                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    skuqtylist.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.purchaseOrderSkuID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.skuName}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.offeredQty}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>

                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody >

                                    <table data-role="table" data-mode="columntoggle" id="table-to-xlsentry"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>


                                                            :

                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {checkboxStates.CreatedDate === true ? <th style={{ width: '3%' }}> Created Date </th> : false}
                                                {checkboxStates.EntryNo === true ? <th style={{ width: '3%' }}> Entry No </th> : false}
                                                {checkboxStates.InspectionType === true ? <th style={{ width: '4%' }}> Inspection Type</th> : false}
                                                {checkboxStates.TypeInterOrExtern === true ? <th style={{ width: '3%' }}> Interal/External </th> : false}
                                                {checkboxStates.Quarter === true ? <th style={{ width: '1%' }}> Quarter</th> : false}
                                                {checkboxStates.Brand === true ? <th style={{ width: '5%' }}> Brand</th> : false}
                                                {checkboxStates.Season === true ? <th style={{ width: '5%' }}> Season</th> : false}
                                                {checkboxStates.Supplier === true ? <th style={{ width: '5%' }}> Supplier </th> : false}
                                                {checkboxStates.Factory === true ? <th style={{ width: '4%' }}> Factory</th> : false}
                                                {checkboxStates.CustomerShipmentRefNo === true ? <th style={{ width: '7%' }}>CustomerShipment Ref No</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}> PO/ID No</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}>FOPO No</th> : false}
                                                {checkboxStates.StyleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                                                {checkboxStates.StyleNo === true ? <th style={{ width: '5%' }}> Style No</th> : false}
                                                {checkboxStates.SKUName === true ? <th style={{ width: '4% ' }}> SKU Name</th> : false}
                                                {checkboxStates.InspectionQuantity === true ? <th style={{ width: '4%' }}> Inspection Quantity</th> : false}
                                                {checkboxStates.InspectionResult === true ? <th style={{ width: '5%' }}> Inspection Result</th> : false}
                                                {checkboxStates.CreatedBy === true ? <th style={{ width: '4%' }}> Created By</th> : false}
                                                {checkboxStates.ApprovalResult === true ? <th style={{ width: '4%' }}> Approval Result</th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.finalInspectionID}>

                                                        {
                                                            checkboxStates.CreatedDate && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    // className="stickyone "
                                                                    title={comment.insCreatedDate}>
                                                                    {comment.insCreatedDate}

                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.EntryNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.finalInspectionID}>
                                                                    {comment.finalInspectionID}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.InspectionType && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionType}>
                                                                    {comment.inspectionType}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.TypeInterOrExtern && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.typeInterOrExtern}>
                                                                    {comment.typeInterOrExtern === 1 ? 'Internal' : 'External'}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.Brand && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.brandName}>
                                                                    {comment.brandName}
                                                                </td>
                                                            )

                                                        }
                                                        {
                                                            checkboxStates.Season && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.seasonName}>
                                                                    {comment.seasonName}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.Supplier && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.supplierName}>
                                                                    {comment.supplierName}
                                                                </td>

                                                            )
                                                        }
                                                        {
                                                            checkboxStates.Factory && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'Factory'}>
                                                                    {comment.factory}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.CustomerShipmentRefNo && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'CustomerShipment Ref'}>
                                                                    {comment.customerShipmentRefNo}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.IDNo && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.idpono}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.IDNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.foidpono}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.StyleName && (
                                                                <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.StyleNo && (
                                                                <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.SKUName && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.skuName}>
                                                                    {comment.skuName}
                                                                    <span>
                                                                        <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                        >
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionQuantity && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {comment.inspectionQty}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionResult}>
                                                                    {comment.inspectionResult}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.CreatedBy && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.createdBy}>
                                                                    {comment.createdBy}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.ApprovalResult && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.approvalResult}>
                                                                    <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                                </td >
                                                            )
                                                        }



                                                        {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%",display: InternalComments && InternalComments.isEdit === 1 ? '' : 'none' }}>
                                                            {/* <span style={{ padding: '2px' }}>
                                                            <span className="btn" title="Approved" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                style={{
                                                                    background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                }}
                                                            >
                                                                <i className="fa fa-check"></i>
                                                            </span>
                                                        </span> */}
                                                        </td>}
                                                    </tr>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table >
                            </div >


                        </div >


                    </div >

                </div >

                {
                    Commentpopup === true ?
                        // style={{ height: "25%" }}
                        <Modal show={Commentpopup === true} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt', Ecomment)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Comments

                                </Modal.Title>
                                {/* {
                                                getFIRemark.length >= 1 ?
                                                    <button style={{ marginLeft: "45rem" }} type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                        onClick={() => handleDeletefinalinsPopup(getheaderName[0].FinalInspectionID)}
                                                    >
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                    : ''
                                            } */}
                            </Modal.Header>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                <thead>
                                    <tr>
                                        <th>Comments<span className="text-danger">*</span></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        {/* <span className="input-icon icon-right">


                                                                                        <DatePicker className="form-control"
                                                                                        name="ReceivedDate"
                                                                                            id={"ReceivedDate" }
                                                                                            // selected={
                                                                                            //     inputField.ReceivedDate
                                                                                            // }
                                                                                            onChange={
                                                                                                event => handleChangeRemarks(1, event, "ReceivedDate")
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            //showMonthDropdown
                                                                                            //showYearDropdown
                                                                                            dropdownMode="scroll"
                                                                                            autoComplete="off"
                                                                                            styles={Submitted && getSelectedApproval === undefined || getSelectedApproval === null ? styles : ''}
                                                                                           // customInput={<CustomInput />}
                                                                                            //isClearable={inputField.ReceivedDate === null ? false : true}
                                                                                        />

                                                  </span> */}

                                        {/* <td >
                                                        <Reactselect
                                                            options={getApprovalList}
                                                            value={getSelectedApproval}
                                                            onChange={handleCmtEXFactoryChanges}
                                                            styles={Submitted && getSelectedApproval === undefined || getSelectedApproval === null ? styles : ''}
                                                        />
                                                    </td> */}
                                        <td colSpan='2'>
                                            <textarea
                                                rows="4"
                                                placeholder="Enter comments here..."
                                                autoFocus onFocus={onFocus} autoComplete="off"
                                                maxLength="500" name="EnterComment"
                                                id={"EnterComment"}
                                                value={Ecomment}
                                                onChange={event => handleChangeComment(event, "reportComment")}
                                                style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                            </textarea>
                                        </td>

                                    </tr >

                                </tbody >
                            </table >
                            <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                    <thead>
                                        <tr>
                                            {/* <th>Results</th> */}
                                            <th>Comments</th>
                                            <th>Created By</th>
                                            <th>Approval Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getFIRemark.length === 0 ?
                                                <tr>
                                                    <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> :
                                                getFIRemark.map((Commentitem) => {
                                                    return (

                                                        <tr>
                                                            <td>
                                                                {Commentitem.commentsDetail}
                                                            </td>
                                                            <td>{Commentitem.createdBy}</td>
                                                            <td>
                                                                {moment(Commentitem.commentsDate).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td>
                                                                <span title='Delete PackageImage' onClick={() => handleDeleteRefinalinspection(Commentitem, '')}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>

                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={(event) => handleSaveApproval(event, Ecomment, getheaderName[0].FinalInspectionID)}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal >
                        : ''
                }

                {openReInspopup === true &&

                    <Modal show={openReInspopup === true} size="xl" dragable backdrop="static" keyboard={false}
                        onHide={() => closeReInspectionDetailpopup()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                ADD Re Inspection

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Report No</th>
                                            <th>Style Name</th>
                                            <th>Style No</th>
                                            <th>SKU ID</th>
                                            <th>SKU Name</th>
                                            <th>PO/ID No</th>
                                            <th>PO No</th>
                                            <th>Factory</th>
                                            <th>Inspected Qty</th>
                                            <th>Balance for Re Inspection</th>

                                            <th>Task Holder / Follower</th>
                                            <th>
                                                <label style={{ marginTop: '8px' }}>
                                                    <input type="checkbox" name="IsSelected"

                                                        onChange={event => (SelectAll(event))}
                                                        checked={selectall === 1 ? true : false}
                                                        className="colored-blue clearOpacity" />
                                                    <span className="text"></span>
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ReInspectionDetailList.length !== 0 ?
                                                ReInspectionDetailList.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item.tnaFinalInspectionID}</td>
                                                                <td>{item.styleName}</td>
                                                                <td>{item.styleNo}</td>
                                                                <td>{item.skuID}</td>
                                                                <td>{item.skuName}</td>
                                                                <td>{item.idNo}</td>
                                                                <td>{item.poNo}</td>
                                                                <td>{item.factory}</td>
                                                                <td>{item.inspectedQty}</td>
                                                                <td>{item.balanceQuantity}</td>
                                                                <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                                                                <td className="Actionbutton fixed-column">
                                                                    <div style={{ width: "80px" }}>
                                                                        {

                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="IsSelected"
                                                                                    disabled={item.balanceQuantity === "0" ? true : false}
                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                    className="colored-blue clearOpacity" />
                                                                                <span className="text"></span>
                                                                            </label>
                                                                        }&nbsp;&nbsp;&nbsp;
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>

                                                    )
                                                }) :
                                                <tr>
                                                    <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                Input.StatusID !== 3 &&
                                <Button variant="success" onClick={() => AddReInspection()}>
                                    Save
                                </Button>
                            }
                        </Modal.Footer>

                    </Modal>

                }

                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
                {/* {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={delCallback}
                        showpopup={true}
                        pageActionId={FLReportSkuComments}
                        Msg={"Final Inspection details deleted successfully"} /> : null
                } */}
                {/* {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                } */}
                {
                    getIDfinalEntry.showPopupDelete ? <SweetAlertPopup data={
                        getIDfinalEntry.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                }
                {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={FinalReinspectioncmt}
                        Msg={"Final Inspection deleted successfully"} /> : null
                }
                {
                    getIDcomment.showPopupDelete ? <SweetAlertPopup data={
                        getIDcomment.Params
                    }
                        deleteCallback={delApprovalCallback}
                        showpopup={true}
                        pageActionId={FLApprovalComment}
                        Msg={"Final Inspection Approval comment deleted successfully"} /> : null
                }
            </div >
            {loader}



        </div >
    )
}

export default ReFinalInspection
