import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import Nodify from "../../../Common/ReactNotification";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { ToleranceID } from "../../../Common/PageActionNumber";
import SearchRetain from "../../../Common/SearchRetain"
import CommonService from '../../../../services/Common/CommonService';
import { useDispatch, useSelector } from "react-redux";
import BuyerService from "../../../../services/Master/BuyerService";
import { retrieveBuyerinfo } from "../../../../actions/buyerinfo"
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import moment from 'moment';

// import CommonService from "../../../../services/Common/CommonService";
const ToleranceList = (props) => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
  // const { user: currentUser } = useSelector((state) => state.auth);
  const [expandedRows, setExpandedRows] = useState([]);
  const [ActivityPopup, setActivityPopup] = useState(false);

  const [expandState, setExpandState] = useState({});
  const [expandBuyerList, setexpandBuyerList] = useState({});
  const [getID, setID] = useState(0);
  const [getnewdata, setnewdata] = useState([])
  const [getToleranceHistoryData, setToleranceHistoryData] = useState([])

  const [getPlaceholder] = useState("Buyer Name");

  const dispatch = useDispatch();
  let activeMenu;
  const menulist = useSelector((state) => state.common.MenuList.item3);
  if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "AQLTolerance"); }


  //reducer
  const reducerState = useSelector((state) => state);
  const { user: currentUser } = reducerState.auth;
  const comments = reducerState.buyerinfo.buyerinfoList;
  let isLoadingBuyerinfo = reducerState.buyerinfo.isLoadingBuyerinfo;

  

  useEffect(() => {
    FinalinspectionService.getToleranceList(0).then((res) => {
      if (res.data) {
        setnewdata(res.data)
      }
    })
  }, []);

  useEffect(() => {
    dispatch(retrieveBuyerinfo());
    const state1 = props.location.state
    if (state1 !== undefined && state1 != null) {
      let Msg;
      let Type = 'success'; //default, info, warning
      let Title = 'Success!';
      if (state1.message === 'Edit') {
        Msg = 'AQL Tolerance added Successfully.';
        // Type = 'default';
      } else if (state1.message === 'Add') {
        Msg = ' AQL Tolerance updated Successfully.';
      } else {
        Msg = 'Error occured';
        Type = 'warning';
        Title = 'Error!';
      }
      window.history.replaceState(null, '')
      Nodify(Title, Type, Msg);
    }
    var page = getPlaceholder;
    var setConstraint = SearchRetain(page);
    if (setConstraint !== undefined) {
      setCurrentPage(setConstraint.CurrentPage);
      setITEMS_PER_PAGE(setConstraint.PageItems);
    }
    //         } else {
    //             hideLoader();
    //         }
    //     }).catch(() => {
    //         hideLoader();
    //     });
    // }, 500)
  }, [dispatch]);



  useEffect(() => {
    if (!isLoadingBuyerinfo) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingBuyerinfo]);


  const headers = [
    {
      name: "#",
      field: "",
      sortable: false,

    },
    {
      name: "Buyer Name",
      field: "buyerName",
      sortable: true,

    },
    {
      name: "Brand",
      field: "brandName",
      sortable: true,

    },
    {
      name: "Offered Qty Tolerance %",
      field: "offerQty",
      sortable: true,

    },
    {
      name: "Create Date / Created By",
      field: "createdDate",
      sortable: true,

    },
    {
      name: "Action",
      field: "",
      sortable: false,
      display: activeMenu && activeMenu[0].isEdit === 0 &&
      activeMenu[0].isDelete === 0 ? 'none' : ''
    },
  ];

  const commentsData = useMemo(() => {
    let computedComments = getnewdata;
    computedComments.map((x) => {
      x.offerQty = x.offerQty.toString();
    })
    if (search) {
      computedComments = computedComments.filter(comment => comment.buyerName.toLowerCase().includes(search.toLowerCase()));
    }
    setTotalItems(computedComments.length);

    // Sorting comments
//     if (sorting.field) {
//       const reversed = sorting.order === "asc" ? 1 : -1;
//       computedComments = computedComments.sort((a, b) => {
//         const valueA = a[sorting.field];
//         const valueB = b[sorting.field];
      
//         // Check if values are numeric
//         const isNumericA = !isNaN(valueA);
//         const isNumericB = !isNaN(valueB);
      
//         if (isNumericA && isNumericB) {
//           // If both values are numeric, compare as numbers
//           return reversed * (parseFloat(valueA) - parseFloat(valueB));
//         } else {
//           // If one or both values are non-numeric, compare as strings
//           return reversed * valueA.toString().localeCompare(valueB.toString());
//         }
//       });
        
// }

if (sorting.field) {
  const reversed = sorting.order === 'asc' ? 1 : -1;
  computedComments = computedComments.sort((a, b) => {
    const valueA = a[sorting.field];
    const valueB = b[sorting.field];

    // Check if both values are numeric
    const isNumericA = !isNaN(valueA);
    const isNumericB = !isNaN(valueB);

    // Check if both values are valid date-time strings using moment
    const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
    const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();

    if (isNumericA && isNumericB) {
      // If both values are numeric, compare as numbers
      return reversed * (parseFloat(valueA) - parseFloat(valueB));
    } else if (isDateTimeA && isDateTimeB) {
      // If both values are valid date-time strings, compare as dates
      return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
    } else {
      // If one or both values are non-numeric and non-valid date-time strings, compare as strings
      return reversed * valueA.toString().localeCompare(valueB.toString());
    }
  });
}




    // Current Page slice
    let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    if (records.length === 0 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
  }, [
    getnewdata,
    currentPage,
    search,
    sorting,
    ITEMS_PER_PAGE
  ]);


  const Delcallback = (value) => {
    dispatch(retrieveBuyerinfo());
    setID({ showPopupDelete: false, Params: [] });
    window.location.reload();
  }

  const GetActivity = (comment) => {
    FinalinspectionService.ToleranceHistory(comment.buyerID, comment.brandID).then((res) => {
      if (res.data) {
        setToleranceHistoryData(res.data)
      }
    })




    setActivityPopup(true)
  }
  const handleFullClose = () => {
    setActivityPopup(false)
  }

  const pageRedirect = (buyerinfoParams, redirect, page) => {
   
    localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
    if (redirect === 'Add') {
      props.history.push('/ToleranceAdd');
    }
    else if (redirect === 'Edit') {
      let PackingParams = {
        Operation: 2,
        BuyerId: buyerinfoParams.buyerID,
        BrandId: buyerinfoParams.brandID.toString()
      }
      CommonService.CheckMappedItemFinalinspection(PackingParams).then(res => {
        if (res.data.outputResult === "-2") {
          let Title = "Warning!";
          let message = "Not allow to edit tolerance when these Buyer and Brand mention in Finalinsepction";
          let type = "warning";
          Nodify(Title, type, message);
        } else {
          props.history.push(`/ToleranceEdit`, { params: buyerinfoParams });
        }
      })
    }
    else {
      let PackingParams = {
        Operation: 2,
        BuyerId: buyerinfoParams.buyerID,
        BrandId: buyerinfoParams.brandID.toString()
      }
      CommonService.CheckMappedItemFinalinspection(PackingParams).then(res => {
        if (res.data.outputResult === "-2") {
          let Title = "Warning!";
          let message = "This AQl Tolerance is already mapped.";
          let type = "warning";
          Nodify(Title, type, message);
        }
        else {
          let Paramstwo = {
            Operation: ToleranceID,
            Id: buyerinfoParams.toleranceID,
          };
          setID({ showPopupDelete: true, Params: Paramstwo });
        }
      });

    }


    // else {
    //     setID({ showPopupDelete: true, Params: buyerinfoParams });
    // }
  };









  return (
    <>
      <div className="page-body">
        <ReactNotification />
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="widget">
              <div className="widget-header ">
                <span className="widget-caption">AQL Tolerance List</span>
              </div>

              <div className="widget-body">
                <div className="dataTables_wrapper form-inline no-footer">
                  <div className="table-toolbar pull-left">
                    <Search onSearch={
                      value => {
                        setSearch(value);
                        setCurrentPage(1);
                      }
                    }
                      placeholder={getPlaceholder} props={props} />

                  </div>
                  <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                    &nbsp;

                    <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add AQL Tolerance">
                      <i className="fa fa-plus"></i>&nbsp;Add
                    </span>
                  </div>


                  <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                    <TableHeader headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })
                      } />
                    <tbody>
                      {
                        commentsData.map((comment,i) => (
                          <Fragment key={i}>
                          <>
                            <tr>
                              <td style={{width:'25px'}}>  
                                  { i + 1}
                              </td>
                              <td>

                                <span className="BuyerPaddingLeft">
                                  {
                                    comment.buyerName
                                  }
                                </span>
                              </td>
                              <td>
                                {comment.brandName}

                              </td>
                              <td>
                                {comment.offerQty}

                              </td>

                              <td>
                                {comment.createdDate} /  {comment.createdByName}
                              </td>
                              <td style={{
                                                            display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                        }}>
                                {comment.length !== 0 && (
                                  <span>
                                    <span  style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} className="btn btn-success btn-xs add" onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)} >
                                      <i className="fa fa-edit "></i>
                                    </span>
                                    &nbsp;
                                    <span  style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} className="btn btn-danger btn-xs delete" onClick={() => pageRedirect(comment, '', getPlaceholder)}>
                                      <i className="fa fa-trash-o"></i>
                                    </span>
                                    &nbsp;
                                    <span class="btn btn-white btn-xs eye" onClick={() => GetActivity(comment)}>
                                      <i class="fa fa-eye "></i>
                                    </span>
                                  </span>


                                )}

                                {
                                  ActivityPopup === true ?
                                    <Modal show={ActivityPopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose()}>
                                      <Modal.Header closeButton>
                                        <Modal.Title>
                                          Activity Popup
                                        </Modal.Title>
                                      </Modal.Header>

                                      <Modal.Body>
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                          <thead>
                                            <tr>
                                              <th>Offer Qty Tolerance</th>
                                              <th>Date </th>
                                              {/* <th>Action </th> */}
                                              <th> User Name </th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              getToleranceHistoryData.map((x, index) => (
                                                <tr>
                                                  <td >
                                                    {x.offerQty}
                                                  </td>
                                                  <td>
                                                    {x.modifiedDate}
                                                  </td>
                                                  {/* <td>
                                                                      {x.modifiedDate}
                                                                    </td> */}
                                                  <td>
                                                    {x.createdByName}
                                                  </td>
                                                </tr>
                                              ))
                                            }


                                          </tbody>
                                        </table>


                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button variant="danger" onClick={() => handleFullClose()} >
                                          Close
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                    : ''
                                }

                              </td>


                            </tr>

                          </>
                          </Fragment>
                        ))
                      }
                      {
                        totalItems === 0 ?
                          <tr>
                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                          </tr> : ''
                      }
                    </tbody>
                  </table>

                  <PageCount onPageCount={
                    value => {
                      setITEMS_PER_PAGE(value);
                      setCurrentPage(1);
                    }
                  } />
                  <Pagination total={totalItems}
                    itemsPerPage={
                      parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                      page => setCurrentPage(page)
                    } />
                </div>
              </div>
              {
                getID.showPopupDelete ? <SweetAlertPopup data={
                  getID.Params
                }
                  deleteCallback={Delcallback}
                  showpopup={true}
                  pageActionId={ToleranceID}
                  Msg={"Tolerance information deleted successfully."} /> : null
              }
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  )
}

export default ToleranceList