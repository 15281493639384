import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetOrderLapdipApprovalViewList, clearLapdipApprovalViewSubRow } from "../../../../actions/orderLabdip";
import { loadSupplierList } from "../../../../actions/tna";
// import CommonService from "../../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
//import SweetAlertPopup from '../../../../Common/SweetAlertPopup';
//import { GenLabdipApprovalId } from "../../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService"
import input from "react-validation/build/input";
import $, { get } from "jquery";
import axios from "axios";
import TnaService from "../../../../services/TNA/TNAService";
import LabdibApproval from "../../../TNA/LabdibApproval";
import LabdibSubmission from "../../../TNA/LabdibSubmission";
//import { TableHeader, Pagination, Search, PageCount } from "../../../../Datatable";
//import SweetAlert from 'react-bootstrap-sweetalert';
import { TNAManualCloseChildLibDip } from "../../../Common/PageActionNumber";
import SweetAlertPopup from "../../../Common/SweetAlertPopup";
const LabDipViewApprovalTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const labdipApprovalList = useSelector((state) => state.orderLabdip.orderLabdipApprovalViewList);
    //const labdipSubmissionParams = useSelector((state) => state.generalLabdip.generalLabdipViewDetailParams);
    let isLoadingOrderLabdip = useSelector((state) => state.orderLabdip.isLoadingOrderLabdip);
    let isReLoadingOrderLabdip = useSelector((state) => state.orderLabdip.isReLoadingOrderLabdip);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
   

    // const getMenuName = (baseName) => {
    //     return IsProduction === 1
    //         ? `${baseName}`
    //         : IsProduction === 2
    //         ? `Production${baseName}`
    //         : undefined;
    // };

    //   // If check production or sampling
    //  const activeMenus = useSelector((state) => state.auth.activeMenuList);
    //  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    //  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
    //  // End 
    //  let info;
    //  let activeMenu;
    //  let activeMenuSub;
    //  let SampleApprovalTab;

    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
    //     info = IsProduction === 1 ? 'Samples' : 'ProductionSample';
    //     activeMenu = menulist.filter(i => i.menuName ===  info);
    //     if (activeMenu.length > 0) {
    //         activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
    //         if (activeMenuSub.length > 0) {
    //             for (let index = 0; index < activeMenuSub.length; index++) {

    //                 const menuItem = activeMenuSub[index];
    //                 const menuName = menuItem.menuName;
    //              if (menuName === getMenuName("SamplesApproval")) 
    //                 { SampleApprovalTab = menuItem;}

                   
    //             }
    //         }
    //     }
    // }

    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
     let info;
    let activeMenu;
    let activeMenuSub;
    let OrderLabdipApprovalTab;

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
       // info = IsProduction === 1 ? 'Samples' : 'ProductionSample';
         info = IsProduction === 1 ? 'OrderLabDip' : (IsProduction === 2 ? 'ProductionOrderLabDip' : 'ProductionOrderLabDip');
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                    if (menuName === getMenuName("OrderLabdipApproval")) {
                            OrderLabdipApprovalTab = menuItem;
                           }
                     }
            }
        }
    }



    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");
    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        styleName: '',
        StyleNo: '',
        Supplier: '',
        SubmitDate: '',
        SendTo: "0",
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralLabdipTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNALabdibSubmittedOption: []
    }]);
    const values = [...inputFields]

    const [getSearchReqValues, setSearchReqValues] = useState({
        Status: 2,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });


    const SearchReqValues = { ...getSearchReqValues };
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [TNAId, setTNAId] = useState();
    const [StyleId, setStyleId] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [SwatchStatus, setSwatchStatus] = useState();
    const [LabdipApprovalList, setLabdipApprovalList] = useState([]);
    const [getOpenLabdipApppopup, setOpenLabdipApppopup] = useState(false);

    const [editLabdipApproval, seteditLabdipApproval] = useState(false);
    const [deleteLabdipApproval, setdeleteLabdipApproval] = useState(false);

    const [showmaunalclose, setshowmaunalclose] = useState(false);

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
    const [getComment, setComment] = useState(false);

    const [LabdipswatchDownload, setLabdipswatchDownload] = useState(false);
    const [LabdipswatchUpload, setLabdipswatchUpload] = useState(false);

  

    const [getsID, setsID] = useState(0);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        // { name: "Action", field: "", sortable: false },

    ];

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    useEffect(() => {
        if (!isLoadingOrderLabdip) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderLabdip])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchLabDip();

    }, [])

    useEffect(() => {
        commonParentExpand();
    }, [isReLoadingOrderLabdip])


    //#region  ------------------  search details --------------------------------
    const searchLabDip = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            , IsProduction: IsProduction
        };
        dispatch(GetOrderLapdipApprovalViewList(params));
        commonParentExpand();
    }

    const resetLabDip = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.Status = 2;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchLabDip();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.Status = 2;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------





    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, pantoneIdOrSwatchId) => {
        let btnToggle = document.getElementById("btn-toggle3_" + index);
        let iconToggle = document.getElementById("icon-toggle3_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setcurrentExpand({ currindex: index, currpantoneIdOrSwatchId: pantoneIdOrSwatchId });
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }

            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: pantoneIdOrSwatchId, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 4, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
                SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
            };

            dispatch(GetOrderLapdipApprovalViewList(params));

            // GeneralService.GetGeneralLapdipSubmissionApprovalList(params).then((response) => {
            //     if (response.data) {
            //         setApprovalList(response.data.pantoneCodeList);
            //         let SubList = [...ApprovalList];
            //         SubList[index].pantoneSubList = response.data.generalLabdipPantoneSubInfo ? response.data.generalLabdipPantoneSubInfo : [];
            //         SubList[index].criticalList = response.data.criticalList ? response.data.criticalList : [];
            //         setApprovalList(SubList);
            //     }
            // }).catch(() => { });
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (pantoneIdOrSwatchId) {
                dispatch(clearLapdipApprovalViewSubRow(index));
            }
        }
    };


    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    const handleserach = () => {

    }


    const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, StatusId, SwatchStatus) => {
       ;
        // TNAService.GetChildId(LabdibFabricInfoId, 1).then((response) => {
        //     if (response.data) {

        //         setSubmitChildId(response.data.outputResult);
        //     }
        // }).catch(() => { })




        TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
            if (response.data.length !== 0) {

                setLabdibFabricInfoId(LabdibFabricInfoId);
                setSubmittedOptionListId(LabdibFabricInfoId);
                setStyleId(StyleId)
                setTaskStatus(StatusId);
                setSwatchStatus(SwatchStatus)
                //  setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                    if (response.data) {
                        setLabdipApprovalList(response.data);
                        setOpenLabdipApppopup(true);
                    }
                }).catch(() => { })

            }
            else {
                Nodify('Warning!', 'warning', "Submission not yet added for this task");
                return false;
            }

        }).catch(() => { });

    }

    const handleLabDipApp = () => {

        setOpenLabdipApppopup(false);
        seteditLabdipApproval(false);
        setdeleteLabdipApproval(false);
        setLabdipswatchDownload(false);
        setLabdipswatchUpload(false);

    }

    const LapdipApprovalCallback = (value, getOrderinfo, Operation, swatchstatus) => {
        if (value === true) {
            setOpenLabdipApppopup(false);
            seteditLabdipApproval(false);
            setdeleteLabdipApproval(false);


            ReloadApproval();
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            searchLabDip();
            // handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);

        }
    }

    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 4, Status: SearchReqValues.Status ? SearchReqValues.Status : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };

        dispatch(GetOrderLapdipApprovalViewList(params));
    }

    const handleChildManualclose = (TaskName, TaskType, TaskID, ChildId, Status, Comment, CommentDate) => {
        showLoader();
        setChildId({ ChildId: ChildId, Status: Status });
        var mandata = { ...Manualclosedata }
        mandata.ManualCloseComment = Comment;
        mandata.ManualCloseDate = new Date(CommentDate);
        setManualclosedata(mandata);
        let Operation;
        setshowmaunalclose(true);


        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }
        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
            Operation = 6;
        }
        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
        }
        else {
            Operation = 8;
        }


        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
            SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
            Operation: Operation, ParentIdOrSubChildId: ChildId
        };

        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {
                let data = response.data.map((item) => {

                    if (item.subTaskInfoId === ChildId) {
                        item.isSelected = 1;

                        return item;
                    } else {
                        return item;
                    }
                });
                let isSelectAll = 0;
                if (data) {
                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                    isSelectAll = isSelectAll ? 1 : 0;
                }
                setselectall(isSelectAll);
                if (Status !== 3) {
                    setMultiCloseDatas(data);
                }
                else {
                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                }
                hideLoader();
            }

            hideLoader();
        }).catch(() => { hideLoader(); });
    }



    const handleChangeIsManualCloseComments = (event, index, subIndex, childIndex, feild) => {

        let input = '';


        setComment(false);
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            Manualclosedata.ManualCloseComment = input;
        }
        else {
            Manualclosedata.ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }

    }
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }




    const handledelete = () => {
       ;
        let Value = [];
        //  Value.TNAId = props.location.state.params.tnaid;
        Value.Id = ChildId.ChildId;
        setshowmaunalclose(true);
        setsID({ TNAManualClose: TNAManualCloseChildLibDip, showPopupDelete: true, Params: Value });
    }


    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            setshowmaunalclose(false)
            searchLabDip();
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setshowmaunalclose(true);
        }
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">

                           
                        <div className="col-md-2 min-height">
                            <div className='form-group'>
                                <label>Pantone Code / Swatch Name </label>
                                <span className='input-icon icon-right'>
                                    <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Pantone Code / Swatch Name" className="form-control" />

                                </span>

                            </div>

                        </div>

                        <div className="col-md-2 min-height">
                            <label htmlFor="Status">
                                Style Name
                            </label>
                            <span className="input-icon icon-right">
                                <input
                                    type="text"
                                    className="form-control StyleList"
                                    title={'Style Name'}
                                    placeholder={'Style Name'}
                                    value={SearchReqValues.StyleName}
                                    onChange={e => handleSearchChange(e, 'StyleName')}
                                />
                            </span>
                        </div>

                        <div className="col-md-2 min-height">
                            <label htmlFor="Status">
                                Style No
                            </label>
                            <span className="input-icon icon-right">
                                <input
                                    type="text"
                                    className="form-control StyleList"
                                    title={'Style No'}
                                    placeholder={'Style No'}
                                    value={SearchReqValues.StyleNo}
                                    onChange={e => handleSearchChange(e, 'StyleNo')}
                                />
                            </span>
                        </div>


                        <div className="col-md-2 min-height">
                            <div className="form-group">
                                <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                                <span className="input-icon icon-right">
                                    <input
                                        value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                        onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                        //onBlur={event => handleDateBlur()}
                                        type="text"
                                        className={'form-control'}
                                        placeholder="Select Date"
                                    />
                                    <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                </span>
                            </div>


                            {SearchReqValues.stateDate[0].isShow &&
                                <DateRangePicker
                                    //onChange={item => setStateDate([item.selection])}
                                    onChange={item => handlePopupDateselection(item)}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={SearchReqValues.stateDate}
                                    showDateDisplay={false}
                                    direction="vertical"
                                    className={'TNAReportDatePicker'}

                                />

                            }
                        </div>


                        <div className="col-md-2 min-height">
                            <label htmlFor="Status">
                                ID/PO NO
                            </label>
                            <span className="input-icon icon-right">
                                <input
                                    type="text"
                                    className="form-control StyleList"
                                    title={'Id Po No'}
                                    placeholder={'ID/PO NO'}
                                    value={SearchReqValues.IdPoNo}
                                    onChange={e => handleSearchChange(e, 'IdPoNo')}
                                />
                            </span>

                        </div>



                        <div className="col-md-2 min-height">
                            <div className="form-group">
                                <label htmlFor="SupplierID">
                                    Supplier
                                    {/* <span className="text-danger">*</span> */}
                                </label>
                                <span className="input-icon icon-right">
                                    <Reactselect className="basic-single" name="SupplierId"
                                        id={
                                            "SupplierId"
                                        }
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={event => handleSearchChange(event, 'SupplierId')}
                                        value={getSupplierList.filter(function (option) {
                                            return option.value === SearchReqValues.SupplierId;

                                        })}
                                        options={getSupplierList}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                    ></Reactselect>

                                </span>
                            </div>
                        </div>
                       
                        {/* <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-2">&nbsp;</div> */}
                        <div className="col-md-2 min-height"style={{ marginTop: '30px' }}>

                            <div className="form-group">
                                <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-danger" title="Reset" onClick={resetLabDip}
                                >
                                    <i className="fa fa-close"></i>
                                </button>
                            </div>
                        </div>


                        </div>



                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Approved Date</th>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>ID/PO NO</th>
                                        <th>Supplier</th>
                                        <th>Item Details (Fabric / Yarn)</th>
                                        <th>Task Holder / Follower</th>
                                        <th>Approval Target</th>
                                        <th>Approval Info</th>
                                        <th>Submission AWB #</th>
                                        <th style={{ display: OrderLabdipApprovalTab && OrderLabdipApprovalTab.isAdd === 1 ? '' : 'none' }} className="text-center fixed-column-header-Route">Action</th>
                                      
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        labdipApprovalList && labdipApprovalList.length > 0 ?
                                            <>
                                                {
                                                    labdipApprovalList && labdipApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle3_" + index} class="btn-toggle3 parentExpand" aria-expanded="false" onClick={() => handleToggle(index, inputField.pantoneIdOrSwatchId)} aria-labelledby="btn-toggle3 id-master">
                                                                            <i id={"icon-toggle3_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.pantoneCode
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="11">
                                                                    {inputField.imagePath ?
                                                                        <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                            <img className="width_manual"
                                                                                src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath}
                                                                                alt="Logo"
                                                                                onClick={
                                                                                    () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath)
                                                                                }
                                                                            />
                                                                        </div> :
                                                                        <div style={{ background: inputField.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                    }
                                                                </td>

                                                            </tr>
                                                            {
                                                                inputField.pantoneSubList && inputField.pantoneSubList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"15px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleName}>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.styleNo}>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.idpono}>
                                                                                {
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.itemDetails} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {
                                                                                    // <span> {subField.approvalInfo} </span>
                                                                                    <span> {subField.approvalInfo ? subField.approvalInfo : "-"} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {(subField.awbDate === null || subField.awbDate === '') ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td style={{ display: OrderLabdipApprovalTab && OrderLabdipApprovalTab.isAdd === 1 ? '' : 'none' }}>


                                                                                {
                                                                                    (subField.manualCloseComment !== '' && subField.manualCloseComment !== null && subField.manualCloseComment !== undefined) && subField.statusID === 3 ?
                                                                                        <button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"}
                                                                                        
                                                                                            title="View Approved Manual Close" onClick={() => handleChildManualclose(StaticTask.LAB_DIP_SUBMISSION, subField.taskType, subField.taskID, subField.childId, subField.statusID,
                                                                                                subField.manualCloseComment, subField.manualCloseDate)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        : ''
                                                                                }

                                                                                {
                                                                                    subField.taskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && subField.statusID === 3 && subField.isShowSubmitButton !== 0
                                                                                        && (subField.childActualEndDate !== 'Manual' && subField.childActualEndDate !== 'Existing') && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                         onClick={() => OpenLabdipApprovalpopup(subField.childId, subField.styleID,
                                                                                                subField.statusID, subField.swatchStatus)}
                                                                                        >
                                                                                            <i className="fa fa-eye "></i>
                                                                                        </button> : ''
                                                                                }
                                                                            </td>




                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="13" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>

            </div >

            {loader}

            {getOpenLabdipApppopup === true ? (
                <Modal
                    show={getOpenLabdipApppopup === true}
                    size="xl"
                    dragable
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="commonwidth"
                    onHide={() => handleLabDipApp()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                TaskStatus === 3 && editLabdipApproval === false ?
                                    "View " : editLabdipApproval === true ? 'Update ' : ''

                            }
                            Lab Dip  Approval



                            {

                                TaskStatus === 3 ?
                                    <>


                                        {
                                            SwatchStatus !== 3 ?
                                                <span title='Upload Approved Swatch Card' style={{ 'margin-left': '850px' }}>
                                                    <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                </span>
                                                : ''

                                        }

                                        &nbsp;
                                        {
                                            SwatchStatus === 3 ?
                                                <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditLabdipApproval(true)}
                                                    className="btn btn-info btn-xs edit">
                                                    <i className="fa fa-edit"></i>
                                                </span> :
                                                <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                    className="btn btn-info btn-xs edit">
                                                    <i className="fa fa-edit"></i>
                                                </span>

                                        }

                                        {/* <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditLabdipApproval(true)}
                                            className="btn btn-info btn-xs edit">
                                            <i className="fa fa-edit"></i>
                                        </span> */}
                                        &nbsp;
                                        <span title='Delete Form'
                                            className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdipApproval(true)}>
                                            <i className="fa fa-trash-o"></i>
                                        </span>
                                    </> : ''
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LabdibApproval props={props}
                            LapdipApprovalCallback={LapdipApprovalCallback}
                            StyleId={StyleId}
                            LabdibFabricInfoId={LabdibFabricInfoId}
                            SubmittedOptionListId={SubmittedOptionListId}
                            TaskStatus={TaskStatus}
                            LabdipApprovalList={LabdipApprovalList}
                            editLabdipApproval={editLabdipApproval}
                            deleteLabdipApproval={deleteLabdipApproval}
                            LabdipswatchDownload={LabdipswatchDownload}
                            LabdipswatchUpload={LabdipswatchUpload}
                            SwatchStatus={SwatchStatus}
                        // SubmitChildId={SubmitChildId}

                        ></LabdibApproval>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            ) : (
                ""
            )}

            {
                showmaunalclose === true ?
                    <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowmaunalclose(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Manual Close
                                &nbsp; &nbsp;
                                {
                                    ChildId.Status === 3 &&
                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                }

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}>

                                            </textarea>
                                        </td>
                                        <td>
                                            <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                    "HolidayDate"
                                                }
                                                selected={
                                                    Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                // style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>ID Po</th>
                                        <th>Details</th>
                                        <th>Task Holder / Follower</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.idPoNo}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.taskOwnerNameList}</td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal> : ''
            }

            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Deleted Successfully'}
            /> : null}



        </Fragment >

    )
}

export default React.memo(LabDipViewApprovalTab);