import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SKU from "../Style/SKU/SKUList";
import AddEditSKU from "../Style/SKU/SKU";
import Program from "../Style/Program/ProgramList";
import AddEditPrograms from "../Style/Program/Program";
import Style from "../Style/StyleRegister";
import StyleService from "../../services/Style/styleService";
import moment from "moment";
import ReactNotification from 'react-notifications-component';
import Nodify from "../Common/ReactNotification"
import SearchRetain from "../Common/SearchRetain";
import Measurement from "../Style/Measurement/MeasurementList";
import AddEditMeasurement from "../Style/Measurement/Measurement";
import SKUService from "../../services/Style/SKUService";
import ProgramService from "../../services/Style/ProgramService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import CommonStyle from "../Style/CommonStyle";

import measurementService from "../../services/Style/measurementService";
import AddEditStrikeOffList from "../Style/StrikeOff/StyleStrikeoff";
import ConfirmationPopup from '../Common/ConfirmationPopup';
import StrikeoffService from "../../services/Style/StrikeoffService";
import StyleTrim from "../Style/Trim/StyleTrim";
import { loadSKUListByStyleID, loadProgramListByStyleID } from "../../actions/style";
import { Modal, Button } from "react-bootstrap";
import ViewTNA from "../Style/ViewTNA/ViewTNA";
import StyleMail from './Email/Email';
import StyleEmailList from './Email/EmailList';
import StyleQCNotes from './QCNotes/qcnotes';
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import QCNoteService from '../../services/Style/QCNoteService';
//import SimpleImageSlider from "react-simple-image-slider";

import SampleReqAdd from "./SampleRequest/SampleReqAdd";
import SampleReqList from "./SampleRequest/SampleReqList";
import { login } from './../../actions/auth';


const StyleListRegister = (props) => {

    const [submitted, setSubmitted] = useState(false);
    const [getStyleInfoList, setStyleInfoList] = useState([]);
    const [getFabricList, setFabricList] = useState([]);
    const [getRemarksList, setRemarksList] = useState([]);
    const [getPage, setPage] = useState([]);
    // const [getSKUList, setSKUList] = useState([]);
    const [getSKUProgram, setSKUProgram] = useState([]);
    const [getSKUSampleApproval, setSKUSampleApproval] = useState([]);
    const [getBindSKU, setBindSKU] = useState([]);
    const [getProgramList, setProgramList] = useState([]);
    const [getBindProgram, setBindProgram] = useState([]);
    //const [getBindMeasurement, setBindMeasurement] = useState([]);
    const [getButtonValue, setButtonValue] = useState("Save");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getHeader, setHeader] = useState("");
    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsSKUParentCallback, setIsSKUParentCallback] = useState(false);
    const [IsProgramParentCallback, setIsProgramParentCallback] = useState(false);
    const [IsMeasurementParentCallback, setIsMeasurementParentCallback] = useState(false);
    const [IsMeasurementChildCallback, setIsMeasurementChildCallback] = useState(false);
    const [IsSampleReqCallback, setSampleReqCallback] = useState(false);
    const [getresponse, setresponse] = useState([]);

    const [getFabricListDropdown, setFabricListDropdown] = useState([]);
    const [getStyleInfo, setStyleInfo] = useState([]);
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 190),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const [getStyleID, setStyleID] = useState(0);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });
    //Strike Off use
    const [getSizeList, setSizeList] = useState([]);
    const [getSizeListmeasurement, setSizeListmeasurement] = useState([]);
    const [IsFromMaster, setIsFromMaster] = useState(undefined);
    const [IsFromTrim, setIsFromTrim] = useState(undefined);
    const [getStyle_StrikeOff, setStyle_StrikeOff] = useState(undefined);
    //Strike Off use

    const [getSKUResult, setSKUResult] = useState([]);

    //const [getMeasurementList, setMeasurementList] = useState([]);
    const [getMeasurement, setMeasurement] = useState([]);

    const [getSamplereq, setSamplereq] = useState([]);
    const [getQCNotesCallback, setQCNotesCallback] = useState([]);
    const [getIsDataLoaded, setIsDataLoaded] = useState(false);
    const [getMailreq, setMailreq] = useState([]);
    const [getExistingSamplereq, setExistingSamplereq] = useState([]);
    //WashCare

    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [Buttonview, SetButtonview] = useState("Hide");
    // const [getWashCareList, setWashCareList] = useState([]);

    const [getBarFiles, setBarFiles] = useState([]);
    const [getBarFilesname, setBarFilesname] = useState([]);
    const [BarisOpenimg, setBarIsOpenimg] = useState(false);
    const [getBarImagename, setBarImagename] = useState();
    const [BarButtonview, SetBarButtonview] = useState("Hide");

    const [IsStyleTabClick, setIsStyleTabClick] = useState(false);
    const [getIspage, setIspage] = useState("");
    const [getDisableMeasureSize, setDisableMeasureSize] = useState(false);
    const [getDisableSampleSize, setDisablesampleeSize] = useState(false);
    const [getQCNotes, setQCNotes] = useState([]);
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const dispatch = useDispatch();
    // useEffect(() => {
    //     function disableF5(e) { if ((e.which || e.keyCode) == 116 || (e.which || e.keyCode) == 82) e.preventDefault(); };

    //     $(document).ready(function () {
    //         $(document).on("keydown", disableF5);
    //     });
    // }, [])
    //reducer
    // // If check production or sampling
    // const activeMenus = useSelector((state) => state.auth.activeMenuList);
    // let IsProduction = activeMenus.IsProduction;
    // // End
    useEffect(() => {
        if (buttonLoader) {
            showLoader();
        } else {
            hideLoader();
        }
    }, [buttonLoader]);
    useEffect(() => {
        ProgramService.GetAllPrograms(props.location.state === undefined ? 0 : props.location.state.params.styleID).then((res) => {
            setresponse(res.data)
        })
    }, [])

    useEffect(() => {

        let getStyle = SetStyle(props);
        console.log(getPage)
        console.log(getIsDataLoaded)
        console.log(getButtonValue)
        //getStyle.IsProduction = IsProduction;
        setStyleInfoList(getStyle);
        if (props.location.state !== undefined) {
            measurementService.GetMeasurement(props.location.state.params.styleID).then((response) => {
                if (response.data.length > 0) {
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].measurementGrandingSize > 0) {
                            setDisableMeasureSize(true);
                        }
                        else {
                            setDisableMeasureSize(false);

                        }
                    }
                } else {
                    setDisableMeasureSize(false);
                }
            })

            StyleService.GetSamplereqList(1, props.location.state.params.styleID, 0).then((response) => {
                if (response.data.length > 0) {
                    setDisablesampleeSize(true);
                }
                else {
                    setDisablesampleeSize(false);

                }

            });
            //setIspage(Page)
            setIsStyleTabClick(true);
        }
        // if (props.location.state !== undefined) { loadList(props.location.state.params.styleID); }
        loadList(props.location.state && props.location.state.params.styleID, IsProduction);

    }, [props.location.state !== undefined]);


    //*******Quick Style Create ***********\\
    useEffect(() => {

        if (QuickStyleCreateDetail.isQuickStyle) {
            let getStyle = SetQuickStyleCreate();

            setStyleInfoList(getStyle);
            //loadList(props.location.state.params.styleID);
            loadList(props.location.state && props.location.state.params.styleID, IsProduction);
        }

        // loadList();

    }, []);
    const reducerStateStyle = useSelector((state) => state).style;
    const QuickStyleCreateDetail = reducerStateStyle.QuickStyleCreateDetail;

    const [getTabs, setTabs] = useState({
        SkuInfo: [], ProgramInfo: []
    });

     // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
     // End
    
     const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `Style${baseName}`
            : IsProduction === 2
            ? `ProductionStyle${baseName}`
            : undefined;
    };

    //Enable Menu
    let info;
    let activeMenu;
    let activeMenuSub;
    let SkuInfo;
    let ProgramInfo;
    let StrikeInfo;
    let TrimInfo;
    let MeasurementInfo;
    let SampleInfo;
    let StyleWashCareInfo;
    let StyleBarCodeInfo;
    let StylNotificationInfo;
    let StylQCNotesInfo;

    let TNAViewInfo;
    let StyleInfoQuick = [];
    let ProgramInfoQuick = [];
    let MeasurementInfoQuick = [];
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {

        info = IsProduction === 1 ? 'Style' : (IsProduction === 2 ? 'ProductionStyle' : undefined);
        activeMenu = menulist.filter(i => i.menuName ===  info );
        //activeMenu = menulist.filter(i => i.menuName === 'Style');


        StyleInfoQuick.Season = menulist.filter(i => i.menuName === 'Season')
        StyleInfoQuick.Brand = menulist.filter(i => i.menuName === 'Brand')
        StyleInfoQuick.GroupType = menulist.filter(i => i.menuName === 'GroupType')
        StyleInfoQuick.EndCustomer = menulist.filter(i => i.menuName === 'EndCustomer')
        StyleInfoQuick.Story = menulist.filter(i => i.menuName === 'Story')
        StyleInfoQuick.Theme = menulist.filter(i => i.menuName === 'Theme')
        StyleInfoQuick.Category = menulist.filter(i => i.menuName === 'Category')
        StyleInfoQuick.Gender = menulist.filter(i => i.menuName === 'Gender')
        StyleInfoQuick.Size = menulist.filter(i => i.menuName === 'Size')
        StyleInfoQuick.ProcessType = menulist.filter(i => i.menuName === 'ProcessType')
        StyleInfoQuick.FabricType = menulist.filter(i => i.menuName === 'FabricType')
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                
                    if (menuName === getMenuName("SKU")) {
                        SkuInfo = menuItem;
                    } else if (menuName === getMenuName("Program")) {
                        ProgramInfo = menuItem;
                
                        // Fetch additional data for ProgramInfoQuick
                        ProgramInfoQuick = {
                            PantoneType: menulist.filter(i => i.menuName === 'PantoneType'),
                            Pantone: menulist.filter(i => i.menuName === 'Pantone'),
                            Swatch: menulist.filter(i => i.menuName === 'Swatch'),
                            ProcessType: menulist.filter(i => i.menuName === 'ProcessType'),
                        };
                    }
                    else if (menuName === getMenuName("Strikeoff")) 
                    {StrikeInfo= menuItem;}
                    else if (menuName === getMenuName("trim")) 
                    {  TrimInfo= menuItem; }
                    else if (menuName === getMenuName("Measurement")) 
                        {  TrimInfo= menuItem;  MeasurementInfoQuick.MeasurementPoint = menulist.filter(i => i.menuName === 'MeasurementPoint')}
                    else if (menuName === getMenuName("Sample")) 
                        {  SampleInfo= menuItem; }
                    else if (menuName === getMenuName("TNAView")) 
                        {  MeasurementInfo= menuItem; }
                    else if (menuName === getMenuName("WashCare")) 
                        {  StyleWashCareInfo= menuItem; }
                    else if (menuName === getMenuName("BarCode")) 
                        {  StyleBarCodeInfo= menuItem; }
                    else if (menuName === getMenuName("Notification")) 
                        {  StylNotificationInfo= menuItem; }
                    else if (menuName === getMenuName("QCNotes")) 
                        {  StylQCNotesInfo= menuItem; }
                }
                // for (let index = 0; index < activeMenuSub.length; index++) {

                //     if (activeMenuSub[index].menuName ===  IsProduction === 1 ? 'StyleSKU' : (IsProduction === 2 ? 'ProductionStyleSKU' : undefined)) {
                //         SkuInfo = activeMenuSub[index];
                //         //setTabs({ SkuInfo: activeMenuSub[index] });


                //         // getTabs(activeMenuSub[index]);
                //         // setTabs(SkuInfo: activeMenuSub[index]);

                //     }
                //     else if (activeMenuSub[index].menuName ===   IsProduction === 1 ? 'StyleProgram' : (IsProduction === 2 ? 'ProductionStyleProgram' : undefined))  {
                //         ProgramInfo = activeMenuSub[index];
                //         ProgramInfoQuick.PantoneType = menulist.filter(i => i.menuName === 'PantoneType')
                //         ProgramInfoQuick.Pantone = menulist.filter(i => i.menuName === 'Pantone')
                //         ProgramInfoQuick.Swatch = menulist.filter(i => i.menuName === 'Swatch')
                //         ProgramInfoQuick.ProcessType = menulist.filter(i => i.menuName === 'ProcessType')

                //     }
                //     else if (activeMenuSub[index].menuName === 'StyleStrikeoff') {
                //         StrikeInfo = activeMenuSub[index];

                //     }
                //     else if (activeMenuSub[index].menuName ===  IsProduction === 1 ? 'Styletrim' : (IsProduction === 2 ? 'ProductionStyletrim' : undefined)) {
                //         TrimInfo = activeMenuSub[index];

                //     }
                //     else if (activeMenuSub[index].menuName === IsProduction === 1 ? 'StyleMeasurement' : (IsProduction === 2 ? 'ProductionStyleMeasurement' : undefined )) {
                //         MeasurementInfo = activeMenuSub[index];
                //         MeasurementInfoQuick.MeasurementPoint = menulist.filter(i => i.menuName === 'MeasurementPoint')

                //     }
                //     else if (activeMenuSub[index].menuName === IsProduction === 1 ? 'StyleSample' : (IsProduction === 2 ? 'ProductionStyleSample' : undefined)) {
                //         SampleInfo = activeMenuSub[index];

                //     }
                //     else if (activeMenuSub[index].menuName ===   IsProduction === 1 ? 'StyleTNAView' : (IsProduction === 2 ? 'ProductionStyleTNAView' : undefined))  {
                //         TNAViewInfo = activeMenuSub[index];

                //     }

                //     else if (activeMenuSub[index].menuName ===   IsProduction === 1 ? 'StyleWashCare' : (IsProduction === 2 ? 'ProductionStyleWashCare' : undefined))  {
                //         StyleWashCareInfo = activeMenuSub[index];

                //     }
                //     else if (activeMenuSub[index].menuName ===  IsProduction === 1 ? 'StyleBarCode' : (IsProduction === 2 ? 'ProductionStyleBarCode' : undefined))  {
                //         StyleBarCodeInfo = activeMenuSub[index];

                //     }
                //     else if (activeMenuSub[index].menuName ===  IsProduction === 1 ? 'StyleNotification' : (IsProduction === 2 ? 'ProductionStyleNotification' : undefined)) {
                //         StylNotificationInfo = activeMenuSub[index];
                //     }
                //     else if (activeMenuSub[index].menuName ===  IsProduction === 1 ? 'StyleQCNotes' : (IsProduction === 2 ? 'ProductionStyleQCNotes' : undefined)) {
                //         StylQCNotesInfo = activeMenuSub[index];
                //     }
                // }
            }
        }
    }

    // End Enable Menu


// //     // Enable Menu
// // let info, activeMenu, activeMenuSub;
// // let SkuInfo, ProgramInfo, StrikeInfo, TrimInfo, MeasurementInfo;
// // let SampleInfo, StyleWashCareInfo, StyleBarCodeInfo, StylNotificationInfo;
// // let TNAViewInfo;

// // let StyleInfoQuick = {};
// // let ProgramInfoQuick = {};
// // let MeasurementInfoQuick={};

// // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
// // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);

// // if (menulist && menulistSub && menulistSub.length > 0) {
// //     const getMenuName = (baseName) => {
// //         return IsProduction === 1
// //             ? `Style${baseName}`
// //             : IsProduction === 2
// //             ? `ProductionStyle${baseName}`
// //             : undefined;
// //     };

// //     info = IsProduction === 1 ? "Style" : IsProduction === 2 ? "ProductionStyle" : undefined;
// //     activeMenu = menulist.filter((i) => i.menuName === info);

// //     StyleInfoQuick = {
// //         Season: menulist.filter((i) => i.menuName === "Season"),
// //         Brand: menulist.filter((i) => i.menuName === "Brand"),
// //         GroupType: menulist.filter((i) => i.menuName === "GroupType"),
// //         EndCustomer: menulist.filter((i) => i.menuName === "EndCustomer"),
// //         Story: menulist.filter((i) => i.menuName === "Story"),
// //         Theme: menulist.filter((i) => i.menuName === "Theme"),
// //         Category: menulist.filter((i) => i.menuName === "Category"),
// //         Gender: menulist.filter((i) => i.menuName === "Gender"),
// //         Size: menulist.filter((i) => i.menuName === "Size"),
// //         ProcessType: menulist.filter((i) => i.menuName === "ProcessType"),
// //         FabricType: menulist.filter((i) => i.menuName === "FabricType"),
// //     };

// //     if (activeMenu.length > 0) {
// //         activeMenuSub = menulistSub.filter((i) => i.mainMenuID === activeMenu[0].menuID);

// //         if (activeMenuSub.length > 0) {
// //             activeMenuSub.forEach((menu) => {
// //                 switch (menu.menuName) {
// //                     case getMenuName("SKU"):
// //                         SkuInfo = menu;
// //                         break;
// //                     case getMenuName("Program"):
// //                         ProgramInfo = menu;
// //                         ProgramInfoQuick = {
// //                             PantoneType: menulist.filter((i) => i.menuName === "PantoneType"),
// //                             Pantone: menulist.filter((i) => i.menuName === "Pantone"),
// //                             Swatch: menulist.filter((i) => i.menuName === "Swatch"),
// //                             ProcessType: menulist.filter((i) => i.menuName === "ProcessType"),
// //                         };
// //                         break;
// //                     case "StyleStrikeoff":
// //                         StrikeInfo = menu;
// //                         break;
// //                     case getMenuName("trim"):
// //                         TrimInfo = menu;
// //                         break;
// //                     case getMenuName("Measurement"):
// //                         MeasurementInfo = menu;
// //                         MeasurementInfoQuick = {
// //                             MeasurementPoint: menulist.filter((i) => i.menuName === "MeasurementPoint"),
// //                         };
// //                         break;
// //                     case getMenuName("Sample"):
// //                         SampleInfo = menu;
// //                         break;
// //                     case getMenuName("TNAView"):
// //                         TNAViewInfo = menu;
// //                         break;
// //                     case getMenuName("WashCare"):
// //                         StyleWashCareInfo = menu;
// //                         break;
// //                     case getMenuName("BarCode"):
// //                         StyleBarCodeInfo = menu;
// //                         break;
// //                     case getMenuName("Notification"):
// //                     case getMenuName("QCNotes"):
// //                         StylNotificationInfo = menu;
// //                         break;
// //                     default:
// //                         break;
// //                 }
// //             });
// //         }
// //     }
// // }

    
    let getSKUList = [];
    if (props.location.state && props.location.state.params) {
        getSKUList = reducerStateStyle.SKUList;
    }

    let EmptySKU = [{
        SKUID: 0, SKUName: '', SKUFrontPicName: "", SKUFrontPicPath: "",
        SKUBackPicName: "", SKUBackPicPath: "", AdditionalPicName1: "", AdditionalPicPath1: "",
        AdditionalPicName2: "", AdditionalPicPath2: "", AdditionalPicName3: "", AdditionalPicPath3: "",
        AdditionalPicName4: "", AdditionalPicPath4: "", PickStylePic: 0, IsDeleted: 0,
    }]

    let EmptySampleReq = [{
        StyleSampleRequestID: 0, SampleRequestID: 0, SkuColorNameIds: '', QtyIds: ''

    }]

    let EmptyProgram = [{
        StyleProgramID: 0, SKUID: '', PantoneTypeID: 0, PantoneID: 0,
        PantoneColor: '', RefColorName: '', BuyerRefNo: '', SwatchImageID: 0, ProcessTypeName: '', ApprovalTypeID: 0, swatchImagePath: "",
        ApprovalTypeName: '', Remark: '', Cirtical: 0, IndexName: '', IsDeleted: 0, FabricID: 0, FabricPartID: 0, LabdibFabricInfoId: 0,
    }]
    let EmptyMeasurement = [{
        StyleMeasurementID: 0,
        MeasurementSpec: '',
        MeasurementGrandingSize: 0,
        IsDeleted: 0,
        Category: [{
            StyleMeasurementCategoryID: 0,
            MeasurementCategoryID: 0,
            IsDeleted: 0,
            IndexName: '',
            MeasuremnentSpecImportName: '',
            MeasuremnentSpecImportPath: '',
            Point: [{
                MeasurementPoints: '',
                MeasurementGranding: '',
                TolerancePlus: 0,
                ToleranceMinus: 0,
                GradingSizes: '',
                GradingSizesId: '',
                GradingSizesValue: '',
                arrGradingSizes: [],
                Remark: '',
                IndexName: '',
                IsDeleted: 0,
            }]
        }],
    }]

    let EmptyCritical = [{ ProgramCirticalInfoID: 0, IsCritical: '', TargettoClose: '', CompletedOn: '', RemindMe: 0, IndexName: '', IsDeleted: 0, }];
    let EmptyManual = [{ IsManual: '', ManualCloseComment: '', ManualCloseDate: '', IsDeleted: 0, CheckSave: false, StyleProgramIndexName: '', Files: [] }]
    let Emptyfile = [{ StyleProgramManualFilelId: 0, FileType: '', FileName: '', FilePath: '', StyleProgramIndexName: '', StyleProgramFileIndexName: "", IsDeleted: 0 }]

    // let EmptyFabric = [{ ProgramFabricInfoID: 0, FabricID: 0, FabricPartID: 0, IndexName: '', IsDeleted: 0, }];

    const Pages = (localStorage.getItem("Page"));
    function SetStyle(props) {

        let StyleValue = {};
        setPage('ADDStyle');
        setHeader('Add Style'); //check dependencies before altering this header value.
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.styleID !== 0) {
                    setHeader('Edit Style'); //check dependencies before altering this header value.
                    setPage(Pages !== null ? Pages : 'LISTStyle');
                    if (props.location.state.params.FromMaster !== undefined || props.location.state.params.TrimFromMaster !== undefined) { setPage('LISTSTRIKEOFF'); }
                    setButtonValue('Update');
                    setStyle_StrikeOff(props.location.state.params);
                    StyleValue.Operation = 2
                    StyleValue.BuyerID = props.location.state.params.buyerID
                    StyleValue.SeasonID = props.location.state.params.seasonID
                    StyleValue.BrandID = props.location.state.params.brandID
                    StyleValue.GroupTypeID = props.location.state.params.groupTypeID
                    StyleValue.StyleName = props.location.state.params.styleName
                    StyleValue.StyleNo = props.location.state.params.styleNo
                    StyleValue.CategoryID = props.location.state.params.categoryID
                    StyleValue.GenderID = props.location.state.params.genderID
                    StyleValue.SizeID = props.location.state.params.sizeID
                    StyleValue.ProcessType = props.location.state.params.processType
                    StyleValue.ProcessTypeID = props.location.state.params.processTypeId
                    StyleValue.IDPoNo = props.location.state.params.idPoNo
                    StyleValue.StyleLogoName = props.location.state.params.styleLogoName
                    StyleValue.StyleLogoPath = props.location.state.params.styleLogoPath
                    StyleValue.CubeEmployeeName = props.location.state.params.cubeEmployeeName
                    StyleValue.Importance = props.location.state.params.importance
                    StyleValue.EndCustomerID = props.location.state.params.endCustomerID
                    StyleValue.StoryID = props.location.state.params.storyID
                    StyleValue.ThemeID = props.location.state.params.themeID
                    //StyleValue.BuyerEmployeeID = props.location.state.params.buyerEmployeeID
                    StyleValue.BuyerEmployeeIdList = props.location.state.params.buyerEmployeeIdList
                    StyleValue.CubeEmployeeIdList = props.location.state.params.cubeEmployeeIdList
                    StyleValue.SupplierEmployeeIdList = props.location.state.params.supplierEmployeeIdList
                    StyleValue.IspurchaseOrderMapped = props.location.state.params.ispurchaseOrderMapped

                    StyleValue.StyleID = props.location.state.params.styleID
                    StyleValue.IsStoryMandatory = props.location.state.params.isStoryMandatory
                    StyleValue.IsThemeMandatory = props.location.state.params.isThemeMandatory
                    //StyleValue.IsProduction = props.location.state.params.isProduction

                    StyleService.LoadFabricDetails(StyleValue.GroupTypeID)
                        .then((response) => {
                            if (response.data) {
                                StyleValue.FabricListInfo = response.data;
                                //setFabricList(response.data);
                            }
                        })
                        .catch(() => { });

                    setStyleID(StyleValue.StyleID);
                    return StyleValue;
                }
            }
        } else { return StyleValue; }
    }

    function loadList(styleID, IsProduction) {

        if (props.location.state || styleID !== undefined) {
            let StyleID;
            if (props.location.state && props.location.state.params) {
                setStyleInfo(props.location.state.params);
                StyleID = props.location.state.params.styleID;
            } else if (styleID !== undefined) {
                StyleID = styleID;
            }
            ;
            StyleService.GetStyleList(StyleID, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
                if (response.data) {
                    setStyleInfo(response.data.item1[0]);
                }
            }).catch(() => { hideLoader(); });


            dispatch(loadSKUListByStyleID(StyleID));
            // SKUService.LoadSKUList(StyleID).then((response) => {
            //     if (response.data) {
            //         setSKUList(response.data);
            //     }
            // }).catch(() => { hideLoader(); });

            SKUService.LoadSkuNameList(StyleID).then((response) => {
                if (response.data) {
                    setSKUProgram(response.data);
                }
            }).catch(() => { hideLoader(); });
            dispatch(loadProgramListByStyleID(StyleID));
            // ProgramService.GetAllPrograms(StyleID).then((response) => {
            //     if (response.data) {
            //         let Program = response.data.program;
            //         let Fabric = response.data.fabric;
            //         let Critical = response.data.critical;
            //         Program.forEach((pro, i) => {
            //             Fabric.forEach((Fab, j) => {
            //                 if (Fab.indexName === pro.indexName) {
            //                     if (pro.Fabric === undefined) {
            //                         pro.Fabric = [];
            //                     }
            //                     pro.Fabric.push(Fab);
            //                 }
            //             });
            //             Critical.forEach((crit, k) => {
            //                 if (crit.indexName === pro.indexName) {
            //                     if (pro.IsCriticals === undefined) {
            //                         pro.IsCriticals = [];
            //                     }
            //                     pro.IsCriticals.push(crit);
            //                 }
            //             });
            //         });
            //         setProgramList(Program);
            //     }
            // }).catch(() => { });

            ProgramService.LoadStyleFabricDetailsList(StyleID).then((response) => {
                if (response.data) {
                    setFabricListDropdown(response.data);
                }
            }).catch(() => { hideLoader(); });

            QCNoteService.LoadStyleBasedQCNoteList(StyleID).then((response) => {
                if (response.data) {
                    setQCNotes(response.data);
                }
            }).catch(() => { hideLoader(); });
            // Sample Approval List
            SKUService.LoadSKUSamplesApprovalList(StyleID).then((response) => {
                if (response.data) {
                    setSKUSampleApproval(response.data);
                }
            }).catch(() => { hideLoader(); });

            // Strikeoff Value Bind

            if (props.location.state !== undefined) {
                if (props.location.state.params.FromMaster !== undefined) {
                    setIsFromTrim();
                    setIsFromMaster(props.location.state.params.FromMaster);
                    if (props.location.state.params.FromMaster === "PickFromMaster1" || props.location.state.params.FromMaster === "PickFromMaster2") {
                        if (props.location.state.params.FromMaster === "PickFromMaster1") {
                            Nodify('success!', 'success', "Strike Off Information added successfully.")
                        }
                        else if (props.location.state.params.FromMaster === "PickFromMaster2") {
                            Nodify('success!', 'success', "Strike Off Information updated successfully.")
                        }
                        setIsFromMaster("PickFromMaster");
                    }
                    else {
                        setIsFromMaster("PickFromMaster");
                    }
                    let StyleId = props.location.state.params.styleID;
                    window.history.replaceState(null, '');
                    ;
                    GetStyleList_StrikeOff(StyleId, IsProduction);
                    props.location.state.params.FromMaster = undefined;
                }
                if (props.location.state.params.TrimFromMaster !== undefined) {
                    setIsFromMaster();
                    setIsFromTrim(props.location.state.params.TrimFromMaster);
                    if (props.location.state.params.TrimFromMaster === "TrimPickFromMaster1" || props.location.state.params.TrimFromMaster === "TrimPickFromMaster2") {
                        if (props.location.state.params.TrimFromMaster === "TrimPickFromMaster1") {
                            Nodify('success!', 'success', "Trim Information added successfully.")
                        }
                        else if (props.location.state.params.TrimFromMaster === "TrimPickFromMaster2") {
                            Nodify('success!', 'success', "Trim Information updated successfully.")
                        }
                        setIsFromTrim("TrimPickFromMaster");
                    }
                    else {
                        setIsFromTrim("TrimPickFromMaster");
                    }
                    let StyleId = props.location.state.params.styleID;
                    window.history.replaceState(null, '');
                    ;
                    GetStyleList_StrikeOff(StyleId, IsProduction);
                    //Temp Solution
                    props.location.state.params.TrimFromMaster = undefined;
                }
            }

            const selectAllOption = {
                value: "-1",
                label: "Select All Sizes"
            };

            StrikeoffService.LoadGroupSizeDetailsList(StyleID).then((response) => {
                if (response.data) {
                    setSizeListmeasurement(response.data);
                    setSizeList([selectAllOption, ...response.data]);
                }
            }).catch(() => { });

            StyleService.LoadWashCareList(styleID).then((response) => {
                if (response.data) {
                    var files = [];
                    response.data.forEach(element => {
                        files.push({
                            StyleWashCareFileId: element.styleWashCareFileId,
                            FileName: element.fileName,
                            FilePath: element.filePath,
                            FileType: element.fileType,
                        })
                    })

                    setFiles(files);


                }
            })
                .catch(() => { });

            StyleService.LoadBarcodesList(StyleID).then((response) => {
                if (response.data) {
                    var files = [];
                    response.data.forEach(element => {
                        files.push({
                            StyleBarcodesFileId: element.styleBarcodesFileId,
                            FileName: element.fileName,
                            FilePath: element.filePath,
                            FileType: element.fileType,
                        })
                    })

                    setBarFiles(files);
                }
            })
                .catch(() => { });


        }
    }


    // useEffect(() => {
    //
    //     StyleService.LoadWashCareList(props.location.state.params.styleID)

    //         .then((response) => {
    //             if (response.data) {
    //                 var files = [];
    //                 response.data.forEach(element => {
    //                     files.push({
    //                         StyleWashCareFileId: element.styleWashCareFileId,
    //                         FileName: element.fileName,
    //                         FilePath: "Images/Style/WashCare/" + element.filePath,
    //                         FileType: element.fileType,
    //                     })
    //                 })

    //                 setFiles(files);


    //             } else {
    //             }
    //         })
    //         .catch(() => { });


    // }, []);
    // useEffect(() => {
    //
    //     StyleService.LoadBarcodesList(props.location.state.params.styleID)

    //         .then((response) => {
    //             if (response.data) {
    //                 var files = [];
    //                 response.data.forEach(element => {
    //                     files.push({
    //                         StyleBarcodesFileId: element.styleBarcodesFileId,
    //                         FileName: element.fileName,
    //                         FilePath: "Images/Style/BarCode/" + element.filePath,
    //                         FileType: element.fileType,
    //                     })
    //                 })

    //                 setBarFiles(files);


    //             } else {
    //             }
    //         })
    //         .catch(() => { });


    // }, []);

    function SetQuickStyleCreate() {
        let StyleValue = {};
        setPage('ADDStyle');
        setHeader('Add Style');
        // setHeader('Edit Style');
        // setPage('LISTStyle');
        // setButtonValue('Update');
        let params = QuickStyleCreateDetail.LastStyle;
        //setStyle_StrikeOff(props.location.state.params);
        StyleValue.Operation = 1;
        StyleValue.BuyerID = params.buyerID;
        StyleValue.SeasonID = params.seasonID;
        StyleValue.BrandID = params.brandID;
        StyleValue.StoryID = params.storyID;
        StyleValue.ThemeID = params.themeID;
        StyleValue.IDPoNo = params.idPoNo;
        StyleValue.GenderID = params.genderID;
        StyleValue.SizeID = params.sizeID;
        StyleValue.BuyerEmployeeID = params.buyerEmployeeID;

        // StyleValue.BuyerEmployeeIdList = params.buyerEmployeeID;
        // StyleValue.CubeEmployeeIdList = params.cubeEmployeeID;
        // StyleValue.SupplierEmployeeIdList = params.supplierEmployeeID;

        StyleValue.CubeEmployeeName = params.cubeEmployeeName;
        StyleValue.EndCustomerID = params.endCustomerID;
        StyleValue.GroupTypeID = params.groupTypeID;

        StyleValue.ProcessType = "";
        StyleValue.ProcessTypeID = 0;
        StyleValue.Importance = 0;
        StyleValue.IsStoryMandatory = 0;
        StyleValue.IsThemeMandatory = 0;
        StyleValue.StyleLogoName = "";
        StyleValue.StyleLogoPath = "";
        StyleValue.CategoryID = 0;
        StyleValue.StyleName = "";
        StyleValue.StyleNo = "";
        StyleValue.StyleID = 0;

        // StyleService.LoadFabricDetails(StyleValue.GroupTypeID)
        //     .then((response) => {
        //         if (response.data) {
        //             StyleValue.FabricListInfo = response.data;
        //             //setFabricList(response.data);
        //         }
        //     })
        //     .catch(() => { });

        return StyleValue;
    }


    //const [getprevPage, setprevPage] = useState(undefined);
    const pageRedirect = (Page) => {

        let Pageset = "";
        if (getHeader === "Add Style") {
            if (Page === 'Style') {
                Pageset = Page;

            }
            if (Page === 'SKU') {
                Pageset = getSKUList.length > 0 ? '' : 'ADD';
            } if (Page === 'PROGRAM') {
                Pageset = getProgramList.length > 0 ? '' : 'ADD';
            }
            if (Page === 'MEASUREMENT') {
                setIsMeasurementParentCallback(false);
                setIsMeasurementChildCallback(false);
                Pageset = getMeasurement.length > 0 ? '' : 'ADD';
            }
        } else {
            if (Page === 'MEASUREMENT') {
                setIsMeasurementParentCallback(false);
                setIsMeasurementChildCallback(false);
            }
            if (Page === 'PROGRAM') {
                setIsProgramParentCallback(false);
                SKUService.LoadSkuNameList(props.location.state?.params.styleID).then((response) => {
                    if (response.data) {
                        setSKUProgram(response.data);
                    }
                }).catch(() => { hideLoader(); });
            }
            if (Page === 'SKU') {
                setIsSKUParentCallback(false);

            }
            if (Page === 'Email') {
                if (props.location.state !== undefined)
                    props.location.state.params.EmailNotification = false;
            }
            if (Page === 'StyleQCNotes') {

            }


            Pageset = "EDIT";
        }

        if (getHeader === 'Edit Style' || getHeader === "Add Style") {


            measurementService.GetMeasurement(props.location.state?.params.styleID).then((response) => {
                if (response.data.length > 0) {
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].measurementGrandingSize > 0) {
                            setDisableMeasureSize(true);
                        }
                        else {
                            setDisableMeasureSize(false);

                        }
                    }
                } else {
                    setDisableMeasureSize(false);
                }
            })

            StyleService.GetSamplereqList(1, props.location.state?.params.styleID, 0).then((response) => {
                if (response.data.length > 0) {
                    setDisablesampleeSize(true);
                }
                else {
                    setDisablesampleeSize(false);

                }

            });

            SKUService.LoadSkuNameList(props.location.state?.params.styleID).then((response) => {
                if (response.data) {
                    setSKUProgram(response.data);
                }
            }).catch(() => { });


            setIspage(Page)

            setIsStyleTabClick(true);
        }
        // setprevPage(Page);
        let combinePage = Pageset === 'ADD' ? 'ADD' + Page : 'LIST' + Page;
        setPage(combinePage);
        localStorage.setItem('Page', combinePage.toString())
    }


    const MeasurementPageCallback = (error) => {
        setPage("LISTMEASUREMENT");
        Nodify('Warning!', 'warning', error);
    }

    // const [getprevPage, setprevPage] = useState(undefined);
    // const pageRedirect = (Page) => {
    //     let prevPage = getprevPage;
    //
    //     if (prevPage !== Page) {
    //         setID({ showPopupConfirm: true, Params: Page });
    //         Page = prevPage;
    //       }
    //     else {

    //     }
    //     loadList();
    //     let Pageset = "";
    //     if (getHeader === "Add Style") {
    //         if (Page === 'Style') { }
    //         if (Page === 'SKU') {
    //             Pageset = getSKUList.length > 0 ? '' : 'ADD';
    //         } if (Page === 'PROGRAM') {
    //             Pageset = getProgramList.length > 0 ? '' : 'ADD';
    //         }
    //         if (Page === 'MEASUREMENT') {
    //             Pageset = getMeasurement.length > 0 ? '' : 'ADD';
    //         }
    //     } else {
    //         Pageset = "EDIT";
    //     }
    //     setprevPage(Page);
    //     let combinePage = Pageset === 'ADD' ? 'ADD' + Page : 'LIST' + Page;
    //     setPage(combinePage);
    // }


    // const RedirectPage = (value) => {
    //     let Page = value.Page === undefined ? value : value.Page;
    //     if (Page === 'ADDSKU') {
    //         setBindSKU(value.data);
    //     } else if (Page === 'ADDPROGRAM') {
    //         setBindProgram(value.data);
    //         ProgramService.GetAllPrograms(getStyleID).then((response) => {
    //             if (response.data) {
    //                 setBindProgram(value.data);
    //                 let Program = response.data.program;
    //                 let Count = 0; let initialCount = 0;
    //                 for (var i = 0; i < getSKUList.length; i++) {
    //                     Count = 0;
    //                     for (var j = 0; j < Program.length; j++) {
    //                         let sku1 = getSKUList[i].skuID === undefined ? getSKUList[i].SKUID : getSKUList[i].skuID;
    //                         let sku2 = Program[j].skuid === undefined ? Program[j].SKUID : Program[j].skuid;
    //                         if (sku1 === sku2) {
    //                             Count++;
    //                         }
    //                     }
    //                     if (Count === 0) {
    //                         initialCount++;
    //                     }
    //                 }
    //                 if ((initialCount === 0 || getSKUList.length === 0) && value.data === undefined) {
    //                     // Nodify('Warning!', 'warning', "All SKU already mapped in program.");
    //                     setBindProgram('SINGLE');
    //                     setPage(Page);
    //                 } else {
    //                     setPage(Page);
    //                 }
    //             } else {
    //                 Nodify('Warning!', 'warning', "All SKU already mapped in program.");
    //             }
    //         }).catch(() => { });
    //     } else if (Page === 'ADDMEASUREMENT') {

    //         setMeasurement(value.data);
    //     }
    //     if (Page !== 'ADDPROGRAM') {
    //         setPage(Page);
    //     }
    // }

    const RedirectPage = (value, StyleID) => {
        ;
        let Page = value.Page === undefined ? value : value.Page;
        if (Page === 'ADDSKU') {
            setBindSKU(value.data);
            // SKUService.LoadSkuNameList(StyleID).then((response) => {
            //     if (response.data) {
            //         setSKUProgram(response.data);
            //     }
            // }).catch(() => { });
        } else if (Page === 'ADDPROGRAM') {
            setBindProgram(value.data);
            // SKUService.LoadSkuNameList(StyleID).then((response) => {
            //         if (response.data) {
            //             setSKUProgram(response.data);
            //         }
            //     }).catch(() => { });
        } else if (Page === 'ADDMEASUREMENT') {
            setMeasurement(value.data);
        } else if (Page === 'ADDSAMPLEREQUEST') {
            setSamplereq(value.data);
            // SKUService.LoadSkuNameList(props.location.state.params.styleID).then((response) => {
            //     if (response.data) {
            //         setSKUProgram(response.data);
            //     }
            // }).catch(() => { });
        }
        else if (Page === 'ADDMAIL') {
            setMailreq(value.data);
            //setAddMailSuccess(false);
        }
        else if (Page === 'EDITMAIL') {
            value.data.action = "EDITMAIL";
            setMailreq(value.data);
        }
        else if (Page === "VIEWMAIL") {
            value.data.action = "VIEWMAIL";
            setMailreq(value.data);
        }
        // else if (Page === 'LISTEmail') {
        //     setAddMailSuccess(false);

        // }

        setPage(Page);
    }

    const SaveSKUList = (value) => {
        setSKUResult([]);
        setSKUResult(value);
    }

    // const SaveSKU = (value) => {
    //     setSKUList([]);
    //     setSKUList(value);
    // }

    const SaveProgram = (value) => {

        setProgramList([]);

        setProgramList(value);
    }

    const SaveMeasurement = (value) => {
        setMeasurement([]);

        setMeasurement(value);
    }

    const SaveSample = (value) => {
        setSamplereq([]);
        setSamplereq(value);
    }


    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }

    const Delcallback = (value) => {
        if (value === true) { }
        setID({ showPopupConfirm: false, Params: [] });
        //setPage("deleteClose");
    }



    function find_duplicate_in_array(arra1) {
        var object = {};
        var result = [];

        arra1.forEach(function (item) {
            if (!object[item])
                object[item] = 0;
            object[item] += 1;
        })
        for (var prop in object) {
            if (object[prop] >= 2) {
                result.push(prop);
            }
        }
        return result;
    }

    const SaveStyle = (e) => {
        debugger;
        let DeplicatedQCSkuID = 0;
        let QCexistingskuID = [];
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;
        let ValidationMsg = "";
        if (getPage === 'ADDStyle' || getPage === 'LISTStyle' || getPage === '') {
            ;
            if (getStyleInfoList.BuyerID !== undefined) {
                if (getStyleInfoList.BuyerID === 0 || getStyleInfoList.SeasonID === 0 || getStyleInfoList.BrandID === 0 || getStyleInfoList.GroupTypeID === 0
                    || getStyleInfoList.StyleName === '' || getStyleInfoList.StyleNo === '' || getStyleInfoList.CategoryID === 0 || getStyleInfoList.GenderID === 0
                    || getStyleInfoList.SizeID === 0 || getStyleInfoList.ProcessTypeID === 0
                    || (getStyleInfoList.BuyerEmployeeIdList === '' || getStyleInfoList.BuyerEmployeeIdList === null) || (getStyleInfoList.CubeEmployeeIdList === '' ||
                        getStyleInfoList.CubeEmployeeIdList === null)
                )

                // && getStyleInfoList.IsThemeMandatory === 1 &&  getStyleInfoList.ThemeID === 0
                // && getStyleInfoList.IsStoryMandatory === 1 &&  getStyleInfoList.StoryID === 0
                {
                    Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fieldss.');

                    setIsParentCallback(true);
                    setButtonLoader(false);
                    setSubmitted(false);
                    return false;
                }
                else if (getStyleInfoList.IsStoryMandatory === 1 && getStyleInfoList.StoryID === 0) {
                    Nodify('Warning!', 'warning', 'Please select a story for this buyer.');
                    setIsParentCallback(true);
                    setButtonLoader(false);
                    setSubmitted(true);
                    return false;

                }
                else if (getStyleInfoList.IsThemeMandatory === 1 && getStyleInfoList.ThemeID === 0) {
                    Nodify('Warning!', 'warning', 'Please select a theme for this buyer.');
                    setButtonLoader(false);
                    setIsParentCallback(true);
                    setSubmitted(true);
                    return false;

                }

                else if (getStyleInfoList.StyleLogoName === '') {
                    if (IsValid) {
                        Nodify('Warning!', 'warning', 'Please upload to style image!');
                        setIsParentCallback(true);
                        setButtonLoader(false);
                        setSubmitted(true);
                    }
                    return false;
                }

                setButtonLoader(false);
                setIsParentCallback(true);
            }
            else {
                if (IsValid) {
                    Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');

                }
                setButtonLoader(false);
                setIsParentCallback(true);
                return false;
            }

            // Validation for Fabric and Remarks

            if (getRemarksList.length === 0 && getFabricList.length === 0) {
                if (IsValid) {
                    Nodify('Warning!', 'warning', 'Please fill fabric and remarks detail fields.');
                }
                setButtonLoader(false);
                setIsParentCallback(true);
                IsValid = false;
                return false;
            }
            else if (getRemarksList.length === 0) {
                if (IsValid) {
                    Nodify('Warning!', 'warning', 'Please fill remarks detail fields.');
                }
                setButtonLoader(false);
                setIsParentCallback(true);
                IsValid = false;
                return false;
            }
            else if (getFabricList.length === 0) {
                if (IsValid) {
                    Nodify('Warning!', 'warning', 'Please fill fabric detail fields.');
                }
                setButtonLoader(false);
                setIsParentCallback(true);
                IsValid = false;
                return false;
            }
            else {
                getFabricList.forEach((Fabric, i) => {
                    if ((Fabric.FabricDetails === '')) {
                        if (IsValid) {
                            Nodify('Warning!', 'warning', 'Please fill fabric detail fields.');
                        }
                        setButtonLoader(false);
                        setIsParentCallback(true);
                        IsValid = false;
                        return false;
                    }
                });


                getRemarksList.forEach((Remark, i) => {
                    if (Remark.Remarks === '' || Remark.ReceivedDate === null) {
                        if (IsValid) {
                            Nodify('Warning!', 'warning', 'Please fill remarks detail fields.');
                        }
                        setButtonLoader(false);
                        setIsParentCallback(true);
                        IsValid = false;
                        return false;
                    }
                    else {
                        let date = new Date(Remark.ReceivedDate);
                        Remark.ReceivedDate.setMinutes(date.getMinutes() + 370);
                        // Remark.ReceivedDate.setDate(Remark.ReceivedDate.getDate() + 1);

                    }
                });
            }

        }
        // end of Validation

        getStyleInfoList.StyleFabricDetailsInformation = getFabricList;
        getRemarksList.forEach((x, i) => {
            x.StyleRemarksIndexName = i.toString();
        })
        getStyleInfoList.StyleRemarksInformation = getRemarksList;
        getStyleInfoList.StyleRemarksFileUploadInformation = [];
        getRemarksList.forEach(element => {
            element.arrAttachment.forEach(f => {
                getStyleInfoList.StyleRemarksFileUploadInformation.push({
                    StyleRemarkFileId: f.StyleRemarkFileId,
                    FileName: f.FileName,
                    FilePath: f.FilePath,
                    FileType: f.FileType,
                    StyleRemarksIndexName: f.StyleRemarksIndexName,
                    StyleRemarksFileIndexName: f.StyleRemarksFileIndexName
                })
            })
        })


        if (getPage === 'ADDSKU') {
            if (getSKUResult.length === undefined || getSKUResult.length === 0) {
                setSKUResult(EmptySKU);
                IsValid = false;
                setIsSKUParentCallback(true);
                setButtonLoader(false);
                // if (IsValid) {
                //     Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in SKU tab.');
                // }
                // Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in SKU tab.');
                return false;
            } else {
                //SKU Insertion/Updation/Validation
                getSKUResult.forEach((sku, i) => {
                    if (sku.SKUName === '' && sku.IsDeleted !== 1) {
                        IsValid = false;
                        return false;
                    }
                    if (sku.SKUFrontPicPath === '' && sku.PickStylePic === 0 && sku.IsDeleted !== 1) {
                        if (IsValid) {
                            IsValid = false;
                        }
                        return false;
                    }
                });

                if (IsValid === false) {
                    setIsSKUParentCallback(true);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in SKU tab.');
                }
            }
            if (getHeader !== 'Edit Style') {
                let getLength = getSKUResult.length === 1 && getSKUResult[0].SKUName === undefined ? 0 : 1;
                getStyleInfoList.StyleSkuDetails = getLength === 0 || getSKUResult.length === 0 ? EmptySKU : getSKUResult;
            } else {
                let getLength = getSKUResult.length === 0 && getSKUResult[0] === undefined ? 0 : 1;
                getStyleInfoList.StyleSkuDetails = getLength === 0 || getSKUResult.length === 0 ? EmptySKU : getSKUResult;
            }
            setSKUResult([]);
        }
        else {
            getStyleInfoList.StyleSkuDetails = EmptySKU;
            setSKUResult([]);
        }

        let StyleProgramDetails = []; let StyleProgramCirticalInformation = [];
        let StyleProgramManualInformation = [];
        let StyleProgramManualFileInformation = [];
        var uniqueimages = []
        //let StyleProgramFabricDetailsInformation = [];
        if (getPage === 'ADDPROGRAM') {

            if (getProgramList.length === undefined || getProgramList.length === 0) {
                setProgramList(EmptyProgram);
                StyleProgramCirticalInformation = EmptyCritical;
                StyleProgramManualInformation = EmptyManual;
                //StyleProgramFabricDetailsInformation = EmptyFabric;
                IsValid = false;
                ValidationMsg = "Please fill all mandatory (*) fields in Program tab.";
                setIsProgramParentCallback(true);
                setButtonLoader(false);
                ValidationPopup(ValidationMsg);
                return false;
            } else {
                //Program Insertion/Updation/Validation
                ;
                let CheckFabric = true; let GetSkuField = ""; let CheckManual = true;
                getProgramList.forEach((Program, i) => {
                    if (Program.IsDeleted === 0) { //Program.DontShow === 0 &&
                        if (Program.Critical === 1) {
                            Program.IsCriticals.forEach((getCrit, j) => {
                                if (getCrit.IsCritical === '' || getCrit.RemindMe === 0 || getCrit.TargettoClose === '' || getCrit.CompletedOn === '') {
                                    CheckFabric = false;
                                    GetSkuField = Program.SKUName;
                                }
                            })
                        }

                        if (CheckFabric) {
                            if (Program.PantoneTypeID === 0 || Program.PantoneID === 0 || Program.PantoneColor === '' ||
                                Program.ProcessTypeId === 0 || Program.ApprovalTypeID === 0 || Program.FabricID === 0
                                || Program.SKUID === ''
                            ) {
                                GetSkuField = Program.SKUName;
                                if (IsValid === true) {
                                    Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields in Program tab " + GetSkuField + " SKU.")
                                    setIsProgramParentCallback(true);
                                    setButtonLoader(false);
                                };
                                IsValid = false;
                                return false;
                            }

                            else {

                                StyleProgramDetails.push({
                                    StyleProgramID: parseInt(Program.StyleProgramID), SKUID: Program.SKUID, PantoneTypeID: parseInt(Program.PantoneTypeID),
                                    PantoneID: parseInt(Program.PantoneID), PantoneColor: Program.PantoneColor, RefColorName: Program.RefColorName, BuyerRefNo: Program.BuyerRefNo,
                                    SwatchImageID: 0, ProcessTypeID: Program.ProcessTypeId, swatchImagePath: '',
                                    Remark: Program.Remark, Cirtical: Program.Critical === undefined ? 0 : parseInt(Program.Critical),
                                    IndexName: Program.IndexName === undefined || Program.IndexName === "" ? i.toString() : (Program.IndexName).toString(),
                                    IsDeleted: 0,
                                    FabricID: parseInt(Program.FabricID), FabricPartID: parseInt(Program.FabricPartID),
                                    //ApprovalTypeID: 0, ApprovalTypeName: Program.ApprovalTypeName,
                                    ApprovalTypeID: parseInt(Program.ApprovalTypeID), ApprovalTypeName: '',
                                    LabdibSubmissionApprovalID: Program.labdibSubmissionApprovalID
                                })

                                Program.IsCriticals.forEach((Crit, k) => {
                                    let Iscrit = Crit.IsCritical === undefined ? "" : (Crit.IsCritical).toString();
                                    let target = ((Crit.TargettoClose) === undefined || (Crit.TargettoClose) === '') ? "" : new Date(Crit.TargettoClose.setDate(Crit.TargettoClose.getDate() + 1));
                                    let CompletedOn = ((Crit.CompletedOn) === undefined || (Crit.CompletedOn) === '') ? "" : new Date(Crit.CompletedOn.setDate(Crit.CompletedOn.getDate() + 1));
                                    let IndexName = ((Crit.IndexName) === undefined || (Crit.IndexName) === "") ? i.toString() : (Crit.IndexName).toString();
                                    let programcirticalinfoid = (Crit.ProgramCirticalInfoID) === undefined ? 0 : parseInt(Crit.ProgramCirticalInfoID);
                                    let remme = (Crit.RemindMe) === undefined ? 0 : parseInt(Crit.RemindMe);
                                    let IsDelete = (Crit.IsDeleted) === undefined ? 0 : parseInt(Crit.IsDeleted);

                                    StyleProgramCirticalInformation.push({
                                        ProgramCirticalInfoID: programcirticalinfoid, IsCritical: Iscrit, TargettoClose: target,
                                        CompletedOn: CompletedOn, RemindMe: remme, IndexName: IndexName,
                                        IsDeleted: IsDelete,
                                    })
                                })
                                if (CheckManual) {
                                    if (Program.ApprovalTypeID === 2 && Program.IsManual[0].ManualCloseComment === '') {
                                        if (IsValid) {
                                            Nodify('Warning!', 'warning', "Manual data should be fill when approval type is Manual in Program tab " + GetSkuField + " SKU.")
                                            setIsProgramParentCallback(true);
                                            setButtonLoader(false);
                                            IsValid = false;
                                            return false;
                                        }
                                    }
                                    else {
                                        ;
                                        Program.IsManual.forEach((man, k) => {
                                            //  if (man.ManualCloseComment !== '') {
                                            let styleprogrammanualid = (man.StyleProgramManualId) === undefined ? 0 : parseInt(man.StyleProgramManualId);
                                            let manualCloseDate = ((man.ManualCloseDate) === undefined || (man.ManualCloseDate) === '') ? "" :
                                                new Date(man.ManualCloseDate.setDate(man.ManualCloseDate.getDate() + 1));
                                            let manualCloseComment = man.ManualCloseComment === undefined ? "" : (man.ManualCloseComment).toString();
                                            let indexname = ((man.StyleProgramIndexName) === undefined || (man.StyleProgramIndexName) === "") ? i.toString() : (man.StyleProgramIndexName).toString();
                                            let IsDelete = (man.IsDeleted) === undefined ? 0 : parseInt(man.IsDeleted);

                                            StyleProgramManualInformation.push({
                                                StyleProgramManualId: styleprogrammanualid,
                                                ManualCloseDate: manualCloseDate,
                                                ManualCloseComment: manualCloseComment,
                                                StyleProgramIndexName: indexname,
                                                //StyleProgramIndexName: man.StyleProgramIndexName.toString(),
                                                IsDeleted: IsDelete,
                                            })

                                            // uniqueimages = [...new Map(StyleProgramManualInformation.map(item =>
                                            //     [item['StyleProgramManualId'], item])).values()];

                                            uniqueimages = StyleProgramManualInformation
                                                .filter(item => item.ManualCloseComment !== ''); // Filter out items with empty ManualCloseComment


                                            if (man.Files) {
                                                if (man.Files.length !== 0) {
                                                    man.Files.forEach((f, k) => {
                                                        let styleProgramManualFilelId = (f.styleProgramManualFilelId) === undefined ? 0 : parseInt(f.styleProgramManualFilelId);
                                                        let fileType = f.fileType === undefined ? "" : (f.fileType).toString();
                                                        let fileName = f.fileName === undefined ? "" : (f.fileName).toString();
                                                        let filePath = f.filePath === undefined ? "" : (f.filePath).toString();
                                                        let styleProgramIndexName = ((man.StyleProgramIndexName) === undefined || (man.StyleProgramIndexName) === "") ? i.toString() : (man.StyleProgramIndexName).toString();
                                                        let styleProgramFileIndexName = ((man.StyleProgramIndexName) === undefined || (man.StyleProgramIndexName) === "") ? i.toString() : (man.StyleProgramIndexName).toString();
                                                        let IsDelete = (f.IsDeleted) === undefined ? 0 : parseInt(f.IsDeleted);
                                                        StyleProgramManualFileInformation.push({
                                                            StyleProgramManualFilelId: styleProgramManualFilelId,
                                                            FileType: fileType,
                                                            FileName: fileName,
                                                            FilePath: filePath,
                                                            // StyleProgramIndexName: styleProgramIndexName,
                                                            StyleProgramIndexName: f.styleProgramIndexName.toString(),
                                                            StyleProgramFileIndexName: f.styleProgramFileIndexName,
                                                            IsDeleted: IsDelete,
                                                        })
                                                    })
                                                    // StyleProgramManualFileInformation = man.Files
                                                }
                                            }
                                            //  }

                                        })
                                    }
                                }

                            }
                        } else {
                            if (IsValid === true) { Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields in Program tab " + GetSkuField + " SKU.") };
                            setIsProgramParentCallback(true);
                            setButtonLoader(false);
                            IsValid = false;
                            return false;
                        }
                    }
                });
            }
            getStyleInfoList.StyleProgramDetails = StyleProgramDetails.length === 0 ? EmptyProgram : StyleProgramDetails;
            // getStyleInfoList.StyleProgramFabricDetailsInformation = StyleProgramFabricDetailsInformation.length === 0 ? EmptyFabric : StyleProgramFabricDetailsInformation;
            getStyleInfoList.StyleProgramCirticalInformation = StyleProgramCirticalInformation.length === 0 ? EmptyCritical : StyleProgramCirticalInformation;
            getStyleInfoList.StyleProgramManualInformation = uniqueimages.length === 0 ? EmptyManual : uniqueimages;
            getStyleInfoList.StyleProgramManualFileInformation = StyleProgramManualFileInformation.length === 0 ? [] : StyleProgramManualFileInformation;
        }
        else if (props.location.state && props.location.state.params.styleName === '' && props.location.state.params.styleNo === '' && getresponse.program.length > 0) {

            getresponse.program.forEach((Program, i) => {
                debugger
                let arrs = Program.skuName.split(',');
                Program.skuid = '';
                for (let skuna of arrs) {
                    ;
                    let trimmedSkuna = skuna.trim();
                    let res = getSKUProgram.filter(x => x.name === trimmedSkuna)
                    arrVal.push({
                        value: res[0]?.value,
                        label: res[0]?.label
                    });
                    if (Program.skuid === '') {
                        Program.skuid = res[0]?.value;
                    }
                    else {
                        Program.skuid = Program.skuid + ',' + res[0]?.value;
                    }
                }

                StyleProgramDetails.push({
                    StyleProgramID: parseInt(Program.styleProgramID), SKUID: Program.skuid === undefined ? '' : Program.skuid.toString(), PantoneTypeID: parseInt(Program.pantoneTypeID),
                    PantoneID: parseInt(Program.pantoneID), PantoneColor: Program.pantoneColor, RefColorName: Program.refColorName, BuyerRefNo: Program.buyerRefNo,
                    SwatchImageID: 0, ProcessTypeID: Program.processTypeId, swatchImagePath: '',
                    Remark: Program.remark, Cirtical: Program.critical === undefined ? 0 : parseInt(Program.critical),
                    IndexName: Program.indexName === undefined || Program.indexName === "" ? i.toString() : (Program.indexName).toString(),
                    IsDeleted: 0,
                    FabricID: parseInt(Program.fabricID), FabricPartID: parseInt(Program.fabricPartID),
                    //ApprovalTypeID: 0, ApprovalTypeName: Program.ApprovalTypeName,
                    ApprovalTypeID: parseInt(Program.approvalTypeID), ApprovalTypeName: '',
                    LabdibSubmissionApprovalID: Program.labdibSubmissionApprovalID
                })

                getresponse.critical.forEach((Crit, k) => {
                    let Iscrit = Crit.isCritical === undefined ? "" : (Crit.isCritical).toString();
                    let target = ((Crit.targettoClose) === undefined || (Crit.targettoClose) === '') ? "" : Crit.targettoClose
                    // new Date(Crit.targettoClose.setDate(Crit.targettoClose.getDate() + 1));
                    let CompletedOn = ((Crit.completedOn) === undefined || (Crit.completedOn) === '') ? "" : Crit.completedOn
                    // new Date(Crit.completedOn.setDate(Crit.completedOn.getDate() + 1));
                    let IndexName = ((Crit.indexName) === undefined || (Crit.indexName) === "") ? i.toString() : (Crit.indexName).toString();
                    let programcirticalinfoid = (Crit.programCirticalInfoID) === undefined ? 0 : parseInt(Crit.programCirticalInfoID);
                    let remme = (Crit.remindMe) === undefined ? 0 : parseInt(Crit.remindMe);
                    let IsDelete = (Crit.isDeleted) === undefined ? 0 : parseInt(Crit.isDeleted);

                    StyleProgramCirticalInformation.push({
                        ProgramCirticalInfoID: programcirticalinfoid, IsCritical: Iscrit, TargettoClose: target,
                        CompletedOn: CompletedOn, RemindMe: remme, IndexName: IndexName,
                        IsDeleted: IsDelete,
                    })
                })


                getresponse.manual.forEach((man, k) => {
                    //  if (man.ManualCloseComment !== '') {
                    let styleprogrammanualid = (man.styleProgramManualId) === undefined ? 0 : parseInt(man.styleProgramManualId);
                    let manualCloseDate = ((man.manualCloseDate) === undefined || (man.manualCloseDate) === '') ? "" : man.manualCloseDate
                    //new Date(man.manualCloseDate.setDate(man.manualCloseDate.getDate() + 1));
                    let manualCloseComment = man.manualCloseComment === undefined ? "" : (man.manualCloseComment).toString();
                    let indexname = ((man.styleProgramIndexName) === undefined || (man.styleProgramIndexName) === "") ? i.toString() : man.styleProgramIndexName.toString();
                    let IsDelete = (man.isDeleted) === undefined ? 0 : parseInt(man.isDeleted);
                    ;
                    StyleProgramManualInformation.push({
                        StyleProgramManualId: styleprogrammanualid,
                        ManualCloseDate: manualCloseDate,
                        ManualCloseComment: manualCloseComment,
                        StyleProgramIndexName: indexname,
                        //StyleProgramIndexName: man.StyleProgramIndexName.toString(),
                        IsDeleted: IsDelete,
                    })
                })
                    ;
                // uniqueimages = [...new Map(StyleProgramManualInformation.map(item =>
                //     [item['StyleProgramManualId'], item])).values()];

                uniqueimages = StyleProgramManualInformation
                    .filter(item => item.ManualCloseComment !== ''); // Filter out items with empty ManualCloseComment

                //if (man.Files) {
                //if (man.Files.length !== 0) {
                getresponse.manualFiles.forEach((f, k) => {
                    let styleProgramManualFilelId = (f.styleProgramManualFilelId) === undefined ? 0 : parseInt(f.styleProgramManualFilelId);
                    let fileType = f.fileType === undefined ? "" : (f.fileType).toString();
                    let fileName = f.fileName === undefined ? "" : (f.fileName).toString();
                    let filePath = f.filePath === undefined ? "" : (f.filePath).toString();
                    //let styleProgramIndexName = ((man.StyleProgramIndexName) === undefined || (man.StyleProgramIndexName) === "") ? i.toString() : (man.StyleProgramIndexName).toString();
                    //let styleProgramFileIndexName = ((man.StyleProgramIndexName) === undefined || (man.StyleProgramIndexName) === "") ? i.toString() : (man.StyleProgramIndexName).toString();
                    let IsDelete = (f.IsDeleted) === undefined ? 0 : parseInt(f.IsDeleted);
                    StyleProgramManualFileInformation.push({
                        StyleProgramManualFilelId: styleProgramManualFilelId,
                        FileType: fileType,
                        FileName: fileName,
                        FilePath: filePath,
                        // StyleProgramIndexName: styleProgramIndexName,
                        StyleProgramIndexName: f.styleProgramIndexName.toString(),
                        StyleProgramFileIndexName: f.styleProgramFileIndexName,
                        IsDeleted: IsDelete,
                    })
                })
                // StyleProgramManualFileInformation = man.Files
                // }
                // }
                //  }


            });

            getStyleInfoList.StyleProgramDetails = StyleProgramDetails.length === 0 ? EmptyProgram : StyleProgramDetails;
            // getStyleInfoList.StyleProgramFabricDetailsInformation = StyleProgramFabricDetailsInformation.length === 0 ? EmptyFabric : StyleProgramFabricDetailsInformation;
            getStyleInfoList.StyleProgramCirticalInformation = StyleProgramCirticalInformation.length === 0 ? EmptyCritical : StyleProgramCirticalInformation;
            getStyleInfoList.StyleProgramManualInformation = uniqueimages.length === 0 ? EmptyManual : uniqueimages;
            getStyleInfoList.StyleProgramManualFileInformation = StyleProgramManualFileInformation.length === 0 ? Emptyfile : StyleProgramManualFileInformation;

        }
        else {
            getStyleInfoList.StyleProgramDetails = EmptyProgram;
            getStyleInfoList.StyleProgramCirticalInformation = EmptyCritical;
            getStyleInfoList.StyleProgramManualInformation = EmptyManual;
            getStyleInfoList.StyleProgramManualFileInformation = Emptyfile;
            // getStyleInfoList.StyleProgramFabricDetailsInformation = EmptyFabric;
        }
        if (getPage === 'ADDMEASUREMENT') {
            // getBindMeasurement   -> Use this getMeasurement to Insert/update/validate
            ;
            if (getMeasurement.length > 0) {
                getStyleInfoList.StyleMeasurementID = parseInt(getMeasurement[0].StyleMeasurementID);
                getStyleInfoList.MeasurementSpecReason = getMeasurement[0].MeasurementSpec;
                //getStyleInfoList.PointsUOM = getMeasurement[0].PointsUOM;
                //getStyleInfoList.MeasurementGrandingSize = parseInt(getMeasurement[0].MeasurementGrandingSize);
                getStyleInfoList.MeasurementGrandingSize = getMeasurement[0].MeasurementGrandingSize === '' ? 0 : getMeasurement[0].MeasurementGrandingSize;
                getStyleInfoList.MeasurementOperation = getMeasurement[0].MeasurementOperation;

                if (getMeasurement[0].Category.length > 0) {

                    // && getStyleInfoList.MeasurementGrandingSize !== ''
                    if (getStyleInfoList.MeasurementSpecReason !== '') {
                        getStyleInfoList.MeasurementCategory = [];
                        getStyleInfoList.MeasurementPoints = [];
                        getMeasurement[0].Category.forEach((Mea, m) => {
                            if (Mea.MeasurementCategoryID !== 0 && Mea.MeasurementCategoryID !== '0' && Mea.MeasurementCategoryID !== '' && Mea.MeasurementCategoryID !== undefined) {
                                getStyleInfoList.MeasurementCategory.push({
                                    StyleMeasurementCategoryID: parseInt(Mea.StyleMeasurementCategoryID), MeasurementCategoryID: parseInt(Mea.MeasurementCategoryID), PointsUOM: parseInt(Mea.PointsUOM), IndexName: Mea.IndexName.toString(), IsDeleted: parseInt(Mea.IsDeleted)
                                });
                                Mea.Point.forEach((Points, p) => {

                                    if (Points.MeasurementPoints !== '' && Points.MeasurementPoints !== 0 && Points.MeasurementPoints !== '0') {
                                        if (Points.arrGradingSizes.length > 0) {
                                            let GradingSizeId = '';
                                            let GradingLabel = '';
                                            let GradingValue = '';
                                            getSizeListmeasurement.forEach(element => {
                                                if (GradingSizeId === '') {
                                                    GradingSizeId = element.value;
                                                } else {
                                                    GradingSizeId = GradingSizeId + "," + element.value;
                                                }
                                                if (GradingLabel === '') {
                                                    GradingLabel = element.label;
                                                } else {
                                                    GradingLabel = GradingLabel + "," + element.label;
                                                }
                                                if (GradingValue === '') {
                                                    if (Points.arrGradingSizes[0][element.label] === '') {
                                                        if (IsValid) {
                                                            //ValidationMsg
                                                            Nodify('Warning!', 'warning', "Please fill all Sizes.")
                                                            // ValidationMsg = "Please fill all mandatory (*) fields in Measurement tab.";
                                                        }
                                                        IsValid = false;
                                                        setButtonLoader(false);
                                                        setIsMeasurementParentCallback(true);
                                                        setIsMeasurementChildCallback(true);
                                                        return false;

                                                    }
                                                    else {
                                                        Points.arrGradingSizes[0][element.label] = Points.arrGradingSizes[0][element.label] === undefined ? 0 : Points.arrGradingSizes[0][element.label];
                                                        GradingValue = Points.arrGradingSizes[0][element.label];
                                                    }

                                                } else {
                                                    if (Points.arrGradingSizes[0][element.label] === '') {
                                                        if (IsValid) {
                                                            Nodify('Warning!', 'warning', "Please fill all Sizes.")
                                                            // ValidationMsg = "Please fill all mandatory (*) fields in Measurement tab.";
                                                        }
                                                        setButtonLoader(false);
                                                        setIsMeasurementParentCallback(true);
                                                        setIsMeasurementChildCallback(true);
                                                        //ValidationPopup(ValidationMsg);
                                                        IsValid = false;
                                                        return false;

                                                    }
                                                    else {
                                                        Points.arrGradingSizes[0][element.label] = Points.arrGradingSizes[0][element.label] === undefined ? 0 : Points.arrGradingSizes[0][element.label];
                                                        GradingValue = GradingValue + "," + Points.arrGradingSizes[0][element.label];
                                                    }
                                                }
                                            });
                                            // && Points.MeasurementGranding !== ''
                                            if (Points.MeasurementPoints !== 0 && GradingValue !== '' && Points.TolerancePlus !== '' && Points.ToleranceMinus !== '') {
                                                // Points.TolerancePlus = Points.TolerancePlus === '' ? 0 : Points.TolerancePlus;
                                                // Points.ToleranceMinus = Points.ToleranceMinus === '' ? 0 : Points.ToleranceMinus;
                                                //Points.MeasurementGranding = Points.MeasurementGranding === '' ? 0 : Points.MeasurementGranding;

                                                getStyleInfoList.MeasurementPoints.push({
                                                    StyleMeasurementCategoryPointsID: Points.StyleMeasurementCategoryPointsID,
                                                    MeasurementPoints: parseInt(Points.MeasurementPoints),
                                                    // MeasurementGranding: parseInt(Points.MeasurementGranding),
                                                    // TolerancePlus: parseInt(Points.TolerancePlus),
                                                    // ToleranceMinus: parseInt(Points.ToleranceMinus),
                                                    MeasurementGranding: Points.MeasurementGranding,
                                                    TolerancePlus: Points.TolerancePlus,
                                                    ToleranceMinus: Points.ToleranceMinus,
                                                    GradingSizes: GradingLabel.toString(),
                                                    GradingSizesId: GradingSizeId.toString(),
                                                    GradingSizesValue: GradingValue.toString(),
                                                    Remarks: Points.Remark,
                                                    IndexName: Points.IndexName.toString(),
                                                    PreferenceId: Points.PreferenceId,
                                                    IsDeleted: parseInt(Points.IsDeleted)
                                                })
                                            }
                                            else {
                                                if (IsValid) {
                                                    ValidationMsg = "Please Select Measurement Point.";
                                                }
                                                //ValidationMsg = "Please fill all mandatory (*) fields in measurement tab.";
                                                IsValid = false;
                                                setButtonLoader(false);
                                                setIsMeasurementParentCallback(true);
                                                setIsMeasurementChildCallback(true);
                                                if (ValidationMsg !== '') {
                                                    ValidationPopup(ValidationMsg);
                                                }
                                                return false;

                                            }
                                        }
                                    }
                                    else {

                                        if (IsValid) {
                                            ValidationMsg = "Please Select Measurement Point.";
                                        }

                                        if (ValidationMsg !== '' && IsValid) {
                                            ValidationPopup(ValidationMsg);
                                        }
                                        IsValid = false;
                                        setButtonLoader(false);
                                        setIsMeasurementParentCallback(true);
                                        setIsMeasurementChildCallback(true);
                                        return false;

                                    }
                                })
                            }
                            else {

                                if (IsValid) {
                                    ValidationMsg = "Please fill all mandatory (*) fields in measurement tab.";
                                }
                                IsValid = false;
                                setButtonLoader(false);
                                setIsMeasurementParentCallback(true);
                                setIsMeasurementChildCallback(true);
                                if (ValidationMsg !== '') {
                                    ValidationPopup(ValidationMsg);
                                }
                                return false;

                            }

                        });
                    }
                    else {

                        if (IsValid) {
                            ValidationMsg = "Please fill all mandatory (*) fields in measurement tab.";

                        }
                        IsValid = false;
                        setButtonLoader(false);
                        setIsMeasurementParentCallback(true);
                        setIsMeasurementChildCallback(true);
                        if (ValidationMsg !== '') {
                            ValidationPopup(ValidationMsg);
                        }
                        return false;

                    }

                }
            }
            //getStyleInfoList.SetMeasurement = getMeasurement;
        }
        else {
            getStyleInfoList.StyleMeasurementID = 0;
            getStyleInfoList.MeasurementSpecReason = '';
            getStyleInfoList.MeasurementGrandingSize = 0;
            getStyleInfoList.MeasurementOperation = 0;
            getStyleInfoList.MeasurementCategory = [{ StyleMeasurementCategoryID: 0, MeasurementCategoryID: 0, PointsUOM: 1, IndexName: '', IsDeleted: 0 }];
            getStyleInfoList.MeasurementPoints = [{
                MeasurementPoints: 0, MeasurementGranding: '', TolerancePlus: '', ToleranceMinus: '',
                GradingSizes: '', GradingSizesId: '', GradingSizesValue: '', Remark: '', IndexName: '', IsDeleted: 0

            }];

        }
        getStyleInfoList.StyleSampleRequestInformation = EmptySampleReq;
        setSamplereq([]);
        // if (getPage === 'LISTStyleQCNotes') {
        if (getPage === 'LISTStyleQCNotes') {
            getStyleInfoList.StyleQCNote = getQCNotesCallback.map((data => {
                return {
                    Comment: (data.Comment == null || data.Comment == undefined) ? "" : data.Comment
                    , IndexID: data.IndexID
                    , QCNoteID: data.QCNoteID
                    , SKUID: data.skuid == undefined ? data.skuid : data.skuid
                    , SKUName: data.skuName
                }
            }));
            getStyleInfoList.StyleQCNote = getQCNotesCallback.filter((data => {
                if (data.skuid) {
                    return data;
                }
            }));
            let QCAttachments = [];
            getQCNotesCallback.forEach((element, index) => {
                for (var i = 0; i < element.Attachment.length; i++) {
                    QCAttachments.push(element.Attachment[i]);
                }
            });

            getStyleInfoList.StyleQCNoteAttachment = QCAttachments;
            QCexistingskuID.push(getStyleInfoList.StyleQCNote.map((data => {
                return data.skuid.split(',').sort().join(',');
            })))
            DeplicatedQCSkuID = find_duplicate_in_array(QCexistingskuID[0]).length;
            if (DeplicatedQCSkuID == 0 && (getQCNotesCallback.length > 0 &&
                (getQCNotesCallback[0].skuid != null && getQCNotesCallback[0].skuid != "" && getQCNotesCallback[0].skuid != undefined))) {
                if (IsValid) {
                    IsValid = true;
                }
            }
            else {
                if (getQCNotesCallback.length == 0
                    || (getQCNotesCallback.length > 0 &&
                        (getQCNotesCallback[0].skuid == null || getQCNotesCallback[0].skuid == "" || getQCNotesCallback[0].skuid == undefined))) {
                    hideLoader();
                    Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in QA Notes tab.');
                    setButtonLoader(false);
                    setSubmitted(false);
                    if (IsValid) {
                        IsValid = false;
                    }
                    return false;
                }
                else {
                    hideLoader();
                    if (DeplicatedQCSkuID > 0) {
                        Nodify('Warning!', 'warning', 'This SKU Name is Already Exist.');
                    }
                    setButtonLoader(false);
                    setSubmitted(false);
                    if (IsValid) {
                        IsValid = false;
                    }
                    return false;
                }
            }
        }
        else {
            getStyleInfoList.StyleQCNote = [];
            getStyleInfoList.StyleQCNoteAttachment = [];
        }

        //  }
        if (getPage === 'ADDSAMPLEREQUEST') {
            if (getSamplereq === undefined || getSamplereq.length === 0) {
                setSamplereq(EmptySampleReq);
                IsValid = false;
                setSampleReqCallback(true);
                setButtonLoader(false);
                return false;
            } else {

                getSamplereq.forEach((item, i) => {
                    if (item.SampleRequestID === 0 || item.SampleRequestID === '0' || item.SkuColorNameIds === '' || item.SizeIdQtyIds === '') {
                        if (IsValid) {
                            IsValid = false;
                        }
                        return false;
                    }
                });

                if (IsValid === false) {
                    setSampleReqCallback(true);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill all mandatory (*) fields in Sample Request tab.');
                    return false;
                } else {
                    setSampleReqCallback(false);
                }
            }
            getStyleInfoList.StyleSampleRequestInformation = getSamplereq.length === 0 ? EmptySampleReq : getSamplereq;
            setSamplereq([]);
        }
        // if (getPage === 'ADDEEMAIL') {
        // } else {

        // }
        if (IsValid) {

            getStyleInfoList.StyleLogoPath = '';
            if (getStyleInfoList.StyleLogoName !== '' && getStyleInfoList.StyleLogoName !== undefined) {
                let val = "/Style/" + getStyleInfoList.StyleLogoName;
                getStyleInfoList.StyleLogoPath = val;
            }
            //loadList(props.location.state.params.styleID);
            getStyleInfoList.StyleWashCareFileUploadInformation = getFiles;
            getStyleInfoList.StyleBarcodesFileUploadInformation = getBarFiles;
            ;
            getStyleInfoList.IsProduction = IsProduction;
            StyleService.InsertUpdateStyle(getStyleInfoList).then(res => {
                ;
                var page = "Remove";
                res.data.tabPage = getPage;
                SearchRetain(page);
                if (res.data.outputResult === "1") {
                    res.data.Func = 'Add';
                    dispatch(login(currentUser.empUserName, currentUser.empPassword, 0)).then((res) => {
                        //buyer and brand will be updated.
                    });
                    setStyleID(res.data.outputResultStyleID)
                        ;
                    loadList(res.data.outputResultStyleID, IsProduction)
                    getStyleInfoList.Operation = 2;
                    getStyleInfoList.StyleID = parseInt(res.data.outputResultStyleID);
                    setID({ showPopupConfirm: true, Params: res.data });
                    LoadPage(getPage);
                    //Commented on 28Jun2023
                    ;
                    GetStyleList_StrikeOff(parseInt(res.data.outputResultStyleID), IsProduction);
                    //setPage('ADD');
                    //setHeader('ADD STYLE');
                } else if (res.data.outputResult === "2") {
                    dispatch(login(currentUser.empUserName, currentUser.empPassword, 0)).then((res) => {
                        //buyer and brand will be updated.
                    });
                    res.data.Func = 'Edit';
                    loadList(res.data.outputResultStyleID, IsProduction)
                    setID({ showPopupConfirm: true, Params: res.data });
                    LoadPage(getPage);
                    //Commented on 28Jun2023
                    ;
                    GetStyleList_StrikeOff(parseInt(res.data.outputResultStyleID), IsProduction);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', 'Style is already exist.');
                }

                setButtonLoader(false);
                setSubmitted(false);
            }, (err) => {
                ;
            });
        }

    }

    const GetStyleList_StrikeOff = (StyleID, IsProduction) => {
        ;
        StyleService.GetStyleList(StyleID, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
            .then((response) => {
                if (response.data) {
                    setStyle_StrikeOff(response.data.item1[0]);
                    props.history.push('/StyleListRegister', { params: response.data.item1[0] });
                }
            })
            .catch(() => { });
    }

    const LoadPage = (fixPage) => {
        if (fixPage !== 'ADD') {
            let pageLoader = fixPage === 'ADDSKU' ? 'LISTSKU' : fixPage === 'ADDPROGRAM' ?
                'LISTPROGRAM' : fixPage === 'ADDMEASUREMENT' ? 'LISTMEASUREMENT' :
                    fixPage === 'ADDSAMPLEREQUEST' ? 'LISTSAMPLEREQUEST' : fixPage === 'LISTWASHCARE' ? 'LISTWASHCARE' :
                        fixPage === 'LISTBARCODES' ? 'LISTBARCODES' : fixPage === 'ADDMAIL' ? 'LISTEmail' : fixPage === 'LISTStyleQCNotes' ? 'LISTStyleQCNotes' : '';

            setPage(pageLoader);
        }
    }

    const Stylecallback = (value) => {
        // setIsParentCallback(false);
        setStyleInfoList(value);
    }

    const Fabriccallback = (value) => {
        // setIsParentCallback(false);
        setFabricList(value);
    }

    const Remarkscallback = (value) => {
        //  setIsParentCallback(false);
        setRemarksList(value);
    }

    const MeasurementCallback = (value) => {
        setMeasurement(value);
        //setMeasurementList(value);
    }

    const SamplereqCallback = (value) => {
        setSamplereq(value);
    }

    const QCNotesCallback = (value) => {
        setQCNotesCallback(value);
    }

    const IsDataBoundCallback = (value) => {
        setIsDataLoaded(value);
    }
    const RedirectToMaster = (value) => {

        if (value !== "AddMaster") {
            //let a = getStyle_StrikeOff;
            getStyle_StrikeOff.SaveInfo = value;
            getStyle_StrikeOff.SizeList = getSizeList;
            getStyle_StrikeOff.SKUList = getSKUProgram;
            props.history.push('/StrikeOffPickFromMaster', { params: getStyle_StrikeOff });
        }
        else {
            let Param = {
                StyleID: getStyle_StrikeOff.styleID,
                BuyerID: getStyle_StrikeOff.buyerID,
                BrandID: getStyle_StrikeOff.brandID,
                From: "Style"
            }
            props.history.push('/AddStrikeOff', { params: Param });

        }
    }

    const RedirectToTrimMaster = (value) => {
        if (value !== "AddMaster") {
            let a = getStyle_StrikeOff;
            getStyle_StrikeOff.SaveInfo = value;
            getStyle_StrikeOff.SizeList = getSizeList;
            getStyle_StrikeOff.SKUList = getSKUProgram;
            props.history.push('/TrimPickFromMaster', { params: getStyle_StrikeOff });
        }
        else {
            let Param = {
                StyleID: getStyle_StrikeOff.styleID,
                BuyerID: getStyle_StrikeOff.buyerID,
                BrandID: getStyle_StrikeOff.brandID,
                From: "Style"
            }
            props.history.push('/TrimRegister', { params: Param });

        }
    }

    const filevals = [...getFiles]


    const AppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "WashCareUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    filevals.push({
                        StyleWashCareFileId: 0,
                        FileName: res.data + "_" + file.name,
                        FilePath: "Images/Style/WashCare/" + file.name,
                        FileType: file.type
                    })

                    setFiles(filevals);

                    // let Split = getFiles[0].FileName.split("_");
                    // getFiles[0].FileName = Split[1];
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }

            setPage('LISTWASHCARE');
        }
    }
    const RemoveImages = async (index) => {
        filevals.splice(index, 1);
        setFiles(filevals);
    }
    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        setFiles(filevals);
    }

    const ViewFile = (index, filename) => { // event.preventDefault();

        // getFiles[index].FileName = filename;
        setImagename(filename);
        setIsOpenimg(true);
    }

    const Barfilevals = [...getBarFiles]
    let arrVal = [];
    const BarAppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "BarCodeUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    Barfilevals.push({
                        StyleBarCodesFileId: 0,
                        FileName: res.data + "_" + file.name,
                        FilePath: "Images/Style/BarCode/" + file.name,
                        IsDeleted: 0,
                        FileType: file.type
                    })

                    // let filename = Barfilevals[0].FileName.substr(5);
                    setBarFiles(Barfilevals);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
            setPage('LISTBARCODES');
        }
    }
    const BarRemoveImage = async (index) => {
        Barfilevals.splice(index, 1);
        setBarFiles(Barfilevals);
    }
    const BarRemoveFiles = async (index) => {
        Barfilevals.splice(index, 1);
        setBarFiles(Barfilevals);
    }

    const BarViewFile = (index, filename) => { // event.preventDefault();

        getBarFiles[index].FileName = filename
        setBarImagename(filename);
        setBarIsOpenimg(true);
    }
    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/StyleList",
            state: {
                message: Func,
            },
        }
        );
    };
    const ResetOperation = (e) => {

        let params = { localStorage: true };
        if (e === "Back") {
            props.history.push('/StyleList', { params: params })
            //    props.history.push({
            //     pathname: "/StyleList",
            //     state: {
            //         message: "Restore",
            //     },
            // });

        }
        else {
            window.location.reload();
        }
    }




    return (
        <div className="page-body">
            <ReactNotification />


            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeader}</h3>

                            {
                                getPage === 'LISTSKU' || getPage === 'LISTPROGRAM' || getPage === 'LISTSTRIKEOFF' || getPage === 'LISTTRIM' || getPage === 'LISTMEASUREMENT' || getPage === 'LISTSAMPLEREQUEST' || getPage === 'LISTTNA' || getPage === 'LISTEmail' || getPage === "ADDMAIL" || getPage === "VIEWMAIL" || getPage === 'EDITMAIL' ?

                                    <div className="buttons-preview" align="right">
                                        <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                        </a>
                                        {((getIsDataLoaded && getButtonValue === "Update") ||
                                            (getButtonValue === "Save")) && <span className="btn btn-danger" onClick={() => ResetOperation(getPage)}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>}
                                        {((getIsDataLoaded && getButtonValue === "Update") ||
                                            (getButtonValue === "Save")) && <span className={buttonLoader ? "btn btn-success is-disabled" : "btn btn-success"} onClick={!buttonLoader && SaveStyle}>
                                                <i className="fa fa-check right"></i>&nbsp;{getButtonValue}
                                            </span>}
                                    </div> :
                                    <div className="buttons-preview" align="right">
                                        <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                        </a>
                                        {((getIsDataLoaded && getButtonValue === "Update") ||
                                            (getButtonValue === "Save")) && <span className="btn btn-danger" onClick={() => ResetOperation(getPage)}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>}
                                        {((getIsDataLoaded && getButtonValue === "Update") ||
                                            (getButtonValue === "Save")) && <span className={buttonLoader ? "btn btn-success is-disabled" : "btn btn-success"} onClick={!buttonLoader && SaveStyle}>
                                                <i className="fa fa-check right"></i>&nbsp;{getButtonValue}
                                            </span>}
                                    </div>
                            }

                        </div>

                        <div className="widget-body StyleHistoryCard">
                            {
                                getStyleID !== 0 &&
                                <>
                                    <div className="">
                                        <CommonStyle props={props} Style={getStyleInfo} />
                                    </div>
                                </>
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabbable">
                                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                                            <li className={IsFromMaster === "PickFromMaster" ? "" : IsFromTrim === "TrimPickFromMaster" ? "" : "active"}>
                                                <a data-toggle="tab" href="#idStyle" onClick={() => pageRedirect('Style')}>
                                                    <h5>Style </h5>
                                                </a>
                                            </li>

                                            {SkuInfo !== undefined && SkuInfo.isList === 1 &&

                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idSKU" onClick={() => pageRedirect('SKU')}>
                                                        <h5>SKU</h5>
                                                    </a>
                                                </li>
                                            }

                                            {/* {ProgramInfo !== undefined && ProgramInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getSKUList.length === 0 ? 'none' : '', opacity: getSKUList.length === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idCompanyHoliday" onClick={() => pageRedirect('PROGRAM')}>
                                                        <h5>Program</h5>
                                                    </a>
                                                </li>
                                            }  */}

                                            {ProgramInfo !== undefined && ProgramInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? 'none' : '', opacity: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idProgram" onClick={() => pageRedirect('PROGRAM')}>
                                                        <h5>Program</h5>
                                                    </a>
                                                </li>
                                            }
                                            {StrikeInfo !== undefined && StrikeInfo.isList === 1 &&
                                                <li className={IsFromMaster === "PickFromMaster" ? "active" : ""} style={{ pointerEvents: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? 'none' : '', opacity: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idStrikeOff" onClick={() => pageRedirect('STRIKEOFF')}>
                                                        <h5>Strike Off</h5>
                                                    </a>
                                                </li>
                                            }
                                            {TrimInfo !== undefined && TrimInfo.isList === 1 &&
                                                <li className={IsFromTrim === "TrimPickFromMaster" ? "active" : ""} style={{ pointerEvents: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? 'none' : '', opacity: (getSKUList.item1 !== undefined && getSKUList.item1.length === 0) || getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idTrims" onClick={() => pageRedirect('TRIM')}>
                                                        <h5>Trim</h5>
                                                    </a>
                                                </li>
                                            }
                                            {MeasurementInfo !== undefined && MeasurementInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: (getStyleID === 0 || getStyleInfo.styleName === '') ? 'none' : '', opacity: (getStyleID === 0 || getStyleInfo.styleName === '') ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idMeasurement" onClick={() => pageRedirect('MEASUREMENT')}>
                                                        <h5>Measurement</h5>
                                                    </a>
                                                </li>
                                            }
                                            {/* <li className="" style={{ pointerEvents: 'none', opacity: '0.6' }}>
                                                <a data-toggle="tab" href="#idMeasurement" onClick={() => pageRedirect('MEASUREMENT')}>
                                                    <h5>MEASUREMENT</h5>
                                                </a>
                                            </li> */}
                                            {SampleInfo !== undefined && SampleInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: (getStyleID === 0 || getStyleInfo.styleName === '') ? 'none' : '', opacity: (getStyleID === 0 || getStyleInfo.styleName === '') ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idsamplerequest" onClick={() => pageRedirect('SAMPLEREQUEST')}>
                                                        <h5>Sample Request</h5>
                                                    </a>
                                                </li>
                                            }{StyleWashCareInfo && StyleWashCareInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idwashcare" onClick={() => pageRedirect('WASHCARE')}>
                                                        <h5>Wash Care</h5>
                                                    </a>
                                                </li>
                                            }{StyleBarCodeInfo && StyleBarCodeInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idbarcodes" onClick={() => pageRedirect('BARCODES')}>
                                                        <h5>Barcodes</h5>
                                                    </a>
                                                </li>
                                            }
                                            {TNAViewInfo !== undefined && TNAViewInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idViewTna" onClick={() => pageRedirect('TNA')}>
                                                        <h5>View TNA</h5>
                                                    </a>
                                                </li>
                                            }{StylNotificationInfo && StylNotificationInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idEmail" onClick={() => pageRedirect('Email')}>
                                                        <h5>Email</h5>
                                                    </a>
                                                </li>
                                            }
                                            {StylQCNotesInfo && StylQCNotesInfo.isList === 1 &&
                                                <li className="" style={{ pointerEvents: getStyleID === 0 ? 'none' : '', opacity: getStyleID === 0 ? '0.6' : '' }}>
                                                    <a data-toggle="tab" href="#idEmail" onClick={() => pageRedirect('StyleQCNotes')}>
                                                        <h5>QA Notes</h5>
                                                    </a>
                                                </li>
                                            }
                                        </ul>
                                        <div className="tab-content">
                                            <div id="idStyle" className={IsFromMaster === "PickFromMaster" ? "tab-pane" : IsFromTrim === "TrimPickFromMaster" ? "tab-pane" : "tab-pane in active"} style={{ width: "100%" }}>
                                                <Style
                                                    IsDataBoundCallback={IsDataBoundCallback}
                                                    getStyleValue={Stylecallback}
                                                    fabriclist={Fabriccallback}
                                                    remarkslist={Remarkscallback}
                                                    IsValidationCallback={IsParentCallback}
                                                    buttonview={""}
                                                    existList={getStyleInfoList}
                                                    StyleInfoQuick={StyleInfoQuick}
                                                    props={props}
                                                    IsStyleTabClick={IsStyleTabClick}
                                                    getIspage={getIspage}
                                                    getDisableMeasureSize={getDisableMeasureSize}
                                                    getDisableSampleSize={getDisableSampleSize}
                                                    IsDisabled={(props.location.state && props.location.state.params.ispurchaseOrderMapped !== '' && props.location.state.params.ispurchaseOrderMapped !== null) ? true : false}
                                                />
                                            </div>

                                            <div id="idSKU" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTSKU' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">SKU List</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        {/* <div className="table-toolbar pull-right">
                                                                            <span title='Add SKU' className="btn btn-primary additonal_move" onClick={() => OpenSlider()}>
                                                                                <i className="fa fa-plus"></i> Slider
                                                                            </span>
                                                                        </div> */}
                                                                        {SkuInfo !== undefined && SkuInfo.isAdd === 1 &&
                                                                            <div className="table-toolbar pull-right">
                                                                                <span title='Add SKU' className="btn btn-primary additonal_move" onClick={() => RedirectPage('ADDSKU')}>
                                                                                    <i className="fa fa-plus"></i> Add
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <SKU props={props}
                                                                skuredirect={RedirectPage}
                                                                ValidationCallBack={ValidationPopup}
                                                                StyleInfo={getStyleInfo}
                                                                SkuInfo={SkuInfo}
                                                            />
                                                        </>
                                                        :
                                                        ''
                                                }
                                                {
                                                    getPage === 'ADDSKU' ?
                                                        <>
                                                            <AddEditSKU props={props} SKUList={getBindSKU} SKUCallback={SaveSKUList} StyleInfo={getStyleInfo} IsValidationCallback={IsSKUParentCallback} SkuInfo={SkuInfo} />
                                                        </>
                                                        : ''
                                                }
                                            </div>

                                            <div id="idProgram" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTPROGRAM' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Program List</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        {ProgramInfo !== undefined && ProgramInfo.isAdd === 1 &&
                                                                            <div className="table-toolbar pull-right">
                                                                                <span title='Add Program' className="btn btn-primary" onClick={() => RedirectPage('ADDPROGRAM', getStyleID)}>
                                                                                    <i className="fa fa-plus"></i> Add
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Program props={props} programredirect={RedirectPage} ProgramList={getProgramList} StyleInfo={getStyleInfo} ValidationCallBack={ValidationPopup} ProgramCallback={SaveProgram} ProgramInfo={ProgramInfo} />
                                                        </>
                                                        : ''
                                                }
                                                {
                                                    getPage === 'ADDPROGRAM' ?
                                                        <>
                                                            <AddEditPrograms props={props} ProgramList={getBindProgram} ProgramCallback={SaveProgram} SKUDetails={getSKUProgram} FabricList={getFabricListDropdown} ClearSKU={getSKUList.item1}
                                                                IsValidationCallback={IsProgramParentCallback} ProgramInfo={ProgramInfo} ProgramInfoQuick={ProgramInfoQuick} />
                                                        </>
                                                        : ''
                                                }
                                            </div>

                                            <div id="idStrikeOff" className={IsFromMaster === "PickFromMaster" ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                <AddEditStrikeOffList
                                                    ParentCallBack={RedirectToMaster}
                                                    StyleInformation={getStyleInfoList}
                                                    StyleId={getStyleID}
                                                    StrikeInfo={StrikeInfo}
                                                ></AddEditStrikeOffList>
                                            </div>

                                            <div id="idTrims" className={IsFromTrim === "TrimPickFromMaster" ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                <StyleTrim
                                                    ParentCallBack={RedirectToTrimMaster}
                                                    StyleInformation={getStyleInfoList}
                                                    StyleId={getStyleID}
                                                    TrimInfo={TrimInfo}
                                                ></StyleTrim>
                                            </div>

                                            <div id="idMeasurement" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTMEASUREMENT' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Measurement List</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        {MeasurementInfo !== undefined && MeasurementInfo.isAdd === 1 &&
                                                                            <div className="table-toolbar pull-right">
                                                                                <span title='Add Measurement' className="btn btn-primary" onClick={() => RedirectPage('ADDMEASUREMENT')}>
                                                                                    <i className="fa fa-plus"></i> Add
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Measurement props={props} MeasurementRedirect={RedirectPage} MeasurementList={getMeasurement} MeasurementCallback={MeasurementCallback} StyleInfo={getStyleInfo} ValidationCallBack={ValidationPopup} MeasurementInfo={MeasurementInfo} />
                                                        </>
                                                        :
                                                        ''
                                                }
                                                {
                                                    getPage === 'ADDMEASUREMENT' ?
                                                        <>
                                                            <AddEditMeasurement props={props} MeasurementList={getMeasurement} MeasurementCallback={SaveMeasurement}
                                                                setSizeListmeasurement={getSizeListmeasurement} IsValidationCallback={IsMeasurementParentCallback}
                                                                IsValidationChildCallback={IsMeasurementChildCallback}
                                                                MeasurementPageCallback={MeasurementPageCallback} MeasurementInfo={MeasurementInfo}
                                                                MeasurementInfoQuick={MeasurementInfoQuick} />
                                                        </>
                                                        : ''
                                                }
                                            </div>


                                            <div id="idsamplerequest" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTSAMPLEREQUEST' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">

                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Sample Request List</span>
                                                                        </div>

                                                                    </div>
                                                                    {/* {
                                                                        getExistingSamplereq.length !== 2
                                                                            ? */}
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        {SampleInfo !== undefined && SampleInfo.isAdd === 1 &&
                                                                            <div className="table-toolbar pull-right">
                                                                                <span title='Add Sample Request' className="btn btn-primary" onClick={() => RedirectPage('ADDSAMPLEREQUEST')}>
                                                                                    <i className="fa fa-plus"></i> Add
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {/* : ''
                                                                    } */}
                                                                </div>
                                                            </div>

                                                            <SampleReqList props={props} SamplereqRedirect={RedirectPage} SampletreqList={getSamplereq} SamplereqCallback={SamplereqCallback} StyleInfo={getStyleInfo} ValidationCallBack={true} SampleInfo={SampleInfo} />
                                                        </>
                                                        :
                                                        ''
                                                }
                                                {
                                                    getPage === 'ADDSAMPLEREQUEST' ?
                                                        <>
                                                            <SampleReqAdd props={props} SampletreqList={getSamplereq}
                                                                SamplereqCallback={SaveSample} SKUDetails={getSKUProgram}
                                                                setSizeListmeasurement={getSizeListmeasurement}
                                                                IsValidationCallback={IsSampleReqCallback}
                                                                ExistingSamplereq={getExistingSamplereq}
                                                                SampleInfo={SampleInfo}
                                                            />
                                                        </>
                                                        : ''
                                                }
                                            </div>

                                            <div id="idwashcare" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTWASHCARE' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">

                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Wash Care</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                                            <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span
                                                                                style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-8 col-lg-8">
                                                                                <div className='form-group'>
                                                                                    <tr>
                                                                                        <td> <input type="file" id="FileUpload" style={{ display: StyleWashCareInfo && StyleWashCareInfo.isAdd === 1 ? '' : 'none', fontSize: '14px' }}
                                                                                            onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                                                    </tr>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        <div className="col-lg-12 col-sm-12">
                                                                            <div className="row no-margin">
                                                                                {
                                                                                    getFiles.length !== 0 ?
                                                                                        getFiles.map((files, fileindex) => (
                                                                                            files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                                files.FileType === "image/gif" ?
                                                                                                <div className="float-Left_washcare file_Image_uploads">
                                                                                                    {/* <div className="col-sm-3"> */}
                                                                                                    <div className="client_tumblineWashcare">

                                                                                                        <img className="width_manual1"
                                                                                                            src={window.$APIBaseURL + 'Images/Style/WashCare/' + files.FileName}
                                                                                                        //onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename)}
                                                                                                        />

                                                                                                        <table className="table_thum">
                                                                                                            <tr className="table_rwo">
                                                                                                                {/* <td title={files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                                                    {files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)}
                                                                                                                </td> */}
                                                                                                            </tr>
                                                                                                        </table>

                                                                                                        <a className="washcareView" title='View Wash Care' style={{ display: StyleWashCareInfo && StyleWashCareInfo.isView === 1 ? '' : 'none' }} onClick={() => ViewFile(fileindex, files.FileName)}>
                                                                                                            <i className="btn btn-info fa fa-eye"></i>
                                                                                                        </a>&nbsp;
                                                                                                        <a title='Remove Wash Care' style={{ display: StyleWashCareInfo && StyleWashCareInfo.isDelete === 1 ? '' : 'none' }} onClick={() => RemoveImages(fileindex)}>
                                                                                                            <i className="btn btn-danger fa fa-trash-o"></i>
                                                                                                        </a>

                                                                                                    </div>
                                                                                                    {

                                                                                                        < div > {
                                                                                                            isOpenimg && (

                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename}
                                                                                                                    alt="bg image"
                                                                                                                    onCloseRequest={
                                                                                                                        () => setIsOpenimg(false)
                                                                                                                    } />
                                                                                                            )
                                                                                                        } </div>
                                                                                                    }
                                                                                                </div> : ''
                                                                                        ))
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    {

                                                                        <div className="col-lg-12 col-sm-12">
                                                                            <div className="row no-margin">
                                                                                {
                                                                                    getFiles.length !== 0 ?
                                                                                        getFiles.map((files, fileindex) => (
                                                                                            files.FileType === "application/pdf" || files.FileType === "application/msword" || files.FileType === "application/vnd.ms-excel"
                                                                                                || files.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                    <tr title={files.FileName}>
                                                                                                        <td className="cut_textInTNA">
                                                                                                            <span style={{ marginRight: '6px' }}>
                                                                                                                {
                                                                                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                                                                }
                                                                                                                {
                                                                                                                    files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                                                        files.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                                                }
                                                                                                            </span>  {files.FileName}
                                                                                                        </td>

                                                                                                        <td className="button_style_uploda">


                                                                                                            {
                                                                                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                                                    || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                                                                    <a type="button" style={{ display: StyleWashCareInfo && StyleWashCareInfo.isView === 1 ? '' : 'none' }}
                                                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                        href={window.$APIBaseURL + 'Images/Style/WashCare/' + files.FileName} download><i class="fa fa-download"></i> </a> : ''
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {Buttonview !== "View" &&

                                                                                                                <p title='Delete File'
                                                                                                                    style={{ display: StyleWashCareInfo && StyleWashCareInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                    onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                                                    className="btn btn-danger btn-xs delete">
                                                                                                                    <i className="fa fa-trash-o"></i>

                                                                                                                </p>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </div> : ''
                                                                                        ))
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                </div>
                                                            </div>


                                                        </>
                                                        :
                                                        ''
                                                }

                                            </div>
                                            <div id="idbarcodes" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTBARCODES' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">

                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Barcodes</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                                            <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-8 col-lg-8">
                                                                                <div className='form-group'>
                                                                                    <tr>
                                                                                        <td> <input type="file" id="FileUpload" style={{ display: StyleBarCodeInfo && StyleBarCodeInfo.isAdd === 1 ? '' : 'none', fontSize: '14px' }}
                                                                                            onChange={(e) => BarAppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                                                    </tr>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        <div className="col-lg-12 col-sm-12">
                                                                            <div className="row no-margin">
                                                                                {
                                                                                    getBarFiles.length !== 0 ?
                                                                                        getBarFiles.map((files, fileindex) => (
                                                                                            files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                                files.FileType === "image/gif" ?
                                                                                                <div className="float-Left_washcare file_Image_uploads">
                                                                                                    {/* <div className="col-sm-3"> */}
                                                                                                    <div className="client_tumblineWashcare">

                                                                                                        <img className="width_manual1"
                                                                                                            src={window.$APIBaseURL + 'Images/Style/BarCode/' + files.FileName}
                                                                                                        // onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + files.FileName)}
                                                                                                        />

                                                                                                        <table className="table_thum">
                                                                                                            <tr className="table_rwo">
                                                                                                                {/* <td title={files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                                                    {files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)}
                                                                                                                </td> */}
                                                                                                            </tr>
                                                                                                        </table>

                                                                                                        <a className="washcareView" style={{ display: StyleBarCodeInfo && StyleBarCodeInfo.isView === 1 ? '' : 'none' }} title='View BarCodes' onClick={() => BarViewFile(fileindex, files.FileName)}>
                                                                                                            <i className="btn btn-info fa fa-eye"></i>
                                                                                                        </a>&nbsp;
                                                                                                        <a title='Remove BarCodes' style={{ display: StyleBarCodeInfo && StyleBarCodeInfo.isDelete === 1 ? '' : 'none' }} onClick={() => BarRemoveImage(fileindex)}>
                                                                                                            <i className="btn btn-danger fa fa-trash-o"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    {/* </div> */}
                                                                                                    {

                                                                                                        < div > {
                                                                                                            BarisOpenimg && (

                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/BarCode/' + getBarImagename}
                                                                                                                    alt="bg image"
                                                                                                                    onCloseRequest={
                                                                                                                        () => setBarIsOpenimg(false)
                                                                                                                    } />
                                                                                                            )
                                                                                                        } </div>
                                                                                                    }
                                                                                                </div> : ''
                                                                                        ))
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="col-lg-12 col-sm-12">
                                                                            <div className="row no-margin">
                                                                                {
                                                                                    getBarFiles.length !== 0 ?
                                                                                        getBarFiles.map((files, fileindex) => (
                                                                                            files.FileType === "application/pdf" || files.FileType === "application/msword" || files.FileType === "application/vnd.ms-excel"
                                                                                                || files.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                    <tr title={files.FileName}>
                                                                                                        <td className="cut_textInTNA">
                                                                                                            <span style={{ marginRight: '6px' }}>
                                                                                                                {
                                                                                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                                                                }
                                                                                                                {
                                                                                                                    files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                                                        files.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                                                }
                                                                                                            </span>  {files.FileName}
                                                                                                        </td>

                                                                                                        <td className="button_style_uploda">
                                                                                                            {
                                                                                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                                                    || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                                                                    <a type="button" style={{ display: StyleBarCodeInfo && StyleBarCodeInfo.isView === 1 ? '' : 'none' }}
                                                                                                                        title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                                        href={window.$APIBaseURL + 'Images/Style/BarCode/' + files.FileName} download><i class="fa fa-download"></i> </a> : ''
                                                                                                            } &nbsp;
                                                                                                            {Buttonview !== "View" &&

                                                                                                                <p title='Delete File'
                                                                                                                    style={{ display: StyleBarCodeInfo && StyleBarCodeInfo.isDelete === 1 ? '' : 'none' }}
                                                                                                                    onClick={() => BarRemoveFiles(fileindex)}
                                                                                                                    className="btn btn-danger btn-xs delete">
                                                                                                                    <i className="fa fa-trash-o"></i>

                                                                                                                </p>
                                                                                                            }


                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </div> : ''
                                                                                        ))
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>


                                                        </>
                                                        :
                                                        ''
                                                }

                                            </div>


                                            <div id="idViewTna" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTTNA' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">View TNA List</span>
                                                                        </div>
                                                                    </div>
                                                                    <ViewTNA props={props} Action={'Style'} TNAViewInfo={TNAViewInfo}> </ViewTNA>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ''
                                                }
                                            </div>
                                            <div id="idEmail" className="tab-pane" style={{ width: "100%" }}>
                                                {
                                                    getPage === 'LISTEmail' ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="widget-header SkuList">
                                                                            <span className="widget-caption">Email List (31 Days)</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">

                                                                        <div className="table-toolbar pull-right">
                                                                            <span style={{ display: StylNotificationInfo && StylNotificationInfo.isAdd === 1 ? '' : 'none' }} title='Add Email' className="btn btn-primary" onClick={() => RedirectPage('ADDMAIL')}>
                                                                                <i className="fa fa-plus"></i> Compose
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <StyleMail StyleInfo={{ styleID: getStyleInfoList.StyleID }} /> */}
                                                                    <StyleEmailList props={props} MailRedirect={RedirectPage}
                                                                        StyleInfo={{ styleID: getStyleInfoList.StyleID }} />

                                                                </div>
                                                            </div>
                                                        </>
                                                        : ''
                                                }
                                                {
                                                    getPage === 'ADDMAIL' || getPage === 'VIEWMAIL' || getPage === 'EDITMAIL' ?
                                                        <>
                                                            <StyleMail props={props}

                                                                StyleInfo={{ styleID: getStyleInfoList.StyleID }} MailRedirect={RedirectPage} EmailList={getMailreq} />

                                                        </>
                                                        : ''
                                                }
                                                {
                                                    getPage === 'LISTStyleQCNotes' ?
                                                        <>
                                                            <StyleQCNotes props={props} QCNotesCallback={QCNotesCallback} QCNotesList={getQCNotes} SKUSampleApproval={getSKUSampleApproval}

                                                                StyleInfo={{ styleID: getStyleInfoList.StyleID }} MailRedirect={RedirectPage} EmailList={getMailreq} />

                                                        </>
                                                        : ''
                                                }
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                    /> : null
                }
            </div >

            {/* {
                // (getPage === 'LISTStyle' || getPage === 'ADDStyle' || getPage === 'ADDSKU' || getPage === 'LISTPROGRAM' || getPage === 'LISTSTRIKEOFF' || getPage === 'LISTTRIM' || getPage === 'ADDPROGRAM' || getPage === 'LISTMEASUREMENT' || getPage === 'LISTSAMPLEREQUEST' || getPage === 'LISTTNA' || getPage === 'LISTEmail' || getPage === "ADDMAIL" || getPage === "VIEWMAIL" || getPage === 'EDITMAIL') ?
                getPage === 'LISTStyle' || getPage === 'ADDStyle' || getPage === 'LISTSKU' || getPage === 'ADDSKU' || getPage === 'ADDPROGRAM' || getPage === 'LISTMEASUREMENT' || getPage === 'ADDMEASUREMENT' || getPage === 'LISTSAMPLEREQUEST' || getPage === 'ADDSAMPLEREQUEST' || getPage === 'LISTTNA' || getPage === 'LISTEmail' || getPage === "ADDMAIL" || getPage === "VIEWMAIL" || getPage === 'EDITMAIL' ?
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                                <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </a>
                                {((getIsDataLoaded && getButtonValue === "Update") ||
                                    (getButtonValue === "Save")) && <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>&nbsp;Reset
                                    </span>}
                                {((getIsDataLoaded && getButtonValue === "Update") ||
                                    (getButtonValue === "Save")) && <span className={buttonLoader ? "btn btn-success is-disabled" : "btn btn-success"} onClick={!buttonLoader && SaveStyle}>
                                        <i className="fa fa-check right"></i>&nbsp;{getButtonValue}
                                    </span>}
                            </div>
                        </div>
                    </div> : ''
            } */}
            <>
                {
                    getPage === 'LISTSKU' || getPage === 'LISTPROGRAM' || getPage === 'LISTSTRIKEOFF' || getPage === 'LISTTRIM'
                        || getPage === 'LISTMEASUREMENT'
                        || getPage === 'LISTSAMPLEREQUEST' || getPage === 'LISTTNA' || getPage === 'LISTEmail' || getPage === "ADDMAIL"
                        || getPage === "VIEWMAIL" || getPage === 'EDITMAIL' ?

                        <div className="buttons-preview" align="right">
                            <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </a>
                            {((getIsDataLoaded && getButtonValue === "Update") ||
                                (getButtonValue === "Save")) && <span className="btn btn-danger" onClick={() => ResetOperation(getPage)}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>}
                            {((getIsDataLoaded && getButtonValue === "Update") ||
                                (getButtonValue === "Save")) && <span className={buttonLoader ? "btn btn-success is-disabled" : "btn btn-success"} onClick={!buttonLoader && SaveStyle}>
                                    <i className="fa fa-check right"></i>&nbsp;{getButtonValue}
                                </span>}
                        </div> :
                        <div className="buttons-preview" align="right">
                            <a className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                            </a>
                            {((getIsDataLoaded && getButtonValue === "Update") ||
                                (getButtonValue === "Save")) && <span className="btn btn-danger" onClick={() => ResetOperation(getPage)}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>}
                            {((getIsDataLoaded && getButtonValue === "Update") ||
                                (getButtonValue === "Save")) && <span className={buttonLoader ? "btn btn-success is-disabled" : "btn btn-success"} onClick={!buttonLoader && SaveStyle}>
                                    <i className="fa fa-check right"></i>&nbsp;{getButtonValue}
                                </span>}
                        </div>
                }
            </>
            {loader}
        </div >
    )
};
export default StyleListRegister;