import React, { useState, useEffect, useMemo, useRef } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import { LoadTnaDetailsreport } from "../../actions/tnadetailsreport";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Nodify from "../Common/ReactNotification";
import { LoadCommentsReportDetails } from "../../actions/reports";
import ReactNotification from 'react-notifications-component';
import SearchRetain from "../Common/SearchRetain";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import { Pagination, Search, PageCount, ReactSearch } from "../Datatable";
import TableHeader from '../Datatable/Header/commetstyle'
//import CommentReportService from '../../services/Report/ReportService'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReportService from "../../services/Report/ReportService";
import * as XLSX from 'xlsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import "./TNA.css"
import { Fragment } from "babel-plugin-react-html-attrs";
const TNADetailsReport = (props) => {
    //)
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [getHideID, setHideID] = useState({
        Po: true,
        season: true,
        Quarter: true,
        brand: true,
        customer: true,
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,
        styCatRecived: true,
        smsPo: true,
        LdSubmit: true,
        LdApproved: true,
        strikeSubmission: true,
        strikeApprv: true,
        strikeOrderNominate: true,
        strikeReceiptNominate: true,
        strikeReceiptBuyer: true,
        TrimSubmission: true,
        TrimApproval: true,
        TrimOrderNominate: true,
        Trimrcptfrombuyer: true,
        TrimReceiptNominate: true,
        TrimOrderLocalSupplier: true,
        TrimReciptLocalSupplier: true,
        protoSubmission: true,
        protoApproval: true,
        fitSubmission: true,
        fitApproval: true,
        smsOrder: true,
        smsInHouse: true,
        smsProduction: true,
        barcodeRecipt: true,
        barcodeApprvl: true,
        apperanceAftWash: true,
        finalInspec: true,
        smsSubmis: true,
        smsApprvl: true,
    });
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [getComment, setComment] = useState({ EnableComments: 0, EnterComment: '', PurchaseOrderSkuID: 0, reportComments: [] })
    //const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    //const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [checked, setchecked] = useState('0');

    const [table, settable] = useState('0');
    const [show, setShow] = useState(false);

    const [status, setStatus] = useState(true);
    const [seasoncheck, setseasoncheck] = useState(true)
    const [Quartercheck, setQuartercheck] = useState(true)

    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [sizeqtylist, setsizeqtylist] = useState([])
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 })

    // PO/season/Quarter
    //const [getHideID, setHideID] = useState({ Po: true, season: true, Quarter: true });
    const Values = { ...getComment }
    const OrdStatus = { ...showmodal }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    activeMenu = menulist.filter(i => i.menuName === "TNADetailsReport")




    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {

            startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    let IsProductionStatus = [
        {
            value: 1,
            label: 'Sampling',
            selected: true,

        },
        {
            value: 2,
            label: 'Production',
            selected: false,

        }
    ]

    useEffect(() => {
       ;
        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        localStorage.setItem('value', (1));
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: Defaultvalue.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        setIsProductionStatussearch(Defaultvalue);
        dispatch(LoadTnaDetailsreport(params));
    }, []);
    const Isproduction = JSON.parse(localStorage.getItem("value"));
    //const TnaDetailsReportList = reducerState.tnadetails.TnaDetailsReportList;

    const TnaDetailsReportList = reducerState.tnadetails.TnaDetailsReportList.item1 !== undefined ? reducerState.tnadetails.TnaDetailsReportList.item1 : reducerState.tnadetails.TnaDetailsReportList;

    if (TnaDetailsReportList.length > 0) {
        for (let index = 0; index < TnaDetailsReportList.length; index++) {
            let Parent = TnaDetailsReportList[index];
            for (let i = 0; i < reducerState.tnadetails.TnaDetailsReportList.item2.length; i++) {
                let Child = reducerState.tnadetails.TnaDetailsReportList.item2[i];
                if (Child.purchaseOrderID === Parent.purchaseOrderID && Child.indexName === Parent.indexName) {

                    Parent.styleName = Child.styleName; Parent.styleNo = Child.styleNo; Parent.categoryName = Child.categoryName;
                    Parent.buyerEmployee = Child.buyerEmployee; Parent.cubeEmployee = Child.cubeEmployee;
                }

            }

        }

    }

    const isLoadingTnaDetailsReport = reducerState.tnadetails.isLoadingTnaDetailsReport;
    // loader
    useEffect(() => {
        if (!isLoadingTnaDetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTnaDetailsReport]);



    // const EmptyCheck = () =>
    // {// change null and number into string for sorting functionality
    // TnaDetailsReportList.map(datass => {
    //     if (datass.statusCreatedBy === null) {
    //         datass.statusCreatedBy = ""
    //     }
    //     else if (datass.commentsCreatedBy === null) {
    //         datass.commentsCreatedBy = ""
    //     }

    //     else if (datass.currentComments === null) {
    //         datass.currentComments = ""
    //     }

    //     else if (datass.orderStatus === null) {
    //         datass.orderStatus = ""
    //     }
    //     else if (datass.purchaseOrderSkuID !== isNaN) {

    //         datass.purchaseOrderSkuID = datass.purchaseOrderSkuID.toString()
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.styleCatalogReceived === null) {
    //         datass.styleCatalogReceived = ""
    //     }
    //     else if (datass.smsPurchaseOrder === null) {
    //         datass.smsPurchaseOrder = ""
    //     }
    //     else if (datass.labDipsSubmitted === null) {
    //         datass.labDipsSubmitted = ""
    //     }
    //     else if (datass.labDipsApproved === null) {
    //         datass.labDipsApproved = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffSubmission === null) {
    //         datass.strikeoffSubmission = ""
    //     }
    //     else if (datass.strikeoffApproval === null) {
    //         datass.strikeoffApproval = ""
    //     }
    //     else if (datass.strikeoffOrdertoNominattedSupplier === null) {
    //         datass.strikeoffOrdertoNominattedSupplier = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffReceiptFromBuyer === null) {
    //         datass.strikeoffReceiptFromBuyer = ""
    //     }
    //     else if (datass.trimSubmission === null) {
    //         datass.trimSubmission = ""
    //     }
    //     else if (datass.trimApproval === null) {
    //         datass.trimApproval = ""
    //     }
    //     else if (datass.trimOrdertoNominattedSupplier === null) {
    //         datass.trimOrdertoNominattedSupplier = ""
    //     }
    //     else if (datass.trimReceiptFromBuyer === null) {
    //         datass.trimReceiptFromBuyer = ""
    //     }


    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleApproval === null) {
    //         datass.fitSampleApproval = ""
    //     }
    //     else if (datass.smsFabricOrder === null) {
    //         datass.smsFabricOrder = ""
    //     }
    //     else if (datass.smsFabricInHouse === null) {
    //         datass.smsFabricInHouse = ""
    //     }
    //     else if (datass.smsProductionFollow === null) {
    //         datass.smsProductionFollow = ""
    //     }
    //     else if (datass.barcodeFileReceiptFromBuyer === null) {
    //         datass.barcodeFileReceiptFromBuyer = ""
    //     }
    //     else if (datass.barcodeApproval === null) {
    //         datass.barcodeApproval = ""
    //     }
    //     else if (datass.apperanceAfterWash === null) {
    //         datass.apperanceAfterWash = ""
    //     }
    //     else if (datass.finalInspection === null) {
    //         datass.finalInspection = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleApproval === null) {
    //         datass.fitSampleApproval = ""
    //     }
    //     else if (datass.smsFabricOrder === null) {
    //         datass.smsFabricOrder = ""
    //     }
    //     else if (datass.smsFabricInHouse === null) {
    //         datass.smsFabricInHouse = ""
    //     }
    //     else if (datass.smsProductionFollow === null) {
    //         datass.smsProductionFollow = ""
    //     }
    //     else if (datass.barcodeFileReceiptFromBuyer === null) {
    //         datass.barcodeFileReceiptFromBuyer = ""
    //     }
    //     else if (datass.barcodeApproval === null) {
    //         datass.barcodeApproval = ""
    //     }

    //     else if (datass.finalInspection === null) {
    //         datass.finalInspection = ""
    //     }
    //     else if (datass.smsSubmission === null) {
    //         datass.smsSubmission = ""
    //     }
    //     else if (datass.smsApproval === null) {
    //         datass.smsApproval = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffReceiptfromNominattedSupplier === null) {
    //         datass.strikeoffReceiptfromNominattedSupplier = ""
    //     }
    //     else if (datass.trimReceiptfromNominattedSupplier === null) {
    //         datass.trimReceiptfromNominattedSupplier = ""
    //     }
    //     else if (datass.trimOrdertoLocalSupplier === null) {
    //         datass.trimOrdertoLocalSupplier = ""
    //     }
    //     else if (datass.trimReceiptfromLocalSupplier === null) {
    //         datass.trimReceiptfromLocalSupplier = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleSubmission === null) {
    //         datass.fitSampleSubmission = ""
    //     } else if (datass.smsSubmission === null) {
    //         datass.smsSubmission = ""
    //     }
    //     else if (datass.smsApproval === null) {
    //         datass.smsApproval = ""
    //     }
    //     else if (datass.protoSampleSubmission === null) {
    //         datass.protoSampleSubmission = ""
    //     }
    //     else if (datass.protoSampleApproval === null) {
    //         datass.protoSampleApproval = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.protoSampleApproval === null) {
    //         datass.protoSampleApproval = ""
    //     }
    //     else if (datass.apperanceAfterWash === null || datass.apperanceAfterWash === undefined) {
    //         datass.apperanceAfterWash = ""
    //     }
    //     else {

    //     }

    // })

    // }


    // // change null and number into string for sorting functionality
    // TnaDetailsReportList.map(datass => {
    //     if (datass.statusCreatedBy === null) {
    //         datass.statusCreatedBy = ""
    //     }
    //     else if (datass.commentsCreatedBy === null) {
    //         datass.commentsCreatedBy = ""
    //     }

    //     else if (datass.currentComments === null) {
    //         datass.currentComments = ""
    //     }

    //     else if (datass.orderStatus === null) {
    //         datass.orderStatus = ""
    //     }
    //     else if (datass.purchaseOrderSkuID !== isNaN) {

    //         datass.purchaseOrderSkuID = datass.purchaseOrderSkuID.toString()
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.styleCatalogReceived === null) {
    //         datass.styleCatalogReceived = ""
    //     }
    //     else if (datass.smsPurchaseOrder === null) {
    //         datass.smsPurchaseOrder = ""
    //     }
    //     else if (datass.labDipsSubmitted === null) {
    //         datass.labDipsSubmitted = ""
    //     }
    //     else if (datass.labDipsApproved === null) {
    //         datass.labDipsApproved = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffSubmission === null) {
    //         datass.strikeoffSubmission = ""
    //     }
    //     else if (datass.strikeoffApproval === null) {
    //         datass.strikeoffApproval = ""
    //     }
    //     else if (datass.strikeoffOrdertoNominattedSupplier === null) {
    //         datass.strikeoffOrdertoNominattedSupplier = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffReceiptFromBuyer === null) {
    //         datass.strikeoffReceiptFromBuyer = ""
    //     }
    //     // else if (datass.trimSubmission === null) {
    //     //     datass.trimSubmission = ""
    //     // }
    //     else if (datass.trimApproval === null) {
    //         datass.trimApproval = ""
    //     }
    //     else if (datass.trimOrdertoNominattedSupplier === null) {
    //         datass.trimOrdertoNominattedSupplier = ""
    //     }
    //     else if (datass.trimReceiptFromBuyer === null) {
    //         datass.trimReceiptFromBuyer = ""
    //     }


    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleApproval === null) {
    //         datass.fitSampleApproval = ""
    //     }
    //     else if (datass.smsFabricOrder === null) {
    //         datass.smsFabricOrder = ""
    //     }
    //     else if (datass.smsFabricInHouse === null) {
    //         datass.smsFabricInHouse = ""
    //     }
    //     else if (datass.smsProductionFollow === null) {
    //         datass.smsProductionFollow = ""
    //     }
    //     else if (datass.barcodeFileReceiptFromBuyer === null) {
    //         datass.barcodeFileReceiptFromBuyer = ""
    //     }
    //     else if (datass.barcodeApproval === null) {
    //         datass.barcodeApproval = ""
    //     }
    //     else if (datass.apperanceAfterWash === null) {
    //         datass.apperanceAfterWash = ""
    //     }
    //     else if (datass.finalInspection === null) {
    //         datass.finalInspection = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleApproval === null) {
    //         datass.fitSampleApproval = ""
    //     }
    //     else if (datass.smsFabricOrder === null) {
    //         datass.smsFabricOrder = ""
    //     }
    //     else if (datass.smsFabricInHouse === null) {
    //         datass.smsFabricInHouse = ""
    //     }
    //     else if (datass.smsProductionFollow === null) {
    //         datass.smsProductionFollow = ""
    //     }
    //     else if (datass.barcodeFileReceiptFromBuyer === null) {
    //         datass.barcodeFileReceiptFromBuyer = ""
    //     }
    //     else if (datass.barcodeApproval === null) {
    //         datass.barcodeApproval = ""
    //     }

    //     else if (datass.finalInspection === null) {
    //         datass.finalInspection = ""
    //     }
    //     else if (datass.smsSubmission === null) {
    //         datass.smsSubmission = ""
    //     }
    //     else if (datass.smsApproval === null) {
    //         datass.smsApproval = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.strikeoffReceiptfromNominattedSupplier === null) {
    //         datass.strikeoffReceiptfromNominattedSupplier = ""
    //     }
    //     else if (datass.trimReceiptfromNominattedSupplier === null) {
    //         datass.trimReceiptfromNominattedSupplier = ""
    //     }
    //     else if (datass.trimOrdertoLocalSupplier === null) {
    //         datass.trimOrdertoLocalSupplier = ""
    //     }
    //     else if (datass.trimReceiptfromLocalSupplier === null) {
    //         datass.trimReceiptfromLocalSupplier = ""
    //     }
    //     else {

    //     }

    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.fitSampleSubmission === null) {
    //         datass.fitSampleSubmission = ""
    //     } else if (datass.smsSubmission === null) {
    //         datass.smsSubmission = ""
    //     }
    //     else if (datass.smsApproval === null) {
    //         datass.smsApproval = ""
    //     }
    //     else if (datass.protoSampleSubmission === null) {
    //         datass.protoSampleSubmission = ""
    //     }
    //     else if (datass.protoSampleApproval === null) {
    //         datass.protoSampleApproval = ""
    //     }
    //     else {

    //     }
    // })
    // TnaDetailsReportList.map(datass => {

    //     if (datass.protoSampleApproval === null) {
    //         datass.protoSampleApproval = ""
    //     }
    //     else if (datass.apperanceAfterWash === null || datass.apperanceAfterWash === undefined) {
    //         datass.apperanceAfterWash = ""
    //     }
    //     else {

    //     }

    // })
    //End Changes Sorting
    //  useEffect(() => {
    //     dispatch(LoadTnaDetailsreport(1));
    // }, []);
    // useEffect()


    const StatusClose = () => {
        let orrStatus = { ...showmodal }
        orrStatus.EnableComments = 0;
        setShowmodal(orrStatus);
    }
    const tableHeader = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: "45px",
            //width: "2%",
              position: "sticky",
              left: 0,
               background: "#222d32",

        },
        getHideID.Po === true
            ? {
                name: "PO Date",
                field: "poDate",
                sortable: true,
                widthsize: "137px",
                position: "sticky",
                left: "44px",
                background: "#222d32",
            }
            : false,
        getHideID.skuID === true
            ? {
                name: "SKU ID",
                field: "purchaseOrderSkuID",
                sortable: true,
                widthsize: "120px",
                position: "sticky",
                left: "180px",
                background: "#222d32",
            }
            : false,

        getHideID.season === true
            ? {
                name: "Season",
                field: "seasonName",
                sortable: true,
                width: "3%",
                widthsize: "120px",
                position: "sticky",
                left: "300px",
                background: "#222d32",
            }
            : false,

        getHideID.Quarter === true
            ? {
                name: "Quarter",
                field: "quarterName",
                sortable: true,
                width: "1%",
                widthsize: "120px",
                position: "sticky",
                left: "420px",
                background: "#222d32",
            }
            : false,

        getHideID.brand === true
            ? {
                name: "Brand",
                field: "brandName",
                sortable: true,
                width: "5%",
                widthsize: "120px",
                position: "sticky",
                left: "540px",
                background: "#222d32",
            }
            : false,

        getHideID.customer === true
            ? {
                name: "Customer",
                field: "customerName",
                sortable: true,
                width: "5%",
                widthsize: "120px",
                position: "sticky",
                left: "660px",
                background: "#222d32",
            }
            : false,
        getHideID.skuName === true
            ? {
                name: "SKU Name",
                field: "skuName",
                sortable: true,
                // width: "5%",
                // widthsize: "120px",
                widthsize: "137px",
                position: "sticky",
                //left: "736px",
                left: "778px",
                background: "#222d32",
            }
            : false,
        getHideID.skuImg === true
            ? {
                name: "SKU Image",
                field: "skuImage",
                sortable: true,
                // width: "4%",
                widthsize: "120px",
                position: "sticky",
                left: "915px",
                background: "#222d32",
            }
            : false,
        getHideID.supplier === true
            ? {
                name: "Supplier",
                field: "supplierName",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.idNo === true
            ? {
                name: "ID No",
                field: "idpono",
                sortable: true,
                width: "4%",
            }
            : false,
        getHideID.category === true
            ? {
                name: "Category",
                field: "categoryName",
                sortable: true,
                width: "4%",
            }
            : false,

        // {
        //     name: "Gender",
        //     field: "Gender",
        //     sortable: true,
        //     width: "4%",
        // },
        getHideID.styleName === true
            ? {
                name: "Style Name",
                field: "styleName",
                sortable: true,
                width: "7%",
            }
            : false,
        getHideID.styleNo === true
            ? {
                name: "Style No",
                field: "styleNo",
                sortable: true,
                width: "7%",
            }
            : false,

        getHideID.size === true
            ? {
                name: "Size",
                field: "size",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.orderQty === true
            ? {
                name: "Order Qty",
                field: "quantity",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.exFac === true
            ? {
                name: "Ex-Fac Date",
                field: "exFacDate",
                sortable: true,
                width: "4%",
            }
            : false,
        getHideID.cubeEmp === true
            ? {
                name: "Cube Follower",
                field: "cubeEmployee",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.buyerEmp === true
            ? {
                name: "Buyer Follower",
                field: "buyerEmployee",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.orderStatus === true
            ? {
                name: "Order Status",
                field: "orderStatus",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.stsCreated === true
            ? {
                name: "	Status Created By",
                field: "statusCreatedBy",
                sortable: true,
                width: "2%",
            }
            : false,

        getHideID.currentStatus === true
            ? {
                name: "Current Status Comment",
                field: "currentComments",
                sortable: true,
                width: "5%",
            }
            : false,
        getHideID.cmtCreated === true
            ? {
                name: "Comment Created By",
                field: "commentsCreatedBy",
                sortable: true,
                width: "2%",
            }
            : false,

        //New added header fields
        getHideID.styCatRecived === true ? {
            name: "Style Catalog Received",
            field: "styleCatalogReceived",
            sortable: true,

        } : false,
        getHideID.smsPo === true ? {
            name: "SMS Purchase Order",
            field: "smsPurchaseOrder",
            sortable: true,
        } : false,
        getHideID.LdSubmit === true ? {
            name: "Lab Dip Submission",
            field: "labDipsSubmitted",
            sortable: true,
        } : false,
        getHideID.LdApproved === true ? {
            name: "Lab Dip Approved",
            field: "labDipsApproved",
            sortable: true,
        } : false,
        getHideID.strikeSubmission === true ? {
            name: "Strike Off Submission",
            field: "strikeoffSubmission",
            sortable: true,
        } : false,
        getHideID.strikeApprv === true ? {
            name: "Strike Off Approval",
            field: "strikeoffApproval",
            sortable: true,
        } : false,
        getHideID.strikeOrderNominate === true ? {
            name: "Strike Off Order to Nominatted Supplier",
            field: "strikeoffOrdertoNominattedSupplier",
            sortable: true,
        } : false,
        getHideID.strikeReceiptNominate === true ? {
            name: "Strike Off Receipt From Nominatted Supplier",
            field: "strikeoffReceiptfromNominattedSupplier",
            sortable: true,
        } : false,
        getHideID.strikeReceiptBuyer === true ? {
            name: "Strike Off Receipt From Buyer",
            field: "strikeoffReceiptFromBuyer",
            sortable: true,
        } : false,
        getHideID.TrimSubmission === true ? {
            name: "Trim Submission",
            field: "trimSubmission",
            sortable: true,
        } : false,
        getHideID.TrimApproval === true ? {
            name: "Trim Approval",
            field: "trimApproval",
            sortable: true,
        } : false,
        getHideID.TrimOrderNominate === true ? {
            name: "Trim Order to Nominatted Supplier",
            field: "trimOrdertoNominattedSupplier",
            sortable: true,
        } : false,
        getHideID.Trimrcptfrombuyer === true ? {
            name: "Trim Receipt From Buyer",
            field: "trimReceiptFromBuyer",
            sortable: true,
        } : false,

        getHideID.TrimReceiptNominate === true ? {
            name: "Trim Receipt From Nominatted Supplier",
            field: "trimReceiptfromNominattedSupplier",
            sortable: true,
        } : false,
        getHideID.TrimOrderLocalSupplier === true ? {
            name: "Trim Order to Local Supplier",
            field: "trimOrdertoLocalSupplier",
            sortable: true,
        } : false,
        getHideID.TrimReciptLocalSupplier === true ? {
            name: "Trim Receipt From Local Supplier",
            field: "trimReceiptfromLocalSupplier",
            sortable: true,
        } : false,
        getHideID.protoSubmission === true ? {
            name: "Proto Sample Submission",
            field: "protoSampleSubmission",
            sortable: true,
        } : false,
        getHideID.protoApproval === true ? {
            name: "Proto Sample Approval",
            field: "protoSampleApproval",
            sortable: true,
        } : false,

        getHideID.fitSubmission === true ? {
            name: "Fit Sample Submission",
            field: "fitSampleSubmission",
            sortable: true,
        } : false,
        getHideID.fitApproval === true ? {
            name: "Fit Sample Approval",
            field: "fitSampleApproval",
            sortable: true,
        } : false,
        getHideID.smsOrder === true ? {
            name: "SMS Fabric Order",
            field: "smsFabricOrder",
            sortable: true,
        } : false,
        getHideID.smsInHouse === true ? {
            name: "SMS Fabric In-house",
            field: "smsFabricInHouse",
            sortable: true,
        } : false,
        getHideID.smsProduction === true ? {
            name: "SMS Production Follow",
            field: "smsProductionFollow",
            sortable: true,
        } : false,
        getHideID.barcodeRecipt === true ? {
            name: "Barcode File Receipt From Buyer",
            field: "barcodeFileReceiptFromBuyer",
            sortable: true,
        } : false,
        getHideID.barcodeApprvl === true ? {
            name: "Barcode Approval",
            field: "barcodeApproval",
            sortable: true,
        } : false,
        getHideID.apperanceAftWash === true ? {
            name: "Apperance After Wash ",
            field: "apperanceAfterWash",
            sortable: true,
        } : false,
        getHideID.finalInspec === true ? {
            name: "Final Inspection",
            field: "finalInspection",
            sortable: true,
        } : false,
        getHideID.smsSubmis === true ? {
            name: "SMS Submission",
            field: "smsSubmission",
            sortable: true,
        } : false,
        getHideID.smsApprvl === true ? {
            name: "SMS Approval",
            field: "smsApproval",
            sortable: true,
        } : false,

        // {
        //     name: "Action",
        //     field: "Action",
        //     className: "text-center fixed-column-header-Route",
        //     sortable: false,
        // }
    ];


    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]

    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateDate[0].isShow = isShow;
        getStateExDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
    }
    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateExDate(getStateExDate);
    }
    const clearDateSelect = () => {
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        //var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        //var defaultExStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(NewemptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(NewemptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: Isproduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
    }
    const clearExDateSelect = () => {
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateExDate(emptyStateExDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultExStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(NewemptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(NewemptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: Isproduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
    }

    function DateExfaconchange(item) {
        stateExDate[0].startDate = item[0].startDate;
        stateExDate[0].endDate = item[0].endDate
        setStateExDate(item)

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
    }

    function DateRangechange(item) {
       
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
    }

    const commentsData = useMemo(() => {

        let computedComments = TnaDetailsReportList;

        let drpvalue = orderStatus;

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment =>
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getBuyerEmpsearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getCubeEmpsearch) {
            computedComments = computedComments.filter(comment =>
                comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getOrderStatussearch) {


            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                    // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            } else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }

            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.poDate, format)) >= stateDate[0].startDate && new Date(moment(comment.poDate, format)) <= stateDate[0].endDate
            )
        }
        setTotalItems(computedComments.length);
        let Exformat = "DD/MM/YYYY";
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = new Date(moment(stateExDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = new Date(moment(stateExDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (stateExDate[0].startDate && stateExDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.exFacDate, Exformat)) >= stateExDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateExDate[0].endDate
            )
        }
        setTotalItems(computedComments.length);

        //sorting table
        if (sorting.field) {
            computedComments.map((x) => {
                x.purchaseOrderSkuID = x.purchaseOrderSkuID.toString();
            })
            const reversed = sorting.order === "asc" ? 1 : -1;

            // if(sorting.field === 'quantity')
            // {
            //   //computedComments = [...computedComments].sort((a, b) => a - b);
            //   computedComments = computedComments.sort((a, b) => {
            //     const valueA = parseFloat(a[sorting.field]);
            //     const valueB = parseFloat(b[sorting.field]);

            //     // Assuming sorting.field contains strings representing numbers
            //     return reversed * (valueA - valueB);
            //   });

            // }
            // else
            // {
            //   computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

            // }

            computedComments = computedComments.sort((a, b) => {
                const valueA = a[sorting.field];
                const valueB = b[sorting.field];

                // Check if values are numeric
                const isNumericA = !isNaN(valueA);
                const isNumericB = !isNaN(valueB);

                if (isNumericA && isNumericB) {
                    // If both values are numeric, compare as numbers
                    return reversed * (parseFloat(valueA) - parseFloat(valueB));
                } else {
                    // If one or both values are non-numeric, compare as strings
                    return reversed * valueA.toString().localeCompare(valueB.toString());
                }
            });

            // computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            TnaDetailsReportList,
            currentPage,
            // search,
            getBuyersearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateExDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);
    const resetStyle = () => {
        setBuyerSearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateDate(emptyStateDate);
        setStateExDate(emptyStateExDate);
        //let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(getIsProductionStatussearch);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(emptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(emptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let value = getIsProductionStatussearch.label === "Production" ? 2 : 1;
        let params = { IsProduction: value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
        //EmptyCheck();
    }
    const handleChangeOrdStatus = (value) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
        setOrderStatusSearch(value);
        //setIsProductionStatussearch(value);
    }
    const handleclick = () => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);

    }

    const handleChangeIsProductionStatus = (value) => {
      
        stateDate[0].startDate = stateDate[0].startDate;
        stateDate[0].endDate = new Date()
        setStateDate(stateDate)
        stateExDate[0].startDate = stateExDate[0].startDate;
        stateExDate[0].endDate = new Date()
        setStateExDate(stateExDate)
       ;
        if (value === null) {
            value = { label: "Sampling", selected: true, value: 1 }
            localStorage.setItem('value', (1));
        } else {
            localStorage.setItem('value', (value.value));
        }

        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: value.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadTnaDetailsreport(params));
    }
    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);
        }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadCommentsReportDetails(params));
            }
            setIsProductionStatussearch(inputText);
        }
        else if (filterName === 'BuyerEmp') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBuyerEmpSearch(inputText);
        }
        else if (filterName === 'CubeEmp') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setCubeEmpSearch(inputText);
        }

        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        }
    };

    //Check Box functionality start
    const CheckBoxHandler = (value) => {
        // PO DATE
        if (value === 1) {
            getHideID.Po
                ? setHideID({
                    Po: false,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: true,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Season
        if (value === 2) {
            getHideID.season
                ? setHideID({
                    Po: getHideID.Po,
                    season: false,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: true,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Quarter
        if (value === 3) {
            getHideID.Quarter
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: false,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: true,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Brand
        if (value === 4) {
            getHideID.brand
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: false,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: true,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Customer
        if (value === 5) {
            getHideID.customer
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: false,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: true,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Supplier
        if (value === 6) {
            getHideID.supplier
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: false,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })

                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: true,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // ID No
        if (value === 7) {
            getHideID.idNo
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: false,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: true,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Style Name
        if (value === 8) {
            getHideID.styleName
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: false,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: true,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Style No
        if (value === 9) {
            getHideID.styleNo
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: false,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: true,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        // Sku Name
        if (value === 10) {
            getHideID.skuName
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: false,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: true,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Sku Image
        if (value === 11) {
            getHideID.skuImg
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: false,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: true,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Size
        if (value === 12) {
            getHideID.size
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: false,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: true,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Order Qty
        if (value === 13) {
            getHideID.orderQty
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: false,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: true,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Ex Fac
        if (value === 14) {
            getHideID.exFac
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: false,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })

                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: true,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Order Status
        if (value === 15) {
            getHideID.orderStatus
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: false,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: true,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        // Current Status
        if (value === 16) {
            getHideID.currentStatus
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: false,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: true,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Cube Emp
        if (value === 17) {
            getHideID.cubeEmp
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: false,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: true,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Buyer Emp
        if (value === 18) {
            getHideID.buyerEmp
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: false,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: true,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Status Created
        if (value === 19) {
            getHideID.stsCreated
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: false,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: true,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        // Comment Created
        if (value === 20) {
            getHideID.cmtCreated
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: false,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: true,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // SKU Id
        if (value === 21) {
            getHideID.skuID
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: false,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: true,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Category
        if (value === 22) {
            getHideID.category
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: false,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: true,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Style Category Recived
        if (value === 23) {
            getHideID.styCatRecived
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: false,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: true,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Sms PO
        if (value === 24) {
            getHideID.smsPo
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: false,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: true,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // lab dip submit
        if (value === 25) {
            getHideID.LdSubmit
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: false,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: true,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // lab dip approve
        if (value === 26) {
            getHideID.LdApproved
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: false,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: true,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });

        }
        // strike of submission
        if (value === 27) {
            getHideID.strikeSubmission
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: false,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: true,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // strike of approval
        if (value === 28) {
            getHideID.strikeApprv
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: false,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: true,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });

        }
        // strike order nominate
        if (value === 29) {
            getHideID.strikeOrderNominate
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: false,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: true,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Strikeoff Receipt Supplier
        if (value === 31) {
            getHideID.strikeReceiptNominate
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: false,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: true,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Strikeoff Receipt buyer
        if (value === 32) {
            getHideID.strikeReceiptBuyer
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: false,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: true,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // TrimSubmission
        if (value === 33) {
            getHideID.TrimSubmission
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: false,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: true,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Trim Approval
        if (value === 34) {
            getHideID.TrimApproval
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: false,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: true,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Trim Order Nominate
        if (value === 35) {
            getHideID.TrimOrderNominate
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: false,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: true,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        // Trim Receipt Supplier
        if (value === 36) {
            getHideID.TrimReceiptNominate
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: false,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: true,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  Trim Order to Local Supplier
        if (value === 37) {
            getHideID.TrimOrderLocalSupplier
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: false,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: true,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  Trim Receipt Local Supplier
        if (value === 38) {
            getHideID.TrimReciptLocalSupplier
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: false,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: true,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  protoSubmission
        if (value === 39) {
            getHideID.protoSubmission
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: false,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: true,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  protoApproval
        if (value === 40) {
            getHideID.protoApproval
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: false,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: true,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  fitSubmission
        if (value === 41) {
            getHideID.fitSubmission
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: false,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: true,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  fitApproval
        if (value === 42) {
            getHideID.fitApproval
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: false,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: true,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  smsOrder
        if (value === 43) {
            getHideID.smsOrder
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: false,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: true,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  smsInHouse
        if (value === 44) {
            getHideID.smsInHouse
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: false,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: true,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        //  smsProduction
        if (value === 45) {
            getHideID.smsProduction
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: false,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: true,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        //  barcodeRecipt
        if (value === 46) {
            getHideID.barcodeRecipt
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: false,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: true,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  barcodeApprvl
        if (value === 47) {
            getHideID.barcodeApprvl
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: false,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: true,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  apperanceAftWash
        if (value === 48) {
            getHideID.apperanceAftWash
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: false,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: true,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,

                });
        }
        //  finalInspec
        if (value === 49) {
            getHideID.finalInspec
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: false,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: true,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  smsSubmis
        if (value === 50) {
            getHideID.smsSubmis
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: false,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: true,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
        //  smsApprvl
        if (value === 51) {
            getHideID.smsApprvl
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: false,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: getHideID.Trimrcptfrombuyer,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: true,
                });
        }
        //  trimreceptfrombuyer
        if (value === 52) {
            getHideID.Trimrcptfrombuyer
                ? setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: false,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                })
                : setHideID({
                    Po: getHideID.Po,
                    season: getHideID.season,
                    Quarter: getHideID.Quarter,
                    brand: getHideID.brand,
                    customer: getHideID.customer,
                    supplier: getHideID.supplier,
                    idNo: getHideID.idNo,
                    styleName: getHideID.styleName,
                    styleNo: getHideID.styleNo,
                    skuName: getHideID.skuName,
                    skuImg: getHideID.skuImg,
                    size: getHideID.size,
                    orderQty: getHideID.orderQty,
                    exFac: getHideID.exFac,
                    orderStatus: getHideID.orderStatus,
                    currentStatus: getHideID.currentStatus,
                    cubeEmp: getHideID.cubeEmp,
                    buyerEmp: getHideID.buyerEmp,
                    stsCreated: getHideID.stsCreated,
                    cmtCreated: getHideID.cmtCreated,
                    skuID: getHideID.skuID,
                    category: getHideID.category,
                    styCatRecived: getHideID.styCatRecived,
                    smsPo: getHideID.smsPo,
                    LdSubmit: getHideID.LdSubmit,
                    LdApproved: getHideID.LdApproved,
                    strikeSubmission: getHideID.strikeSubmission,
                    strikeApprv: getHideID.strikeApprv,
                    strikeOrderNominate: getHideID.strikeOrderNominate,
                    strikeReceiptNominate: getHideID.strikeReceiptNominate,
                    strikeReceiptBuyer: getHideID.strikeReceiptBuyer,
                    TrimSubmission: getHideID.TrimSubmission,
                    TrimApproval: getHideID.TrimApproval,
                    TrimOrderNominate: getHideID.TrimOrderNominate,
                    Trimrcptfrombuyer: true,
                    TrimReceiptNominate: getHideID.TrimReceiptNominate,
                    TrimOrderLocalSupplier: getHideID.TrimOrderLocalSupplier,
                    TrimReciptLocalSupplier: getHideID.TrimReciptLocalSupplier,
                    protoSubmission: getHideID.protoSubmission,
                    protoApproval: getHideID.protoApproval,
                    fitSubmission: getHideID.fitSubmission,
                    fitApproval: getHideID.fitApproval,
                    smsOrder: getHideID.smsOrder,
                    smsInHouse: getHideID.smsInHouse,
                    smsProduction: getHideID.smsProduction,
                    barcodeRecipt: getHideID.barcodeRecipt,
                    barcodeApprvl: getHideID.barcodeApprvl,
                    apperanceAftWash: getHideID.apperanceAftWash,
                    finalInspec: getHideID.finalInspec,
                    smsSubmis: getHideID.smsSubmis,
                    smsApprvl: getHideID.smsApprvl,
                });
        }
    };
    //Check Box functionality End

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }

    // const GetSizeandQty = (PurchaseOrderSkuID,) => {
    //     ;
    //     ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
    //         ;
    //         if (response.data) {
    //             setsizeqtylist(response.data)
    //         }

    //     }).catch(() => { });
    //     setsizeqtypopup(true);

    // }
    const GetSizeandQty = (PurchaseOrderSkuID, orderQty) => {
        ;
        setskuid('');
        ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
            ;
            if (response.data) {
                setsizeqtylist(response.data)
            }

        }).catch(() => { });
        setsizeqtypopup(true);
        setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }


    return (
        <div className="page-body">
            <ReactNotification />
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-header">
                            <span className="widget-caption">TNA Details Report List (91 Days)
                                &nbsp;
                                {/* <button className="btn btn-success float-right" title="Export to Excel"
                                    columns={columns} data={ExcelValues}
                                    onClick={() => DownloadExcel()}
                                >
                                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                                </button> */}

                            </span>
                            {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button"
                                    className="fa fa-file-excel-o fa-fa-file-excelcomt"
                                    table={"table-to-xls"}
                                    filename={"TNADetailsReportList"}
                                    sheet="TNADetailsReport"
                                    buttonText="  Export to Excel"

                                />
                            )}
                        </div>
                        <div className="widget-body">
                            <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="widget">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        onChange={e => handleChange(e, 'Buyer')}
                                                                                        value={getBuyersearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                            <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        onChange={e => handleChange(e, 'Season')}
                                                                                        value={getSeasonsearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        onChange={e => handleChange(e, 'Brand')}
                                                                                        value={getBrandsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        onChange={e => handleChange(e, 'Quarter')}
                                                                                        value={getQuartersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        onChange={e => handleChange(e, 'Supplier')}
                                                                                        value={getSuppliersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>ID No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'ID No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'ID No'}
                                                                                        onChange={e => handleChange(e, 'ID/NO')}
                                                                                        value={getIdPosearch}
                                                                                    />

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    &nbsp;
                                                                    <div className="row">
                                                                        <div className="col-md-12">


                                                                        </div>
                                                                    </div>


                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                            <label>End Customer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'End Customer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'End Customer'}
                                                                                        onChange={e => handleChange(e, 'EndCustomer')}
                                                                                        value={getEndCustomersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Category</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Category'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Category'}
                                                                                        onChange={e => handleChange(e, 'Category')}
                                                                                        value={getCategorysearch}
                                                                                    />

                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Style Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        onChange={e => handleChange(e, 'StyleName')}
                                                                                        value={getStyleNamesearch}
                                                                                    />
                                                                                </span>

                                                                            </div>

                                                                            <div className="col-md-2">
                                                                            <label>Style No</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Style No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style No'}
                                                                                        onChange={e => handleChange(e, 'StyleNo')}
                                                                                        value={getStyleNosearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Buyer Follower</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer Follower'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer Follower'}
                                                                                        onChange={e => handleChange(e, 'BuyerEmp')}

                                                                                        value={getBuyerEmpsearch}
                                                                                    />
                                                                                </span>
                                                                                {/* <span className='input-icon icon-right'>
                                    <Reactselect className="basic-single" name="GenderID"
                                    maxlength={"50"}
                                    placeholder={"Buyer Emp"}
                                      onChange={e => handleChange(e, 'BuyerEmp')}
                                      value={getBuyerEmpsearch}
                                    ></Reactselect>
                                  </span> */}
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                            <label>Cube Follower</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Cube Follower'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Cube Follower'}
                                                                                        onChange={e => handleChange(e, 'CubeEmp')}

                                                                                        value={getCubeEmpsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">



                                                                            <div className="col-md-2">
                                                                                <label>PO Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateDate([item.selection])}
                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Ex-Fac Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleExDateSelect(event, !stateExDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearExDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateExDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateExDate([item.selection])}
                                                                                            onChange={item => DateExfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateExDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Status</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={orderStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                        value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Sampling / Production</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="IsProductionStatusID"
                                                                                        options={IsProductionStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeIsProductionStatus(e, 'IsProductionStatus')}
                                                                                        value={getIsProductionStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-1">
                                                                                <div className="col-md-1 margin-top-30" style={{ marginLeft: "1px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-11">
                                                                        </div>

                                                                    </div>
                                                                    &nbsp;
                                                                </div>
                                                            </div>
                                                        </div>

                                                        &nbsp;


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header>
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(1)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.Po}
                                                        disabled
                                                    />
                                                    &nbsp;<label htmlFor="podate">PO Date</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(21)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.skuID}
                                                    />
                                                    &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(2)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="season"
                                                        checked={getHideID.season}
                                                    />
                                                    &nbsp;<label htmlFor="season">Season</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(3)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.Quarter}
                                                    />
                                                    &nbsp;<label htmlFor="quarter">Quarter</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(4)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.brand}
                                                    />
                                                    &nbsp;<label htmlFor="brand">Brand</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(5)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.customer}
                                                    />
                                                    &nbsp;<label htmlFor="customer">Customer</label>
                                                </li>
                                            </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(6)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.supplier}
                                                        />
                                                        &nbsp;<label htmlFor="supplier">Supplier </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(7)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.idNo}
                                                        />
                                                        &nbsp;<label htmlFor="idNo">ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(8)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.styleName}
                                                        />
                                                        &nbsp;<label htmlFor="styleName">Style Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(9)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.styleNo}
                                                        />
                                                        &nbsp;<label htmlFor="styleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(10)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.skuName}
                                                        />
                                                        &nbsp;<label htmlFor="skuName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(11)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.skuImg}
                                                        />
                                                        &nbsp;<label htmlFor="skuImg">SKU Image</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(12)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.size}
                                                        />
                                                        &nbsp;<label htmlFor="size">Size</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(13)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.orderQty}
                                                        />
                                                        &nbsp;<label htmlFor="orderQty">Order Qty</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(14)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.exFac}
                                                        />
                                                        &nbsp;<label htmlFor="exFac">Ex-Fac Date</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(15)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.orderStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="orderStatus">Order Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(16)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.currentStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="currentStatus">Comments</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(17)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.cubeEmp}
                                                        />
                                                        &nbsp;<label htmlFor="cubeEmp">Cube Follower</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(18)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.buyerEmp}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="buyerEmp">Buyer Follower</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(19)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.stsCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="Createdby">Status Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(20)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.cmtCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="Createdby">Comment Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(22)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.category}
                                                        />
                                                        &nbsp;<label htmlFor="category">Category</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(23)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.styCatRecived}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Style Catalog Received
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(24)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsPo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            SMS Purchase Order
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(25)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.LdSubmit}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Lab Dip Submission
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(26)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.LdApproved}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Lab Dip Approval
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(27)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.strikeSubmission}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Strike Off Submission
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(28)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.strikeApprv}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Strike Off Approval
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(29)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.strikeOrderNominate}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Strike Off Order Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                {/* <div className="col-lg-4">
                        <li
                          className="listmodal"
                          onClick={() => CheckBoxHandler(30)}>
                          <input
                            className="hidecheckbox"
                            type="checkbox"
                            name="quarter"
                            checked={getHideID.strikeOrderNominate}
                          />
                          &nbsp;
                          <label htmlFor="styCatRecived">
                            Strikeoff Order Supplier
                          </label>
                        </li>
                      </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(31)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.strikeReceiptNominate}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Strike Off Receipt Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(32)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.strikeReceiptBuyer}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Strike Off Receipt Buyer
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(33)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimSubmission}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">Trim Submission</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(34)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimApproval}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">Trim Approval</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(35)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimOrderNominate}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Trim Order Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(52)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.Trimrcptfrombuyer}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Trim Receipt From Buyer
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(36)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimReceiptNominate}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Trim Receipt Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(37)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimOrderLocalSupplier}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived" className="lowCaseText">
                                                            Trim Order to Local Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(38)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.TrimReciptLocalSupplier}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Trim Receipt Local Supplier
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(39)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.protoSubmission}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Proto Sample Submission
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(40)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.protoApproval}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Proto Sample Approval
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(41)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.fitSubmission}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Fit Sample Submission
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(42)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.fitApproval}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Fit Sample Approval
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(43)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsOrder}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            SMS Fabric Order
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(44)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsInHouse}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived" className="lowCaseText">
                                                            SMS Fabric In-house
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(45)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsProduction}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            SMS Production Follow
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(46)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.barcodeRecipt}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Barcode File Receipt Buyer
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(47)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.barcodeApprvl}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Barcode Approval
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(48)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.apperanceAftWash}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Apperance After Wash
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(49)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.finalInspec}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">
                                                            Final Inspection
                                                        </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(50)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsSubmis}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">SMS Submission</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onClick={() => CheckBoxHandler(51)}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="quarter"
                                                            checked={getHideID.smsApprvl}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="styCatRecived">SMS Approval</label>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal>



                    <div className="row">
                        <div className="col-lg-11">

                        </div>

                        <div className="col-lg-1 button-group" style={{ margin: "1rem -7rem 1rem 1rem", float: "right" }} >
                            <button type="button" title="Hide Header" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>

                        </div>

                        <div className="col-md-12">
                            <div style={{ overflow: "auto" }}>
                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer zui-table" id="editabledatatable" style={{ width: "560%" }}>
                                    {/* <thead>
                    <tr>
                      {getHideID.Po ? <th style={{ width: '5%' }}> PO Date </th> : false}
                      {getHideID.skuID ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                      {getHideID.season ? <th style={{ width: '3%' }}> Season</th> : false}
                      {getHideID.Quarter ? <th style={{ width: '1%' }}> Quarter</th> : false}
                      {getHideID.brand ? <th style={{ width: '5%' }}> Brand</th> : false}
                      {getHideID.customer ? <th style={{ width: '5%' }}> Customer</th> : false}
                      {getHideID.supplier ? <th style={{ width: '5%' }}> Supplier </th> : false}
                      {getHideID.idNo ? <th style={{ width: '4%' }}> ID No</th> : false}
                      {getHideID.styleName ? <th style={{ width: '7%' }}> Style Name</th> : false}
                      {getHideID.styleNo ? <th style={{ width: '7%' }}> Style No</th> : false}
                      {getHideID.skuName ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                      {getHideID.skuImg ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                      {getHideID.size ? <th style={{ width: '5%' }}> Size</th> : false}
                      {getHideID.orderQty ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                      {getHideID.exFac ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                      {getHideID.cubeEmp ? <th style={{ width: '5%' }}> Cube Follower</th> : false}
                      {getHideID.buyerEmp ? <th style={{ width: '5%' }}> Buyer Follower</th> : false}
                      {getHideID.orderStatus ? <th style={{ width: '5%' }}> Order Status</th> : false}
                      {getHideID.stsCreated ? <th style={{ width: '2%' }}>  Status Createdby</th> : false}
                      {getHideID.currentStatus ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                      {getHideID.cmtCreated ? <th style={{ width: '2%' }}>  Comment Createdby</th> : false}
                      <th style={{ width: '4%' }} className="text-center fixed-column-header-Route">Action</th>
                    </tr>
                  </thead> */}
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment,i) => (

                                                <tr key={comment.purchaseOrderSkuID}>
                                                   <td className="stickyone newcss" title={i+1}>{i+1}</td>
                                                    {getHideID.Po && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickytwo newcss"
                                                            title={comment.poDate}>
                                                            {comment.poDate}

                                                        </td>
                                                    )}
                                                    {getHideID.skuID && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickythree newcss"
                                                            title={comment.purchaseOrderSkuID}>
                                                            {comment.purchaseOrderSkuID}
                                                        </td>
                                                    )}
                                                    {getHideID.season && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickyfour newcss"
                                                            title={comment.seasonName}>
                                                            {comment.seasonName}
                                                        </td>
                                                    )}
                                                    {getHideID.Quarter && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickyfive newcss"
                                                            title={comment.quarterName}>
                                                            {comment.quarterName}
                                                        </td>
                                                    )}
                                                    {getHideID.brand && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickysix newcss"
                                                            title={comment.brandName}>
                                                            {comment.brandName}
                                                        </td>
                                                    )}
                                                    {getHideID.customer && (
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            className="stickyseven newcss"
                                                            title={comment.customerName}>
                                                            {comment.customerName}
                                                        </td>
                                                    )}
                                                    {getHideID.skuName && < td
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        className="stickyeight newcss" title={comment.skuName}> {
                                                            comment.skuName
                                                        }</td>}
                                                    {getHideID.skuImg && <td className="text-center stickynine newcss"
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                    >{
                                                            < SimpleReactLightbox >
                                                                <SRLWrapper options={options}>
                                                                    {
                                                                        < img className="width_manual"
                                                                            src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath}
                                                                            alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)} />

                                                                    }

                                                                </SRLWrapper>
                                                            </SimpleReactLightbox>
                                                            // comment.skuImage
                                                            // comment.skuImagePath
                                                        }</td>}
                                                    {getHideID.supplier && <td className="cut_textCommentsreport  newcss" title={comment.supplierName}>{comment.supplierName}</td>}
                                                    {getHideID.idNo && <td className="cut_textCommentsreport newcss" title={comment.idpono}>
                                                        {comment.idpono}
                                                    </td>}
                                                    {getHideID.category && <td className="cut_textCommentsreport newcss" title={comment.categoryName}>{
                                                        comment.categoryName
                                                    }</td>}
                                                    {/* {
                                                        <td>Test</td>
                                                    } */}
                                                    {getHideID.styleName && <td className="cut_textCommentsreport newcss" title={comment.styleName}>{comment.styleName}</td>}
                                                    {getHideID.styleNo && <td className="cut_textCommentsreport newcss" title={comment.styleNo}>{comment.styleNo}</td>}

                                                    {getHideID.size && <td className="cut_textCommentsreport newcss" title={comment.size}>{
                                                        comment.size
                                                    }</td>}
                                                    {getHideID.orderQty &&
                                                        <td className="cut_textCommentsreport newcss" title={comment.quantity}>
                                                            <span>{comment.quantity}</span>
                                                            <span>
                                                                <span className="btn btn-success" title="View Sizes" style={{ float: "right", display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} onClick={() => GetSizeandQty(comment.purchaseOrderSkuID, comment.quantity)}
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </span>
                                                            </span>


                                                        </td>}
                                                    {getHideID.exFac && <td className="cut_textCommentsreport newcss" title={comment.exFacDate}>{
                                                        comment.exFacDate
                                                    }</td>}
                                                    {getHideID.cubeEmp && <td className="cut_textCommentsreport newcss" title={comment.cubeEmployee}>{
                                                        comment.cubeEmployee
                                                    }</td>}
                                                    {getHideID.buyerEmp && <td className="cut_textCommentsreport newcss" title={comment.buyerEmployee}>{
                                                        comment.buyerEmployee
                                                    }</td>}

                                                    {getHideID.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                        comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                            'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                    'Yet to Start'}>
                                                        {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                            comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                'Yet to Start'
                                                        }
                                                    </td>}
                                                    {/* {getHideID.orderStatus && <td>
                            {comment.orderStatus === '' ? "-" : comment.orderStatus}
                          </td>} */}
                                                    {getHideID.stsCreated && <td className="cut_textCommentsreport newcss" title={comment.statusCreatedBy}>{
                                                        comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                    }</td>}
                                                    {getHideID.currentStatus && <td className="cut_textCommentsreport newcss" title={comment.currentComments}>{
                                                        comment.currentComments === "" ? "-" : comment.currentComments
                                                    }</td>}
                                                    {getHideID.cmtCreated && <td className="cut_textCommentsreport newcss" title={comment.commentsCreatedBy}>{
                                                        comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                    }</td>}
                                                    {getHideID.styCatRecived &&
                                                        <td className="newcss" title={comment.styleCatalogReceived}>{comment.styleCatalogReceived === "" ? "NA" : comment.styleCatalogReceived}</td>
                                                    }  {getHideID.smsPo &&
                                                        <td className="newcss" title={comment.smsPurchaseOrder}>{comment.smsPurchaseOrder === "" ? "NA" : comment.smsPurchaseOrder}</td>
                                                    }  {getHideID.LdSubmit &&
                                                        <td className="newcss" title={comment.labDipsSubmitted}>{comment.labDipsSubmitted === "" ? "NA" : comment.labDipsSubmitted}</td>
                                                    }  {getHideID.LdApproved &&
                                                        <td className="newcss" title={comment.labDipsApproved}>{comment.labDipsApproved === "" ? "NA" : comment.labDipsApproved}</td>
                                                    }  {getHideID.strikeSubmission &&
                                                        <td className="newcss" title={comment.strikeoffSubmission}>{comment.strikeoffSubmission === "" ? "NA" : comment.strikeoffSubmission}</td>
                                                    }  {getHideID.strikeApprv &&
                                                        <td className="newcss" title={comment.strikeoffApproval}>{comment.strikeoffApproval === "" ? "NA" : comment.strikeoffApproval}</td>
                                                    }  {getHideID.strikeOrderNominate &&
                                                        <td className="newcss" title={comment.strikeoffOrdertoNominattedSupplier}>{comment.strikeoffOrdertoNominattedSupplier === "" ? "NA" : comment.strikeoffOrdertoNominattedSupplier}</td>
                                                    }
                                                    {getHideID.strikeReceiptNominate &&
                                                        <td className="newcss" title={comment.strikeoffReceiptfromNominattedSupplier}>{comment.strikeoffReceiptfromNominattedSupplier === "" ? "NA" : comment.strikeoffReceiptfromNominattedSupplier}</td>
                                                    }
                                                    {getHideID.strikeReceiptBuyer &&
                                                        <td className="newcss" title={comment.strikeoffReceiptFromBuyer}>{comment.strikeoffReceiptFromBuyer === "" ? "NA" : comment.strikeoffReceiptFromBuyer}</td>
                                                    }  {getHideID.TrimSubmission &&
                                                        <td className="newcss" title={comment.trimSubmission}>{comment.trimSubmission === "" ? "NA" : comment.trimSubmission}</td>
                                                    }  {getHideID.TrimApproval &&
                                                        <td className="newcss" title={comment.trimApproval}>{comment.trimApproval === "" ? "NA" : comment.trimApproval}</td>
                                                    }  {getHideID.TrimOrderNominate &&
                                                        <td className="newcss" title={comment.trimOrdertoNominattedSupplier}>{comment.trimOrdertoNominattedSupplier === "" ? "NA" : comment.trimOrdertoNominattedSupplier}</td>
                                                    }  {getHideID.TrimReceiptNominate &&
                                                        <td className="newcss" title={comment.trimReceiptfromNominattedSupplier}>{comment.trimReceiptfromNominattedSupplier === "" ? "NA" : comment.trimReceiptfromNominattedSupplier}</td>
                                                    }
                                                    {getHideID.Trimrcptfrombuyer &&
                                                        <td className="newcss" title={comment.trimReceiptFromBuyer}>{comment.trimReceiptFromBuyer === "" ? "NA" : comment.trimReceiptFromBuyer}</td>
                                                    }
                                                    {getHideID.TrimOrderLocalSupplier &&
                                                        <td className="newcss" title={comment.trimOrdertoLocalSupplier}>{comment.trimOrdertoLocalSupplier === "" ? "NA" : comment.trimOrdertoLocalSupplier}</td>
                                                    }  {getHideID.TrimReciptLocalSupplier &&
                                                        <td className="newcss" title={comment.trimReceiptfromLocalSupplier}>{comment.trimReceiptfromLocalSupplier === "" ? "NA" : comment.trimReceiptfromLocalSupplier}</td>
                                                    }  {getHideID.protoSubmission &&
                                                        <td className="newcss" title={comment.protoSampleSubmission}>{comment.protoSampleSubmission === "" ? "NA" : comment.protoSampleSubmission}</td>
                                                    }  {getHideID.protoApproval &&
                                                        <td className="newcss" title={comment.protoSampleApproval}>{comment.protoSampleApproval === "" ? "NA" : comment.protoSampleApproval}</td>
                                                    }  {getHideID.fitSubmission &&
                                                        <td className="newcss" title={comment.fitSampleSubmission}>{comment.fitSampleSubmission === "" ? "NA" : comment.fitSampleSubmission}</td>
                                                    }  {getHideID.fitApproval &&
                                                        <td className="newcss" title={comment.fitSampleApproval}>{comment.fitSampleApproval === "" ? "NA" : comment.fitSampleApproval}</td>
                                                    }  {getHideID.smsOrder &&
                                                        <td className="newcss" title={comment.smsFabricOrder}>{comment.smsFabricOrder === "" ? "NA" : comment.smsFabricOrder}</td>
                                                    }  {getHideID.smsInHouse &&
                                                        <td className="newcss" title={comment.smsFabricInHouse}>{comment.smsFabricInHouse === "" ? "NA" : comment.smsFabricInHouse}</td>
                                                    }  {getHideID.smsProduction &&
                                                        <td className="newcss" title={comment.smsProductionFollow}>{comment.smsProductionFollow === "" ? "NA" : comment.smsProductionFollow}</td>
                                                    }  {getHideID.barcodeRecipt &&
                                                        <td className="newcss" title={comment.barcodeFileReceiptFromBuyer}>{comment.barcodeFileReceiptFromBuyer === "" ? "NA" : comment.barcodeFileReceiptFromBuyer}</td>
                                                    }  {getHideID.barcodeApprvl &&
                                                        <td className="newcss" title={comment.barcodeApproval}>{comment.barcodeApproval === "" ? "NA" : comment.barcodeApproval}</td>
                                                    }  {getHideID.apperanceAftWash &&
                                                        <td className="newcss" title={comment.apperanceAfterWash}>{comment.apperanceAfterWash === "" ? "NA" : comment.apperanceAfterWash}</td>
                                                    }  {getHideID.finalInspec &&
                                                        <td className="newcss" title={comment.finalInspection}>{comment.finalInspection === "" ? "NA" : comment.finalInspection}</td>
                                                    }  {getHideID.smsSubmis &&
                                                        <td className="newcss" title={comment.smsSubmission}>{comment.smsSubmission === "" ? "NA" : comment.smsSubmission}</td>
                                                    }  {getHideID.smsApprvl &&
                                                        <td className="newcss" title={comment.smsApproval}>{comment.smsApproval === "" ? "NA" : comment.smsApproval}</td>
                                                    }
                                                </tr>

                                            ))

                                        }

                                        {
                                            sizeqtypopup === true ?

                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                    {/* <Modal.Header closeButton>
                                                        <Modal.Title>Size and Qty </Modal.Title>

                                                    </Modal.Header> */}

                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Size and Qty
                                                        </Modal.Title>


                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                        <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>

                                                    </div>

                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>Size</th>
                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    sizeqtylist.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.sizes}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.qtys}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>

                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="52" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody>

                                    <table data-role="table" data-mode="columntoggle" id="table-to-xls"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>


                                                            :

                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {getHideID.Po === true ? <th style={{ width: '5%' }}> PO Date </th> : false}
                                                {getHideID.skuID === true ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                                                {getHideID.season === true ? <th style={{ width: '3%' }}> Season</th> : false}
                                                {getHideID.Quarter === true ? <th style={{ width: '1%' }}> Quarter</th> : false}
                                                {getHideID.brand === true ? <th style={{ width: '5%' }}> Brand</th> : false}
                                                {getHideID.customer === true ? <th style={{ width: '5%' }}> Customer</th> : false}
                                                {getHideID.supplier === true ? <th style={{ width: '5%' }}> Supplier </th> : false}
                                                {getHideID.idNo === true ? <th style={{ width: '4%' }}> ID No</th> : false}
                                                {getHideID.category === true ? <th style={{ width: '7%' }}>Category</th> : false}
                                                {getHideID.styleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                                                {getHideID.styleNo === true ? <th style={{ width: '7%' }}> Style No</th> : false}
                                                {getHideID.skuName === true ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                                                {getHideID.skuImg === true ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                                                {getHideID.size === true ? <th style={{ width: '5%' }}> Size</th> : false}
                                                {getHideID.orderQty === true ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                                                {getHideID.exFac === true ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                                                {getHideID.cubeEmp === true ? <th style={{ width: '5%' }}> Cube Follower</th> : false}
                                                {getHideID.buyerEmp === true ? <th style={{ width: '5%' }}> Buyer Follower</th> : false}
                                                {getHideID.orderStatus === true ? <th style={{ width: '5%' }}> Order Status</th> : false}
                                                {getHideID.stsCreated === true ? <th style={{ width: '2%' }}>  Status Created By</th> : false}
                                                {getHideID.currentStatus === true ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                                                {getHideID.cmtCreated === true ? <th style={{ width: '2%' }}>  Comment Created By</th> : false}
                                                {getHideID.styCatRecived === true ? <th style={{ width: '5%' }}> Style Catalog Received  </th> : false}
                                                {getHideID.smsPo === true ? <th style={{ width: '2%' }}> SMS Purchase Order</th> : false}
                                                {getHideID.LdSubmit === true ? <th style={{ width: '3%' }}> Lab Dip Submission</th> : false}
                                                {getHideID.LdApproved === true ? <th style={{ width: '1%' }}> Lab Dip Approval</th> : false}
                                                {getHideID.strikeSubmission === true ? <th style={{ width: '5%' }}> Strike Off Submission</th> : false}
                                                {getHideID.strikeApprv === true ? <th style={{ width: '5%' }}>Strike Off Approval </th> : false}
                                                {getHideID.strikeOrderNominate === true ? <th style={{ width: '5%' }}> Strike Off Order to Nominatted Supplier </th> : false}
                                                {getHideID.strikeReceiptNominate === true ? <th style={{ width: '4%' }}> Strike Off Receipt From Nominatted Supplier </th> : false}
                                                {getHideID.strikeReceiptBuyer === true ? <th style={{ width: '7%' }}> Strike Off Receipt From Buyer </th> : false}
                                                {getHideID.TrimSubmission === true ? <th style={{ width: '7%' }}> Trim Submission </th> : false}
                                                {getHideID.TrimApproval === true ? <th style={{ width: '5%' }}> Trim Approval </th> : false}
                                                {getHideID.Trimrcptfrombuyer === true ? <th style={{ width: '5%' }}> Trim Receipt From Buyer </th> : false}
                                                {getHideID.TrimOrderNominate === true ? <th style={{ width: '4% ' }}> Trim Order to Nominatted Supplier</th> : false}
                                                {getHideID.TrimReceiptNominate === true ? <th style={{ width: '5%' }}> Trim Receipt From Nominatted Supplier</th> : false}
                                                {getHideID.TrimOrderLocalSupplier === true ? <th style={{ width: '5%' }}>Trim Order to Local Supplier  </th> : false}
                                                {getHideID.TrimReciptLocalSupplier === true ? <th style={{ width: '4%' }}>Trim Receipt From Local Supplier </th> : false}
                                                {getHideID.protoSubmission === true ? <th style={{ width: '5%' }}> Proto Sample Submission </th> : false}
                                                {getHideID.protoApproval === true ? <th style={{ width: '5%' }}> Proto Sample Approval </th> : false}
                                                {getHideID.fitSubmission === true ? <th style={{ width: '5%' }}>Fit Sample Submission  </th> : false}
                                                {getHideID.fitApproval === true ? <th style={{ width: '2%' }}>Fit Sample Approval  </th> : false}
                                                {getHideID.smsOrder === true ? <th style={{ width: '5%' }}> SMS Fabric Order   </th> : false}
                                                {getHideID.smsInHouse === true ? <th style={{ width: '2%' }}> SMS Fabric In-house </th> : false}
                                                {getHideID.smsProduction === true ? <th style={{ width: '4%' }}>SMS Production Follow </th> : false}
                                                {getHideID.barcodeRecipt === true ? <th style={{ width: '5%' }}>Barcode File Receipt From Buyer  </th> : false}
                                                {getHideID.barcodeApprvl === true ? <th style={{ width: '5%' }}>Barcode Approval  </th> : false}
                                                {getHideID.apperanceAftWash === true ? <th style={{ width: '5%' }}> Apperance After Wash </th> : false}
                                                {getHideID.finalInspec === true ? <th style={{ width: '2%' }}> Final Inspection </th> : false}
                                                {getHideID.smsSubmis === true ? <th style={{ width: '5%' }}>  SMS Submission  </th> : false}
                                                {getHideID.smsApprvl === true ? <th style={{ width: '2%' }}>SMS Approval  </th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.purchaseOrderSkuID}>
                                                        {getHideID.Po && <td className="" title={comment.poDate}>{
                                                            comment.poDate
                                                        }</td>}
                                                        {getHideID.skuID && <td className="cut_textCommentsreport" title={comment.purchaseOrderSkuID}>{
                                                            comment.purchaseOrderSkuID
                                                        }</td>}
                                                        {getHideID.season && <td className="cut_textCommentsreport" title={comment.seasonName}>{comment.seasonName}</td>}
                                                        {getHideID.Quarter && <td className="cut_textCommentsreport" title={comment.quarterName}>{
                                                            comment.quarterName
                                                        }</td>}
                                                        {getHideID.brand && <td className="cut_textCommentsreport" title={comment.brandName}>{
                                                            comment.brandName
                                                        }</td>}
                                                        {getHideID.customer && <td className="cut_textCommentsreport" title={comment.customerName}>{comment.customerName}</td>}
                                                        {getHideID.supplier && <td className="cut_textCommentsreport" title={comment.supplierName}>{comment.supplierName}</td>}
                                                        {getHideID.idNo && <td className="cut_textCommentsreport" title={comment.iDPONO}>
                                                            {comment.idpono}
                                                        </td>}
                                                        {getHideID.category && <td className="cut_textCommentsreport" title={comment.categoryName}>{
                                                            comment.categoryName
                                                        }</td>}
                                                        {getHideID.styleName && <td className="cut_textCommentsreport" title={comment.styleName}>{comment.styleName}</td>}
                                                        {getHideID.styleNo && <td className="cut_textCommentsreport" title={comment.styleNo}>{comment.styleNo}</td>}

                                                        {getHideID.skuName && < td className="cut_textCommentsreport" title={comment.skuName}> {
                                                            comment.skuName
                                                        }</td>}
                                                        {getHideID.skuImg && <td> {
                                                            <div className="text-right">
                                                                <img src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath} width='3%' height={'3%'} />
                                                            </div>

                                                        }</td>}
                                                        {getHideID.size && <td className="cut_textCommentsreport" title={comment.size}>{
                                                            comment.size
                                                        }</td>}
                                                        {getHideID.orderQty &&
                                                            <td className="cut_textCommentsreport" title={comment.quantity}>{
                                                                comment.quantity
                                                            }</td>}
                                                        {getHideID.exFac && <td className="cut_textCommentsreport" title={comment.exFacDate}>{
                                                            comment.exFacDate
                                                        }</td>}
                                                        {getHideID.cubeEmp && <td className="cut_textCommentsreport" title={comment.cubeEmployee}>{
                                                            comment.cubeEmployee
                                                        }</td>}
                                                        {getHideID.buyerEmp && <td className="cut_textCommentsreport" title={comment.buyerEmployee}>{
                                                            comment.buyerEmployee
                                                        }</td>}

                                                        {getHideID.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                    comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                        'Yet to Start'}>
                                                            {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                    'Yet to Start'
                                                            }
                                                        </td>}

                                                        {getHideID.stsCreated && <td className="cut_textCommentsreport" title={comment.statusCreatedBy}>{
                                                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                        }</td>}
                                                        {getHideID.currentStatus && <td className="text-center cut_textCommentsreport" title={comment.currentComments}>{
                                                            comment.currentComments === "" ? "-" : comment.currentComments
                                                        }</td>}
                                                        {getHideID.cmtCreated && <td className="cut_textCommentsreport" title={comment.commentsCreatedBy}>{
                                                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                        }</td>}
                                                        {
                                                            getHideID.styCatRecived && <td title={comment.styleCatalogReceived}>{comment.styleCatalogReceived === "" ? "NA" : comment.styleCatalogReceived}</td>
                                                        }  {
                                                            getHideID.smsPo &&
                                                            <td title={comment.smsPurchaseOrder}>{comment.smsPurchaseOrder === "" ? "NA" : comment.smsPurchaseOrder}</td>
                                                        }  {
                                                            getHideID.LdSubmit &&
                                                            <td title={comment.labDipsSubmitted}>{comment.labDipsSubmitted === "" ? "NA" : comment.labDipsSubmitted}</td>
                                                        }  {getHideID.LdApproved &&
                                                            <td title={comment.labDipsApproved}>{comment.labDipsApproved === "" ? "NA" : comment.labDipsApproved}</td>
                                                        }  {getHideID.strikeSubmission &&
                                                            <td title={comment.strikeoffSubmission}>{comment.strikeoffSubmission === "" ? "NA" : comment.strikeoffSubmission}</td>
                                                        }  {getHideID.strikeApprv &&
                                                            <td title={comment.strikeoffApproval}>{comment.strikeoffApproval === "" ? "NA" : comment.strikeoffApproval}</td>
                                                        }  {getHideID.strikeOrderNominate &&
                                                            <td title={comment.strikeoffOrdertoNominattedSupplier}>{comment.strikeoffOrdertoNominattedSupplier === "" ? "NA" : comment.strikeoffOrdertoNominattedSupplier}</td>
                                                        }
                                                        {getHideID.strikeReceiptNominate &&
                                                            <td title={comment.strikeoffReceiptfromNominattedSupplier}>{comment.strikeoffReceiptfromNominattedSupplier === "" ? "NA" : comment.strikeoffReceiptfromNominattedSupplier}</td>
                                                        }
                                                        {getHideID.strikeReceiptBuyer &&
                                                            <td title={comment.strikeoffReceiptFromBuyer}>{comment.strikeoffReceiptFromBuyer === "" ? "NA" : comment.strikeoffReceiptFromBuyer}</td>
                                                        }  {getHideID.TrimSubmission &&
                                                            <td title={comment.trimSubmission}>{comment.trimSubmission === "" ? "NA" : comment.trimSubmission}</td>
                                                        }  {getHideID.TrimApproval &&
                                                            <td title={comment.trimApproval}>{comment.trimApproval === "" ? "NA" : comment.trimApproval}</td>

                                                        }
                                                        {getHideID.Trimrcptfrombuyer &&
                                                            <td className="newcss" title={comment.trimReceiptFromBuyer}>{comment.trimReceiptFromBuyer === "" ? "NA" : comment.trimReceiptFromBuyer}</td>
                                                        }
                                                        {getHideID.TrimOrderNominate &&
                                                            <td title={comment.trimOrdertoNominattedSupplier}>{comment.trimOrdertoNominattedSupplier === "" ? "NA" : comment.trimOrdertoNominattedSupplier}</td>
                                                        }  {getHideID.TrimReceiptNominate &&
                                                            <td title={comment.trimReceiptfromNominattedSupplier}>{comment.trimReceiptfromNominattedSupplier === "" ? "NA" : comment.trimReceiptfromNominattedSupplier}</td>
                                                        }  {getHideID.TrimOrderLocalSupplier &&
                                                            <td title={comment.trimOrdertoLocalSupplier}>{comment.trimOrdertoLocalSupplier === "" ? "NA" : comment.trimOrdertoLocalSupplier}</td>
                                                        }  {getHideID.TrimReciptLocalSupplier &&
                                                            <td title={comment.trimReceiptfromLocalSupplier}>{comment.trimReceiptfromLocalSupplier === "" ? "NA" : comment.trimReceiptfromLocalSupplier}</td>
                                                        }  {getHideID.protoSubmission &&
                                                            <td title={comment.protoSampleSubmission}>{comment.protoSampleSubmission === "" ? "NA" : comment.protoSampleSubmission}</td>
                                                        }  {getHideID.protoApproval &&
                                                            <td title={comment.protoSampleApproval}>{comment.protoSampleApproval === "" ? "NA" : comment.protoSampleApproval}</td>
                                                        }  {getHideID.fitSubmission &&
                                                            <td title={comment.fitSampleSubmission}>{comment.fitSampleSubmission === "" ? "NA" : comment.fitSampleSubmission}</td>
                                                        }  {getHideID.fitApproval &&
                                                            <td title={comment.fitSampleApproval}>{comment.fitSampleApproval === "" ? "NA" : comment.fitSampleApproval}</td>
                                                        }  {getHideID.smsOrder &&
                                                            <td title={comment.smsFabricOrder}>{comment.smsFabricOrder === "" ? "NA" : comment.smsFabricOrder}</td>
                                                        }  {getHideID.smsInHouse &&
                                                            <td title={comment.smsFabricInHouse}>{comment.smsFabricInHouse === "" ? "NA" : comment.smsFabricInHouse}</td>
                                                        }  {getHideID.smsProduction &&
                                                            <td title={comment.smsProductionFollow}>{comment.smsProductionFollow === "" ? "NA" : comment.smsProductionFollow}</td>
                                                        }  {getHideID.barcodeRecipt &&
                                                            <td title={comment.barcodeFileReceiptFromBuyer}>{comment.barcodeFileReceiptFromBuyer === "" ? "NA" : comment.barcodeFileReceiptFromBuyer}</td>
                                                        }  {getHideID.barcodeApprvl &&
                                                            <td title={comment.barcodeApproval}>{comment.barcodeApproval === "" ? "NA" : comment.barcodeApproval}</td>
                                                        }  {getHideID.apperanceAftWash &&
                                                            <td title={comment.apperanceAfterWash}>{comment.apperanceAfterWash === "" ? "NA" : comment.apperanceAfterWash}</td>
                                                        }  {getHideID.finalInspec &&
                                                            <td title={comment.finalInspection}>{comment.finalInspection === "" ? "NA" : comment.finalInspection}</td>
                                                        }  {getHideID.smsSubmis &&
                                                            <td title={comment.smsSubmission}>{comment.smsSubmission === "" ? "NA" : comment.smsSubmission}</td>
                                                        }  {getHideID.smsApprvl &&
                                                            <td title={comment.smsApproval}>{comment.smsApproval === "" ? "NA" : comment.smsApproval}</td>
                                                        }
                                                    </tr>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table>
                            </div>

                        </div>


                    </div>

                </div>

                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
            </div>
            {loader}
        </div >
    )
}

export default TNADetailsReport