import React, { useState, useEffect, useMemo, useRef, Fragment} from "react";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SearchRetain from "../../Common/SearchRetain";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import trimService from "../../../services/Style/TrimService";
import Nodify from "../../Common/ReactNotification";
import { MasterTrimID } from "../../Common/PageActionNumber";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import Lightbox from "react-image-lightbox";
import CommonService from '../../../services/Common/CommonService';
import { retrieveTrim } from "../../../actions/trimMaster";

const MasterTrimList = (props) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer/ Brand / Trim Name / Code / Technical Specification / Purchase Type / Trim Supplier");
    const [getID, setID] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandRefList, setexpandRefList] = useState({});
    const [expandColorList, setexpandColorList] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [CriticalList, setCriticalList] = useState([]);

    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.trim.trimList;
    let isLoadingTrim = reducerState.trim.isLoadingTrim;
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    // //Enable Menu
    // let activeMenu;
    // const menulist = useSelector((state) => state.common.MenuList.item3);
    // if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "TrimMaster"); }
    // // End Enable Menu

       // If check production or sampling
       const activeMenus = useSelector((state) => state.auth.activeMenuList);
       let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
       let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
       // End
   
         //Enable Menu
         let activeMenu;
         let info;
         const menulist = useSelector((state) => state.common.MenuList.item3);
         if (menulist !== undefined) { 
           //activeMenu = menulist.filter(i => i.menuName === "StrikeoffMaster");
           info = IsProduction === 1 ? 'TrimMaster' : (IsProduction === 2 ? 'ProductionTrimMaster' : undefined);
                activeMenu = menulist.filter(i => i.menuName ===  info );
        }
         // End Enable Menu


    useEffect(() => {
        let StrikeoffId = 0;
        dispatch(retrieveTrim(StrikeoffId));
        const state1 = props.location.state;

        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Trim updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Trim added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

        trimService.GetTrimPrintColorList(0).then((response1) => {
            if (response1.data) {
                setCriticalList(response1.data);
            }
        });
    }, []);

    useEffect(() => {
        if (!isLoadingTrim) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTrim]);

    const headers = [
        // {
        //     name: "#",
        //     sortable: false,
        //     widthsize: "4%"
        // },
        {
            name: "#",
            sortable:false,
            field: "",
            widthsize: "1%"
        },
        {
            name: "Buyer / Brand Name",
            sortable: true,
            field: "buyerAndBrandName",
            widthsize: "10%"
        },
        {
            name: "Trim Image",
            sortable: false,
            widthsize: "10%"
        }, {
            name: "Trim Name",
            field: "trimName",
            sortable: true,
            widthsize: "12%"
        }, {
            name: "Trim Code",
            field: "trimCode",
            sortable: true,
            widthsize: "12%"
        },
        // {
        //     name: "Buyer Reference No",
        //     field: "buyerReferenceNo",
        //     sortable: true,
        //     widthsize: "14%"
        // },
        // {
        //     name: "Technical Specification",
        //     field: "technicalSpecification",
        //     sortable: true,
        //     widthsize: "16%"
        // },
        {
            name: "Purchase Type",
            field: "purchaseTypeName",
            sortable: true,
            widthsize: "12%"
        },
        {
            name: "Trim Supplier",
            field: "trimSupplierName",
            sortable: true,
            widthsize: "12%"
        },
        {
            name: "Action",
            sortable: false,
            widthsize: "10%",
            display: activeMenu.length > 0 && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : ''

        },
    ];

    const childHeaders_Ref = [
        {
            name: "Height",
            sortable: false
        },
        {
            name: "Width",
            sortable: false
        },
        {
            name: "UOM",
            sortable: false
        },
    ];

    const commentsData = useMemo(() => {
        debugger;
        let computedComments = comments.filter(o => 
            BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                o.buyerBrandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0
            )
        );
        debugger;
        // let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.buyerBrandID === brandId && isAllInfo === 0 && isMapped === 1));
           debugger;
            if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }
        if (search) {
            computedComments = computedComments.filter(comment => comment.strikeOffName.toLowerCase().includes(search.toLowerCase())
                || comment.strikeOffCode.toLowerCase().includes(search.toLowerCase())
                || comment.buyerReferenceNo.toLowerCase().includes(search.toLowerCase())
                || comment.quality.toLowerCase().includes(search.toLowerCase())
                || comment.purchaseTypeName.toLowerCase().includes(search.toLowerCase())
                || comment.trimSupplierName.toLowerCase().includes(search.toLowerCase())
                || comment.buyerName.toLowerCase().includes(search.toLowerCase())
                || comment.brandName.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const pageRedirect = (Params, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/TrimRegister');
        }
        else if (redirect === 'Edit') {
            props.history.push(`/TrimRegister`, { params: Params });
        }
        else {
            let dataParams = {
                Operation: MasterTrimID,
                Id: Params.trimInfoID,
            };
            CommonService.CheckMappedItem(dataParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This trim is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    const handleExpandRow = (TrimInfoID) => {
        setExpandedRows([]);
        // showLoader();
        // setTimeout(() => {
        trimService.GetTrimReferenceList(TrimInfoID).then((response) => {
            if (response.data) {
                setexpandRefList(response.data);
                trimService.TrimTabList(TrimInfoID).then((response) => {
                    if (response.data) {
                        setexpandColorList(response.data);
                        // Expand row Start
                        let currentExpandedRows = expandedRows;
                        const isRowExpanded = currentExpandedRows.includes(TrimInfoID);
                        let obj = {};
                        isRowExpanded ? (obj[TrimInfoID] = false) : (obj[TrimInfoID] = true);
                        setExpandState(obj);
                        currentExpandedRows = [];
                        const newExpandedRows = isRowExpanded ?
                            currentExpandedRows.filter(id => id !== TrimInfoID) :
                            currentExpandedRows.concat(TrimInfoID);
                        setExpandedRows(newExpandedRows);
                        // hideLoader();
                        // Expand row End
                    }
                });
            }
        });
        // }, 400);
    }

    const Delcallback = (value) => {

        setID({ showPopupDelete: false, Params: [] });
    }


    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Trim List </span>
                            </div>
                            <div> {
                                isOpen && (
                                    <Lightbox
                                        mainSrc={isOpen}
                                        alt="bg image"

                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder}
                                            props={props}
                                        />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu.length > 0 &&  activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <a className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Trim">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </a>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                    <>
                                                        <tr key={
                                                            comment.trimInfoID
                                                        }>
                                                            {/* <td> <i
                                                                className={
                                                                    expandState[comment.trimInfoID] ?
                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                }
                                                                onClick={event => handleExpandRow(comment.trimInfoID)}
                                                            ></i></td> */}
                                                            <td style={{width:'25px'}}>  
                                                               { i + 1}
                                                            </td>
                                                            <td>
                                                                <span>
                                                                {comment.buyerAndBrandName.replace(/-/g, '/')}
                                                                </span>
                                                            </td>
                                                            <td className="trwidth_manual" style={{ textAlign: "center" }}>

                                                                {
                                                                    < img className="width_manual"
                                                                        src={comment.trimLogoPath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Trim/' + comment.trimLogoPath}
                                                                        alt="Logo"

                                                                        onClick={
                                                                            () => setIsOpen(comment.trimLogoPath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Trim/' + comment.trimLogoPath)
                                                                        }
                                                                    />
                                                                }</td>

                                                            <td>

                                                                <span>
                                                                    {comment.trimName}
                                                                </span>
                                                            </td>
                                                            <td>{comment.trimCode}</td>
                                                            {/* <td>{comment.buyerReferenceNo}</td>
                                                            <td>{comment.technicalSpecification}</td> */}
                                                            <td>{comment.purchaseTypeName}</td>
                                                            <td>{comment.purchaseType === 1 ? comment.trimSupplierName : "-"}</td>

                                                            <td style={{ display: activeMenu.length > 0 && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : '' }}>
                                                                <span style={{ display: activeMenu.length > 0 && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Trim' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu.length > 0 && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Trim' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        {CriticalList.filter(x => x.trimInfoID === comment.trimInfoID && x.indexName === comment.indexName)[0] !== undefined && (
                                                            CriticalList.filter(x => x.trimInfoID === comment.trimInfoID && x.indexName === comment.indexName).length !== 0 && (
                                                                <>
                                                                    {
                                                                        CriticalList.filter(x => x.trimInfoID === comment.trimInfoID && x.indexName === comment.indexName).map((critical, indexValue) => (
                                                                            (critical.notes !== '' || critical.targettoClose !== null || critical.completedOn !== null) && (
                                                                                <tr>
                                                                                    <td colSpan="6">
                                                                                        <span style={{ color: 'red' }}>{"Critical : " + (critical.strPantoneName + ' ' + critical.pantoneTypeName + ' - ' + critical.notes)} ,</span>
                                                                                        <span style={{ color: '#427fed' }}> Target to Close : {critical.targettoClose === null ? '-' : critical.targettoClose} ,</span>
                                                                                        <span style={{ color: '#00a65a' }}> Completed On : {critical.completedOn === null ? '-' : critical.completedOn}</span>
                                                                                        <br />
                                                                                    </td>
                                                                                </tr>

                                                                            )
                                                                        ))
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                        {
                                                            expandedRows.includes(comment.trimInfoID) ?
                                                                <>

                                                                    <tr>
                                                                        <td colSpan="9">
                                                                            <div className="col-md-12">
                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                    <TableHeader headers={childHeaders_Ref}
                                                                                        onSorting={
                                                                                            (field, order) => setSorting({ field, order })
                                                                                        } />
                                                                                    <tbody>
                                                                                        {
                                                                                            expandRefList.map(ref => (
                                                                                                <tr>
                                                                                                    <td>{ref.height}</td>
                                                                                                    <td>{ref.width}</td>
                                                                                                    <td>{ref.sizeName}</td>
                                                                                                </tr>

                                                                                            ))
                                                                                        }
                                                                                        {
                                                                                            expandRefList.length === 0 ?
                                                                                                <tr>
                                                                                                    <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr> : ''
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                {expandColorList.colorGroup.length !== 0 && (
                                                                                    <div className="col-md-12" style={{ marginTop: "15px", border: "1px solid #dddddd" }}>
                                                                                        <div style={{ marginTop: "15px" }}>
                                                                                            {
                                                                                                expandColorList.colorGroup.map((colorgroup, index) => (
                                                                                                    <div className={index === 5 ? "row" : index === 11 ? "row" : index === 17 ? "row" : index === 23 ? "row" : index === 29 ? "row" : ""}>
                                                                                                        <div className="col-md-2" >
                                                                                                            <img className="img-fluid"
                                                                                                                src={colorgroup.trimLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + colorgroup.trimLogoPath}
                                                                                                                alt="Logo"
                                                                                                                onClick={() => setIsOpen(colorgroup.trimLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Style/Trim/' + colorgroup.trimLogoPath)}
                                                                                                                style={{ height: '100px', width: "100%", marginBottom: "5px" }}
                                                                                                            />
                                                                                                            {
                                                                                                                expandColorList.color.filter(x => x.indexName === colorgroup.indexName).map(color => (
                                                                                                                    <>
                                                                                                                        <h3 className="heading_group" style={{ fontSize: "12px", fontWeight: "600", textTransform: 'uppercase', margin: '2px 0px' }}>
                                                                                                                            {color.pantoneTypeName + " - " + color.pantoneColor}
                                                                                                                            <div style={{ background: color.pantoneColor, marginLeft: "5px", verticalAlign: "bottom", width: '12px', display: "inline-block", height: '12px', borderRadius: '2px' }}></div>
                                                                                                                        </h3>
                                                                                                                        {color.notes !== '' && (
                                                                                                                            <span className="text-danger">{color.notes}</span>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </> : ''
                                                        }
                                                    </>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />

                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={MasterTrimID}
                                    Msg={"Trim deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )

};
export default MasterTrimList;