import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetOrderStrikeOffSubmissionApprovalGridList, clearStrikeOffSubmissionSubRow, GetOrderStrikeOffApprovalList, ReloadOrderStrikeOffApprovalList } from "../../../../actions/orderStrikeOff";
import { loadSupplierList, getSkuAndStyleDetails } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import { loadRouteDependencyList } from "../../../../actions/route";
import taskService from "../../../../services/Master/TaskService";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { loadTaskHolderList } from "../../../../actions/task";
import { LoadSizeList } from '../../../../actions/size'
import TnaService from "../../../../services/TNA/TNAService";
import DatePicker from "react-datepicker";
import StrikeOffSubmission from "../../../TNA/StrikeoffSubmission_Order";
import { loadSKUListByStyleID } from "../../../../actions/style";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";

function StrikeOffViewRequestTab(props) {
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getStyleNameSearch, setgetStyleNameSearch] = useState("");
    const [getStyleNoSearch, setgetStyleNoSearch] = useState("");
    const [getIdPoSearch, setgetIdPoSearch] = useState("");
    //reducer
    const { user: currentUser } = useSelector((state) => state.auth);
    const StrikeOffSubmissionList = useSelector((state) => state.orderStrikeOff.orderStrikeOffSubmissionList);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    let isLoadingOrderStrikeOff = useSelector((state) => state.orderStrikeOff.isLoadingOrderStrikeOff);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const editTNATaskStrikeOffSubmissionList = useSelector((state) => state.orderStrikeOff.editTNATaskStrikeOffSubmissionList);
    // const StaticTask = useSelector((state) => state.tna.StaticTask);
    let GLDRequestStatusList = useSelector((state) => state.orderStrikeOff.OLDRequestStatusList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    //Doubt
    const genStrikeOffTNAHolidayList = useSelector((state) => state.generalLabdip.genStrikeOffTNAHolidayList);
    //end
    const sizeList = useSelector((state) => state.size.drpSizeList);
    const skuList = useSelector((state) => state.style.SKUList);


    //state

    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currStrikeOffId: "", currColorGroupID: "" });
    const [getSizeList, setSizeList] = useState([]);
    const [showSizePopup, setShowSizePopup] = useState({ isPopup: false, detail: '' });

    const [MainFields, setMainFields] = useState({
        GenStrikeOffSupplierTNAId: 0,
        GenStrikeOffDetailTNAId: 0,
        GenStrikeOffTNASubmissionAndApproval: [],
        GenTNAAdditionalTaskDetails: [],
        CreatedBy: currentUser.employeeinformationID,
    });

    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        styleName: '',
        StyleNo: '',
        Supplier: '',
        SubmittedDate: '',
        SendTo: "0",
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        StrikeOffId: 0, StrikeoffCode: 0, StrikeoffName: "", Quality: "",
        StrikeoffDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        SubmitStatus: '',
        GenralStrikeOffTNASubmissionAndApprovalId: 0,
        GeneralTNAStrikeOffSubmittedOption: [],
        ColorGroupId: 0
    }]);

    const values = [...inputFields]

    const [inputTaskFields, setInputTaskFields] = useState([]);
    const [isTaskFieldChange, setIsTaskFieldChange] = useState(0);
    const [getCalculation, setCalculation] = useState({
        isLoad: 0, isDuration: false, isEndDate: false
    });
    const [getLeaveDaysList, setLeaveDaysList] = useState([]);

  

    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        StyleName: "",
        StyleNo: "",
        IdPoNo: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });

    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
     let info;
    let activeMenu;
    let activeMenuSub;
   let OrderStrikeOffRequest;
   

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
       // info = IsProduction === 1 ? 'Samples' : 'ProductionSample';
         info = IsProduction === 1 ? 'OrderStrikeOff' : (IsProduction === 2 ? 'ProductionOrderStrikeOff' : 'ProductionOrderStrikeOff');
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                     if (menuName === getMenuName("OrderStrikeOffRequest")) {
                        OrderStrikeOffRequest = menuItem;
                        }
                        

                   
                }
            }
        }
    }


    const [editStrikeOffSubmission, seteditStrikeOffSubmission] = useState(false);
    const [delteStrikeOffSubmission, setdeleteStrikeOffSubmission] = useState(false);

    const [showmaunalclose, setshowmaunalclose] = useState(false);

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
    const [getComment, setComment] = useState(false);


    const [TNAId, setTNAId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [SwatchStatus, setSwatchStatus] = useState();
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);

    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});

    const headers = [
        { name: "Style Name", field: "StyleName", sortable: false },
        { name: "Style No", field: "StyleNo", sortable: false },
        { name: "ID Po", field: "IDPo", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        { name: "Action", field: "", sortable: false },
    ];

    const SearchReqValues = { ...getSearchReqValues };
    const valuesTask = [...inputTaskFields]
    const CalculationValue = { ...getCalculation };

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    useEffect(() => {
        if (!isLoadingOrderStrikeOff) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingOrderStrikeOff])

    useEffect(() => {


        dispatch(loadSupplierList());
        dispatch(loadTaskHolderList());
        dispatch(loadRouteDependencyList());
        taskService.getLoadLeaveDaysList().then((response) => {
            if (response.data) {
                setLeaveDaysList(response.data);
            }
        });
        searchStrikeOff();
    }, [])


    //#region  ------------------  search details --------------------------------
    const searchStrikeOff = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0,
            Index: currentExpand.currindex, SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId,
            StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo
            , IsProduction: IsProduction
        };
        dispatch(GetOrderStrikeOffSubmissionApprovalGridList(params));
        // dispatch(LoadSizeList());
        // let params = {
        //     BuyerId: StrikeOffViewDetailParams.BuyerId, BrandId: StrikeOffViewDetailParams.BrandId, SeasonId: StrikeOffViewDetailParams.SeasonId,
        //     BuyerName: StrikeOffViewDetailParams.BuyerName, BrandName: StrikeOffViewDetailParams.BrandName, SeasonName: StrikeOffViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralStrikeOffDetailsViewList(params));
        commonParentExpand();
    }

    const resetStrikeOff = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        SearchReqValues.StyleName = "";
        SearchReqValues.StyleNo = "";
        SearchReqValues.IdPoNo = "";
        setSearchReqValues(SearchReqValues);
        searchStrikeOff();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        } else if (FieldName === 'StyleName') {
            SearchReqValues.StyleName = inputText;
        }
        else if (FieldName === 'StyleNo') {
            SearchReqValues.StyleNo = inputText;
        }
        else if (FieldName === 'IdPoNo') {
            SearchReqValues.IdPoNo = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    //#end  ------------------  search details end --------------------------------



    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, StrikeOffId, ColorGroupID, isExpandedChild) => {

        if (isExpandedChild) {
            //Child row only reload data
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: StrikeOffId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
            };
            setcurrentExpand({ currindex: index, currStrikeOffId: StrikeOffId, currColorGroupID: ColorGroupID })
            dispatch(GetOrderStrikeOffSubmissionApprovalGridList(params));

        } else {

            let btnToggle = document.getElementById("btn-toggle1_" + index);
            let iconToggle = document.getElementById("icon-toggle1_" + index);
            let ariaExpanded = btnToggle.getAttribute('aria-expanded');
            if (ariaExpanded === 'false') {
                btnToggle.setAttribute('aria-expanded', 'true');
                iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
                let StartDate = null;
                let EndDate = null;
                if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                    StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
                }
                if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                    EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
                }
                let params = {
                    BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: StrikeOffId, ColorGroupID: ColorGroupID, Index: index,
                    SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
                    SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                    StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
                };
                setcurrentExpand({ currindex: index, currStrikeOffId: StrikeOffId, currColorGroupID: ColorGroupID })
                dispatch(GetOrderStrikeOffSubmissionApprovalGridList(params));
            }
            else {

                btnToggle.setAttribute('aria-expanded', 'false');
                iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
                if (StrikeOffId) {
                    dispatch(clearStrikeOffSubmissionSubRow(index));

                }
            }
        }
    };
    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandRequestTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandRequestTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    const handleSizePopupClose = () => {

        setShowSizePopup({ isPopup: false, detail: '' });

    }
    const handleserach = () => {

    }


    const handleStyleDetails = (detail, strikeOffGarmentSize, skuidlist, TaskName) => {

       
        // let sizes;
        // var sizeFilter = [];

        // if (strikeOffGarmentSize.includes(',')) {
        //     sizes = strikeOffGarmentSize.split(',');

        //     sizeList.map(item => {
        //         sizes.map(s => {
        //             if (item.value === parseInt(s)) {
        //                 sizeFilter.push({ label: item.label })
        //             }
        //         })
        //     })
        // }
        // else {
        //     sizes = sizeList.filter(x => x.value === parseInt(strikeOffGarmentSize))[0].label;
        //     sizeFilter.push({ label: sizes })
        // }



        // // const sizeFilter = getSizeList.filter(d => strikeOffGarmentSize.includes(d.value));
        // setSizeList(sizeFilter);
        // setShowSizePopup({ isPopup: true, detail: detail });

        if (skuidlist) {
            dispatch(getSkuAndStyleDetails({
                TNAId: 0, SubId: 0,
                MainId: 0, StrikeOffIdOrTrimInfoId: 0,
                TaskName: TaskName, StyleId: 0
                , SKUIdList: skuidlist, StyleStrikeOffGarmentSizeIdList: strikeOffGarmentSize

            }))
                .then(data => {
                    if (data) {
                        setShowSizePopup({ isPopup: true, detail: detail });
                        setSizeList(data);
                    }
                });
        } else {
            let Title = "Warning!";
            let message = "No SKU in " + TaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }

    }





    //action releated functionalities
    //manual close

    const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, buyerid, brandid, seasonid, supplierid) => {

        showLoader();
        var manualclosedata = { ...Manualclosedata };
        manualclosedata.ManualCloseComment = "";
        manualclosedata.ManualCloseDate = ''
        setManualclosedata(manualclosedata)
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
        }

        else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
            Operation = 6;
        }
        else {
            Operation = 7;

        }


        if (Operation !== 7) { // check submission exists for approvals
            TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
                if (response.data.length !== 0) {

                    setshowmaunalclose(true);

                    //get manual close
                    let Operation;
                    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                        Operation = 1;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        Operation = 3;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                        Operation = 2;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                        Operation = 4;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                        Operation = 5;
                    }
                    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                        Operation = 6;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                        Operation = 7;
                    }
                    else {
                        Operation = 8;
                    }


                    let params = {
                        BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
                        SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
                        TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                        Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                    };

                    TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                        if (response.data) {
                            let manindex = 1;
                            let data = response.data.map((item) => {

                                item.index = manindex;
                                manindex = manindex + 1;

                                if (item.subTaskInfoId === ChildId) {
                                    item.isSelected = 1;

                                    return item;
                                } else {
                                    return item;
                                }
                            });
                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }
                            setselectall(isSelectAll);
                            if (Status !== 3) {
                                setMultiCloseDatas(data);
                                let maindata = [...new Map(data.map(item =>
                                    [item['strikeofforTrimLogoId'], item])).values()];


                                setOrderInfoMulticloseMainDetails(maindata);
                                DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                            }
                            else {
                                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                                let maindata = [...new Map(data.map(item =>
                                    [item['strikeofforTrimLogoId'], item])).values()];


                                setOrderInfoMulticloseMainDetails(maindata);
                                DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                            }

                        }


                    }).catch(() => { });
                }
                else {
                    Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }
        else {
            setshowmaunalclose(true);

            //get manual close
            let Operation;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                Operation = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                Operation = 3;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                Operation = 2;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                Operation = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                Operation = 5;
            }
            //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
            else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
                Operation = 6;
            }
            else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                Operation = 7;
            }
            else {
                Operation = 8;
            }


            let params = {
                BuyerId: buyerid, BrandId: brandid, SeasonId: seasonid,
                SupplierId: supplierid, TaskType: TaskType, TaskNameId: TaskID,
                TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
            };

            await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                if (response.data) {
                    let manindex = 1;
                    let data = response.data.map((item) => {
                        item.index = manindex;
                        manindex = manindex + 1;
                        if (item.subTaskInfoId === ChildId) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });
                    let isSelectAll = 0;
                    if (data) {
                        isSelectAll = !!!data.find(d => d.isSelected === 0);
                        isSelectAll = isSelectAll ? 1 : 0;
                    }
                    setselectall(isSelectAll);
                    if (Status !== 3) {
                        setMultiCloseDatas(data);
                        let maindata = [...new Map(data.map(item =>
                            [item['strikeofforTrimLogoId'], item])).values()];


                        setOrderInfoMulticloseMainDetails(maindata);
                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                    }
                    else {
                        setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                        let maindata = [...new Map(data.map(item =>
                            [item['strikeofforTrimLogoId'], item])).values()];


                        setOrderInfoMulticloseMainDetails(maindata);
                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                    }
                    hideLoader();
                }

                hideLoader();
            }).catch(() => { hideLoader(); });
        }

    };

    const DefaultExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = false;
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }

    const handleExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeofforTrimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }


    const handleChangeIsManualCloseComments = (event, feild) => {
        var manualclosedata = { ...Manualclosedata };

        let input = '';

        setComment(false);
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            manualclosedata.ManualCloseComment = input;
        }
        else {
            manualclosedata.ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setManualclosedata(manualclosedata);
    }
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const SelectAll = (event, strikeofforTrimLogoPath, mainindex) => {
        let IsSelected = 0;
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        Maindetails[mainindex].isSelectedAll = IsSelected;
        setOrderInfoMulticloseMainDetails(Maindetails);

        //setselectall({ IsAllSelected: IsSelected, AllSelectedIndex: mainindex });
    }

    const OnSelectRecords = (event, index, mainindex) => {
        const value = [...MultiCloseDatas];
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }

            Maindetails[mainindex].isSelectedAll = isSelectAll;
            setOrderInfoMulticloseMainDetails(Maindetails);
            // setselectall({ IsAllSelected: isSelectAll, AllSelectedIndex: mainindex });
            setMultiCloseDatas(value);
        }

    }

    const SaveChildManulaCloseComments = () => {



        if (Manualclosedata.ManualCloseComment === '' || Manualclosedata.ManualCloseComment === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter comments");
        }
        else if (Manualclosedata.ManualCloseDate === '') {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].IsManualClose = 0;
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].StatusID = 3;
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate = new Date();
            // values[index].SubTaskList[subIndex].subFieldList[childIndex].ChildActualEndDate = new Date();
            // setInputFields(values);


            let ChildStatus = 2;


            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                var ManualCloseData = { ...Manualclosedata };
                ManualCloseData.ManualCloseDate = new Date(Manualclosedata.ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate);
                // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = Manualclosedata.ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                        setshowmaunalclose(false);

                        ReloadSubmission();
                        // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);
                        searchStrikeOff();


                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }

        }
    }



    const ReloadSubmission = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0,
            Index: currentExpand.currindex, SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId,
            StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };

        let approvalparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, StrikeOffId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            StyleName: SearchReqValues.StyleName, StyleNo: SearchReqValues.StyleNo, IdPoNo: SearchReqValues.IdPoNo, IsProduction: IsProduction
        };
        dispatch(ReloadOrderStrikeOffApprovalList(approvalparams));
        dispatch(GetOrderStrikeOffSubmissionApprovalGridList(params));


    }


    const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, StatusId, SwatchStatus) => {
        setTNAId(TNAId);
        setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
        setTaskStatus(StatusId);
        setSwatchStatus(SwatchStatus)
        setStrikeOffmodalpopup(true);
    }

    const handleStrikeoffSub = () => {
        setStrikeOffmodalpopup(false)
        seteditStrikeOffSubmission(false);
        setdeleteStrikeOffSubmission(false);
        // setStrikeoffswatchDownload(false);
        // setStrikeoffswatchUpload(false);
    }
    const handleCloseManualPopup = () => {
        var manualclosedata = { ...Manualclosedata };
        manualclosedata.ManualCloseComment = '';
        manualclosedata.ManualCloseDate = '';
        setManualclosedata(manualclosedata)
        setshowmaunalclose(false)
    }
    const StrikeoffSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            setStrikeOffmodalpopup(false)
            seteditStrikeOffSubmission(false);
            setdeleteStrikeOffSubmission(false);

            ReloadSubmission();
            searchStrikeOff();
            // handleToggle(currentExpand.currindex, currentExpand.currStrikeOffId, currentExpand.currColorGroupID);

        }
    }
    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>


                    <div className="col-sm-12">
                            
                            <div className="col-md-2">
                                <div className='form-group'>
                                    <label>Strike Off Code / Name</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Strike Off Code / Name" className="form-control" />
                                    </span>

                                </div>

                            </div>
                            <div className="col-md-2">
                                <label htmlFor="Status">
                                    Style Name
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style Name'}
                                        placeholder={'Style Name'}
                                        value={SearchReqValues.StyleName}
                                        onChange={e => handleSearchChange(e, 'StyleName')}
                                    />
                                </span>
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="Status">
                                    Style No
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Style No'}
                                        placeholder={'Style No'}
                                        value={SearchReqValues.StyleNo}
                                        onChange={e => handleSearchChange(e, 'StyleNo')}
                                    />
                                </span>
                            </div>





                            <div className="col-md-2">
                                <label htmlFor="Status">
                                        ID/PO NO
                                </label>
                                <span className="input-icon icon-right">
                                    <input
                                        type="text"
                                        className="form-control StyleList"
                                        title={'Id Po No'}
                                        placeholder={'ID/PO NO'}
                                        value={SearchReqValues.IdPoNo}
                                        onChange={e => handleSearchChange(e, 'IdPoNo')}
                                    />
                                </span>

                            </div>
                       
                        
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="SupplierID">
                                        Supplier
                                        {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="SupplierId"
                                            id={
                                                "SupplierId"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'SupplierId')}
                                            value={getSupplierList.filter(function (option) {
                                                return option.value === SearchReqValues.SupplierId;

                                            })}
                                            options={getSupplierList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>

                                    </span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Submit Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                    </div>
                        <div className="col-sm-12">
                            <div className="col-md-2">

                                <div className="form-group">
                                    <label>Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span>
                                </div>


                                {SearchReqValues.stateDate[0].isShow &&
                                    <DateRangePicker
                                        //onChange={item => setStateDate([item.selection])}
                                        onChange={item => handlePopupDateselection(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={SearchReqValues.stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}

                                    />

                                }

                            </div>
                            <div className="col-md-2" style={{ paddingTop: '29px' }}>

                                <button type="button" className="btn btn-success" title="Search" onClick={event => searchStrikeOff()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-danger" title="Reset" onClick={resetStrikeOff}
                                >
                                    <i className="fa fa-close"></i>
                                </button>
                            </div>
                            {/* <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Submit Status
                                    </label>

                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList && GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div> */}
                        </div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "35px" }}> #</th>
                                        <th className="col-action-or-other">Requested Date</th>
                                        <th className="col-action-or-other">Style Name</th>
                                        <th className="col-action-or-other">Style No</th>
                                        <th className="col-action-or-other">ID/PO NO</th>
                                        <th className="col-action-or-other">Supplier</th>
                                        <th className="col-action-or-other">Details</th>
                                        <th className="col-action-or-other">Task Holder / Follower</th>
                                        <th className="col-action-or-other">Submit Target</th>
                                        <th className="col-action-or-other">Submit Status</th>
                                        <th className="col-action-or-other">AWB & Date</th>
                                        <th style = {{display: OrderStrikeOffRequest && OrderStrikeOffRequest.isAdd === 1 ? '' : 'none' }} className="text-center fixed-column-header-Route col-action-or-other">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        StrikeOffSubmissionList && StrikeOffSubmissionList.length > 0 ?
                                            <>
                                                {
                                                    StrikeOffSubmissionList && StrikeOffSubmissionList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle1_" + index} class="btn-toggle1 parentExpandRequestTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.strikeOffId, inputField.colorGroupID, false)} aria-labelledby="btn-toggle1 id-master">
                                                                            <i id={"icon-toggle1_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandRequestTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.strikeOff
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="10">

                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>

                                                                </td>

                                                            </tr>
                                                            {
                                                                inputField.strikeOffSubmitApprovalReferenceList && inputField.strikeOffSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"16px"}}>
                                                                                {subIndex + 1}
                                                                                {/* {JSON.stringify(subField)} */}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.requestedDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.styleNo} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.idpono} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details}
                                                                                        {
                                                                                            subField.strikeOffGarmentSize &&
                                                                                            <span onClick={() => handleStyleDetails(subField.details, subField.strikeOffGarmentSize, subField.skuIdList, StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT)} className="text-primary" style={{ color: '#0488f8' }}> {"Size Details"} </span>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td className="cut_text" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} / {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submitTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.submitStatus} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {(subField.awbDate === null || subField.awbDate === '') ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td style = {{display: OrderStrikeOffRequest && OrderStrikeOffRequest.isAdd === 1 ? '' : 'none' }}>
                                                                                {(subField.isShowSubmitButton === 1 || subField.isShowSubmitButton === 2) && subField.statusID !== 3 ?
                                                                                    <Fragment>&nbsp;<button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                        onClick={() => handleChildManualclose(StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT, subField.taskType, subField.taskID, subField.childId, subField.statusID, subField.manualCloseComment)}
                                                                                    >
                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                        <i className="fa fa-close"></i>
                                                                                    </button>
                                                                                    </Fragment>
                                                                                    :
                                                                                    <button type="button" className="btn btn-xs btn-success" title='Submitted' disabled>
                                                                                        <i className="fa fa-check"></i>
                                                                                    </button>
                                                                                }




                                                                                &nbsp;

                                                                                {
                                                                                    // TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                    subField.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && subField.isShowSubmitButton === 2 && subField.statusID !== 3 ?
                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                            onClick={() => OpenStrikeoffSubmssionpopup(subField.tnaId, subField.childId,
                                                                                                subField.statusID, subField.swatchStatus)}
                                                                                        >
                                                                                            <i className="fa fa-send"></i>
                                                                                        </button> :
                                                                                        subField.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && subField.isShowSubmitButton === 0 ?
                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                <i className="fa fa-check"></i>
                                                                                            </button>
                                                                                            : ''

                                                                                }
                                                                            </td>



                                                                        </tr>



                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="13" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>

                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

                        </div>
                    </div>
                </div>
            </div >
            {
                //View Size
                showSizePopup.isPopup === true ?
                    <Modal show={showSizePopup.isPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleSizePopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Size
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Detail</th>
                                            <th>Garment Name</th>
                                            <th>Sku Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // getSizeList && getSizeList.map((inputField) => (
                                            <Fragment>
                                                <tr>
                                                    <td>
                                                        <span>
                                                            {showSizePopup.detail}

                                                        </span>
                                                    </td>
                                                    {/* <td>
                                                            <span>
                                                                {inputField.label}
                                                            </span>
                                                        </td> */}

                                                    <td>{getSizeList.sizeNameList}</td>
                                                    <td>{getSizeList.skuNameList}</td>
                                                </tr>
                                            </Fragment>
                                            // ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="danger" onClick={() => handleSizePopupClose()}><i className='fa fa-times'></i>
                                    Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }
            {loader}


            {
                strikeoffModalpopup === true ?

                    <Modal show={strikeoffModalpopup === true}
                        size="xl" dragable backdrop="static"
                        keyboard={false}
                        dialogClassName="commonwidth"
                        onHide={() => handleStrikeoffSub()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    TaskStatus === 3 && editStrikeOffSubmission === false ?
                                        "View " : editStrikeOffSubmission === true ? 'Update ' : ''
                                }

                                Strike Off Submission
                                {
                                    TaskStatus === 3 ?
                                        <>

                                            {/* {
                                                (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                    <span title='Download Swatch Card' style={{ 'margin-left': '650px' }} onClick={() => setStrikeoffswatchDownload(true)}>
                                                        <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                    </span>
                                                    : ''

                                            }
                                            &nbsp;

                                            {
                                                SwatchStatus === 1 ?
                                                    <span title='Upload Submitted Swatch Card'>
                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                    </span>
                                                    : ''

                                            }
                                            &nbsp;
                                            {
                                                SwatchStatus === 2 ?
                                                    <span title='Edit Form' style={{ 'margin-left': '680px' }} onClick={() => seteditStrikeOffSubmission(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span> :
                                                    <span title='Edit Form' onClick={() => seteditStrikeOffSubmission(true)}
                                                        className="btn btn-info btn-xs edit">
                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                            } */}
                                            <span title='Edit Form' style={{ 'margin-left': '680px' }} onClick={() => seteditStrikeOffSubmission(true)}
                                                className="btn btn-info btn-xs edit">
                                                <i className="fa fa-edit"></i>
                                            </span>
                                            &nbsp;
                                            <span title='Delete Form'
                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffSubmission(true)}>
                                                <i className="fa fa-trash-o"></i>
                                            </span>
                                        </> : ''
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <StrikeOffSubmission props={props}
                                StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                TNAId={TNAId}
                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                TaskStatus={TaskStatus}

                                values={values}
                                editStrikeOffSubmission={editStrikeOffSubmission}
                                deleteStrikeOffSubmission={delteStrikeOffSubmission}
                                // StrikeoffswatchDownload={StrikeoffswatchDownload}
                                // StrikeoffswatchUpload={StrikeoffswatchUpload}
                                SwatchStatus={SwatchStatus}
                            ></StrikeOffSubmission>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>

                    </Modal> : ''
            }




            {
                showmaunalclose === true ?
                    <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Manual Close : <span style={{textTransform:"capitalize"}}>{StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT}</span> &nbsp;
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                                            >

                                            </textarea>
                                        </td>
                                        <td>
                                            <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                    "HolidayDate"
                                                }
                                                selected={
                                                    Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                // style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                OrderInfoMulticloseMainDetails.length !== 0 ?

                                    <table className="table table-bordered">

                                        <tbody>
                                            {
                                                OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                    <Fragment>
                                                        <tr>
                                                            <td>
                                                                <div className="row">
                                                                    <div className="col-lg-8">
                                                                        <i
                                                                            className={
                                                                                expandStateMultiSelect[comment.strikeofforTrimLogoPath] ?
                                                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                            }
                                                                            onClick={() => handleExpandRowMultiSelect(comment.strikeofforTrimLogoPath, MultiCloseDatas)}
                                                                        ></i>
                                                                        <span className="BuyerPaddingLeft">
                                                                            {
                                                                                comment.strikeofforTrimCode
                                                                            }
                                                                            -
                                                                            {
                                                                                comment.strikeofforTrimName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <span className="Actionbutton">
                                                                            <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                        <>
                                                            {
                                                                expandedRowsMultiSelect.includes(comment.strikeofforTrimLogoPath) ?

                                                                    <tr>
                                                                        <td>
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Style Name</th>
                                                                                        <th>Style No</th>
                                                                                        <th>PO/ID No</th>
                                                                                        <th>Details</th>
                                                                                        <th>Task Holder / Follower</th>
                                                                                        <th>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                    onChange={event => (SelectAll(event, comment.strikeofforTrimLogoPath, mainindex))}
                                                                                                    //  checked={(selectall.IsAllSelected === 1 && selectall.AllSelectedIndex === mainindex) ? true : false}
                                                                                                    checked={comment.isSelectedAll}
                                                                                                    className="colored-blue clearOpacity" />
                                                                                                <span className="text"></span>
                                                                                            </label>

                                                                                        </th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        expandListMultiSelect.map((item, index) => (
                                                                                            <Fragment>

                                                                                                <tr>
                                                                                                    <td>{item.styleName}</td>
                                                                                                    <td>{item.styleNo}</td>
                                                                                                    <td>{item.idPoNo}</td>
                                                                                                    <td>{item.details}</td>
                                                                                                    <td>{item.taskOwnerNameList}</td>
                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                        <div style={{ width: "80px" }}>
                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                                    onChange={event => (OnSelectRecords(event, item.index - 1, mainindex))}
                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                <span className="text"></span>
                                                                                                            </label>

                                                                                                        </div>

                                                                                                    </td>

                                                                                                </tr>

                                                                                            </Fragment>
                                                                                        ))

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                        </>
                                                    </Fragment>
                                                ))
                                            }






                                        </tbody>
                                    </table>
                                    :
                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <TableHeader headers={headers} />
                                        <tbody>
                                            <tr><td colSpan="10" className='norecordfound'><span>No Records Found</span></td></tr>
                                        </tbody>
                                    </table>


                            }

                        </Modal.Body>
                        <Modal.Footer>
                            {
                                //  subField.StatusID !== 3 &&
                                <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                    Save
                                </Button>


                            }


                        </Modal.Footer>
                    </Modal> : ''
            }
        </Fragment >

    )
}

export default React.memo(StrikeOffViewRequestTab);