import React, { useState, useEffect, Fragment, useMemo } from "react";
import 'react-notifications-component/dist/theme.css';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { Redirect } from 'react-router-dom';
import SearchRetain from "../../Common/SearchRetain";
import { retrieveorderProtoFit } from "../../../actions/orderProtoFitSms";

const ProtoFitSmsList = (props) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [getID, setID] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [getPlaceholder] = useState("Buyer / Season / Brand");

  const dispatch = useDispatch();
  const reducerState = useSelector((state) => state);
  const { user: currentUser } = reducerState.auth;

 
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];




  const comments = reducerState.orderProtoFitSms.protoFitSmsList;
  let isLoadingProtoFitSms = reducerState.orderProtoFitSms.isLoadingProtoFitSms;




  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  let IsProduction = activeMenus.IsProduction;
  // End

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    let info;
   //let isTablist = false;
  
   // Set 'info' based on IsProduction
   info = IsProduction === 1 ? 'Samples' : (IsProduction === 2 ? 'ProductionSample' : 'ProductionSample');
  
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === info); }
    // End Enable Menu


  useEffect(() => {
    dispatch(retrieveorderProtoFit(IsProduction));
    const state1 = props.location.state;
    if (state1 !== undefined && state1 !== null) {
      let Msg;
      let Type = 'success'; // default, info, warning
      let title = 'Success!';
      if (state1.message === 'Edit') {
        Msg = 'ProtoFitSms updated successfully.';
      } else if (state1.message === 'Add') {
        Msg = 'ProtoFitSms added successfully.';
      } else {
        Msg = 'Error occured';
        Type = 'warning';
        title = 'Error!';
      }
      window.history.replaceState(null, '')
      Nodify(title, Type, Msg);
    }
    var page = getPlaceholder;
    var setConstraint = SearchRetain(page);
    if (setConstraint !== undefined) {
      setCurrentPage(setConstraint.CurrentPage);
      setITEMS_PER_PAGE(setConstraint.PageItems);
    }



  }, [dispatch, IsProduction]);

  useEffect(() => {
    if (!isLoadingProtoFitSms) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingProtoFitSms])

  const commentsData = useMemo(() => {

   // let computedComments = comments && comments.filter(d => d.brandName && d.buyerName && d.seasonName);
    debugger;
    let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
      o.brandId === brandId && o.buyerId === buyerId && isAllInfo === 1 && isMapped === 0));
    debugger;
  // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
  //     o.buyerID === buyerId && o.brandID === brandId && isAllInfo === 0 && isMapped === 1));
  // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped, seasonID, cubeEmployeeIdList }) =>
  //     o.brandID === brandId && isAllInfo === 0 &&
  //     (isMapped === 1 && seasonID === o.seasonID && cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString()))));

    if (search) {
      computedComments = computedComments.filter(comment =>
        (comment.buyerName && comment.buyerName.toLowerCase().includes(search.toLowerCase()))
        || (comment.seasonName && comment.seasonName.toLowerCase().includes(search.toLowerCase()))
        || (comment.brandName && comment.brandName.toLowerCase().includes(search.toLowerCase()))

      );
    }

    if (computedComments) {
      setTotalItems(computedComments.length);
      // if (sorting.field === 'noOfStyles') {
      //     const reversed = sorting.order === "asc" ? 1 : -1;
      //     if (reversed === 1) {
      //         computedComments = computedComments.sort((a, b) => (Number(a.noOfStyles)) - (Number(b.noOfStyles)));
      //     } else {
      //         computedComments = computedComments.sort((a, b) => (Number(b.noOfStyles)) - (Number(a.noOfStyles)));
      //     }
      // } else if (sorting.field === 'recievedOn') {
      //     const reversed = sorting.order === "asc" ? 1 : -1;
      //     computedComments = computedComments.sort((a, b) => reversed * (new moment(a[sorting.field]).format('YYYYMMDD') - new moment(b[sorting.field]).format('YYYYMMDD')))
      // } else if (sorting.field && sorting.field !== 'noOfStyles' && sorting.field !== 'recievedOn') {
      //     const reversed = sorting.order === "asc" ? 1 : -1;
      //     computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field]).toString().localeCompare((b[sorting.field]).toString()));
      // }
      // Sorting comments
      if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
      }
      // Current Page slice
      let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
      if (records.length === 0 && currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
      return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }
  }, [
    comments, currentPage, search, sorting, ITEMS_PER_PAGE]);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const headers = [
    { name: "#", field: "", sortable: false },
    { name: "Buyer", field: "buyerName", sortable: true },
    { name: "Brand", field: "brandName", sortable: true },
    { name: "Season", field: "seasonName", sortable: true },
    {
      name: "Action", field: "", sortable: false,
      display: activeMenu[0].isView === 0 ? 'none' : ''
    },
  ];

  // Actions
  const pageRedirect = (Params, redirect, page) => {
    localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
    if (redirect === 'View') {
      // Params.action = "View";
      props.location.state = { params: Params };
      //D: \GitHub_Source\ICube_React\src\components\SamplingOrder\LabDip\LabDipDetailsView\SamGenlLabDipView.jsx
      props.history.push(`/SamplesView`, { params: Params });
    }

  };



  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const Delcallback = (value) => {
    // if (value === true) {
    //     PurchaseOrderService.GetPurchaseOrderList(0).then((response) => {
    //         if (response.data) {
    //             setComments(response.data);
    //         }
    //     });
    // }
    setID({ showPopupDelete: false, Params: [] });
  }





  return (
    <>
      <div className="page-body">
        <ReactNotification />
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="widget">
              <div className="widget-header ">
                <span className="widget-caption">Samples Information</span>

              </div>
              <div>
                {
                  isOpen && (
                    <Lightbox mainSrc={isOpen} alt="bg image" onCloseRequest={() => setIsOpen(false)} />
                  )
                }
              </div>
              <div className="widget-body">
                <div className="dataTables_wrapper form-inline no-footer">
                  <div className="table-toolbar pull-left">
                    <Search onSearch={value => { setSearch(value); setCurrentPage(1); }} placeholder={getPlaceholder} props={props} />
                  </div>
                  {/* <div className="table-toolbar pull-right">
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)}>
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div> */}
                  {


                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                      <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                      <tbody>
                        {
                          commentsData && commentsData.map((comment,i)=> (
                            <Fragment key={i}>
                            <tr>
                            <td style={{width:'25px'}}>  
                                                        { i + 1}
                                                    </td>
                              <td>{comment.buyerName}</td>
                              <td>{comment.brandName}</td>
                              <td>{comment.seasonName}</td>



                              <td style={{ display: activeMenu[0].isView === 0 ? 'none' : '' }}>
                                {/* <span title='Edit Order Labdip' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-success btn-xs edit">
                                                                    <i className="fa fa-edit "></i>
                                                                </span> */}
                                &nbsp;
                                <span style={{ display: activeMenu[0].isView === 1 ? '' : 'none' }} title='View Samples Information' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                  className="btn btn-white btn-xs eye">
                                  <i className="fa fa-eye "></i>
                                </span>
                                &nbsp;
                                {/* <span title='Delete Order Labdip' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span> */}
                              </td>
                            </tr>
                            </Fragment>
                          ))
                        }

                        {
                          totalItems === 0 ?
                            <tr>
                              <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                            </tr>
                            : ''
                        }
                      </tbody>
                    </table>
                  }

                  <PageCount onPageCount={
                    value => {
                      setITEMS_PER_PAGE(value);
                      setCurrentPage(1);
                    }
                  } />
                  <Pagination total={totalItems}
                    itemsPerPage={
                      parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                      page => setCurrentPage(page)
                    } />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader} </>
  )
}

export default ProtoFitSmsList