import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetGeneralLapdipApprovalList, clearLapdipApprovalSubRow, GetGeneralLapdipApprovalViewList, GetGeneralLapdipSubmissionApprovalList } from "../../../../../actions/generalLabdip";
import { loadSupplierList } from "../../../../../actions/tna";
import CommonService from "../../../../../services/Common/CommonService";
import Nodify from "../../../../Common/ReactNotification"
import SweetAlertPopup from '../../../../Common/SweetAlertPopup';
import { GenLabdipSubmissionlId, GenManualCloseChildLibDip, GenManualSubCloseChildLibDip } from "../../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../../services/General/GeneralService";
import input from "react-validation/build/input";
import { TableHeader, Pagination, Search, PageCount } from "../../../../Datatable";
import SweetAlert from 'react-bootstrap-sweetalert';
import FabricService from "../../../../../services/Master/FabricService";
const LabDipViewSubmissionTab = (props) => {

    //declaration
    const dispatch = useDispatch();

    const { user: currentUser } = useSelector((state) => state.auth);

    const [loader, showLoader, hideLoader] = useFullPageLoader();
debugger
    //reducer
    const labdipApprovalList = useSelector((state) => state.generalLabdip.generalLabdipApprovalList);


    //const labdipSubmissionParams = useSelector((state) => state.generalLabdip.generalLabdipViewDetailParams);
    let isLoadingGeneralLabdip = useSelector((state) => state.generalLabdip.isLoadingGeneralLabdip);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    let GLDRequestStatusList = useSelector((state) => state.generalLabdip.GLDRequestStatusList);
    const genLapdibTNAHolidayList = useSelector((state) => state.generalLabdip.genLapdibTNAHolidayList);
    //state

    const [getID, setID] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currpantoneIdOrSwatchId: "" });
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    const [showManual, setshowManual] = useState(false);
    const [showSubmitManual, setSubmitshowManual] = useState(false);
    const [showApproval, setshowApproval] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', operation: 0 }]);
    const [ApprovalManualInfo, setApprovalManualInfo] = useState([{ approvalManualCloseDate: '', approvalManualCloseComment: '' }]);
    const [getSearch, setSearch] = useState("");
    const [showMultiClosediv, setshowMultiClosediv] = useState();
    const [showApprovaldiv, setshowApprovaldiv] = useState();
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [deleteOrderinfo, setdeleteOrderinfo] = useState([]);
    const [deletepopup, setdeletepopup] = useState(false);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const [getsID, setsID] = useState(0);
    const [minApprovalDate, setMinApprovalDate] = useState({});
    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmitDate: '',
        SubmitDateEdit: '',
        SendTo: "", SendToEdit: '', SendToDisplay: '',
        SendThrough: 0,
        EndCustomer: "", IsAllFabricLoad: 0,
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralLabdipTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNALabdibSubmittedOption: []
    }]);
    const [emptyfeilds, setemptyfeilds] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmitDate: '',
        SubmitDateEdit: '',
        SendTo: "", SendToEdit: '',
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        PantoneID: 0, PantoneCode: "", RefColorName: "",
        PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
        FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralLabdipTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNALabdibSubmittedOption: []
    }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const values = [...inputFields]
    const [showawb, setshowawb] = useState(false);
    const [getTaskholder, setTaskholder] = useState([]);
    const [ShowPopup, setShowPopup] = useState(false);
    const [FabricDetails, setFabricDetails] = useState([]);
    const [getindexLabdip, setindexLabdip] = useState({ index: 0, GenlabdipID: 0, ID: 0 });
    const [ApprovalList, setApprovalList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [getheader, setheader] = useState();
    const [submittedoption, setsubmittedoption] = useState([]);
    const [firstsubapprid, setfirstsubapprid] = useState({
        SubApprId: 0,
        GenLapdipProgramInfoId: 0

    });
    const [tab, setTab] = useState(0);
    const [showSubmission, setshowSubmission] = useState(false);
    const [View, setView] = useState(false);


    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },
        // { name: "Action", field: "", sortable: false },
    ];
    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 0,
        SupplierId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });
    // let activeMenu;
    // let activeMenuSub;
    // let LapDipSubmissionTab;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     activeMenu = menulist.filter(i => i.menuName === 'LabDip');
    //     if (activeMenu.length > 0) {
    //         activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);


    //         if (activeMenuSub.length > 0) {

    //             for (let index = 0; index < activeMenuSub.length; index++) {
    //                 if (activeMenuSub[index].menuName === "GeneralLabdipSubmission") {
    //                     LapDipSubmissionTab = activeMenuSub[index];
    //                 }
    //             }
    //         }
    //     }
    // }


    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
    let info;
    let activeMenu;
    let activeMenuSub;
    let LapDipSubmissionTab;
    

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
        info = IsProduction === 1 ? 'LabDip' : 'ProductionLabDip';
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                 if (menuName === getMenuName("GeneralLabdipSubmission")) {
                    LapDipSubmissionTab = menuItem;
                    }   }
            }
        }
    }


    useEffect(() => {

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //searchLabDip();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };


    }, []);

    const SearchReqValues = { ...getSearchReqValues };

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, approvalStatus: 0
    });

    const [TaskDetails, setTaskDetails] = useState([]);
    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState({ SubmissionComment: '', ApprovalComment: '' });
    const [SubmitReschedule, setSubmitReschedule] = useState(false);
    const [openReschedulepopup, setopenReschedulepopup] = useState(false);

    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]

    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }
    let styles1s = {
        control: styles1 => ({ ...styles1, width: "230px" }),
    }

    let styles2s = {
        control: styles2 => ({ ...styles2, width: "230px", border: '1px solid red' }),
    }
    var ImgClassName = "upload-btn-wrapper2"
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);

    const pageRedirect = (SubmissionParams) => {
        let Param = { Operation: 44, Id: SubmissionParams.genLapdipProgramInfoId };
        CommonService.CheckMappedItem(Param).then(res => {
            if (res.data.outputResult === "-2") {
                let message = "This Submission is already mapped.";
                Nodify('warning', 'warning', message);
            }
            else {
                let StartDate = null;
                let EndDate = null;
                if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                    StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
                }
                if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                    EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
                }

                let searchParams = {
                    BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
                    SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                    IsProduction: IsProduction
                };


                setID({ showPopupDelete: true, Params: SubmissionParams, SearchParams: searchParams });
            }
        });

    };
    useEffect(() => {
        if (!isLoadingGeneralLabdip) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralLabdip])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchLabDip();

    }, [])

    const TabChange = (val) => {
        setTab(val);

    }

    //#region  ------------------  search details --------------------------------
    const searchLabDip = () => {

        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralLapdipApprovalList(params));

        // let params = {
        //     BuyerId: labdipViewDetailParams.BuyerId, BrandId: labdipViewDetailParams.BrandId, SeasonId: labdipViewDetailParams.SeasonId,
        //     BuyerName: labdipViewDetailParams.BuyerName, BrandName: labdipViewDetailParams.BrandName, SeasonName: labdipViewDetailParams.SeasonName,
        //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
        // };
        // dispatch(getGeneralLapdipDetailsViewList(params));
        commonParentExpand();
    }

    const resetLabDip = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 0;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        setSearchReqValues(SearchReqValues);
        searchLabDip();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = inputText;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------


    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, pantoneIdOrSwatchId) => {
        let btnToggle = document.getElementById("btn-toggle2_" + index);
        let iconToggle = document.getElementById("icon-toggle2_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");

            setcurrentExpand({ currindex: index, currpantoneIdOrSwatchId: pantoneIdOrSwatchId });
            // searchText();
            // dispatch(GetGeneralLapdipSubmissionApprovalList(params));
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }
            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: pantoneIdOrSwatchId, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 2, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };

            dispatch(GetGeneralLapdipApprovalList(params));

        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (pantoneIdOrSwatchId) {
                dispatch(clearLapdipApprovalSubRow(index));
            }
        }
    };

    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandSubmitTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandSubmitTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }



    const Delcallback = (value) => {

        searchLabDip();
        let btnToggle = document.getElementById("btn-toggle_" + currentExpand.currindex);
        let iconToggle = document.getElementById("icon-toggle_" + currentExpand.currindex);
        btnToggle.setAttribute('aria-expanded', 'false');
        iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        setID({ showPopupDelete: false, Params: [] });
    }

    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: '',
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 2,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };

        let approvalviewparams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: "",
            //SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };

        dispatch(GetGeneralLapdipApprovalList(params));
        dispatch(GetGeneralLapdipApprovalViewList(approvalviewparams));
    }

    const ReloadreqTab = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 1, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId,
            StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralLapdipSubmissionApprovalList(params));
    }
    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
            isShow: false
        }
    ]);


    const handledelete = () => {
       
        let Value = [];
        let Params = {
            Operation: 88,
            Id: ChildId.ChildId,
        };
        CommonService.CheckMappedItem(Params).then(res => {
            if (res.data.outputResult === "-2") {
                // setchkDataExists(true);
                let Title = "Warning!";
                let message = "Approval exists against this Submission.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setSubmitshowManual(true);
                // Value.TNAId = props.location.state.params.tnaid;
                Value.Id = ChildId.ChildId;
                setsID({ TNAManualClose: GenManualSubCloseChildLibDip, showPopupDelete: true, Params: Value });
            }
        })


    }

    const handleCloseManualPopup = () => {
        setshowManual(false);
    }
    const handleManualclose = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId) => {
        showLoader();
        let Operation;
        setComment(false);
        ApprovalManualInfo[0].approvalManualCloseComment = ''
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Manual Close');

        }
        else {
            Operation = 4;
            setheader('View Manual Close');

        }
        GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                if (response.data) {
                    let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                        if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });
                    setApprovalManualInfo(data);
                    setMultiCloseDatas(data);
                    setshowManual(true);
                    hideLoader();
                } else {
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });


    }

    const ViewSubmittedManualClose = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId) => {
       
        setheader('View Manual Close');
        showLoader();
        setChildId({ ChildId: GenLapdipProgramInfoId, Status: 0 });
        GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, PantoneId, 3, GenLapdipProgramInfoId).then((response) => {
                if (response.data) {
                    let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                        if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });

                    let getDateValue = new Date(data[0].manualCloseDate);
                    var dd = getDateValue.getDate();
                    var mm = getDateValue.getMonth() + 1;
                    var yyyy = getDateValue.getFullYear();

                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                    let coverted = dd + '/' + mm + '/' + yyyy;
                    data[0].manualCloseDate = coverted
                    // let isSelectAll = 0;
                    // if (data) {
                    //     isSelectAll = !!!data.find(d => d.isSelected === 0);
                    //     isSelectAll = isSelectAll ? 1 : 0;
                    // }
                    // setselectall(isSelectAll);
                    setSubmitshowManual(true);
                    setManualInfo(data);
                    setMultiCloseDatas(data);
                    hideLoader();

                } else {
                    hideLoader();
                }
            }).catch(() => { setSubmitshowManual(true); hideLoader(); });


    }

    const handleChangeIsManualCloseComments = (e, feild, Id) => {
        let Manual = [...ApprovalManualInfo];

        let input = '';

        if (feild === "Comment") {
            if (e.target.value.trim() !== '') {
                input = e.target.value;
            }
            Manual[0].approvalManualCloseComment = input;
        }
        else {
            Manual[0].approvalManualCloseDate = e;
        }
        setApprovalManualInfo(Manual);

    }

    const SaveChildManulaCloseComments = () => {

        if ((!ApprovalManualInfo[0].approvalManualCloseComment) || (!ApprovalManualInfo[0].approvalManualCloseDate)) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.genralLabdipTNAProgramDetailId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.genralLabdipTNAProgramDetailId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            } else {
                var ManualCloseData = [...ApprovalManualInfo];

                ManualCloseData[0].ManualCloseDate = convertUTCDateToLocalDate(new Date(ManualCloseData[0].approvalManualCloseDate));
                //ManualCloseData[0].ManualCloseDate.setMinutes(ManualCloseData[0].approvalManualCloseDate.getMinutes() + 370);

                // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData[0].manualCloseDate.getMinutes() + 370);
                ManualCloseData[0].ManualCloseComment = ManualCloseData[0].approvalManualCloseComment;
                ManualCloseData[0].IdList = strValue;
                ManualCloseData[0].Operation = 2;
                GeneralService.UpdateGeneralLabdipManualInfo(ManualCloseData[0]).then((res) => {

                    if (res.data.outputResult === "1") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Approval");
                        setshowManual(false);
                        setSubmitshowManual(false);
                        //searchText();

                        // handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                        ReloadApproval();
                        searchLabDip();
                    } else if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', "Manual Comment Added Successfully for Approval");
                        setshowManual(false);
                        setSubmitshowManual(false);
                        // searchText();
                        // searchLabDip();
                        //handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                        ReloadApproval();
                        searchLabDip();
                    } else if (res.data.outputResult === "-2") {
                        Nodify('Warning!', 'warning', "Error Occured");
                    }
                })
            }

        }

    }



    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }
            setselectall(isSelectAll);

            setMultiCloseDatas(value);
        }

    }

    const AddRows = () => {

        let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            setOrderinfo(FileteredRecords);
        }
    }

    const ViewSubmission = async (Id, SubmitStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index, Type) => {
        setTab(0);
        setInputFields(emptyfeilds);
        setindexLabdip({ index: index, GenlabdipID: GenLabdipId, ID: Id })
        let Operation;
        Operation = 3;
        if (Type === 'View') { setView(true) }
        else { setView(false) }
        setheader('View Manual Close');

        // GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
        //     if (response.data) {
        //         setFabricDetails(response.data);
        //     }
        // }).catch(() => { });
        GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        //  var Main = labdipSubmissionList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        var Sub = labdipApprovalList[index].pantoneSubList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genralLabdipTNASubmissionAndApprovalId === props.genralLabdipTNASubmissionAndApprovalId) {
                element.isSelected = 1;
            }
        })
       
        //clear and fetch new while popup open
        values[0].SubmittedDateEdit = '';
        values[0].SendTo = '';
        values[0].SendThrough = 0;
        values[0].SwatchCardImageName = '';
        values[0].SwatchCardIamgePath = '';
        values[0].Remarks = '';
        values[0].AWBDateandNo = '';
        values[0].AWBDate = '';
        values[0].AWBName = '';
        values[0].AWBDateEdit = '';
        setshowawb(false);



        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].PantoneCode = Sub[0].pantoneCode;
        values[0].RefColorName = Sub[0].pantoneReferColorName;
        values[0].PantoneID = Sub[0].pantoneID;
        values[0].PantoneNameOrSwatchName = Sub[0].pantoneNameOrSwatchName;
        values[0].PantoneColorOrOrSwatchImage = Sub[0].pantoneColorOrOrSwatchImage;
        values[0].FabricID = Sub[0].fabricID;
        if (Sub[0].isAllFabricLoad === 1) {
            // if (Type === 'View') {
            //     await FabricService.GetFabricList(Sub[0].fabricID, "F").then((response) => {
            //        ;
            //         if (response.data) {
            //             setFabricDetails(response.data)
            //         }
            //     })
            // } else {
            await FabricService.GetFabricList(0, "F").then((response) => {
               ;
                if (response.data) {
                    setFabricDetails(response.data)
                }
            })
            // }
        }
        else if (Sub[0].isAllFabricLoad === null || Sub[0].isAllFabricLoad === 0) {
            GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
                if (response.data) {
                    setFabricDetails(response.data);
                }
            }).catch(() => { });
        }
        values[0].IsAllFabricLoad = Sub[0].isAllFabricLoad;
        values[0].PantoneTypeName = Sub[0].pantoneTypeName;
        values[0].GenralLabdipTNASubmissionAndApprovalId = Sub[0].genralLabdipTNASubmissionAndApprovalId;
        values[0].SubmitStatus = Sub[0].submitStatus;
        values[0].SubmittedDate = new Date(Sub[0].submitDate)
        //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
        let getDateValue = new Date(Sub[0].submitDate);
        var dd = getDateValue.getDate();
        var mm = getDateValue.getMonth() + 1;
        var yyyy = getDateValue.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = yyyy + '-' + mm + '-' + dd;
        values[0].SubmittedDateEdit = getDateValue;
        values[0].SendTo = Sub[0].sendTo;
        values[0].SendToDisplay = Sub[0].sendToNames;
        values[0].SendToEdit = Sub[0].sendToNames;
        if (Type === 'Edit') {
            let arr = Sub[0].sendTo.split(',');
            let arrVal = [];
            for (let size of arr) {
                GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((res) => {
                    if (res.data) {
                        let sendtoId = res.data.filter(x => x.value === parseInt(size))
                        arrVal.push({
                            value: sendtoId[0].value,
                            label: sendtoId[0].label
                        })
                        values[0].SendToEdit = arrVal;
                        //setInputFields(values)
                    }
                    //setInputFields(values)
                }).catch(() => { });

            }
        }
        values[0].SendThrough = Sub[0].sendThrough;
        values[0].SwatchCardImageName = Sub[0].swatchCardImageName;
        values[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
        values[0].Remarks = Sub[0].remarks;

        if (parseInt(Sub[0].sendThrough) === 2) {
            setshowawb(true);
            let getDate = new Date(Sub[0].awbDate);
            let dd = getDate.getDate();
            let mm = getDate.getMonth() + 1;
            let yyyy = getDate.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let coverted = dd + '/' + mm + '/' + yyyy;
            values[0].AWBDateandNo = Sub[0].awbDate === null ? '' : coverted + ' / ' + Sub[0].awbName;
            values[0].AWBDate = new Date(Sub[0].awbDate)
            values[0].AWBName = Sub[0].awbName;

            values[0].AWBDateEdit = getDate;
        }
        GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                if (response.data.generalLabdipPantoneSubInfo) {
                    let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                        if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });

                    setOrderinfo(data);

                    var optios = [];
                    //
                    response.data.generalTNALabdibSubmittedOption.forEach(element => {
                        optios.push({

                            GeneralLabdibSubmittedOptionId: element.generalLabdibSubmittedOptionId,
                            GenralLabdipTNASubmissionAndApprovalId: element.genralLabdipTNASubmissionAndApprovalId,
                            GenLapdipProgramInfoId: element.genLapdipProgramInfoId,
                            OptionName: element.optionName,
                            SupplierReferenceNo: element.supplierReferenceNo
                        })
                    })

                    setfirstsubapprid(
                        {
                            SubApprId: response.data.generalTNALabdibSubmittedOption[0].genralLabdipTNASubmissionAndApprovalId,
                            GenLapdipProgramInfoId: response.data.generalTNALabdibSubmittedOption[0].genLapdipProgramInfoId

                        })
                    setsubmittedoption(optios);
                    setshowSubmission(true);
                }
            }).catch(() => { });

        setInputFields(values);
    }

    const OpenLabdipApprovalpopup = async (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index) => {
        setTab(0);
        setSubmitted(false);

        let Optionvalue = [];
        let Operation;

        values[0].ApprovalDate = '';
        values[0].ApprovalRemark = '';
        if (approvalStatus === "Pending") {
            Operation = 2;
            /// setshowMultiClosediv(true);
            // setshowApprovaldiv(false);

            setshowMultiClosediv(false);
            setshowApprovaldiv(true);

            setheader('Manual Close');
        }
        else {
            Operation = 4;
            setshowMultiClosediv(false);
            setshowApprovaldiv(true);
            setheader('View Manual Close');

        }
        ApprovedOption.forEach(element => {
            let LabelColor = '';
            if (element.value === 'Approved') {
                LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
            }
            else if (element.value === 'Approved with Comments') {
                LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
            }
            else if (element.value === 'Rejected') {
                LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
            }
            else if (element.value === 'Hold') {
                LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
            }


            Optionvalue.push({
                value: element.key,
                label: LabelColor,
                subLabel: element.value,
            })
        });

        setApprovedOptions(Optionvalue);
        // GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
        //     if (response.data) {
        //         setFabricDetails(response.data);
        //     }
        // }).catch(() => { });
        GeneralService.LoadGeneralTNAApprovalSubmissionTaskHolderList(GenLabdipId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });

        // var Main = labdipApprovalList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        var Sub = labdipApprovalList[index].pantoneSubList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genralLabdipTNASubmissionAndApprovalId === props.genralLabdipTNASubmissionAndApprovalId) {
                element.isSelected = 1;
            }
        })
       
        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].PantoneCode = Sub[0].pantoneCode;
        values[0].RefColorName = Sub[0].pantoneReferColorName;
        values[0].PantoneID = Sub[0].pantoneID;
        values[0].PantoneNameOrSwatchName = Sub[0].pantoneNameOrSwatchName;
        values[0].PantoneColorOrOrSwatchImage = Sub[0].pantoneColorOrOrSwatchImage;
        if (Sub[0].isAllFabricLoad === 1) {
            await FabricService.GetFabricList(0, "F").then((response) => {
               ;
                if (response.data) {
                    setFabricDetails(response.data)
                }
            })
        } else if (Sub[0].isAllFabricLoad === 0 || Sub[0].isAllFabricLoad === null || Sub[0].isAllFabricLoad === undefined) {
            GeneralService.LoadGeneralLabdipFabricDetailsList(GenLabdipId).then((response) => {
                if (response.data) {
                    setFabricDetails(response.data);
                }
            }).catch(() => { });
        }
        values[0].FabricID = Sub[0].fabricID;
        values[0].PantoneTypeName = Sub[0].pantoneTypeName;
        values[0].GenralLabdipTNASubmissionAndApprovalId = Sub[0].genralLabdipTNASubmissionAndApprovalId;
        values[0].ApprovalStatus = Sub[0].approvalStatus;
        values[0].SubmitStatus = Sub[0].submitStatus;
        values[0].Remarks = Sub[0].remarks;

        let getDate = new Date(Sub[0].awbDate);
        let dd = getDate.getDate();
        let mm = getDate.getMonth() + 1;
        let yyyy = getDate.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = dd + '/' + mm + '/' + yyyy;




        let awbdate = Sub[0].awbDate === null ? '' : coverted;
        values[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
        values[0].SendTo = Sub[0].sentTo;
        values[0].SubmittedDate = new Date(Sub[0].submitDate)
        //setMinApprovalDate((moment(values[0].SubmittedDate).format("YYYY-MM-DD")).toString());
        values[0].SwatchCardImageName = Sub[0].swatchCardImageName;
        values[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;
        if (Sub[0].awbName !== '') {
            setshowawb(true);
            values[0].AWBDateandNo = Sub[0].awbDate + ' / ' + Sub[0].awbName;
        }
        if (approvalStatus !== "Completed") {
            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
                TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });
                        let isSelectAll = 0;
                        if (data) {
                            isSelectAll = !!!data.find(d => d.isSelected === 0);
                            isSelectAll = isSelectAll ? 1 : 0;
                        }
                        setselectall(isSelectAll);
                        // setMultiCloseDatas(data);
                        setOrderinfo([]);
                        setOrderinfo(data);
                        setsubmittedoption(response.data.generalTNALabdibSubmittedOption);
                        setshowApproval(true);

                    }
                }).catch(() => { });
        }
        else {
            values[0].ApprovalDate = new Date(Sub[0].approvalDate)
            values[0].ApprovalRemark = Sub[0].approvalRemark;

            GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
                TaskId, PantoneId, Operation, GenLapdipProgramInfoId).then((response) => {
                    if (response.data.generalLabdipPantoneSubInfo) {
                        let data = response.data.generalLabdipPantoneSubInfo.map((item) => {

                            if (item.genralLabdipTNASubmissionAndApprovalId === Id) {
                                item.isSelected = 1;

                                return item;
                            } else {
                                return item;
                            }
                        });

                        setOrderinfo(data);

                        setsubmittedoption(response.data.generalTNALabdibSubmittedOption);
                        setshowApproval(true);
                    }
                }).catch(() => { });
        }


    }

    const OpenDeletPopup = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, PantoneId, GenLabdipId, GenLapdipProgramInfoId, index) => {

        GeneralService.GetGeneralLapdibSubmissionandOrderInfoandManualList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, PantoneId, 5, GenLapdipProgramInfoId).then((response) => {
                if (response.data.generalLabdipPantoneSubInfo) {
                    setdeleteOrderinfo(response.data.generalLabdipPantoneSubInfo);
                    setdeletepopup(true);
                }
            }).catch(() => { });

    }

    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            searchLabDip();
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setSubmitshowManual(true);
        }

    }

    const DeleteMultipleApproval = () => {
        var data = { Operation: 1, GeneralTNALabdibOrderInfo: deleteOrderinfo }

        GeneralService.DeleteMultipleGenLabdipApproval(data).then(res => {
            if (res.data.outputResult === "1") {
                Nodify('Success!', 'success', 'Lab Dib Submission Deleted Successfully.');
                setdeleteOrderinfo([]);
                setdeletepopup(false);
                handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                ReloadApproval();
            }
            else if (res.data.outputResult === "0") {
                Nodify("Error!", "Error Occured!");
            }
        });
    }


    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowApprovaldiv(false);
    }
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseDate ? '' : '1px solid red' }}
            />
        )
    }
    // const searchText = () => {
    //
    //     let params = {
    //         BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
    //         SearchText: (getSearch && getSearch.trim()), Operation: 2
    //     };

    //     dispatch(GetGeneralLapdipApprovalList(params));
    // }
    function ontransfer(event, FabricID) {
       
        setFabricDetails([]);
        if (event.target.checked === true) {
            values[0].IsAllFabricLoad = 1;
            FabricService.GetFabricList(0, "F").then((response) => {
               ;
                if (response.data) {
                    setFabricDetails(response.data)
                }
            })
        }
        if (event.target.checked === false) {
            values[0].IsAllFabricLoad = 0;
            //values[0].FabricID = "0";
            GeneralService.LoadGeneralLabdipFabricDetailsList(getindexLabdip.GenlabdipID).then((response) => {
                if (response.data) {
                    setFabricDetails(response.data);
                }
            }).catch(() => { });
            var Sub = labdipApprovalList[getindexLabdip.index].pantoneSubList.filter(x => x.genralLabdipTNASubmissionAndApprovalId === getindexLabdip.ID);
            values[0].FabricID = Sub[0].fabricID;
        }
        setInputFields(values)
    }
    const handleChange = (event, feild) => {
        let input = '';

        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date should not lesser than Submit Date.");
                    values[0].ApprovalDate = '';
                }
                else {
                    values[0].ApprovalDate = event;
                }
            }
            else {
                values[0].ApprovalDate = ''
            }

        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].ApprovalRemark = input;
        }
        else if (feild === "FabricID") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].FabricID = parseInt(input);
        }
        setInputFields(values);
    }
    const handlemultidd = (event, index) => {

        if (event.length === 0) {
            values[0].SendTo = "";
            values[0].SendToEdit = '';
            // values[index].SkuColorNameIds = "";
        } else {
            let strValue = "";
            for (let Sku of event) {
                if (strValue === "") {
                    strValue = Sku.value.toString();
                } else {
                    strValue = strValue + "," + Sku.value;
                }
            }
            values[0].SendTo = strValue;
            values[0].SendToEdit = event;
        }

        setInputFields(values);
    }
    const SaveSubmission = () => {
       ;
        let CheckSubmittedOption = true;

        submittedoption.forEach(element => {
            if (element.OptionName === '' || element.SupplierReferenceNo === '') {
                CheckSubmittedOption = false;
            }
        })
        if (parseInt(values[0].SendThrough) === 1) {
            values[0].AWBName = '';
            values[0].AWBDate = ''
        }
        if (values[0].SubmittedDate === '' || values[0].SendTo === "" || values[0].SendThrough === 0 ||
            values[0].FabricID === "0") {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmittedpopup(true);
            // setSubmitted(true);
        }
        else if ((parseInt(values[0].SendThrough) === 2 && values[0].AWBDateandNo === '')) {
            Nodify("Warning!", "warning", "Please fill Awb Name & Date.");
        }
        else if (CheckSubmittedOption === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
            setSubmitted(true);
        }

        else {
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            //values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            if (values[0].AWBDate !== '') {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }
            values[0].Operation = 1;
            values[0].GenLapdipProgramInfoId = firstsubapprid.GenLapdipProgramInfoId;
            values[0].GeneralTNALabdibOrderInfo = getOrderinfo;
            values[0].GeneralTNALabdibSubmittedOption = [];
            // getOrderinfo.map(ordinf => {
            submittedoption.map(opt => {
                values[0].GeneralTNALabdibSubmittedOption.push({
                    GeneralLabdibSubmittedOptionId: opt.GeneralLabdibSubmittedOptionId,
                    GenralLabdipTNASubmissionAndApprovalId: firstsubapprid.SubApprId,
                    GenLapdipProgramInfoId: firstsubapprid.GenLapdipProgramInfoId,
                    OptionName: opt.OptionName,
                    SupplierReferenceNo: opt.SupplierReferenceNo

                })
            })
            // })

            GeneralService.InsertUpdateGeneralLabdipSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowSubmission(false);

                    //handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                    ReloadApproval();
                    searchLabDip();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Submission Updated Successfully');
                    setshowSubmission(false);
                    //searchLabDip();
                    //handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                    ReloadApproval();
                    searchLabDip();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const handleChangeSubmit = (event, feild) => {
        let input = '';

        if (feild === "SubmittedDate") {
            values[0].SubmittedDate = event;
            values[0].SubmittedDateEdit = event;

        }
        else if (feild === "Sendthrough") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].SendThrough = parseInt(input);
            if (input === "2") {
                setshowawb(true);
            }
            else {
                setshowawb(false);
            }
        }
        else if (feild === "SendTo") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].SendTo = input;
        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].Remarks = input;
        }
        else if (feild === "FabricID") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].FabricID = input;
        }
        setInputFields(values);
    }



    const OnChangeOrderinfo = (event, index) => {

        const orderinfo = [...getOrderinfo];
        let SelectedCount = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedCount = SelectedCount + 1;
            }
        })
        if (event.target.checked === true) {
            if (SelectedCount === 1) {
                orderinfo[index].SelectedDetail = 0;
                Nodify('Warning!', 'warning', 'Please select only one.');
            }
            else {
                orderinfo[index].selectedDetail = 1;
            }
        }
        else {
            orderinfo[index].selectedDetail = 0;
        }
        setOrderinfo(orderinfo);

        let SelectedDetail = '';
        let SelectedGenLabdipId = 0;
        orderinfo.forEach(element => {
            if (element.selectedDetail === 1) {
                SelectedDetail = element.fabricID;
                SelectedGenLabdipId = element.genLabdipId;
            }
        })

        GeneralService.LoadGeneralLabdipFabricDetailsList(SelectedGenLabdipId).then((response) => {
            if (response.data) {
                setFabricDetails(response.data);
            }
        }).catch(() => { });

        values[0].FabricID = SelectedDetail;

        setInputFields(values);
    }

    const handleRemoveOrderInfo = (index) => {
        const value = [...getOrderinfo]
        value.splice(index, 1);
        setOrderinfo(value);

    }

    const SaveApproval = () => {

        let IsValid = true;
        if (values[0].ApprovalDate === '' || values[0].ApprovalDate === null) {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== null &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            if (values[0].AWBDate !== '') {
                values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
            }

            values[0].Operation = 2;
            values[0].GeneralTNALabdibOrderInfo = getOrderinfo;
            values[0].GeneralTNALabdibSubmittedOption = [];
            getOrderinfo.map(ordinf => {
                submittedoption.map(opt => {
                    values[0].GeneralTNALabdibSubmittedOption.push({
                        GeneralLabdibSubmittedOptionId: 0,
                        GenralLabdipTNASubmissionAndApprovalId: ordinf.genralLabdipTNASubmissionAndApprovalId,
                        GenLapdipProgramInfoId: submittedoption[0].genLapdipProgramInfoId,
                        OptionName: opt.optionName,
                        SupplierReferenceNo: opt.supplierReferenceNo,
                        ApprovedOptionName: opt.approvedOptionName
                    })
                })
            })
            GeneralService.InsertUpdateGeneralLabdipSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Approval Added Successfully');
                    // searchLabDip();
                    setshowApproval(false);
                    // handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                    ReloadApproval();
                    searchLabDip();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    //  searchLabDip();
                    setshowApproval(false);
                    //  handleToggle(currentExpand.currindex, currentExpand.currpantoneIdOrSwatchId);
                    ReloadApproval();
                    searchLabDip();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }
    const handleRemoveFields = index => {
        submittedoption.splice(index, 1);
        setInputFields(values);
    };
    const handleAddField = (index) => {
        var isvalid = true;

        inputFields.forEach((element, index) => {
            if (submittedoption[index].OptionName === '' || submittedoption[index].SupplierReferenceNo === '') {
                isvalid = false;
            }
        })
        if (isvalid) {
            submittedoption.push({ OptionName: '', SupplierReferenceNo: '', GeneralLabdibSubmittedOptionId: 0 });
            setInputFields(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
            setSubmitted(true);
        }
    }

    const handleSubmitoptionchange = (event, feild, index, Taskname) => {
       
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                submittedoption[index].SupplierReferenceNo = event.target.value;
            }
            if (Taskname !== "Labdip Approval") {
                if (submittedoption[index].OptionName !== '' && submittedoption[index].SupplierReferenceNo !== '') {
                    let Existoption = submittedoption.filter(x =>
                        x.OptionName.toLowerCase() === submittedoption[index].OptionName.toLowerCase()
                        && x.SupplierReferenceNo === submittedoption[index].SupplierReferenceNo.toLowerCase())
                    if (Existoption.length > 1) {
                        submittedoption[index].SupplierReferenceNo = '';
                        setSubmitted(true);
                        Nodify('Warning!', 'warning', 'SupplierReferenceNo Already Exists.');
                    }
                }
            }
            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {
            SOValoes.forEach(element => {
                element.approvedOptionName = "";
            })
            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }

    const handlepopupchange = (event, feild) => {
        let input = '';

        if (feild === "AWBName") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].AWBName = input;
        }
        else if (feild === "AWBDate") {
            values[0].AWBDate = event;
            values[0].AWBDateEdit = event;

        }
        setInputFields(values);
    }

    const SaveAwbnoDate = () => {
        var isvalid = true;
        if (values[0].AWBName === '' || values[0].AWBDate === '' || values[0].AWBDate === null) {
            isvalid = false;
        }

        if (isvalid) {
            values[0].AWBDateandNo = values[0].AWBName + " / " + values[0].AWBDate.toLocaleDateString('en-GB');;
            setInputFields(values);
            setShowPopup(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill mandatory (*) fields in AWB Popup.');
            setSubmittedpopup(true);
        }

    }

    const CustomInputAWB = (props) => {
       ;
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submittedpopup && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000",
                    border:
                        submitted &&
                            props.value === ""
                            ? "1px solid red"
                            : "",
                }}
            />
        )
    }

    const CustomInputsubmit = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    border: submittedpopup && props.value === '' ? '1px solid red' : ''
                }}
            />
        )
    }



    //Reschedule functionalities

    const EndDateCustomInput = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            //style={{ border: submitdate && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }


    const OpenReschedulePopup = (genralLabdipTNAId, genralLabdipTNASubmissionAndApprovalId, genralLabdipTNAProgramDetailId) => {
      ;
        GeneralService.getTNATaskDetailsList(genralLabdipTNAId, genralLabdipTNAProgramDetailId).then((response) => {
            if (response.data) {
                setTaskDetails(response.data);
                    GeneralService.getTNATaskRescheduleList(genralLabdipTNAId, genralLabdipTNAProgramDetailId).then((response) => {
                    if (response.data) {
                       setSubRescheduleList(response.data);
                    }
                }).catch(() => { });

                setopenReschedulepopup(true);
            }
        }).catch(() => { });

        var cmtval = { ...RescheduleComment }
        cmtval.SubmissionComment = '';
        cmtval.ApprovalComment = '';
        setRescheduleComment(cmtval);
    }

    const handleChangeReschedule = (e, index, feild) => {
        var taskval = [...TaskDetails];
        var cmtval = { ...RescheduleComment }


        if (feild === "Duration") {
            taskval[index].duration = e.target.value;
        }
        else if (feild === "EndDate") {
            taskval[index].endDate = e;
        }
        else if (feild === "SubmissionComment") {
            cmtval.SubmissionComment = e.target.value;
        }
        if (feild === "ApprovalComment") {
            cmtval.ApprovalComment = e.target.value;
        }
        setTaskDetails(taskval);
        setRescheduleComment(cmtval);
        if (feild === "Duration" || feild === "EndDate") {
            datecalculation(index, feild)
        }
    }

    const handleSaveReschedule = () => {
       ;
        //if ((RescheduleComment.SubmissionComment === '' || RescheduleComment.ApprovalComment === '')) {
        if (RescheduleComment.SubmissionComment === '') {
            setSubmitReschedule(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
           
            var InsertData = [];

            TaskDetails.map((item, i) => {
                InsertData.push({
                    GenralLabdipTNAProgramDetailId: item.genralLabdipTNAProgramDetailId,
                    GenralLabdipTNASubmissionAndApprovalId: item.genralLabdipTNASubmissionAndApprovalId,
                    GenralLabdipTNAId: item.genralLabdipTNAId,
                    Comments: i === 0 ? RescheduleComment.SubmissionComment : RescheduleComment.ApprovalComment,
                    Createdby: currentUser.employeeinformationID,
                    StartDate: item.startDate,
                    EndDate: item.endDate,
                    Duration: parseInt(item.duration),
                    Type: i === 0 ? "Submission" : "Approval",
                })
            })

            let savecount = 0;
            InsertData.map(data => {
                GeneralService.InsertGenTNATaskReschedule(data).then((res) => {
                    if (res.data.outputResult === "1") {
                        savecount = savecount + 1;
                    }
                })
            })
            Nodify('Success!', 'success', "Reschedule Added Successfully");
            setopenReschedulepopup(false);
            ReloadApproval();
            searchLabDip();
            ReloadreqTab();
        }
    }


    function datecalculation(index, FieldName) {
       
        var taskval = [...TaskDetails];
        let employee1 = "";
        let employee2 = "";
        let employee3 = "";
        if (taskval[index].taskHolderOrFollowerName) {
            let TaskHolderName = taskval[index].taskHolderOrFollowerName.split('-');
            if (TaskHolderName[0] !== undefined) {
                employee1 = TaskHolderName[0].trim();

                if (TaskHolderName[1] !== undefined) {
                    let FollowerName = TaskHolderName[1].split(',');
                    if (FollowerName[0] !== undefined) {
                        employee2 = FollowerName[0].trim();

                        if (FollowerName[1] !== undefined) {
                            employee3 = FollowerName[1].trim();
                        } else {
                            employee3 = "";
                        }
                    } else {
                        employee2 = "";
                        employee3 = "";
                    }

                } else {
                    employee2 = "";
                    employee3 = "";
                }
            }
        }

        let Duration = taskval[index].duration;
        let StartDate = new Date(taskval[index].startDate);
        let EndDate = new Date(taskval[index].endDate);
        let LeaveDays = taskval[index].leaveDays;
        let titleEmployee1 = employee1;
        let total = 0;
        if (FieldName === "Duration") {
            EndDate = addDays(StartDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }
        }
        else {
            //based on end date
            let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            if (!isSameDay(new Date(EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
                EndDate = CheckDate;
            }
            StartDate = subDays(EndDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                StartDate = subDays(StartDate, 1);
                StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

            }
        }

        taskval[index].startDate = StartDate;
        taskval[index].endDate = EndDate;
        setTaskDetails(taskval);
    }



    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = genLapdibTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);

                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
       
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = genLapdibTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }
    // const MultiValueLabel = (props) => {
    //     const { innerProps, children, data } = props;
    //     return (
    //       <div title={data.name} {...innerProps}>
    //         {children}
    //       </div>
    //     );
    //   };

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                        {/* <div className="row">

                            <div className="col-sm-4">

                                <div className="form-group">
                                    <label >Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' : moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span>
                                </div>

                                {stateDate[0].isShow &&
                                    <DateRangePicker
                                        onChange={item => setStateDate([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}
                                    />
                                }
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor={"Supplier"}>Supplier
                                        <span className="text-danger">*</span></label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single " name="Supplier"
                                            id={
                                                "Supplier"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handlechange(event, 'Supplier')}
                                            value={getSupplierList.filter(function (option) {
                                                return option.value === Searchvalues.Supplier;

                                            })}
                                            options={getSupplierList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label htmlFor={"Status"}>Status<span className="text-danger">*</span></label>
                                    <select id={"Status"} name="Status" className="form-select"
                                        value={Searchvalues.approvalStatus}
                                        onChange={event => handlechange(event, 'approvalStatus')}>
                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Status- </option>
                                        <option value="1" key="1">Completed</option>
                                        <option value="2" key="2">Pending</option>
                                        <option value="3" key="3">All</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-1">
                                <label >&nbsp;</label>
                                <span className="input-icon icon-right">
                                    <button type="button" className="btn btn-success" title="Search" onClick={event => Filter()}

                                        disabled={Searchvalues.StartDate === 0 || Searchvalues.EndDate === 0 ||
                                            Searchvalues.SupplierId === 0 ? true : false}
                                    >
                                        <i className="fa fa-search"></i>

                                    </button>
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-danger" title="Reset"

                                    >
                                        <i className="fa fa-close"></i>

                                    </button>
                                </span>

                            </div>


                        </div> */}
                            <div className="col-sm-12">

                            <div className="col-md-2">
                                <div className='form-group'>
                                    <label>Pantone Code / Swatch Name </label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Pantone Code / Swatch Name" className="form-control" />
                                        {/* <span title='Search' onClick={() => searchText()}
                                        className="btn btn-danger btn-xs search"> */}
                                        {/* <i onClick={() => searchText()} className="fa fa-search"></i> */}
                                        {/* </span> */}
                                    </span>

                                </div>

                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="SupplierID">
                                        Supplier
                                        {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="SupplierId"
                                            id={
                                                "SupplierId"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'SupplierId')}
                                            value={getSupplierList.filter(function (option) {
                                                return option.value === SearchReqValues.SupplierId;

                                            })}
                                            options={getSupplierList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>

                                    </span>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="Status">
                                        Submit Status
                                    </label>
                                    <span className="input-icon icon-right">
                                        <Reactselect className="basic-single" name="Status"
                                            id={
                                                "Status"
                                            }
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={event => handleSearchChange(event, 'Status')}
                                            value={GLDRequestStatusList.filter(function (option) {
                                                return option.value === SearchReqValues.StatusId;
                                            })}
                                            options={GLDRequestStatusList}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>


                            <div className="col-md-2">

                                <div className="form-group">
                                    <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box will cause the date picker to appear and disappear">(Clicking on the text box...)</span> </label>
                                    <span className="input-icon icon-right">
                                        <input
                                            value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control'}
                                            placeholder="Select Date"
                                        />
                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                    </span>
                                </div>


                                {SearchReqValues.stateDate[0].isShow &&
                                    <DateRangePicker
                                        //onChange={item => setStateDate([item.selection])}
                                        onChange={item => handlePopupDateselection(item)}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={SearchReqValues.stateDate}
                                        showDateDisplay={false}
                                        direction="vertical"
                                        className={'TNAReportDatePicker'}

                                    />

                                }

                            </div>
                            <div className="col-md-2" style={{ paddingTop: '29px' }}>

                                <button type="button" className="btn btn-success" title="Search" onClick={event => searchLabDip()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-danger" title="Reset" onClick={resetLabDip}
                                >
                                    <i className="fa fa-close"></i>
                                </button>
                            </div>

                    </div>
                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" >
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Submitted Date</th>
                                        <th>Story</th>
                                        <th>Theme</th>
                                        <th>Supplier</th>
                                        <th>Item Details (Fabric / Yarn)</th>
                                        <th>Task Holder / Follower</th>
                                        <th>Approval Target</th>
                                        <th>Approval Status</th>
                                        <th>AWB & Date</th>
                                        <th style={{ width: "200px", paddingBottom: "2px" }} className="text-center fixed-column-header-Route" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        labdipApprovalList && labdipApprovalList.length > 0 ?
                                            <>
                                                {
                                                    labdipApprovalList && labdipApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle2_" + index} class="btn-toggle2 parentExpandSubmitTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.pantoneIdOrSwatchId)} aria-labelledby="btn-toggle2 id-master">
                                                                            <i id={"icon-toggle2_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandSubmitTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.pantoneCode
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan="10">
                                                                    {inputField.imagePath ?
                                                                        <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                            <img className="width_manual"
                                                                                src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath}
                                                                                alt="Logo"
                                                                                onClick={
                                                                                    () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputField.imagePath)
                                                                                }
                                                                            />
                                                                        </div> :
                                                                        <div style={{ background: inputField.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                                    }
                                                                </td>

                                                            </tr>
                                                            {

                                                                // inputField.generalLabdipMultiApprovalList && inputField.generalLabdipMultiApprovalList.map((subField, subIndex) => (
                                                                inputField.pantoneSubList && inputField.pantoneSubList.map((subField, subIndex) => (


                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"15px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.submitDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.storyName}>
                                                                                {
                                                                                    <span> {subField.storyName} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.themeName}>
                                                                                {
                                                                                    <span> {subField.themeName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.itemDetails} </span>
                                                                                }
                                                                            </td>
                                                                            <td className="cut_text" title={subField.taskHolderFollowerNames}>
                                                                                {
                                                                                    <span> {subField.taskHolderFollowerNames} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {subField.awbDate === null ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>



                                                                            <td className="fixed-column-Route">
                                                                                {


                                                                                    showManual === true ?
                                                                                        <Modal show={showManual === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, subIndex)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {getheader} : Lab Dip Approval &nbsp;

                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>

                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>Comments <span className="text-danger">*</span></th>
                                                                                                            <th>Close Date <span className="text-danger">*</span></th>

                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr>
                                                                                                            <td style={{ width: "600px" }} >
                                                                                                                <textarea
                                                                                                                    rows="3"
                                                                                                                    placeholder="Enter Comments here..."
                                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                                    maxLength="500" name="EnterRemarks"
                                                                                                                    id={"EnterRemarks"}
                                                                                                                    value={ApprovalManualInfo[0].approvalManualCloseComment}
                                                                                                                    onChange={event => handleChangeIsManualCloseComments(event, "Comment", subField.genralLabdipTNASubmissionAndApprovalId)}
                                                                                                                    //style={{ width: "100%", border: (!getComment) || (!ApprovalManualInfo[0].approvalManualCloseComment) ? '' : '1px solid red' }}
                                                                                                                    style={{ width: "100%", border: (!getComment) || ApprovalManualInfo[0].approvalManualCloseComment ? '' : '1px solid red' }}
                                                                                                                    disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                >

                                                                                                                </textarea>
                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {

                                                                                                                    subField.approvalStatus !== "Completed" ?
                                                                                                                        <DatePicker className="form-control" name="HolidayDate1"
                                                                                                                            id={
                                                                                                                                "HolidayDate1" + index
                                                                                                                            }
                                                                                                                            selected={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            onChange={event => handleChangeIsManualCloseComments(event, "CommentDate", subField.genralLabdipTNASubmissionAndApprovalId)}
                                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                                            peekNextMonth
                                                                                                                            showMonthDropdown
                                                                                                                            // showYearDropdown
                                                                                                                            dropdownMode="scroll"
                                                                                                                            autoComplete="off"
                                                                                                                            minDate={new Date()}
                                                                                                                            maxDate={new Date()}
                                                                                                                            customInput={<CustomInput />}

                                                                                                                            disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                        />

                                                                                                                        :

                                                                                                                        <input
                                                                                                                            placeholder="Enter the StoryName"
                                                                                                                            className="form-control"
                                                                                                                            title={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            name="StoryName"
                                                                                                                            id={"StoryName"}
                                                                                                                            value={ApprovalManualInfo[0].approvalManualCloseDate}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                }


                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>

                                                                                                </table>
                                                                                                {
                                                                                                    <table className="table table-bordered">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Story</th>
                                                                                                                <th>Theme</th>
                                                                                                                <th>Details </th>
                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                <th>
                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                            disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                MultiCloseDatas.length !== 0 ?
                                                                                                                    MultiCloseDatas.map((item, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                    <td>{item.itemDetails}</td>
                                                                                                                                    <td>{item.taskHolderFollowerNames}</td>

                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                    disabled={subField.approvalStatus === "Completed" ? true : false}
                                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                <span className="text"></span>
                                                                                                                                            </label>

                                                                                                                                        </div>

                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </>

                                                                                                                        )
                                                                                                                    })
                                                                                                                    :
                                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>


                                                                                                }

                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>
                                                                                                {
                                                                                                    subField.approvalStatus !== "Completed" &&
                                                                                                    <Button variant="success" onClick={() => SaveChildManulaCloseComments()}>
                                                                                                        Save
                                                                                                    </Button>


                                                                                                }


                                                                                            </Modal.Footer>
                                                                                        </Modal> : ''

                                                                                }

                                                                                {


                                                                                    showSubmitManual === true ?
                                                                                        <Modal show={showSubmitManual === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setSubmitshowManual(false)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {getheader} : Lab Dip Submission &nbsp;
                                                                                                    {
                                                                                                        <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>
                                                                                                    }
                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>

                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>Comments <span className="text-danger">*</span></th>
                                                                                                            <th>Close Date <span className="text-danger">*</span></th>

                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr>
                                                                                                            <td style={{ width: "600px" }} >
                                                                                                                <textarea
                                                                                                                    rows="3"
                                                                                                                    placeholder="Enter Comments here..."
                                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                                    maxLength="500" name="EnterRemarks"
                                                                                                                    id={"EnterRemarks"}
                                                                                                                    value={ManualInfo[0].manualCloseComment}
                                                                                                                    onChange={event => handleChangeIsManualCloseComments(event, "Comment", subField.genralLabdipTNASubmissionAndApprovalId)}
                                                                                                                    style={{ width: "100%", border: getComment && ManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                                                                                                    disabled={subField.submitStatus === "Completed" ? true : false}
                                                                                                                >

                                                                                                                </textarea>
                                                                                                            </td>
                                                                                                            <td >




                                                                                                                <input
                                                                                                                    placeholder="Enter the StoryName"
                                                                                                                    className="form-control"
                                                                                                                    title={ManualInfo[0].manualCloseDate}
                                                                                                                    name="StoryName"
                                                                                                                    id={"StoryName"}
                                                                                                                    value={ManualInfo[0].manualCloseDate}
                                                                                                                    disabled

                                                                                                                ></input>


                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>

                                                                                                </table>
                                                                                                {
                                                                                                    <table className="table table-bordered">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>Story</th>
                                                                                                                <th>Theme</th>
                                                                                                                <th>Details </th>
                                                                                                                <th>Task Holder / Follower</th>


                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                MultiCloseDatas.length !== 0 ?
                                                                                                                    MultiCloseDatas.map((item, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                    <td>{item.itemDetails}</td>
                                                                                                                                    <td>{item.taskHolderFollowerNames}</td>


                                                                                                                                </tr>
                                                                                                                            </>

                                                                                                                        )
                                                                                                                    })
                                                                                                                    :
                                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                            }

                                                                                                        </tbody>
                                                                                                    </table>


                                                                                                }

                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>



                                                                                            </Modal.Footer>
                                                                                        </Modal> : ''

                                                                                }

                                                                                {


                                                                                    showApproval === true ?
                                                                                        <Modal dialogClassName="commonwidth" show={showApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowApproval(false)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {/* {getheader} :  */}
                                                                                                    Lab Dip Approval
                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>

                                                                                                {
                                                                                                    showMultiClosediv === true ?

                                                                                                        <>

                                                                                                            <div className="row">
                                                                                                                <div className="col-lg-12">
                                                                                                                    <div className="alert alert-info">
                                                                                                                        Note : If you want to submit multiple, you can select multiple items.
                                                                                                                    </div>
                                                                                                                    <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                                                        {
                                                                                                                            MultiCloseDatas.length !== 0 ?

                                                                                                                                <table className="table table-bordered">
                                                                                                                                    <thead>
                                                                                                                                        <tr>
                                                                                                                                            <th>Story</th>
                                                                                                                                            <th>Theme</th>
                                                                                                                                            <th>Details </th>
                                                                                                                                            <th>Task Holder / Follower</th>
                                                                                                                                            <th>
                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                                                                        onChange={event => (SelectAll(event))}
                                                                                                                                                        checked={selectall === 1 ? true : false}
                                                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                                                    <span className="text"></span>
                                                                                                                                                </label>
                                                                                                                                            </th>

                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        {

                                                                                                                                            MultiCloseDatas.map((item, index) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                                            <td>{item.itemDetails}</td>
                                                                                                                                                            <td>{item.taskHolderFollowerNames}</td>
                                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                                            onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                    </label>

                                                                                                                                                                </div>

                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                                :
                                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                    <TableHeader headers={headers} />
                                                                                                                                    <tbody>
                                                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <br />

                                                                                                            <div className="row">
                                                                                                                <div className="col-sm-12" align="right">
                                                                                                                    <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                                                                                                                        <i className="fa fa-check right"></i> Add
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : ''
                                                                                                }
                                                                                                {
                                                                                                    showApprovaldiv === true ?
                                                                                                        <>
                                                                                                            <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                                                                                <div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="BuyerID">
                                                                                                                                    Buyer-Season-Brand

                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Buyer-Season-Brand"
                                                                                                                                        className="form-control"
                                                                                                                                        name=" Buyer-Season-Brand"
                                                                                                                                        id={" Buyer-Season-Brand"}
                                                                                                                                        value={inputFields[0].BuyerBrandSeasonName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="SupplierID">
                                                                                                                                    Supplier
                                                                                                                                    {/* <span className="text-danger">*</span> */}
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Supplier"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SupplierID"
                                                                                                                                        id={"SupplierID"}
                                                                                                                                        value={inputFields[0].SupplierName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="Name">
                                                                                                                                    Submit Date
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                        id={
                                                                                                                                            "SubmittedDate"
                                                                                                                                        }
                                                                                                                                        selected={
                                                                                                                                            inputFields[0].SubmittedDate
                                                                                                                                        }

                                                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                                                        peekNextMonth


                                                                                                                                        disabled
                                                                                                                                    />

                                                                                                                                    {/* <input
                                                                                                                                        placeholder="SubmittedDate"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SubmittedDate"
                                                                                                                                        id={"SubmittedDate"}
                                                                                                                                        value={inputFields[0].SubmittedDate}
                                                                                                                                        disabled

                                                                                                                                    ></input> */}

                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"SendTo"}>
                                                                                                                                    Approval Date
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    {
                                                                                                                                        inputFields[0].ApprovalStatus !== "Completed" ?
                                                                                                                                            <DatePicker className="form-control" name="ApprovalDate"
                                                                                                                                                id={
                                                                                                                                                    "ApprovalDate"
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    inputFields[0].ApprovalDate
                                                                                                                                                }
                                                                                                                                                onChange={event => handleChange(event, 'ApprovalDate')}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth

                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                customInput={<CustomInputApproval />}
                                                                                                                                                isClearable={inputFields[0].ApprovalDate === null ? false : true}
                                                                                                                                            />



                                                                                                                                            // <input
                                                                                                                                            //     id="ApprovalDate"
                                                                                                                                            //     name="ApprovalDate"
                                                                                                                                            //     value={inputFields[0].ApprovalDate}
                                                                                                                                            //     placeholder="DD/MM/YYYY"
                                                                                                                                            //     type="date"
                                                                                                                                            //     min={minApprovalDate}
                                                                                                                                            //     autoComplete="off"
                                                                                                                                            //     className={"form-control"}
                                                                                                                                            //     onKeyDown={(e) => e.preventDefault()}
                                                                                                                                            //     style={{
                                                                                                                                            //         color:
                                                                                                                                            //             inputFields[0].ApprovalDate === ""
                                                                                                                                            //                 ? "#bfdea1"
                                                                                                                                            //                 : "#000",
                                                                                                                                            //         border:
                                                                                                                                            //             submitted &&
                                                                                                                                            //                 inputFields[0].ApprovalDate === ""
                                                                                                                                            //                 ? "1px solid red"
                                                                                                                                            //                 : "",
                                                                                                                                            //     }}
                                                                                                                                            //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                                                                                                            // />
                                                                                                                                            :
                                                                                                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                                id={
                                                                                                                                                    "SubmittedDate"
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    inputFields[0].ApprovalDate
                                                                                                                                                }

                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth

                                                                                                                                                customInput={<CustomInputApproval />}
                                                                                                                                                disabled
                                                                                                                                            />
                                                                                                                                        // <input
                                                                                                                                        //     placeholder="ApprovalDate"
                                                                                                                                        //     className="form-control"
                                                                                                                                        //     name="ApprovalDate"
                                                                                                                                        //     id={"ApprovalDate"}
                                                                                                                                        //     value={inputFields[0].ApprovalDate}
                                                                                                                                        //     disabled

                                                                                                                                        // ></input>
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">


                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"StoryName"}>
                                                                                                                                    Story Name
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the StoryName"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].StoryName}
                                                                                                                                        name="StoryName"
                                                                                                                                        id={"StoryName"}
                                                                                                                                        value={inputFields[0].StoryName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={" ThemeName"}>
                                                                                                                                    Theme Name
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the  ThemeName"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].ThemeName}
                                                                                                                                        name=" ThemeName"
                                                                                                                                        id={" ThemeName"}
                                                                                                                                        value={inputFields[0].ThemeName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"Endcustomer"}>
                                                                                                                                    End Customer

                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the End Customer"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].EndCustomer}
                                                                                                                                        name="End Customer No"
                                                                                                                                        id={"End Customer"}
                                                                                                                                        value={inputFields[0].EndCustomer}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <div className='form-group'>
                                                                                                                                <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                    <input
                                                                                                                                        placeholder="Submit Sent Details"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SubmitSentDetails"
                                                                                                                                        id={
                                                                                                                                            "SubmitSentDetails"
                                                                                                                                        }
                                                                                                                                        value={inputFields[0].SubmitSentDetails}
                                                                                                                                        disabled
                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-12">
                                                                                                                        <div className="tabbable">
                                                                                                                            <ul
                                                                                                                                className="nav nav-tabs nav-justified"
                                                                                                                                id="myTab2"
                                                                                                                            >
                                                                                                                                <li
                                                                                                                                    className={tab === 0 ? "active" : ""} >

                                                                                                                                    <a
                                                                                                                                        onClick={event => TabChange(0)}

                                                                                                                                    >
                                                                                                                                        <h5>Submit Information</h5>
                                                                                                                                    </a>
                                                                                                                                </li>

                                                                                                                                <li
                                                                                                                                    className={tab === 1 ? "active" : " "}

                                                                                                                                >
                                                                                                                                    <a


                                                                                                                                        onClick={event => TabChange(1)}
                                                                                                                                    >
                                                                                                                                        <h5>Order Information</h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-sm-10">
                                                                                                                                            <div className="row">
                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className='form-group'>
                                                                                                                                                        {
                                                                                                                                                            inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor="BuyerID">Pantone #</label>
                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                        <input
                                                                                                                                                                            placeholder="Pantone #"
                                                                                                                                                                            className="form-control"

                                                                                                                                                                            name="PantoneCodeandType"
                                                                                                                                                                            id={
                                                                                                                                                                                "PantoneCodeandType"
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                inputFields[0].PantoneTypeName + "-" + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                            }
                                                                                                                                                                            disabled

                                                                                                                                                                        ></input>
                                                                                                                                                                    </span>
                                                                                                                                                                </>
                                                                                                                                                                :
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor="SwatchName">Swatch Name</label>
                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                        <input
                                                                                                                                                                            placeholder="Swatch Name"
                                                                                                                                                                            className="form-control"
                                                                                                                                                                            title={inputFields[0].PantoneNameOrSwatchName}
                                                                                                                                                                            name="SwatchName"
                                                                                                                                                                            id={
                                                                                                                                                                                "SwatchName"
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                            }
                                                                                                                                                                            disabled

                                                                                                                                                                        ></input>
                                                                                                                                                                    </span>
                                                                                                                                                                </>
                                                                                                                                                        }

                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className='form-group'>
                                                                                                                                                        <label htmlFor="SeasonID" >Ref. Color Name</label>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input
                                                                                                                                                                placeholder="Ref. Color Name"
                                                                                                                                                                className="form-control"
                                                                                                                                                                title={inputFields[0].RefColorName}
                                                                                                                                                                name="RefColorName"
                                                                                                                                                                id={
                                                                                                                                                                    "RefColorName"
                                                                                                                                                                }
                                                                                                                                                                value={
                                                                                                                                                                    inputFields[0].RefColorName
                                                                                                                                                                }
                                                                                                                                                                disabled
                                                                                                                                                            ></input>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>


                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className="form-group">
                                                                                                                                                        {
                                                                                                                                                            inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor={"Quarter"}>Color / Image</label>
                                                                                                                                                                    {
                                                                                                                                                                        inputFields[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                                                                                                            <div style={{ background: inputFields[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                                                                            :
                                                                                                                                                                            <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                                                    }
                                                                                                                                                                </> :
                                                                                                                                                                <>
                                                                                                                                                                    <img src={inputFields[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                                                                                                                                                        alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                                                                    />
                                                                                                                                                                </>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="row">
                                                                                                                                                <div className="col-sm-6">
                                                                                                                                                    <div className="form-group">
                                                                                                                                                        <label htmlFor={"FabricID"}>

                                                                                                                                                            Fabric Details<span className="text-danger">*</span></label>

                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <select id={"FabricID"} name="FabricID" className="form-select"
                                                                                                                                                                value={inputFields[0].FabricID}
                                                                                                                                                                disabled
                                                                                                                                                            >
                                                                                                                                                                <option value="0">-Select Fabric-</option>
                                                                                                                                                                {
                                                                                                                                                                    FabricDetails.map(item => (
                                                                                                                                                                        <option key={item.id} value={item.value}>
                                                                                                                                                                            {item.label}
                                                                                                                                                                        </option>
                                                                                                                                                                    ))
                                                                                                                                                                }
                                                                                                                                                            </select>
                                                                                                                                                        </span>

                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </div>
                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                <div className="row">
                                                                                                                                                    <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0" }}>
                                                                                                                                                        <div className='form-group'>
                                                                                                                                                            <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                                                                                            <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                                                                                                                rows="4" style={{ width: "100%" }}
                                                                                                                                                                disabled
                                                                                                                                                            ></textarea>
                                                                                                                                                        </div>
                                                                                                                                                    </div>

                                                                                                                                                </div>
                                                                                                                                            </div>


                                                                                                                                            <div>
                                                                                                                                                <table>
                                                                                                                                                    <tr>
                                                                                                                                                        <td><label>Submit Options</label> </td>
                                                                                                                                                        <td><label>Submission Card</label></td>
                                                                                                                                                    </tr>
                                                                                                                                                    <tr>
                                                                                                                                                        <td> <div className="col-sm-6" style={{ paddingLeft: "0" }}>
                                                                                                                                                            <div className="form-group">


                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                                                                    style={{ width: "700px" }}>
                                                                                                                                                                    <thead>
                                                                                                                                                                        <tr>
                                                                                                                                                                            <th >Options <span className="text-danger">*</span></th>
                                                                                                                                                                            <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                                                                            <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                                                                                        </tr>

                                                                                                                                                                    </thead>
                                                                                                                                                                    <tbody>
                                                                                                                                                                        {
                                                                                                                                                                            submittedoption && (
                                                                                                                                                                                submittedoption.map((item, index) => {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <>
                                                                                                                                                                                            <tr>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                                                                        <input
                                                                                                                                                                                                            placeholder="Option Name"
                                                                                                                                                                                                            className="form-control"
                                                                                                                                                                                                            title={item.optionName}
                                                                                                                                                                                                            name="OptionName"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "OptionName"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            value={
                                                                                                                                                                                                                item.optionName
                                                                                                                                                                                                            }
                                                                                                                                                                                                            disabled
                                                                                                                                                                                                        ></input>


                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </td>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                                                        <input
                                                                                                                                                                                                            placeholder=""
                                                                                                                                                                                                            className="form-control"
                                                                                                                                                                                                            title={item.supplierReferenceNo}
                                                                                                                                                                                                            name="Sendto"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "Sendto"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            value={
                                                                                                                                                                                                                item.supplierReferenceNo
                                                                                                                                                                                                            }
                                                                                                                                                                                                            disabled
                                                                                                                                                                                                        ></input>
                                                                                                                                                                                                    </span>

                                                                                                                                                                                                </td>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className='input-icon icon-right'>


                                                                                                                                                                                                        <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "TaskHolderOrFollower"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            isLoading={false}
                                                                                                                                                                                                            isClearable={true}
                                                                                                                                                                                                            isSearchable={true}
                                                                                                                                                                                                            onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index, "Labdip Approval")}
                                                                                                                                                                                                            value={getApprovedOptions.filter(function (option) {
                                                                                                                                                                                                                return option.value === item.approvedOptionName;
                                                                                                                                                                                                            })}
                                                                                                                                                                                                            options={getApprovedOptions}
                                                                                                                                                                                                            styles={
                                                                                                                                                                                                                submitted && (item.approvedOptionName === '0' || item.approvedOptionName === "" || item.approvedOptionName === null) ? styles2 : styles1
                                                                                                                                                                                                            }


                                                                                                                                                                                                            menuPosition="fixed"
                                                                                                                                                                                                            // disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                                                                            isDisabled={inputFields[0].ApprovalStatus === "Completed"}

                                                                                                                                                                                                        ></Reactselect>
                                                                                                                                                                                                    </span>

                                                                                                                                                                                                </td>

                                                                                                                                                                                            </tr>
                                                                                                                                                                                        </>

                                                                                                                                                                                    )
                                                                                                                                                                                }))
                                                                                                                                                                        }
                                                                                                                                                                    </tbody>
                                                                                                                                                                </table>
                                                                                                                                                            </div>
                                                                                                                                                        </div></td>
                                                                                                                                                        <td>
                                                                                                                                                            <div className={ImgClassName}
                                                                                                                                                                htmlFor="upload-button"
                                                                                                                                                            >
                                                                                                                                                                <img
                                                                                                                                                                    src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}

                                                                                                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                                                                                            </div>
                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </table>




                                                                                                                                                <div> {
                                                                                                                                                    isOpen && (

                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                                                                            alt="bg image"
                                                                                                                                                            onCloseRequest={
                                                                                                                                                                () => setIsOpen(false)
                                                                                                                                                            } />
                                                                                                                                                    )
                                                                                                                                                } </div>

                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                                                                    {/* <div className="row">
                                                                                                                                        <div className="col-sm-12" align={"right"}>
                                                                                                                                            <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}>
                                                                                                                                                <i className="fa fa-plus"></i> &nbsp;Add
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                    </div> */}
                                                                                                                                    <br />
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-lg-12">
                                                                                                                                            <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                                                                                {
                                                                                                                                                    getOrderinfo.length !== 0 ?

                                                                                                                                                        <table className="table table-bordered">
                                                                                                                                                            <TableHeader headers={headers} />
                                                                                                                                                            <tbody>
                                                                                                                                                                {

                                                                                                                                                                    getOrderinfo.map((item, index) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                                    <td>{item.itemDetails}</td>
                                                                                                                                                                                    <td>{item.taskHolderFollowerNames}</td>

                                                                                                                                                                                    {/* <td className="Actionbutton fixed-column">
                                                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                <input type="checkbox" name="SelectedDetail"
                                                                                                                                                                                                    disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                                                                    onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                                                                                                    checked={item.selectedDetail === 1 ? true : false}
                                                                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                                            </label>
                                                                                                                                                                                            &nbsp;&nbsp;

                                                                                                                                                                                            <button
                                                                                                                                                                                                disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                                                                                type="button"
                                                                                                                                                                                                className="btn  btn-xs  btn-danger"
                                                                                                                                                                                                title="Delete"
                                                                                                                                                                                                onClick={() =>
                                                                                                                                                                                                    handleRemoveOrderInfo(index)
                                                                                                                                                                                                }
                                                                                                                                                                                            >
                                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                                            </button>


                                                                                                                                                                                        </div>

                                                                                                                                                                                    </td> */}
                                                                                                                                                                                </tr>
                                                                                                                                                                            </>

                                                                                                                                                                        )
                                                                                                                                                                    })
                                                                                                                                                                }

                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        :
                                                                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                                            <TableHeader headers={headers} />
                                                                                                                                                            <tbody>
                                                                                                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <br />


                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>


                                                                                                                <div className="mt-10">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-8 col-lg-8">
                                                                                                                            <div className='form-group'>
                                                                                                                                <label htmlFor="Remarks">Remarks</label>
                                                                                                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}
                                                                                                                                    onChange={event => handleChange(event, 'Remarks')}
                                                                                                                                    rows="4" style={{ width: "110%" }}
                                                                                                                                    disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                ></textarea>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col-sm-12" align="right">
                                                                                                                    <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}
                                                                                                                        disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check right"></i> &nbsp;Save
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </>
                                                                                                        : ''
                                                                                                }

                                                                                            </Modal.Body>

                                                                                        </Modal> : ''

                                                                                }

                                                                                {


                                                                                    showSubmission === true ?
                                                                                        <Modal dialogClassName="commonwidth" show={showSubmission === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowSubmission(false)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {
                                                                                                        View === true ?
                                                                                                            "View" : "Edit"
                                                                                                    }
                                                                                                    &nbsp; Lab Dip Submission
                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>
                                                                                                <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                                                                                                    <div className="col-sm-12">
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor="BuyerID">
                                                                                                                        Buyer-Season-Brand
                                                                                                                        {/* <span className="text-danger">*</span> */}
                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <input
                                                                                                                            placeholder="Buyer-Season-Brand"
                                                                                                                            className="form-control"
                                                                                                                            title={inputFields[0].BuyerBrandSeasonName}
                                                                                                                            name=" Buyer-Season-Brand"
                                                                                                                            id={" Buyer-Season-Brand"}
                                                                                                                            value={inputFields[0].BuyerBrandSeasonName}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor="SupplierID">
                                                                                                                        Supplier
                                                                                                                        {/* <span className="text-danger">*</span> */}
                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <input
                                                                                                                            placeholder="Supplier"
                                                                                                                            className="form-control"
                                                                                                                            title={inputFields[0].SupplierName}
                                                                                                                            name="SupplierID"
                                                                                                                            id={"SupplierID"}
                                                                                                                            value={inputFields[0].SupplierName}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor="Name">
                                                                                                                        Submit Date
                                                                                                                        <span className="text-danger">*</span>
                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        {
                                                                                                                            View === true ?
                                                                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                    id={
                                                                                                                                        "SubmittedDate"
                                                                                                                                    }
                                                                                                                                    selected={
                                                                                                                                        inputFields[0].SubmittedDate
                                                                                                                                    }

                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                    peekNextMonth

                                                                                                                                    // customInput={<CustomInput />}
                                                                                                                                    disabled
                                                                                                                                />
                                                                                                                                // <input
                                                                                                                                //     placeholder="Supplier"
                                                                                                                                //     className="form-control"
                                                                                                                                //     title={inputFields[0].SubmittedDate}
                                                                                                                                //     name="SupplierID"
                                                                                                                                //     id={"SupplierID"}
                                                                                                                                //     value={inputFields[0].SubmittedDate}
                                                                                                                                //     disabled

                                                                                                                                // ></input>
                                                                                                                                :
                                                                                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                    id={
                                                                                                                                        "SubmittedDate"
                                                                                                                                    }
                                                                                                                                    selected={
                                                                                                                                        inputFields[0].SubmittedDateEdit
                                                                                                                                    }

                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                    peekNextMonth
                                                                                                                                    onChange={event => handleChangeSubmit(event, 'SubmittedDate')}
                                                                                                                                    customInput={<CustomInputsubmit />}

                                                                                                                                />
                                                                                                                            // <input
                                                                                                                            //     id="SubmittedDate"
                                                                                                                            //     name="SubmittedDate"
                                                                                                                            //     value={inputFields[0].SubmittedDateEdit}
                                                                                                                            //     placeholder="DD/MM/YYYY"
                                                                                                                            //     type="date"
                                                                                                                            //     autoComplete="off"
                                                                                                                            //     className={"form-control"}
                                                                                                                            //     onKeyDown={(e) => e.preventDefault()}
                                                                                                                            //     style={{
                                                                                                                            //         border: submittedpopup && inputFields[0].SubmittedDateEdit === '' ? '1px solid red' : ''
                                                                                                                            //     }}
                                                                                                                            //     onChange={event => handleChangeSubmit(event, 'SubmittedDate')}
                                                                                                                            // />

                                                                                                                        }

                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        {
                                                                                                                            subField.submitStatus === 'Completed' && View === true ?
                                                                                                                                <span>
                                                                                                                                    <input
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].SendToDisplay}
                                                                                                                                        value={inputFields[0].SendToDisplay} />
                                                                                                                                </span>
                                                                                                                                :
                                                                                                                                <span className=''  title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                                                                                                                                    <Reactselect
                                                                                                                                        className="basic-single"
                                                                                                                                        name={"Sku"}
                                                                                                                                        id={
                                                                                                                                            "Sent To"
                                                                                                                                        }
                                                                                                                                        // value={item.MeasurementPoints}
                                                                                                                                        //isDisabled={false}
                                                                                                                                        isLoading={false}
                                                                                                                                        isClearable={true}
                                                                                                                                        isSearchable={true}
                                                                                                                                        options={getTaskholder}
                                                                                                                                        value={inputFields[0].SendToEdit}
                                                                                                                                        isMulti
                                                                                                                                        // components={{
                                                                                                                                        //     MultiValueLabel,
                                                                                                                                        //  }}
                                                                                                                                        onChange={event => handlemultidd(event, 'SendTo')}
                                                                                                                                        menuPosition="fixed"
                                                                                                                                        styles={submittedpopup && inputFields[0].SendTo === "" ? styles2s : styles1s}
                                                                                                                                    // readOnly
                                                                                                                                    // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                                                                                                    />
                                                                                                                                </span>

                                                                                                                        }

                                                                                                                        {/* <select id={"SendTo"} name="SendTo" className="form-select"
                                                                                                                            value={inputFields[0].SendTo}
                                                                                                                            onChange={(event) => handleChangeSubmit(event, 'SendTo')}
                                                                                                                            style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                                                                                                                            disabled={View === true ? true : false}
                                                                                                                        >
                                                                                                                            <option value="0">-Select SendTo-</option>
                                                                                                                            {
                                                                                                                                getTaskholder.map(item => (
                                                                                                                                    <option key={item.id} value={item.value}>
                                                                                                                                        {item.label}
                                                                                                                                    </option>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </select> */}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">


                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor={"StoryName"}>
                                                                                                                        Story Name
                                                                                                                        <span className="text-danger">*</span>
                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <input
                                                                                                                            placeholder="Enter the StoryName"
                                                                                                                            className="form-control"
                                                                                                                            title={inputFields[0].StoryName}
                                                                                                                            name="StoryName"
                                                                                                                            id={"StoryName"}
                                                                                                                            value={inputFields[0].StoryName}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor={" ThemeName"}>
                                                                                                                        Theme Name
                                                                                                                        <span className="text-danger">*</span>
                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <input
                                                                                                                            placeholder="Enter the  ThemeName"
                                                                                                                            className="form-control"
                                                                                                                            title={inputFields[0].ThemeName}
                                                                                                                            name=" ThemeName"
                                                                                                                            id={" ThemeName"}
                                                                                                                            value={inputFields[0].ThemeName}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor={"Endcustomer"}>
                                                                                                                        End Customer

                                                                                                                    </label>
                                                                                                                    <span className="input-icon icon-right">
                                                                                                                        <input
                                                                                                                            placeholder="Enter the End Customer"
                                                                                                                            className="form-control"
                                                                                                                            title={inputFields[0].EndCustomer}
                                                                                                                            name="End Customer No"
                                                                                                                            id={"End Customer"}
                                                                                                                            value={inputFields[0].EndCustomer}
                                                                                                                            disabled

                                                                                                                        ></input>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-3">
                                                                                                                <div className="form-group">
                                                                                                                    <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                                                                                                                    <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                                                                                                                        value={inputFields[0].SendThrough}
                                                                                                                        onChange={event => handleChangeSubmit(event, 'Sendthrough')}
                                                                                                                        style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                                                                                                                        disabled={View === true ? true : false}
                                                                                                                    >
                                                                                                                        <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through- </option>
                                                                                                                        <option value="1" key="1">Hand Carried</option>
                                                                                                                        <option value="2" key="2">Courier - DHL</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {
                                                                                                                showawb === true ?
                                                                                                                    <div className="col-sm-3">
                                                                                                                        <div className='form-group'>
                                                                                                                            <label htmlFor="Awbdate" >AWB/Date</label>
                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                <input
                                                                                                                                    placeholder="AWB/Date"
                                                                                                                                    className="form-control"
                                                                                                                                    name="AWBDateandNo"
                                                                                                                                    id={
                                                                                                                                        "AWBDateandNo"
                                                                                                                                    }
                                                                                                                                    value={inputFields[0].AWBDateandNo === null ? '-' : inputFields[0].AWBDateandNo}
                                                                                                                                    // style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                                                                                                                    onClick={() => setShowPopup(true)}
                                                                                                                                    disabled={View === true ? true : false}
                                                                                                                                ></input>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div> : ''
                                                                                                            }
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-12">
                                                                                                            <div className="tabbable">
                                                                                                                <ul
                                                                                                                    className="nav nav-tabs nav-justified"
                                                                                                                    id="myTab1"
                                                                                                                >
                                                                                                                    <li
                                                                                                                        className={tab === 0 ? "active" : ""} >

                                                                                                                        <a
                                                                                                                            onClick={event => TabChange(0)}

                                                                                                                        >
                                                                                                                            <h5>Submit Information</h5>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <li
                                                                                                                        className={tab === 1 ? "active" : " "}

                                                                                                                    >
                                                                                                                        <a
                                                                                                                            onClick={event => TabChange(1)}
                                                                                                                        >
                                                                                                                            <h5>Order Information</h5>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                                <div className="tab-content">

                                                                                                                    <div id="idSubmitInformation1" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                                                                                        <div className="row">
                                                                                                                            <div className="col-sm-12">
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-sm-3">
                                                                                                                                        <div className='form-group'>
                                                                                                                                            {
                                                                                                                                                inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                    <>
                                                                                                                                                        <label htmlFor="BuyerID">Pantone #</label>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input
                                                                                                                                                                placeholder="Pantone #"
                                                                                                                                                                className="form-control"

                                                                                                                                                                name="PantoneCodeandType"
                                                                                                                                                                id={
                                                                                                                                                                    "PantoneCodeandType"
                                                                                                                                                                }
                                                                                                                                                                value={
                                                                                                                                                                    inputFields[0].PantoneTypeName + "-" + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                }
                                                                                                                                                                disabled

                                                                                                                                                            ></input>
                                                                                                                                                        </span>
                                                                                                                                                    </>
                                                                                                                                                    :
                                                                                                                                                    <>
                                                                                                                                                        <label htmlFor="SwatchName">Swatch Name<span className="text-danger">*</span></label>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input
                                                                                                                                                                placeholder="Swatch Name"
                                                                                                                                                                className="form-control"
                                                                                                                                                                title={inputFields[0].PantoneNameOrSwatchName}
                                                                                                                                                                name="SwatchName"
                                                                                                                                                                id={
                                                                                                                                                                    "SwatchName"
                                                                                                                                                                }
                                                                                                                                                                value={
                                                                                                                                                                    inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                }
                                                                                                                                                                disabled

                                                                                                                                                            ></input>
                                                                                                                                                        </span>
                                                                                                                                                    </>
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    <div className="col-sm-3">
                                                                                                                                        <div className='form-group'>
                                                                                                                                            <label htmlFor="SeasonID" >Ref. Color Name</label>
                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                <input
                                                                                                                                                    placeholder="Ref. Color Name"
                                                                                                                                                    className="form-control"
                                                                                                                                                    title={inputFields[0].RefColorName}
                                                                                                                                                    name="RefColorName"
                                                                                                                                                    id={
                                                                                                                                                        "RefColorName"
                                                                                                                                                    }
                                                                                                                                                    value={
                                                                                                                                                        inputFields[0].RefColorName
                                                                                                                                                    }
                                                                                                                                                    disabled
                                                                                                                                                ></input>
                                                                                                                                            </span>
                                                                                                                                        </div>
                                                                                                                                    </div>


                                                                                                                                    <div className="col-sm-3">
                                                                                                                                        <div className="form-group">
                                                                                                                                            {
                                                                                                                                                inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                    <>
                                                                                                                                                        <label htmlFor={"Quarter"}>Color / Image</label>
                                                                                                                                                        {
                                                                                                                                                            inputFields[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                                                                                                <div style={{ background: inputFields[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                                                                :
                                                                                                                                                                <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                                        }
                                                                                                                                                    </> :
                                                                                                                                                    <>
                                                                                                                                                        <img src={inputFields[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                                                                                                                                            alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                                                        />
                                                                                                                                                    </>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-sm-6">
                                                                                                                                        <div className="form-group">
                                                                                                                                            <label htmlFor={"FabricID"}>

                                                                                                                                                Fabric Details<span className="text-danger">*</span></label>


                                                                                                                                            <span className="input-icon icon-right">
                                                                                                                                                <select id={"FabricID"} name="FabricID" className="form-select"
                                                                                                                                                    value={inputFields[0].FabricID}
                                                                                                                                                    onChange={(event) => handleChange(event, 'FabricID')}
                                                                                                                                                    style={{ border: submittedpopup && inputFields[0].FabricID === "0" ? '1px solid red' : '' }}
                                                                                                                                                    disabled={View === true ? true : false}
                                                                                                                                                >
                                                                                                                                                    <option value="0">-Select Fabric-</option>
                                                                                                                                                    {
                                                                                                                                                        FabricDetails.map(item => (
                                                                                                                                                            <option key={item.id} value={item.value}>
                                                                                                                                                                {item.label}
                                                                                                                                                            </option>
                                                                                                                                                        ))
                                                                                                                                                    }

                                                                                                                                                </select>
                                                                                                                                            </span>


                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-lg-3">

                                                                                                                                        <label style={{ marginTop: '32px' }}>
                                                                                                                                            <input type="checkbox" name="SelectedDetail"
                                                                                                                                                className="colored-blue clearOpacity"
                                                                                                                                                onChange={event => (ontransfer(event, inputFields[0].FabricID))}
                                                                                                                                                checked={inputFields[0].IsAllFabricLoad === 1 ? 1 : 0}
                                                                                                                                                disabled={View === true ? true : false}
                                                                                                                                                style={{ border: "1px solid blue" }}
                                                                                                                                            />

                                                                                                                                            <span className="text"></span>
                                                                                                                                            <label htmlFor="">Load All Fabric</label>
                                                                                                                                        </label>


                                                                                                                                    </div>
                                                                                                                                </div>


                                                                                                                                <div className="row">
                                                                                                                                    <table width={"100%"}>
                                                                                                                                        <tr>
                                                                                                                                            <td><label>Submit Options</label> </td>
                                                                                                                                            <td><label>Swatch Card (Image Upload)</label></td>
                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td> <div className="">
                                                                                                                                                <div className="">


                                                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                                                        style={{ width: '683px' }}   >
                                                                                                                                                        <thead>
                                                                                                                                                            <tr>
                                                                                                                                                                <th >Options <span className="text-danger">*</span></th>
                                                                                                                                                                <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                                                                {
                                                                                                                                                                    View !== true && (
                                                                                                                                                                        <th >Action</th>
                                                                                                                                                                    )
                                                                                                                                                                }


                                                                                                                                                            </tr>

                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            {
                                                                                                                                                                submittedoption.map((item, index) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td>
                                                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                                                        <input
                                                                                                                                                                                            placeholder="Option Name"
                                                                                                                                                                                            className="form-control"
                                                                                                                                                                                            title={item.OptionName}
                                                                                                                                                                                            name="OptionName"
                                                                                                                                                                                            id={
                                                                                                                                                                                                "OptionName"
                                                                                                                                                                                            }
                                                                                                                                                                                            value={
                                                                                                                                                                                                item.OptionName
                                                                                                                                                                                            }
                                                                                                                                                                                            onChange={event => (handleSubmitoptionchange(event, 'OptionName', index, "Labdip Submission"))}
                                                                                                                                                                                            style={{ width: "250px", border: submittedpopup && item.OptionName === '' ? '1px solid red' : '' }}
                                                                                                                                                                                            disabled={View === true ? true : false}
                                                                                                                                                                                        ></input>


                                                                                                                                                                                    </span>
                                                                                                                                                                                </td>
                                                                                                                                                                                <td>
                                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                                        <input
                                                                                                                                                                                            placeholder=""
                                                                                                                                                                                            className="form-control cut_text"
                                                                                                                                                                                            title={item.SupplierReferenceNo}
                                                                                                                                                                                            name="SupplierReferenceNo"
                                                                                                                                                                                            id={
                                                                                                                                                                                                "SupplierReferenceNo"
                                                                                                                                                                                            }
                                                                                                                                                                                            value={
                                                                                                                                                                                                item.SupplierReferenceNo
                                                                                                                                                                                            }
                                                                                                                                                                                            onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index, "Labdip Submission"))}


                                                                                                                                                                                            disabled={View === true === "Completed" ? true : false}
                                                                                                                                                                                        ></input>
                                                                                                                                                                                    </span>

                                                                                                                                                                                </td>
                                                                                                                                                                                {
                                                                                                                                                                                    View !== true && (
                                                                                                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                                                                                                            {" "}
                                                                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                                                                {submittedoption.length !== 1 && (
                                                                                                                                                                                                    <button
                                                                                                                                                                                                        type="button"
                                                                                                                                                                                                        className="btn  btn-xs  btn-danger"
                                                                                                                                                                                                        title="Delete option"
                                                                                                                                                                                                        onClick={() =>
                                                                                                                                                                                                            handleRemoveFields(index)
                                                                                                                                                                                                        }

                                                                                                                                                                                                    >
                                                                                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                                                                                    </button>
                                                                                                                                                                                                )}
                                                                                                                                                                                                &nbsp;
                                                                                                                                                                                                {submittedoption.length === index + 1 && (
                                                                                                                                                                                                    <button
                                                                                                                                                                                                        type="button"
                                                                                                                                                                                                        className="btn  btn-xs  btn-success"
                                                                                                                                                                                                        title="Add option"
                                                                                                                                                                                                        onClick={() =>
                                                                                                                                                                                                            handleAddField(index)
                                                                                                                                                                                                        }

                                                                                                                                                                                                    >
                                                                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                                                                    </button>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </td>
                                                                                                                                                                                    )
                                                                                                                                                                                }


                                                                                                                                                                            </tr>
                                                                                                                                                                        </>

                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </div>
                                                                                                                                            </div></td>
                                                                                                                                            <td>
                                                                                                                                                <div className={ImgClassName}
                                                                                                                                                    htmlFor="upload-button"
                                                                                                                                                >
                                                                                                                                                    <img
                                                                                                                                                        src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}


                                                                                                                                                        alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                                                                                                                                    <input type="file" id="upload-button" accept=".jpg,.jpeg,.png"
                                                                                                                                                        value=''
                                                                                                                                                        disabled={View === true ? true : false}
                                                                                                                                                    />
                                                                                                                                                    {
                                                                                                                                                        inputFields[0].SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                                                                                                                                            <div style={
                                                                                                                                                                {
                                                                                                                                                                    marginTop: '8px',
                                                                                                                                                                    float: 'right',
                                                                                                                                                                    bottom: '10px',
                                                                                                                                                                    position: 'relative',
                                                                                                                                                                }
                                                                                                                                                            }>


                                                                                                                                                            </div>
                                                                                                                                                        )
                                                                                                                                                    }

                                                                                                                                                </div>
                                                                                                                                            </td>

                                                                                                                                        </tr>
                                                                                                                                    </table>




                                                                                                                                    <div> {
                                                                                                                                        isOpen && (

                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                                                                alt="bg image"
                                                                                                                                                onCloseRequest={
                                                                                                                                                    () => setIsOpen(false)
                                                                                                                                                } />
                                                                                                                                        )
                                                                                                                                    } </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>



                                                                                                                    <div id="idOrderInformation1" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                                                                                        {/* <div className="row">
                                                                                                                            {
                                                                                                                                View === true && (
                                                                                                                                    <div className="col-sm-12" align={"right"}>
                                                                                                                                        <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                                                                                                                            disabled={View === true ? true : false}>
                                                                                                                                            <i className="fa fa-plus"></i> &nbsp;Add
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }

                                                                                                                        </div> */}
                                                                                                                        <br />
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-12">
                                                                                                                                <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                                                                    {
                                                                                                                                        getOrderinfo.length !== 0 ?

                                                                                                                                            <table className="table table-bordered">
                                                                                                                                                <thead>
                                                                                                                                                    <tr>
                                                                                                                                                        <th>Story</th>
                                                                                                                                                        <th>Theme</th>
                                                                                                                                                        <th>Details</th>
                                                                                                                                                        <th>Task Holder / Follower</th>
                                                                                                                                                    </tr>

                                                                                                                                                </thead>
                                                                                                                                                <tbody>
                                                                                                                                                    {

                                                                                                                                                        getOrderinfo.map((item, index) => {
                                                                                                                                                            return (
                                                                                                                                                                <>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td>{item.storyName}</td>
                                                                                                                                                                        <td>{item.themeName}</td>
                                                                                                                                                                        <td>{item.itemDetails}</td>
                                                                                                                                                                        <td>{item.taskHolderFollowerNames}</td>
                                                                                                                                                                        {/* <td className="Actionbutton fixed-column">
                                                                                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                    <input type="checkbox" name="SelectedDetail"
                                                                                                                                                                                        disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                                                        onChange={event => (OnChangeOrderinfo(event, index))}
                                                                                                                                                                                        checked={item.selectedDetail === 1 ? true : false}
                                                                                                                                                                                        className="colored-blue clearOpacity" />
                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                </label>
                                                                                                                                                                                &nbsp;&nbsp;

                                                                                                                                                                                <button
                                                                                                                                                                                    disabled={inputFields[0].SubmitStatus === "Completed" ? true : false}
                                                                                                                                                                                    type="button"
                                                                                                                                                                                    className="btn  btn-xs  btn-danger"
                                                                                                                                                                                    title="Delete"
                                                                                                                                                                                    onClick={() =>
                                                                                                                                                                                        handleRemoveOrderInfo(index)
                                                                                                                                                                                    }
                                                                                                                                                                                >
                                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                                </button>


                                                                                                                                                                            </div>

                                                                                                                                                                        </td> */}
                                                                                                                                                                    </tr>
                                                                                                                                                                </>

                                                                                                                                                            )
                                                                                                                                                        })
                                                                                                                                                    }

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            :
                                                                                                                                            <table className="table table-bordered table-striped table-condensed flip-content">
                                                                                                                                                <TableHeader headers={headers} />
                                                                                                                                                <tbody>
                                                                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>


                                                                                                                    </div>


                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <div className="col-sm-12 col-lg-12">
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-8 col-lg-8">
                                                                                                                <div className='form-group'>
                                                                                                                    <label htmlFor="Remarks">Remarks</label>
                                                                                                                    <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                                                                        onChange={event => handleChangeSubmit(event, 'Remarks')}
                                                                                                                        disabled={View === true ? true : false}
                                                                                                                        rows="4" style={{ width: "108%" }}></textarea>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-12" align="right">
                                                                                                            <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                                                                                                                disabled={View === true ? true : false}
                                                                                                            >
                                                                                                                <i className="fa fa-check right"></i> &nbsp;Save
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {
                                                                                                        ShowPopup === true ?
                                                                                                            <Modal show={ShowPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => setShowPopup(false)}>
                                                                                                                <Modal.Header closeButton>
                                                                                                                    <Modal.Title>
                                                                                                                        AWB and Date
                                                                                                                    </Modal.Title>
                                                                                                                </Modal.Header>
                                                                                                                <Modal.Body>
                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                                                                                                                                <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                                                                                                                                {/* <th styles={{ width: "100px" }}>Action</th> */}
                                                                                                                            </tr>

                                                                                                                        </thead>
                                                                                                                        <tbody>

                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                        <input type="text"
                                                                                                                                            placeholder="AWB Name"
                                                                                                                                            id={"AWBName"}
                                                                                                                                            name="AWBName"
                                                                                                                                            value={inputFields[0].AWBName === null ? '' : inputFields[0].AWBName}
                                                                                                                                            style={{ border: submittedpopup && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                                                                                                                            autoFocus
                                                                                                                                            maxLength="25"
                                                                                                                                            className={'form-control'}
                                                                                                                                            onChange={event => handlepopupchange(event, 'AWBName')}
                                                                                                                                            menuPosition="fixed"
                                                                                                                                        // disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                                                        />
                                                                                                                                    </span>

                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <DatePicker className="form-control" name="AWBDate"
                                                                                                                                        id={
                                                                                                                                            "AWBDate"
                                                                                                                                        }
                                                                                                                                        selected={
                                                                                                                                            inputFields[0].AWBDateEdit
                                                                                                                                        }
                                                                                                                                        onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                                                        peekNextMonth

                                                                                                                                        dropdownMode="scroll"
                                                                                                                                        autoComplete="off"
                                                                                                                                        customInput={<CustomInputAWB />}
                                                                                                                                        isClearable={inputFields[0].AWBDateEdit === null ? false : true}
                                                                                                                                    />
                                                                                                                                    {/* <span className="input-icon icon-right">
                                                                                                                                        <input id={"AWBDate"} name="AWBDate"
                                                                                                                                            value={inputFields[0].AWBDateEdit} placeholder="DD/MM/YYYY"
                                                                                                                                            type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                                                                                                                            className={'form-control'}
                                                                                                                                            style={{ border: submittedpopup && inputFields[0].AWBDateEdit === '' ? '1px solid red' : '' }}
                                                                                                                                            onChange={event => handlepopupchange(event, 'AWBDate')}
                                                                                                                                        />
                                                                                                                                    </span> */}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Modal.Body>
                                                                                                                <Modal.Footer>
                                                                                                                    <Button variant="success" onClick={() => SaveAwbnoDate()}>
                                                                                                                        <i className="fa fa-check right"></i>&nbsp;
                                                                                                                        Save
                                                                                                                    </Button>
                                                                                                                </Modal.Footer>
                                                                                                            </Modal>
                                                                                                            : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </Modal.Body>

                                                                                        </Modal> : ''

                                                                                }

                                                                                {LapDipSubmissionTab && LapDipSubmissionTab.isView !== 0 &&
                                                                                    subField.manualCloseComment !== '' && subField.submitStatus === "Completed" ?
                                                                                    <button type="button" className="btn btn-xs btn-success" title="View Submitted Manual Close"
                                                                                        onClick={() => ViewSubmittedManualClose(subField.genralLabdipTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                            subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                            subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                            subField.genralLabdipTNASubmissionAndApprovalId)}
                                                                                    >
                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button> : ''
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    LapDipSubmissionTab && LapDipSubmissionTab.isAdd !== 0 &&
                                                                                        subField.approvalManualCloseComment === '' && subField.approvalStatus === "Pending" && subField.isManualSubmission === 1 ?
                                                                                        <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Add Approval Manual Close"
                                                                                            onClick={() => handleManualclose(subField.genralLabdipTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        </Fragment>
                                                                                        : ''
                                                                                    /* subField.manualCloseComment !== '' && subField.approvalStatus === "Completed" ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                            onClick={() => handleManualclose(subField.genralLabdipTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button> : '' */

                                                                                }
                                                                                &nbsp;
                                                                                {LapDipSubmissionTab && LapDipSubmissionTab.isView !== 0 &&
                                                                                    subField.submitStatus === "Completed" && subField.isFormSubmission === 1 ?

                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                        onClick={() => ViewSubmission(subField.genralLabdipTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                            subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                            subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                            subField.genLapdipProgramInfoId, index, 'View')}>

                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button> :
                                                                                    ''
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    LapDipSubmissionTab && LapDipSubmissionTab.isAdd !== 0 &&
                                                                                        subField.isShowApprovalButton === 0 && subField.manualCloseComment === '' && subField.isFormSubmission === 1 ?
                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                            onClick={() => OpenLabdipApprovalpopup(subField.genralLabdipTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId, index)}

                                                                                        >
                                                                                            <i className="fa fa-check"></i>
                                                                                        </button> : ''
                                                                                    /* subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' ?
                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                            onClick={() => OpenLabdipApprovalpopup(subField.genralLabdipTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId, index)}>

                                                                                            <i className="fa fa-eye "></i>
                                                                                        </button> :
                                                                                        '' */

                                                                                }


                                                                                &nbsp;
                                                                                {LapDipSubmissionTab && LapDipSubmissionTab.isEdit !== 0 &&
                                                                                    subField.isFormSubmission === 1 && (
                                                                                        <button type="button" title="Edit Submission" class="btn btn-info btn-xs edit"
                                                                                            onClick={() => ViewSubmission(subField.genralLabdipTNASubmissionAndApprovalId, subField.submitStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId, index, 'Edit')}
                                                                                            disabled={subField.approvalStatus !== "Pending" ? true : false}
                                                                                        ><i class="fa fa-edit"></i></button>
                                                                                    )
                                                                                }



                                                                                &nbsp;
                                                                                {LapDipSubmissionTab && LapDipSubmissionTab.isDelete !== 0 &&
                                                                                    subField.isFormSubmission === 1 && (
                                                                                        <button type="button" title='Delete Submission'
                                                                                            className="btn btn-danger btn-xs delete" onClick={() => OpenDeletPopup(subField.genralLabdipTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskId, subField.pantoneID, subField.genLabdipId,
                                                                                                subField.genLapdipProgramInfoId, index)}
                                                                                            disabled={subField.approvalStatus !== "Pending" ? true : false}
                                                                                        >
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    // new Date() > new Date(subField.EndDate) &&
                                                                                    subField.submitStatus === "Completed" &&
                                                                                    <button type="button" className={subField.isRescheduled === 0 ? "btn btn-xs btn-light" : "btn btn-xs btn-success"} title='Child Reschedule '
                                                                                        onClick={() => OpenReschedulePopup(subField.genralLabdipTNAId, subField.genralLabdipTNASubmissionAndApprovalId
                                                                                            , subField.genralLabdipTNAProgramDetailId)}
                                                                                    >
                                                                                        <i class="fa fa-calendar"></i>
                                                                                    </button>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            {/* <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span> */}
                            {/* <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span> */}
                            {/* <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button> */}
                        </div>
                    </div>
                </div>
            </div >
            {
                getID.showPopupDelete ? <SweetAlertPopup data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                    pageActionId={GenLabdipSubmissionlId}
                    searchParams={getID.SearchParams}
                    Msg={"Lab Dip Submission Deleted Successfully."}
                /> : null
            }

            {
                <SweetAlert
                    show={deletepopup}
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    // closeOnConfirm
                    // closeOnCancel
                    title="Are you sure do you want to delete it?"
                    onConfirm={DeleteMultipleApproval}
                    onCancel={() => setdeletepopup(false)}
                    showCloseButton
                    focusCancelBtn>
                    {/* I did it! */}
                </SweetAlert>
            }
            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Manual Close Submission Deleted Sucessfully'}
            /> : null}

            {
                openReschedulepopup === true &&
                <Modal show={openReschedulepopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setopenReschedulepopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Reschedule Add
                            {
                                getSubRescheduleList.length !== 0 ?
                                    <>
                                        <br />
                                        (Initial Date  Duration : {getSubRescheduleList[0].duration} Start Date : {getSubRescheduleList[0].startDate} End Date : {getSubRescheduleList[0].endDate})
                                    </>
                                    : ''

                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Pantone detail</th>
                                    <th>Details</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Leave Days</th>

                                    {/* <th>Action </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TaskDetails.length !== 0 ?
                                    TaskDetails.filter(t => t.taskName === "Lab Dip Submission").map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.taskName}

                                                        </td>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    item.pantoneCode
                                                                }
                                                            </span>

                                                            {item.imagePath ?
                                                                <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                    <img className="width_manual"
                                                                        src={item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + item.imagePath}
                                                                        alt="Logo"
                                                                        onClick={
                                                                            () => setIsOpen(item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + item.imagePath)
                                                                        }
                                                                    />
                                                                </div> :
                                                                <div style={{ background: item.pantoneColor, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>
                                                            }

                                                        </td>
                                                        <td>
                                                            {item.itemDetails}

                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                value={item.duration}
                                                                onChange={event => handleChangeReschedule(event, index, "Duration")}
                                                                maxLength="3"
                                                                className={'form-control'}
                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildStartdate"
                                                                id={
                                                                    "ChildStartdate"
                                                                }
                                                                selected={Date.parse(moment(item.startDate, 'MM/DD/YYYY').toISOString())}
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildEnddate"
                                                                id={
                                                                    "ChildEnddate"
                                                                }

                                                                selected={Date.parse(moment(item.endDate, 'MM/DD/YYYY').toISOString())}
                                                                onChange={
                                                                    event => handleChangeReschedule(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                customInput={<EndDateCustomInput />}

                                                            />
                                                        </td>
                                                        <td>
                                                            {item.leaveDayName}

                                                        </td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                        :
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                }

                            </tbody>
                        </table>
                        <br />

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    <th>Submission Reschedule Comments<span className="text-danger">*</span></th>
                                    {/* <th>Approval Reschedule Comments<span className="text-danger">*</span></th> */}
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.SubmissionComment}
                                            onChange={event => handleChangeReschedule(event, 0, "SubmissionComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.SubmissionComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>
                                    {/* <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.ApprovalComment}
                                            onChange={event => handleChangeReschedule(event, 0, "ApprovalComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.ApprovalComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td> */}

                                </tr>
                            </tbody>

                        </table>
                        {
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Comments</th>
                                        <th>Rescheduled Date</th>
                                        <th>Rescheduled By</th>
                                        {/* <th>Action </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getSubRescheduleList.length !== 0 ?
                                            getSubRescheduleList.filter(t => t.type === 'Submission').map((item, subindex) => {
                                                //getSubRescheduleList.filter(t => t.type !== 'Initial').map((item, subindex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.type}

                                                            </td>
                                                            <td style={{ width: "80px" }}>
                                                                {item.duration}

                                                            </td>
                                                            <td>
                                                                {item.startDate}

                                                            </td>
                                                            <td>
                                                                {item.endDate}

                                                            </td>
                                                            <td style={{ width: "300px" }}>
                                                                {item.comments}
                                                            </td>
                                                            <td>
                                                                {item.createdDate}

                                                            </td>
                                                            <td>
                                                                {item.rescheduledBy}

                                                            </td>

                                                        </tr>
                                                    </>

                                                )
                                            })
                                            :
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }

                                </tbody>
                            </table>


                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSaveReschedule()}>
                            {/* {getRescheduleAction === 'Add' ? 'Save' : 'Update'} */}
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

            }
            {loader}
        </Fragment >

    )
}

export default React.memo(LabDipViewSubmissionTab);