import React, { useState, useEffect, useRef } from "react";
import UploadImage from "../../Common/UploadImage"
import ReactNotification from 'react-notifications-component';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Address from "../CommonComponent/Address";
import Employee from "../CommonComponent/Employee";
import Holiday from "../CommonComponent/HoliDay";
import companyService from "../../../services/Master/Companyservice";
import 'react-notifications-component/dist/theme.css';
import validator from 'validator';
import { emailExp } from "../../Common/RegExpression";
import Nodify from "../../Common/ReactNotification"
import { CompanyModuleId } from "../../Common/ModuleID";
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";
import CityService from "../../../services/Master/CityService";
import ConfirmationPopup from "../../Common/ConfirmationPopup";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)




const CompanyRegisterNew = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPage, setPage] = useState([]);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} });


    // //Enable Menu

    let activeMenu;
    let activeMenuSub;
    let AddressInfo;
    let AddressInfoQuick;
    let EmployeeInfo;
    let EmployeeInfoQuick = [];
    //let EmployeeInfoQuick = [{ Role: 0, Department: 0 }];
    let HolidayInfo;
    debugger;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {

        activeMenu = menulist.filter(i => i.menuName === 'Company');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'CompanyAddress') {

                        AddressInfo = activeMenuSub[index];
                        AddressInfoQuick = menulist.filter(i => i.menuName === 'Branch');

                    }
                    else if (activeMenuSub[index].menuName === 'CompanyEmployee') {

                        EmployeeInfo = activeMenuSub[index];
                        //EmployeeInfoQuick.EmployeeInfo = activeMenuSub[index];
                        EmployeeInfoQuick.Role = menulist.filter(i => i.menuName === 'Role')
                        EmployeeInfoQuick.Department = menulist.filter(i => i.menuName === 'Department')

                        //  EmployeeInfoQuick.push(menulist.filter(i => i.menuName === 'Role'));
                        //  EmployeeInfoQuick.push(menulist.filter(i => i.menuName === 'Department'));
                    }
                    else if (activeMenuSub[index].menuName === 'CompanyHoliday') {
                        HolidayInfo = activeMenuSub[index];

                    }

                }
            }
        }
    }
    debugger;
    // // End Enable Menu

    const [refershstate, setrefershstate] = useState(0)
    const [getCompanyList, setCompanyList] = useState({});
    const [getCompanyAddressList, setCompanyAddressList] = useState({});
    const [getCompanyEmployeeList, setCompanyEmployeeList] = useState({});
    const [getCompanyHolidayList, setCompanyHolidayList] = useState({});
    const [cityList, setcityList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [BranchList, setBranchList] = useState([]);
    const [genderList, setgenderList] = useState([]);
    const [AddressList, setAddressList] = useState([]);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [HolidayList, setHolidayList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [submitted, setSubmitted] = useState({ Company: false, Reference: false });
    const [checked, setChecked] = useState({ CompanyStaus: true, CompanyAdmin: true });
    const [getstate_CompanyID, setstate_CompanyID] = useState(0);
    const [getHeaderName, addHeaderName] = useState("Add");
    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [IsParentCallback, setIsParentCallback] = useState(false);
    const [IsEmpParentCallback, setIsEmpParentCallback] = useState(false);
    const [IsHolidayParentCallback, setIsHolidayParentCallback] = useState(false);
    const inputRef = useRef();


    function SetCompany(props) {
        let CompanyValue = {
            Operation: 1,
            CompanyID: 0,
            Name: '',
            ReferenceName: '',
            LogoName: '',
            LogoPath: '',
            Status: 1,
            Tabletype: CompanyModuleId,   // 2 => Company List
            Createdby: currentUser.employeeinformationID
        };
        //setButtonLoader(false);
        setPage('CompanyRegister');
       
        if (CombuysubId !== null) {
           
            setstate_CompanyID(CombuysubId);
            setButtonName("Update");
            CompanyValue.Operation = 1;
            CompanyValue.CompanyID = props[0].companyBuyerSupID;
            CompanyValue.Name = validator.trim(props[0].name);
            CompanyValue.ReferenceName = validator.trim(props[0].referenceName);
            CompanyValue.LogoName = props[0].logoName;
            CompanyValue.LogoPath = props[0].logoPath;
            CompanyValue.Status = props[0].activeStatus;
            CompanyValue.Tabletype = props[0].tabletype;
            SetButtonvisible(props[0].action);
            if (CompanyValue.Status === 1) {
                setChecked({ CompanyStaus: checked });
            } else {
                setChecked({
                    CompanyStaus: !checked
                });
            }

            if (CombuysubId) {
                addHeaderName("Add");
            } else {
                addHeaderName("Edit");
            }
            return CompanyValue;
        }
        else if (props.location.state !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            if (props.location.state && props.location.state.params.length !== 0) {
                if (props.location.state.params.companyBuyerSupID !== "") {
                    setstate_CompanyID(props.location.state.params.companyBuyerSupID);
                    setButtonName("Update");
                    CompanyValue.Operation = 2;
                    CompanyValue.CompanyID = props.location.state.params.companyBuyerSupID;
                    CompanyValue.Name = validator.trim(props.location.state.params.name);
                    CompanyValue.ReferenceName = validator.trim(props.location.state.params.referenceName);
                    CompanyValue.LogoName = props.location.state.params.logoName;
                    CompanyValue.LogoPath = props.location.state.params.logoPath;
                    CompanyValue.Status = props.location.state.params.activeStatus;
                    CompanyValue.Tabletype = props.location.state.params.tabletype;
                    SetButtonvisible(props.location.state.params.action);
                    if (CompanyValue.Status === 1) {
                        setChecked({ CompanyStaus: checked });
                    } else {
                        setChecked({
                            CompanyStaus: !checked
                        });
                    }

                    if (props.location.state.params.action !== 'Edit') {
                        addHeaderName("View");
                    } else {
                        addHeaderName("Edit");
                    }
                    return CompanyValue;
                }
            }
        } else {
            return CompanyValue;
        }
    }
    const CombuysubId = JSON.parse(localStorage.getItem("CompanySubbuyID"));
    useEffect(() => {
       
        // Company Information
        if (props.location.state.params.action === 'Edit') {
            let getCompany = SetCompany(props);
            setCompanyList(getCompany)
        } else {
            if (CombuysubId !== null) {
                companyService.getAllCompanyBuyerSupList(CombuysubId, 2).then(async (res) => {
                    if (res.data) {
                        let getCompany = await SetCompany(res.data);
                        setCompanyList(getCompany)
                    }
                })
            } else {
                let getCompany = SetCompany(props);
                setCompanyList(getCompany)

            }
        }

        companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });

        companyService.loadGenderList().then((response) => {
            if (response.data) {
                setgenderList(response.data);
            }
        }).catch(() => { });

        companyService.loadAddressTypeList().then((response) => {
            if (response.data) {
                setBranchList(response.data);
            }
        }).catch(() => { });

        CityService.loadStateList().then((response) => {
            if (response.data) {
                setstateList(response.data);
            }
        }).catch(() => { });

        CityService.loadCountryList().then((response) => {
            if (response.data) {
                setcountryList(response.data);
            }
        }).catch(() => { });
        // AddressList
        if (props.location.state && props.location.state.params.companyBuyerSupID !== undefined && (props.location.state.params.action === 'Edit' || props.location.state.params.action === 'View')) {
            // 1 => Address List Against Company
            companyService.BindAddressList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            // 2 => Employee List Against Company
            companyService.BindEmployeeList(props.location.state.params.companyBuyerSupID).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            // 3 => Employee List Against Company
            companyService.BindHolidayList(props.location.state.params.companyBuyerSupID, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });
        } else if (props.location.state.params.action === 'Add' && CombuysubId !== null) {
            // 1 => Address List Against Company
            companyService.BindAddressList(CombuysubId).then((response) => {
                if (response.data) {
                    setAddressList(response.data);
                }
            }).catch(() => { });

            // 2 => Employee List Against Company
            companyService.BindEmployeeList(CombuysubId).then((response) => {
                if (response.data) {
                    setEmployeeList(response.data);
                }
            }).catch(() => { });

            // 3 => Employee List Against Company
            companyService.BindHolidayList(CombuysubId, new Date().getFullYear()).then((response) => {
                if (response.data) {
                    setHolidayList(response.data);
                }
            }).catch(() => { });
        }
    }, [refershstate]);

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/Company')
            localStorage.removeItem("CompanySubbuyID");
        } else {
            // props.history.push('/companyRegister')
            // setSubmitted({ Company: false, Reference: false });
            // let getCompany = SetCompany(props);
            // setCompanyList(getCompany);
            window.location.reload();
        }
    }

    const handleChange = (e) => {
       
        let companyValue = getCompanyList;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            setSubmitted(true);
        }
        if (e.target.type === 'checkbox') {
            if (!e.target.checked) {
                companyValue[e.target.name] = 0;
            } else {
                companyValue[e.target.name] = 1;
            }
            setChecked({ CompanyStaus: e.target.checked });

        }
        else {
            if (e.target.name === "Name") {
                if (submitted.Reference === true) {
                    setSubmitted({ company: false, Reference: true });
                }
                else {
                    setSubmitted({ company: false, Reference: false });
                }
            }

            else if (e.target.name === "ReferenceName") {
                if (submitted.company === true) {
                    setSubmitted({ company: true, Reference: false });
                }
                else {
                    setSubmitted({ company: false, Reference: false });
                }
            }
            companyValue[e.target.name] = inputText
        }
        setCompanyList(companyValue);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/Company",
            state: {
                message: Func
            }
        });
    }

    const AddCompanyback = (value) => {
        getCompanyList.LogoName = value;
    }

    const Addcallback = async (value) => {
       
        setCompanyAddressList(value);
        await companyService.loadCityList().then((response) => {
            if (response.data) {
                setcityList(response.data);
            }
        }).catch(() => { });
        //setIsParentCallback(false);
    }

    const Empcallback = (value) => {
        setCompanyEmployeeList(value);
        //setIsEmpParentCallback(false);
    }

    const Holidaycallback = (value) => {
        setCompanyHolidayList(value);
        //setIsHolidayParentCallback(false);
    }

    const [CompanyinputFields, setCompanyinputFields] = useState([{
        AddressInformationID: 0,
        AddressType: 0,
        Country: 0,
        CompanyState: 0,
        City: 0,
        PrimaryContactNumber: '',
        SecondaryContactNumber: '',
        PrimaryEmailAddress: '',
        SecondaryEmailAddress: '',
        PostalCode: '',
        Website: '',
        AddressLine1: '',
        AddressLine2: '',
        Latitude: '',
        Longitude: '',
        IsDeleted: 0
    }]);

    const [EmployeeinputFields, setEmployeeinputFields] = useState([{
        EmployeeinformationID: 0,
        EmpFirstName: '',
        EmpLastName: '',
        EmpDateofBirth: '',
        EmpGender: 0,
        EmpDateofJoin: '',
        EmpDepartmentID: 0,
        EmpUserName: '',
        EmpPassword: '',
        ConfirmPassword: '',
        EmpCompanyAdministrator: 0,
        EmpAddressLine1: '',
        EmpAddressLine2: '',
        EmpCity: 0,
        EmpState: 0,
        EmpCountry: 0,
        EmpPostalCode: '',
        EmpPrimaryContactNumber: '',
        EmpSecondaryContactNumber: '',
        EmpProfilePicName: '',
        Content: [],
        EmpProfilePicPath: '',
        ActiveStatus: 0,
        IsDeleted: 0
    }]);

    const [HolidayinputFields, setHolidayinputFields] = useState([{
        HolidayInformationID: 0,
        HolidayName: '',
        HolidayDate: new Date(),
        HolidayEndDate: '',
        IsDeleted: 0
    }]);

    const viewtoEdit = (e) => {
        SetButtonvisible("Edit");
        addHeaderName("EDIT");
    }

    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });
            setButtonName("Update");
            addHeaderName("EDIT");
            localStorage.removeItem("CompanySubbuyID");
        } else {
            let x = Math.floor((Math.random() * 100) + 1);
            setrefershstate(x);
            setID({ showPopupConfirm: false, Params: [] });
        }
    }


    const SaveCompany = (e) => {
        setButtonLoader(true);

        let IsValid = true;
        let ValidationMsg = "";
        setSubmitted({ Company: true, Reference: true });
        e.preventDefault();
        if (getCompanyList.Name.trim() === '' && getCompanyList.ReferenceName.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Please fill all mandatory (*) fields.");
        }
        else if (getCompanyList.Name.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Company Name is required.");
        }
        else if (getCompanyList.ReferenceName.trim() === '') {
            setButtonLoader(false);
            ValidationPopup("Reference Name is required.");
        }

        if (getCompanyList.Name !== '' && getCompanyList.ReferenceName !== '') {
            getCompanyList.Name = getCompanyList.Name.trim();
            getCompanyList.ReferenceName = getCompanyList.ReferenceName.trim();
            if (getCompanyAddressList.length === undefined) {
                getCompanyList.CompanyInformation = CompanyinputFields;
            }
            else {
                for (var index = 0; index < getCompanyAddressList.length; index++) {
                    if (getCompanyAddressList[index].AddressType === 0
                        && getCompanyAddressList[index].City === 0
                        && getCompanyAddressList[index].PrimaryContactNumber === ""
                        && getCompanyAddressList[index].PostalCode === ""
                        && getCompanyAddressList[index].AddressLine1 === ""
                        && getCompanyAddressList[index].AddressLine2 === ""
                        && getCompanyAddressList[index].PrimaryEmailAddress === ""
                        && getCompanyAddressList[index].Website === "") {
                        //   getCompanyAddressList[index].IsDeleted = 1;
                    }
                    else if (getCompanyAddressList[index].AddressType > 0 && getCompanyAddressList[index].City > 0 && getCompanyAddressList[index].PrimaryContactNumber !== "" && getCompanyAddressList[index].PostalCode !== "" && getCompanyAddressList[index].AddressLine1 !== "") {
                        getCompanyAddressList[index].AddressLine1 = getCompanyAddressList[index].AddressLine1.trim();
                        getCompanyAddressList[index].AddressLine2 = getCompanyAddressList[index].AddressLine2.trim();
                        getCompanyAddressList[index].PrimaryEmailAddress = getCompanyAddressList[index].PrimaryEmailAddress.trim();
                        getCompanyAddressList[index].Website = getCompanyAddressList[index].Website.trim();

                        if (getCompanyAddressList[index].PrimaryEmailAddress !== "") {
                            if (emailExp.test(getCompanyAddressList[index].PrimaryEmailAddress) === false) {
                                IsValid = false;
                                if (getCompanyAddressList[index].AddressTypeName !== undefined) {
                                    ValidationMsg = "Email address is not valid in Address tab (" + getCompanyAddressList[index].AddressTypeName + ")";
                                    //getCompanyAddressList[index].PrimaryEmailAddress === '';
                                }
                                else {
                                    ValidationMsg = "Email address is not valid in Address tab";
                                    //getCompanyAddressList[index].PrimaryEmailAddress === '';
                                }

                                setButtonLoader(false);
                                ValidationPopup(ValidationMsg);
                                setIsParentCallback(true);
                                getCompanyAddressList[index].PrimaryEmailAddress = '';
                                return false;
                            }
                        }
                        // if (getCompanyAddressList[index].PrimaryContactNumber.length !== 20) {
                        //     IsValid = false;
                        //     if (getCompanyAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Contact number is not valid in Address tab (" + getCompanyAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Contact number is not valid in Address tab";
                        //     }
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;

                        // }
                        // if (getCompanyAddressList[index].PostalCode.length !== 20) {
                        //     IsValid = false;
                        //     if (getCompanyAddressList[index].AddressTypeName !== undefined) {
                        //         ValidationMsg = "Postal code is not valid in Address tab (" + getCompanyAddressList[index].AddressTypeName + ")";
                        //     }
                        //     else {
                        //         ValidationMsg = "Postal code is not valid in Address tab";
                        //     }
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsParentCallback(true);
                        //     return false;
                        // }
                    }
                    else if (getCompanyAddressList[index].IsDeleted !== 1) {
                        IsValid = false;
                        if (getCompanyAddressList[index].AddressTypeName !== undefined) {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab (" + getCompanyAddressList[index].AddressTypeName + ")";
                        }
                        else {
                            ValidationMsg = "Please fill all mandatory (*) fields in Address tab";
                        }
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsParentCallback(true);
                        return false;
                    }
                }
                getCompanyList.CompanyInformation = getCompanyAddressList;
            }

            if (getCompanyEmployeeList.length === undefined) {
                getCompanyList.EmployeeInformation = EmployeeinputFields;
            }
            else {
                for (var index = 0; index < getCompanyEmployeeList.length; index++) {
                    if (getCompanyEmployeeList[index].EmpFirstName === ''
                        && getCompanyEmployeeList[index].EmpLastName === ''
                        && getCompanyEmployeeList[index].EmpGender === 0
                        && (getCompanyEmployeeList[index].EmpDateofBirth === '' || getCompanyEmployeeList[index].EmpDateofBirth === null)
                        && (getCompanyEmployeeList[index].EmpDateofJoin === '' || getCompanyEmployeeList[index].EmpDateofJoin === null)
                        && getCompanyEmployeeList[index].EmpDepartmentID === 0
                        && getCompanyEmployeeList[index].EmpRoleID === 0
                        && getCompanyEmployeeList[index].EmpAddressLine1 === ''
                        && getCompanyEmployeeList[index].EmpAddressLine2 === ''
                        && getCompanyEmployeeList[index].EmpCity === 0
                        && getCompanyEmployeeList[index].EmpCompanyAdministrator === 0
                        && getCompanyEmployeeList[index].ActiveStatus === 1
                        && getCompanyEmployeeList[index].EmpPostalCode === ''
                        && getCompanyEmployeeList[index].EmpPrimaryContactNumber === ''
                        && getCompanyEmployeeList[index].EmpUserName === ''
                        && getCompanyEmployeeList[index].EmpPassword === ''
                        && getCompanyEmployeeList[index].ConfirmPassword === '') {
                        //   getCompanyEmployeeList[index].IsDeleted = 1;
                    }
                    else if (getCompanyEmployeeList[index].EmpFirstName && getCompanyEmployeeList[index].EmpLastName && getCompanyEmployeeList[index].EmpGender && getCompanyEmployeeList[index].EmpRoleID && getCompanyEmployeeList[index].EmpAddressLine1 && getCompanyEmployeeList[index].EmpCity && getCompanyEmployeeList[index].EmpPostalCode && getCompanyEmployeeList[index].EmpPrimaryContactNumber && getCompanyEmployeeList[index].EmpUserName && getCompanyEmployeeList[index].EmpPassword && getCompanyEmployeeList[index].ConfirmPassword) {
                        getCompanyEmployeeList[index].EmpFirstName = getCompanyEmployeeList[index].EmpFirstName.trim();
                        getCompanyEmployeeList[index].EmpLastName = getCompanyEmployeeList[index].EmpLastName.trim();
                        getCompanyEmployeeList[index].EmpAddressLine1 = getCompanyEmployeeList[index].EmpAddressLine1.trim();
                        getCompanyEmployeeList[index].EmpAddressLine2 = getCompanyEmployeeList[index].EmpAddressLine2.trim();
                        getCompanyEmployeeList[index].EmpUserName = getCompanyEmployeeList[index].EmpUserName.trim();
                        getCompanyEmployeeList[index].EmpPassword = getCompanyEmployeeList[index].EmpPassword.trim();
                        getCompanyEmployeeList[index].ConfirmPassword = getCompanyEmployeeList[index].ConfirmPassword.trim();

                        if (getCompanyEmployeeList[index].EmpPassword.length < 8) {
                            IsValid = false;
                            ValidationMsg = "Password should be above 7 characters for (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (getCompanyEmployeeList[index].EmpPassword !== getCompanyEmployeeList[index].ConfirmPassword) {
                            IsValid = false;
                            ValidationMsg = "Password and Confirm password is must be same for (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            return false;
                        }
                        if (emailExp.test(getCompanyEmployeeList[index].EmpUserName) === false) {
                            IsValid = false;
                            ValidationMsg = "Email Address is not valid in Employee Tab (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsEmpParentCallback(true);
                            getCompanyEmployeeList[index].EmpUserName = '';
                            return false;
                        }
                        // if (getCompanyEmployeeList[index].EmpPrimaryContactNumber.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Contact number is not valid in Employee Tab (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                        // if (getCompanyEmployeeList[index].EmpPostalCode.length !== 20) {
                        //     IsValid = false;
                        //     ValidationMsg = "Postal code is not valid in Employee Tab (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                        //     setButtonLoader(false);
                        //     ValidationPopup(ValidationMsg);
                        //     setIsEmpParentCallback(true);
                        //     return false;
                        // }
                    }
                    else if (getCompanyEmployeeList[index].IsDeleted !== 1) {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Employee tab (" + getCompanyEmployeeList[index].EmpFirstName + " " + getCompanyEmployeeList[index].EmpLastName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsEmpParentCallback(true);
                        return false;
                    }

                    if (getCompanyEmployeeList[index] !== undefined) {
                        if (getCompanyEmployeeList[index].EmpDateofBirth !== "" && getCompanyEmployeeList[index].EmpDateofBirth !== null && getCompanyEmployeeList[index].EmpDateofBirth !== undefined) {
                            getCompanyEmployeeList[index].EmpDateofBirth.setDate(getCompanyEmployeeList[index].EmpDateofBirth.getDate() + 1);
                        }
                        if (getCompanyEmployeeList[index].EmpDateofJoin !== "" && getCompanyEmployeeList[index].EmpDateofJoin !== null && getCompanyEmployeeList[index].EmpDateofJoin !== undefined) {
                            getCompanyEmployeeList[index].EmpDateofJoin.setDate(getCompanyEmployeeList[index].EmpDateofJoin.getDate() + 1);
                        }
                    }
                }
                getCompanyList.EmployeeInformation = getCompanyEmployeeList;
            }

            if (getCompanyHolidayList.length === undefined) {
                getCompanyList.HolidayInformation = HolidayinputFields;
            }
            else {
                for (var index = 0; index < getCompanyHolidayList.length; index++) {
                    var IsEmpty = getCompanyHolidayList.filter((x => ((x.HolidayName === '' || x.HolidayDate === null) && x.IsDeleted === 0)));
                    if (IsEmpty.length !== 0) {
                        if (IsEmpty[0].HolidayName === '' || IsEmpty[0].HolidayDate === null) { }
                        else {
                            IsValid = false;
                            ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getCompanyHolidayList[index].HolidayName + ")";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            setIsHolidayParentCallback(true);
                            return false;
                        }
                    }
                    if (getCompanyHolidayList[index].HolidayName === ''
                        && (getCompanyHolidayList[index].HolidayDate === '' || getCompanyHolidayList[index].HolidayDate === null)
                        && (getCompanyHolidayList[index].HolidayEndDate === '' || getCompanyHolidayList[index].HolidayEndDate === null)) {
                        //   getCompanyHolidayList[index].IsDeleted = 1;
                    }
                    else if (getCompanyHolidayList[index].HolidayName && getCompanyHolidayList[index].HolidayDate) {
                        getCompanyHolidayList[index].HolidayName = getCompanyHolidayList[index].HolidayName.trim();
                        getCompanyHolidayList[index].HolidayDate.setDate(getCompanyHolidayList[index].HolidayDate.getDate() + 1);
                        if (getCompanyHolidayList[index].HolidayEndDate !== "" && getCompanyHolidayList[index].HolidayEndDate !== null && getCompanyHolidayList[index].HolidayEndDate !== undefined) {
                            getCompanyHolidayList[index].HolidayEndDate.setDate(getCompanyHolidayList[index].HolidayEndDate.getDate() + 1);
                        }
                    }
                    else if (getCompanyHolidayList[index].IsDeleted !== 1) {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields in Holiday tab (" + getCompanyHolidayList[index].HolidayName + ")";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        setIsHolidayParentCallback(true);
                        return false;
                    }
                }
                getCompanyList.HolidayInformation = getCompanyHolidayList;
            }

            if (IsValid) {
                getCompanyList.LogoPath = '';
                if (getCompanyList.LogoName !== '' && getCompanyList.LogoName !== undefined) {
                    let val = "/Logo/" + getCompanyList.LogoName;
                    getCompanyList.LogoPath = val;
                }

               ;
                getCompanyList.Operation = ButtonName === 'Update' ? 2 : 1;
                companyService.InsertUpdateCompany(getCompanyList).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    res.data.Func = Func;
                    res.data.tabPage = getPage;
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        setID({ showPopupConfirm: true, Params: res.data });
                        localStorage.setItem('CompanySubbuyID', (res.data.outputResultStyleID));

                        //PageRedirect(Func);
                    } else if (res.data.outputResult === "2") {
                        Func = 'Edit';
                        res.data.Func = Func
                        setID({ showPopupConfirm: true, Params: res.data });
                        // PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {

                        ValidationPopup("Company name already exists!");
                    }
                    else if (res.data.outputResult === "0") {
                        // setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                    setButtonLoader(false);
                });
            }
        }
        else {
            scrollToRef(inputRef);
        }
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);

        return false;
    }
    return (
        <div className="page-body">
            <ReactNotification />


            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeaderName} Company</h3>
                            <div className="buttons-preview" align="right">

                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                {Buttonvisible !== "View" &&
                                    (<button type="submit" className="btn btn-success" onClick={SaveCompany} disabled={buttonLoader}>

                                        <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' :
                                    activeMenu && activeMenu[0].isView === 0 ? '' :
                                        (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                            <i className="fa fa-edit"></i>&nbsp;Edit
                                        </button>)
                                }
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className='form-group'>
                                                <label htmlFor="Name">Company Name<span className="text-danger">*</span></label>
                                                <span className='input-icon icon-right'>
                                                    <Input placeholder="Enter Company Name" id="Name" name="Name" //className="form-control"
                                                        value={getCompanyList.Name}
                                                        type="text"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        maxLength="50"
                                                        autoFocus
                                                        ref={inputRef}
                                                        className={'form-control' + (
                                                            submitted.Company && !getCompanyList.Name ? ' is-invalid' : ''
                                                        )}
                                                        style={{ border: submitted.Company && !getCompanyList.Name ? '1px solid red' : '' }}
                                                    />
                                                </span>

                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="ReferenceName">Reference Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <span className="input-icon icon-right">
                                                    <Input placeholder="Enter Reference Name" id="ReferenceName" name="ReferenceName" // className="form-control"
                                                        value={getCompanyList.ReferenceName}
                                                        type="text"
                                                        maxLength="50"
                                                        autoComplete="off"
                                                        onChange={handleChange}
                                                        ref={inputRef}
                                                        className={'form-control' + (
                                                            submitted.Reference && !getCompanyList.ReferenceName ? ' is-invalid' : ''
                                                        )}
                                                        style={{ border: submitted.Reference && !getCompanyList.ReferenceName ? '1px solid red' : '' }}
                                                    />
                                                </span>

                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Status">Status</label>
                                                <span className="input-icon icon-right">
                                                    <input className="checkbox-slider checkbox-slider_Status colored-blue" id="Status" name="Status"
                                                        value={getCompanyList.Status} checked={checked.CompanyStaus}
                                                        autoComplete="off" onChange={handleChange} type="checkbox">
                                                    </input>
                                                    <span className="text"></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 pull-right">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">Company Logo</label>
                                                <span className="text-danger">(Click on the image to upload new)</span><br />
                                                <UploadImage UploadCallback={AddCompanyback} PageName='Logo' filePath={getCompanyList.LogoPath} Buttonview={Buttonvisible} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                            <li className="active" >
                                <a data-toggle="tab" href="#idCompanyAddress">
                                    <h5>Address</h5>
                                </a>
                            </li>
                            <li className="">
                                <a data-toggle="tab" href="#idCompanyEmployee">
                                    <h5>Employee</h5>
                                </a>
                            </li>
                            <li className="">
                                <a data-toggle="tab" href="#idCompanyHoliday">
                                    <h5>Holiday</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div id="idCompanyAddress" className="tab-pane in active" style={{ width: "100%" }}>
                                {AddressInfo && AddressInfo.isList === 1 ?
                                    <Address
                                        addressCallback={Addcallback}
                                        IsValidationCallback={IsParentCallback}
                                        citylist={cityList}
                                        branchlist={BranchList}
                                        AddressList={AddressList}
                                        Id={getstate_CompanyID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={AddressInfo}
                                        AddressInfoQuick={AddressInfoQuick}
                                        PageName={"company"}
                                        stateList={stateList}
                                        countryList={countryList}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>
                            <div id="idCompanyEmployee" className="tab-pane" style={{ width: "100%" }}>
                                {EmployeeInfo && EmployeeInfo.isList === 1 ?
                                    <Employee employeeCallback={Empcallback}
                                        IsValidationCallback={IsEmpParentCallback}
                                        citylist={cityList}
                                        genderlist={genderList}
                                        EmployeeList={EmployeeList}
                                        Id={getstate_CompanyID}
                                        Buttonview={Buttonvisible}
                                        PageName={"Company"}
                                        MenuPermission={EmployeeInfo}
                                        EmployeeInfoQuick={EmployeeInfoQuick}
                                        props={props}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>
                            <div id="idCompanyHoliday" className="tab-pane" style={{ width: "100%" }}>
                                {HolidayInfo && HolidayInfo.isList === 1 ?
                                    <Holiday holidayCallback={Holidaycallback}
                                        IsValidationCallback={IsHolidayParentCallback}
                                        HolidayList={HolidayList}
                                        Id={getstate_CompanyID}
                                        Buttonview={Buttonvisible}
                                        MenuPermission={HolidayInfo}
                                        PageName={"Company"}
                                    />
                                    : <span><h3 className="red">User has access denied. Please contact the administrator! </h3></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <a className="btn btn-primary" href="/Company">
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </a>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        {Buttonvisible !== "View" &&
                            (<button type="submit" className="btn btn-success" onClick={SaveCompany} disabled={buttonLoader}>

                                <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                            </button>)
                        }
                        {Buttonvisible === "View" && activeMenu && activeMenu[0].isView === 1 ? '' :
                            activeMenu && activeMenu[0].isView === 0 ? '' :
                                (<button type="submit" style={{ display: activeMenu && activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                    <i className="fa fa-edit"></i>&nbsp;Edit
                                </button>)
                        }
                        {/* style={{ display: activeMenu[0].isView === 1 ? '' : 'none' }} */}
                    </div>
                </div>
            </div>
            {
                getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                /> : null
            }
        </div>
    );
};


export default CompanyRegisterNew;