import React, { useState, useEffect,useRef, useMemo } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import { LoadReQAInspectionList } from "../../../../actions/inspectionQC";
import Nodify from "../../../Common/ReactNotification";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { QaReinspectioncmt } from "../../../Common/PageActionNumber";
import { Modal, Button } from "react-bootstrap";
import { Pagination, PageCount } from "../../../Datatable";
import TableHeader from '../../../Datatable/Header/commetstyle'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import QAService from "../../../../services/InspectionQC/InspectionQCService";
import { Fragment } from "babel-plugin-react-html-attrs";
const ToDoReinspectionQA = ({ props, Taskcallback }) => {
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [getreportnosearch, setreportnoSearch] = useState("");
    const [Commentpopup, setCommentpopup] = useState(false);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [skuqtylist, setskuqtylist] = useState([]);
    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [getheaderName, setheaderName] = useState("");
    const [getFIRemark, setFIRemark] = useState([])
    const [Ecomment, setFIRemarks] = useState();
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [openReInspopup, setopenReInspopup] = useState(false);
    const [ReInspectionDetailList, setReInspectionDetailList] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
    const [getInspectionInfo, setInspectionInfo] = useState({});
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getIDfinalEntry, setIDfinalEntry] = useState({ showPopupDelete: false, Params: {} });
    const [getIDcomment, setIDcomment] = useState({ showPopupDelete: false, Params: {} });
    const [show, setShow] = useState(false);
    const [checkboxStates, setCheckboxStates] = useState({
        CreatedDate: true,
        EntryNo: true,
        InspectionType: true,
        ReInspectionDoneAgainst: true,
        Brand: true,
        Season: true,
        Supplier: true,
        Factory: true,
        CustomerShipmentRefNo: true,
        IDNo: true,
        StyleName: true,
        StyleNo: true,
        SKUName: true,
        SKUImg: true,
        orderQty: true,
        InspectionQuantity: true,
        InspectionResult: true,
        CreatedBy: true,
        ApprovalResult: true,
    });
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultApStartDate = new Date();
    const [stateApDate, setStateApDate] = useState([
        {

            startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    const QAReInspectionList = reducerState.inspectionQC.ReQAInspectionList !== undefined ? reducerState.inspectionQC.ReQAInspectionList : reducerState.inspectionQC.ReQAInspectionList;
    const isLoadingQADetailsReport = reducerState.inspectionQC.isLoadingQADetailsReport;
    debugger;
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

     //Enable Menu
  let activeMenu;
  let activeMenuSub;
  let InternalComments;
  let info;
  const menulist = useSelector((state) => state.common.MenuList.item3);
  const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
  if (menulist !== undefined) {
      info = IsProduction === 1 ? 'InternalQAAudit' : (IsProduction === 2 ? 'InternalQAAudit' : undefined);
      activeMenu = menulist.filter(i => i.menuName ===  info );
      
      if (activeMenu.length > 0) {
          activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
          if (activeMenuSub.length > 0) {
               for (let index = 0; index < activeMenuSub.length; index++) {
                        if (activeMenuSub[index].menuName === 'InternalReTest') 
                            { InternalComments = activeMenuSub[index];}
               
                }
            }
      }
      
  }
  // End Enable Menu

    
    QAReInspectionList.map((x) => {
        if (x.quarterName === null || x.quarterName === undefined) {
            x.quarterName = ''
        }
    })
    const datePickerreport = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerreport.current && !datePickerreport.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
            
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(Defaultvalue);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        dispatch(LoadReQAInspectionList(params));
    }, [dispatch]);
    // loader
    useEffect(() => {
        if (!isLoadingQADetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingQADetailsReport]);

    let Size = '120px';
    const tableHeader = [
        { name: "#", field: "", sortable: false, widthsize: "2%", left: 0 },
        checkboxStates.CreatedDate && (
            {
                name: "Entry No / Date",
                field: "insCreatedDate",
                sortable: true,
                widthsize: Size,
                left: 0,
            }
        )
        ,
        // checkboxStates.EntryNo && ({
        //     name: "Entry No",
        //     field: "qcInspectionID",
        //     sortable: true,
        //     widthsize: "3%",
        //     left: "120px",

        // })

        // , checkboxStates.InspectionType && ({
        //     name: "Report Type",
        //     field: "reportType",
        //     sortable: true,

        //     widthsize: "4%",
        //     left: "240px",

        // })

        , checkboxStates.Supplier && ({
            name: "Supplier",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: Size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        })
        // , checkboxStates.Factory && ({
        //     name: "Factory",
        //     field: "factory",
        //     sortable: true,
        //     //width: "4%",
        //     widthsize: "120px",
        //     // position: "sticky",
        //     left: "858px",
        //     //background: "#222d32",
        // })
        , checkboxStates.StyleName && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: Size,
            //width: "7%",
        })
        // , checkboxStates.StyleNo && ({
        //     name: "Style No",
        //     field: "styleNo",
        //     sortable: true,
        //     widthsize: Size,
        //     // width: "7%",
        // })
        , checkboxStates.SKUName && ({
            name: "SKU Name",
            field: "skuName",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.IDNo && ({
            name: "PO/ID No",
            field: "idpono",
            sortable: true,
            widthsize: Size,
            //width: "4%",
        })
        , checkboxStates.SKUImg && ({
            name: "SKU Image",
            field: "skuImg",
            sortable: true,
            widthsize: Size,
        })
        // , checkboxStates.IDNo && ({
        //     name: "FO PO No",
        //     field: "foidpono",
        //     sortable: true,
        //     widthsize: "120px",
        //     //width: "4%",
        // })

        , checkboxStates.orderQty && ({
            name: "Order Qty",
            field: "ordQty",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.InspectionQuantity && ({
            name: "Report Qty",
            field: "inspectionQty",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.InspectionResult && ({
            name: "Report Status",
            field: "inspectionResult",
            sortable: true,
            widthsize: Size,
            // width: "4%",
        })

        , checkboxStates.ApprovalResult && ({
            name: "Approval Status",
            field: "approvalResult",
            sortable: true,
            widthsize: Size,
            // width: "2%",
        })
        , checkboxStates.InspectionResult && ({
            name: "Re Ins Status",
            field: "inspectionResult",
            sortable: true,
            widthsize: Size,
            // width: "4%",
        })
        // , checkboxStates.CreatedBy && ({
        //     name: "Created By",
        //     field: "createdBy",
        //     sortable: true,
        //     widthsize: "4%",
        //     // width: "2%",
        // })


        ,
        {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            width: "120px",
            widthsize: Size,
            //display: InternalComments &&  InternalComments.isDelete === 0 &&  InternalComments.isView === 0 &&  InternalComments.isEdit === 0  && InternalComments.isAdd === 0  ? 'none' : ''
            display: InternalComments && InternalComments.isAdd === 0  ? 'none' : ''
            //left: "736px",
        }
    ];


    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }


        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        dispatch(LoadReQAInspectionList(params));
    }
    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            setIsProductionStatussearch(inputText);
        }
        else if (filterName === 'ReportNo') {

            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setreportnoSearch(inputText);
        }
        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        }
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };

    const resetFinalIns = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateDate(emptyStateDate);
        setStateApDate(emptyStateExDate);

        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        dispatch(LoadReQAInspectionList(params));
        //EmptyCheck();
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateDate[0].isShow = isShow;
        getStateApDate[0].isShow = false;
        setStateApDate(getStateApDate);
        setStateDate(getStateDate);
    }

    const handleclick = () => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);

    }
    function DateRangechange(item) {

        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        dispatch(LoadReQAInspectionList(params));
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const commentsData = useMemo(() => {
        let computedComments = QAReInspectionList;
        let drpvalue = [];

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }
        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment => {
                comment.customerName == null ? comment.customerName = "" : comment.customerName = comment.customerName;
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase());
            }
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                    // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            } else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }
            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        if (getreportnosearch) {
            computedComments = computedComments.filter(comment =>
                comment.qcInspectionID.toString().includes(getreportnosearch)
            );
            setTotalItems(computedComments.length);
        }
        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.insCreatedDate, format)) >= stateDate[0].startDate && new Date(moment(comment.insCreatedDate, format)) <= stateDate[0].endDate
            )
        }

        setTotalItems(computedComments.length);
        //sorting table
        if (sorting.field === "typeInterOrExtern") {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field] - (b[sorting.field])));
        }
        else if (sorting.field) {
            computedComments.map((x) => {
                x.qcInspectionID = x.qcInspectionID.toString();
            })
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            QAReInspectionList,
            currentPage,
            // search,
            getreportnosearch,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateApDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);

    const GetComments = async (comment) => {
        showLoader();
        let Approvallist = [];
        let Approval = [{ FinalInspectionID: comment.finalInspectionID, InsCreatedDate: comment.insCreatedDate }]
        setheaderName(Approval);
        setCommentpopup(true);
        QAService.GetReinspectionCommentsList(comment.qcInspectionID, Taskcallback).then(async (response) => {
            if (response.data) {
                setFIRemark(response.data)
                hideLoader();
            }
        }).catch(() => { hideLoader(); });
    }

    function openReInspectionDetailpopup(QCInspectionID, TnaId) {
        QAService.GetQAReInspectionDetailList(2, QCInspectionID, TnaId, Taskcallback).then((response) => {
            if (response.data) {
                setopenReInspopup(true);
                setReInspectionDetailList(response.data)
            }
            else {
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
    }

    const GetSKUandQty = (finalInspectionID) => {
        //setskuid('');
        QAService.GetPurchaseOrderSKUQty(finalInspectionID).then((response) => {
            if (response.data) {

                setskuqtylist(response.data)
            }
        }).catch(() => { });
        setsizeqtypopup(true);
        //setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }

    const handleFullClose = (Feildname, comment) => {
        setCommentpopup(false);
        setFIRemark([]);
        setFIRemarks('');
    }

    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setFIRemarks(inputtext);

    }

    function handleDeleteRefinalinspection(comment) {

        let PackingParams = {
            Operation: 129,
            Id: comment.reinpsectionCommentsID,
        }
        setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
    }

    const handleSaveApproval = (e, comment) => {
        let len = 0;
        if (Ecomment === undefined || Ecomment === '') {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // // var Data = [];

            var data = {
                CreatedBy: currentUser.employeeinformationID, ReinpsectionCommentsID: 0, InspectionId: comment.qcInspectionID
                , CommentsDetail: Ecomment, TaskName: Taskcallback
            };

            QAService.InsertUpdateReinspectionComment(data).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added for " + comment.qcInspectionID + " Successfully");
                    setCommentpopup(false);
                    setFIRemarks('');
                    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
                    setIsProductionStatussearch(Defaultvalue);
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }

                    let StartApDate = null;
                    let EndApDate = null;
                    if (moment(stateApDate[0].startDate).isValid()) {
                        StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApDate[0].endDate).isValid()) {
                        EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
                    dispatch(LoadReQAInspectionList(params));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

    }

    function delCallbackfinalins(value) {
        if (value === true) {
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            if (moment(stateApDate[0].startDate).isValid()) {
                StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
            dispatch(LoadReQAInspectionList(params));
            setCommentpopup(true);
        } else {
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }
    const closeReInspectionDetailpopup = () => {
        let IsSelected = 0;
        ReInspectionDetailList.forEach(element => {
            element.isSelected = 0;
        })

        setselectall(IsSelected);
        setopenReInspopup(false);
    }
    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...ReInspectionDetailList];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setReInspectionDetailList(value);
    }
    const AddReInspection = () => {
        const Values = [...ReInspectionDetailList]
        let selectedInspIds = '';
        Values.map(x => {
            if (x.isSelected === 1) {
                if (selectedInspIds === '') {
                    if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                        selectedInspIds = x.tnaqcInspectionID.toString();
                    }
                }
                else {
                    if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                        selectedInspIds = selectedInspIds + "," + x.tnaqcInspectionID.toString();
                    }
                }
            }
        })

        let uniquevalues = [...new Map(ReInspectionDetailList.map(item =>
            [item['skuName'], item])).values()];

        uniquevalues.map(x => {
            let totqty = 0;
            //x.refInspecSKUID = x.skuID;
            x.FinalInspectionIds = selectedInspIds;

        })
        uniquevalues.map(x => {
            let totqty = 0;
            x.refInspecSKUID = x.skuID;
            //x.FinalInspectionIds = selectedInspIds;

        })

        let Finaldata = uniquevalues;

        let isvalid = true;
        isvalid = !!ReInspectionDetailList.find(x => x.isSelected === 1)
        if (isvalid) {
            let PurchaseOrderSkuID = "";
            let NewArr = [];
            NewArr = Values.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (PurchaseOrderSkuID === '') {
                    PurchaseOrderSkuID = Purchaseorder.purchaseOrderSkuID;
                } else {
                    PurchaseOrderSkuID = PurchaseOrderSkuID + ',' + Purchaseorder.purchaseOrderSkuID
                }

            })
            Values.filter(x => x.isSelected === 1).map((x) => {
                if (x.isSelected === 1) {
                    x.IscheckReportSelect = 1;
                } else {
                    x.IscheckReportSelect = 0;
                }

            })
            var maindetails = {
                supplierId: Finaldata[0].supplierId
                , supplierName: Finaldata[0].supplierName
                , tnaid: Finaldata[0].tnaid
                , purchaseOrderID: Finaldata[0].purchaseOrderID,
                idPoNos: Finaldata[0].idNo
            }
            getInspectionInfo.multiselecteddatas = Finaldata;
            // getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = maindetails;
            getInspectionInfo.TnaID = Finaldata[0].tnaid
            getInspectionInfo.type = Finaldata[0].typeInterOrExtern;
            getInspectionInfo.PurchaseOrderSkuID = PurchaseOrderSkuID;
            getInspectionInfo.Isproduction = IsProduction;
            getInspectionInfo.ParentTask = "Internal QA audit";
            getInspectionInfo.Mode = "ReInspection";
            getInspectionInfo.Action = "Add";
            getInspectionInfo.IsAddorEditReinspection = 'Add ReInspection';
            getInspectionInfo.buyerName = Finaldata[0].buyerName;
            getInspectionInfo.seasonName = Finaldata[0].seasonName;
            getInspectionInfo.brandName = Finaldata[0].brandName;
            getInspectionInfo.buyerID = Finaldata[0].buyerID;
            getInspectionInfo.brandID = Finaldata[0].brandID;
            getInspectionInfo.supplierId = Finaldata[0].supplierId;
            getInspectionInfo.supplierName = Finaldata[0].supplierName;
            getInspectionInfo.seasonID = Finaldata[0].seasonID;
            getInspectionInfo.styleID = Finaldata[0].styleID;
            getInspectionInfo.styleName = Finaldata[0].styleName;
            getInspectionInfo.styleName = Finaldata[0].styleNo;
            getInspectionInfo.TaskStatus = 1;
            getInspectionInfo.TaskType = Taskcallback;
            // getInspectionInfo.TNAInspectionID = Values[0].tnaFinalInspectionID;
            getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                ? uniquevalues[0].reInspectionAgainst : Finaldata[0].FinalInspectionIds;
            getInspectionInfo.PageName = "To Do Re Inspection";
            props.history.push(`/AddQCInspection`, { params: getInspectionInfo });
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;

    };

    return (
        <div className="page-body" style={{ background: "none" }}>
            {/* <ReactNotification /> */}
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                            <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="widget">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion1">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne1">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne1" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        onChange={e => handleChange(e, 'Buyer')}
                                                                                        value={getBuyersearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                                <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        onChange={e => handleChange(e, 'Season')}
                                                                                        value={getSeasonsearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        onChange={e => handleChange(e, 'Brand')}
                                                                                        value={getBrandsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        onChange={e => handleChange(e, 'Quarter')}
                                                                                        value={getQuartersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        onChange={e => handleChange(e, 'Supplier')}
                                                                                        value={getSuppliersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>PO/ID No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'PO/ID No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'PO/ID No'}
                                                                                        onChange={e => handleChange(e, 'ID/NO')}
                                                                                        value={getIdPosearch}
                                                                                    />

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2" >
                                                                                <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Style Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        onChange={e => handleChange(e, 'StyleName')}
                                                                                        value={getStyleNamesearch}
                                                                                    />
                                                                                </span>

                                                                            </div>

                                                                            <div className="col-md-2" >
                                                                                <label>SKU Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'SKU Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'SKU Name'}
                                                                                        onChange={e => handleChange(e, 'SKUName')}
                                                                                        value={getSkuSearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2" >
                                                                                <label>Factory</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Factory'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Factory'}
                                                                                        onChange={e => handleChange(e, 'Factory')}
                                                                                        value={getFactorySearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Re Inspection Status Filter</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        //options={InspectionandApproval}
                                                                                        isClearable={true}
                                                                                    //onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                    //value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>



                                                                            <div className="col-md-2"  ref={datePickerreport}>
                                                                                <label>Inspection Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                        type="text"
                                                                                        className={'form-control styledatapickerwidth100'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times stylemleft" onClick={clearDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateDate([item.selection])}
                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2" >
                                                                                <label>Report No</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Report No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Report No'}
                                                                                        onChange={e => handleChange(e, 'ReportNo')}
                                                                                        value={getreportnosearch}
                                                                                    />
                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2">
                                                                                <div className="col-md-1 margin-top-30">
                                                                                    <span >
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header>
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">

                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedDate"
                                                            checked={checkboxStates.CreatedDate}

                                                        />
                                                        &nbsp;<label htmlFor="CreatedDate">Created Date </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="EntryNo"
                                                            checked={checkboxStates.EntryNo}
                                                        />
                                                        &nbsp;<label htmlFor="EntryNo">Entry No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionType"
                                                            checked={checkboxStates.InspectionType}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionType">Report Type</label>
                                                    </li>
                                                </div>
                                                {/* <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ReInspectionDoneAgainst"
                                                            checked={checkboxStates.ReInspectionDoneAgainst}
                                                        />
                                                        &nbsp;<label htmlFor="ReInspectionDoneAgainst">ReInspection Done</label>
                                                    </li>
                                                </div> */}
                                                {/* <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Brand"
                                                            checked={checkboxStates.Brand}
                                                        />
                                                        &nbsp;<label htmlFor="Brand">Brand</label>
                                                    </li>
                                                </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Season"
                                                            checked={checkboxStates.Season}
                                                        />
                                                        &nbsp;<label htmlFor="Season">Season</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Supplier"
                                                            checked={checkboxStates.Supplier}
                                                        />
                                                        &nbsp;<label htmlFor="Supplier">Supplier</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Factory"
                                                            checked={checkboxStates.Factory}
                                                        />
                                                        &nbsp;<label htmlFor="Factory">Factory</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="IDNo"
                                                            checked={checkboxStates.IDNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="IDNo">PO/ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleName"
                                                            checked={checkboxStates.StyleName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleName">Style Name</label>
                                                    </li>
                                                </div>


                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleNo"
                                                            checked={checkboxStates.StyleNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="SKUName"
                                                            checked={checkboxStates.SKUName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="SKUName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionQuantity"
                                                            checked={checkboxStates.InspectionQuantity}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionQuantity">Inspection Quantity</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionResult"
                                                            checked={checkboxStates.InspectionResult}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionResult">Inspection Result</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedBy"
                                                            checked={checkboxStates.CreatedBy}
                                                        />
                                                        &nbsp;<label htmlFor="CreatedBy">CreatedBy</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ApprovalResult"
                                                            checked={checkboxStates.ApprovalResult}
                                                        />
                                                        &nbsp;<label htmlFor="ApprovalResult">ApprovalResult</label>
                                                    </li>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    <div className="row">
                        <div className="col-lg-11">
                        </div>
                        <button style={{ marginTop: '8px', marginBottom: '7px' }} title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                        {/* <label style={{ marginTop: '7px', marginLeft: "34px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                            <input type="checkbox" name="IsAllSelected"
                                onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                value={isAllSelected ? true : false}
                                checked={isAllSelected ? true : false}
                                className="selectallcheckbox"
                            />
                        </label> */}
                        {commentsData.length !== 0 && (
                            <ReactHTMLTableToExcel
                                id="test-table-s-button-final"
                                className="fa fa-file-excel-o  btn btn-success excelcomt"
                                table={"table-to-xlsentry"}
                                filename={"ToDoReInspectionList"}
                                sheet="ToDoReInspection"
                                buttonText=""
                            />
                        )}
                        <div className="col-md-12">
                            <div style={{ overflow: "auto" }}>
                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "160vm" }}>
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment, i) => (
                                                <Fragment key={i}>
                                                    <tr key={comment.finalInspectionID}>
                                                        <td style={{ width: '25px' }}>
                                                            {i + 1}
                                                        </td>
                                                        {
                                                            checkboxStates.CreatedDate && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    // className="stickyone "
                                                                    title={`${comment.reportType === "Re Inspection" ? "Re" : "New"} - ${comment.qcInspectionID} - ${comment.insCreatedDate}`}>
                                                                    {comment.reportType === "Re Inspection" ? "Re" : "New"} - {comment.qcInspectionID} - {comment.insCreatedDate}

                                                                </td>
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.EntryNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.qcInspectionID}>
                                                               {comment.reportType} {comment.qcInspectionID}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.InspectionType && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.reportType}>
                                                                {comment.reportType}
                                                            </td>
                                                        )
                                                    } */}
                                                        {
                                                            checkboxStates.Supplier && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={`${comment.supplierName} - ${comment.factory}`}>
                                                                    {comment.supplierName} - {comment.factory}
                                                                </td>

                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.Factory && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'Factory'}>
                                                                {comment.factory}
                                                            </td>
                                                        )
                                                    } */}
                                                        {
                                                            checkboxStates.StyleName && (
                                                                <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.SKUName && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.skuName}>
                                                                    {comment.skuName}
                                                                    <span>
                                                                        <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.qcInspectionID)}
                                                                        >
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.StyleNo && (
                                                            <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                        )
                                                    }
                                                     */}
                                                        {
                                                            checkboxStates.IDNo && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.idpono}
                                                                </td>
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.IDNo && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'FO PO No'}>
                                                                {comment.foidpono === '0' ? '-' : comment.foidpono}
                                                            </td>
                                                        )
                                                    } */}
                                                        {
                                                            checkboxStates.SKUImg && (
                                                                <td title={comment.SKUImg}>
                                                                    {/* {comment.SKUImg} */}
                                                                    {'-'}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.orderQty && (
                                                                <td title={comment.orderQty}>
                                                                    {/* {comment.SKUImg} */}
                                                                    {'-'}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionQuantity && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {comment.inspectionQty}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionResult}>
                                                                    {comment.inspectionResult}
                                                                </td>
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.InspectionResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionResult}>
                                                                {comment.inspectionResult}
                                                            </td>
                                                        )
                                                    }
                                                    */}
                                                        {/* {
                                                        checkboxStates.CreatedBy && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.createdBy}>
                                                                {comment.createdBy}
                                                            </td>
                                                        )
                                                    } */}
                                                        {
                                                            checkboxStates.ApprovalResult && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={`${comment.approvalResult}  on  ${comment.approvalDate} - ${comment.createdBy}`}>
                                                                    <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b></span>
                                                                    <span> - {comment.createdBy} </span>
                                                                </td >
                                                            )
                                                        }


                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    // style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'-'}>
                                                                    {'-'}
                                                                </td>
                                                            )
                                                        }
                                                        {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%",display: InternalComments &&  InternalComments.isAdd === 0  ? 'none' : ''  }}>
                                                            < span >
                                                                <button type="button"
                                                                    style={{
                                                                        background: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                        color: 'white',
                                                                        padding: '3px 6px',
                                                                        borderRadius: '1px',
                                                                        border: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                        display: InternalComments &&  InternalComments.isAdd === 0  ? 'none' : ''
                                                                    }}

                                                                    //className={(comment.approvalResult === null || comment.approvalResult == "") ? "btn btn-xs btn-primary" : "btn btn-xs btn-danger"}
                                                                    title='Comment'

                                                                    onClick={() => GetComments(comment, 'Comments')}>
                                                                    <i className="fa fa-comment"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button type="button" className="btn btn-xs btn-success" 
                                                                     style={{display: InternalComments &&  InternalComments.isAdd === 0  ? 'none' : '' }}
                                                                     title='Add Re Inspection'
                                                                
                                                                    // onClick={() => handleFinalInspecView(comment, 'Edit', '')}
                                                                    onClick={() => openReInspectionDetailpopup(comment.qcInspectionID, comment.tnaid)}

                                                                >
                                                                    <i className="fa fa-plus "></i>
                                                                </button>
                                                            </span >
                                                        </td>}
                                                        {
                                                            sizeqtypopup === true ?

                                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                                    {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>SKU and Qty
                                                                        </Modal.Title>


                                                                    </Modal.Header>
                                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                                        {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                                    </div>

                                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: "350px" }}>PO-SKUID</th>
                                                                                    <th style={{ width: "350px" }}>SKU</th>
                                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                                </tr>

                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    skuqtylist.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span className="input-icon icon-right">
                                                                                                            {item.purchaseOrderSkuID}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className="input-icon icon-right">
                                                                                                            {item.skuName}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span className="input-icon icon-right">
                                                                                                            {item.offeredQty}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>

                                                                    </Modal.Footer>

                                                                </Modal> : ''
                                                        }
                                                        {
                                                            Commentpopup === true ?
                                                                // style={{ height: "25%" }}
                                                                <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt')}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            Comments

                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Comments<span className="text-danger">*</span></th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td colSpan='2'>
                                                                                    <textarea
                                                                                        rows="4"
                                                                                        placeholder="Enter comments here..."
                                                                                        autoFocus autoComplete="off"
                                                                                        maxLength="500" name="EnterComment"
                                                                                        id={"EnterComment"}
                                                                                        value={Ecomment}
                                                                                        onChange={event => handleChangeComment(event, "reportComment")}
                                                                                        style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                                    </textarea>
                                                                                </td>

                                                                            </tr >

                                                                        </tbody >
                                                                    </table >
                                                                    <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                            <thead>
                                                                                <tr>
                                                                                    {/* <th>Results</th> */}
                                                                                    <th>Comments</th>
                                                                                    <th>Created By</th>
                                                                                    <th>Approval Date</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    getFIRemark.length === 0 ?
                                                                                        <tr>
                                                                                            <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                                                        </tr> :
                                                                                        getFIRemark.map((Commentitem) => {
                                                                                            return (

                                                                                                <tr>
                                                                                                    <td>
                                                                                                        {Commentitem.commentsDetail}
                                                                                                    </td>
                                                                                                    <td>{Commentitem.createdBy}</td>
                                                                                                    <td>
                                                                                                        {moment(Commentitem.commentsDate).format('DD/MM/YYYY')}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span title='Delete PackageImage' onClick={() => handleDeleteRefinalinspection(Commentitem, '')}
                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>

                                                                                            );
                                                                                        })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="success" onClick={(event) => handleSaveApproval(event, comment)}>
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal >
                                                                : ''
                                                        }
                                                        {
                                                            openReInspopup === true &&
                                                            <Modal show={openReInspopup === true} size="lg" dragable backdrop="static" keyboard={false}
                                                                onHide={() => closeReInspectionDetailpopup()}
                                                            >
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                        ADD Re Inspection

                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                                                        <table className="table table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Report No</th>
                                                                                    <th>Style Name</th>
                                                                                    <th>Style No</th>
                                                                                    <th>SKU ID</th>
                                                                                    <th>SKU Name</th>
                                                                                    <th>PO/ID No</th>
                                                                                    <th>PO No</th>
                                                                                    <th>Factory</th>
                                                                                    <th>Inspected Qty</th>
                                                                                    {/* <th>Balance for Re Inspection</th> */}
                                                                                    <th>Task Holder / Follower</th>
                                                                                    <th>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                onChange={event => (SelectAll(event))}
                                                                                                checked={selectall === 1 ? true : false}
                                                                                                className="colored-blue clearOpacity" />
                                                                                            <span className="text"></span>
                                                                                        </label>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    ReInspectionDetailList.length !== 0 ?
                                                                                        ReInspectionDetailList.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>{item.tnaqcInspectionID}</td>
                                                                                                        <td>{item.styleName}</td>
                                                                                                        <td>{item.styleNo}</td>
                                                                                                        <td>{item.skuID}</td>
                                                                                                        <td>{item.skuName}</td>
                                                                                                        <td>{item.idNo}</td>
                                                                                                        <td>{item.poNo}</td>
                                                                                                        <td>{item.factory}</td>
                                                                                                        <td>{item.inspectedQty}</td>
                                                                                                        {/* <td>{item.balanceQuantity}</td> */}
                                                                                                        <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                                                                                                        <td className="Actionbutton fixed-column">
                                                                                                            <div style={{ width: "80px" }}>
                                                                                                                {

                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                            disabled={item.balanceQuantity === "0" ? true : false}
                                                                                                                            onChange={event => (OnSelectRecords(event, index))}
                                                                                                                            checked={item.isSelected === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                }&nbsp;&nbsp;&nbsp;
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            )
                                                                                        }) :
                                                                                        <tr>
                                                                                            <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                                                        </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    {
                                                                        <Button variant="success" onClick={() => AddReInspection()}>
                                                                            Save
                                                                        </Button>
                                                                    }
                                                                </Modal.Footer>
                                                            </Modal>
                                                        }
                                                    </tr>
                                                </Fragment>
                                            ))
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody >

                                    <table data-role="table" data-mode="columntoggle" id="table-to-xlsentry"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>


                                                            :

                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {checkboxStates.CreatedDate === true ? <th style={{ width: '3%' }}> Created Date </th> : false}
                                                {checkboxStates.EntryNo === true ? <th style={{ width: '3%' }}> Entry No </th> : false}
                                                {checkboxStates.InspectionType === true ? <th style={{ width: '4%' }}> Inspection Type</th> : false}
                                                {checkboxStates.Supplier === true ? <th style={{ width: '5%' }}> Supplier </th> : false}
                                                {checkboxStates.Factory === true ? <th style={{ width: '4%' }}> Factory</th> : false}
                                                {checkboxStates.StyleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                                                {checkboxStates.StyleNo === true ? <th style={{ width: '5%' }}> Style No</th> : false}
                                                {checkboxStates.SKUName === true ? <th style={{ width: '4% ' }}> SKU Name</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}> PO/ID No</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}>FO IDPO No</th> : false}
                                                {checkboxStates.InspectionQuantity === true ? <th style={{ width: '4%' }}> Report Quantity</th> : false}
                                                {checkboxStates.InspectionResult === true ? <th style={{ width: '5%' }}> Reinpection Result</th> : false}
                                                {checkboxStates.CreatedBy === true ? <th style={{ width: '4%' }}> Created By</th> : false}
                                                {checkboxStates.ApprovalResult === true ? <th style={{ width: '4%' }}> Approval Result</th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.finalInspectionID}>

                                                        {
                                                            checkboxStates.CreatedDate && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    // className="stickyone "
                                                                    title={comment.insCreatedDate}>
                                                                    {comment.insCreatedDate}

                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.EntryNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.qcInspectionID}>
                                                                    {comment.qcInspectionID}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.InspectionType && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.reportType}>
                                                                    {comment.reportType}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.Supplier && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.supplierName}>
                                                                    {comment.supplierName}
                                                                </td>

                                                            )
                                                        }
                                                        {
                                                            checkboxStates.Factory && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'Factory'}>
                                                                    {comment.factory}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.StyleName && (
                                                                <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.StyleNo && (
                                                                <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.SKUName && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.skuName}>
                                                                    {comment.skuName}
                                                                    <span>
                                                                        <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                        >
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.IDNo && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.idpono}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.IDNo && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'FO PO No'}>
                                                                    {comment.foidpono === '0' ? '-' : comment.foidpono}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionQuantity && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {comment.inspectionQty}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionResult}>
                                                                    {comment.inspectionResult}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.CreatedBy && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.createdBy}>
                                                                    {comment.createdBy}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.ApprovalResult && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.approvalResult}>
                                                                    <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                                </td >
                                                            )
                                                        }
                                                    </tr>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table >
                            </div >
                        </div >


                    </div >

                </div >

                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={QaReinspectioncmt}
                        Msg={"QA ReInspection comment deleted successfully"} /> : null
                }
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
            </div >
            {loader}



        </div >
    )
}

export default ToDoReinspectionQA;