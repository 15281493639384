import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification"
import roleService from "../../../../services/Master/RoleService";
import validator from 'validator'
import SearchRetain from "../../../Common/SearchRetain";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import { retrieveUserType } from "../../../../actions/role";
import { retrieveMenuname } from "../../../../actions/UserPermission";
import { loadStyleTrimColorListByStyleID } from "../../../../actions/style";
import { Alert } from "reactstrap";


const RoleListRegister = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const userType = useSelector((state) => state.role.userTypeList);

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getRole, setRole] = useState([{ Id: 0, Name: "", CompBuySupTableType: 0, MenuDetails: [] }]);
    const [getmenuDetailsHeader, setMenuDetailsHeader] = useState({ ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0 });

    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }


    function RoleListing(props) {
        let Role = [...getRole];


        Array.from(document.querySelectorAll("input")).forEach(input => (input.value = ""));
        if (props.location.state.params.length !== 0) {
            if (props.location.state.params.Id !== "") {
                setButtonName("Update");
                setHeaderName("Edit");
                Role.Id = props.location.state.params.roleID;
                Role.Name = validator.trim(props.location.state.params.roleName);
                Role.Operation = 2;
                return Role;
            }
        } else {
            return Role;
        }
    }

    const dispatch = useDispatch();

    const menuList = useSelector((state) => state.userpermission.menuList);
    useEffect(() => {
        dispatch(retrieveUserType());
        dispatch(retrieveMenuname(0));
        let getRoleList = RoleListing(props);
        setRole(getRoleList)
        roleService.GetRoleList(0).then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const UserRightsValue = [...inputFields];

    useEffect(() => {
       
        if (menuList.item1 !== undefined) {
           
            if (menuList.item1.length > 0) {

                for (var i = 0; i < menuList.item1.length; i++) {
                    UserRightsValue.push({ ValidateMenu: menuList.item1[i].menuName, userType: 1, subParentUserType: 77, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item1[i].menuDisplayName, MenuId: menuList.item1[i].menuID, ShowButton: 1, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 1, IsProduction: 0 });
                    for (var j = 0; j < menuList.item2.length; j++) {
                        if (menuList.item2[j].mainMenuID === menuList.item1[i].menuID) { UserRightsValue.push({ ValidateMenu: menuList.item2[j].menuName, userType: 2, subParentUserType: menuList.item2[j].mainMenuID, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item2[j].menuDisplayName, MenuId: menuList.item2[j].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 2, IsProduction: menuList.item1[i].menuID }); }
                        for (var k = 0; k < menuList.item3.length; k++) {
                            if (menuList.item1[i].menuID === menuList.item2[j].mainMenuID) {

                                if (menuList.item2[j].menuID === menuList.item3[k].mainMenuID) {
                                    UserRightsValue.push({ ValidateMenu: menuList.item3[k].menuName, userType: 3, subParentUserType: 0, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item3[k].menuDisplayName, MenuId: menuList.item3[k].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 0, IsProduction: menuList.item1[i].menuID });
                                    if (menuList.item3[k].menuName === "LabDip" || menuList.item3[k].menuName === "ProductionLabDip"  
                                        || menuList.item3[k].menuName === "OrderLabDip" || menuList.item3[k].menuName === "ProductionOrderLabDip" 
                                        || menuList.item3[k].menuName === "StrikeOffList"  || menuList.item3[k].menuName === "ProductionStrikeOffList"
                                        || menuList.item3[k].menuName === "OrderStrikeOff"  || menuList.item3[k].menuName === "ProductionOrderStrikeOff"
                                        || menuList.item3[k].menuName === "TrimList"  || menuList.item3[k].menuName === "ProductionOrderTrimList" 
                                        || menuList.item3[k].menuName === "OrderTrim"  || menuList.item3[k].menuName === "ProductionOrderTrim" 
                                        || menuList.item3[k].menuName === "Style" || menuList.item3[k].menuName === "ProductionStyle" 
                                        || menuList.item3[k].menuName === "Route" || menuList.item3[k].menuName === "ProductionRoute"
                                         || menuList.item3[k].menuName === "TNA" || menuList.item3[k].menuName === "ProductionTNA" 
                                         || menuList.item3[k].menuName === "TNAReport" || menuList.item3[k].menuName === "ProductionTNAReport" 
                                         || menuList.item3[k].menuName === "Company" || menuList.item3[k].menuName === "BuyerList" 
                                         || menuList.item3[k].menuName === "Supplier" || menuList.item3[k].menuName === "Mobile"
                                         || menuList.item3[k].menuName === "Testing" || menuList.item3[k].menuName === "InternalQAAudit"
                                         || menuList.item3[k].menuName === "Samples" || menuList.item3[k].menuName === "ProductionSample"
                                         || menuList.item3[k].menuName === "FinalInspection"  || menuList.item3[k].menuName === "InternalFinalInspection") {

                                        for (var l = 0; l < menuList.item4.length; l++) {
                                            if (menuList.item3[k].menuID === menuList.item4[l].mainMenuID) {
                                                UserRightsValue.push({ ValidateMenu: menuList.item4[l].menuName, userType: 4, subParentUserType: 0, MenuChk: 0, RoleMenuPermissionId: 0, Menu: menuList.item4[l].menuDisplayName, MenuId: menuList.item4[l].menuID, ShowButton: 0, AddButton: 0, EditButton: 0, ViewButton: 0, DeleteButton: 0, ParentMenu: 4, IsProduction: menuList.item1[i].menuID });

                                            }
                                        }
                                    }
                                }
                            }

                        }

                    }
                }
            }
            debugger;
            setInputFields(UserRightsValue);
        }

    }, [menuList.length]);

    const values = [...getRole];
    const handleInputChange = (index, event, FieldName) => {
       ;
        if (FieldName === "role") {
            if (event.target.value.trim() !== '') {
                values[index].Name = event.target.value;
            }
            else { values[index].Name = '' }
        }
        else {
            values[index].CompBuySupTableType = 0;
            if (event !== null) { values[index].CompBuySupTableType = event.value; }
        }
        setRole(values);
    };

    const handleOnSelectRecords = (event, index, FieldName) => {
       ;
        let MenuHeader = { ...getmenuDetailsHeader };
        // let count = 0;
        if (FieldName === 'MenuChk') {
            UserRightsValue[index].MenuChk = 0;
            UserRightsValue[index].ShowButton = 0;
            UserRightsValue[index].AddButton = 0;
            UserRightsValue[index].EditButton = 0;
            UserRightsValue[index].ViewButton = 0;
            UserRightsValue[index].DeleteButton = 0;
            UserRightsValue[index].QuickButton = 0;
            if (event.target.checked) {
                UserRightsValue[index].MenuChk = 1;
                UserRightsValue[index].ShowButton = 1;
                UserRightsValue[index].AddButton = 1;
                UserRightsValue[index].EditButton = 1;
                UserRightsValue[index].ViewButton = 1;
                UserRightsValue[index].DeleteButton = 1;
                UserRightsValue[index].QuickButton = 1;

                let isShow = !!UserRightsValue.find(d => d.ShowButton === 0);
                if (!isShow) {
                    MenuHeader.ShowButton = 1;
                }
                let isAdd = !!UserRightsValue.find(d => d.AddButton === 0);
                if (!isAdd) {
                    MenuHeader.AddButton = 1;
                }
                let isEdit = !!UserRightsValue.find(d => d.EditButton === 0);
                if (!isEdit) {
                    MenuHeader.EditButton = 1;
                }
                let isView = !!UserRightsValue.find(d => d.ViewButton === 0);
                if (!isView) {
                    MenuHeader.ViewButton = 1;
                }

                let isDelete = !!UserRightsValue.find(d => d.DeleteButton === 0);
                if (!isDelete) {
                    MenuHeader.DeleteButton = 1;
                }
                let isQuick = !!UserRightsValue.find(d => d.QuickButton === 0);
                if (!isQuick) {
                    MenuHeader.QuickButton = 1;
                }
            } else {
                MenuHeader.ShowButton = 0;
                MenuHeader.AddButton = 0;
                MenuHeader.EditButton = 0;
                MenuHeader.ViewButton = 0;
                MenuHeader.DeleteButton = 0;
                MenuHeader.QuickButton = 0;
            }

        }
        else {
            UserRightsValue[index][FieldName] = event.target.checked ? 1 : 0;
            if (event.target.checked) {
                let hasNotChecked = !!UserRightsValue.find(d => d[FieldName] === 0);

                if (!hasNotChecked) {
                    MenuHeader[FieldName] = 1;
                }
                if (UserRightsValue[index].ShowButton === 1 &&
                    UserRightsValue[index].AddButton === 1 &&
                    UserRightsValue[index].EditButton === 1 &&
                    UserRightsValue[index].ViewButton === 1 &&
                    UserRightsValue[index].DeleteButton === 1 &&
                    UserRightsValue[index].QuickButton === 1) {
                    UserRightsValue[index].MenuChk = 1;
                }
            } else {
                MenuHeader[FieldName] = 0;
                UserRightsValue[index].MenuChk = 0;
            }

        }
        // BindChildParent(UserRightsValue, index, event);
       ;
        setInputFields(UserRightsValue);
        setMenuDetailsHeader(MenuHeader);

    };

    const handleOnSelectRecordsHeader = (event, FieldName) => {
       ;
        let MenuHeader = { ...getmenuDetailsHeader };
        for (var i = 0; i < UserRightsValue.length; i++) {
           ;
            if (event.target.checked) {

                UserRightsValue[i][FieldName] = 1;

                // if (UserRightsValue[i].ValidateMenu !== 'Dashboard')
                // { UserRightsValue[i][FieldName] = 1; }


                if (UserRightsValue[i].ShowButton === 1 &&
                    UserRightsValue[i].AddButton === 1 &&
                    UserRightsValue[i].EditButton === 1 &&
                    UserRightsValue[i].ViewButton === 1 &&
                    UserRightsValue[i].DeleteButton === 1 &&
                    UserRightsValue[i].QuickButton === 1) {
                    UserRightsValue[i].MenuChk = 1;
                }
            }
            else {
                if (UserRightsValue[i].ValidateMenu !== 'Dashboard') { UserRightsValue[i][FieldName] = 0; }
                else { UserRightsValue[i][FieldName] = 1; }
                UserRightsValue[i].MenuChk = 0;
            }
        }

        if (event.target.checked) {
            MenuHeader[FieldName] = 1;
        }
        else {
            MenuHeader[FieldName] = 0;
        }
        setMenuDetailsHeader(MenuHeader);
        setInputFields(UserRightsValue);
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/RoleList');
        } else {
            window.location.reload();

        }
    }


    const CheckDuplicate = (index) => {
       ;
        if (ExistingList) {
            for (var i = 0; i < ExistingList.item1.length; i++) {
                if (ExistingList.item1[i].roleName.trim().toLowerCase() === values[index].Name.trim().toLowerCase() && ExistingList.item1[i].compBuySupTableType === values[index].CompBuySupTableType) {
                    values[index].Name = '';
                    setRole(values);
                    Nodify('Warning!', 'warning', "This Role is already exist.");
                    return false;
                }
            }
        }

        for (var i = 0; i < (getRole.length); i++) {
            if (i !== index) {
                if (getRole[i].Name.trim().toLowerCase() === getRole[index].Name.trim().toLowerCase()) {
                    values[index].Name = "";
                    setRole(values);
                    Nodify('Warning!', 'warning', 'This Role is already exist.');
                    return false;
                }
            }
        }
    }

    const SaveRoleList = (e) => {
       
        setButtonLoader(true);
        e.preventDefault();

        if (values[0].Name === '' || values[0].CompBuySupTableType === 0) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please fill the mandatory(*) fields.");
        }
        else {
            BindChildParent(UserRightsValue);
           ;
            let InputCommonMaster = { Createdby: currentUser.employeeinformationID, MasterInformation: values, MenuDetails: UserRightsValue };
            roleService.InsertUpdateRoleGrid(InputCommonMaster).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    values[0].MenuDetails = [];
                    ValidationPopup("This Role is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
            //}
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/RoleList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Role</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>
                                    &nbsp;Reset
                                </span>
                                <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveRoleList}>
                                    <i className="fa fa-check right"></i>&nbsp;
                                    {ButtonName}
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {
                                        getRole.map((inputField, index) => (
                                            <Fragment key={`${inputField}~${index}`}>
                                                <div className="row row_left10">
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor="role" >Role <span className="text-danger">*</span></label>
                                                            <span className="input-icon icon-right">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Name_" + index}
                                                                    name="Name"
                                                                    placeholder="Enter Role"
                                                                    maxLength="50"
                                                                    value={inputField.Name}
                                                                    onChange={event => handleInputChange(index, event, 'role')}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && !inputField.Name ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 divder_style">
                                                        <div className="form-group">
                                                            <label htmlFor="role" >User Type <span className="text-danger">*</span></label>
                                                            <span className='input-icon icon-right'>
                                                                <Reactselect name="role"
                                                                    id='role'
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={userType}
                                                                    value={userType.filter(function (option) {
                                                                        return option.value === inputField.CompBuySupTableType;
                                                                    })}
                                                                    menuPosition="unset"
                                                                    onChange={event => handleInputChange(index, event, 'userType')}
                                                                    onBlur={() => CheckDuplicate(0)}
                                                                    //menuPortalTarget={document.body}
                                                                    //styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                                    styles={submitted && inputField.CompBuySupTableType === 0 ? styles : ''}
                                                                //style={{ border: submitted && inputField.CompBuySupTableType === 0 ? '1px solid red' : '' }}

                                                                ></Reactselect>
                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </Fragment>
                                        ))
                                    }


                                    <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                            <thead>
                                                <tr >
                                                    {/* <th style={{ width: "210px" }} className="fixed-column-header-first-tna"> #</th> */}
                                                    <th style={{ width: "300px" }}>Menu</th>
                                                    <th style={{ width: "120px" }}>
                                                        <label >
                                                            <input type="checkbox" name="ShowButtonHeader"
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'ShowButton'))}
                                                                value={getmenuDetailsHeader.ShowButton}
                                                                checked={getmenuDetailsHeader.ShowButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="Show Menu" />
                                                            <span className="text">List</span>
                                                        </label>

                                                    </th>
                                                    <th style={{ width: "40px" }}>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="AddButton"
                                                                value={getmenuDetailsHeader.AddButton}
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'AddButton'))}
                                                                checked={getmenuDetailsHeader.AddButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="Add"
                                                            />
                                                            <span className="text">Add</span>

                                                        </label></th>
                                                    <th style={{ width: "40px" }}>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="EditButton"
                                                                value={getmenuDetailsHeader.EditButton}
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'EditButton'))}
                                                                checked={getmenuDetailsHeader.EditButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="Edit" />

                                                            <span className="text">Edit</span>
                                                        </label></th>
                                                    <th style={{ width: "40px" }}>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="ViewButton"
                                                                value={getmenuDetailsHeader.ViewButton}
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'ViewButton'))}
                                                                checked={getmenuDetailsHeader.ViewButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="View" />
                                                            <span className="text">View</span>

                                                        </label></th>

                                                    <th style={{ width: "40px" }}>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="DeleteButton"
                                                                value={getmenuDetailsHeader.DeleteButton}
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'DeleteButton'))}
                                                                checked={getmenuDetailsHeader.DeleteButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="Delete" />
                                                            <span className="text">Delete</span>

                                                        </label></th>
                                                    <th style={{ width: "1040px" }}>
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="QuickButton"
                                                                value={getmenuDetailsHeader.QuickButton}
                                                                onChange={event => (handleOnSelectRecordsHeader(event, 'QuickButton'))}
                                                                checked={getmenuDetailsHeader.QuickButton === 1 ? true : false}
                                                                className="clearOpacity textdesign" title="Quick Master" />
                                                            <span className="text">Quick Master</span>

                                                        </label></th>


                                                </tr>

                                            </thead>
                                            <tbody>
                                                {inputFields && (inputFields).map((inputField, index) => (
                                                    <Fragment key={`${inputField}~${index}`}>

                                                        <tr class="parent" data-child={"parent_" + index}>

                                                            {
                                                                inputField.ParentMenu === 1 ?


                                                                    inputField.Menu === 'Dashboard' ?
                                                                        <>

                                                                            <td colSpan='1' style={{ backgroundColor: "#ffffff" }}>
                                                                                <span style={{ color: "#333333" }} className='input-icon icon-right text'>
                                                                                    {inputField.Menu}
                                                                                </span>
                                                                            </td>
                                                                            <td colSpan='6' style={{ backgroundColor: "#ffffff" }}>
                                                                                <div style={{ width: "80px" }}>
                                                                                    <label>
                                                                                        <input type="checkbox" name="ShowButton"
                                                                                            value={inputField.ShowButton}
                                                                                            onChange={event => (handleOnSelectRecords(event, index, 'ShowButton'))}
                                                                                            checked={inputField.ShowButton === 1 ? true : false}
                                                                                            className="clearOpacity textdesign"
                                                                                            disabled
                                                                                        />
                                                                                        <span title={inputField.Menu} className="text"></span>
                                                                                    </label>

                                                                                </div>

                                                                            </td>
                                                                        </>

                                                                        :

                                                                        <td colSpan='7' style={{ backgroundColor: "#222d32" }}>
                                                                            <span style={{ color: "#FFF" }} className='input-icon icon-right text'>
                                                                                {inputField.Menu}
                                                                            </span>
                                                                        </td>








                                                                    :
                                                                    (inputField.ParentMenu === 0 || inputField.ParentMenu === 4) ?
                                                                        <td>
                                                                            {/* <span className='input-icon icon-right'> */}
                                                                            <div>
                                                                                <label style={{ marginTop: '8px', marginLeft: inputField.ParentMenu === 4 ? '25px' : '1px' }}>
                                                                                    <input type="checkbox" name="MenuChk" title={inputField.Menu}
                                                                                        value={inputField.MenuChk}
                                                                                        onChange={event => (handleOnSelectRecords(event, index, 'MenuChk'))}
                                                                                        checked={inputField.MenuChk === 1 ? true : false}
                                                                                        className="clearOpacity textdesign" />
                                                                                    {/* <span className="text">{inputField.Menu}</span> */}
                                                                                    <span title={inputField.Menu} className="text"> {inputField.Menu} </span>
                                                                                </label>

                                                                            </div>
                                                                            {/* </span> */}

                                                                        </td>
                                                                        :
                                                                        ''

                                                            }
                                                            {
                                                                (inputField.ParentMenu === 0 || inputField.ParentMenu === 4) &&
                                                                <>

                                                                    {
                                                                        inputField.ValidateMenu === 'RouteExport' ||
                                                                            inputField.ValidateMenu === 'TNAActivate' ||
                                                                            inputField.ValidateMenu === 'TNACloseComplate' ||
                                                                            inputField.ValidateMenu === 'TNAComment' ||
                                                                            inputField.ValidateMenu === 'TNAAttachment' ||
                                                                            inputField.ValidateMenu === 'TNAstartstop' ||
                                                                            inputField.ValidateMenu === 'TNAReportActivate' ||
                                                                            inputField.ValidateMenu === 'TNAReportCloseComplate' ||
                                                                            inputField.ValidateMenu === 'TNAReportComment' ||
                                                                            inputField.ValidateMenu === 'TNAReportAttachment' ||
                                                                            inputField.ValidateMenu === 'TNAReportStartStop'||
                                                                            inputField.ValidateMenu === 'TestingComments'||
                                                                            inputField.ValidateMenu === 'InternalComments'||
                                                                            inputField.ValidateMenu === 'FIComments'||
                                                                            inputField.ValidateMenu === 'InspectionDetails'
                                                                             

                                                                            ?
                                                                            <>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>

                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="AddButton"
                                                                                                value={inputField.AddButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'AddButton'))}
                                                                                                checked={inputField.AddButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='Add' className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                    </div>
                                                                                </td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="ShowButton"
                                                                                                value={inputField.ShowButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'ShowButton'))}
                                                                                                checked={inputField.ShowButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='List' className="text"></span>
                                                                                        </label>

                                                                                    </div>


                                                                                </td>

                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="AddButton"
                                                                                                value={inputField.AddButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'AddButton'))}
                                                                                                checked={inputField.AddButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='Add' className="text"></span>
                                                                                        </label>

                                                                                    </div>

                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="EditButton"
                                                                                                value={inputField.EditButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'EditButton'))}
                                                                                                checked={inputField.EditButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='Edit' className="text"></span>
                                                                                        </label>

                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="ViewButton"
                                                                                                value={inputField.DeleteButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'ViewButton'))}
                                                                                                checked={inputField.ViewButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='View' className="text"></span>
                                                                                        </label>

                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="DeleteButton"
                                                                                                value={inputField.DeleteButton}
                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'DeleteButton'))}
                                                                                                checked={inputField.DeleteButton === 1 ? true : false}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span title='Delete' className="text"></span>
                                                                                        </label>

                                                                                    </div>
                                                                                </td>




                                                                                <td>
                                                                                    <div style={{ width: "80px" }}>
                                                                                        {/* {inputField.ValidateMenu} */}
                                                                                        {(inputField.ValidateMenu === 'Season' || inputField.ValidateMenu === 'Branch' || inputField.ValidateMenu === 'City' || inputField.ValidateMenu === 'Gender'
                                                                                            || inputField.ValidateMenu === 'Pantone' || inputField.ValidateMenu === 'GroupType' || inputField.ValidateMenu === 'Category'
                                                                                            || inputField.ValidateMenu === 'MeasurementPoint' || inputField.ValidateMenu === 'FabricType' || inputField.ValidateMenu === 'Role' ||
                                                                                            inputField.ValidateMenu === 'City' || inputField.ValidateMenu === 'FabricPart' || inputField.ValidateMenu === 'Swatch'
                                                                                            || inputField.ValidateMenu === 'Department' || inputField.ValidateMenu === 'GroupSize' || inputField.ValidateMenu === 'ProcessType'
                                                                                            || inputField.ValidateMenu === 'PantoneType' || inputField.ValidateMenu === 'Size') &&
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="QuickButton"
                                                                                                    value={inputField.QuickButton}
                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'QuickButton'))}
                                                                                                    checked={inputField.QuickButton === 1 ? true : false}
                                                                                                    className="clearOpacity textdesign" />
                                                                                                <span title={"Quick Master - " + inputField.Menu} className="text"></span>
                                                                                            </label>
                                                                                        }


                                                                                    </div>
                                                                                </td>




                                                                            </>

                                                                    }


                                                                </>


                                                            }

                                                        </tr>
                                                    </Fragment>
                                                ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    &nbsp;
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveRoleList}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                {ButtonName}
                                            </button>
                                        </div>
                                    </div>





                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoleListRegister;

function BindChildParent(UserRightsValue) {
   
    let ParentList;
    let childParentList;
    let isShowGenLab, isShowGenStrike, isShowGenTrim, isShowGenTnarepot;
    let isShowStyle, isShowOrdLab, isShowOrdStrike, isShowOrdTrim, isShowPurchase, isShowRoute, isShowTNA, isShowTNAReport;
    let isShowStrikeMaster, isShowTrimMaster;
    let isShowCompany, isShowSupplier, isShowBuyerList, isShowBuyerInfo;
    let isShowGroup, isShowCategory, isShowStrikeOffType, isShowTrimType, isShowMeasurement, isShowGroupFType, isShowGroupFPart, isShowYarnType, isShowAgency;
    let isShowSwatch, isShowRole, isShowPermission, isShowDepartment, isShowSize, isShowGroupSize, isShowProcess;
    let isShowBranch, isShowCity, isShowSeason, isShowGender, isShowCurrency;
    let isShowTaskType, isShowTask;
    let isShowCourier
    let iscommentsreport, isTnadetailsRport;
    let isShowSamples;
    let isShowTesting;
    let isShowsampleTesting;
    //production
    let isShowStylePro, isShowOrdLabPro, isShowOrdStrikePro, isShowOrdTrimPro, isShowPurchasePro, isShowRoutePro, isShowTNAPro, isShowTNAReportPro;
    let isShowGenLabPro, isShowGenStrikePro, isShowGenTrimPro, isShowGenTnarepotPro;
    let isShowStrikeMasterPro, isShowTrimMasterPro;
    let isShowSamplespro;
    let isShowTestingpro;
    let isShowsampleTestingPro;
    let isFinalinspecPro;
    let isShowproductionEntry;
    let isShipmentpro;
    let isinvoicepro;
    let isPaymentpro;
    let isShowFIDefecteDetails,isShowFIWorkmanshipDetails,isShowFIWorkmanshipTemplate,isShowFIPackageItem,isShowFIPackageTemplate,isShowFIImageName,isShowFIAvailableInfoItem,isShowFIAvailableInfoTemplate,
    isShowFICsrDetails,isShowFICsrTemplate,isShowFICsrDropdown,isShowFIWarehouse,isShowFIBCWarehouse,isShowFIAQL,isShowFITestingItem,isShowFITestingTemplate,isShowFIAQLInspection,isShowFIAQLTolerance;
    for (var index = 0; index < UserRightsValue.length; index++) {
       ;
        //General(sampling)



        if (UserRightsValue[index].ValidateMenu === 'Style' || UserRightsValue[index].ValidateMenu === 'OrderLabDip' || UserRightsValue[index].ValidateMenu === 'OrderStrikeOff' || UserRightsValue[index].ValidateMenu === 'OrderTrim' || UserRightsValue[index].ValidateMenu === 'PurchaseOrder' || UserRightsValue[index].ValidateMenu === 'Route'
            || UserRightsValue[index].ValidateMenu === 'TNA' || UserRightsValue[index].ValidateMenu === 'TNAReport' && UserRightsValue[index].IsProduction === 2) {
            if (!isShowStylePro && !isShowOrdLabPro && !isShowOrdStrikePro && !isShowOrdTrimPro && !isShowPurchasePro && !isShowRoutePro && !isShowTNAPro && !isShowTNAReportPro) {
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                isShowStylePro = UserRightsValue.find(m => m.ValidateMenu === 'Style' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowOrdLabPro = UserRightsValue.find(m => m.ValidateMenu === 'OrderLabDip' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowOrdStrikePro = UserRightsValue.find(m => m.ValidateMenu === 'OrderStrikeOff' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowOrdTrimPro = UserRightsValue.find(m => m.ValidateMenu === 'OrderTrim' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowPurchasePro = UserRightsValue.find(m => m.ValidateMenu === 'PurchaseOrder' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowRoutePro = UserRightsValue.find(m => m.ValidateMenu === 'Route' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowTNAPro = UserRightsValue.find(m => m.ValidateMenu === 'TNA' && m.ShowButton === 1 && m.IsProduction === 2);
                isShowTNAReportPro = UserRightsValue.find(m => m.ValidateMenu === 'TNAReport' && m.ShowButton === 1 && m.IsProduction === 2);

                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'ProductionOrder' && d.userType === 2);
                //&& d.subParentUserType === 1);
                if (isShowStylePro | isShowOrdLabPro || isShowOrdStrikePro || isShowOrdTrimPro || isShowPurchasePro || isShowRoutePro || isShowTNAPro || isShowTNAReportPro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }


        }
        else if (UserRightsValue[index].ValidateMenu === 'LabDip' || UserRightsValue[index].ValidateMenu === 'StrikeOffList' || UserRightsValue[index].ValidateMenu === 'TrimList' && UserRightsValue[index].ValidateMenu === "GeneralTNAReport" && UserRightsValue[index].IsProduction === 2) {
            if (!isShowGenLabPro && !isShowGenStrikePro && !isShowGenTrimPro && !isShowGenTnarepotPro) {
                isShowGenLabPro = UserRightsValue.find(m => m.ValidateMenu === 'LabDip' && m.ShowButton === 1);
                isShowGenStrikePro = UserRightsValue.find(m => m.ValidateMenu === 'StrikeOffList' && m.ShowButton === 1);
                isShowGenTrimPro = UserRightsValue.find(m => m.ValidateMenu === 'TrimList' && m.ShowButton === 1);
                isShowGenTnarepotPro = UserRightsValue.find(m => m.ValidateMenu === "GeneralTNAReport" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "ProductionGeneral" && d.userType === 2 && d.subParentUserType === 2);
                if (isShowGenLabPro || isShowGenStrikePro || isShowGenTrimPro || isShowGenTnarepotPro) {
                    // alert(0);
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === 'ProductionStrikeoffMaster' || UserRightsValue[index].ValidateMenu === 'ProductionTrimMaster' && UserRightsValue[index].IsProduction === 2) {
            if (!isShowStrikeMasterPro && !isShowTrimMasterPro) {
                isShowStrikeMasterPro = UserRightsValue.find(m => m.ValidateMenu === 'ProductionStrikeoffMaster' && m.ShowButton === 1);
                isShowTrimMasterPro = UserRightsValue.find(m => m.ValidateMenu === 'ProductionTrimMaster' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'ProductionComponent' && d.userType === 2 && d.subParentUserType === 1);

                if (isShowStrikeMasterPro || isShowTrimMasterPro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Samples" && UserRightsValue[index].IsProduction === 2) {
            if (!isShowSamplespro) {
                isShowSamplespro = UserRightsValue.find(m => m.ValidateMenu === "Samples" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "ProductionSamples" && d.userType === 2 && d.subParentUserType === 2);

                if (isShowSamplespro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Testing" && UserRightsValue[index].IsProduction === 2) {
            if (!isShowTestingpro) {
                isShowTestingpro = UserRightsValue.find(m => m.ValidateMenu === "Testing" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "ProductionTesting" && d.userType === 2 && d.subParentUserType === 2);

                if (isShowTestingpro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "InternalQAAudit" || UserRightsValue[index].ValidateMenu === "InternalFinalInspection" && UserRightsValue[index].IsProduction === 2) {
            if (!isShowsampleTestingPro && !isFinalinspecPro) {
                isShowsampleTestingPro = UserRightsValue.find(m => m.ValidateMenu === "InternalQAAudit" && m.ShowButton === 1);
                isFinalinspecPro = UserRightsValue.find(m => m.ValidateMenu === "InternalFinalInspection" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "InspectionQualityControl" && d.userType === 2 && d.subParentUserType === 2);

                if (isShowsampleTestingPro || isFinalinspecPro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "DailyProductionEntry" && UserRightsValue[index].IsProduction === 2) {
            if (!isShowproductionEntry) {
                isShowproductionEntry = UserRightsValue.find(m => m.ValidateMenu === "DailyProductionEntry" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "ProductionProduction" && d.userType === 2 && d.subParentUserType === 2);

                if (isShowproductionEntry) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Shipment Booking" || UserRightsValue[index].ValidateMenu === "Invoice " && UserRightsValue[index].IsProduction === 2) {
            if (!isShipmentpro && !isinvoicepro) {
                isShipmentpro = UserRightsValue.find(m => m.ValidateMenu === "Shipment Booking" && m.ShowButton === 1);
                isinvoicepro = UserRightsValue.find(m => m.ValidateMenu === "Invoice " && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "Shipment" && d.userType === 2 && d.subParentUserType === 2);

                if (isShipmentpro || isinvoicepro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Payments" && UserRightsValue[index].IsProduction === 2) {
            if (!isPaymentpro) {
                isPaymentpro = UserRightsValue.find(m => m.ValidateMenu === "Payments" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "Payment" && d.userType === 2 && d.subParentUserType === 2);

                if (isPaymentpro) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        // -------------------------Production------------------production-------------------Production----------------------------------------

        else if (UserRightsValue[index].ValidateMenu === "CommentsReport" || UserRightsValue[index].ValidateMenu === "TNADetailsReport") {
            if (!iscommentsreport && !isTnadetailsRport) {
                iscommentsreport = UserRightsValue.find(m => m.ValidateMenu === "CommentsReport" && m.ShowButton === 1);
                isTnadetailsRport = UserRightsValue.find(m => m.ValidateMenu === "TNADetailsReport" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === "Report" && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "ReportInformation" && d.userType === 2 && d.subParentUserType === 118);
                if (iscommentsreport || isTnadetailsRport) {
                    // alert(0);
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Samples" && UserRightsValue[index].IsProduction === 1) {
            if (!isShowSamples) {
                isShowSamples = UserRightsValue.find(m => m.ValidateMenu === "Samples" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "SamplingSample" && d.userType === 2 && d.subParentUserType === 1);

                if (isShowSamples) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "Testing" && UserRightsValue[index].IsProduction === 1) {
            if (!isShowTesting) {
                isShowTesting = UserRightsValue.find(m => m.ValidateMenu === "Testing" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "SamplingTesting" && d.userType === 2 && d.subParentUserType === 1);

                if (isShowTesting) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === "InternalQAAudit" && UserRightsValue[index].IsProduction === 1) {
            if (!isShowsampleTesting) {
                isShowsampleTesting = UserRightsValue.find(m => m.ValidateMenu === "InternalQAAudit" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === "SamplingInspectionQualityControl" && d.userType === 2 && d.subParentUserType === 1);

                if (isShowsampleTesting) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        else if (UserRightsValue[index].ValidateMenu === 'LabDip' || UserRightsValue[index].ValidateMenu === 'StrikeOffList' || UserRightsValue[index].ValidateMenu === 'TrimList' || UserRightsValue[index].ValidateMenu === 'GeneralTNAReport') {
            if (!isShowGenLab && !isShowGenStrike && !isShowGenTrim && !isShowGenTnarepot) {
                isShowGenLab = UserRightsValue.find(m => m.ValidateMenu === 'LabDip' && m.ShowButton === 1);
                isShowGenStrike = UserRightsValue.find(m => m.ValidateMenu === 'StrikeOffList' && m.ShowButton === 1);
                isShowGenTrim = UserRightsValue.find(m => m.ValidateMenu === 'TrimList' && m.ShowButton === 1);
                isShowGenTnarepot = UserRightsValue.find(m => m.ValidateMenu === "GeneralTNAReport" && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'SamplingGeneral' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowGenLab || isShowGenStrike || isShowGenTrim || isShowGenTnarepot) {
                    // alert(0);
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }
        }
        //Order(sampling)
        else if (UserRightsValue[index].ValidateMenu === 'Style' || UserRightsValue[index].ValidateMenu === 'OrderLabDip' || UserRightsValue[index].ValidateMenu === 'OrderStrikeOff' || UserRightsValue[index].ValidateMenu === 'OrderTrim' || UserRightsValue[index].ValidateMenu === 'PurchaseOrder' || UserRightsValue[index].ValidateMenu === 'Route' || UserRightsValue[index].ValidateMenu === 'TNA' || UserRightsValue[index].ValidateMenu === 'TNAReport' && UserRightsValue[index].IsProduction === 1) {
            if (!isShowStyle && !isShowOrdLab && !isShowOrdStrike && !isShowOrdTrim && !isShowPurchase && !isShowRoute && !isShowTNA && !isShowTNAReport) {
                // alert(1);
                isShowStyle = UserRightsValue.find(m => m.ValidateMenu === 'Style' && m.ShowButton === 1);
                isShowOrdLab = UserRightsValue.find(m => m.ValidateMenu === 'OrderLabDip' && m.ShowButton === 1);
                isShowOrdStrike = UserRightsValue.find(m => m.ValidateMenu === 'OrderStrikeOff' && m.ShowButton === 1);
                isShowOrdTrim = UserRightsValue.find(m => m.ValidateMenu === 'OrderTrim' && m.ShowButton === 1);
                isShowPurchase = UserRightsValue.find(m => m.ValidateMenu === 'PurchaseOrder' && m.ShowButton === 1);
                isShowRoute = UserRightsValue.find(m => m.ValidateMenu === 'Route' && m.ShowButton === 1);
                isShowTNA = UserRightsValue.find(m => m.ValidateMenu === 'TNA' && m.ShowButton === 1);
                isShowTNAReport = UserRightsValue.find(m => m.ValidateMenu === 'TNAReport' && m.ShowButton === 1);

                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'SamplingOrder' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowStyle || isShowOrdLab || isShowOrdStrike || isShowOrdTrim || isShowPurchase || isShowRoute || isShowTNA || isShowTNAReport) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
                // else {
                //     if (childParentList.length > 0) { childParentList[0].ShowButton = 0 }
                //     if (ParentList.length > 0) { ParentList[0].ShowButton = 0 }
                // }
            }

        }
        //Component(sampling)
        else if (UserRightsValue[index].ValidateMenu === 'StrikeoffMaster' || UserRightsValue[index].ValidateMenu === 'TrimMaster') {
            if (!isShowStrikeMaster && !isShowTrimMaster) {
                isShowStrikeMaster = UserRightsValue.find(m => m.ValidateMenu === 'StrikeoffMaster' && m.ShowButton === 1);
                isShowTrimMaster = UserRightsValue.find(m => m.ValidateMenu === 'TrimMaster' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'SamplingComponent' && d.userType === 2 && d.subParentUserType === 1);

                if (isShowStrikeMaster || isShowTrimMaster) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        //Company Info(Master)
        else if (UserRightsValue[index].ValidateMenu === 'Company') {
            if (!isShowCompany) {
               ;
                isShowCompany = UserRightsValue.find(m => m.ValidateMenu === 'Company' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'CompanyInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowCompany) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Buyer Info(Master)
        else if (UserRightsValue[index].ValidateMenu === 'BuyerList' || UserRightsValue[index].ValidateMenu === 'BuyerInfo') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'BuyerList' && m.ShowButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.ValidateMenu === 'BuyerInfo' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'BuyerInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Supplier(Master)
        else if (UserRightsValue[index].ValidateMenu === 'Supplier') {
            if (!isShowSupplier) {
                isShowSupplier = UserRightsValue.find(m => m.ValidateMenu === 'Supplier' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'SupplierInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSupplier) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }


        //General(Master)
        else if (UserRightsValue[index].ValidateMenu === 'Branch' || UserRightsValue[index].ValidateMenu === 'City' || UserRightsValue[index].ValidateMenu === 'Season' || UserRightsValue[index].ValidateMenu === 'Gender' || UserRightsValue[index].ValidateMenu === 'Currency') {
            if (!isShowBranch && !isShowCity && !isShowSeason && !isShowGender && !isShowCurrency) {
                isShowBranch = UserRightsValue.find(m => m.ValidateMenu === 'Branch' && m.ShowButton === 1);
                isShowCity = UserRightsValue.find(m => m.ValidateMenu === 'City' && m.ShowButton === 1);
                isShowSeason = UserRightsValue.find(m => m.ValidateMenu === 'Season' && m.ShowButton === 1);
                isShowGender = UserRightsValue.find(m => m.ValidateMenu === 'Gender' && m.ShowButton === 1);
                isShowCurrency = UserRightsValue.find(m => m.ValidateMenu === 'Currency' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'General' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBranch || isShowCity || isShowSeason || isShowGender || isShowCurrency) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Pantone Info(Master)
        else if (UserRightsValue[index].ValidateMenu === 'Pantone' || UserRightsValue[index].ValidateMenu === 'PantoneType') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'Pantone' && m.ShowButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.ValidateMenu === 'PantoneType' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'PantoneInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Component(Master)
        else if (UserRightsValue[index].ValidateMenu === 'GroupType' || UserRightsValue[index].ValidateMenu === 'Category' || UserRightsValue[index].ValidateMenu === 'StrikeOffType' ||
            UserRightsValue[index].ValidateMenu === 'TrimType' || UserRightsValue[index].ValidateMenu === 'MeasurementPoint' || UserRightsValue[index].ValidateMenu === 'FabricType' ||
            UserRightsValue[index].ValidateMenu === 'FabricPart' || UserRightsValue[index].ValidateMenu === 'YarnType' || UserRightsValue[index].ValidateMenu === 'Agency') {
            if (!isShowGroup && !isShowCategory && !isShowStrikeOffType && !isShowTrimType && !isShowMeasurement && !isShowGroupFType && !isShowGroupFPart && !isShowYarnType && !isShowAgency) {

                isShowGroup = UserRightsValue.find(m => m.ValidateMenu === 'GroupType' && m.ShowButton === 1);
                isShowCategory = UserRightsValue.find(m => m.ValidateMenu === 'Category' && m.ShowButton === 1);
                isShowStrikeOffType = UserRightsValue.find(m => m.ValidateMenu === 'StrikeOffType' && m.ShowButton === 1);
                isShowTrimType = UserRightsValue.find(m => m.ValidateMenu === 'TrimType' && m.ShowButton === 1);
                isShowMeasurement = UserRightsValue.find(m => m.ValidateMenu === 'MeasurementPoint' && m.ShowButton === 1);
                isShowGroupFType = UserRightsValue.find(m => m.ValidateMenu === 'FabricType' && m.ShowButton === 1);
                isShowGroupFPart = UserRightsValue.find(m => m.ValidateMenu === 'FabricPart' && m.ShowButton === 1);
                isShowYarnType = UserRightsValue.find(m => m.ValidateMenu === 'YarnType' && m.ShowButton === 1);
                isShowAgency = UserRightsValue.find(m => m.ValidateMenu === 'Agency' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Component' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowGroup || isShowCategory || isShowStrikeOffType || isShowTrimType || isShowMeasurement || isShowGroupFType || isShowGroupFPart || isShowYarnType || isShowAgency) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Component1(Master)
        else if (UserRightsValue[index].ValidateMenu === 'Swatch' || UserRightsValue[index].ValidateMenu === 'Role' || UserRightsValue[index].ValidateMenu === 'UserPermission' || UserRightsValue[index].ValidateMenu === 'Department' || UserRightsValue[index].ValidateMenu === 'Size' || UserRightsValue[index].ValidateMenu === 'GroupSize' || UserRightsValue[index].ValidateMenu === 'ProcessType') {
            if (!isShowSwatch && !isShowRole && !isShowPermission && !isShowDepartment && !isShowSize && !isShowGroupSize && !isShowProcess) {

                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'Swatch' && m.ShowButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.ValidateMenu === 'Role' && m.ShowButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'UserPermission' && m.ShowButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.ValidateMenu === 'Department' && m.ShowButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'Size' && m.ShowButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.ValidateMenu === 'GroupSize' && m.ShowButton === 1);
                isShowBuyerList = UserRightsValue.find(m => m.ValidateMenu === 'ProcessType' && m.ShowButton === 1);


                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'AddtionalComponent' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSwatch || isShowRole || isShowPermission || isShowDepartment || isShowSize || isShowGroupSize || isShowProcess) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }

        //Task Info(Master)
        else if (UserRightsValue[index].ValidateMenu === 'TaskType' || UserRightsValue[index].ValidateMenu === 'Task') {
            if (!isShowTaskType && !isShowTask) {
                isShowTaskType = UserRightsValue.find(m => m.ValidateMenu === 'TaskType' && m.ShowButton === 1);
                isShowTask = UserRightsValue.find(m => m.ValidateMenu === 'Task' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'TaskInfo' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowTaskType || isShowTask) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }
            }
        }
        //Courier Info
        else if (UserRightsValue[index].ValidateMenu === 'Courier') {
            if (!isShowCourier) {
                isShowCourier = UserRightsValue.find(m => m.ValidateMenu === 'Courier' && m.ShowButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'General' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'CourierInformation' && d.userType === 2 && d.subParentUserType === 102);
                if (isShowCourier) {
                    if (childParentList.length > 0) { childParentList[0].ShowButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].ShowButton = 1 }
                }

            }
        }

         //Final Inspection(Master)
         else if (UserRightsValue[index].ValidateMenu === 'Defects' || UserRightsValue[index].ValidateMenu === 'Workmanship' 
            || UserRightsValue[index].ValidateMenu === 'WorkmanshipGroup' || UserRightsValue[index].ValidateMenu === 'Package' 
            || UserRightsValue[index].ValidateMenu === 'PackageGroup' || UserRightsValue[index].ValidateMenu === 'Item' 
            || UserRightsValue[index].ValidateMenu === 'Template' ||  UserRightsValue[index].ValidateMenu ===  'PackageImageList') {
            if (!isShowFIDefecteDetails && !isShowFIWorkmanshipDetails && !isShowFIWorkmanshipTemplate && !isShowFIPackageItem 
                && !isShowFIPackageTemplate && !isShowFIImageName && !isShowFIAvailableInfoItem && !isShowFIAvailableInfoTemplate) {

                    isShowFIDefecteDetails = UserRightsValue.find(m => m.ValidateMenu === 'Defects' && m.showButton === 1);
                    isShowFIWorkmanshipDetails = UserRightsValue.find(m => m.ValidateMenu === 'Workmanship' && m.showButton === 1);
                    isShowFIWorkmanshipTemplate = UserRightsValue.find(m => m.ValidateMenu === 'WorkmanshipGroup' && m.showButton === 1);
                    isShowFIPackageItem = UserRightsValue.find(m => m.ValidateMenu === 'Package' && m.showButton === 1);
                    isShowFIPackageTemplate = UserRightsValue.find(m => m.ValidateMenu === 'PackageGroup' && m.showButton === 1);
                    isShowFIAvailableInfoItem = UserRightsValue.find(m => m.ValidateMenu === 'Item' && m.showButton === 1);
                    isShowFIAvailableInfoTemplate = UserRightsValue.find(m => m.ValidateMenu === 'Template' && m.showButton === 1);
                    isShowFIImageName = UserRightsValue.find(m => m.ValidateMenu === 'PackageImageList' && m.showButton === 1);
                    ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                debugger;
                //ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'FinalInspection' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowFIDefecteDetails || isShowFIWorkmanshipDetails || isShowFIWorkmanshipTemplate 
                    || isShowTrimType || isShowMeasurement || isShowGroupFType 
                    || isShowFIPackageItem || isShowFIPackageTemplate || isShowFIAvailableInfoItem
                    || isShowFIAvailableInfoTemplate || isShowFIImageName ) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    //isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Final Inspection 1 (Master)
        else if (UserRightsValue[index].ValidateMenu === 'CSRDetails' || UserRightsValue[index].ValidateMenu === 'CSRTemplate' 
            || UserRightsValue[index].ValidateMenu === 'CSRDropdown'   || UserRightsValue[index].ValidateMenu === 'Warehouse' 
            || UserRightsValue[index].ValidateMenu === 'BuyerCustomerWarehouse' || UserRightsValue[index].ValidateMenu === 'AQL' 
            || UserRightsValue[index].ValidateMenu === 'TestingItem' || UserRightsValue[index].ValidateMenu === 'TestingTemplate' 
            || UserRightsValue[index].ValidateMenu === 'AQLInspection' ||  UserRightsValue[index].ValidateMenu === 'AQLTolerance') {
            if (    !isShowFICsrDetails && !isShowFICsrTemplate  && !isShowFICsrDropdown
                 && !isShowFIWarehouse && !isShowFIBCWarehouse && !isShowFIAQL 
                 && !isShowFITestingItem && !isShowFITestingTemplate 
                 && !isShowFIAQLInspection && !isShowFIAQLTolerance) {

                isShowFICsrDetails = UserRightsValue.find(m => m.ValidateMenu === 'CSRDetails' && m.showButton === 1);
                isShowFICsrTemplate = UserRightsValue.find(m => m.ValidateMenu === 'CSRTemplate' && m.showButton === 1);
                isShowFICsrDropdown = UserRightsValue.find(m => m.ValidateMenu === 'CSRDropdown' && m.showButton === 1);
                isShowFIWarehouse = UserRightsValue.find(m => m.ValidateMenu === 'Warehouse' && m.showButton === 1);
                isShowFIBCWarehouse = UserRightsValue.find(m => m.ValidateMenu === 'BuyerCustomerWarehouse' && m.showButton === 1);
                isShowFIAQL = UserRightsValue.find(m => m.ValidateMenu === 'AQL' && m.showButton === 1);
                isShowFITestingItem = UserRightsValue.find(m => m.ValidateMenu === 'TestingItem' && m.showButton === 1);
                isShowFITestingTemplate = UserRightsValue.find(m => m.ValidateMenu === 'TestingTemplate' && m.showButton === 1);
                isShowFIAQLInspection = UserRightsValue.find(m => m.ValidateMenu === 'AQLInspection' && m.showButton === 1);
                isShowFIAQLTolerance = UserRightsValue.find(m => m.ValidateMenu === 'AQLTolerance' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                debugger;
                //ParentList = UserRightsValue.filter(d => d.ValidateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.ValidateMenu === 'AdditionalFinalInspection' && d.userType === 2 && d.subParentUserType === 3);
                if ( isShowFICsrDetails || isShowFICsrTemplate || isShowFICsrDropdown
                     || isShowFIWarehouse || isShowFIBCWarehouse || isShowFIAQL 
                     || isShowGroupFPart || isShowFITestingItem || isShowFITestingTemplate 
                     || isShowFIAQLInspection || isShowFIAQLTolerance ) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    //isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

    }

}
