import React, { useState, useEffect, useCallback, Fragment } from "react";
import InternalQAAuditDetails from './InspectionQCDetails'
import InternalQAAuditInspectionEntry from './InspectionQCInspectionEntry'
import ToDoReinspectionQA from './ToDoReinspectionQA'
import { useDispatch, useSelector } from "react-redux";

const PilotrunTab = ({ props, TaskName }) => {
   
    const [tabName, setTabName] = useState("idInspectionDetailQA");

    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();

    useEffect(() => {
        // ActivateDetailsTab()
        // setTabName('idInspectionDetailQA')
        if (props.location.state != null && props.location.state.params.PageName == "To Do Re Inspection") {
            ActivateReinspectionTab('idReInspection');
            }
        else if (props.location.state != null && props.location.state.params.PageName == "Inspection Entry") {
            ActivateEntryTab('idInspectionEntryQA');
            }
        else if(props.location.state == null || 
            props.location.state.params.PageName == "Inspection Details" ||
            props.location.state.params.PageName == undefined){
            ActivateDetailsTab('idInspectionDetailQA');
          }
    }, [props.location.state])

    const ActivateDetailsTab = (value) => {
        // document.getElementById("liDetailsQA").className = "active";
        // var elementEntry = document.getElementById("liEntryQA");
        // elementEntry.classList.remove("active");
        document.getElementById("liDetailsQA").className = "active";
        var elementEntry = document.getElementById("liEntryQA");
        elementEntry.classList.remove("active");
        var elementReInspection = document.getElementById("liReInspectionDetails");
        elementReInspection.classList.remove("active");
        document.getElementById("idInspectionDetailQA").className = "tab-pane col-lg-12 active";
        document.getElementById("idInspectionEntryQA").className = "tab-pane col-lg-12";
        document.getElementById("idReInspection").className = "tab-pane col-lg-12";
        setTabName(value)
    }
    const ActivateEntryTab = (value) => {
        // document.getElementById("liEntryQA").className = "active";
        // var elementDetails = document.getElementById("liDetailsQA");
        // elementDetails.classList.remove("active");
        document.getElementById("liEntryQA").className = "active";
        var elementDetails = document.getElementById("liDetailsQA");
        elementDetails.classList.remove("active");
        var elementReInspection = document.getElementById("liReInspectionDetails");
        elementReInspection.classList.remove("active");
        document.getElementById("idInspectionDetailQA").className = "tab-pane col-lg-12";
        document.getElementById("idInspectionEntryQA").className = "tab-pane col-lg-12 active";
        document.getElementById("idReInspection").className = "tab-pane col-lg-12";
        setTabName(value)
    }
    const ActivateReinspectionTab = (value) => {

        document.getElementById("liReInspectionDetails").className = "active";
        var elementDetails = document.getElementById("liDetailsQA");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liEntryQA");
        elementEntry.classList.remove("active");
        document.getElementById("idInspectionDetailQA").className = "tab-pane col-lg-12";
        document.getElementById("idInspectionEntryQA").className = "tab-pane col-lg-12";
        document.getElementById("idReInspection").className = "tab-pane col-lg-12 active";
        setTabName(value)
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable" style={{ maxWidth: '113vw' }}>
                        <ul className="nav nav-tabs nav-justified" id="myTab5" >
                            <li className="active" id="liDetailsQA">
                                <a data-toggle="tab" href="#idInspectionDetailQA" id="aidInspectionDetailQA" onClick={() => ActivateDetailsTab('idInspectionDetailQA')}>
                                    <h5>Inspection Details</h5>
                                </a>
                            </li>
                            <li className="" id="liEntryQA" >
                                <a data-toggle="tab" href="#idInspectionEntryQA" id="aidInspectionEntryQA"
                                    onClick={() => ActivateEntryTab('idInspectionEntryQA')}
                                >
                                    <h5>Inspection Entry</h5>
                                </a>
                            </li>
                            <li className="" id="liReInspectionDetails" >
                                <a data-toggle="tab" href="#idReInspection" id="aidReInspection"
                                    onClick={() => ActivateReinspectionTab('idReInspection')}
                                >
                                    <h5>To Do Re Inspection</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content Dashboard" style={{ padding: "0px" }}>
                            <div id="idInspectionDetailQA" className="" style={{ padding: "0px" }} >
                                {tabName === 'idInspectionDetailQA' && <InternalQAAuditDetails props={props} Taskcallback={TaskName} />}
                            </div>
                            <div id="idInspectionEntryQA" className="" style={{ padding: "0px" }}>
                                {tabName === 'idInspectionEntryQA' && <InternalQAAuditInspectionEntry props={props} Taskcallback={TaskName} />}
                            </div>
                            <div id="idReInspection" className="" style={{ padding: "0px" }}>
                                {tabName === 'idReInspection' && <ToDoReinspectionQA props={props} Taskcallback={TaskName} />}
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </Fragment >
    )
}

export default PilotrunTab