import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from 'react-router-dom';
// import { Doughnut, Line } from "react-chartjs-2";
//import Chart from 'chart.js/auto';
//import { DashboardChart } from "../../actions/chart";
// import { Col } from 'react-bootstrap';
// import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import FinalInspectionList from "../FinalInspection/FinalInspectionList";
import FinalInspectionEntry from "../FinalInspection/FinalInspectionEntry";
import ReFinalInspection from "../FinalInspection/ReFinalInspection";
//import Tnareport from '../../components/TNA/TNAReport'
import ReactNotification from 'react-notifications-component';
import { LoadFlDetailsreport, LoadFinalInspectionList, LoadReFinalInspectionList } from "../../../../actions/finalInspection";
//import CommentStyle from "../Reports/CommentStyle";
const Dashboard = (props) => {

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let isLoadingDashboard = useSelector((state) => state.dashboardreducer.isLoadingDashboard);
  const CurrentUser = useSelector((state) => state.auth.user);
  const [ChartCount, setChartCount] = useState({ totalStyleCount: 0, createdStyleCount: 0 });
  const dispatch = useDispatch();
  const [chosenChart, setchosenChart] = useState("1");
  const [ActiveTab, setActiveTab] = useState(1);

  const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
 
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

  
  var defaultStartDate = new Date();
  const [stateDate, setStateDate] = useState([
    {
      startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);
  var defaultExStartDate = new Date();
  const [stateExDate, setStateExDate] = useState([
    {

      startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);

  var defaultInspectionStartDate = new Date();
  const [stateInsDate, setStateInsDate] = useState([
    {

      startDate: defaultInspectionStartDate.setDate(defaultInspectionStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);

  var defaultTNAStartDate = new Date();
  const [stateTNADate, setStateTNADate] = useState([
    {

      startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);

  var defaultApStartDate = new Date();
  const [stateApDate, setStateApDate] = useState([
    {

      startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);


  const ActivateDetailsTab = () => {
    document.getElementById("liDetails").className = "active";
    var elementEntry = document.getElementById("liEntry");
    elementEntry.classList.remove("active");
    var elementReInspection = document.getElementById("liReInspectionDetails");
    elementReInspection.classList.remove("active");
    document.getElementById("idInspectionDetail").className = "tab-pane col-lg-12 active";
    document.getElementById("idInspectionEntry").className = "tab-pane col-lg-12";
    document.getElementById("idReInspection").className = "tab-pane col-lg-12";


    if (props.location.state && props.location.state.params.localStorage !== undefined && props.location.state.params.localStorage !== false) {
      const PoStartdate = localStorage.getItem("PodateStatedate")
      const POenddate = localStorage.getItem("Podateenddate")
      const PoExStartdate = localStorage.getItem("PodateExStatedate")
      const POExenddate = localStorage.getItem("PodateExenddate")
      stateDate[0].startDate = PoStartdate === null ? new Date(stateDate[0].startDate) : new Date(PoStartdate);
      stateDate[0].endDate = POenddate === null ? new Date() : new Date(POenddate);
      stateExDate[0].startDate = PoExStartdate === null ? new Date(stateExDate[0].startDate) : new Date(PoExStartdate);
      stateExDate[0].endDate = POExenddate === null ? new Date() : new Date(POExenddate);
      setStateDate(stateDate);
      setStateExDate(stateExDate);
    }
    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    setIsProductionStatussearch(Defaultvalue);
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }

    let TNAStartDate = null;
    let TNAEndDate = null;
    if (moment(stateTNADate[0].startDate).isValid()) {
      TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateTNADate[0].endDate).isValid()) {
      TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }

    let InspectionStartDate = null;
    let InspectionEndDate = null;
    if (moment(stateInsDate[0].startDate).isValid()) {
      InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateInsDate[0].endDate).isValid()) {
      InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    const currentDate = new Date();

    // Create a moment object from the current date and add one year
    const futureDate = moment(currentDate).add(1, 'year');

    // Format the future date
    const formattedDate = futureDate.format('MM/DD/YYYY');
    let params = {
      IsProduction: IsProduction
      , PoStartDate: '01/01/1970'
      , PoEndDate: EndDate
      , ExfacStartDate: '01/01/1970'
      , ExfacEndDate: formattedDate
      , TNAStartDate: TNAStartDate
      , TNAEndDate: TNAEndDate
      , InspectionStartDate: '01/01/1970'
      , InspectionEndDate: InspectionEndDate
    }
    dispatch(LoadFlDetailsreport(params));
  }

  const ActivateEntryTab = () => {

    document.getElementById("liEntry").className = "active";
    var elementDetails = document.getElementById("liDetails");
    elementDetails.classList.remove("active");
    var elementReInspection = document.getElementById("liReInspectionDetails");
    elementReInspection.classList.remove("active");
    document.getElementById("idInspectionDetail").className = "tab-pane col-lg-12";
    document.getElementById("idInspectionEntry").className = "tab-pane col-lg-12 active";
    document.getElementById("idReInspection").className = "tab-pane col-lg-12";
    ; let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    setIsProductionStatussearch(Defaultvalue);

    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartApDate = null;
    let EndApDate = null;
    if (moment(stateApDate[0].startDate).isValid()) {
      StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateApDate[0].endDate).isValid()) {
      EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }

    dispatch(LoadFinalInspectionList(params));
    localStorage.setItem("Ischecked", false)

  }

  const ActivateReinspectionTab = () => {

    document.getElementById("liReInspectionDetails").className = "active";
    var elementDetails = document.getElementById("liDetails");
    elementDetails.classList.remove("active");
    var elementEntry = document.getElementById("liEntry");
    elementEntry.classList.remove("active");
    document.getElementById("idInspectionDetail").className = "tab-pane col-lg-12";
    document.getElementById("idInspectionEntry").className = "tab-pane col-lg-12";
    document.getElementById("idReInspection").className = "tab-pane col-lg-12 active";
    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    setIsProductionStatussearch(Defaultvalue);
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartApDate = null;
    let EndApDate = null;
    if (moment(stateApDate[0].startDate).isValid()) {
      StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateApDate[0].endDate).isValid()) {
      EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
    dispatch(LoadReFinalInspectionList(params));
  }

 
  //   ;
  //   var chartStartDate = new Date();
  //   const [chartDateRange, setchartDateRange] = useState([
  //     {
  //       startDate: chartStartDate.setDate(chartStartDate.getDate() - 30),
  //       endDate: new Date(),
  //       key: 'selection',
  //       isShow: false
  //     }
  //   ]);
  //   const handleDateSelect = (event, isShow) => {
  //     let getchartDateRange = [...chartDateRange];
  //     getchartDateRange[0].isShow = isShow;
  //     setchartDateRange(getchartDateRange);
  //   }
  //   const clearDateSelect = () => {
  //     const emptychartDateRange = [
  //       {
  //         startDate: null,
  //         endDate: new Date(""),
  //         key: 'selection',
  //         isShow: false
  //       }];
  //     setchartDateRange(emptychartDateRange);
  //   }
  //   const DoughnutData = {
  //     labels: ["Total Style", "Created Style"],
  //     datasets: [
  //       {
  //         data: [ChartCount.totalStyleCount, ChartCount.createdStyleCount],
  //         borderColor: "#45B39D",
  //         backgroundColor: ["rgba(69, 179, 157, 0.5)", "rgba(69, 179, 157, 0.9)"],
  //         fill: true
  //       }
  //     ]
  //   };
  //   const Linedatapoints = [ChartCount.totalStyleCount, ChartCount.createdStyleCount];
  //   const LineData = {
  //     labels: ["Total Style", "Created Style Count"],
  //     datasets: [
  //       {
  //         label: "illustration of total styles to created styles",
  //         borderColor: "#45B39D",
  //         backgroundColor: ["rgba(69, 179, 157, 0.9)"],
  //         data: Linedatapoints,
  //         fill: false,
  //         cubicInterpolationMode: 'linear ',
  //       }
  //     ]
  //   };
  //   const SMSStyles = (
  //     <Doughnut
  //       type="doughnut"
  //       options={{
  //         color: '#FDFEFE',
  //         title: {
  //           display: true,
  //           fontSize: 15
  //         },
  //         legend: {
  //           display: true, //Is the legend shown?
  //           position: "top" //Position of the legend.
  //         },

  //       }}
  //       data={DoughnutData}
  //     />
  //   );
  //   const SMSLineStyles = (
  //     <Line
  //       type="line"
  //       width={130}
  //       height={60}
  //       options={{
  //         color: '#FDFEFE',
  //         tension: 3,
  //         plugins: {
  //           title: {
  //             display: true,
  //             text: 'Style Receipt',
  //             color: "#FDFEFE"
  //           }
  //         },
  //         responsive: true,
  //         interaction: {
  //           intersect: false,
  //         },
  //         scales: {
  //           x: {
  //             display: true,
  //             title: {
  //               display: true
  //             },
  //             ticks: {
  //               color: "white",
  //             },
  //             grid: {
  //               color: '',
  //               borderColor: '#FDFEFE'
  //             }
  //           },
  //           y: {
  //             display: true,
  //             title: {
  //               display: true,
  //               text: 'Value'
  //             },
  //             ticks: {
  //               color: "white"
  //             },
  //             grid: {
  //               color: '',
  //               borderColor: '#FDFEFE'
  //             },
  //             suggestedMin: -10,
  //             suggestedMax: 200
  //           }
  //         }
  //       }}
  //       data={LineData}

  //     />
  //   );

  // useEffect(() => {
  //
  //   if (!isLoadingDashboard) {
  //     hideLoader();
  //   } else {
  //     showLoader();
  //   }
  // }, [isLoadingDashboard])

  useEffect(() => {
   ;
    if (props.location.state == null ||
      props.location.state.params.PageName == "Inspection Details" ||
      props.location.state.params.PageName == undefined) {
      ActivateDetailsTab();
    }
    else if (props.location.state != null && props.location.state.params.PageName == "Inspection Entry") {
      ActivateEntryTab();
    }
    else if (props.location.state != null && props.location.state.params.PageName == "To Do Re Inspection") {
      ActivateReinspectionTab();
    }
  }, [props.location.state, ActiveTab])

  const { user: currentUser } = useSelector((state) => state.auth);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  // const child = useCallback(() =>  <FinalInspectionList props={props}></FinalInspectionList>, []);

  return (
    <>
      <ReactNotification />
      &nbsp;

      <div className="">
        <div className="col-md-12">

          <div className="tabbable" style={{ width: "100%" }}>
            <ul className="nav nav-tabs nav-justified" id="myTab5" >
              <li className="" id="liDetails">
                <a data-toggle="tab" href="#idInspectionDetail" id="aidInspectionDetail" onClick={() => ActivateDetailsTab()}>
                  <h5>Inspection Details</h5>
                </a>
              </li>
              <li className="" id="liEntry" >
                <a data-toggle="tab" href="#idInspectionEntry" id="aidInspectionEntry" onClick={() => ActivateEntryTab()}>
                  <h5>Inspection Entry</h5>
                </a>
              </li>

              <li className="" id="liReInspectionDetails">
                <a data-toggle="tab" href="#idReInspection" id="aidReInspection" onClick={() => ActivateReinspectionTab()}>
                  <h5>To Do Re Inspection</h5>
                </a>
              </li>
            </ul>

            <div className="tab-content Dashboard" style={{ padding: "0px" }}>
              <div id="idInspectionDetail" className="" style={{ padding: "0px" }} >
                {/* <h2 className="red">Work In Progress</h2> */}

                <FinalInspectionList props={props}></FinalInspectionList>
                {/* {child} */}
              </div>
              <div id="idInspectionEntry" className="" style={{ padding: "0px" }}>
                {/* <h2 className="red">Work In Progress</h2> */}
                <FinalInspectionEntry props={props} ></FinalInspectionEntry>
              </div>

              <div id="idReInspection" className="" style={{ padding: "0px" }}>
                {/* <h2 className="red">Work In Progress</h2> */}
                <ReFinalInspection props={props}></ReFinalInspection>
              </div>
            </div>


            {loader}

          </div>
        </div >
      </div >

      {loader}
    </>
  );
};

export default Dashboard;