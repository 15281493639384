import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Redirect } from 'react-router-dom';
// import InternalQAAuditDetails from './InspectionQCDetails'
// import InternalQAAuditInspectionEntry from './InspectionQCInspectionEntry'
// import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import TestingDetails from './TestingDetails';
import TestEntries from './TestEntries';
import Retest from './Retest';
import moment from "moment";
// import { LoadQADetailsreport } from '../../../../actions/inspectionQC'
const FabricTesting = ({ props, TaskValue }) => {
    var defaultStartDate = new Date();
    const [getStyleOptions, setStyleOptions] = useState([]);
    const [getTabName, setTabName] = useState("idTestingDetail");

    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {

            startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultTNAStartDate = new Date();
    const [stateTNADate, setStateTNADate] = useState([
        {

            startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();

    useEffect(() => {
        //setTabName("TestDetails")
        //ActivateDetailsTab("TestDetails");
       
        if (props.location.state != null && props.location.state.params.tabName == "To do Re Test") {
            ActivateReTestTab('idTestingReTest');
            }
        else if (props.location.state != null && props.location.state.params.tabName == "Testing Entry") {
            ActivateEntryTab('idTestingEntry');
            }
        else if(props.location.state == null || 
            props.location.state.params.tabName == "Testing Details" ||
            props.location.state.params.tabName == undefined){
            ActivateDetailsTab('idTestingDetail');
          }
        }, [props.location.state])

    const ActivateDetailsTab = (tabid) => {
        document.getElementById("liTestingDetails").className = "active";
        var elementEntry = document.getElementById("liTestingEntry");
        elementEntry.classList.remove("active");
        var elementReTest = document.getElementById("liTestingReTest");
        elementReTest.classList.remove("active");
        document.getElementById("idTestingDetail").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingEntry").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }
    const ActivateEntryTab = (tabid) => {
        document.getElementById("liTestingEntry").className = "active";
        var elementDetails = document.getElementById("liTestingDetails");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingReTest");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingReTest").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }

    const ActivateReTestTab = (tabid) => {
        document.getElementById("liTestingReTest").className = "active";
        var elementDetails = document.getElementById("liTestingDetails");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingEntry");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest").className = "tab-pane col-lg-12 active";
        setTabName(tabid);
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable max-width-view-port">
                        <ul className="nav nav-tabs nav-justified" id="myTab5" >
                            <li className="active" id="liTestingDetails">
                                <a data-toggle="tab" href="#idTestingDetail" id="aidTestingDetail"
                                    onClick={() => ActivateDetailsTab('idTestingDetail')}>
                                    <h5>Testing Details</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingEntry" >
                                <a data-toggle="tab" href="#idTestingEntry" id="aidTestingEntry"
                                    onClick={() => ActivateEntryTab('idTestingEntry')}
                                >
                                    <h5>Testing  Entry</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingReTest" >
                                <a data-toggle="tab" href="#idTestingReTest" id="aidTestingReTest"
                                    onClick={() => ActivateReTestTab('idTestingReTest')}
                                >
                                    <h5>To do Re Test</h5>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content Dashboard" style={{ padding: "0px" }}>
                            <div id="idTestingDetail" className="" style={{ padding: "0px" }} >
                                {getTabName === "idTestingDetail" && <TestingDetails props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingEntry" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingEntry" && <TestEntries props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingReTest" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingReTest" && <Retest props={props} Taskcallback={TaskValue} />}
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </Fragment>
    )
}

export default FabricTesting