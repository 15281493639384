import React, { useState, useEffect, useRef, Fragment } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import 'react-notifications-component/dist/theme.css';
import Select from "react-validation/build/select";
import strikeoffService from "../../../services/Style/StrikeoffService";
import Nodify from "../../Common/ReactNotification";
import { useSelector } from "react-redux";

const Reference = ({
    UOMCallBack,
    IsValidationCallback,
    ReferenceList,
    IsView,
    Id,
}) => {
    const EmptyInputFields =
    {
        StrikeOffReferenceID: 0,
        // StrikeOffID: 0,
        Height: '',
        Width: '',
        SizeID: 0,
        IsDeleted: 0
    }

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "StyleStrikeoff"); }

    // End Enable Menu style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }}
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [submitted, setSubmitted] = useState(false);
    const [getStrikeoffSizeList, setStrikeoffSizeList] = useState([]);
    const values = [...inputFields];
    const inputRef = useRef();

    useEffect(() => {
        strikeoffService.LoadStrikeoffSizeList().then((response) => {
            if (response.data) {
                setStrikeoffSizeList(response.data);
            }
        }).catch(() => { });

    }, []);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    useEffect(() => {
        if (ReferenceList.length !== 0) {
            ReferenceList.forEach(Reference => {
                inputFields.push({
                    StrikeOffReferenceID: Reference.strikeOffReferenceID,
                    // StrikeOffID: Reference.strikeOffID,
                    SizeName: Reference.height + "-" + Reference.width,
                    Height: Reference.height,
                    Width: Reference.width,
                    SizeID: Reference.sizeID,
                    IsDeleted: Reference.isDeleted === undefined ? 0 : Reference.isDeleted,
                });
                setInputFields(inputFields);
            })
        }
        if (Id !== 0) {
            if (ReferenceList.length !== 0) {
                const values = [...inputFields];
                values.splice(0, 1);
                setInputFields(values);
            }
        }
    }, [ReferenceList]);

    const handleAddFields = (index) => {
        let IsValid = true;
        var CheckValue1 = [...inputFields].filter(x => x.IsDeleted === 0);

        for (let item of CheckValue1) {
            if (item.Height === ''
                || item.Width === ''
                || item.SizeID === 0) {
                IsValid = false;
            }
        }
        if (IsValid) {
            values.push(EmptyInputFields);
            setInputFields(values);
            UOMCallBack(values);
            setSubmitted(false);
        } else {
            Nodify('Warning!', 'warning', 'Please fill this mandatory(*) fields.');
            setSubmitted(true);
        }
    }

    const handleInputChange = (index, event) => {
        setSubmitted(false);
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }

        if (event.target.name === "Height") {
            // var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            // values[index].Height = checkNumber[0];
            if (inputText !== '') {
                // var regex = /^(?=.*?[1-9])[0-9()-/\+]+$/; to check number entered or not
                var regex = /^[0-9()-/\+]+$/;
                let isValid = regex.test(inputText);
                if (isValid) {
                    values[index].Height = inputText;
                }
            }
            else {
                values[index].Height = '';
            }

        }
        else if (event.target.name === "Width") {
            // var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            // values[index].Width = checkNumber[0];
            if (inputText !== '') {
                var regex = /^[0-9()-/\+]+$/;
                let isValid = regex.test(inputText);
                if (isValid) {
                    values[index].Width = inputText;
                }
            }
            else {
                values[index].Width = '';
            }
        }
        else {
            values[index][event.target.name] = parseInt(inputText);
        }

        setInputFields(values);
        UOMCallBack(values);
    }

    const CheckDuplicate = (index, FieldName) => {
        for (let item = 0; item < values.length; item++) {
            if (item !== index) {
                if (values[index].Height === values[item].Height
                    && values[index].Width === values[item].Width
                    && values[index].SizeID === values[item].SizeID) {
                    if (FieldName === "Height" || FieldName === 'Width') {
                        values[index][FieldName] = '';
                    }
                    else {
                        values[index][FieldName] = 0;
                    }
                    setInputFields(values);
                    UOMCallBack(values);
                    Nodify('Warning!', 'warning', "This reference is already exist!");
                    setSubmitted(true);
                    return false;
                }
            }
        }
    }

    const handleRemoveFields = index => {
        if (values[index].StrikeOffReferenceID === 0) {
            values.splice(index, 1);
            setInputFields(values);
            UOMCallBack(values);
        }
        else {
            strikeoffService.CheckColorGroupMapped(2, parseInt(values[index].StrikeOffReferenceID)).then((response) => {
                if (response.data) {
                    if (response.data.outputResult === "-1") {
                        Nodify('Warning!', 'warning', "This reference is already mapped!");
                        return false;
                    }
                    else {
                        values[index].IsDeleted = 1;
                        setInputFields(values);
                        UOMCallBack(values);
                    }
                }
            });
        }
    }

    return (
        <>
            {
                inputFields.filter(x => x.IsDeleted === 0).map((inputField1, index1) => (

                    inputFields.filter(x => x.IsDeleted === 0).length === (index1 + 1) && (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="buttons-preview" align="right" style={{ marginBottom: "15px" }}>
                                    <button className="btn btn-success" title="Add Reference"
                                        onClick={
                                            () => handleAddFields(inputFields.filter(x => x.IsDeleted === 0).length - 1)
                                        }>
                                        <i className="fa fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                ))
            }
            <div className="row">
                <div className="col-md-12">
                    <div className="dataTables_wrapper no-footer">
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                            <thead>
                                <tr>
                                    <th>Height<span className="text-danger">*</span></th>
                                    <th>Width<span className="text-danger">*</span></th>
                                    <th>UOM<span className="text-danger">*</span></th>
                                    <th style={{ display: activeMenu && activeMenu[0]?.isDelete === 0 ? 'none' : '' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inputFields.map((inputField, index) => (
                                        <Fragment key={
                                            `${inputField}~${index}`
                                        }>
                                            {
                                                inputField.IsDeleted === 0 && (
                                                    <tr>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"Height_" + index}
                                                                    name="Height"
                                                                    placeholder="Enter Height"
                                                                    maxLength="50"
                                                                    value={inputField.Height}
                                                                    ref={inputRef}
                                                                    onChange={event => handleInputChange(index, event)}
                                                                    onBlur={() => CheckDuplicate(index, 'Height')}
                                                                    style={{ border: submitted && inputField.Height === '' ? '1px solid red' : '' }}
                                                                />
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={"Width_" + index}
                                                                name="Width"
                                                                placeholder="Enter Width"
                                                                maxLength="50"
                                                                value={inputField.Width}
                                                                onChange={event => handleInputChange(index, event)}
                                                                onBlur={() => CheckDuplicate(index, 'Width')}
                                                                style={{ border: submitted && inputField.Width === '' ? '1px solid red' : '' }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select id={
                                                                "SizeID_" + index
                                                            }
                                                                name="SizeID"
                                                                className="form-select"
                                                                value={
                                                                    inputField.SizeID
                                                                }
                                                                onChange={
                                                                    event => handleInputChange(index, event)
                                                                }
                                                                onBlur={() => CheckDuplicate(index, 'SizeID')}
                                                                style={{ border: submitted && inputField.SizeID === 0 ? '1px solid red' : '' }}
                                                            >
                                                                <option value='0'>- Select UOM -</option>

                                                                {
                                                                    getStrikeoffSizeList.map(item => (
                                                                        <option key={
                                                                            item.id
                                                                        }
                                                                            value={
                                                                                item.id
                                                                            }>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                        <td style={{ display: activeMenu && activeMenu[0]?.isDelete === 0 ? 'none' : '' }}>
                                                            <span style={
                                                                {
                                                                    display: inputField.IsDeleted === 1 ? "none" : (inputFields.filter(x => x.IsDeleted === 0).length === 1 ? "none" : "")
                                                                }
                                                            }>
                                                                <button className="btn btn-danger" title="Delete Reference"
                                                                    style={{ display: activeMenu && activeMenu[0]?.isDelete === 1 ? '' : 'none' }}
                                                                    onClick={
                                                                        () => handleRemoveFields(index)
                                                                    }>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </button>
                                                            </span>

                                                        </td>
                                                    </tr>
                                                )}
                                        </Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>

    )
};
export default Reference;
