import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
// import ReactNotification from 'react-notifications-component';
import { getGeneralTrimDetailsViewList, clearTrimDetailsViewSubRow, UpdateGenTrimDetailsViewCheckBox, UpdateGenTrimDetailsViewAllCheckBox, getGenTrimTNARefernceStatusList } from "../../../../actions/generalTrim";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GenTrimInfoId } from "../../../Common/PageActionNumber";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import SizeService from "../../../../services/Master/SizeService";
import strikeoffService from "../../../../services/Style/StrikeoffService";
// import { event } from "jquery";


const TrimViewDetailTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    //reducer
    const TrimViewDetailList = useSelector((state) => state.generalTrim.generalTrimViewDetailList);
    const TrimViewDetailParams = useSelector((state) => state.generalTrim.generalTrimViewDetailParams);
    let isLoadingGeneralTrim = useSelector((state) => state.generalTrim.isLoadingGeneralTrim);

    let GLDStatusList = useSelector((state) => state.generalLabdip.GLDStatusList);

    //state

    const [getID, setID] = useState(0);
    const [getSearch, setSearch] = useState("");

    const [isOpen, setIsOpen] = useState(false);
    const [showViewStatusPopup, setShowViewStatusPopup] = useState(false);

    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currTrimId: "", currColorGroupID: "" });
    const [getPlaceholder] = useState("Buyer / Season / Brand");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getViewStatusList, setViewStatusList] = useState([]);
    const [getSizeList, setSizeList] = useState([]);
    const [getApprovalList, setApprovalList] = useState([]);


    const [getSearchvalues, setSearchvalues] = useState({
        StatusId: 0,
        ApprovalTypeId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });
    // let activeMenu;
    // let activeMenuSub;
    // let TrimViewDetailTab;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     activeMenu = menulist.filter(i => i.menuName === 'TrimList');
    // }
    // if (activeMenu.length > 0) {
    //     activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
    //     if (activeMenuSub.length > 0) {
    //         for (let index = 0; index < activeMenuSub.length; index++) {
    //             if (activeMenuSub[index].menuName === "GeneralTrimDetails") {
    //                 TrimViewDetailTab = activeMenuSub[index];
    //             }
    //         }
    //     }



    // }

    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
     let info;
    let activeMenu;
    let activeMenuSub;
    let TrimViewDetailTab;
   

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
        info = IsProduction === 1 ? 'TrimList' : 'ProductionOrderTrimList';
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                 if (menuName === getMenuName("GeneralTrimDetails")) {
                    TrimViewDetailTab = menuItem;
                    }

                    
                }
            }
        }
    }

    const Searchvalues = { ...getSearchvalues };

    useEffect(() => {
        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });
    }, [])

    //#region  ------------------  search details --------------------------------

    const searchTrim = () => {
        Searchvalues.stateDate[0].isShow = false;
        setSearchvalues(Searchvalues);

        let StartDate = null;
        let EndDate = null;
        if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = {
            BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
            BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
            TrimId: 0, ColorGroupID: 0, Index: 0, SearchText: Searchvalues.SearchTxt, StatusId: Searchvalues.StatusId ? Searchvalues.StatusId : null, ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(getGeneralTrimDetailsViewList(params));
        commonParentExpand();
    }

    const resetTrim = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        Searchvalues.StatusId = "";
        Searchvalues.SearchTxt = "";
        Searchvalues.ApprovalTypeId = "";
        setSearchvalues(Searchvalues);
        searchTrim();

    }


    const clearDateSelect = () => {
        Searchvalues.stateDate[0].startDate = null;
        Searchvalues.stateDate[0].endDate = new Date("");
        Searchvalues.stateDate[0].key = 'selection';
        Searchvalues.stateDate[0].isShow = false;

        setSearchvalues(Searchvalues);
    }
    const handleDateSelect = (event, isShow) => {

        Searchvalues.stateDate[0].isShow = isShow;
        setSearchvalues(Searchvalues);
    }

    const handlePopupDateselection = (item) => {

        Searchvalues.stateDate = [item.selection];
        setSearchvalues(Searchvalues);
    }

    const handleChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'ApprovalType') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            Searchvalues.StatusId = inputText;
        } else if (FieldName === 'ApprovalType') {
            Searchvalues.ApprovalTypeId = inputText;
        }
        else if (FieldName === 'SearchTxt') {
            Searchvalues.SearchTxt = inputText;
        }
        setSearchvalues(Searchvalues);

    }

    const ViewStatus = (genTrimRefernceID, genTrimId, genTrimLogoID) => {
        let params = {
            GenTrimRefernceID: genTrimRefernceID, GenTrimId: genTrimId,
            StatusId: Searchvalues.StatusId ? Searchvalues.StatusId : 0, GenTrimLogoID: genTrimLogoID
        };
        dispatch(getGenTrimTNARefernceStatusList(params))
            .then(data => {
                if (data && data.length > 0) {
                    setViewStatusList(data);
                    setShowViewStatusPopup(true);
                } else {
                    Nodify('Status', 'success', 'Currently - To Do status. Yet to request');
                    setViewStatusList([]);

                }
            })
            .catch(e => {
                console.log(e);
                hideLoader();
            });
    }

    const handleViewStatusPopupClose = () => {
        setShowViewStatusPopup(false);
    }


    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------

    useEffect(() => {
        if (!isLoadingGeneralTrim) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralTrim])



    const handleToggle = (index, TrimId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle_" + index);
        let iconToggle = document.getElementById("icon-toggle_" + index);
        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            let StartDate = null;
            let EndDate = null;
            if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = {
                BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
                BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
                TrimId: TrimId, ColorGroupID: ColorGroupID, Index: index, SearchText: Searchvalues.SearchTxt, StatusId: Searchvalues.StatusId, ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };
            setcurrentExpand({ currindex: index, currTrimId: TrimId, currColorGroupID: ColorGroupID })
            dispatch(getGeneralTrimDetailsViewList(params));
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (TrimId) {
                dispatch(clearTrimDetailsViewSubRow(index));

            }
        }
    };

    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpandViewTNA");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandViewTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    // const searchText = () => {
    //     let params = {
    //         BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
    //         BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
    //         PantoneId: 0, Index: 0, SearchText: (getSearch && getSearch.trim())
    //     };
    //     dispatch(getGeneralTrimDetailsViewList(params));
    // }
    useEffect(() => {

        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                setSizeList(list);
            }
        }).catch(() => { });
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                searchTrim();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    const pageRedirect = (Params, redirect) => {
        //
        // localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            let buyerbrandseason = {
                buyerId: TrimViewDetailParams.BuyerId, brandId: TrimViewDetailParams.BrandId, seasonId: TrimViewDetailParams.SeasonId,

            };
            props.props.location.state = { params: buyerbrandseason };
            props.props.history.push('/SamGenlTrimAddEdit', { params: buyerbrandseason });
        }
        else if (redirect === 'Edit') {

            let Param = { Operation: 51, Id: Params.genTrimRefernceID, };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This General Trim is already mapped.";
                    Nodify('Warning', 'warning', message);
                }
                else {
                    props.props.location.state = { params: Params };
                    props.props.history.push(`/SamGenlTrimAddEdit`, { params: Params, SizeList: getSizeList });

                }
            });

        }
        else if (redirect === 'CreateTna') {
            let TrimReferenceList = [];
            if (!isAllSelected) {
                let detailList = TrimViewDetailList.filter(d => d.trimReferenceList && d.trimReferenceList.length > 0);
                detailList.map((item, index) => {
                    item.trimReferenceList && item.trimReferenceList.filter(d => d.isSelected === true).map((item2) => {
                        TrimReferenceList.push({
                            GenTrimRefernceID: item2.genTrimRefernceID, GenTrimLogoID: item2.genTrimLogoID,
                            GenTrimMainId: item2.genTrimMainId, GenTrimId: item2.genTrimId
                        });
                    });

                })
            }

            let buyerbrandseason = {
                buyerId: TrimViewDetailParams.BuyerId, brandId: TrimViewDetailParams.BrandId, seasonId: TrimViewDetailParams.SeasonId,
                buyerName: TrimViewDetailParams.BuyerName, brandName: TrimViewDetailParams.BrandName, seasonName: TrimViewDetailParams.SeasonName,
                isAllSelected: isAllSelected, genTrimReferenceList: TrimReferenceList
            };

            if ((TrimReferenceList && TrimReferenceList.length > 0) || isAllSelected) {
                props.props.location.state = { params: buyerbrandseason };
                props.props.history.push('/SamGenlTrimAddTNA', { params: buyerbrandseason });
            } else {

                Nodify('Warning', 'warning', 'Please select any one Trim reference');
            }

        }
        else {
            //purchaseOrderID
            let Param = { Operation: 51, Id: Params.genTrimRefernceID };
            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let message = "This General Trim is already mapped.";
                    Nodify('Warning', 'warning', message);
                }
                else {
                    setID({ showPopupDelete: true, Params: Params });
                }
            });
        }
    };

    const Delcallback = (value) => {
        let StartDate = null;
        let EndDate = null;
        if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = {
            BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
            BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
            TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex, SearchText: Searchvalues.SearchTxt, StatusId: Searchvalues.StatusId, ApprovalTypeId: Searchvalues.ApprovalTypeId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(getGeneralTrimDetailsViewList(params));
        let btnToggle = document.getElementById("btn-toggle_" + currentExpand.currindex);
        let iconToggle = document.getElementById("icon-toggle_" + currentExpand.currindex);
        btnToggle.setAttribute('aria-expanded', 'false');
        iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        setID({ showPopupDelete: false, Params: [] });
    }


    //
    const ViewFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }

    const handleAllOnSelect = (event, FieldName) => {
        if (event.target.checked) {
            setIsAllSelected(true);
            dispatch(UpdateGenTrimDetailsViewAllCheckBox(true));
        } else {
            setIsAllSelected(false);
            dispatch(UpdateGenTrimDetailsViewAllCheckBox(false));
        }
    }


    const handleOnSelect = (event, FieldName, index, subIndex) => {
        let isChecked = false;
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: index, subIndex: subIndex
        };
        dispatch(UpdateGenTrimDetailsViewCheckBox(params));
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">
                           
                           <div className="col-md-2">
                               <div className='form-group'>
                                   <label>Trim Code / Name </label>
                                   <span className='input-icon icon-right'>
                                       <input type="text" name="SearchTxt" value={Searchvalues.SearchTxt} onChange={event => handleChange(event, "SearchTxt")} placeholder="Trim Code / Name" className="form-control" />
                                       {/* <span title='Search' onClick={() => searchText()}
                                       className="btn btn-danger btn-xs search"> */}
                                       {/* <i onClick={() => searchText()} className="fa fa-search"></i> */}
                                       {/* </span> */}
                                   </span>

                               </div>
                           </div>
                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="ApprovalType">
                                       Approval Type
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="ApprovalType"
                                           id={
                                               "ApprovalType"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleChange(event, 'ApprovalType')}
                                           value={getApprovalList.filter(function (option) {
                                               return option.value === Searchvalues.ApprovalTypeId;
                                           })}
                                           options={getApprovalList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>
                                   </span>
                               </div>
                           </div>


                           <div className="col-md-2">
                               <div className="form-group">
                                   <label htmlFor="Status">
                                       Status
                                   </label>
                                   <span className="input-icon icon-right">
                                       <Reactselect className="basic-single" name="Status"
                                           id={
                                               "Status"
                                           }
                                           isLoading={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={event => handleChange(event, 'Status')}
                                           value={GLDStatusList.filter(function (option) {
                                               return option.value === Searchvalues.StatusId;
                                           })}
                                           options={GLDStatusList}
                                           menuPortalTarget={document.body}
                                           styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                       ></Reactselect>
                                   </span>
                               </div>
                           </div>

                    


                     

                           <div className="col-md-2">

                               <div className="form-group">
                                   <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box will cause the date picker to appear and disappear">(Clicking on the text box...)</span> </label>
                                   <span className="input-icon icon-right">
                                       <input
                                           value={(Searchvalues.stateDate[0].startDate === null ? '' : moment(Searchvalues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((Searchvalues.stateDate[0].endDate === null || !moment(Searchvalues.stateDate[0].endDate).isValid()) ? '' : moment(Searchvalues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                                           onClick={event => handleDateSelect(event, !Searchvalues.stateDate[0].isShow)}
                                           //onBlur={event => handleDateBlur()}
                                           type="text"
                                           className={'form-control'}
                                           placeholder="Select Date"
                                       />
                                       <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                   </span>
                               </div>


                               {Searchvalues.stateDate[0].isShow &&
                                   <DateRangePicker
                                       //onChange={item => setStateDate([item.selection])}
                                       onChange={item => handlePopupDateselection(item)}
                                       showSelectionPreview={true}
                                       moveRangeOnFirstSelection={false}
                                       months={2}
                                       ranges={Searchvalues.stateDate}
                                       showDateDisplay={false}
                                       direction="vertical"
                                       className={'TNAReportDatePicker'}

                                   />

                               }

                           </div>
                           <div className="col-md-2" style={{ paddingTop: '29px' }}>
                               <span className="input-icon icon-right">
                                   <button type="button" className="btn btn-success" title="Search" onClick={event => searchTrim()}
                                       disabled={Searchvalues.Buyer === 0 || Searchvalues.Brand === 0 ||
                                           Searchvalues.Season === 0 || Searchvalues.Supplier === 0 ? true : false}
                                   >
                                       <i className="fa fa-search"></i>
                                   </button>
                                   &nbsp;&nbsp;
                                   <button type="button" className="btn btn-danger" title="Reset" onClick={resetTrim}
                                   >
                                       <i className="fa fa-close"></i>
                                   </button>
                               </span>
                           </div>

                           <div className="col-md-2" style={{ paddingTop: '29px' }}>
                               <div className="table-toolbar pull-right">
                                   &nbsp;
                                   {TrimViewDetailTab !== undefined && TrimViewDetailTab.isAdd !== 0 &&
                                       <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)}>
                                           <i className="fa fa-plus"></i>&nbsp;Add
                                       </span>
                                   }
                                   &nbsp;
                                   {TrimViewDetailTab !== undefined && TrimViewDetailTab.isAdd !== 0 &&

                                       TrimViewDetailList && TrimViewDetailList.length > 0 &&
                                       <span className="btn btn-primary" onClick={() => pageRedirect('', 'CreateTna', getPlaceholder)}>
                                           <i className="fa fa-plus"></i>&nbsp;Create TNA
                                       </span>

                                   }
                               </div>

                           </div>

                     
                   </div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Date</th>
                                        <th >Story</th>
                                        <th >Theme</th>
                                        <th >Details</th>
                                        <th >Purchase Type</th>
                                        <th >Trim Supplier</th>
                                        <th >Approval Type</th>
                                        <th >Task Holder / Follower</th>

                                        <th style={{ width: "200px", paddingBottom: "2px" }} className="text-center fixed-column-header-Route">
                                            Action  &nbsp;
                                            {
                                                TrimViewDetailList && TrimViewDetailList.length > 0 &&
                                                <label>
                                                    <input type="checkbox" name="IsAllSelected"
                                                        onChange={event => (handleAllOnSelect(event, 'IsAllSelected'))}
                                                        value={isAllSelected}
                                                        checked={isAllSelected}
                                                        className="clearOpacity textdesign" />
                                                    <span className="text"></span>
                                                </label>
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        TrimViewDetailList && TrimViewDetailList.length > 0 ?
                                            <>
                                                {
                                                    TrimViewDetailList && TrimViewDetailList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle_" + index} class="btn-toggle parentExpandViewTNA" aria-expanded="false" onClick={() => handleToggle(index, inputField.trimId, inputField.colorGroupID)} aria-labelledby="btn-toggle id-master">
                                                                            <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandViewTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.trim
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="borderRightLeftNone" colSpan="7">
                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="fixed-column-Route"></td>
                                                            </tr>
                                                            {
                                                                inputField.trimReferenceList && inputField.trimReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"16px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.recievedDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.storyName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.themeName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.purchaseTypeName ? subField.purchaseTypeName : "-"} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {(subField.trimSupplierName && subField.purchaseTypeID !== 3) ? subField.trimSupplierName : "-"} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.purchaseTypeID === 3 && (subField.approvalTypeName ? subField.approvalTypeName : "-")} </span>
                                                                                }
                                                                            </td>

                                                                            <td className="cut_textInTNA" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} </span>
                                                                                }

                                                                            </td>



                                                                            <td className="fixed-column-Route btn-action-center">
                                                                                {TrimViewDetailTab !== undefined && TrimViewDetailTab.isView !== 0 &&
                                                                                    <span title="View Status" class="btn btn-info btn-xs edit"
                                                                                        onClick={
                                                                                            event => ViewStatus(subField.genTrimRefernceID, subField.genTrimId, subField.genTrimLogoID)
                                                                                        }>
                                                                                        <i className="fa fa-eye">
                                                                                        </i>
                                                                                    </span>
                                                                                }
                                                                                &nbsp;

                                                                                {TrimViewDetailTab !== undefined && TrimViewDetailTab.isEdit !== 0 &&
                                                                                    <span title="Edit" className="btn btn-info btn-xs edit" onClick={() => pageRedirect(subField, 'Edit')}><i class="fa fa-edit"></i></span>
                                                                                }
                                                                                &nbsp;
                                                                                {TrimViewDetailTab !== undefined && TrimViewDetailTab.isDelete !== 0 &&
                                                                                    <span title='Delete' onClick={() => pageRedirect(subField, '')}
                                                                                        className="btn btn-danger btn-xs delete">
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </span>
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    subField.approvalTypeID === 1 && subField.purchaseTypeID === 3 &&
                                                                                    <label>
                                                                                        <input type="checkbox" name="IsSelected"
                                                                                            onChange={event => (handleOnSelect(event, 'IsSelected', index, subIndex))}
                                                                                            value={isAllSelected ? true : subField.isSelected}
                                                                                            checked={isAllSelected ? true : subField.isSelected}
                                                                                            className="clearOpacity textdesign" />
                                                                                        <span className="text"></span>
                                                                                    </label>
                                                                                }


                                                                            </td>
                                                                        </tr>
                                                                        {

                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName && subField.genTrimId === Crit.genTrimId
                                                                                    && subField.storyThemeIndexName === Crit.storyThemeIndexName
                                                                                    ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>

                                                                                    : ''
                                                                            ))
                                                                        }
                                                                        {



                                                                            inputField.manualCommentList && subField.approvalTypeID === 2 && inputField.manualCommentList.map((Comment) => (
                                                                                subField.trimIndexName === Comment.trimIndexName && subField.genTrimMainId === Comment.genTrimMainId && subField.storyThemeIndexName === Comment.storyThemeIndexName ?

                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={Comment.manualCloseComment} >
                                                                                        <td colSpan="12">
                                                                                            <div className="row-1">
                                                                                                <span><b>Manual Comment{inputField.approvalTypeID} :</b> {Comment.manualCloseComment}
                                                                                                </span>
                                                                                            </div>

                                                                                            {
                                                                                                inputField.manualImageList && inputField.manualImageList.map((files, fileindex) => (
                                                                                                    Comment.trimIndexName === files.trimIndexName
                                                                                                        // && Comment.genTrimId === files.genTrimId
                                                                                                        //     && Comment.storyThemeIndexName === files.storyThemeIndexName
                                                                                                        ?
                                                                                                        <div className="col-md-2 file_list_upload" >
                                                                                                            <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} title={files.fileName}  >
                                                                                                                File :
                                                                                                                <td className="cut_textInTNA"  >
                                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                                        {
                                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                        }
                                                                                                                    </span>  {files.fileName}
                                                                                                                </td>

                                                                                                                <td >

                                                                                                                    {
                                                                                                                        files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + "Images/Style/Trim/" + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                            // TrimViewDetailTab !== undefined && TrimViewDetailTab.isView !== 0 &&
                                                                                                                            <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                onClick={
                                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                                }></i>

                                                                                                                    }
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            {

                                                                                                                <div> {
                                                                                                                    isOpenimg && (

                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + "Images/Style/Trim/" + getImagename}
                                                                                                                            alt="bg image"
                                                                                                                            onCloseRequest={
                                                                                                                                () => setIsOpenimg(false)
                                                                                                                            } />
                                                                                                                    )
                                                                                                                } </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                        : ''
                                                                                                ))

                                                                                            }
                                                                                        </td>
                                                                                    </tr>




                                                                                    : ''
                                                                            ))
                                                                        }
                                                                        {
                                                                            inputField.existingList && subField.approvalTypeID === 3 && inputField.existingList.map((exist) => (
                                                                                subField.programIndexName === exist.programIndexName && subField.genTrimId === exist.genTrimId ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex}>
                                                                                        <td colSpan="10">
                                                                                            <span><b>Season Name : </b> {exist.seasonName} &nbsp;</span>
                                                                                            <span><b>Approval Date : </b>  {new Date(exist.approvalDate).toLocaleDateString()} &nbsp;</span>
                                                                                            <span><b>Details : </b> {exist.details} &nbsp;</span>
                                                                                            <span><b>Approved By : </b> {exist.approvedBy}</span>

                                                                                        </td>
                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }







                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            {/* <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span> */}
                            {/* <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span> */}
                            {/* <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button> */}
                        </div>
                    </div>
                </div>
            </div >
            {
                getID.showPopupDelete ? <SweetAlertPopup data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                    pageActionId={GenTrimInfoId}
                    Msg={"Trim Details Deleted Successfully."} /> : null
            }

            {
                //View Status
                showViewStatusPopup === true ?
                    <Modal show={showViewStatusPopup === true} dialogClassName="modal-lg" dragable backdrop="static" keyboard={false} onHide={() => handleViewStatusPopupClose()} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                View Status
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12">

                                <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                    <thead>
                                        <tr>
                                            <th>Supplier</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getViewStatusList && getViewStatusList.map((inputField) => (
                                                <Fragment>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                {inputField.supplier}

                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {inputField.status}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12" style={{ paddingTop: '8px' }}>
                                <Button variant="danger" onClick={() => handleViewStatusPopupClose()}>
                                    <i className="fa fa-times"></i>&nbsp;Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal> : ''
            }


            {loader}
        </Fragment >

    )
}

export default React.memo(TrimViewDetailTab);