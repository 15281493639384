import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Redirect } from 'react-router-dom';
// import InternalQAAuditDetails from './InspectionQCDetails'
// import InternalQAAuditInspectionEntry from './InspectionQCInspectionEntry'
// import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import TestingDetails from './TestingDetails';
import TestEntries from './TestEntries';
import Retest from './Retest';
import moment from "moment";
// import { LoadQADetailsreport } from '../../../../actions/inspectionQC'
const TrimTesting = ({ props, TaskValue }) => {
   
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();
    const [getTabName, setTabName] = useState("idTestingDetail3")

    useEffect(() => {
        debugger
        //ActivateDetailsTab();
        if (props.location.state != null && props.location.state.params.tabName == "To do Re Test") {
            ActivateReTestTab('idTestingReTest3');
            }
        else if (props.location.state != null && props.location.state.params.tabName == "Testing Entry") {
            ActivateEntryTab('idTestingEntry3');
            }
        else if(props.location.state == null || 
            props.location.state.params.tabName == "Testing Details" ||
            props.location.state.params.tabName == undefined){
            ActivateDetailsTab('idTestingDetail3');
          }
        }, [props.location.state])

    const ActivateDetailsTab = (tabid) => {
        
        document.getElementById("liTestingDetails3").className = "active";
        var elementEntry = document.getElementById("liTestingEntry3");
        elementEntry.classList.remove("active");
        var elementReTest = document.getElementById("liTestingReTest3");
        elementReTest.classList.remove("active");
        document.getElementById("idTestingDetail3").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingEntry3").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest3").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }
    const ActivateEntryTab = (tabid) => {
        
        document.getElementById("liTestingEntry3").className = "active";
        var elementDetails = document.getElementById("liTestingDetails3");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingReTest3");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail3").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry3").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingReTest3").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }

    const ActivateReTestTab = (tabid) => {
        
        document.getElementById("liTestingReTest3").className = "active";
        var elementDetails = document.getElementById("liTestingDetails3");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingEntry3");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail3").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry3").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest3").className = "tab-pane col-lg-12 active";
        setTabName(tabid);
    }



    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable max-width-view-port">
                        <ul className="nav nav-tabs nav-justified" id="myTab5" >
                            <li className="active" id="liTestingDetails3">
                                <a data-toggle="tab" href="#idTestingDetail3" id="aidTestingDetail"
                                    onClick={() => ActivateDetailsTab("idTestingDetail3")}>
                                    <h5>Testing Details</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingEntry3" >
                                <a data-toggle="tab" href="#idTestingEntry3" id="aidTestingEntry"
                                    onClick={() => ActivateEntryTab("idTestingEntry3")}
                                >
                                    <h5>Testing  Entry</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingReTest3" >
                                <a data-toggle="tab" href="#idTestingReTest3" id="aidTestingReTest"
                                    onClick={() => ActivateReTestTab("idTestingReTest3")}
                                >
                                    <h5>To do Re Test</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content Dashboard" style={{ padding: "0px" }}>
                            <div id="idTestingDetail3" className="" style={{ padding: "0px" }} >
                                {getTabName === "idTestingDetail3" && <TestingDetails props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingEntry3" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingEntry3" && <TestEntries props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingReTest3" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingReTest3" && <Retest props={props} Taskcallback={TaskValue} />}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </Fragment>
    )
}

export default TrimTesting