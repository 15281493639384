import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import UploadImage from "../Common/UploadImage"
//import Select from "react-validation/build/select";
import Reactselect from 'react-select';
import StyleService from "../../services/Style/styleService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Nodify from "../Common/ReactNotification"
//import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CommonLoader from "../Common/CommonLoader"
import measurementService from "../../services/Style/measurementService";
import FabricService from "../../services/Master/FabricService";
import SeasonPopup from "../quickMaster/_season";
import BrandPopup from "../quickMaster/_Brand";
import GroupTypePopup from "../quickMaster/_GroupType";
import CategoryPopup from "../quickMaster/_Category";
import BrandEndCustomerStoryTheme from "../quickMaster/_BrandEndCustomerStoryTheme";
import Fabric from "../quickMaster/_Fabric";
import { Modal, Button } from "react-bootstrap";
import GenderPopup from "../quickMaster/_Gender";
import CategoryService from "../../services/Master/CategoryService";
import SizePopup from "../quickMaster/_Size";
import SizeService from "../../services/Master/SizeService";
import { retrieveSeason } from "../../actions/season";
import ProcessTypePopup from "../quickMaster/_ProcessType";
import { retrieveProcessType } from "../../actions/ProcessType";
import ProcessTypeService from "../../services/Master/ProcessTypeService";
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import { loadCubeBuyerSupplierEmp } from "../../actions/common";


const StyleRegister = ({
    props, getStyleValue, fabriclist, remarkslist, IsValidationCallback, buttonview, existList, Buttonview,
    StyleInfoQuick, StyleinfoView, IsStyleTabClick, getIspage, getDisableMeasureSize, getDisableSampleSize, IsDataBoundCallback, IsDisabled
}) => {
   
    //const [getDisableMSize, setDisableMSize] = useState(false);
    // const [getDisableSSize, setDisableSSize] = useState(false);

    let M = getDisableMeasureSize
    let S = getDisableSampleSize

    //setDisableMSize(getDisableMeasureSize);
    //setDisableSSize(getDisableSampleSize);


    const dispatch = useDispatch();
    //reducer
    const reducerState = useSelector((state) => state);

    const { user: currentUser } = reducerState.auth;
    let getSeasonList = useSelector((state) => state.season.seasonList);

    let CubeList = useSelector((state) => state.common.CubeList);
    let SupList = useSelector((state) => state.common.SupList);
   
   
    // //Enable Menu
    // let activeMenu;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Style"); }

    // // End Enable Menu

     // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
     // End

       //Enable Menu
       let activeMenu;
       let activeMenuSub;
       let InternalComments;
       let info;
       const menulist = useSelector((state) => state.common.MenuList.item3);
       const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
       if (menulist !== undefined) {
           info = IsProduction === 1 ? 'Style' : (IsProduction === 2 ? 'ProductionStyle' : undefined);
           activeMenu = menulist.filter(i => i.menuName ===  info );
           
        //    if (activeMenu.length > 0) {
        //        activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
        //        if (activeMenuSub.length > 0) {
        //            for (let index = 0; index < activeMenuSub.length; index++) {
        //                InternalComments = activeMenuSub[index];
        //            }
        //        }
        //    }
           
       }
       // End Enable Menu
  
    // Filter the list to include only active seasons
    getSeasonList = getSeasonList.filter(season => season.activeStatus === 1);

// Sort the filtered list by seasonName in ascending order
    getSeasonList = getSeasonList.sort((a, b) => 
    a.seasonName.toString().toLowerCase() > b.seasonName.toString().toLowerCase() ? 1 : -1
   );

   // getSeasonList = getSeasonList.sort((a, b) => (a["seasonName"].toString().toLowerCase() > b["seasonName"].toString().toLowerCase() ? 1 : -1));
    const QuickStyleCreateDetail = reducerState.style.QuickStyleCreateDetail;
    const EmptyInputFields =
    {
        Operation: 1,
        StyleID: 0,
        BuyerID: 0,
        SeasonID: 0,
        BrandID: 0,
        GroupTypeID: 0,
        StyleName: '',
        StyleNo: '',
        EndCustomerID: 0,
        StoryID: 0,
        ThemeID: 0,
        CategoryID: 0,
        GenderID: 0,
        SizeID: 0,
        ProcessTypeID: 0,
        BuyerEmployeeID: 0,
        arrBuyerEmployeeID: [],
        arrCubeEmployeeID: [],
        arrSupplierEmployeeID: [],

        BuyerEmployeeIdList: '',
        CubeEmployeeIdList: '',
        SupplierEmployeeIdList: '',

        Importance: undefined,
        arrImportance: [],
        ProcessType: '',
        IDPoNo: '',
        StyleDescription: '',
        StyleLogoName: '',
        StyleLogoPath: '',
        StyleFabricDetails: '',
        CubeEmployeeName: '',
        IsStoryMandatory: 0,
        IsThemeMandatory: 0,
        // Remark :'',
        // ReceivedDate: '',
        RemarkList: [],
        FabricList: [],
        CreatedBy: currentUser.employeeinformationID,
    }

    const [getStyleList, setStyleList] = useState(EmptyInputFields);
    const [getDisabledBuyerbrand, setDisabledBuyerbrand] = useState(false);
    const [getAllSeasonList, setAllSeasonList] = useState([]);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getGroupList, setGroupList] = useState([]);
    const [getGenderList, setGenderList] = useState([]);
    const [getSizeList, setSizeList] = useState([]);
    const [getEmployeeList, setEmployeeList] = useState([]);
    const [getCubeEmployeeList, setCubeEmployeeList] = useState([]);
    const [getSupplierEmployeeList, setSupplierEmployeeList] = useState([]);
    const [getFabricList, setFabricList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [getEndCustomerList, setEndCustomerList] = useState([]);
    const [getStoryList, setStoryList] = useState([]);
    const [getThemeList, setThemeList] = useState([]);
    const [getCategoryList, setCategoryList] = useState([]);
    const [ClearDate, setClearDate] = useState(false);
    const [getCommonLoader, setCommonLoader] = useState(false);
    const [DisableSize, setDisableSize] = useState(false);
    const [ExistingFabricList, setExistingFabricList] = useState([]);
    const [getExistingCategoryList, setExistingCategoryList] = useState([]);
    const [getLoadSizeList, setLoadSizeList] = useState([]);
    const [getMaxidBrand, setMaxidBrand] = useState(0);
    const [getMaxidEndCust, setMaxidEndCust] = useState(0);
    const [getMaxidStory, setMaxidStory] = useState(0);
    const [getMaxidTheme, setMaxidTheme] = useState(0);
    const [getMaxidSeason, setMaxidSeason] = useState(0);
    const [getMaxidGroupType, setMaxidGroupType] = useState(0);
    const [getMaxidCategory, setMaxidCategory] = useState(0);
    const [getMaxidGender, setMaxidGender] = useState(0);
    const [getMaxidSize, setMaxidSize] = useState(0);
    const [getMaxidFabric, setMaxidFabric] = useState(0);
    const [getMaxidProcessType, setMaxidProcessType] = useState(0);
    const [getProcessTypeList, setProcessTypeList] = useState([]);
    const [getIsDataBound, setIsDataBound] = useState(false);
    // if (getIspage === 'Style' && IsStyleTabClick === true) {
    //     window.location.reload();
    // }


    //#region Popup Initialization
    const [getQuickMasterName, setQuickMasterName] = useState('');
    //#endregion

    let BuyerIDDefault = 0;
    let GroupIDDefault = 0;

    const inputRef = useRef();
    const [submitted, setSubmitted] = useState(false);

    let DynamicClassName = "col-lg-9 col-sm-8 col-xs-12"
    if (buttonview === "View") {
        DynamicClassName = "col-lg-12 col-sm-12 col-xs-12"
    }

    const [RemarksList, setRemarksList] = useState([{
        StyleRemarksInfoID: 0,
        Remarks: '',
        ReceivedDate: null,
        IsDeleted: 0,
        IsShowAttachment: 0,
        arrAttachment: [],
        AttachmentCount: 0

    }]);
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6;
    let BuyerListOptions = [] = [];

    const IMPORTANCE = [
        { value: '1', label: 'Priority' },
        { value: '2', label: 'Critical' }

    ];

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }
    let values = [...RemarksList]



    const handleChangeRemarks = (index, e, FieldName) => {
        //  setSubmitted(false);
        let inputText = '';
        if (FieldName === "Remarks") {
            //if (e.target.value !== '') {
            if (e.target.value.trim() !== "") {
                inputText = e.target.value;
                values[index][e.target.name] = inputText;
            } else {
                values[index][e.target.name] = inputText;
            }
            // }
        }
        else if (FieldName === "ReceivedDate") {
            if (e !== null) {
                values[index].ReceivedDate = e;
            }
            else {
                values[index].ReceivedDate = null;
            }

            values[index]["Remarks"] = values[index]["Remarks"].trim();
            //setStyleList(styleValue);
        }
        setRemarksList(values);
        remarkslist(values);// call back to parent
        //getStyleValue(existList);
    }

    const handleBlurRemarks = (index, e, FieldName) => {
        //  setSubmitted(false);
        let inputText = '';
        if (FieldName === "Remarks") {
            //if (e.target.value !== '') {
            inputText = e.target.value.trim();
            values[index][e.target.name] = inputText;
            // }
        }

        setRemarksList(values);
        remarkslist(values);// call back to parent
        //getStyleValue(existList);
    }

    const AddRemarksFields = (index) => {
        if (values[index].Remarks !== '' && values[index].ReceivedDate !== null) {
            values.push({
                StyleRemarksInfoID: 0,
                Remarks: '',
                ReceivedDate: null,
                IsDeleted: 0,
                IsShowAttachment: 0,
                arrAttachment: [],
                AttachmentCount: 0
            });
            setRemarksList(values);
            remarkslist(values);// call back to parent
            //getStyleValue(existList);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill all remarks mandatory(*) fields.');

        }

    }
    const [getFiles, setFiles] = useState([]);
    const handleAttachment = (index) => {
        values[index].IsShowAttachment = 1;
        setTaskIndexFileupload(index);
        setRemarksList(values);
        remarkslist(values);
        setFiles(values[index].arrAttachment);
    }
    const handleCloseAttachment = (index) => {
        values[index].IsShowAttachment = 0;
        setRemarksList(values);
        remarkslist(values);
        setFiles(values[index].arrAttachment);
    }
    const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    const filevals = [...getFiles]
    const AppendFiles = async (e) => {
        const file = e.target.files[0];
        let a = getTaskIndexFileupload;


        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "StyleRemarksFileUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    if (res.statusText === "Created") { }
                    filevals.push({
                        StyleRemarkFileId: 0,
                        FileName: file.name,
                        FilePath: "Images/StyleRemarksFileUpload/" + file.name,
                        FileType: file.type,
                        StyleRemarksIndexName: getTaskIndexFileupload.toString(),
                        StyleRemarksFileIndexName: a.toString(),
                    })
                    setFiles(filevals);
                    values[a].arrAttachment = filevals.filter(x => x.StyleRemarksFileIndexName.toString() === a.toString());
                    values[a].AttachmentCount = filevals.filter(x => x.StyleRemarksFileIndexName.toString() === a.toString()).length;
                    setRemarksList(values);
                    remarkslist(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        values[getTaskIndexFileupload].arrAttachment.splice(index, 1);
        values[getTaskIndexFileupload].AttachmentCount = filevals.length;
        setRemarksList(values);
        remarkslist(values);
        setFiles(filevals);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const handleRemoveFields = (index) => {

        values.splice(index, 1);
        setRemarksList(values);
        remarkslist(values);// call back to parent
    }

    const [FabricList, setFabricDetailsList] = useState([{
        StyleFabricInfoID: 0,
        ShellName: '',
        FabricDetails: '',
        ArrayFabricDetail: '',
        IsDeleted: 0

    }]);

    let FabricValues = [...FabricList]

    const AddFabricFields = (index) => {
        if (FabricValues[index].FabricDetails !== '') {
            FabricValues.push({
                StyleFabricInfoID: 0,
                ShellName: '',
                FabricDetails: '',
                ArrayFabricDetail: '',
                IsDeleted: 0
            });
            setFabricDetailsList(FabricValues);
            fabriclist(FabricValues);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill all fabric mandatory(*) fields.');
        }
    }

    const handleRemoveFabricFields = (index) => {
        FabricValues.splice(index, 1);
        setFabricDetailsList(FabricValues);
        fabriclist(FabricValues);
    }

    const handleInputChangeFabric = (event, index) => {
        // setSubmitted(false);
       ;
        if (event.length === 0) {
            FabricValues[index].FabricDetails = '';
            FabricValues[index].ArrayFabricDetail = '';
            ResetMaxId("FabricID");
        }
        else {
            ResetMaxId("FabricID");
            let strValue = '';
            for (let size of event) {
                if (strValue === '') { strValue = size.value.toString() }
                else { strValue = strValue + "," + size.value }
            }

            FabricValues[index].ShellName = "Shell-" + [index + 1];
            FabricValues[index].FabricDetails = strValue;
            FabricValues[index].ArrayFabricDetail = event;
        }
        setFabricDetailsList(FabricValues)
        fabriclist(FabricValues);
    }
    //Enter Error msg
    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             //SaveBuyerInfo(event);
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, []);


    // useEffect(() => {
    //     measurementService.GetMeasurement(props.location.state.params.styleID).then((response) => {
    //         if (response.data) {
    //             for (var i = 0; i < response.data.length; i++) {
    //                 if (response.data[i].measurementGrandingSize > 0) {
    //                     setDisableSize(true);
    //                 }
    //                 else {
    //                     setDisableSize(false);
    //                 }
    //             }
    //         }
    //     })

    //     StyleService.GetSamplereqList(1, props.location.state.params.styleID, 0).then((response) => {
    //         if (response.data.length > 0) {
    //             setDisableSize(true);
    //         }
    //         else {
    //             setDisableSize(false);
    //         }

    //     });
    // }, [IsStyleTabClick === true])
    useEffect(() => {

        if (existList.StyleID !== undefined && existList.StyleID !== 0) {
            //getStyleList(existList)
            getStyleList.Operation = 2
            getStyleList.BuyerID = existList.BuyerID
            getStyleList.SeasonID = existList.SeasonID
            getStyleList.BrandID = existList.BrandID
            getStyleList.GroupTypeID = existList.GroupTypeID
            getStyleList.StyleName = existList.StyleName
            getStyleList.StyleNo = existList.StyleNo
            getStyleList.CategoryID = existList.CategoryID
            getStyleList.GenderID = existList.GenderID
            getStyleList.SizeID = existList.SizeID
            getStyleList.ProcessTypeID = existList.ProcessTypeID
            getStyleList.ProcessType = existList.ProcessType
            getStyleList.IDPoNo = existList.IDPoNo
            getStyleList.StyleLogoName = existList.StyleLogoName
            getStyleList.StyleLogoPath = existList.StyleLogoPath

            //getStyleList.Importance = existList.Importance
            getStyleList.EndCustomerID = existList.EndCustomerID
            getStyleList.StoryID = existList.StoryID
            getStyleList.ThemeID = existList.ThemeID



            getStyleList.StyleID = existList.StyleID
            getStyleList.IsStoryMandatory = existList.IsStoryMandatory
            getStyleList.IsThemeMandatory = existList.IsThemeMandatory
            if (existList.Importance !== '' && existList.Importance !== undefined && existList.Importance !== null) {

                let Importance = existList.Importance.split(',');
                let arrVal = [];
                for (let StrImportance of Importance) {
                    let res = IMPORTANCE.filter(x => x.value === StrImportance);
                    arrVal.push({
                        value: res[0].value,
                        label: res[0].label
                    });
                }

                getStyleList.arrImportance = arrVal;
                getStyleList.Importance = existList.Importance
            }



            // if (existList.BuyerEmployeeIdList !== '' && existList.BuyerEmployeeIdList !== undefined && existList.BuyerEmployeeIdList !== null) {
            //     let BuyerEmployeeIdList = existList.BuyerEmployeeIdList.split(',');
            //     let arrVal = [];
            //     for (let StrBuyerEmployeeIdList of BuyerEmployeeIdList) {
            //         let res = getBuyerList.filter(x => x.value === StrBuyerEmployeeIdList);
            //         arrVal.push({
            //             value: res[0].value,
            //             label: res[0].label
            //         });
            //     }

            //     getStyleList.arrBuyerEmployeeID = arrVal;
            // }

            if (existList.BuyerEmployeeIdList !== '' && existList.BuyerEmployeeIdList !== undefined && existList.BuyerEmployeeIdList !== null) {
                let resp = [];
                StyleService.LoadBuyerEmployee(getStyleList.BuyerID).then((response) => {
                    if (response.data) {
                        setEmployeeList(response.data);
                        resp = response.data;
                        let BuyerEmployeeIdList = existList.BuyerEmployeeIdList.split(',');
                        let arrVal = [];
                        for (let StrBuyerEmployeeIdList of BuyerEmployeeIdList) {
                            let res = resp.filter(x => x.value === parseInt(StrBuyerEmployeeIdList));
                            arrVal.push({
                                value: res[0].value,
                                label: res[0].label
                            });
                        }
                        getStyleList.arrBuyerEmployeeID = arrVal;
                        getStyleList.BuyerEmployeeIdList = existList.BuyerEmployeeIdList;
                    }
                }).catch(() => { });
            }

            dispatch(loadCubeBuyerSupplierEmp(1))
                .then(data => {
                    if (data) {
                        if (data.length > 0) {
                            if (existList.CubeEmployeeIdList !== '' && existList.CubeEmployeeIdList !== undefined && existList.CubeEmployeeIdList !== null) {

                                let CubeEmployeeIdList = existList.CubeEmployeeIdList.split(',');
                                let arrVal = [];
                                for (let StrCubeEmployeeIdList of CubeEmployeeIdList) {
                                    let res = data.filter(x => x.value === parseInt(StrCubeEmployeeIdList));
                                    arrVal.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }
                                getStyleList.arrCubeEmployeeID = arrVal;
                                getStyleList.CubeEmployeeIdList = existList.CubeEmployeeIdList;
                            }


                        }

                    }
                })



            dispatch(loadCubeBuyerSupplierEmp(3))
                .then(data => {
                    if (data) {
                        if (data.length > 0) {
                            if (existList.SupplierEmployeeIdList !== '' && existList.SupplierEmployeeIdList !== undefined && existList.SupplierEmployeeIdList !== null) {

                                let SupplierEmployeeIdList = existList.SupplierEmployeeIdList.split(',');
                                let arrVal = [];
                                for (let StrSupplierEmployeeIdList of SupplierEmployeeIdList) {
                                    let res = data.filter(x => x.value === parseInt(StrSupplierEmployeeIdList));
                                    if (res.length > 0) {
                                        arrVal.push({
                                            value: res[0].value,
                                            label: res[0].label
                                        });
                                    }

                                }

                                getStyleList.arrSupplierEmployeeID = arrVal;
                                getStyleList.SupplierEmployeeIdList = existList.SupplierEmployeeIdList;
                            }

                        }
                    }
                })

            if (getStyleList.BuyerID !== 0) {
                LoadBuyerBased(getStyleList.BuyerID, setBrandList, setEndCustomerList, setStoryList, setThemeList, setEmployeeList, BuyerBrandDetails);
            }

            if (getStyleList.GroupTypeID !== 0) {
                LoadGroupBased(getStyleList.GroupTypeID, setCategoryList, setFabricList)
            }

            measurementService.GetMeasurement(existList.StyleID).then((response) => {
                if (response.data) {
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].measurementGrandingSize > 0) {
                            M = true;

                        }
                    }
                }
            })

            StyleService.GetSamplereqList(1, existList.StyleID, 0).then((response) => {
                if (response.data.length > 0) {
                    S = true;
                }

            });

        } else if (QuickStyleCreateDetail.isQuickStyle) {
            getStyleList.Operation = 1
            getStyleList.BuyerID = existList.BuyerID
            getStyleList.SeasonID = existList.SeasonID
            getStyleList.BrandID = existList.BrandID
            getStyleList.GroupTypeID = existList.GroupTypeID
            getStyleList.StyleName = ""
            getStyleList.StyleNo = ""
            getStyleList.CategoryID = 0
            getStyleList.GenderID = existList.GenderID
            getStyleList.SizeID = existList.SizeID
            getStyleList.ProcessTypeID = 0
            getStyleList.ProcessType = ""
            getStyleList.IDPoNo = existList.IDPoNo
            getStyleList.StyleLogoName = ""
            getStyleList.StyleLogoPath = ""
            getStyleList.CubeEmployeeName = existList.CubeEmployeeName
            //getStyleList.Importance = existList.Importance
            getStyleList.EndCustomerID = existList.EndCustomerID
            getStyleList.StoryID = existList.StoryID
            getStyleList.ThemeID = existList.ThemeID
            getStyleList.BuyerEmployeeID = existList.BuyerEmployeeID
            getStyleList.StyleID = 0
            getStyleList.IsStoryMandatory = 0
            getStyleList.IsThemeMandatory = 0

            if (getStyleList.BuyerID !== 0) {
                LoadBuyerBased(getStyleList.BuyerID, setBrandList, setEndCustomerList, setStoryList, setThemeList, setEmployeeList, BuyerBrandDetails);
            }

            if (getStyleList.GroupTypeID !== 0) {
                LoadGroupBased(getStyleList.GroupTypeID, setCategoryList, setFabricList)
            }
        }

        FabricService.GetFabricList(0).then((response) => {
            if (response.data) {
                setExistingFabricList(response.data);
            }
        });

        CategoryService.GetCategoryList(0).then((response) => {
            if (response.data) {
                setExistingCategoryList(response.data);
            }
        });



        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                setLoadSizeList(list);
            }
        }).catch(() => { });
        dispatch(loadCubeBuyerSupplierEmp(1))
            .then(data => {
                if (data) {
                    if (data.length > 0) {
                        if (existList.CubeEmployeeIdList !== '' && existList.CubeEmployeeIdList !== undefined && existList.CubeEmployeeIdList !== null) {

                            let CubeEmployeeIdList = existList.CubeEmployeeIdList.split(',');
                            let arrVal = [];
                            for (let StrCubeEmployeeIdList of CubeEmployeeIdList) {
                                let res = data.filter(x => x.value === parseInt(StrCubeEmployeeIdList));
                                arrVal.push({
                                    value: res[0].value,
                                    label: res[0].label
                                });
                            }
                            getStyleList.arrCubeEmployeeID = arrVal;
                            getStyleList.CubeEmployeeIdList = existList.CubeEmployeeIdList;
                        }


                    }

                }
            })



        dispatch(loadCubeBuyerSupplierEmp(3))
            .then(data => {
                if (data) {
                    if (data.length > 0) {
                        if (existList.SupplierEmployeeIdList !== '' && existList.SupplierEmployeeIdList !== undefined && existList.SupplierEmployeeIdList !== null) {

                            let SupplierEmployeeIdList = existList.SupplierEmployeeIdList.split(',');
                            let arrVal = [];
                            for (let StrSupplierEmployeeIdList of SupplierEmployeeIdList) {
                                let res = data.filter(x => x.value === parseInt(StrSupplierEmployeeIdList));
                                if (res.length > 0) {
                                    arrVal.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }

                            }

                            getStyleList.arrSupplierEmployeeID = arrVal;
                            getStyleList.SupplierEmployeeIdList = existList.SupplierEmployeeIdList;
                        }

                    }
                }
            })
        setDisabledBuyerbrand((existList.IspurchaseOrderMapped !== null || existList.IspurchaseOrderMapped !== '') ? false : true)
    }, [existList]);




    // useEffect(() => {
    //     if (CubeList.length > 0) {
    //         if (existList.CubeEmployeeIdList !== '' && existList.CubeEmployeeIdList !== undefined && existList.CubeEmployeeIdList !== null) {

    //             let CubeEmployeeIdList = existList.CubeEmployeeIdList.split(',');
    //             let arrVal = [];
    //             for (let StrCubeEmployeeIdList of CubeEmployeeIdList) {
    //                 let res = CubeList.filter(x => x.value === parseInt(StrCubeEmployeeIdList));
    //                 arrVal.push({
    //                     value: res[0].value,
    //                     label: res[0].label
    //                 });
    //             }

    //             getStyleList.arrCubeEmployeeID = arrVal;
    //         }
    //     }
    //     if (SupList.length > 0) {

    //         if (existList.SupplierEmployeeIdList !== '' && existList.SupplierEmployeeIdList !== undefined && existList.SupplierEmployeeIdList !== null) {
    //             let SupplierEmployeeIdList = existList.SupplierEmployeeIdList.split(',');
    //             let arrVal = [];
    //             for (let StrSupplierEmployeeIdList of SupplierEmployeeIdList) {
    //                 let res = SupList.filter(x => x.value === parseInt(StrSupplierEmployeeIdList));
    //                 arrVal.push({
    //                     value: res[0].value,
    //                     label: res[0].label
    //                 });
    //             }

    //             getStyleList.arrSupplierEmployeeID = arrVal;

    //         }
    //     }
    // }, [SupList])




    useEffect(() => {
        if (existList.Importance !== undefined) {
            if (getStyleList.StyleID !== 0) {
                setCommonLoader(true);
                StyleService.GetRemarksInfo(getStyleList.StyleID)
                    .then((response) => {
                        if (response.data) {
                            //
                            let Remark = response.data.remarkDetailsList;
                            if (Remark.length !== 0) {
                                //RemarksList();
                                var fileuploadlist = [];
                                if (response.data.remarkFileUploadList.length !== 0) {
                                    response.data.remarkFileUploadList.forEach(element => {
                                        fileuploadlist.push({
                                            StyleRemarkFileId: element.styleRemarkFileId,
                                            FileName: element.fileName,
                                            FilePath: element.filePath,
                                            FileType: element.fileType,
                                            StyleRemarksIndexName: element.styleRemarksIndexName,
                                            StyleRemarksFileIndexName: element.styleRemarksFileIndexName
                                        })
                                    })
                                }


                                Remark.forEach((Remarksinfo, index) => {
                                    RemarksList.push({
                                        StyleRemarksInfoID: Remarksinfo.styleRemarksInfoID,
                                        Remarks: Remarksinfo.remarks,
                                        ReceivedDate: Remarksinfo.receivedDate === null ? null : new Date(Remarksinfo.receivedDate),
                                        arrAttachment: fileuploadlist.filter(x => x.StyleRemarksIndexName === Remarksinfo.styleRemarksIndexName).length === 0 ?
                                            [] : fileuploadlist,
                                        AttachmentCount: fileuploadlist.filter(x => x.StyleRemarksIndexName === Remarksinfo.styleRemarksIndexName).length

                                        //ReceivedDate: Remarksinfo.receivedDate,
                                    });
                                });
                                setRemarksList(RemarksList);
                                remarkslist(RemarksList);
                            }
                            if (RemarksList.length > 1) { RemarksList.splice(0, 1); }
                        }
                    })
                    .catch(() => { })
                    .finally(() => {
                        setCommonLoader(false);
                    });

            }
        }
    }, [existList.Importance !== undefined]);



    useEffect(() => {
       ;
        if (existList.FabricListInfo !== undefined) {

            StyleService.GetFabricInfo(getStyleList.StyleID)
                .then((response) => {
                    if (response.data) {

                        let Fabric = response.data;
                        if (Fabric.length !== 0) {
                            Fabric.forEach(Fabricinfo => {
                                let arr = Fabricinfo.fabricDetails.split(',');
                                let arrVal = [];
                                for (let size of arr) {
                                    let res = existList.FabricListInfo.filter(x => x.value === parseInt(size));
                                    arrVal.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }
                                FabricList.push({
                                    StyleFabricInfoID: Fabricinfo.styleFabricInfoID,
                                    ShellName: Fabricinfo.shellName,
                                    FabricDetails: Fabricinfo.fabricDetails,
                                    ArrayFabricDetail: arrVal,
                                });
                            });

                        }
                        if (FabricList.length > 1) { FabricList.splice(0, 1); }
                        //LFD
                        setIsDataBound(true);
                        setFabricList(existList.FabricListInfo);
                        fabriclist(existList.FabricListInfo);
                        IsDataBoundCallback(true);
                    }
                })
                .catch(() => { })
                .finally(() => {
                    setCommonLoader(false);
                });
        }

    }, [existList.FabricListInfo !== undefined]);

    useEffect(() => {
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {
                    //setBuyerList(response.data);
                    BuyerListOptions = response.data;
                    setBuyerList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                    // if (existList.BuyerEmployeeIdList !== '' && existList.BuyerEmployeeIdList !== undefined && existList.BuyerEmployeeIdList !== null) {
                    //     let BuyerEmployeeIdList = existList.BuyerEmployeeIdList.split(',');
                    //     let arrVal = [];
                    //     for (let StrBuyerEmployeeIdList of BuyerEmployeeIdList) {
                    //         let res = getBuyerList.filter(x => x.value === StrBuyerEmployeeIdList);
                    //         arrVal.push({
                    //             value: res[0].value,
                    //             label: res[0].label
                    //         });
                    //     }

                    //     getStyleList.arrBuyerEmployeeID = arrVal;
                    // }
                }

            })
            .catch(() => { });

        StyleService.LoadGroupType()
            .then((response) => {
                if (response.data) {
                    setGroupList(response.data);
                } else {
                }
            })
            .catch(() => { });

        if (getSeasonList.length === 0) {
            let SeasonID = 0;
            dispatch(retrieveSeason(SeasonID));
        }

        // StyleService.LoadSeason(1, 0)
        //     .then((response) => {
        //         if (response.data) {
        //             setSeasonList(response.data);
        //         } else {
        //         }
        //     })
        //     .catch(() => { });

        StyleService.LoadGender()
            .then((response) => {
                if (response.data) {
                    setGenderList(response.data);
                } else {
                }
            })
            .catch(() => { });

        StyleService.LoadSize()
            .then((response) => {
                if (response.data) {
                    setSizeList(response.data);
                } else {
                }
            })
            .catch(() => { });
        StyleService.LoadProcessType()
            .then((response) => {
                if (response.data) {
                    setProcessTypeList(response.data);
                } else {
                }
            })
            .catch(() => { });

        setDisabledBuyerbrand((existList.IspurchaseOrderMapped !== null || existList.IspurchaseOrderMapped !== '') ? false : true)
    }, []);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    let styleValue = getStyleList;

    const ImageCallback = (value) => {
        //let styleValue = getStyleList;
        styleValue.StyleLogoName = value;
        setStyleList(styleValue);
        getStyleValue(styleValue);
    }

    const ResetMaxId = (FieldName) => {
        if (FieldName === "BrandID") {
            setMaxidBrand(0);
        }
        if (FieldName === "EndCustomerID") {
            setMaxidEndCust(0);
        }
        if (FieldName === "StoryID") {
            setMaxidStory(0);
        }
        if (FieldName === "ThemeID") {
            setMaxidTheme(0);
        }
        if (FieldName === "SeasonID") {
            setMaxidSeason(0);
        }
        if (FieldName === "CategoryID") {
            setMaxidCategory(0);
        }
        if (FieldName === "GenderID") {
            setMaxidGender(0);
        }
        if (FieldName === "SizeID") {
            setMaxidSize(0);
        }
        if (FieldName === "GroupTypeID") {
            setMaxidGroupType(0);
        }
        if (FieldName === "FabricID") {
            setMaxidFabric(0);
        }
        if (FieldName === "ProcessTypeID") {
            setMaxidProcessType(0);
        }


    }

    // To ensure there are no empty spaces at the beginning of the strings for StyleName and StyleNo...

    // const handleChangeStyle = (e, FieldName) => {

    //     let inputText = e.target.value;

    //     // Remove leading spaces only
    //     if (inputText.startsWith(' ')) {
    //         inputText = inputText.trimStart();
    //     }

    //     let newStyleValue = { ...styleValue };
    //     newStyleValue[FieldName] = inputText;
    //     setStyleList(newStyleValue);
    // };


    //let styleValue = getStyleList;
    const handleChange = (e, FieldName) => {

        if (FieldName === "ReceivedDate") {
            if (e !== null) {
                styleValue.ReceivedDate = e;
            }
            else {
                styleValue.ReceivedDate = null;
            }
            setStyleList(styleValue);

        }
        else if (FieldName === "SeasonID"
            || FieldName === "BrandID"
            || FieldName === "EndCustomerID"
            || FieldName === "StoryID"
            || FieldName === "ThemeID"
            || FieldName === "CategoryID"
            || FieldName === "GenderID"
            || FieldName === "SizeID"
            || FieldName === "Importance"
            || FieldName === "BuyerEmployeeIdList"
            || FieldName === "CubeEmployeeIdList"
            || FieldName === "SupplierEmployeeIdList"
            || FieldName === "ProcessTypeID"
            || FieldName === "StyleName"
            || FieldName === "StyleNo"
        ) {
            BuyerIDDefault = styleValue.BuyerID;
            LoadBuyerBased(BuyerIDDefault, setBrandList, setEndCustomerList, setStoryList, setThemeList, setEmployeeList, BuyerBrandDetails);
            if (e !== null) {
                if (FieldName === 'Importance') {
                    let strValue = '';
                    for (let size of e) {
                        if (strValue === '') { strValue = size.value.toString() }
                        else { strValue = strValue + "," + size.value }
                    }
                    styleValue['arrImportance'] = e;
                    styleValue[FieldName] = strValue;
                }
                else if (FieldName === 'BuyerEmployeeIdList') {
                    let strValue = '';
                    for (let size of e) {
                        if (strValue === '') { strValue = size.value.toString() }
                        else { strValue = strValue + "," + size.value }
                    }
                    styleValue['arrBuyerEmployeeID'] = e;
                    styleValue[FieldName] = strValue;
                }
                else if (FieldName === 'CubeEmployeeIdList') {
                    let strValue = '';
                    for (let size of e) {
                        if (strValue === '') { strValue = size.value.toString() }
                        else { strValue = strValue + "," + size.value }
                    }
                    styleValue['arrCubeEmployeeID'] = e;
                    styleValue[FieldName] = strValue;
                }
                else if (FieldName === 'SupplierEmployeeIdList') {
                    let strValue = '';
                    for (let size of e) {
                        if (strValue === '') { strValue = size.value.toString() }
                        else { strValue = strValue + "," + size.value }
                    }
                    styleValue['arrSupplierEmployeeID'] = e;
                    styleValue[FieldName] = strValue;
                }
                else if (FieldName === "StyleName" || FieldName === "StyleNo") {
                    let inputText = '';
                    if (e.target.value.trim() !== '') {
                        inputText = e.target.value;
                    }
                    styleValue[FieldName] = inputText;
                    setStyleList(styleValue);
                }
                else { styleValue[FieldName] = e.value; }
                // setMaxid(0);
                ResetMaxId(FieldName);
                setStyleList(styleValue);
                getStyleValue(styleValue);
                return false;
            }
            else {
                styleValue[FieldName] = 0;
                ResetMaxId(FieldName);
                //setMaxid(0);
            }

            setStyleList(styleValue);
            getStyleValue(styleValue);


        }
        else {
            let inputtext = '';

            // if (e.target.value.trim() !== '') {
            //     //styleValue[e.target.name] = e.target.value;
            //     setSubmitted(false);
            // }

            // else
            // {
            //     IsValidationCallback = true;
            // }

            styleValue[e.target.name] = e.target.value.trim();
            setStyleList(styleValue);

        }
        getStyleValue(styleValue);
    }

    const handleChangeBuyer = (e) => {
        if (e !== null && e.id !== 0) {
            styleValue.BuyerID = e.id;
            styleValue.IsStoryMandatory = e.isStoryMandatory;
            styleValue.IsThemeMandatory = e.isThemeMandatory;
            BuyerIDDefault = styleValue.BuyerID;
            styleValue.BuyerName = e.name;
            LoadBuyerBased(BuyerIDDefault, setBrandList, setEndCustomerList, setStoryList, setThemeList, setEmployeeList, BuyerBrandDetails);
            styleValue['arrBuyerEmployeeID'] = "";
        }
        else {
            setBrandList([])
            setEndCustomerList([])
            setStoryList([])
            setThemeList([])
            setEmployeeList([])
            styleValue.BuyerID = 0;
        }
        styleValue.BrandID = 0;
        styleValue.EndCustomerID = 0;
        styleValue.StoryID = 0;
        styleValue.ThemeID = 0;
        //styleValue.BuyerEmployeeID = 0; changed by Ajay
        styleValue.BuyerEmployeeIdList = "";
        setStyleList(styleValue);
        getStyleValue(styleValue);

    };

    const handleChangeGroup = (e) => {

       ;
        if (e !== null && e.id !== 0) {
            ResetMaxId("GroupTypeID");
            styleValue.GroupTypeID = e.id;
            GroupIDDefault = styleValue.GroupTypeID;
            styleValue.GroupTypeName = e.name;
            LoadGroupBased(GroupIDDefault, setCategoryList, setFabricList)
            //fabriclist([]);
            // FabricList.forEach(item => {
            //     item.ArrayFabricDetail = [];
            // });
            FabricValues[0].FabricDetails = '';
            FabricValues[0].ArrayFabricDetail = '';
            setFabricDetailsList(FabricValues)
            fabriclist(FabricValues);
        }
        else {
            ResetMaxId("GroupTypeID");
            styleValue.GroupTypeID = 0;
            setCategoryList([]);
            setFabricList([]);
            //setFabricList([null]);
            // FabricList.ArrayFabricDetail = [];
            //fabriclist([null]);


        }
        styleValue.CategoryID = 0;
        setStyleList(styleValue);
        getStyleValue(styleValue);
    };
    //#region Popup Open



    const handleQuickmasterpopup = (Name) => {

        setQuickMasterName(Name);
    };
    //#endregion Popup Open

    const handlePopupClose = () => {
        setQuickMasterName('');
    };

    //#region PopupCallBack
    const seasonCallback = (value) => {
        if (value === true) {
            StyleService.LoadSeason(1, 0)
                .then((response) => {
                    if (response.data) {
                        //  setAllSeasonList(response.data);
                        getSeasonList = response.data;
                        var maxid = getSeasonList.reduce((max, data) => Math.max(max, data.value), getSeasonList[0].value);
                        setMaxidSeason(maxid);
                        styleValue.SeasonID = maxid;
                        setStyleList(styleValue);
                        getStyleValue(styleValue);
                    } else {
                    }
                })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }



    const groupTypeCallback = (value) => {
        if (value === true) {
            StyleService.LoadGroupType()
                .then((response) => {
                    if (response.data) {
                        setGroupList(response.data);
                        var Grouptype = response.data;
                        var maxid = Grouptype.reduce((max, data) => Math.max(max, data.value), Grouptype[0].value);
                        setMaxidGroupType(maxid);
                        styleValue.GroupTypeID = maxid;
                        setStyleList(styleValue);
                        getStyleValue(styleValue);
                    } else {
                    }
                })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }

    const categoryCallback = (value) => {
        if (value === true) {
            StyleService.LoadCategory(getStyleList.GroupTypeID).then((response) => {
                if (response.data) {
                    setCategoryList(response.data);
                    var Category = response.data;
                    var maxid = Category.reduce((max, data) => Math.max(max, data.value), Category[0].value);
                    setMaxidCategory(maxid);
                    styleValue.CategoryID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }

    const brandCallback = (value) => {
        if (value === true) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, getStyleList.BuyerID).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                    setBrandList(respected_Brand.filter(o => BuyerBrandDetails.some(({ brandId }) =>
                        o.value === brandId)));
                    //setBrandList(respected_Brand);
                    var maxid = respected_Brand.reduce((max, data) => Math.max(max, data.value), respected_Brand[0].value);
                    setMaxidBrand(maxid);
                    styleValue.BrandID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);

                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }


    const EndcustomerCallback = (value) => {
        if (value === true) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, getStyleList.BuyerID).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_EndCustomer = BuyerInfoList.filter(x => x.buyerMapID === 2);
                    setEndCustomerList(respected_EndCustomer);
                    var maxid = respected_EndCustomer.reduce((max, data) => Math.max(max, data.value), respected_EndCustomer[0].value);
                    setMaxidEndCust(maxid);
                    styleValue.EndCustomerID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);

                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }

    const ThemeCallBack = (value) => {
        if (value === true) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, getStyleList.BuyerID).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Theory = BuyerInfoList.filter(x => x.buyerMapID === 4);
                    setThemeList(respected_Theory);
                    var maxid = respected_Theory.reduce((max, data) => Math.max(max, data.value), respected_Theory[0].value);
                    setMaxidTheme(maxid);
                    styleValue.ThemeID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);

                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }

    const StoryCallBack = (value) => {
        if (value === true) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, getStyleList.BuyerID).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Story = BuyerInfoList.filter(x => x.buyerMapID === 3);
                    setStoryList(respected_Story);
                    var maxid = respected_Story.reduce((max, data) => Math.max(max, data.value), respected_Story[0].value);
                    setMaxidStory(maxid);
                    styleValue.StoryID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }
    }



    const FabricCallback = (value) => {
        if (value === true) {
            StyleService.LoadFabricDetails(getStyleList.GroupTypeID)
                .then((response) => {
                    if (response.data) {
                        setFabricList(response.data);
                        var Fabric = response.data;
                        var maxid = Fabric.reduce((max, data) => Math.max(max, data.value), Fabric[0].value);
                        setMaxidFabric(maxid);

                    }
                })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }



    const genderCallback = (value) => {
        if (value === true) {
            StyleService.LoadGender().then((response) => {
                if (response.data) {
                    setGenderList(response.data);
                    var Gender = response.data;
                    var maxid = Gender.reduce((max, data) => Math.max(max, data.value), Gender[0].value);
                    setMaxidGender(maxid);
                    styleValue.GenderID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }

    const sizeCallback = (value) => {
        if (value === true) {
            StyleService.LoadSize().then((response) => {
                if (response.data) {
                    setSizeList(response.data);
                    var Size = response.data;
                    var maxid = Size.reduce((max, data) => Math.max(max, data.value), Size[0].value);
                    setMaxidSize(maxid);
                    styleValue.SizeID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }
    const processTypeCallback = (value) => {

        if (value === true) {
            StyleService.LoadProcessType().then((response) => {
                if (response.data) {
                    setProcessTypeList(response.data);
                    var ProcessType = response.data;
                    var maxid = ProcessType.reduce((max, data) => Math.max(max, data.value), ProcessType[0].value);
                    setMaxidProcessType(maxid);
                    styleValue.ProcessTypeID = maxid;
                    setStyleList(styleValue);
                    getStyleValue(styleValue);
                } else {
                }
            })
                .catch(() => { });
            setQuickMasterName('');
        } else {
            setQuickMasterName('');
        }

    }
    //#endregion Popup Callback


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    const ShowRequired = (Name) => {
        if (Name === "Category" || Name === "Fabric") {
            Nodify('Warning!', 'warning', 'Please select group type.');
        }
        else {
            Nodify('Warning!', 'warning', 'Please select buyer.');
        }

    }

    const handleSeasonChange = () => {
        if (IsDisabled) {
            Nodify('Warning!', 'warning', "The season is Already Mapped you can't Add.");
        }
        else {
            setQuickMasterName('Season')
        }
    }
    const handleGroupSizeChange = (IsValid) => {
       
        if (IsValid) {
            Nodify('Warning!', 'warning', "The Group Size is Already Mapped you can't Add.");
        }
        else {
            setQuickMasterName('Size')
        }
    }



    return (
        <>
            {
                getCommonLoader === false && ((getIsDataBound && getStyleList.Operation == 2) || (!getIsDataBound && getStyleList.Operation == 1)) ?

                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="widget flat radius-bordered">

                                <div className="widget-body">
                                    <div id="registration-form">
                                        <Form>
                                            <div className="row">
                                                <div className="col-sm-9">


                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="BuyerID">Buyer Name<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BuyerID"
                                                                        id={
                                                                            "BuyerID"
                                                                        }
                                                                        isDisabled={IsDisabled}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={handleChangeBuyer}
                                                                        // value={getStyleList.BuyerID}
                                                                        value={getBuyerList.filter(function (option) {
                                                                            return option.value === getStyleList.BuyerID;
                                                                        })}
                                                                        options={getBuyerList}
                                                                        autoFocus
                                                                        styles={submitted && getStyleList.BuyerID === 0 ? styles : styles1}


                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="SeasonID" >
                                                                    {StyleInfoQuick.Season.length > 0 && StyleInfoQuick.Season[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => handleSeasonChange()}
                                                                        >

                                                                        </button>
                                                                    }
                                                                    Season<span className="text-danger">*</span>
                                                                </label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single"
                                                                        name="SeasonID"
                                                                        id={"SeasonID"}
                                                                        isDisabled={IsDisabled}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getSeasonList}
                                                                        // value={getStyleList.SeasonID}
                                                                        value={getSeasonList.filter(function (option) {
                                                                            return option.value === (getMaxidSeason !== 0 ? getMaxidSeason : getStyleList.SeasonID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'SeasonID')}
                                                                        styles={submitted && getStyleList.SeasonID === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                            {/* <div>
                                                                <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop" onClick={() => setQuickMasterName('Season')} title="Add Season">
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div> */}
                                                        </div>
                                                        <div className="col-sm-4">
                                                            {/* <div className='form-group'> */}
                                                            <div className='form-group'>
                                                                <label htmlFor="Name" >
                                                                    {StyleInfoQuick.Brand.length > 0 && StyleInfoQuick.Brand[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => getStyleList.BuyerID === 0 ? ShowRequired('Brand') : setQuickMasterName('Brand')} title="Add Brand">
                                                                        </button>
                                                                    }
                                                                    Brand<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right' >
                                                                    <Reactselect className="basic-single" name="BrandID"
                                                                        id={
                                                                            "BrandID"
                                                                        }
                                                                        isDisabled={IsDisabled}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        // value={getStyleList.BrandID}
                                                                        value={getBrandList.filter(function (option) {
                                                                            return option.value === (getMaxidBrand !== 0 ? getMaxidBrand : getStyleList.BrandID);

                                                                        })}
                                                                        options={getBrandList}
                                                                        onChange={event => handleChange(event, 'BrandID')}
                                                                        styles={submitted && getStyleList.BrandID === 0 ? styles : ''}

                                                                    ></Reactselect>

                                                                </span>
                                                            </div>

                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                onClick={() => setQuickMasterName('Brand')} title="Add Brand"
                                                                disabled={getStyleList.BuyerID === 0 ? true : false}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}



                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            {/* <div className='form-group'> */}
                                                            <div className='form-group'>
                                                                <label htmlFor="GroupTypeID">
                                                                    {StyleInfoQuick.GroupType.length > 0 && StyleInfoQuick.GroupType[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => setQuickMasterName('GroupType')} title="Add GroupType">
                                                                        </button>
                                                                    }
                                                                    Group Type<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="GroupTypeID"
                                                                        id={
                                                                            "GroupTypeID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={handleChangeGroup}
                                                                        // value={getStyleList.GroupID}
                                                                        value={getGroupList.filter(function (option) {
                                                                            return option.value === (getMaxidGroupType !== 0 ? getMaxidGroupType : getStyleList.GroupTypeID);
                                                                        })}
                                                                        options={getGroupList}
                                                                        styles={submitted && getStyleList.GroupTypeID === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop" onClick={() => setQuickMasterName('GroupType')} title="Add Group Type">
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="StyleName">Style Name<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Style Name"
                                                                        id="StyleName"
                                                                        name="StyleName"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"

                                                                        // ref={inputRef}
                                                                        className={'form-control'}
                                                                        value={getStyleList.StyleName}
                                                                        onChange={event => handleChange(event, 'StyleName')}
                                                                        style={
                                                                            {
                                                                                border: submitted && getStyleList.StyleName === '' ? '1px solid red' : ''
                                                                            }
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="Name">Style No<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Style No" id="StyleNo" name="StyleNo" //className="form-control"
                                                                        value={getStyleList.StyleNo}
                                                                        onChange={event => handleChange(event, 'StyleNo')}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control'}
                                                                        style={
                                                                            {
                                                                                border: submitted && getStyleList.StyleNo === '' ? '1px solid red' : ''
                                                                            }
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="EndCustomerID">
                                                                    {StyleInfoQuick.EndCustomer.length > 0 && StyleInfoQuick.EndCustomer[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => getStyleList.BuyerID === 0 ? ShowRequired('EndCustomer') : setQuickMasterName('End Customer')} title="Add EndCustomer">

                                                                        </button>
                                                                    }
                                                                    End Customer</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="EndCustomerID"
                                                                        id={
                                                                            "EndCustomerID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getEndCustomerList}
                                                                        // value={getStyleList.EndCustomerID}
                                                                        value={getEndCustomerList.filter(function (option) {
                                                                            return option.value === (getMaxidEndCust !== 0 ? getMaxidEndCust : getStyleList.EndCustomerID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'EndCustomerID')}


                                                                    ></Reactselect>
                                                                </span>
                                                            </div>

                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                onClick={() => setQuickMasterName('EndCustomer')} title="Add EndCustomer" disabled={getStyleList.BuyerID === 0 ? true : false}>
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="StoryID" >
                                                                    {StyleInfoQuick.Story.length > 0 && StyleInfoQuick.Story[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => getStyleList.BuyerID === 0 ? ShowRequired('Story') : setQuickMasterName('Story')} title="Add Story">

                                                                        </button>
                                                                    }
                                                                    Story {getStyleList.IsStoryMandatory === 1 && <span className="text-danger">*</span>}</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="StoryID"
                                                                        id={
                                                                            "StoryID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getStoryList}
                                                                        // value={getStyleList.StoryID}
                                                                        value={getStoryList.filter(function (option) {
                                                                            return option.value === (getMaxidStory !== 0 ? getMaxidStory : getStyleList.StoryID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'StoryID')}
                                                                        styles={submitted && getStyleList.StoryID === 0 && getStyleList.IsStoryMandatory === 1 ? styles : ''}
                                                                    // styles={submitted && getStyleList.IsStoryMandatory === 1 ? '1px solid red' : ''}
                                                                    // styles={
                                                                    //     {
                                                                    //         border: submitted && getStyleList.IsStoryMandatory === 1 ? '1px solid red' : ''
                                                                    //     }
                                                                    // }
                                                                    ></Reactselect>
                                                                </span>
                                                            </div>

                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                onClick={() => setQuickMasterName('Story')} title="Add Story" disabled={getStyleList.BuyerID === 0 ? true : false}>
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="ThemeID" >
                                                                    {StyleInfoQuick.Theme.length > 0 && StyleInfoQuick.Theme[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => getStyleList.BuyerID === 0 ? ShowRequired('Theme') : setQuickMasterName('Theme')} title="Add Theme">

                                                                        </button>
                                                                    }
                                                                    Theme {getStyleList.IsThemeMandatory === 1 && <span className="text-danger">*</span>}</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="ThemeID"
                                                                        id={
                                                                            "ThemeID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getThemeList}
                                                                        // value={getStyleList.ThemeID}
                                                                        value={getThemeList.filter(function (option) {
                                                                            return option.value === (getMaxidTheme !== 0 ? getMaxidTheme : getStyleList.ThemeID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'ThemeID')}
                                                                        styles={submitted && getStyleList.ThemeID === 0 && getStyleList.IsThemeMandatory === 1 ? styles : ''}
                                                                    ></Reactselect>

                                                                </span>
                                                            </div>

                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                onClick={() => setQuickMasterName('Theme')} title="Add Theme" disabled={getStyleList.BuyerID === 0 ? true : false}>
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            {/* <div className='form-group'> */}
                                                            <div className='form-group'>
                                                                <label htmlFor="CategoryID">
                                                                    {StyleInfoQuick.Category.length > 0 && StyleInfoQuick.Category[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => getStyleList.GroupTypeID === 0 ? ShowRequired('Category') : setQuickMasterName('Category')} title="Add Category" >

                                                                        </button>
                                                                    }
                                                                    Category Name<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="CategoryID"
                                                                        id={
                                                                            "CategoryID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getCategoryList}
                                                                        // value={getStyleList.CategoryID}
                                                                        value={getCategoryList.filter(function (option) {
                                                                            return option.value === (getMaxidCategory !== 0 ? getMaxidCategory : getStyleList.CategoryID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'CategoryID')}
                                                                        styles={submitted && getStyleList.CategoryID === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                            {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                onClick={() => setQuickMasterName('Category')} title="Add Category"
                                                                disabled={getStyleList.GroupTypeID === 0 ? true : false}>
                                                                <i className="fa fa-plus"></i>
                                                            </button> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="GenderID" >
                                                                    {StyleInfoQuick.Gender.length > 0 && StyleInfoQuick.Gender[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => setQuickMasterName('Gender')} title="Add Gender" >
                                                                        </button>
                                                                    }
                                                                    Gender<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="GenderID"
                                                                        id={
                                                                            "GenderID"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={getGenderList}
                                                                        // value={getStyleList.GenderID}
                                                                        value={getGenderList.filter(function (option) {
                                                                            return option.value === (getMaxidGender !== 0 ? getMaxidGender : getStyleList.GenderID);
                                                                        })}
                                                                        onChange={event => handleChange(event, 'GenderID')}
                                                                        styles={submitted && getStyleList.GenderID === 0 ? styles : ''}

                                                                    ></Reactselect>

                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="SizeID" >
                                                                    {StyleInfoQuick.Size.length > 0 && StyleInfoQuick.Size[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            onClick={() => handleGroupSizeChange(M === false && S === false ? false : true)}>

                                                                        </button>
                                                                    }
                                                                    Group Size<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="SizeID"
                                                                        id={
                                                                            "SizeID"
                                                                        }
                                                                        isDisabled={M === false && S === false ? false : true}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}

                                                                        onChange={event => handleChange(event, 'SizeID')}
                                                                        options={getSizeList}
                                                                        value={getStyleList.StyleName === '' && getStyleList.StyleNo === '' ? getStyleList.SizeID = 0 : getSizeList.filter(function (option) {
                                                                            return option.value === (getMaxidSize !== 0 ? getMaxidSize : getStyleList.SizeID);
                                                                        })}
                                                                        styles={submitted && getStyleList.SizeID === 0 ? styles : ''}

                                                                    ></Reactselect>

                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className='col-sm-4'>
                                                            <div className='form-group'>
                                                                {StyleInfoQuick.ProcessType.length > 0 && StyleInfoQuick.ProcessType[0].isQuick === 1 && Buttonview !== "View" &&
                                                                    <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                        onClick={() => setQuickMasterName('ProcessType')} title="Add Process Type">

                                                                    </button>
                                                                }
                                                                <label htmlFor="ProcessType">Process Type<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="ProcessTypeID"
                                                                        id={
                                                                            "ProcessTypeID"
                                                                        }
                                                                        // isDisabled={DisableSize === true ? true : false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}

                                                                        onChange={event => handleChange(event, 'ProcessTypeID')}
                                                                        options={getProcessTypeList}
                                                                        value={getProcessTypeList.filter(function (option) {
                                                                            return option.value === (getMaxidProcessType !== 0 ? getMaxidProcessType : getStyleList.ProcessTypeID);
                                                                        })}
                                                                        styles={submitted && getStyleList.ProcessTypeID === 0 ? styles : ''}

                                                                    ></Reactselect>

                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="IDPoNo">ID/PO No</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter ID/PO No"
                                                                        id="IDPoNo" name="IDPoNo"
                                                                        value={getStyleList.IDPoNo}
                                                                        onChange={event => handleChange(event, 'IDPoNo')}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control'}
                                                                    />

                                                                </span>
                                                            </div>
                                                        </div>




                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="Importance" >Importance</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="Importance"
                                                                        id={
                                                                            "Importance"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isMulti

                                                                        onChange={event => handleChange(event, 'Importance')}
                                                                        options={IMPORTANCE}
                                                                        value={getStyleList.arrImportance}
                                                                        // value={getSizeList.filter(function (option) {
                                                                        //     return option.value === getStyleList.Importance;
                                                                        // })}
                                                                        styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                    >  </Reactselect>

                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label htmlFor="Importance">Importance</label>
                                                    <span className='input-icon icon-right'>
                                                        <Select id="Importance" name="Importance"
                                                            value={getStyleList.Importance}
                                                            onChange={event => handleChange(event, 'Importance')}
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            ref={inputRef}
                                                            className={'form-control'}
                                                          >
                                                            <option value="0">- Select Importance -</option>
                                                            <option value="1">Priority</option>
                                                            <option value="2">Critical</option>

                                                        </Select>
                                                    </span>
                                                </div>
                                            </div> */}

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="BuyerEmployeeIdList">Buyer Employee<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="BuyerEmployeeIdList"
                                                                        id={
                                                                            "BuyerEmployeeIdList"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isMulti
                                                                        // value={getStyleList.BuyerEmployeeID}
                                                                        onChange={event => handleChange(event, 'BuyerEmployeeIdList')}
                                                                        options={getEmployeeList}
                                                                        value={getStyleList.arrBuyerEmployeeID}
                                                                        // value={getEmployeeList.filter(function (option) {
                                                                        //     return option.value === getStyleList.BuyerEmployeeID;
                                                                        // })}
                                                                        styles={submitted && getStyleList.arrBuyerEmployeeID.length === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="CubeEmployeeIdList">Cube Employee<span className="text-danger">*</span></label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="CubeEmployeeIdList"
                                                                        id={
                                                                            "CubeEmployeeIdList"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isMulti
                                                                        // value={getStyleList.BuyerEmployeeID}
                                                                        onChange={event => handleChange(event, 'CubeEmployeeIdList')}
                                                                        options={CubeList}
                                                                        value={getStyleList.arrCubeEmployeeID}
                                                                        // value={CubeList.filter(function (option) {
                                                                        //     return option.value === getStyleList.BuyerEmployeeID;
                                                                        // })}
                                                                        styles={submitted && getStyleList.arrCubeEmployeeID.length === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                            {/* <div className='form-group'>
                                                                <label htmlFor="EmployeeID">Cube Employee</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Cube Employee" id="CubeEmployeeName"
                                                                        name="CubeEmployeeName"
                                                                        value={getStyleList.CubeEmployeeName}
                                                                        onChange={event => handleChange(event, 'CubeEmployeeName')}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control'}
                                                                    />
                                                                </span>
                                                            </div> */}
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className='form-group'>
                                                                <label htmlFor="SupplierEmployeeIdList">Supplier Employee</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Reactselect className="basic-single" name="SupplierEmployeeIdList"
                                                                        id={
                                                                            "SupplierEmployeeIdList"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isMulti
                                                                        onChange={event => handleChange(event, 'SupplierEmployeeIdList')}
                                                                        options={SupList}
                                                                        value={getStyleList.arrSupplierEmployeeID}
                                                                    // value={SupList.filter(function (option) {
                                                                    //     return option.value === getStyleList.SupplierEmployeeID;
                                                                    // })}
                                                                    //styles={submitted && getStyleList.arrSupplierEmployeeID === 0 ? styles : ''}

                                                                    ></Reactselect>
                                                                </span>
                                                            </div>
                                                            {/* <div className='form-group'>
                                                                <label htmlFor="EmployeeID">Cube Employee</label>
                                                                <span className='input-icon icon-right'>
                                                                    <Input placeholder="Enter Cube Employee" id="CubeEmployeeName"
                                                                        name="CubeEmployeeName"
                                                                        value={getStyleList.CubeEmployeeName}
                                                                        onChange={event => handleChange(event, 'CubeEmployeeName')}
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        maxLength="50"
                                                                        ref={inputRef}
                                                                        className={'form-control'}
                                                                    />
                                                                </span>
                                                            </div> */}
                                                        </div>

                                                        {/*
                                            <div className='col-sm-4'>
                                                <div className='form-group'>
                                                    <label htmlFor="ReceivedDate">Received Date</label>
                                                    <span className="input-icon icon-right">
                                                        <DatePicker className="form-control"
                                                            name="ReceivedDate"
                                                            id={
                                                                "ReceivedDate"
                                                            }
                                                            selected={
                                                                getStyleList.ReceivedDate
                                                            }
                                                            value={
                                                                getStyleList.ReceivedDate
                                                            }
                                                            onChange={
                                                                event => handleChange(event, "ReceivedDate")
                                                            }
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="scroll"
                                                            autoComplete="off"
                                                            customInput={<CustomInput />}
                                                            isClearable={ClearDate}
                                                        />
                                                    </span>

                                                </div>
                                            </div>
                                        */}
                                                    </div>

                                                    <div className="row">

                                                        {/* <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label htmlFor="Remark">Remark</label>
                                                    <span className='input-icon icon-right'>
                                                        <textarea placeholder="Enter Remark"
                                                            id="Remark" name="Remark" //className="form-control"
                                                            value={getStyleList.Remark}
                                                            onChange={event => handleChange(event, 'Remark')}
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            ref={inputRef}
                                                            className={'form-control'}
                                                        />

                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className='form-group'>
                                                    <label htmlFor="Name" >Fabric Details<span className="text-danger">(Multi select option)*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Reactselect className="basic-single" name="StyleFabricDetails"
                                                            id={
                                                                "StyleFabricDetails"
                                                            }
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isMulti
                                                            options={getFabricList}
                                                            value={arrStyleFabricDetails}
                                                            onChange={event => handleInputChangeFabric(event)}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </div>
                                        */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 pull-right">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail2">Style Image <span className="text-danger">*</span></label>
                                                        <span className="text-danger">(Click on the image to upload new)</span><br />
                                                        <UploadImage UploadCallback={ImageCallback} PageName='Style' filePath={getStyleList.StyleLogoPath} buttonview='' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="5" style={{ textAlign: "center" }}><span className="">Fabric Information</span></th>
                                                            </tr>
                                                            <tr>

                                                                <th style={{ width: "120px" }}>#</th>
                                                                <th>
                                                                    {StyleInfoQuick.FabricType.length > 0 && StyleInfoQuick.FabricType[0].isQuick === 1 && Buttonview !== "View" &&
                                                                        <button type='button' className="glyphicon glyphicon-plus AddPlusIcon"
                                                                            style={{ backgroundColor: "#222d32" }}
                                                                            onClick={() => getStyleList.GroupTypeID === 0 ? ShowRequired('Fabric') : setQuickMasterName('Fabric')}
                                                                            title="Add Fabric">
                                                                        </button>
                                                                    }
                                                                    Fabric Details <span className="text-danger">*</span></th>
                                                                <th style={{
                                                                    display: activeMenu && activeMenu[0].isAdd === 0 && activeMenu[0].isDelete === 0 ? 'none' : '', width: "120px"
                                                                }}
                                                                >Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                FabricList.map((inputField, index) => (
                                                                    <Fragment key={
                                                                        `${inputField}~${index}`
                                                                    }>

                                                                        <tr>

                                                                            <td style={{ width: "120px" }}>
                                                                                <label className="form-control">Shell - {index + 1}</label>
                                                                            </td>
                                                                            <td>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect name="StyleFabricDetails"
                                                                                        id={
                                                                                            "StyleFabricDetails" + { index }
                                                                                        }
                                                                                        // name="Fabric"
                                                                                        //filterOption={false}
                                                                                        className="basic-multi-select"
                                                                                        classNamePrefix="select"
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        //value={getFabricList.find(obj => obj.value === inputField.FabricDetails)} // set selected value
                                                                                        options={getFabricList}
                                                                                        // value={getFabricList.filter(option => option.value === (getMaxidFabric !== 0 ? getMaxidFabric : inputField.ArrayFabricDetail))}
                                                                                        value={inputField.ArrayFabricDetail}
                                                                                        menuPosition="unset"
                                                                                        onChange={event => handleInputChangeFabric(event, index)}
                                                                                        styles={submitted && inputField.ArrayFabricDetail === '' ? styles : ''}

                                                                                    ></Reactselect>
                                                                                    {/* <button type='button' className="btn  btn-xs  btn-success qiuckMasterTop"
                                                                                        onClick={() => setQuickMasterName('Fabric')} title="Add Fabric"
                                                                                        disabled={getStyleList.GroupTypeID === 0 ? true : false}>
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button> */}
                                                                                </span>


                                                                            </td>


                                                                            <td style={{
                                                                                display: activeMenu && activeMenu[0].isAdd === 0 && activeMenu[0].isDelete === 0 ? 'none' : ''
                                                                            }}>
                                                                                {FabricList.length !== 1 && (
                                                                                    <button type='button' className="btn  btn-xs  btn-danger" title="Delete Fabric"
                                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                        onClick={
                                                                                            () => handleRemoveFabricFields(index)
                                                                                        }

                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </button>
                                                                                )}
                                                                                &nbsp;
                                                                                {FabricList.length === (index + 1) && (
                                                                                    <button type='button' className="btn  btn-xs  btn-success" title="Add Fabric"
                                                                                        style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                        onClick={
                                                                                            () => AddFabricFields(index)
                                                                                        }

                                                                                    >
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button>
                                                                                )}
                                                                            </td>

                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            &nbsp;
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="5" style={{ textAlign: "center" }}><span className="">Remarks Information</span></th>
                                                                </tr>
                                                                <tr>
                                                                    {/* <th>#</th> */}
                                                                    <th>Remarks <span className="text-danger">*</span></th>
                                                                    <th>Received Date <span className="text-danger">*</span></th>
                                                                    <th style={{ width: "11%" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    RemarksList.map((inputField, index) => (
                                                                        <Fragment key={
                                                                            `${inputField}~${index}`
                                                                        }>

                                                                            <tr>
                                                                                {/* <td>{index}</td> */}
                                                                                <td>
                                                                                    {/* <textarea type="text"
                                                                                        id={
                                                                                            "Remarks_" + index
                                                                                        }
                                                                                        name="Remarks"
                                                                                        placeholder="Enter Remarks Name"
                                                                                        maxLength="500"
                                                                                        value={
                                                                                            inputField.Remarks
                                                                                        }
                                                                                        onChange={event => handleChangeRemarks(index, event, 'Remarks')}
                                                                                        onBlur={event => handleBlurRemarks(index, event, 'Remarks')}
                                                                                        style={
                                                                                            {
                                                                                                border: submitted && inputField.Remarks === '' ? '1px solid red' : '', width: "100%", height: "65px"
                                                                                            }
                                                                                        }

                                                                                    /> */}
                                                                                    <span className="input-icon icon-right">
                                                                                        <textarea type="text"
                                                                                            id={
                                                                                                "Remarks_" + index
                                                                                            }
                                                                                            name="Remarks"
                                                                                            placeholder="Enter Remarks Name"
                                                                                            maxLength="500"
                                                                                            value={inputField.Remarks}
                                                                                            onChange={event => handleChangeRemarks(index, event, 'Remarks')}
                                                                                            style={
                                                                                                {
                                                                                                    border: submitted && inputField.Remarks === '' ? '1px solid red' : '', width: "100%", height: "65px"
                                                                                                }
                                                                                            } />
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="input-icon icon-right"
                                                                                        style={
                                                                                            {
                                                                                                border: submitted && inputField.ReceivedDate === null ? '1px solid red' : ''
                                                                                            }
                                                                                        }>

                                                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                                                            id={
                                                                                                "ReceivedDate" + index
                                                                                            }
                                                                                            selected={
                                                                                                inputField.ReceivedDate
                                                                                            }
                                                                                            onChange={
                                                                                                event => handleChangeRemarks(index, event, "ReceivedDate")
                                                                                            }
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            //showMonthDropdown
                                                                                            //showYearDropdown
                                                                                            dropdownMode="scroll"
                                                                                            autoComplete="off"
                                                                                            customInput={<CustomInput />}
                                                                                            isClearable={inputField.ReceivedDate === null ? false : true}
                                                                                        />

                                                                                    </span>

                                                                                </td>


                                                                                <td>
                                                                                    {RemarksList.length !== 1 && (
                                                                                        <button type='button' className="btn  btn-xs  btn-danger" title="Delete Remark"
                                                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                            onClick={
                                                                                                () => handleRemoveFields(index)
                                                                                            }

                                                                                        >
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </button>
                                                                                    )}
                                                                                    &nbsp;
                                                                                    {RemarksList.length === (index + 1) && (
                                                                                        <button type='button' className="btn  btn-xs  btn-success" title="Add Remark"
                                                                                            style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                            onClick={
                                                                                                () => AddRemarksFields(index)
                                                                                            }

                                                                                        >
                                                                                            <i className="fa fa-plus"></i>
                                                                                        </button>
                                                                                    )}
                                                                                    &nbsp;
                                                                                    <button type="button" className="btn btn-xs btn-blue" title="Attachments" onClick={() => handleAttachment(index)}
                                                                                    >
                                                                                        <b> {inputField.AttachmentCount} </b>
                                                                                        <i className="fa fa-upload"></i>
                                                                                    </button>

                                                                                    {

                                                                                        inputField.IsShowAttachment === 1 &&
                                                                                        <Modal show={inputField.IsShowAttachment === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleCloseAttachment(index)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title> <span className="widget-caption" style={{ fontSize: '14px' }}>File  Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span></Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-12 col-lg-12">

                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-8 col-lg-8">
                                                                                                                <div className='form-group'>
                                                                                                                    <tr>
                                                                                                                        <td> <input type="file" id="FileUpload" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none', fontSize: '14px' }}
                                                                                                                            onChange={(e) => AppendFiles(e)}
                                                                                                                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                                                                                    </tr>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className="col-lg-12 col-sm-12">
                                                                                                            <div className="row no-margin">
                                                                                                                {
                                                                                                                    getFiles.length !== 0 ?
                                                                                                                        getFiles.map((files, fileindex) => (
                                                                                                                            files.StyleRemarksFileIndexName.toString() === getTaskIndexFileupload.toString() &&
                                                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                <tr title={files.FileName}>
                                                                                                                                    <td className="cut_textInTNA">
                                                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                                                            {
                                                                                                                                                files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                            files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                            }
                                                                                                                                        </span>  {files.FileName}
                                                                                                                                    </td>
                                                                                                                                    <td className="button_style_uploda">




                                                                                                                                        {
                                                                                                                                            files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/StyleRemarksFileUpload/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                    style={{
                                                                                                                                                        display: activeMenu && activeMenu[0].isView === 0 ? 'none' : ''
                                                                                                                                                    }}
                                                                                                                                                    onClick={
                                                                                                                                                        event => ViewFile(event, files.FileName)
                                                                                                                                                    }></i>
                                                                                                                                        }
                                                                                                                                        &nbsp;

                                                                                                                                        <p style={{
                                                                                                                                            display: activeMenu && activeMenu[0].isDelete === 0 ? 'none' : ''
                                                                                                                                        }} title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                                                                            className="btn btn-danger btn-xs delete"
                                                                                                                                        >
                                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                                        </p>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                {


                                                                                                                                    <div> {
                                                                                                                                        isOpenimg && (

                                                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/StyleRemarksFileUpload/' + getImagename}
                                                                                                                                                alt="bg image"
                                                                                                                                                onCloseRequest={
                                                                                                                                                    () => setIsOpenimg(false)
                                                                                                                                                } />
                                                                                                                                        )
                                                                                                                                    } </div>
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        ))
                                                                                                                        : ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>



                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>

                                                                                                <Button variant="success" onClick={() => handleCloseAttachment(index)}> Save </Button>

                                                                                            </Modal.Footer>
                                                                                        </Modal>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </>

                                        </Form>
                                    </div>

                                    <Modal show={getQuickMasterName} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add {getQuickMasterName}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {
                                                getQuickMasterName !== '' ?
                                                    getQuickMasterName === 'Brand' || getQuickMasterName === 'End Customer' ||
                                                        getQuickMasterName === 'Story' || getQuickMasterName === 'Theme' ?
                                                        <BrandEndCustomerStoryTheme
                                                            getEndCustomerList={getEndCustomerList}
                                                            BrandCallback={brandCallback}
                                                            EndCustomerCallback={EndcustomerCallback}
                                                            StoryCallBack={StoryCallBack}
                                                            ThemeCallBack={ThemeCallBack}
                                                            showpopup={true}
                                                            BuyerID={getStyleList.BuyerID}
                                                            Brandlist={getBrandList}
                                                            Endcustomerlist={getEndCustomerList}
                                                            Storylist={getStoryList}
                                                            Themelist={getThemeList}
                                                            MappingName={getQuickMasterName}

                                                        /> :
                                                        getQuickMasterName === 'Season' ?
                                                            <SeasonPopup
                                                                seasonCallback={seasonCallback}
                                                                showpopup={true}
                                                            /> :
                                                            getQuickMasterName === 'GroupType' ?
                                                                <GroupTypePopup
                                                                    getGroupList={getGroupList}
                                                                    groupTypeCallback={groupTypeCallback}
                                                                    showpopup={true}
                                                                /> :
                                                                getQuickMasterName === 'Category' ?
                                                                    <CategoryPopup
                                                                        getCategoryList={getCategoryList}
                                                                        categoryCallback={categoryCallback}
                                                                        GroupTypeID={getStyleList.GroupTypeID}
                                                                        GroupTypeName={styleValue.GroupTypeName}
                                                                        showpopup={true}
                                                                    /> :
                                                                    getQuickMasterName === 'Fabric' ?
                                                                        <Fabric
                                                                            FabricList={getFabricList}
                                                                            FabricCallBack={FabricCallback}
                                                                            GroupTypeId={getStyleList.GroupTypeID}
                                                                            showpopup={true}
                                                                        /> :
                                                                        getQuickMasterName === 'Gender' ?
                                                                            <GenderPopup
                                                                                getGenderList={getGenderList}
                                                                                genderCallback={genderCallback}
                                                                                showpopup={true}
                                                                            />
                                                                            :
                                                                            getQuickMasterName === 'Size' ?
                                                                                <SizePopup
                                                                                    getSizeList={getSizeList}
                                                                                    LoadSizeList={getLoadSizeList}
                                                                                    sizeCallback={sizeCallback}
                                                                                    showpopup={true}
                                                                                /> :
                                                                                getQuickMasterName === 'ProcessType' ?
                                                                                    <ProcessTypePopup
                                                                                        getProcessTypeList={getProcessTypeList}
                                                                                        // LoadProcessTypeList={getProcessTypeList}
                                                                                        processTypeCallback={processTypeCallback}
                                                                                        showpopup={true}
                                                                                    /> :
                                                                                    null
                                                    : ''

                                            }

                                        </Modal.Body>

                                    </Modal>


                                </div>
                            </div>
                        </div>
                    </div >

                    :
                    <>
                        {((!getIsDataBound && getStyleList.Operation == 2) || (getIsDataBound && getStyleList.Operation == 1)) && <CommonLoader />}
                    </>
            }
        </>
    )
};
export default StyleRegister;

function LoadGroupBased(GroupIDDefault, setCategoryList, setFabricList) {
    StyleService.LoadCategory(GroupIDDefault)
        .then((response) => {
            if (response.data) {
                setCategoryList(response.data);
            }

        })
        .catch(() => { });
    StyleService.LoadFabricDetails(GroupIDDefault)
        .then((response) => {
            if (response.data) {
                setFabricList(response.data);
            }
        })
        .catch(() => { });
}



function LoadBuyerBased(BuyerIDDefault, setBrandList, setEndCustomerList, setStoryList, setThemeList, setEmployeeList, BuyerBasedBrandDetails) {
    StyleService.LoadBrandThemeStoryEndCustomer(5, BuyerIDDefault).then((response) => {
        if (response.data) {
            let BuyerInfoList = response.data;
            setBrandList(BuyerInfoList.filter(o => BuyerBasedBrandDetails.some(({ brandId }) =>
                o.value === brandId)));
            let respected_EndCustomer = BuyerInfoList.filter(x => x.buyerMapID === 2);
            setEndCustomerList(respected_EndCustomer);
            let respected_Story = BuyerInfoList.filter(x => x.buyerMapID === 3);
            setStoryList(respected_Story);
            let respected_Theory = BuyerInfoList.filter(x => x.buyerMapID === 4);
            setThemeList(respected_Theory);
        }
    }).catch(() => { });

    StyleService.LoadBuyerEmployee(BuyerIDDefault).then((response) => {
        if (response.data) {
            setEmployeeList(response.data);
        }
    }).catch(() => { });
}
