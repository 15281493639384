import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { GetGeneralTrimApprovalViewList, clearTrimApprovalViewSubRow, GetGeneralTrimSubmissionApprovalGridList } from "../../../../actions/generalTrim";
import { loadSupplierList } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { GenTrimApprovalId, GenManualCloseAppChildTrim } from "../../../Common/PageActionNumber";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import GeneralService from "../../../../services/General/GeneralService";
import input from "react-validation/build/input";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import SweetAlert from 'react-bootstrap-sweetalert';

const TrimViewApprovalTab = (props) => {

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const { user: currentUser } = useSelector((state) => state.auth);
    //reducer
    const TrimApprovalList = useSelector((state) => state.generalTrim.generalTrimApprovalViewList);
    //const TrimSubmissionParams = useSelector((state) => state.generalTrim.generalTrimViewDetailParams);
    let isLoadingGeneralTrim = useSelector((state) => state.generalTrim.isLoadingGeneralTrim);
    const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
    const genTrimTNAHolidayList = useSelector((state) => state.generalLabdip.genLapdibTNAHolidayList);

    // let activeMenu;
    // let activeMenuSub;
    // let TrimApprovalTab;
    // const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    // const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    // if (menulist !== undefined && menulistSub.length > 0) {
    //     activeMenu = menulist.filter(i => i.menuName === 'TrimList');
    //     if (activeMenu.length > 0) {
    //         activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

    //         if (activeMenuSub.length > 0) {

    //             for (let index = 0; index < activeMenuSub.length; index++) {
    //                 if (activeMenuSub[index].menuName === "GeneralTrimApproval") {
    //                     TrimApprovalTab = activeMenuSub[index];
    //                 }
    //             }
    //         }
    //     }
    // }
    // //state


    const getMenuName = (baseName) => {
        return IsProduction === 1
            ? `${baseName}`
            : IsProduction === 2
            ? `Production${baseName}`
            : undefined;
    };

      // If check production or sampling
     const activeMenus = useSelector((state) => state.auth.activeMenuList);
     let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
     let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal:activeMenus.IsProduction;
     // End 
    let info;
    let activeMenu;
    let activeMenuSub;
    let TrimApprovalTab;
   

    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        //activeMenu = menulist.filter(i => i.menuName === 'TrimList');
        info = IsProduction === 1 ? 'TrimList' : 'ProductionOrderTrimList';
        activeMenu = menulist.filter(i => i.menuName ===  info);
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {

                    const menuItem = activeMenuSub[index];
                    const menuName = menuItem.menuName;
                 if (menuName === getMenuName("GeneralTrimApproval")) {
                    TrimApprovalTab = menuItem;
                    }
                 }
            }
        }
    }


    const [getID, setID] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [currentExpand, setcurrentExpand] = useState({ currindex: "", currTrimId: "", currColorGroupID: "" });
    const [getComment, setComment] = useState(false);
    const [selectall, setselectall] = useState(false);
    const [getsID, setsID] = useState(0);
    const [showManual, setshowManual] = useState(false);
    const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
    const [showApproval, setshowApproval] = useState(false);
    const [ManualInfo, setManualInfo] = useState([{ IdList: '', manualCloseDate: '', manualCloseComment: '', }]);
    const [getSearch, setSearch] = useState("");
    const [showMultiClosediv, setshowMultiClosediv] = useState();
    const [showApprovaldiv, setshowApprovaldiv] = useState();
    const [getOrderinfo, setOrderinfo] = useState([]);
    const [tab, setTab] = useState(0);
    const [inputFields, setInputFields] = useState([{
        BuyerBrandSeasonName: '',
        StoryName: '',
        ThemeName: '',
        Supplier: '',
        SubmitDate: '',
        SendTo: "0",
        SendThrough: 0,
        EndCustomer: "",
        AWBName: "", AWBDate: "", AWBDateandNo: "",
        TrimId: 0, TrimCode: 0, TrimName: "", TechnicalSpecification: "",
        TrimDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
        GenralTrimTNASubmissionAndApprovalId: 0,
        ApprovalDate: '', ApprovalRemark: '',
        GeneralTNATrimSubmittedOption: []
    }]);
    const [submittedpopup, setSubmittedpopup] = useState(false);
    const values = [...inputFields]
    const [showawb, setshowawb] = useState(false);
    const [getTaskholder, setTaskholder] = useState([]);
    const [ShowPopup, setShowPopup] = useState(false);
    const [FabricDetails, setFabricDetails] = useState([]);
    const [ApprovalList, setApprovalList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getApprovedOptions, setApprovedOptions] = useState([]);
    const [getheader, setheader] = useState();
    const [submittedoption, setsubmittedoption] = useState([]);
    const [getSearchReqValues, setSearchReqValues] = useState({
        StatusId: 2,
        SupplierId: 0,
        SearchTxt: "",
        stateDate: [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }
        ]

    });
    const [TrimDimension, setTrimDimension] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getSubImageList, setSubImageList] = useState([]);
    const [OrderInfoMainDetails, setOrderInfoMainDetails] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandList, setexpandList] = useState({});
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [deleteOrderinfo, setdeleteOrderinfo] = useState([]);
    const [deletepopup, setdeletepopup] = useState(false);
    const [submittedoptionAll, setsubmittedoptionAll] = useState([]);

    const pageRedirect = (SubmissionParams) => {

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let searchParams = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate
        };


        setID({ showPopupDelete: true, Params: SubmissionParams, SearchParams: searchParams });

    };


    const SearchReqValues = { ...getSearchReqValues };

    const [View, setView] = useState(false);
    const headers = [
        { name: "Story", field: "Story", sortable: false },
        { name: "Theme", field: "Theme", sortable: false },
        { name: "Details", field: "Details", sortable: false },
        { name: "Task Holder / Follower", field: "", sortable: false },

    ];

    const [Searchvalues, setSearchvalues] = useState({
        SupplierId: 0, approvalStatus: 0
    });

    const [TaskDetails, setTaskDetails] = useState([]);
    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState({ SubmissionComment: '', ApprovalComment: '' });
    const [SubmitReschedule, setSubmitReschedule] = useState(false);
    const [openReschedulepopup, setopenReschedulepopup] = useState(false);


    let BuyerId = 0;
    let BrandId = 0;
    let SeasonId = 0;
    //get props params
    if (props.props.location.state !== undefined) {

        BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
        BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
        SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

    }

    const ApprovedOption = [
        { key: '1', value: 'Approved' },
        { key: '2', value: 'Approved with Comments' },
        { key: '3', value: 'Rejected' },
        { key: '4', value: 'Hold' }
    ]

    let styles1 = {
        control: styles1 => ({ ...styles1 }),
    }

    let styles2 = {
        control: styles2 => ({ ...styles2, border: '1px solid red' }),

    }
    var ImgClassName = "upload-btn-wrapper2"

    useEffect(() => {
        if (!isLoadingGeneralTrim) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingGeneralTrim])

    useEffect(() => {

        dispatch(loadSupplierList());
        searchTrim();

    }, [])
    const TabChange = (val) => {
        setTab(val);

    }

    //#region  ------------------  search details --------------------------------
    const searchTrim = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: currentExpand.currindex,
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralTrimApprovalViewList(params));
        commonParentExpand();
    }

    const ReloadreqTab = () => {
        SearchReqValues.stateDate[0].isShow = false;
        setSearchReqValues(SearchReqValues);

        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }

        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0,
            Index: currentExpand.currindex, SearchText: SearchReqValues.SearchTxt, Operation: 1,
            StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };
        dispatch(GetGeneralTrimSubmissionApprovalGridList(params));
    }
    const resetTrim = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        SearchReqValues.StatusId = 2;
        SearchReqValues.SupplierId = 0;
        SearchReqValues.SearchTxt = "";
        setSearchReqValues(SearchReqValues);
        searchTrim();

    }


    const clearDateSelect = () => {
        SearchReqValues.stateDate[0].startDate = null;
        SearchReqValues.stateDate[0].endDate = new Date("");
        SearchReqValues.stateDate[0].key = 'selection';
        SearchReqValues.stateDate[0].isShow = false;

        setSearchReqValues(SearchReqValues);
    }
    const handleDateSelect = (event, isShow) => {

        SearchReqValues.stateDate[0].isShow = isShow;
        setSearchReqValues(SearchReqValues);
    }

    const handlePopupDateselection = (item) => {

        SearchReqValues.stateDate = [item.selection];
        setSearchReqValues(SearchReqValues);
    }

    const handleSearchChange = (e, FieldName) => {

        let inputText = '';
        if (FieldName === 'SearchTxt') {
            if (e && e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
        } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
            if (e) {
                inputText = e.value
            }
        }

        if (FieldName === 'Status') {
            SearchReqValues.StatusId = 2;
        }
        else if (FieldName === 'SupplierId') {
            SearchReqValues.SupplierId = inputText;
        } else if (FieldName === 'SearchTxt') {
            SearchReqValues.SearchTxt = inputText;
        }
        setSearchReqValues(SearchReqValues);

    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //#end  ------------------  search details end --------------------------------





    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index, TrimId, ColorGroupID) => {
        let btnToggle = document.getElementById("btn-toggle3_" + index);
        let iconToggle = document.getElementById("icon-toggle3_" + index);

        let ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setcurrentExpand({ currindex: index, currTrimId: TrimId, currColorGroupID: ColorGroupID })
            let StartDate = null;
            let EndDate = null;
            if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
                StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
            }
            if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
                EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
            }

            let params = {
                BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: TrimId, ColorGroupID: ColorGroupID, Index: index,
                SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
                IsProduction: IsProduction
            };

            dispatch(GetGeneralTrimApprovalViewList(params));

            // GeneralService.GetGeneralTrimSubmissionApprovalList(params).then((response) => {
            //     if (response.data) {
            //         setApprovalList(response.data.pantoneCodeList);
            //         let SubList = [...ApprovalList];
            //         SubList[index].pantoneSubList = response.data.generalTrimPantoneSubInfo ? response.data.generalTrimPantoneSubInfo : [];
            //         SubList[index].criticalList = response.data.criticalList ? response.data.criticalList : [];
            //         setApprovalList(SubList);
            //     }
            // }).catch(() => { });
        }
        else {

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            if (TrimId) {
                dispatch(clearTrimApprovalViewSubRow(index));
            }
        }
    };


    const commonParentExpand = () => {
        let rowHiddens = document.querySelectorAll(".parentExpand");
        for (let row of rowHiddens) {
            row.setAttribute('aria-expanded', 'false');
        }
        let rowIconHiddens = document.querySelectorAll(".clscollapseexpandTNA");
        for (let rowIcon of rowIconHiddens) {
            rowIcon.className = rowIcon.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
    }

    const Delcallback = (value) => {

        searchTrim();
        let btnToggle = document.getElementById("btn-toggle_" + currentExpand.currindex);
        let iconToggle = document.getElementById("icon-toggle_" + currentExpand.currindex);
        btnToggle.setAttribute('aria-expanded', 'false');
        iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");

        setID({ showPopupDelete: false, Params: [] });
    }

    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
            isShow: false
        }
    ]);



    // const previmage = () => {
    //     if (getCurrimageindex === 0) {
    //         setCurrimageindex(0);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex - 1);
    //     }
    // }

    // const nextimage = () => {
    //     if (getCurrimageindex === getSubImageList.length - 1) {
    //         setCurrimageindex(getSubImageList.length - 1);
    //     }
    //     else {
    //         setCurrimageindex(getCurrimageindex + 1);
    //     }
    // }
    const handleCloseManualPopup = () => {
        setshowManual(false);
    }
    const DeleteCallback = (value) => {
        if (value === true) {
            setsID({ showPopupDelete: false, Params: [] });
            searchTrim();
        } else {
            setsID({ showPopupDelete: false, Params: [] })
            setshowManual(true);
        }
    }
    const handledelete = () => {
       ;
        let Value = [];
        //  Value.TNAId = props.location.state.params.tnaid;
        Value.Id = ChildId.ChildId;
        setshowManual(true);
        setsID({ TNAManualClose: GenManualCloseAppChildTrim, showPopupDelete: true, Params: Value });
    }

    const handleManualclose = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId, trimId, GenTrimLogoID, GenTrimId, GenTrimRefernceId, isManualSubmission) => {
        showLoader();
       
        setChildId({ ChildId: Id, Status: isManualSubmission });
        let Operation;
        if (approvalStatus === "Pending") {
            Operation = 2;
            setheader('Manual Close');
        }
        else {
            Operation = 4;
            setheader('View Manual Close');

        }
        GeneralService.GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceId).then((response) => {
                if (response.data) {
                    let data = response.data.trimSubmitApprovalReferenceList.map((item) => {
                        if (item.genTrimTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;
                            return item;
                        } else {
                            item.isSelected = 0;
                            return item;
                        }
                    });
                    setManualInfo(data);
                    setMultiCloseDatas(data);
                    hideLoader();
                    setshowManual(true);
                } else {
                    hideLoader();
                    setshowManual(true);
                }
            }).catch(() => { hideLoader(); setshowManual(true); });
    }




    const OpenTrimApprovalpopup = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        trimId, GenTrimLogoID, GenTrimId, GenTrimMainId, index, GenTrimRefernceID, Type) => {
        setTab(0);
        showLoader();
        let Optionvalue = [];
        let Operation = 4;

        values[0].ApprovalDate = '';
        values[0].ApprovalRemark = '';
        values[0].ApprovalDateEdit = '';

        if (Type === 'View') { setView(true) }
        else { setView(false) }
        setheader('View Manual Close');
        ApprovedOption.forEach(element => {
            let LabelColor = '';
            if (element.value === 'Approved') {
                LabelColor = <><span style={{ color: "#00b050" }}><b>Approved</b></span></>
            }
            else if (element.value === 'Approved with Comments') {
                LabelColor = <><span style={{ color: "#fca404" }}><b>Approved with Comments</b></span></>
            }
            else if (element.value === 'Rejected') {
                LabelColor = <><span style={{ color: "#c40404" }}><b>Rejected</b></span></>
            }
            else if (element.value === 'Hold') {
                LabelColor = <><span style={{ color: "#073763" }}><b>Hold</b></span></>
            }


            Optionvalue.push({
                value: element.key,
                label: LabelColor,
                subLabel: element.value,
            })
        });

        setApprovedOptions(Optionvalue);
        GeneralService.GetGeneralTrimDimensionList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTrimDimension(response.data);
            }
        }).catch(() => { });
        GeneralService.GetGeneralTrimTaskHolderList(GenTrimMainId).then((response) => {
            if (response.data) {
                setTaskholder(response.data);
            }
        }).catch(() => { });


        var Sub = TrimApprovalList[index].trimSubmitApprovalReferenceList.filter(x => x.genTrimTNASubmissionAndApprovalId === Id);
        Sub.forEach(element => {
            if (element.genTrimTNASubmissionAndApprovalId === Id) {
                element.isSelected = 1;
            }
        })

        values[0].SupplierName = Sub[0].supplierName;
        values[0].BuyerBrandSeasonName = Sub[0].buyerBrandSeasonName;
        values[0].StoryName = Sub[0].storyName;
        values[0].ThemeName = Sub[0].themeName;
        values[0].EndCustomer = Sub[0].endCustomerNameIdList;
        values[0].TrimCode = Sub[0].trimCode;
        values[0].TrimId = Sub[0].trimId;
        values[0].TrimName = Sub[0].trimName;
        values[0].TechnicalSpecification = Sub[0].technicalSpecification;
        values[0].TrimDimensionId = Sub[0].trimRefernceId;
        values[0].GenralTrimTNASubmissionAndApprovalId = Sub[0].genTrimTNASubmissionAndApprovalId;
        values[0].ApprovalStatus = Sub[0].approvalStatus;
        values[0].SubmitStatus = Sub[0].submitStatus;
        values[0].Remarks = Sub[0].remarks;

        let getDate = new Date(Sub[0].awbDate);
        let ddd = getDate.getDate();
        let mmm = getDate.getMonth() + 1;
        let yyyyy = getDate.getFullYear();

        if (ddd < 10) {
            ddd = '0' + ddd;
        }
        if (mmm < 10) {
            mmm = '0' + mmm;
        }
        let covertedd = ddd + '/' + mmm + '/' + yyyyy;



        let awbdate = Sub[0].awbDate === null ? '' : covertedd;
        values[0].SubmitSentDetails = Sub[0].sendThroughName + ' ' + Sub[0].awbName + ' ' + awbdate;
        values[0].SendTo = Sub[0].sendTo;
        values[0].SubmittedDate = new Date(Sub[0].submitDate)
        values[0].SwatchCardImageName = Sub[0].swatchCardImageName;
        values[0].SwatchCardIamgePath = Sub[0].swatchCardImagePath;


        let images = [];
        images = Sub[0].trimImageList.split(',');
        setSubImageList(images);
        setCurrimageindex(0);

        let getDateValue = new Date(Sub[0].approvalDate);
        var dd = getDateValue.getDate();
        var mm = getDateValue.getMonth() + 1;
        var yyyy = getDateValue.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let coverted = yyyy + '-' + mm + '-' + dd;
        values[0].ApprovalDate = new Date(Sub[0].approvalDate);
        values[0].ApprovalRemark = Sub[0].approvalRemark;
        values[0].ApprovalDateEdit = getDateValue;

        GeneralService.GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, trimId, GenTrimLogoID, GenTrimId, Operation, GenTrimRefernceID).then((response) => {
                if (response.data.trimSubmitApprovalReferenceList) {
                    let data = response.data.trimSubmitApprovalReferenceList.map((item) => {

                        if (item.genTrimTNASubmissionAndApprovalId === Id) {
                            item.isSelected = 1;

                            return item;
                        } else {
                            return item;
                        }
                    });

                    let index = 0;
                    data.forEach(element => {
                        index = index + 1;
                        element.index = index;
                    })

                    let maindata = [...new Map(data.map(item =>
                        [item['trimLogoId'], item])).values()];

                    setOrderInfoMainDetails(maindata);
                    setOrderinfo(data);
                    handleExpandRow(data[0].trimLogoPath, data);
                    setsubmittedoption([]);
                    setsubmittedoptionAll([]);
                    setsubmittedoption(response.data.generalTNATrimSubmittedOption.filter(x => x.genralTrimTNASubmissionAndApprovalId === values[0].GenralTrimTNASubmissionAndApprovalId));
                    setsubmittedoptionAll(response.data.generalTNATrimSubmittedOption)
                    setshowApproval(true);
                    hideLoader();
                } else {
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });



    }

    const handleExpandRow = (trimLogoPath, list) => {
        setExpandedRows([]);
        setexpandList(list.filter(x => x.trimLogoPath === trimLogoPath));
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(trimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[trimLogoPath] = false) : (obj[trimLogoPath] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== trimLogoPath) :
            currentExpandedRows.concat(trimLogoPath);
        setExpandedRows(newExpandedRows);


    }
    const ShowHideDivs = () => {
        setshowMultiClosediv(true);
        setshowApprovaldiv(false);
    }
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }
    // const searchText = () => {
    //    ;
    //     let params = {
    //         BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, PantoneId: 0, Index: currentExpand.currindex,
    //         SearchText: (getSearch && getSearch.trim()), Operation: 3
    //     };

    //     dispatch(GetGeneralTrimApprovalViewList(params));
    // }


    const handleChange = (event, feild) => {
        let input = '';
       ;
        if (feild === "ApprovalDate") {
            if (event !== null) {
                if (new Date(event) < new Date(values[0].SubmittedDate)) {
                    Nodify("Warning!", "warning", "Approval Date shouls not lesser that Submit Date.");
                    values[0].ApprovalDateEdit = '';
                    values[0].ApprovalDate = '';
                }
                else {
                    values[0].ApprovalDateEdit = event;
                    values[0].ApprovalDate = event;
                }
            }
            else {
                values[0].ApprovalDateEdit = '';
                values[0].ApprovalDate = '';
            }

        }
        else if (feild === "Remarks") {
            if (event.target.value !== '') {
                input = event.target.value;
            }
            values[0].ApprovalRemark = input;
        }

        setInputFields(values);
    }

    const handleSubmitoptionchange = (event, feild, index) => {
        var SOValoes = [...submittedoption];
        let input = '';
        if (event !== null) {

            input = event.value;
            if (feild === "OptionName") {
                submittedoption[index].OptionName = event.target.value;
            }
            else if (feild === "SupplierReferenceNo") {
                submittedoption[index].SupplierReferenceNo = event.target.value;
            }
            if (feild === "ApprovedOption") {
                SOValoes[index].approvedOptionName = input;
                if (input === "3") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "3";
                    })
                }
                else if (input === "4") {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "4";
                    })
                }
                else {
                    SOValoes.forEach(element => {
                        element.approvedOptionName = "";
                    })
                    SOValoes[index].approvedOptionName = input;
                }

            }
        }
        else {
            SOValoes.forEach(element => {
                element.approvedOptionName = "";
            })
            SOValoes[index].approvedOptionName = '';
        }


        setsubmittedoption(SOValoes);
    }


    const SaveApproval = () => {

        let IsValid = true;
        if (values[0].ApprovalDate === '' || values[0].ApprovalDate === null) {
            IsValid = false;

        }
        var approvecount = 0;
        submittedoption.forEach(element => {
            if (element.approvedOptionName !== null && element.approvedOptionName !== null &&
                element.approvedOptionName !== '') {
                approvecount = approvecount + 1;
            }
        })
        if (IsValid === false) {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
        else if (approvecount === 0) {
            Nodify("Warning!", "warning", "Please fill atleast one Approved Option.");
            setSubmitted(true);
        }
        else {
           ;
            values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
            values[0].ApprovalDate = new Date(values[0].ApprovalDate.setMinutes(values[0].ApprovalDate.getMinutes() + 370))
            values[0].Operation = 2;
            values[0].GeneralTNATrimOrderInfo = getOrderinfo;
            values[0].GeneralTNATrimSubmittedOption = [];

            submittedoption.map(opt => {
                submittedoptionAll.map(optall => {
                    if (optall.optionName === opt.optionName) {
                        optall.approvedOptionName = opt.approvedOptionName
                    }
                })

            })


            submittedoptionAll.map(opt => {
                values[0].GeneralTNATrimSubmittedOption.push({
                    GeneralTrimSubmittedOptionId: opt.generalTrimSubmittedOptionId,
                    GenralTrimTNASubmissionAndApprovalId: opt.genralTrimTNASubmissionAndApprovalId,
                    GenTrimId: opt.genTrimId,
                    GenTrimLogoId: opt.genTrimLogoId,
                    GenTrimRefernceId: opt.genTrimRefernceId,
                    OptionName: opt.optionName,
                    SupplierReferenceNo: opt.supplierReferenceNo,
                    ApprovedOptionName: opt.approvedOptionName
                })
            })

            GeneralService.InsertUpdateGeneralTrimSubmissionApproval(values[0]).then(res => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');

                    setshowApproval(false);
                    // searchTrim();
                    //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchTrim();
                } else if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Approval Updated Successfully');
                    // searchTrim();
                    setshowApproval(false);
                    //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
                    ReloadApproval();
                    searchTrim();
                }
                else if (res.data.outputResult === "0") {
                    Nodify("Error!", "Error Occured!");
                }
            });
        }

    }

    const OpenDeletPopup = (Id, approvalStatus, BuyerId, BrandId, SeasonId, SupplierId, Follower, TaskId,
        TrimId, GenTrimLogoID, GenTrimId, GenTrimMainId, index, GenTrimRefernceID, Type) => {


        GeneralService.GetGeneralTrimOrderInfoList(Id, BuyerId, BrandId, SeasonId, SupplierId, Follower,
            TaskId, TrimId, GenTrimLogoID, GenTrimId, 4, GenTrimRefernceID).then((response) => {
                if (response.data.trimSubmitApprovalReferenceList) {
                    setdeleteOrderinfo(response.data.trimSubmitApprovalReferenceList);
                    setdeletepopup(true);
                }
            }).catch(() => { });

    }

    const DeleteMultipleApproval = () => {
        var data = { Operation: 2, GeneralTNATrimOrderInfo: deleteOrderinfo }

        GeneralService.DeleteMultipleGenTrimApproval(data).then(res => {
            if (res.data.outputResult === "1") {
                Nodify('Success!', 'success', 'Trim Approval Deleted Successfully.');
                setdeleteOrderinfo([]);
                // searchTrim();
                setdeletepopup(false);
                //handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
                ReloadApproval();
                searchTrim();
            }
            else if (res.data.outputResult === "0") {
                Nodify("Error!", "Error Occured!");
            }
        });
    }
    const ReloadApproval = () => {
        let StartDate = null;
        let EndDate = null;
        if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
            StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
        }
        if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
            EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
        }
        let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId, TrimId: 0, ColorGroupID: 0, Index: "",
            // SearchText: (getSearch && getSearch.trim()), Operation: 3
            SearchText: SearchReqValues.SearchTxt, Operation: 3, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
            SupplierId: SearchReqValues.SupplierId, StartDate: StartDate, EndDate: EndDate,
            IsProduction: IsProduction
        };



        dispatch(GetGeneralTrimApprovalViewList(params));
    }
    const CustomInputApproval = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{
                    color:
                        props.value === ""
                            ? "#bfdea1"
                            : "#000",
                    border:
                        submitted &&
                            props.value === ""
                            ? "1px solid red"
                            : "",
                }}
            />
        )
    }

    const EndDateCustomInput = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            //style={{ border: submitdate && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const OpenReschedulePopup = (genTrimSupplierTNAId, genralLabdipTNASubmissionAndApprovalId,
        genTrimDetailTNAId, colorGroupID) => {

        GeneralService.getTNATaskDetailsList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
            if (response.data) {
                setTaskDetails(response.data);

                GeneralService.getTNATaskRescheduleList_Trim(genTrimSupplierTNAId, genTrimDetailTNAId, colorGroupID).then((response) => {
                    if (response.data) {
                        setSubRescheduleList(response.data);
                    }
                }).catch(() => { });

                setopenReschedulepopup(true);
            }
        }).catch(() => { });

        var cmtval = { ...RescheduleComment }
        cmtval.SubmissionComment = '';
        cmtval.ApprovalComment = '';
        setRescheduleComment(cmtval);
    }

    const handleChangeReschedule = (e, index, feild) => {
        var taskval = [...TaskDetails];
        var cmtval = { ...RescheduleComment }


        if (feild === "Duration") {
            taskval[index].duration = e.target.value;
        }
        else if (feild === "EndDate") {
            taskval[index].endDate = e;
        }
        else if (feild === "SubmissionComment") {
            cmtval.SubmissionComment = e.target.value;
        }
        if (feild === "ApprovalComment") {
            cmtval.ApprovalComment = e.target.value;
        }
        setTaskDetails(taskval);
        setRescheduleComment(cmtval);
        if (feild === "Duration" || feild === "EndDate") {
            datecalculation(index, feild)
        }
    }
    const handleSaveReschedule = () => {
        //if ((RescheduleComment.SubmissionComment === '' || RescheduleComment.ApprovalComment === '')) {
            if (RescheduleComment.ApprovalComment === ''){
            setSubmitReschedule(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
           
            var InsertData = [];

            TaskDetails.map((item, i) => {
                InsertData.push({
                    GenTrimDetailTNAId: item.genTrimDetailTNAId,
                    GenTrimTNASubmissionAndApprovalId: item.genTrimTNASubmissionAndApprovalId,
                    GenTrimSupplierTNAId: item.genTrimSupplierTNAId,
                    Comments: i === 0 ? RescheduleComment.SubmissionComment : RescheduleComment.ApprovalComment,
                    Createdby: currentUser.employeeinformationID,
                    StartDate: item.startDate,
                    EndDate: item.endDate,
                    Duration: parseInt(item.duration),
                    Type: i === 0 ? "Submission" : "Approval",
                    ColorGroupID: item.colorGroupID
                })
            })

            let savecount = 0;
            InsertData.map(data => {
                GeneralService.InsertGenTNATaskReschedule_Trim(data).then((res) => {
                    if (res.data.outputResult === "1") {
                        savecount = savecount + 1;
                    }
                })
            })
            Nodify('Success!', 'success', "Reschedule Added Successfully");
            setopenReschedulepopup(false);
            ReloadApproval();
            searchTrim();
            ReloadreqTab();
        }
    }


    function datecalculation(index, FieldName) {
       
        var taskval = [...TaskDetails];
        let employee1 = "";
        let employee2 = "";
        let employee3 = "";
        if (taskval[index].taskHolderOrFollowerName) {
            let TaskHolderName = taskval[index].taskHolderOrFollowerName.split('-');
            if (TaskHolderName[0] !== undefined) {
                employee1 = TaskHolderName[0].trim();

                if (TaskHolderName[1] !== undefined) {
                    let FollowerName = TaskHolderName[1].split(',');
                    if (FollowerName[0] !== undefined) {
                        employee2 = FollowerName[0].trim();

                        if (FollowerName[1] !== undefined) {
                            employee3 = FollowerName[1].trim();
                        } else {
                            employee3 = "";
                        }
                    } else {
                        employee2 = "";
                        employee3 = "";
                    }

                } else {
                    employee2 = "";
                    employee3 = "";
                }
            }
        }

        let Duration = taskval[index].duration;
        let StartDate = new Date(taskval[index].startDate);
        let EndDate = new Date(taskval[index].endDate);
        let LeaveDays = taskval[index].leaveDays;
        let titleEmployee1 = employee1;
        let total = 0;
        if (FieldName === "Duration") {
            EndDate = addDays(StartDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }
        }
        else {
            //based on end date
            let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            if (!isSameDay(new Date(EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
                EndDate = CheckDate;
            }
            StartDate = subDays(EndDate, parseInt(Duration) - 1);
            total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                StartDate = subDays(StartDate, 1);
                StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

            }
        }

        taskval[index].startDate = StartDate;
        taskval[index].endDate = EndDate;
        setTaskDetails(taskval);
    }



    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName) {
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = genTrimTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);

                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        return total;
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
       
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = genTrimTNAHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    return (
        <Fragment>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                    <div className="col-sm-12">

                            
<div className="col-md-2">
    <div className='form-group'>
        <label>Trim Code / Name </label>
        <span className='input-icon icon-right'>
            <input type="text" value={SearchReqValues.SearchTxt} onChange={event => handleSearchChange(event, "SearchTxt")} placeholder="Trim Code / Name" className="form-control" />

        </span>

    </div>

</div>
<div className="col-md-2">
    <div className="form-group">
        <label htmlFor="SupplierID">
            Supplier
            {/* <span className="text-danger">*</span> */}
        </label>
        <span className="input-icon icon-right">
            <Reactselect className="basic-single" name="SupplierId"
                id={
                    "SupplierId"
                }
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                onChange={event => handleSearchChange(event, 'SupplierId')}
                value={getSupplierList.filter(function (option) {
                    return option.value === SearchReqValues.SupplierId;

                })}
                options={getSupplierList}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
            ></Reactselect>

        </span>
    </div>
</div>
<div className="col-md-2">

    <div className="form-group">
        <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box will cause the date picker to appear and disappear">(Clicking on the text box...)</span> </label>
        <span className="input-icon icon-right">
            <input
                value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                //onBlur={event => handleDateBlur()}
                type="text"
                className={'form-control'}
                placeholder="Select Date"
            />
            <i className="fa fa-times" onClick={clearDateSelect} ></i>
        </span>
    </div>


    {SearchReqValues.stateDate[0].isShow &&
        <DateRangePicker
            //onChange={item => setStateDate([item.selection])}
            onChange={item => handlePopupDateselection(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={SearchReqValues.stateDate}
            showDateDisplay={false}
            direction="vertical"
            className={'TNAReportDatePicker'}

        />

    }

</div>



<div className="col-md-2" style={{ marginTop: '30px' }}>

    <div className="form-group">
        <button type="button" className="btn btn-success" title="Search" onClick={event => searchTrim()}
        >
            <i className="fa fa-search"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" className="btn btn-danger" title="Reset" onClick={resetTrim}
        >
            <i className="fa fa-close"></i>
        </button>
    </div>
</div>

</div>

                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                <thead>
                                    <tr>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> </th>
                                        <th className="fixed-column-header-first-tna" style={{ width: "35px" }}> #</th>
                                        <th>Approved Date</th>
                                        <th>Story</th>
                                        <th>Theme</th>
                                        <th>Supplier</th>
                                        <th>Details</th>
                                        {/* <th>Task Holder / Follower</th> */}
                                        <th>Approval Target</th>
                                        <th>Approval Status</th>
                                        <th>Approval Info</th>
                                        <th>Submission AWB #</th>
                                        <th className="text-center fixed-column-header-Route" style={{ width: "200px", paddingBottom: "2px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        TrimApprovalList && TrimApprovalList.length > 0 ?
                                            <>
                                                {
                                                    TrimApprovalList && TrimApprovalList.map((inputField, index) => (
                                                        <Fragment key={`${inputField}~${index}`}>
                                                            <tr class="parent" data-child={"parent_" + index}>
                                                                <td className="fixed-column-first-tna">

                                                                    {
                                                                        <span id={"btn-toggle3_" + index} class="btn-toggle3 parentExpand" aria-expanded="false" onClick={() => handleToggle(index, inputField.trimId, inputField.colorGroupID)} aria-labelledby="btn-toggle3 id-master">
                                                                            <i id={"icon-toggle3_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                        </span>
                                                                    }

                                                                </td>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <span className='input-icon icon-right'>
                                                                        {
                                                                            inputField.trim
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="borderRightLeftNone" colSpan="8">

                                                                    <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                        <img className="width_manual"
                                                                            src={inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath}
                                                                            alt="Logo"
                                                                            onClick={
                                                                                () => setIsOpen(inputField.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + inputField.imagePath)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td className="fixed-column-Route btn-action-center"></td>
                                                            </tr>
                                                            {
                                                                inputField.trimSubmitApprovalReferenceList && inputField.trimSubmitApprovalReferenceList.map((subField, subIndex) => (

                                                                    <Fragment key={`${subIndex}~${subIndex}`}>
                                                                        <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                            <td></td>
                                                                            <td className="fixed-column-first-tna" style={{paddingLeft:"16px"}}>
                                                                                {subIndex + 1}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.storyName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.themeName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.supplierName} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.details} </span>
                                                                                }
                                                                            </td>
                                                                            {/* <td className="cut_text" title={subField.followerName}>
                                                                                {
                                                                                    <span> {subField.followerName} </span>
                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {
                                                                                    <span> {moment(subField.approvalTarget).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalStatus} </span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    <span> {subField.approvalInfo} </span>
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    <span> {subField.awbName} - {subField.awbDate === null ? '' : moment(subField.awbDate).format('DD/MM/YYYY')} </span>
                                                                                }
                                                                            </td>



                                                                            <td className="fixed-column-Route">

                                                                                {


                                                                                    showApproval === true ?
                                                                                        <Modal show={showApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowApproval(false)}>
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title>
                                                                                                    {View === true ?
                                                                                                        "View" : "Edit"} Lab Dip Approval
                                                                                                </Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Modal.Body>


                                                                                                {
                                                                                                    showApprovaldiv === true ?
                                                                                                        <>
                                                                                                            <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                                                                                                <div className="col-sm-12">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="BuyerID">
                                                                                                                                    Buyer-Season-Brand

                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Buyer-Season-Brand"
                                                                                                                                        className="form-control"
                                                                                                                                        name=" Buyer-Season-Brand"
                                                                                                                                        id={" Buyer-Season-Brand"}
                                                                                                                                        value={inputFields[0].BuyerBrandSeasonName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="SupplierID">
                                                                                                                                    Supplier
                                                                                                                                    {/* <span className="text-danger">*</span> */}
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Supplier"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SupplierID"
                                                                                                                                        id={"SupplierID"}
                                                                                                                                        value={inputFields[0].SupplierName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor="Name">
                                                                                                                                    Submit Date
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                        id={
                                                                                                                                            "SubmittedDate"
                                                                                                                                        }
                                                                                                                                        selected={
                                                                                                                                            inputFields[0].SubmittedDate
                                                                                                                                        }

                                                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                                                        peekNextMonth

                                                                                                                                        // customInput={<CustomInput />}
                                                                                                                                        disabled
                                                                                                                                    />

                                                                                                                                    {/* <input
                                                                                                                                        placeholder="SubmittedDate"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SubmittedDate"
                                                                                                                                        id={"SubmittedDate"}
                                                                                                                                        value={inputFields[0].SubmittedDate}
                                                                                                                                        disabled

                                                                                                                                    ></input> */}

                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"SendTo"}>
                                                                                                                                    Approval Date
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    {
                                                                                                                                        View !== true ?
                                                                                                                                            <DatePicker className="form-control" name="ApprovalDate"
                                                                                                                                                id={
                                                                                                                                                    "ApprovalDate"
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    inputFields[0].ApprovalDateEdit === '' ? '' : inputFields[0].ApprovalDateEdit
                                                                                                                                                }
                                                                                                                                                onChange={event => handleChange(event, 'ApprovalDate')}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth

                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                customInput={<CustomInputApproval />}
                                                                                                                                                isClearable={inputFields[0].ApprovalDateEdit === null ? false : true}
                                                                                                                                            />
                                                                                                                                            // <input
                                                                                                                                            //     id="ApprovalDate"
                                                                                                                                            //     name="ApprovalDate"
                                                                                                                                            //     value={inputFields[0].ApprovalDateEdit}
                                                                                                                                            //     placeholder="DD/MM/YYYY"
                                                                                                                                            //     type="date"
                                                                                                                                            //     autoComplete="off"
                                                                                                                                            //     className={"form-control"}
                                                                                                                                            //     onKeyDown={(e) => e.preventDefault()}
                                                                                                                                            //     style={{
                                                                                                                                            //         color:
                                                                                                                                            //             inputFields[0].ApprovalDateEdit === ""
                                                                                                                                            //                 ? "#bfdea1"
                                                                                                                                            //                 : "#000",
                                                                                                                                            //         border:
                                                                                                                                            //             submitted &&
                                                                                                                                            //                 inputFields[0].ApprovalDateEdit === ""
                                                                                                                                            //                 ? "1px solid red"
                                                                                                                                            //                 : "",

                                                                                                                                            //     }}
                                                                                                                                            //     onChange={event => handleChange(event, 'ApprovalDate')}

                                                                                                                                            // />
                                                                                                                                            :
                                                                                                                                            <DatePicker className="form-control" name="SubmittedDate"
                                                                                                                                                id={
                                                                                                                                                    "SubmittedDate"
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    inputFields[0].ApprovalDate
                                                                                                                                                }

                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                disabled
                                                                                                                                            />
                                                                                                                                        // <input
                                                                                                                                        //     placeholder="ApprovalDate"
                                                                                                                                        //     className="form-control"
                                                                                                                                        //     name="ApprovalDate"
                                                                                                                                        //     id={"ApprovalDate"}
                                                                                                                                        //     value={inputFields[0].ApprovalDate}
                                                                                                                                        //     disabled

                                                                                                                                        // ></input>
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">


                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"StoryName"}>
                                                                                                                                    Story Name
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the StoryName"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].StoryName}
                                                                                                                                        name="StoryName"
                                                                                                                                        id={"StoryName"}
                                                                                                                                        value={inputFields[0].StoryName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={" ThemeName"}>
                                                                                                                                    Theme Name
                                                                                                                                    <span className="text-danger">*</span>
                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the  ThemeName"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].ThemeName}
                                                                                                                                        name=" ThemeName"
                                                                                                                                        id={" ThemeName"}
                                                                                                                                        value={inputFields[0].ThemeName}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-3">
                                                                                                                            <div className="form-group">
                                                                                                                                <label htmlFor={"Endcustomer"}>
                                                                                                                                    End Customer

                                                                                                                                </label>
                                                                                                                                <span className="input-icon icon-right">
                                                                                                                                    <input
                                                                                                                                        placeholder="Enter the End Customer"
                                                                                                                                        className="form-control"
                                                                                                                                        title={inputFields[0].EndCustomer}
                                                                                                                                        name="End Customer No"
                                                                                                                                        id={"End Customer"}
                                                                                                                                        value={inputFields[0].EndCustomer}
                                                                                                                                        disabled

                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <div className='form-group'>
                                                                                                                                <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                    <input
                                                                                                                                        placeholder="Submit Sent Details"
                                                                                                                                        className="form-control"
                                                                                                                                        name="SubmitSentDetails"
                                                                                                                                        id={
                                                                                                                                            "SubmitSentDetails"
                                                                                                                                        }
                                                                                                                                        value={inputFields[0].SubmitSentDetails}
                                                                                                                                        disabled
                                                                                                                                    ></input>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-12">
                                                                                                                        <div className="tabbable">
                                                                                                                            <ul
                                                                                                                                className="nav nav-tabs nav-justified"
                                                                                                                                id="myTab3"
                                                                                                                            >
                                                                                                                                <li
                                                                                                                                    className={tab === 0 ? "active" : ""} >

                                                                                                                                    <a
                                                                                                                                        onClick={event => TabChange(0)}
                                                                                                                                    // href="#idSubmitInformation3"
                                                                                                                                    >
                                                                                                                                        <h5>Submit Information</h5>
                                                                                                                                    </a>
                                                                                                                                </li>

                                                                                                                                <li
                                                                                                                                    className={tab === 1 ? "active" : " "}

                                                                                                                                >
                                                                                                                                    <a

                                                                                                                                        // href="#idOrderInformation3"
                                                                                                                                        onClick={event => TabChange(1)}
                                                                                                                                    >
                                                                                                                                        <h5>Order Information</h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                <div id="idSubmitInformation3" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-sm-12">
                                                                                                                                            <div className="row">
                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className='form-group'>
                                                                                                                                                        {
                                                                                                                                                            inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor="BuyerID">Pantone #</label>
                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                        <input
                                                                                                                                                                            placeholder="Pantone #"
                                                                                                                                                                            className="form-control"

                                                                                                                                                                            name="PantoneCodeandType"
                                                                                                                                                                            id={
                                                                                                                                                                                "PantoneCodeandType"
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                            }
                                                                                                                                                                            disabled

                                                                                                                                                                        ></input>
                                                                                                                                                                    </span>
                                                                                                                                                                </>
                                                                                                                                                                :
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor="SwatchName">Swatch Name</label>
                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                        <input
                                                                                                                                                                            placeholder="Swatch Name"
                                                                                                                                                                            className="form-control"
                                                                                                                                                                            title={inputFields[0].PantoneNameOrSwatchName}
                                                                                                                                                                            name="SwatchName"
                                                                                                                                                                            id={
                                                                                                                                                                                "SwatchName"
                                                                                                                                                                            }
                                                                                                                                                                            value={
                                                                                                                                                                                inputFields[0].PantoneNameOrSwatchName
                                                                                                                                                                            }
                                                                                                                                                                            disabled

                                                                                                                                                                        ></input>
                                                                                                                                                                    </span>
                                                                                                                                                                </>
                                                                                                                                                        }

                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className='form-group'>
                                                                                                                                                        <label htmlFor="SeasonID" >Ref.ColorName</label>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input
                                                                                                                                                                placeholder="Ref.ColorName"
                                                                                                                                                                className="form-control"
                                                                                                                                                                title={inputFields[0].RefColorName}
                                                                                                                                                                name="RefColorName"
                                                                                                                                                                id={
                                                                                                                                                                    "RefColorName"
                                                                                                                                                                }
                                                                                                                                                                value={
                                                                                                                                                                    inputFields[0].RefColorName
                                                                                                                                                                }
                                                                                                                                                                disabled
                                                                                                                                                            ></input>
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>


                                                                                                                                                <div className="col-sm-3">
                                                                                                                                                    <div className="form-group">
                                                                                                                                                        {
                                                                                                                                                            inputFields[0].PantoneTypeName !== "Others" ?
                                                                                                                                                                <>
                                                                                                                                                                    <label htmlFor={"Quarter"}>Color/Image</label>
                                                                                                                                                                    {
                                                                                                                                                                        inputFields[0].PantoneColorOrOrSwatchImage !== '' ?
                                                                                                                                                                            <div style={{ background: inputFields[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                                                                                                                                                            :
                                                                                                                                                                            <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                                                    }
                                                                                                                                                                </> :
                                                                                                                                                                <>
                                                                                                                                                                    <img src={inputFields[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                                                                                                                                                        alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                                                                                                                                                    />
                                                                                                                                                                </>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="row">
                                                                                                                                                <div className="col-sm-6">
                                                                                                                                                    <div className="form-group">
                                                                                                                                                        <label htmlFor={"FabricID"}>

                                                                                                                                                            Fabric Details<span className="text-danger">*</span></label>

                                                                                                                                                        <span className="input-icon icon-right">
                                                                                                                                                            <select id={"FabricID"} name="FabricID" className="form-select"
                                                                                                                                                                value={inputFields[0].FabricID}
                                                                                                                                                                disabled
                                                                                                                                                            >
                                                                                                                                                                <option value="0">-Select Fabric-</option>
                                                                                                                                                                {
                                                                                                                                                                    FabricDetails.map(item => (
                                                                                                                                                                        <option key={item.id} value={item.value}>
                                                                                                                                                                            {item.label}
                                                                                                                                                                        </option>
                                                                                                                                                                    ))
                                                                                                                                                                }
                                                                                                                                                            </select>
                                                                                                                                                        </span>

                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                <div className="row">
                                                                                                                                                    <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0px" }}>
                                                                                                                                                        <div className='form-group'>
                                                                                                                                                            <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                                                                                            <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                                                                                                                rows="4" style={{ width: "100%" }}
                                                                                                                                                                disabled
                                                                                                                                                            ></textarea>
                                                                                                                                                        </div>
                                                                                                                                                    </div>

                                                                                                                                                </div>
                                                                                                                                            </div>


                                                                                                                                            <div className="row">
                                                                                                                                                <table>
                                                                                                                                                    <tr>
                                                                                                                                                        <td><label>Submit Options</label> </td>
                                                                                                                                                        <td><label>Submission Card</label></td>
                                                                                                                                                    </tr>
                                                                                                                                                    <tr>
                                                                                                                                                        <td> <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
                                                                                                                                                            <div className="form-group">


                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                                                                                                    style={{ width: "637px" }}>
                                                                                                                                                                    <thead>
                                                                                                                                                                        <tr>
                                                                                                                                                                            <th >Options <span className="text-danger">*</span></th>
                                                                                                                                                                            <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                                                                                            <th>Approved Option <span className="text-danger">*</span></th>

                                                                                                                                                                        </tr>

                                                                                                                                                                    </thead>
                                                                                                                                                                    <tbody>
                                                                                                                                                                        {
                                                                                                                                                                            submittedoption && (
                                                                                                                                                                                submittedoption.map((item, index) => {
                                                                                                                                                                                    return (
                                                                                                                                                                                        <>
                                                                                                                                                                                            <tr>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className="input-icon icon-right">
                                                                                                                                                                                                        <input
                                                                                                                                                                                                            placeholder="Option Name"
                                                                                                                                                                                                            className="form-control"
                                                                                                                                                                                                            title={item.optionName}
                                                                                                                                                                                                            name="OptionName"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "OptionName"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            value={
                                                                                                                                                                                                                item.optionName
                                                                                                                                                                                                            }
                                                                                                                                                                                                            disabled
                                                                                                                                                                                                        ></input>


                                                                                                                                                                                                    </span>
                                                                                                                                                                                                </td>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                                                                        <input
                                                                                                                                                                                                            placeholder=""
                                                                                                                                                                                                            className="form-control"
                                                                                                                                                                                                            title={item.supplierReferenceNo}
                                                                                                                                                                                                            name="Sendto"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "Sendto"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            value={
                                                                                                                                                                                                                item.supplierReferenceNo
                                                                                                                                                                                                            }
                                                                                                                                                                                                            disabled
                                                                                                                                                                                                        ></input>
                                                                                                                                                                                                    </span>

                                                                                                                                                                                                </td>
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <span className='input-icon icon-right'>


                                                                                                                                                                                                        <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                                                                                            id={
                                                                                                                                                                                                                "TaskHolderOrFollower"
                                                                                                                                                                                                            }
                                                                                                                                                                                                            isLoading={false}
                                                                                                                                                                                                            isClearable={true}
                                                                                                                                                                                                            isSearchable={true}
                                                                                                                                                                                                            onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                                                                                            value={getApprovedOptions.filter(function (option) {
                                                                                                                                                                                                                return option.value === item.approvedOptionName;
                                                                                                                                                                                                            })}
                                                                                                                                                                                                            options={getApprovedOptions}
                                                                                                                                                                                                            styles={
                                                                                                                                                                                                                submitted && item.approvedOptionName === '0' ? styles2 : styles1
                                                                                                                                                                                                            }


                                                                                                                                                                                                            menuPosition="fixed"
                                                                                                                                                                                                            disabled={View === true ? true : false}
                                                                                                                                                                                                        // isDisabled={inputFields[0].ApprovalStatus === "Completed"}
                                                                                                                                                                                                        // disabled

                                                                                                                                                                                                        ></Reactselect>
                                                                                                                                                                                                    </span>

                                                                                                                                                                                                </td>

                                                                                                                                                                                            </tr>
                                                                                                                                                                                        </>

                                                                                                                                                                                    )
                                                                                                                                                                                }))
                                                                                                                                                                        }
                                                                                                                                                                    </tbody>
                                                                                                                                                                </table>
                                                                                                                                                            </div>
                                                                                                                                                        </div></td>
                                                                                                                                                        <td>
                                                                                                                                                            <div className={ImgClassName}
                                                                                                                                                                htmlFor="upload-button"
                                                                                                                                                            >
                                                                                                                                                                <img
                                                                                                                                                                    src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}

                                                                                                                                                                    alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                                                                                            </div>
                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </table>




                                                                                                                                                <div> {
                                                                                                                                                    isOpen && (

                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                                                                                            alt="bg image"
                                                                                                                                                            onCloseRequest={
                                                                                                                                                                () => setIsOpen(false)
                                                                                                                                                            } />
                                                                                                                                                    )
                                                                                                                                                } </div>

                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div id="idOrderInformation3" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                                                                                                    <br />
                                                                                                                                    <div className="row">
                                                                                                                                        <div className="col-lg-12">
                                                                                                                                            <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                                                                                                                                {
                                                                                                                                                    getOrderinfo.length !== 0 ?

                                                                                                                                                        <table className="table table-bordered">
                                                                                                                                                            <TableHeader headers={headers} />
                                                                                                                                                            <tbody>
                                                                                                                                                                {

                                                                                                                                                                    getOrderinfo.map((item, index) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>{item.storyName}</td>
                                                                                                                                                                                    <td>{item.themeName}</td>
                                                                                                                                                                                    <td>{item.itemDetails}</td>
                                                                                                                                                                                    <td>{item.taskHolderFollowerNames}</td>


                                                                                                                                                                                </tr>
                                                                                                                                                                            </>

                                                                                                                                                                        )
                                                                                                                                                                    })
                                                                                                                                                                }

                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                        :
                                                                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content">
                                                                                                                                                            <TableHeader headers={headers} />
                                                                                                                                                            <tbody>
                                                                                                                                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                            </tbody>
                                                                                                                                                        </table>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <br />


                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>


                                                                                                                <div className="col-sm-12 col-lg-12">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-8 col-lg-8">
                                                                                                                            <div className='form-group'>
                                                                                                                                <label htmlFor="Remarks">Remarks</label>
                                                                                                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}

                                                                                                                                    rows="4" style={{ width: "100%" }}
                                                                                                                                    disabled={View === true ? true : false}
                                                                                                                                    onChange={event => handleChange(event, 'Remarks')}
                                                                                                                                ></textarea>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    View !== true && (
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-sm-12" align="right">
                                                                                                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}

                                                                                                                                >
                                                                                                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }


                                                                                                            </div>



                                                                                                        </>
                                                                                                        : ''
                                                                                                }

                                                                                            </Modal.Body>

                                                                                        </Modal> : ''

                                                                                }

                                                                                {

                                                                                    subField.manualCloseComment === '' && subField.approvalStatus === "Pending" ?
                                                                                        <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-darkorange" title="Manual Close"
                                                                                            onClick={() => handleManualclose(subField.genTrimTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId, 0, subField.isManualSubmission)}
                                                                                        >
                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                        </Fragment>
                                                                                        :
                                                                                        subField.manualCloseComment !== '' && subField.approvalStatus === "Completed" ?
                                                                                            <button type="button" className="btn btn-xs btn-success" title="View Manual Close Data"
                                                                                                onClick={() => handleManualclose(subField.genTrimTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                    subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                    subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId, subField.genTrimRefernceID, subField.isManualSubmission)}
                                                                                            >
                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                <i className="fa fa-eye"></i>
                                                                                            </button> : ''

                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' && subField.isFormSubmission === 1 ?
                                                                                        TrimApprovalTab && TrimApprovalTab.isView !== 0 &&

                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                            onClick={() => OpenTrimApprovalpopup(subField.genTrimTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId,
                                                                                                subField.genTrimMainId, index, subField.genTrimRefernceID, 'View')}>
                                                                                            <i className="fa fa-eye "></i>
                                                                                        </button>
                                                                                        : ''

                                                                                }

                                                                                &nbsp;

                                                                                {
                                                                                    TrimApprovalTab && TrimApprovalTab.isEdit !== 0 &&
                                                                                    subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' && subField.isFormSubmission === 1 && (
                                                                                        <span title="Edit Approval" class="btn btn-info btn-xs edit"
                                                                                            onClick={() => OpenTrimApprovalpopup(subField.genTrimTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId,
                                                                                                subField.genTrimMainId, index, subField.genTrimRefernceID, 'Edit')}
                                                                                        ><i class="fa fa-edit"></i></span>
                                                                                    )
                                                                                }

                                                                                &nbsp;
                                                                                {
                                                                                    TrimApprovalTab && TrimApprovalTab.isDelete !== 0 &&
                                                                                    subField.isFormSubmission === 1 && (
                                                                                        <span title='Delete Approval'
                                                                                            className="btn btn-danger btn-xs delete" onClick={() => OpenDeletPopup(subField.genTrimTNASubmissionAndApprovalId, subField.approvalStatus,
                                                                                                subField.buyerId, subField.brandId, subField.seasonId, subField.supplierId,
                                                                                                subField.follower, subField.taskID, subField.trimId, subField.genTrimLogoID, subField.genTrimId,
                                                                                                subField.genTrimMainId, index, subField.genTrimRefernceID, 'Edit')}>
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    // new Date() > new Date(subField.EndDate) &&
                                                                                    subField.approvalStatus === "Completed" &&
                                                                                    <button type="button" className={subField.isRescheduled === 0 ? "btn btn-xs btn-light" : "btn btn-xs btn-success"} title='Child Reschedule '
                                                                                        onClick={() => OpenReschedulePopup(subField.genTrimSupplierTNAId, subField.genTrimTNASubmissionAndApprovalId
                                                                                            , subField.genTrimDetailTNAId, subField.colorGroupId)}
                                                                                    >
                                                                                        <i class="fa fa-calendar"></i>
                                                                                    </button>
                                                                                }


                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            inputField.criticalList && inputField.criticalList.map((Crit) => (
                                                                                subField.programIndexName === Crit.programIndexName ?
                                                                                    <tr class={"child_" + index + " parentChild_" + index + "_" + subIndex} >
                                                                                        <td colSpan="10">
                                                                                            <span style={{ color: 'red' }}>Critical : {Crit.criticalComment}</span>,&nbsp;
                                                                                            <span style={{ color: '#427fed' }}>Target to Close : {new Date(Crit.targettoClose).toLocaleDateString()}</span>,&nbsp;
                                                                                            <span style={{ color: '#00a65a' }}>Completed On : {Crit.completedOn !== '' ? new Date(Crit.completedOn).toLocaleDateString() : '-'}</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    : ''
                                                                            ))
                                                                        }
                                                                    </Fragment>
                                                                ))
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </>
                                            :

                                            <tr><td colSpan="12" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            {/* <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span> */}
                            {/* <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span> */}
                            {/* <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button> */}
                        </div>
                    </div>
                </div>
            </div >
            {
                showManual === true ?
                    <Modal show={showManual === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {getheader} : Trim Approval (Local Development) 
                                &nbsp; &nbsp;
                                {
                                    ChildId.Status === 1 &&
                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete()}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                <thead>
                                    <tr>
                                        <th>Comments <span className="text-danger">*</span></th>
                                        <th>Close Date <span className="text-danger">*</span></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "600px" }} >
                                            <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={ManualInfo[0].manualCloseComment}

                                                style={{ width: "100%", border: getComment && ManualInfo[0].manualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled
                                            >

                                            </textarea>
                                        </td>
                                        <td >
                                            {

                                                <DatePicker className="form-control" name="StoryName"
                                                    id={
                                                        "StoryName"
                                                    }
                                                    selected={new Date(ManualInfo[0].manualCloseDate)}

                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    disabled
                                                // showYearDropdown

                                                //disabled={subField.approvalStatus === "Completed" ? true : false}
                                                />
                                                // <input
                                                //     placeholder="Enter the StoryName"
                                                //     className="form-control"
                                                //     title={ManualInfo[0].manualCloseDate}
                                                //     name="StoryName"
                                                //     id={"StoryName"}
                                                //     value={ManualInfo[0].manualCloseDate}
                                                //     disabled

                                                // ></input>
                                            }


                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            {
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Story</th>
                                            <th>Theme</th>
                                            <th>Details </th>
                                            <th>Task Holder / Follower</th>
                                            {/* <th>
                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                </th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            MultiCloseDatas.length !== 0 ?
                                                MultiCloseDatas.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item.storyName}</td>
                                                                <td>{item.themeName}</td>
                                                                <td>{item.details}</td>
                                                                <td>{item.followerName}</td>
                                                            </tr>
                                                        </>

                                                    )
                                                })
                                                :
                                                <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                        }

                                    </tbody>
                                </table>


                            }

                        </Modal.Body>
                        <Modal.Footer>



                        </Modal.Footer>
                    </Modal> : ''

            }

            {


                showApproval === true ?
                    <Modal dialogClassName="commonwidth" show={showApproval === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setshowApproval(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {
                                    View === true ?
                                        "View" : "Edit"
                                }
                                &nbsp; Trim Approval
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "1px", margin: "0px" }}>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="BuyerID">
                                                        Buyer-Season-Brand

                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            placeholder="Buyer-Season-Brand"
                                                            className="form-control"
                                                            name=" Buyer-Season-Brand"
                                                            id={" Buyer-Season-Brand"}
                                                            value={inputFields[0].BuyerBrandSeasonName}
                                                            disabled

                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="SupplierID">
                                                        Supplier
                                                        {/* <span className="text-danger">*</span> */}
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            placeholder="Supplier"
                                                            className="form-control"
                                                            name="SupplierID"
                                                            id={"SupplierID"}
                                                            value={inputFields[0].SupplierName}
                                                            disabled

                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="Name">
                                                        Submit Date
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <DatePicker className="form-control" name="SubmittedDate"
                                                            id={
                                                                "SubmittedDate"
                                                            }
                                                            selected={
                                                                inputFields[0].SubmittedDate
                                                            }

                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth

                                                            // customInput={<CustomInput />}
                                                            disabled
                                                        />

                                                        {/* <input
                                                            placeholder="SubmittedDate"
                                                            className="form-control"
                                                            name="SubmittedDate"
                                                            id={"SubmittedDate"}
                                                            value={inputFields[0].SubmittedDate}
                                                            disabled

                                                        ></input> */}

                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={"SendTo"}>
                                                        Approval Date
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        {
                                                            View !== true ?
                                                                <DatePicker className="form-control" name="ApprovalDate"
                                                                    id={
                                                                        "ApprovalDate"
                                                                    }
                                                                    selected={
                                                                        inputFields[0].ApprovalDateEdit
                                                                    }
                                                                    onChange={event => handleChange(event, 'ApprovalDate')}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth

                                                                    dropdownMode="scroll"
                                                                    autoComplete="off"
                                                                    customInput={<CustomInputApproval />}
                                                                    isClearable={inputFields[0].ApprovalDateEdit === null ? false : true}
                                                                />
                                                                // <input
                                                                //     id="ApprovalDate"
                                                                //     name="ApprovalDate"
                                                                //     value={inputFields[0].ApprovalDateEdit}
                                                                //     placeholder="DD/MM/YYYY"
                                                                //     type="date"
                                                                //     autoComplete="off"
                                                                //     className={"form-control"}
                                                                //     onKeyDown={(e) => e.preventDefault()}
                                                                //     style={{
                                                                //         color:
                                                                //             inputFields[0].ApprovalDate === ""
                                                                //                 ? "#bfdea1"
                                                                //                 : "#000",
                                                                //         border:
                                                                //             submitted &&
                                                                //                 inputFields[0].ApprovalDate === ""
                                                                //                 ? "1px solid red"
                                                                //                 : "",
                                                                //     }}
                                                                //     onChange={event => handleChange(event, 'ApprovalDate')}
                                                                // />
                                                                :
                                                                <DatePicker className="form-control" name="SubmittedDate"
                                                                    id={
                                                                        "SubmittedDate"
                                                                    }
                                                                    selected={
                                                                        inputFields[0].ApprovalDate
                                                                    }

                                                                    dateFormat="dd/MM/yyyy"
                                                                    peekNextMonth
                                                                    disabled
                                                                />
                                                            // <input
                                                            //     placeholder="ApprovalDate"
                                                            //     className="form-control"
                                                            //     name="ApprovalDate"
                                                            //     id={"ApprovalDate"}
                                                            //     value={inputFields[0].ApprovalDate}
                                                            //     disabled

                                                            // ></input>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">


                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={"StoryName"}>
                                                        Story Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            placeholder="Enter the StoryName"
                                                            className="form-control"
                                                            title={inputFields[0].StoryName}
                                                            name="StoryName"
                                                            id={"StoryName"}
                                                            value={inputFields[0].StoryName}
                                                            disabled

                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={" ThemeName"}>
                                                        Theme Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            placeholder="Enter the  ThemeName"
                                                            className="form-control"
                                                            title={inputFields[0].ThemeName}
                                                            name=" ThemeName"
                                                            id={" ThemeName"}
                                                            value={inputFields[0].ThemeName}
                                                            disabled

                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={"Endcustomer"}>
                                                        End Customer

                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <input
                                                            placeholder="Enter the End Customer"
                                                            className="form-control"
                                                            title={inputFields[0].EndCustomer}
                                                            name="End Customer No"
                                                            id={"End Customer"}
                                                            value={inputFields[0].EndCustomer}
                                                            disabled

                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="Awbdate" >Submit Sent Details</label><span className="text-danger">*</span>
                                                    <span className='input-icon icon-right'>
                                                        <input
                                                            placeholder="Submit Sent Details"
                                                            className="form-control"
                                                            name="SubmitSentDetails"
                                                            id={
                                                                "SubmitSentDetails"
                                                            }
                                                            value={inputFields[0].SubmitSentDetails}
                                                            disabled
                                                        ></input>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="tabbable">
                                                <ul
                                                    className="nav nav-tabs nav-justified"
                                                    id="myTab2"
                                                >
                                                    <li
                                                        className={tab === 0 ? "active" : ""} >

                                                        <a
                                                            onClick={event => TabChange(0)}

                                                        >
                                                            <h5>Submit Information</h5>
                                                        </a>
                                                    </li>

                                                    <li
                                                        className={tab === 1 ? "active" : " "}

                                                    >
                                                        <a


                                                            onClick={event => TabChange(1)}
                                                        >
                                                            <h5>Order Information</h5>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div id="idSubmitInformation2" className={tab === 0 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="BuyerID">Trim Code</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder="StikeoffCode"
                                                                                    className="form-control cut_text"
                                                                                    title={inputFields.TrimCode}
                                                                                    name="TrimCode"
                                                                                    id={
                                                                                        "TrimCode"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].TrimCode
                                                                                    }

                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        <div className='form-group'>
                                                                            <label htmlFor="SeasonID" >Trim Name</label>
                                                                            <span className='input-icon icon-right'>
                                                                                <input
                                                                                    placeholder=" Name"
                                                                                    className="form-control cut_text"
                                                                                    title={inputFields.TrimName}
                                                                                    name="TrimName"
                                                                                    id={
                                                                                        "TrimName"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].TrimName
                                                                                    }

                                                                                    disabled
                                                                                ></input>
                                                                            </span>
                                                                        </div>
                                                                    </div>



                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor={"Quarter"}>Technical Specification</label>
                                                                            <span className="input-icon icon-right">

                                                                                <input
                                                                                    placeholder="TechnicalSpecification"
                                                                                    className="form-control cut_text"
                                                                                    title={inputFields.TechnicalSpecification}
                                                                                    name="TechnicalSpecification"
                                                                                    id={
                                                                                        "TechnicalSpecification"
                                                                                    }
                                                                                    value={
                                                                                        inputFields[0].TechnicalSpecification
                                                                                    }

                                                                                    disabled
                                                                                ></input>

                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">

                                                                        <label style={{ marginLeft: "20px" }} htmlFor={"Quarter"}>Trim Image</label>
                                                                        <br />
                                                                        {/* <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i> */}
                                                                        {
                                                                            getSubImageList.length !== 0 ?
                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + getSubImageList[getCurrimageindex]} alt="No Image" width="150px" height="150px" style={{ border: "2px solid black" }} />
                                                                                :
                                                                                <div align="center" style={{ width: "150px", height: "150px" }}>
                                                                                    No Images Found
                                                                                </div>
                                                                        }
                                                                        {/* <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i> */}




                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">

                                                                            <label htmlFor={"TrimDimensionId"}>Trim Dimension <span className="text-danger">*</span></label>
                                                                            <select id={"TrimDimensionId"} name="TrimDimensionId" className="form-select"
                                                                                value={inputFields[0].TrimDimensionId}
                                                                                // onChange={(event) => handleChangeSubmissionMain(event, 'TrimDimensionId')}
                                                                                style={{ border: submittedpopup && inputFields[0].TrimDimensionId === "0" ? '1px solid red' : '' }}
                                                                                disabled
                                                                            >
                                                                                <option value="0">-Select Trim-</option>
                                                                                {
                                                                                    TrimDimension.map(item => (
                                                                                        <option key={item.id} value={item.value}>
                                                                                            {item.label}
                                                                                        </option>
                                                                                    ))
                                                                                }

                                                                            </select>




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-lg-12">
                                                                    <div className="row">
                                                                        <div className="col-sm-8 col-lg-8" style={{ paddingLeft: "0px" }}>
                                                                            <div className='form-group'>
                                                                                <label htmlFor="Remarks">Submitted Remarks</label>
                                                                                <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                                                                                    rows="4" style={{ width: "100%" }}
                                                                                    disabled
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <table>
                                                                        <tr>
                                                                            <td><label>Submit Options</label> </td>
                                                                            <td><label>Submission Card</label></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
                                                                                <div className="form-group">


                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                                                                        style={{ width: "637px" }}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th >Options <span className="text-danger">*</span></th>
                                                                                                <th>Supplier Reference No <span className="text-danger">*</span></th>
                                                                                                <th>Approved Option <span className="text-danger">*</span></th>

                                                                                            </tr>

                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                submittedoption && (
                                                                                                    submittedoption.map((item, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span className="input-icon icon-right">
                                                                                                                            <input
                                                                                                                                placeholder="Option Name"
                                                                                                                                className="form-control"
                                                                                                                                title={item.optionName}
                                                                                                                                name="OptionName"
                                                                                                                                id={
                                                                                                                                    "OptionName"
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    item.optionName
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                            ></input>


                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <input
                                                                                                                                placeholder=""
                                                                                                                                className="form-control"
                                                                                                                                title={item.supplierReferenceNo}
                                                                                                                                name="Sendto"
                                                                                                                                id={
                                                                                                                                    "Sendto"
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    item.supplierReferenceNo
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                            ></input>
                                                                                                                        </span>

                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span className='input-icon icon-right'>


                                                                                                                            <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                                                id={
                                                                                                                                    "TaskHolderOrFollower"
                                                                                                                                }
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                onChange={event => handleSubmitoptionchange(event, 'ApprovedOption', index)}
                                                                                                                                value={getApprovedOptions.filter(function (option) {
                                                                                                                                    return option.value === item.approvedOptionName;
                                                                                                                                })}
                                                                                                                                options={getApprovedOptions}
                                                                                                                                styles={
                                                                                                                                    submitted && item.approvedOptionName === null ? styles2 : styles1
                                                                                                                                }


                                                                                                                                menuPosition="fixed"
                                                                                                                                // disabled={inputFields[0].ApprovalStatus === "Completed" ? true : false}
                                                                                                                                isDisabled={View === true ? true : false}

                                                                                                                            ></Reactselect>
                                                                                                                        </span>

                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            </>

                                                                                                        )
                                                                                                    }))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div></td>
                                                                            <td>
                                                                                <div className={ImgClassName}
                                                                                    htmlFor="upload-button"
                                                                                >
                                                                                    <img
                                                                                        src={inputFields[0].SwatchCardIamgePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}

                                                                                        alt="Picture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />


                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </table>




                                                                    <div> {
                                                                        isOpen && (

                                                                            <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                alt="bg image"
                                                                                onCloseRequest={
                                                                                    () => setIsOpen(false)
                                                                                } />
                                                                        )
                                                                    } </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="idOrderInformation2" className={tab === 1 ? "tab-pane in active" : "tab-pane"} style={{ width: "100%" }}>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="dataTables_wrapper no-footer">
                                                                    {
                                                                        OrderInfoMainDetails.length !== 0 ?

                                                                            <table className="table table-bordered">

                                                                                <tbody>

                                                                                    {
                                                                                        OrderInfoMainDetails.map(comment => (
                                                                                            <Fragment>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            <div className="col-lg-8">
                                                                                                                <i
                                                                                                                    className={
                                                                                                                        expandState[comment.trimLogoPath] ?
                                                                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                    }
                                                                                                                    onClick={() => handleExpandRow(comment.trimLogoPath, getOrderinfo)}
                                                                                                                ></i>
                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    {
                                                                                                                        comment.trimCode
                                                                                                                    }
                                                                                                                    -
                                                                                                                    {
                                                                                                                        comment.trimName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <span className="Actionbutton">
                                                                                                                    <img src={window.$APIBaseURL + "Images/Style/Trim/" + comment.trimLogoPath} height="50px" width="50px"></img>
                                                                                                                </span>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </td>

                                                                                                </tr>
                                                                                                <>
                                                                                                    {
                                                                                                        expandedRows.includes(comment.trimLogoPath) ?
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Story</th>
                                                                                                                                <th>Theme</th>
                                                                                                                                <th>Details </th>
                                                                                                                                <th>Task Holder / Follower</th>

                                                                                                                            </tr>
                                                                                                                        </thead>

                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                expandList.map((item, index) => (
                                                                                                                                    <Fragment>

                                                                                                                                        <tr>
                                                                                                                                            <td>{item.storyName}</td>
                                                                                                                                            <td>{item.themeName}</td>
                                                                                                                                            <td>{item.details}</td>
                                                                                                                                            <td>{item.followerName}</td>


                                                                                                                                        </tr>

                                                                                                                                    </Fragment>
                                                                                                                                ))

                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr> : null
                                                                                                    }
                                                                                                </>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }


                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                <TableHeader headers={headers} />
                                                                                <tbody>
                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                </tbody>
                                                                            </table>


                                                                    }



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-sm-8 col-lg-8">
                                                <div className='form-group'>
                                                    <label htmlFor="Remarks">Remarks</label>
                                                    <textarea maxLength="300" name="Remarks" value={inputFields[0].ApprovalRemark}
                                                        onChange={event => handleChange(event, 'Remarks')}
                                                        rows="4" style={{ width: "100%" }}
                                                        disabled={View === true ? true : false}
                                                    ></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {
                                    View !== true && (
                                        <div className="row">
                                            <div className="col-sm-12" align="right">
                                                <button type="button" className="btn btn-success margin-Align" onClick={() => SaveApproval()}

                                                >
                                                    <i className="fa fa-check right"></i> &nbsp;Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }



                            </>


                        </Modal.Body>

                    </Modal> : ''

            }

            {
                getID.showPopupDelete ? <SweetAlertPopup data={
                    getID.Params
                }
                    deleteCallback={Delcallback}
                    showpopup={true}
                    pageActionId={GenTrimApprovalId}
                    searchParams={getID.SearchParams}
                    Msg={"Trim Approval Deleted Successfully."}
                /> : null
            }
            {
                <SweetAlert
                    show={deletepopup}
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    // closeOnConfirm
                    // closeOnCancel
                    title="Are you sure do you want to delete it?"
                    onConfirm={DeleteMultipleApproval}
                    onCancel={() => setdeletepopup(false)}
                    showCloseButton
                    focusCancelBtn>
                    {/* I did it! */}
                </SweetAlert>
            }
            {getsID.showPopupDelete ? <SweetAlertPopup

                // data={''}
                data={
                    getsID.Params
                }
                deleteCallback={DeleteCallback}
                showpopup={true}
                pageActionId={getsID.TNAManualClose}
                Msg={'Manual Close Approval Deleted Sucessfully'}
            /> : null}

            {
                openReschedulepopup === true &&
                <Modal show={openReschedulepopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => setopenReschedulepopup(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Reschedule Add
                            {
                                getSubRescheduleList.length !== 0 ?
                                    <>
                                        <br />
                                        (Initial Date  Duration : {getSubRescheduleList[0].duration} Start Date : {getSubRescheduleList[0].startDate} End Date : {getSubRescheduleList[0].endDate})
                                    </>
                                    : ''

                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Trim</th>
                                    <th>Details</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Leave Days</th>

                                    {/* <th>Action </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TaskDetails.length !== 0 ?
                                        TaskDetails.filter(t => t.taskName === "Trim Approval (Local Development)").map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>
                                                            {item.taskName}

                                                        </td>
                                                        <td>
                                                            <span className='input-icon icon-right'>
                                                                {
                                                                    item.trim
                                                                }
                                                            </span>


                                                            <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                <img className="width_manual"
                                                                    src={item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath}
                                                                    alt="Logo"
                                                                    onClick={
                                                                        () => setIsOpen(item.imagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + item.imagePath)
                                                                    }
                                                                />
                                                            </div>


                                                        </td>
                                                        <td>
                                                            {item.itemDetails}

                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                value={item.duration}
                                                                // onChange={event => handleChangeReschedule(event, index, "Duration")}
                                                                maxLength="3"
                                                                className={'form-control'}
                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildStartdate"
                                                                id={
                                                                    "ChildStartdate"
                                                                }
                                                                selected={Date.parse(moment(item.startDate, 'MM/DD/YYYY').toISOString())}
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </td>
                                                        <td>
                                                            <DatePicker className="form-control min_width_Date"
                                                                name="ChildEnddate"
                                                                id={
                                                                    "ChildEnddate"
                                                                }

                                                                selected={Date.parse(moment(item.endDate, 'MM/DD/YYYY').toISOString())}
                                                                onChange={
                                                                    event => handleChangeReschedule(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                customInput={<EndDateCustomInput />}

                                                            />
                                                        </td>
                                                        <td>
                                                            {item.leaveDayName}

                                                        </td>

                                                    </tr>
                                                </>

                                            )
                                        })
                                        :
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                }

                            </tbody>
                        </table>
                        <br />

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                            <thead>
                                <tr>
                                    {/* <th>Submission Reschedule Comments<span className="text-danger">*</span></th> */}
                                    <th>Approval Reschedule Comments<span className="text-danger">*</span></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    {/* <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.SubmissionComment}
                                            onChange={event => handleChangeReschedule(event, 0, "SubmissionComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.SubmissionComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td> */}
                                    <td style={{ width: "600px" }} >
                                        <textarea
                                            rows="3"
                                            placeholder="Enter Comments here..."
                                            autoFocus onFocus={""} autoComplete="off"
                                            maxLength="500" name="EnterRemarks"
                                            id={"EnterRemarks"}
                                            value={RescheduleComment.ApprovalComment}
                                            onChange={event => handleChangeReschedule(event, 0, "ApprovalComment")}
                                            style={{ width: "100%", border: SubmitReschedule && RescheduleComment.ApprovalComment === '' ? '1px solid red' : '' }}>
                                        </textarea>
                                    </td>

                                </tr>
                            </tbody>

                        </table>
                        {
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Duration</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Comments</th>
                                        <th>Rescheduled Date</th>
                                        <th>Rescheduled By</th>
                                        {/* <th>Action </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getSubRescheduleList.length !== 0 ?
                                            getSubRescheduleList.filter(t => t.type === 'Approval').map((item, subindex) => {
                                                //getSubRescheduleList.filter(t => t.type !== 'Initial').map((item, subindex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {item.type}

                                                            </td>
                                                            <td style={{ width: "80px" }}>
                                                                {item.duration}

                                                            </td>
                                                            <td>
                                                                {item.startDate}

                                                            </td>
                                                            <td>
                                                                {item.endDate}

                                                            </td>
                                                            <td style={{ width: "300px" }}>
                                                                {item.comments}
                                                            </td>
                                                            <td>
                                                                {item.createdDate}

                                                            </td>
                                                            <td>
                                                                {item.rescheduledBy}

                                                            </td>

                                                        </tr>
                                                    </>

                                                )
                                            })
                                            :
                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                    }

                                </tbody>
                            </table>


                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSaveReschedule()}>
                            {/* {getRescheduleAction === 'Add' ? 'Save' : 'Update'} */}
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>

            }
            {loader}
        </Fragment >

    )
}

export default React.memo(TrimViewApprovalTab);