import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import CommonStyle from "../../../Style/CommonStyle";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import OrderDetails from "./OrderDetails";
import TestPhotos from "./TestPhotos";
import Summary from "./Summary";
import Nodify from "../../../Common/ReactNotification";
import TNAService from "../../../../services/TNA/TNAService";
import TestingInformation from "./TestingInformation";
import StrikeoffTrimOrderdeatils from "./StrikeoffTrimOrderdeatils";
import GarmanetorderDetails from './GarmanetorderDetails'
import SweetAlert from 'react-bootstrap-sweetalert';
import { GetTestDetailsReportList } from "../../../../actions/tna";
import CommonTestingkeyinfo from "../../../Style/CommonTestingkeyinfo";
import StyleService from "../../../../services/Style/styleService";
import moment from "moment";
const CreateTesting = (props) => {

  const getInspectionType = props.location.state && props.location.state.params.type;
  const [getCurrentTab, setCurrentTab] = useState('Tab_MainInformation');
  const [getStyleMainInfo, setStyleMainInfo] = useState({});
  const [getQualityMainInfo, setQualityMainInfo] = useState([]);
  const [getInspectionID, setInspectionID] = useState(0);
  const [addStyleConfirmation, setAddStyleConfirmation] = useState({ showPopupConfirm: false, Params: {} });
  const [getTestingInfoTabdata, setTestingInfoTabdata] = useState([]);
  const [getTestingUOMdata, setTestingUOMdata] = useState([]);
  const [errors, setErrors] = useState({}); // State to store validation errors
  const [getLabandRemarksData, setLabandRemarksData] = useState([]);
  const [getTestingInfoWashCount, setTestingInfoWashCount] = useState([]);
  const [getTestingInfoAfterWash, setTestingInfoAfterWash] = useState([]);
  const [getTestNameInfo, setTestnameInfo] = useState([]);
  const [getEXFileInfo, setEXFileInfo] = useState({});
  const [getExlabInfo, setExlabInfo] = useState({});
  const [buttonLoader, setButtonLoader] = useState(true);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [getRefreah, setRefreash] = useState(true);
  const [refershstate, setrefershstate] = useState(0);
  const [getTestPhotos, setTestPhotos] = useState([]);
  const [IsParentCallback, setIsParentCallback] = useState(false);
  const [IsStartdatevalidtion, setIsStartdatevalidtion] = useState(false);
  const [Islabcomments, setlabcomments] = useState(false);
  const [Islabremark, setlabremark] = useState(false);
  const [IsTestingdetails, setTestingdetails] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [getIsPhotoValidationFailed, setIsPhotoValidationFailed] = useState(false);
  const [getIsLoadTestPhotos, setIsLoadTestPhotos] = useState({ isLoad: 0, StyleID: 0 });
  var defaultStartDate = new Date();
  const [getInspectionInfo, setInspectionInfo] = useState([]);
  const [getExFacStartDate, setExFacStartDate] = useState([
    {
      startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
      endDate: new Date(),
      // startDate: moment().subtract(90, "days").format("MM/DD/YYYY"),
      //endDate: moment().subtract(1, "days").add(1, "days").format("MM/DD/YYYY"),
      key: 'selection',
      isShow: false
    }
  ]);
  // Gowtham Detail wash info
  const [getDetailwashheader, setDetailwashheader] = useState([]);
  const [getDetailwashsub, setDetailwashsub] = useState([]);
  const [getSpiralityDetailwashsub, setSpiralityDetailwashsub] = useState([]);
  const [getkeyinfo, setkeyinfo] = useState(0)
  const dispatch = useDispatch();
  useEffect(() => {

    const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
    if (props.location.state.params.TaskStatus === 2) {
      setButtonLoader(props.location.state.params.TestingInspectionID === 0 ? true : false)
      setInspectionID(props.location.state.params.TestingInspectionID === 0 ? true : false)
    } else {
      setButtonLoader(inpsectionId === null ? true : false)
      setInspectionID(inpsectionId === null ? 0 : 1)
    }

    StyleService.GetStyleInspectionList(
      props.location.state.params.styleID || props.location.state.params.details.StyleId,
      2,
      props.location.state.params.TestingInspectionID || inpsectionId,
      // props.location.state.params.TnaID,
      2
    ).then((response) => {
      debugger
      if (response.data) {
        setInspectionInfo(response.data[0]);
        if (response.data.item1 && response.data.item1.length > 0) {
          setInspectionInfo(response.data.item1[0]);
        } else {
          console.error("Item1 is empty or undefined.");
        }
      }
    }).catch((error) => {
      console.error("API call failed:", error);
    });
  }, [getInspectionID])

  const TabRedirection = (tab) => {
    setCurrentTab(tab);
  }

  const handleClose = (value) => {
    setButtonLoader(false);
    //props.location.state.params.Mode = 'InspectionAdd';
    setAddStyleConfirmation({ showPopupConfirm: false, Params: [] });
    let x = Math.floor((Math.random() * 100) + 1);
    setrefershstate(x);
    setkeyinfo(1);
    // setRefreash(false)
  }
  function Maincallback(value) {
    setStyleMainInfo(value)
  }
  function Qualitytestcallback(value) {
    setQualityMainInfo(value)
  }

  function TestnameCallback(value) {
    setTestnameInfo(value)
  }
  function ExternalFilescallback(value) {

    setEXFileInfo(value)
  }
  function ExLabDetailsCallback(value) {
    setExlabInfo(value)
  }
  function ResetOperation(e) {
    if (e === "Back") {
      if (props.location.state.params.ParentTask === "TNAEdit") {
        props.history.push('/TNAEdit', { params: props.location.state.params.details });
      } else if (props.location.state.params.ParentTask === 'TNAView') {
        //localStorage.removeItem("InspectionID");
        props.history.push('/TNAView', { params: props.location.state.params.details });
        setRefreash(true)
      } else if (props.location.state.params.ParentTask === 'TNAReport') {
        props.history.push('/TNAReport')
      }
      else if (props.location.state.params.ParentTask === 'Dashboard') {
        props.history.push('/Dashboard')
      }
      else {
debugger
        //let params = { localStorage: true, PageName: props.location.state.params.TaskType , tabName: props.location.state.params.tabName};        
        dispatch(GetTestDetailsReportList(
          1
          , props.location.state.params.Isproduction
          , props.location.state.params.TaskType
          , moment(getExFacStartDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
          , moment(getExFacStartDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")));
        //props.history.push('/TestingParent', { params:params });
        props.history.push('/TestingParent', {localStorage: true, params:props.location.state.params });

      }
      localStorage.removeItem("TestingInspectionID");
    } else {
      window.location.reload();
    }
  }
  let confirmBtnText = 'Yes,do it!'
  let cancelBtnText = 'Cancel'
  const copystyle = () => {
    if (props.location.state.params.ParentTask === "TNAEdit") {
      props.history.push('/TNAEdit', { params: props.location.state.params.details });
    } else if (props.location.state.params.ParentTask === 'TNAView') {
      //localStorage.removeItem("InspectionID");
      props.history.push('/TNAView', { params: props.location.state.params.details });
      setRefreash(true)
    } else if (props.location.state.params.ParentTask === 'TNAReport') {
      props.history.push('/TNAReport')
    }
    else if (props.location.state.params.ParentTask === 'Dashboard') {
      props.history.push('/Dashboard')
    }
    else {
      let params = { localStorage: true, PageName: props.location.state.params.PageName };

      props.history.push('/TestingParent', { params: params })
    }
    localStorage.removeItem("TestingInspectionID");
  }
  const GetTestingInfoValues = (value) => {

    // value.forEach(element => {
    //   element.TestingInfoID = element.id;
    // })
    setTestingInfoWashCount(value.DetailedWashCountInfo);
    setTestingInfoAfterWash(value.DetailedAfterWashDetail);
    setTestingInfoTabdata(value);
  };
  const GetDetailwashcallback = (data) => {
    setDetailwashheader(data)
  }
  const GetDetailwashsubcallback = (data) => {
    setDetailwashsub(data)
  }
  const labcallback = (value) => {

    setLabandRemarksData(value)
  }
  const GetSpiralDetailwashcallback = (data) => {
    setSpiralityDetailwashsub(data)
  }

  const getUomData = (data) => {

    setTestingUOMdata(data)
  }
  const validateFieldsByTaskType = (taskType, styleInfo) => {
    let mandatoryFields = [];

    switch (taskType) {
      case "FABRIC TEST":
        mandatoryFields = [
          styleInfo.SupplierReferenceNo,
          styleInfo.ColorDepth,
          styleInfo.LotWeight,
          styleInfo.FabricLotNo,
          styleInfo.ReportdateandNo,
          styleInfo.Agencyname,
          styleInfo.StyleLogoName
        ];
        break;
      case "Strike off Test":
      case "Trims Test":
        mandatoryFields = [
          styleInfo.SupplierReferenceNo,
          styleInfo.ColorDepth,
          styleInfo.ReportdateandNo,
          styleInfo.Agencyname
        ];
        break;
      case "GARMENT TEST":
        mandatoryFields = [
          styleInfo.ColorDepth,
          styleInfo.CategoryType,
          styleInfo.ReportdateandNo,
          styleInfo.Agencyname,
          styleInfo.StyleLogoName
        ];
        break;
      default:
        return true; // If no TaskType matches, assume it's valid.
    }

    // Return false if any mandatory field is invalid (empty or zero)
    return mandatoryFields.every(field => field !== "" && field !== 0);
  };

  function SaveMainInformation(e) {
    ;
    let nes = [...getQualityMainInfo]
    let photos = [...getTestPhotos];
    let testinfo = [...getTestingInfoTabdata]
    let TestExteral = [...getTestNameInfo];
    let WashTypeName = [];
    let WashTypePhotos = [];
    let isvalid = true;
    let isvalidstr = false;
    let isvalidPhoto = false;
    if (getCurrentTab === "Tab_MainInformation") {
      debugger
      if (props.location.state.params.type === 1) {
        if (e === 'Save') {
          // Check if TestStartDate is empty or null
          if (getStyleMainInfo.TestEndDate === "" || getStyleMainInfo.TestEndDate === null) {
            // Add specific logic here if needed for 'save'
            //settestEndDateInfo(1);
            setIsStartdatevalidtion(true)
            Nodify('Warning!', 'warning', "Please fill mandatory fields.");
            return false;
          }
        }
        if (getStyleMainInfo.TestStartDate === '' ||
          getStyleMainInfo.WashingType === 0 || getStyleMainInfo.SupplierReferenceNo === "" ||
          getStyleMainInfo.ColorDepth === 0 || getStyleMainInfo.DryerType === 0 || getStyleMainInfo.NoofWashes === '0') {
          // getStyleMainInfo.FabricLotNo === '' ||
          //   getStyleMainInfo.LotWeight === ''
          setIsPhotoValidationFailed(false);
          Nodify('Warning!', 'warning', "Please fill mandatory fields");

          setIsParentCallback(true)
          isvalid = false;
          return false;
        }
        let isAnyValueFilled = false;
        let isvalidstr = false;

        if (getQualityMainInfo && props.location.state.params.TaskType !== "Strike off Test" && props.location.state.params.TaskType !== "Trims Test") {
          debugger
          for (let j = 0; j < getQualityMainInfo.length; j++) {
            // Check if SubTask array is valid
            if (!nes[j].SubTask || nes[j].SubTask.length === 0) {
              isvalidstr = true;
              setIsParentCallback(true);
              Nodify('Warning!', 'warning', "Please Expand the fabric and OrderDetails");
              return false;
            }

            // Loop through SubTask to check if at least one offerqty is filled
            for (let i = 0; i < nes[j].SubTask.length; i++) {
              if (nes[j].SubTask[i].offerqty !== '') {
                isAnyValueFilled = true;
                break; // No need to check further, one filled value is enough
              }
            }

            // If one value is filled, no need to continue checking
            if (isAnyValueFilled) {
              break;
            }
          }

          // After the loop, check if any value was filled
          if (!isAnyValueFilled) {
            setIsParentCallback(true);
            Nodify('Warning!', 'warning', "Please fill at least one field in fabric and OrderDetails");
            isvalid = false;
          }

          if (isvalidstr) {
            setIsPhotoValidationFailed(false);
            return false; // Stop further execution if validation failed
          }
        }

      }
      if (getStyleMainInfo.StyleLogoPath === '') {
        setIsParentCallback(true);
        Nodify('Warning!', 'warning', "Please fill mandatory fields");
        isvalid = false;

      }
      if(((getEXFileInfo.length === 0 || (getEXFileInfo.length === undefined)) && props.location.state.params.type === 2) &&(e === 'Save' || e === 'Draft') ){
        setIsParentCallback(true);
        Nodify('Warning!', 'warning', "Please fill mandatory fields");
        isvalid = false;

      }
      // Main logic
      if (props.location.state.params.type === 2) {
        debugger
        const { TaskType } = props.location.state.params;
        // Validate based on TaskType
        if (!validateFieldsByTaskType(TaskType, getStyleMainInfo)) {
          setIsPhotoValidationFailed(false);
          Nodify('Warning!', 'warning', "Please fill mandatory fields");
          setIsParentCallback(true);
          return false;
        }

        // Further validation for "FABRIC TEST", "GARMENT TEST", etc.
        if (TaskType === "FABRIC TEST" || TaskType === "GARMENT TEST" || TaskType === "YARN TEST") {
          if (getQualityMainInfo) {
            for (let j = 0; j < getQualityMainInfo.length; j++) {
              // Check if SubTask array is valid
              if (!getQualityMainInfo[j].SubTask || getQualityMainInfo[j].SubTask.length === 0) {
                Nodify('Warning!', 'warning', "Please Expand the fabric and OrderDetails");
                setIsParentCallback(true);
                return false;
              }

              // Loop through SubTask to check if at least one offerqty is filled
              let isAnyValueFilled = false;
              for (let i = 0; i < getQualityMainInfo[j].SubTask.length; i++) {
                if (getQualityMainInfo[j].SubTask[i].offerqty !== '') {
                  isAnyValueFilled = true;
                  break; // One filled value is enough
                }
              }

              // If no value is filled, show warning
              if (!isAnyValueFilled) {
                Nodify('Warning!', 'warning', "Please fill at least one field in fabric and OrderDetails");
                setIsParentCallback(true);
                return false;
              }
            }
          }
        }
        else {
          for (let j = 0; j < getQualityMainInfo.length; j++) {
            // Check if SubTask array is valid
            if (!getQualityMainInfo[j].SubTask || getQualityMainInfo[j].SubTask.length === 0) {
              Nodify('Warning!', 'warning', "Please Expand the fabric and OrderDetails");
              setIsParentCallback(true);
              return false;
            }
          }
        }
        // Additional validation for "Test Selection"
        if (getTestNameInfo) {
          for (let j = 0; j < getTestNameInfo.length; j++) {
            if (TestExteral[j].Testresult === 0 && TestExteral[j].ischecked === 1) {
              Nodify('Warning!', 'warning', "Please fill mandatory fields in Test Selection");
              setIsParentCallback(true);
              setIsPhotoValidationFailed(false);
              return false;
            }
          }
        }
      }
    }

    else if (getCurrentTab === "Tab-Testphotos" && e === "Save") {
      if (photos.length > 0) {
        for (var i = 0; i < photos.length; i++) {
          if (!photos[i].washName) {
            setIsPhotoValidationFailed(true);
            isvalidPhoto = true;
            isvalidstr = true
            //Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields in Test Photos tab.');
            //return false;
          }
          else {
            for (var j = 0; j < photos[i].photoDetails.length; j++) {
              if (!photos[i].photoDetails[j].title || !photos[i].photoDetails[j].fileName) {
                setIsPhotoValidationFailed(true);
                isvalidPhoto = true;
                isvalidstr = true
                //Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields in Test Photos tab.');
                //return false;
              }
            }
          }
          if (isvalidPhoto) {
            setIsPhotoValidationFailed(true);
            //Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields in Test Photos tab.');
            //return false;
          }
        }
      }
    }
    else if (getCurrentTab === "Tab_Testinfo" && e === "Save") {
      // if ((testinfo.length > 0 && getStyleMainInfo.IsSaveType === 1 && (getStyleMainInfo.ColorDepth === 97 || getStyleMainInfo.ColorDepth === 101))) {
      //   isvalid = false
      //   setTestingdetails(true);
      //   Nodify('Warning!', 'warning', "Please fill all mandatory fields");
      //   return false;
      // }
      //else {
      //console.log("Test name length"getTestNames.length)
      if (props.location.state.params.TaskType === 'FABRIC TEST') {

        const validationRules = {
          "MASS PER UNIT AREA (GSM)": (item) => item.foundGSM === '' && item.isChecked === 1,
          "DIMENSIONAL STABILTY TO WASHING": (item) => ((item.beforeWash === '' || item.afterWash === '' || item.afterWash === 0) && (item.isChecked === 1)),
          "SPIRALITY": (item) => ((item.beforeWash === '' || item.afterWash === '' || item.afterWash === 0 || item.BWDeviation === "" || item.AWDeviation === "") && (item.isChecked === 1)),
          "PILLING": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "PH": (item) => (item.foundGSM === '' && (item.isChecked === 1)),
          "COLOUR FASTNESS TO RUBBING": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO WASHING": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO WATER": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO PRESPIRATION - ALKALINE": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO PRESPIRATION - ACID": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          // Add more rules for other test names here
        };

        if (testinfo.length > 0
          // && getStyleMainInfo.IsSaveType === 1 && (getStyleMainInfo.ColorDepth === 97 || getStyleMainInfo.ColorDepth === 101)) 
          && testinfo.some(item => {
            const { testName } = item;
            return validationRules[testName] && validationRules[testName](item);
          })) {
          isvalid = false;
          setTestingdetails(true);
          setlabcomments(true)
          Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
          return false;
        }



        //let errors = {};
        for (const item of testinfo) {
          const { testName } = item;
          if (validationRules[testName] && validationRules[testName](item)) {
            errors = "Please fill all mandatory(*) fields";
          }
        }

        // Handle errors or take appropriate action based on the validation results
        if (Object.keys(errors).length === 0) {
          // Validation successful, you can proceed with saving
          isvalid = true
        }
        else {
          // Validation failed, convert errors to a single string or display them as needed
          const errorMessage = errors.toString()
          //alert(errorMessage); // Display the error message as an alert
          isvalid = false
          Nodify('Warning!', 'warning', errorMessage);
          return false;
        }

        if ((getLabandRemarksData.length > 0 && getLabandRemarksData[0].value === 0)) {
          isvalid = false
          setlabcomments(true)
          Nodify('Warning!', 'warning', 'Please fill Lab comments.');
          return false;
        }
        // if (getLabandRemarksData.length === 0) {
        //   isvalid = false
        //   setlabcomments(true)
        //   Nodify('Warning!', 'warning', 'Please fill Lab comments.');
        //   return false;
        // }
        for (var j = 0; j < getLabandRemarksData.length; j++) {

          if (getLabandRemarksData[j].value === 2 && getLabandRemarksData[j].Remarks === '') {
            isvalid = false
            setlabremark(true)
            Nodify('Warning!', 'warning', 'Please fill Lab Remarks.');
            return false;
          }
        }



      }
      else if (props.location.state.params.TaskType === 'GARMENT TEST') {
        const validationRules = {
          "APPEARANCE AFTER WASH": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "DIMENSIONAL STABILTY TO WASHING": (item) => ((item.beforeWash === '' || item.afterWash === '' || item.afterWash === 0 || item.BWDeviation === "" || item.AWDeviation === "") && (item.isChecked === 1)),
          "SPIRALITY": (item) => ((item.beforeWash === '' || item.afterWash === '' || item.afterWash === 0 || item.beforeWash === 0) && (item.isChecked === 1)),

          // Add more rules for other test names here
        };

        if (testinfo.length > 0
          // && getStyleMainInfo.IsSaveType === 1 && (getStyleMainInfo.ColorDepth === 97 || getStyleMainInfo.ColorDepth === 101)) 
          && testinfo.some(item => {
            const { testName } = item;
            return validationRules[testName] && validationRules[testName](item);
          })) {
          isvalid = false;
          setTestingdetails(true);
          setlabcomments(true)
          Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
          return false;
        }
        let errors = {};
        for (const item of testinfo) {
          const { testName } = item;
          if (validationRules[testName] && validationRules[testName](item)) {
            errors = "Please fill all mandatory(*) fields";
          }
        }

        // Handle errors or take appropriate action based on the validation results
        if (Object.keys(errors).length === 0) {
          // Validation successful, you can proceed with saving
          isvalid = true
        }
        else {
          // Validation failed, convert errors to a single string or display them as needed
          const errorMessage = errors.toString()
          //alert(errorMessage); // Display the error message as an alert
          isvalid = false
          Nodify('Warning!', 'warning', errorMessage);
          return false;
        }
        if ((getLabandRemarksData.length === 0) || (getLabandRemarksData.length > 0 && getLabandRemarksData[0].value === 0)) {
          isvalid = false
          setlabcomments(true)
          Nodify('Warning!', 'warning', 'Please fill Lab comments.');
          return false;
        }
        for (var j = 0; j < getLabandRemarksData.length; j++) {
          if (getLabandRemarksData[j].value === 2 && getLabandRemarksData[j].Remarks === '') {
            setlabremark(true)
            isvalid = false
            Nodify('Warning!', 'warning', 'Please fill Lab Remarks.');
            return false;
          }
        }

      }
      else if (props.location.state.params.TaskType === 'YARN TEST') {

        const validationRules = {

          "PH": (item) => (item.foundGSM === '' && (item.isChecked === 1)),
          "COLOUR FASTNESS TO RUBBING": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO WASHING": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO WATER": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO PRESPIRATION - ALKALINE": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "COLOUR FASTNESS TO PRESPIRATION - ACID": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          // Add more rules for other test names here
        };

        if (testinfo.length > 0
          // && getStyleMainInfo.IsSaveType === 1 && (getStyleMainInfo.ColorDepth === 97 || getStyleMainInfo.ColorDepth === 101)) 
          && testinfo.some(item => {
            const { testName } = item;
            return validationRules[testName] && validationRules[testName](item);
          })) {
          isvalid = false;
          setTestingdetails(true);
          setlabcomments(true)
          Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
          return false;
        }

        let errors = {};
        for (const item of testinfo) {
          const { testName } = item;
          if (validationRules[testName] && validationRules[testName](item)) {
            errors = "Please fill all mandatory(*) fields";
          }
        }

        // Handle errors or take appropriate action based on the validation results
        if (Object.keys(errors).length === 0) {
          // Validation successful, you can proceed with saving
          isvalid = true
        }
        else {
          // Validation failed, convert errors to a single string or display them as needed
          const errorMessage = errors.toString()
          //alert(errorMessage); // Display the error message as an alert
          isvalid = false
          Nodify('Warning!', 'warning', errorMessage);
          return false;
        }
        if ((getLabandRemarksData.length === 0) || (getLabandRemarksData.length > 0 && getLabandRemarksData[0].value === 0)) {
          isvalid = false
          setlabcomments(true)
          Nodify('Warning!', 'warning', 'Please fill Lab comments.');
          return false;
        }
        for (var j = 0; j < getLabandRemarksData.length; j++) {
          if (getLabandRemarksData[j].value === 2 && getLabandRemarksData[j].Remarks === '') {
            setlabremark(true)
            isvalid = false
            Nodify('Warning!', 'warning', 'Please fill Lab Remarks.');
            return false;
          }
        }

      }
      else if (props.location.state.params.TaskType === 'Strike off Test') {
        const validationRules = {
          //"DURABILITY TEST": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          "DURABILITY": (item) => ((item.gradeID === '' || item.gradeID === 0) && (item.isChecked === 1)),
          // Add more rules for other test names here
        };

        if (testinfo.length > 0 && testinfo.some(item => {
          const { testName } = item;
          return validationRules[testName] && validationRules[testName](item);
        })) {
          isvalid = false;
          setTestingdetails(true);
          setlabcomments(true)
          Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
          return false;
        }
        let errors = {};
        for (const item of testinfo) {
          const { testName } = item;
          if (validationRules[testName] && validationRules[testName](item)) {
            errors = "Please fill all mandatory(*) fields";
          }
        }

        // Handle errors or take appropriate action based on the validation results
        if (Object.keys(errors).length === 0) {
          // Validation successful, you can proceed with saving
          isvalid = true
        }
        else {
          // Validation failed, convert errors to a single string or display them as needed
          const errorMessage = errors.toString()
          //alert(errorMessage); // Display the error message as an alert
          isvalid = false
          Nodify('Warning!', 'warning', errorMessage);
          return false;
        }
        if ((getLabandRemarksData.length === 0) || (getLabandRemarksData.length > 0 && getLabandRemarksData[0].value === 0)) {
          isvalid = false
          setlabcomments(true)
          Nodify('Warning!', 'warning', 'Please fill Lab comments.');
          return false;
        }
        for (var j = 0; j < getLabandRemarksData.length; j++) {
          if (getLabandRemarksData[j].value === 2 && getLabandRemarksData[j].Remarks === '') {
            isvalid = false
            setlabremark(true)
            Nodify('Warning!', 'warning', 'Please fill Lab Remarks.');
            return false;
          }
        }
      }
      else if (props.location.state.params.TaskType === 'Trims Test') {
        const validationRules = {
          "ATTACHMENT OF STRENGTH": (item) => ((item.foundGSM === '' || item.gradeID === 0) && (item.isChecked === 1)),
          // Add more rules for other test names here
        };

        if (testinfo.length > 0
          // && getStyleMainInfo.IsSaveType === 1 && (getStyleMainInfo.ColorDepth === 97 || getStyleMainInfo.ColorDepth === 101)) 
          && testinfo.some(item => {
            const { testName } = item;
            return validationRules[testName] && validationRules[testName](item);
          })) {
          isvalid = false;
          setTestingdetails(true);
          setlabcomments(true)
          Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
          return false;
        }
        let errors = {};
        for (const item of testinfo) {
          const { testName } = item;
          if (validationRules[testName] && validationRules[testName](item)) {
            errors = "Please fill all mandatory(*) fields";
          }
        }

        // Handle errors or take appropriate action based on the validation results
        if (Object.keys(errors).length === 0) {
          //Validation successful, you can proceed with saving
          isvalid = true
        }
        else {
          // Validation failed, convert errors to a single string or display them as needed
          const errorMessage = errors.toString()
          //alert(errorMessage); // Display the error message as an alert
          isvalid = false
          Nodify('Warning!', 'warning', errorMessage);
          return false;
        }
        if ((getLabandRemarksData.length === 0) || (getLabandRemarksData.length > 0 && getLabandRemarksData[0].value === 0)) {
          isvalid = false
          setlabcomments(true)
          Nodify('Warning!', 'warning', 'Please fill Lab comments.');
          return false;
        }
        for (var j = 0; j < getLabandRemarksData.length; j++) {
          if (getLabandRemarksData[j].value === 2 && getLabandRemarksData[j].Remarks === '') {
            setlabremark(true)
            isvalid = false
            Nodify('Warning!', 'warning', 'Please fill Lab Remarks.');
            return false;
          }
        }


      }



      //}


    }
    else if (getCurrentTab === "Tab_Testinfo" && e === "Draft") {
      // if (testinfo.length === 0) {
      //   isvalid = false
      //   Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields");
      //   return false;
      // }
    }

    if (isvalid) {

      let SkuImageInfo = []
      let Maininfo = []
      let Qualityinfo = []
      let testnameexter = []
      let Exfiles = [];
      let TestingPageInfo = [];
      let TestingUOMInfo = [];
      let TestingDetailsHeaders = [];
      let TestingDetailsSubDetail = [];
      let TestingSpiralitySubdata = [];
      getTestingInfoTabdata.map(x => {
        debugger
        TestingPageInfo.push({
          TestingInfoID: x.TestingInfoID === undefined ? 0 : x.TestingInfoID,
          TestID: x.testID,
          TestTypeID: x.testTypeID,
          FieldID: x.fieldID,
          FieldName: x.fieldName,
          ReqGSM: x.ReqGSM,
          FoundGSM: x.foundGSM,
          AllowedTolerance: x.allowedTolerance,
          ResultType: x.resultType,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          BeforeWash: x.beforeWash === "" ? 0 : x.beforeWash,
          BeforeWashChangeIn: x.beforeWashChangeIn,
          AfterWash: x.afterWash === "" ? 0 : x.afterWash,
          AfterWashChangeIn: x.afterWashChangeIn,
          Observation: x.Observation,
          ColorDepthID: x.colorDepthID,
          ColorDepth: x.colorDepth,
          FabricDetailsID: x.fabricDetailsID,
          FabricDetails: x.fabricDetails,
          GenderID: x.genderID,
          GradeID: x.gradeID,
          GradeName: x.gradeName,
          GradeValue: x.gradeValue,
          Group: x.group,
          id: x.id,
          maxResult: x.maxResult,
          minResult: x.minResult,
          rowindex: x.rowindex,
          ChangeIn: isNaN(x.ChangeIn) ? 0 : x.ChangeIn,
          BWDeviation: x.BWDeviation,
          AWDeviation: x.AWDeviation,
          IsChecked: x.isChecked

        })
      })

      if (getTestingUOMdata.length === 0) {
        TestingUOMInfo = []
      }
      else {
        getTestingUOMdata.map(x => {
          TestingUOMInfo.push({
            TestUOMID: x.TestUOMID === undefined ? 0 : x.TestUOMID,
            TestName: x.TestName,
            value: x.value,
            label: x.label,
            TNATestingInspectionID: x.TNATestingInspectionID,

          })
        })
      }

      getDetailwashheader.map((x, indx) => {
        TestingDetailsHeaders.push({
          DetailWashID: x.DetailWashID ? 0 : x.DetailWashID,
          TNATestInspectionID: x.TNATestInspectionID === undefined ? 0 : props.location.state.params.TestingInspectionID,
          TNAID: props.location.state.params.details?.tnaid,
          TestTypeID: x.TestTypeID,
          TestID: x.TestID,
          AfterWashType: x.Awvalue.toString(),
          TestName: x.TestName
        })
      })
      getDetailwashsub.map((x, indx) => {
        debugger
        TestingDetailsSubDetail.push({
          DetailWashID: x.DetailWashID === undefined ? 0 : x.DetailWashID,
          TNATestInspectionID: x.TNATestInspectionID === undefined ? 0 : props.location.state.params.TestingInspectionID,
          TNAID: props.location.state.params.details?.tnaid,
          TestTypeID: x.testTypeID,
          TestID: x.testID,
          FeildID: x.fieldID === '' ? 0 : parseInt(x.fieldID),
          FeildName: x.fieldName,
          AfterWash: (isNaN(x.afterWash) || x.afterWash === '') ? 0 : parseInt(x.afterWash),
          BeforeWash: (isNaN(x.beforeWash) || x.beforeWash === '') ? 0 : parseInt(x.beforeWash),
          TestName: x.testName
        })
        let currcatindex = TestingDetailsSubDetail.length - 1;
        let headernames = '';
        let Changeinnames = '';
        x.GradingAwchangeIn.map(s => {
          if (headernames === '') {
            headernames = s.AwchangeIN;
          }
          else {
            headernames = headernames + "," + s.AwchangeIN;
          }
        })
        x.GradingAwchangeIn.map(s => {
          if (Changeinnames === '') {
            Changeinnames = s.ChangeIn === undefined ? '' : s.ChangeIn.toString();
          }
          else {
            Changeinnames = Changeinnames + "," + (s.ChangeIn === undefined ? '' : s.ChangeIn.toString());
          }
        })
        TestingDetailsSubDetail[currcatindex].AfterWashType = headernames.toString();
        TestingDetailsSubDetail[currcatindex].ChangeIn = Changeinnames;
      })
      getSpiralityDetailwashsub.map((x, indx) => {
        TestingSpiralitySubdata.push({
          DetailWashID: x.DetailWashID === undefined ? 0 : x.DetailWashID,
          TNATestInspectionID: x.TNATestInspectionID === undefined ? 0 : props.location.state.params.TestingInspectionID,
          TNAID: props.location.state.params.details?.tnaid,
          TestTypeID: x.testTypeID,
          TestID: x.testID,
          FeildID: x.fieldID === '' ? 0 : parseInt(x.fieldID),
          FeildName: x.fieldName,
          AfterWash: (isNaN(x.afterWash) || x.afterWash === '') ? 0 : parseInt(x.afterWash),
          BeforeWash: (isNaN(x.beforeWash) || x.beforeWash === '') ? 0 : parseInt(x.beforeWash),
          TestName: x.testName
        })
        let currcatindex = TestingSpiralitySubdata.length - 1;
        let headernames = '';
        let Changeinnames = '';
        let Devinnames = '';
        x.GradingAwchangeIn.map(s => {
          if (headernames === '') {
            headernames = s.AwchangeIN;
          }
          else {
            headernames = headernames + "," + s.AwchangeIN;
          }
        })
        x.GradingAwchangeIn.map(s => {
          if (Devinnames === '') {
            Devinnames = s.Deviation;
          }
          else {
            Devinnames = Devinnames + "," + s.Deviation;
          }
        })
        x.GradingAwchangeIn.map(s => {
          if (Changeinnames === '') {
            Changeinnames = s.ChangeIn === undefined ? '' : s.ChangeIn.toString()
          }
          else {
            Changeinnames = Changeinnames + "," + (s.ChangeIn === undefined ? '' : s.ChangeIn.toString());
          }
        })
        TestingSpiralitySubdata[currcatindex].AfterWashType = headernames.toString();
        TestingSpiralitySubdata[currcatindex].Deviation = Devinnames;
        TestingSpiralitySubdata[currcatindex].ChangeIn = Changeinnames;
      })


      getQualityMainInfo.map((x, i) => {
        Maininfo.push({
          TNATestmaininfoID: x.TNATestmaininfoID === undefined ? 0 : x.TNATestmaininfoID,
          TNAID: props.location.state.params.details.tnaid,
          SkuID: x.SkuID,
          Mainindex: i,
          PantoneCode: x.PantoneCode === undefined ? '' : x.PantoneCode,
          PantoneTypeName: x.PantoneTypeName === undefined ? '' : x.PantoneTypeName,
          PantoneColor: x.PantoneColor === undefined ? '' : x.PantoneColor,
          RefColorName: x.RefColorName === undefined ? '' : x.RefColorName,
          ProcessTypeName: x.ProcessTypeName === undefined ? '' : x.ProcessTypeName,
          FabricDetailName: x.FabricDetailName === undefined ? '' : x.FabricDetailName,
          TaskID: props.location.state.params.quantityinfo.TaskId,
          StyleID: props.location.state.params.styleID,
          IsDeleted: x.IsDeleted === undefined ? 0 : x.IsDeleted,
          BuyerID: props.location.state.params.buyerID,
          BrandID: props.location.state.params.brandID,
          FabricID: x.FabricID === undefined ? 0 : x.FabricID,
          StrikeOffTrimName: x.StrikeOffTrimName === undefined ? '' : x.StrikeOffTrimName,
          StrikeTrimQuality: x.TrikeTrimQuality === undefined ? '' : x.TrikeTrimQuality,
          StrikeoffTrimLogoName: x.StrikeoffTrimLogoName === undefined ? '' : x.StrikeoffTrimLogoName,
          StrikeofftrimCode: x.StrikeofftrimCode === undefined ? '' : x.StrikeofftrimCode,
          StrikeofftrimID: x.strikeofftrimID === undefined ? '' : x.StrikeofftrimCode,
          StrikoffTrimImagepath: x.StrikoffTrimImagepath === undefined ? '' : x.StrikoffTrimImagepath,
          StrikeofftrimprintcolorID: x.StrikeofftrimprintcolorID === undefined ? '' : x.StrikeofftrimprintcolorID,
          Catogery: x.CategoryName === undefined ? '' : x.CategoryName,
          CatogeryType: x.CategoryTypeName === undefined ? '' : x.CategoryTypeName,
          StyleName: x.StyleName === undefined ? '' : x.StyleName,
          StyleProgramID: (props.location.state.params.TaskType === 'Strike off Test' || props.location.state.params.TaskType === 'Trims Test') ? x.StrikeofftrimID || 0 : x.StyleProgramID ? x.StyleProgramID : 0
        })

        getQualityMainInfo[i].SubTask.map((y, yindex) => {
          Qualityinfo.push({
            TNATestQualityinfoID: y.TNATestQualityinfoID === undefined ? 0 : y.TNATestQualityinfoID,
            TNAID: y.TnaID,
            SkuID: parseInt(y.SkuID),
            PurchaseOrderSkuID: parseInt(y.PurchaseOrderSkuID),
            SubTaskID: y.TnaExFactoryId,
            StyleName: y.StyleName,
            StyleNo: y.StyleNo,
            SkuName: y.SkuName,
            EndCustomer: y.EndCustomer,
            FocusPo: y.PoNo,
            POQty: y.OrderQuantity === undefined ? '' : y.OrderQuantity.toString(),
            OfferQty: (props.location.state.params.TaskType === "Strike off Test" || props.location.state.params.TaskType === "Trims Test") ? 0 : y.offerqty === '' ? 0 : parseInt(y.offerqty),
            IDNO: y.IdNo,
            Mainindex: i,
            SubIndex: yindex,
            IsDeleted: y.IsDeleted === undefined ? 0 : y.IsDeleted,
            IsInpsectionStatus: 0,
            PreviousReports: (y.PreviousReports === null || y.PreviousReports === undefined) ? '' : y.PreviousReports,
            Taskowner: (y.Taskowner === null || y.Taskowner === undefined || y.Taskowner === '') ? '' : y.Taskowner,
            IsChecked: y.ischecked
          })
        })
      })
      photos.forEach((element, index) => {
        WashTypeName.push({
          WashID: element.Id
          , WashTypeID: element.washTypeID
          , WashName: element.washName
        })
        for (var k = 0; k < element.photoDetails.length; k++) {
          WashTypePhotos.push(element.photoDetails[k]);
        }
      });
      if (props.location.state.params.type === 2) {
        getTestNameInfo.map((x) => {
          testnameexter.push({
            ID: x.ID ? x.ID : 0,
            TestID: 0,
            TestTypeID: x.TestNameID,
            TestResult: x.Testresult,
            TestName: x.TestName
          })
        })
        let fileArray = [];

        if (Array.isArray(getEXFileInfo)) {
          fileArray = getEXFileInfo;
        } else if (typeof getEXFileInfo === 'object' && getEXFileInfo.files) {
          fileArray = getEXFileInfo.files;
        } else {
          console.error('Invalid getEXFileInfo format');
        }

        Exfiles = fileArray.map(x => ({
          ID: x.ExternalFileupID || 0,
          FileName: x.FileName,
          FilePath: x.Filepath,
          FileType: x.FileType,
          TestTypeID: 0
        }));
      }

      const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));

      let testStartDate = getStyleMainInfo.TestStartDate ? new Date(getStyleMainInfo.TestStartDate) : null;
      if (testStartDate) {
        testStartDate.setMinutes(testStartDate.getMinutes() + 370);
      }

      let testEndDate = getStyleMainInfo.TestEndDate ? new Date(getStyleMainInfo.TestEndDate) : null;
      if (testEndDate) {
        testEndDate.setMinutes(testEndDate.getMinutes() + 370);
      }

      let Inputmaster = {
        Operation: props.location.state.params.page === 'AddInspection' ? 1 : 2,
        PageType: props.location.state.params.pageType === 'AddInspection' ? 1 : 2,
        TNATestInspectionID: (props.location.state.params.TaskStatus === 1 && inpsectionId === null) ? 0 : props.location.state.params.TaskStatus === 2 ? props.location.state.params.TestingInspectionID : inpsectionId,
        TNAID: props.location.state.params.details.TnaId,
        //TNAID: props.location.state.params.details.TnaId,
        PurchaseOrderID: props.location.state.params.details.purchaseOrderID,
        BuyerID: props.location.state.params.buyerID,
        SeasonID: props.location.state.params.seasonID,
        BrandID: props.location.state.params.brandID,
        StyleID: props.location.state.params.styleID,
        SupplierID: props.location.state.params.supplierID,
        IsSaveType: e === 'Draft' ? 1 : 2,
        SupplierRefNo: getStyleMainInfo.SupplierReferenceNo,
        InspectionType: props.location.state.params.page === 'AddInspection' ? 1 : 2,
        FabricLotNo: getStyleMainInfo.FabricLotNo.toString(),
        LotWeight: getStyleMainInfo.LotWeight.toString(),
        //  TestStartDate: getStyleMainInfo.TestStartDate,
        // TestEndDate: getStyleMainInfo.TestEndDate === '' ? null : getStyleMainInfo.TestEndDate,

        TestStartDate: testStartDate,
        TestEndDate: testEndDate === '' ? null : testEndDate,
        ColorDepthID: getStyleMainInfo.ColorDepth,
        WashingTypeID: getStyleMainInfo.WashingType,
        DryerTypeID: getStyleMainInfo.DryerType,
        NoofWashesID: getStyleMainInfo.NoofWashes,
        ExternalLabComment: getExlabInfo.valueOf.length !== 0 ? getExlabInfo.value.toString() : '',
        ExternalRemark: getExlabInfo ? getExlabInfo.Remarks : '',
        ExternalFileName: getStyleMainInfo ? getStyleMainInfo.StyleLogoName : '',
        ExternalFilePath: getStyleMainInfo ? getStyleMainInfo.StyleLogoPath : '',
        ExternalFileType: '',
        CategoryType: getStyleMainInfo.CategoryType === '' ? 0 : getStyleMainInfo.CategoryType,
        ReportName: props.location.state.params.TaskType,
        // TestingSKUInfomation: SkuImageInfo,
        Testingmaininfomation: Maininfo,
        Testingualityinfomation: Qualityinfo,
        TypeInternorExtern: getInspectionType,
        Isproduction: props.location.state.params.Isproduction,
        CreatedBY: currentUser.employeeinformationID,
        WashTypeNames: WashTypeName,
        WashTypePhotos: WashTypePhotos,
        TestingInfoDetails: TestingPageInfo,
        ExternalTestInfo: testnameexter,
        DeatilwashkinfoHeader: TestingDetailsHeaders,
        DetailWashAllinfo: TestingDetailsSubDetail,
        SpiralDetailWashAllinfo: TestingSpiralitySubdata,
        ExternalTestInspectionReport: (Exfiles === undefined || Exfiles == null) ? [] : Exfiles,
        AgencyName: getStyleMainInfo.Agencyname,
        ReportNo: getStyleMainInfo.ReportdateandNo,
        UOMLabellist: (TestingUOMInfo === 0 || TestingUOMInfo == null) ? [] : TestingUOMInfo,
        LabCommentID: props.location.state.params.type === 2 ? getExlabInfo.value : getLabandRemarksData.length > 0 ? getLabandRemarksData[0].value : 0,
        LabRemarks: props.location.state.params.type === 2 ? getExlabInfo.Remarks : getLabandRemarksData.length > 0 ? getLabandRemarksData[0].Remarks : "",
        DetailedWashCountInfo: (getTestingInfoAfterWash === undefined || getTestingInfoAfterWash == null) ? [] : getTestingInfoAfterWash,
        FinalInspectionIds: props.location.state.params.FinalInspectionIds !== undefined ?
          props.location.state.params.FinalInspectionIds : '',
      }

      TNAService.InsertTestingInformation(Inputmaster).then((res) => {
        if (res.data.outputResult === "1") {
          let msg = 'Testing ' + e + ' saved Successfully'
          Nodify('Success!', 'success', msg);
          let params = { tabPage: "MainInfo", Parentask: props.location.state.params.ParentTask };
          setAddStyleConfirmation({ showPopupConfirm: true, Params: params });
          localStorage.setItem('TestingInspectionID', (res.data.outputResultStyleID));
          setInspectionID(parseInt(res.data.outputResultStyleID))
          setIsLoadTestPhotos({ isLoad: 1, StyleID: res.data.outputResultStyleID });
          // let x = Math.floor((Math.random() * 100) + 1);
          // setrefershstate(x);
        }
      })
    }
  }

  const TestPhotosCallBack = (TestPhotos) => {
    setTestPhotos(TestPhotos);
  }

  return (
    <Fragment>
      <div className="page-body">
        <ReactNotification />
        {/* <div className="row">
     <div className="col-md-12">
      <div className="widget-body" style={{ paddingBottom: "0" }}>
       {

        <>
         <div className="">
          <CommonStyle props={props} Style={getStyleInfo} />
         </div>
        </>
       }
      </div>
     </div>
    </div> */}
        <br />
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="widget flat radius-bordered">
              <div className="widget-header heading_box_style">
                <h3 className="heading_style_common">
                  {
                    props.location.state.params.Mode === 'ReInspection' ?
                      <>
                        {
                          'Re Inspection'
                        } - {getInspectionType === 1 ? "Internal" : "External"}
                      </> :
                      <>
                        {
                          (props.location.state.params.pageType === 'AddInspection' && getkeyinfo === 1) ? "Edit " :
                          props.location.state.params.pageType === 'AddInspection' ? "Add " :
                            props.location.state.params.pageType === "ViewInspection" ? 'View ' :
                              'Edit '
                        }
                        {`${props.location.state.params.TaskType === 'FABRIC TEST' ? 'Fabric Test' :
                          props.location.state.params.TaskType === 'GARMENT TEST' ? 'Garment Test' : props.location.state.params.TaskType === 'YARN TEST' ? 'Yarn Test' :
                            props.location.state.params.TaskType === 'Strike off Test' ? 'Strike Off Test' : 'Trims Test'}`} - {getInspectionType === 1 ? "Internal" : "External"}
                      </>
                  }

                </h3>
                <div className="buttons-preview" align="right" style={{ marginTop: "0rem" }}>
                  <span className="btn btn-primary"
                    onClick={
                      () => ResetOperation("Back")
                    }>
                    <i className="fa fa-arrow-circle-left"></i>
                    &nbsp;Back</span>
                  <span className="btn btn-danger"
                    onClick={
                      () => ResetOperation()
                    }>
                    <i className="fa fa-refresh"></i>
                    &nbsp;Reset</span>
                  {getStyleMainInfo.IsSaveType !== 2 ? <span className="btn btn-warning" style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
                    onClick={
                      () => SaveMainInformation("Draft")
                    }

                  >
                    <i className="fa fa-rocket"></i>
                    &nbsp;Draft</span> : ''}
                  {!buttonLoader && <span className="btn btn-success" disabled={buttonLoader}
                    style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
                    onClick={
                      () => SaveMainInformation("Save")
                    }

                  >
                    <i className="fa fa-check"></i>
                    &nbsp;Save</span>}
                </div>
              </div>
              {(getkeyinfo ===1 || props.location.state.params.pageType !== "AddInspection")? <div className="row">
                <div className="col-md-12">
                  <div className="widget-body" style={{ paddingBottom: "0" }}>
                    {

                      <>
                        <div className="">
                          {/* <CommonStyle props={props} Style={getStyleInfo} /> */}
                          <CommonTestingkeyinfo props={props} Style={getInspectionInfo} />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div> : ''}
              <div className="widget-body StyleHistoryCard">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabbable">
                      <ul className="nav nav-tabs nav-justified" id="myTab5">
                        <li className="active">
                          <a data-toggle="tab" href="#idMainInformation" onClick={() => TabRedirection('Tab_MainInformation')}>
                            <h5>{`${props.location.state.params.TaskType === 'FABRIC TEST' ? 'FABRIC TEST' :
                              props.location.state.params.TaskType === 'GARMENT TEST' ? 'GARMENT TEST' : props.location.state.params.TaskType === 'YARN TEST' ? 'YARN TEST' :
                                props.location.state.params.TaskType === 'Strike off Test' ? 'STRIKEE OFF TEST' : 'TRIMS TEST'}`} & ORDER DETAILS</h5>
                          </a>
                        </li>
                        {
                          getInspectionType === 1 ?
                            <li>
                              <a data-toggle="tab" href="#idTestinginfo" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} onClick={() => TabRedirection('Tab_Testinfo')}>
                                <h5>TESTING INFORMATION</h5>
                              </a>
                            </li> : ""
                        }

                        {
                          getInspectionType === 1 ?
                            <li>
                              <a data-toggle="tab" href="#idtestphotos" style={{ pointerEvents: getInspectionID === 0 ? 'none' : '', opacity: getInspectionID === 0 ? '0.6' : '' }} onClick={() => TabRedirection('Tab-Testphotos')}>
                                <h5>TEST PHOTOS</h5>
                              </a>
                            </li> : ""
                        }
                        {
                          getInspectionType === 1 ?
                            <li>
                              <a data-toggle="tab" href="#idsummary" onClick={() => TabRedirection('Tab_Summary')}>
                                <h5>Previous Test</h5>
                              </a>
                            </li> : ""
                        }
                      </ul>
                      <div className="tab-content">
                        <div id="idMainInformation" className="tab-pane active" style={{ width: "100%" }}>
                          {(props.location.state.params.TaskType === "FABRIC TEST" || props.location.state.params.TaskType === "YARN TEST") && getCurrentTab === "Tab_MainInformation" ?
                            <OrderDetails props={props} Mainfieldscallback={Maincallback} Startdatevalidation={IsStartdatevalidtion}
                              Qualitycallback={Qualitytestcallback} IsValidationCallback={IsParentCallback}
                              rerenderdep={refershstate} TestNameDeatils={TestnameCallback} ExternalFiles={ExternalFilescallback} ExLabDetails={ExLabDetailsCallback} /> :
                            (props.location.state.params.TaskType === "Strike off Test" || props.location.state.params.TaskType === "Trims Test") && getCurrentTab === "Tab_MainInformation" ?
                              <StrikeoffTrimOrderdeatils props={props} Mainfieldscallback={Maincallback} Startdatevalidation={IsStartdatevalidtion}
                                Qualitycallback={Qualitytestcallback} IsValidationCallback={IsParentCallback}
                                rerenderdep={refershstate} TestNameDeatils={TestnameCallback} ExternalFiles={ExternalFilescallback} ExLabDetails={ExLabDetailsCallback} />
                              : <GarmanetorderDetails props={props} Mainfieldscallback={Maincallback} Startdatevalidation={IsStartdatevalidtion}
                                Qualitycallback={Qualitytestcallback} IsValidationCallback={IsParentCallback}
                                rerenderdep={refershstate} TestNameDeatils={TestnameCallback} ExternalFiles={ExternalFilescallback} ExLabDetails={ExLabDetailsCallback} />
                          }
                        </div>
                        <div id="idTestinginfo" className="tab-pane" style={{ width: "100%" }}>
                          {getCurrentTab === "Tab_Testinfo" && <TestingInformation props={props} UOMDetailsList={getUomData} labremarkDetail={labcallback} Labcomments={Islabcomments} Labremark={Islabremark} Testingdetails={IsTestingdetails}
                            TestingInfo={GetTestingInfoValues} rerenderdep={refershstate} Detailwashinfocall={GetDetailwashcallback} IsSaveFailed={getIsPhotoValidationFailed}
                            Detailwashsubcall={GetDetailwashsubcallback} SpiralDetailwashsubcall={GetSpiralDetailwashcallback} />}
                        </div>
                        <div id="idtestphotos" className="tab-pane" style={{ width: "100%" }}>
                          {getCurrentTab === "Tab-Testphotos" && <TestPhotos props={props} CallTestPhotos={TestPhotosCallBack} IsSaveFailed={getIsPhotoValidationFailed} InspectionID={getIsLoadTestPhotos} />}
                        </div>
                        <div id="idsummary" className="tab-pane" style={{ width: "100%" }}>
                          {getCurrentTab === "Tab_Summary" && <Summary />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          addStyleConfirmation.showPopupConfirm ?
            <SweetAlert
              show={addStyleConfirmation.showPopupConfirm}
              //warning
              info
              showCancel
              showConfirm={true}
              confirmBtnText={confirmBtnText}
              cancelBtnText={cancelBtnText}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="primary"
              closeOnClickOutside={true}
              inputType={'new'}
              // closeOnConfirm
              // closeOnCancel
              title={addStyleConfirmation.Params.Parentask === "TNAEdit" ? "Do you want to Redirect to TNA Edit" : "Do you want to Redirect to Testing Module"}
              onConfirm={copystyle}
              onCancel={handleClose}
              showCloseButton
              focusCancelBtn
            // style={{ backgroundColor: 'blue', color: 'white' }}
            /> : ''

        }
        <div className="buttons-preview" align="right" style={{ marginTop: "4rem" }}>
          <span className="btn btn-primary"
            onClick={
              () => ResetOperation("Back")
            }>
            <i className="fa fa-arrow-circle-left"></i>
            &nbsp;Back</span>
          <span className="btn btn-danger"
            onClick={
              () => ResetOperation()
            }>

            <i className="fa fa-refresh"></i>
            &nbsp;Reset</span>
          {getStyleMainInfo.IsSaveType !== 2 ? < span className="btn btn-warning"
            style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
            onClick={
              () => SaveMainInformation("Draft")
            }

          >
            <i className="fa fa-rocket"></i>
            &nbsp;Draft</span> : ''}
          {!buttonLoader && <span className="btn btn-success" disabled={buttonLoader}
            style={{ display: props.location.state.params.pageType === 'ViewInspection' ? 'none' : '' }}
            onClick={
              () => SaveMainInformation("Save")
            }

          >
            <i className="fa fa-check"></i>
            &nbsp;Save</span>}
        </div>
      </div>
      {loader}
    </Fragment >
  )
}

export default CreateTesting