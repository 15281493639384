import React, { useState, useEffect, useDebugValue } from "react";
import 'react-notifications-component/dist/theme.css';
import UploadImage from "../Common/UploadImage";
import Lightbox from "react-image-lightbox"

const CommonTestingkeyinfo = ({ props, Style }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    // const buyerInfo = `${Style.buyerName} - ${Style.seasonName} - ${Style.brandName}`;
    const buyerInfo = Style ? `${Style.buyerName} - ${Style.seasonName} - ${Style.brandName}` : '';

    const charLimit = 42;
    const firstLine = buyerInfo?.length > charLimit ? buyerInfo.substring(0, charLimit) : buyerInfo;
    const secondLine = buyerInfo?.length > charLimit ? buyerInfo.substring(charLimit) : '';

    return (
        <>
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>
            {Style !== undefined && (
                <div className="col-lg-12">
                    <div className="row style-row no_padding">
                        <div className="col-lg-11">
                            <div>
                                <div>


                                    <div className="style-item">
                                        <div className="title-bg">
                                            <label htmlFor="BuyerInfo"><b>Buyer Info</b></label><br />

                                            <span>{firstLine}</span>
                                            {secondLine && (
                                                <>
                                                    <br />
                                                    <span>{secondLine}</span>
                                                </>
                                            )}
                                        </div>
                                        {/* <div className="title-bg" style={{ marginLeft: "35px" }}>
                                            <label htmlFor="BuyerInfo"><b>Style Name</b></label><br />
                                            <span >{Style.styleName}</span>
                                        </div> */}

                                        <div className="title-bg" style={{ marginLeft: "40px" }} >
                                            <label htmlFor="BuyerInfo"><b>Test Entry No.</b></label><br />
                                            <span >{Style.testEntryNo}</span>

                                        </div>

                                        <>
                                            {props.location.state.params.type === 2 ? (
                                                <>
                                                    <div className="title-bg" style={{ marginLeft: "40px" }}>
                                                        <label htmlFor="BuyerInfo"><b>Agency</b></label><br />
                                                        <span>{Style.agency}</span>
                                                    </div>
                                                    <div className="title-bg" style={{ marginLeft: "40px" }}>
                                                        <label htmlFor="BuyerInfo"><b>Report No. & Date</b></label><br />
                                                        <span>{Style.reportNoDate}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                        {props.location.state.params.type === 1 ?
                                            <div className="title-bg" style={{ marginLeft: "40px" }} >
                                                <label htmlFor="BuyerInfo"><b>Test Start & End Date</b></label><br />
                                                <span >{Style.testStartandEndDate}</span>

                                            </div>
                                            : ''}
                                        {/* </>
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            
                                        </> */}

                                        <div className="title-bg" style={{ marginLeft: "40px" }}>
                                            <label htmlFor="BuyerInfo"><b>Supplier</b></label><br />
                                            <span >{props.location.state.params.supplierName}</span>
                                        </div>
                                        <div className="title-bg" style={{ marginLeft: "40px" }}>
                                            <label htmlFor="BuyerInfo"><b>Test Result</b></label><br />
                                            <b className={Style.testResult === 'Draft' ? 'warning' : Style.testResult === 'Satisfied' ? 'blue' : 'danger'}>
                                                {Style.testResult}
                                            </b>
                                        </div>

                                        <div className="title-bg" style={{ marginLeft: "40px" }} >
                                            <label htmlFor="BuyerInfo"><b>Approval Result</b></label><br />
                                            <b className={Style.approvalResult === 'Approved' ? 'success' : Style.approvalResult === 'Approved with Comments' ? 'warning' : 'danger'}>
                                                {Style.approvalResult}
                                            </b>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <img className="img-fluid"
                                src={Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath}
                                alt="Style Pic"
                                onClick={() => setIsOpen(Style.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images" + Style.styleLogoPath)}
                                style={{ height: '60px', float: "right", marginBottom: "5px" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CommonTestingkeyinfo;