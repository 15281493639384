import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import Reactselect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadQADetailsreport, UpdateCommentsreportViewAllCheckBox, UpdateCommentsreportViewCheckBox } from "../../../../actions/inspectionQC";
import { Redirect } from 'react-router-dom';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Pagination, Search, PageCount, ReactSearch } from "../../../Datatable";
import Nodify from "../../../Common/ReactNotification"
import InspectionQCService from "../../../../services/InspectionQC/InspectionQCService";
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import TableHeader from '../../../Datatable/Header/QAauditinspectionHeader';
import axios from "axios";
import $ from "jquery";
import ReportService from "../../../../services/Report/ReportService";
import Lightbox from "react-image-lightbox"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import QcInspection from "../../../../services/QCInspection/QcInspection";
import { QAReportSkuComments } from "../../../Common/PageActionNumber";
import StyleService from "../../../../services/Style/styleService";
import AddQCInspection from "../../../Production/Inspection_Quality Control/InspectionQC/AddQCinspection";
import { QAInspectionId } from "../../../Common/PageActionNumber";
import { GetSubChildMultExFactoryFormSKUList } from "../../../../actions/tna";
import SweetAlertPopup from "../../../Common/SweetAlertPopup";
import QAReInspection from "../../../Production/Inspection_Quality Control/InspectionQC/QAReinspection";
const InternalQAAuditDetails = ({ props, Taskcallback }) => {
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    const [getTNAPurchaseOrderID, setTNAPurchaseOrderID] = useState();
    const [statusType, Setstatustype] = useState(false);
    const [approvalOrInspectionList, setapprovalOrInspectionList] = useState([]);
    const [getQCfinalinspectionMulti, setQCfinalinspectionMulti] = useState([]);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [Submitted, setSubmitted] = useState(false);
    const [isInspectionStarted, setisInspectionStarted] = useState([]);
    const [InspectionAndApprovalpopup, SetinspectionAndApprovalpopup] = useState(false);
    const [selectedCommentExfactory, setSelectedCommentExfactory] = useState()
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [Commentpopup, setCommentpopup] = useState(false);
    const [Ecomment, setEcomment] = useState();
    const [getordindex, setOrdindex] = useState(0);
    const [show, setShow] = useState(false);
    const [ReportTypeList, setReportTypeList] = useState([]);
    const [getQAInspectionPageView, setQAInspectionPageView] = useState('AddInspection');
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [reportcomments, setreportcomments] = useState([])
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getFiles, setFiles] = useState([])
    const [getCmtExFactoryList, setCmtExFactoryList] = useState([])
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getReportStatussearch, setReportStatussearch] = useState("");
    const [getReportAndApprovalFilter, setReportAndApprovalFilter] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    const [getpurchaseId, setPurchaseId] = useState("0");
    const [getSupplierID, setSupplierID] = useState("0")
    const [getindex, setindex] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const EmptyInputFields = [];

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [getIDQATask, setIDQATask] = useState({ showPopupDelete: false, Params: {} });
    const Input = { ...inputFields };
    const [getQcBuyerbrandListParam, setQcBuyerbrandListParam] = useState({});
    const [getAddQCInspectionListParam, setQCAddInspectionListParam] = useState([]);
    const [getskusingleID, setskusingleID] = useState();
    const [getQCInspectionInfo, setQCInspectionInfo] = useState({
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    });
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {

            startDate: null,//defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultTNAStartDate = new Date();
    const [stateTNADate, setStateTNADate] = useState([
        {

            startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
    });

    const handleSelect = ranges => {
        setSelectedDateRange(ranges.selection);
        console.log(ranges.selection);
    };



    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    const [sizeqtylist, setsizeqtylist] = useState([]);
    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }
    const [checkboxStates, setCheckboxStates] = useState({
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        inspectionStatus: true,
        approvalStatus: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,
        PlanEndDate: true,
        Factory: true,
        FOPONo: true,
        BalQtyforNewInspection: true,
        BalQtyforReNewInspection: true

    });
    const datePickerRefEx = useRef(null);
    const datePickerRefTNA = useRef(null);
    const datePickerRefEntry = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRefEx.current && !datePickerRefEx.current.contains(event.target)) {
                setStateExDate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerRefTNA.current && !datePickerRefTNA.current.contains(event.target)) {
                setStateTNADate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerRefEntry.current && !datePickerRefEntry.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

    //  //Enable Menu
    //  let activeMenu;
    //  const menulist = useSelector((state) => state.common.MenuList.item3);
    //  if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'InternalQAAudit'); }
    //  // End Enable Menu

      //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let InternalComments;
    let info;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        info = IsProduction === 1 ? 'InternalQAAudit' : (IsProduction === 2 ? 'InternalQAAudit' : undefined);
        activeMenu = menulist.filter(i => i.menuName ===  info );
        
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    InternalComments = activeMenuSub[index];
                }
            }
        }
        
    }
    // End Enable Menu


    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.inspectionQC.QADetailsReportList.item1 !== undefined ? reducerState.inspectionQC.QADetailsReportList.item1 : reducerState.inspectionQC.QADetailsReportList;
    if (comments.length > 0) {
        for (let index = 0; index < comments.length; index++) {
            let Parent = comments[index];
            for (let i = 0; i < reducerState.inspectionQC.QADetailsReportList.item2.length; i++) {
                let Child = reducerState.inspectionQC.QADetailsReportList.item2[i];
                if (Child.purchaseOrderID === Parent.purchaseOrderID && Child.indexName === Parent.indexName) {
                    Parent.styleID = Child.styleID; Parent.styleName = Child.styleName; Parent.styleNo = Child.styleNo; Parent.categoryName = Child.categoryName;
                    Parent.buyerEmployee = Child.buyerEmployee; Parent.cubeEmployee = Child.cubeEmployee;
                }
            }
        }
    }
    let isLoadingInspectionQCAllDetails = reducerState.inspectionQC.isLoadingQADetailsReport;


    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];


    window.addEventListener('beforeunload', function () {
        // Clear localStorage

        localStorage.removeItem("ProdTNAStartDate");
        localStorage.removeItem("ProdTNAEndDate");
    });


    useEffect(() => {
        if (!isLoadingInspectionQCAllDetails) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingInspectionQCAllDetails]);


    useEffect(() => {
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        const ProTNAStartdate = localStorage.getItem("ProdTNAStartDate")
        const ProTNAEnddate = localStorage.getItem("ProdTNAEndDate")
        stateTNADate[0].startDate = ProTNAStartdate === null ? new Date(stateTNADate[0].startDate) : new Date(ProTNAStartdate);
        stateTNADate[0].endDate = ProTNAEnddate === null ? new Date() : new Date(ProTNAEnddate);
        setStateTNADate(stateTNADate)

        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));
        console.log(params);



    }, []);

    const resetStyle = () => {

        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');
        setReportStatussearch('');
        localStorage.removeItem("ProdTNAStartDate");
        localStorage.removeItem("ProdTNAEndDate");

        if (props.location.state && props.location.state.params.localStorage === true) {
            props.location.state.params.localStorage = false;
        }
        localStorage.removeItem("PodateExStatedate");
        localStorage.removeItem("PodateExenddate");
        //setStateExDate(emptyStateExDate);
        stateTNADate[0].endDate = new Date();
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {

                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]



        setStateTNADate(emptyStateDate)
        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);

        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            TNAStartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            TNAEndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
    }
    function QCFinalInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }
    function FinalInspectionCallback(value) {

    }
    function pageRedirectForQAInspection(value) {
        setQAInspectionPageView(value);
    }
    function handleCloseQAInspecViewQA(index, subIndex, childIndex) {
        Input.Finalview = 0;
        setInputFields(Input);
    }
    function handleDeleteQAinspection(item) {
        let PackingParams = {
            Operation: 110,
            Id: item.tnaqcInspectionID,
        }
        setIDQATask({ showPopupDelete: true, Params: PackingParams });
    }
    function handleQAInspecViewDetails(item, type) {

        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID)).map((x) => {
            x.isSelected = 1;
        })
        getQCInspectionInfo.buyerName = getQcBuyerbrandListParam.buyerName;
        getQCInspectionInfo.buyerID = getQcBuyerbrandListParam.buyerID;
        getQCInspectionInfo.brandID = getQcBuyerbrandListParam.brandID;
        getQCInspectionInfo.brandName = getQcBuyerbrandListParam.brandName;
        getQCInspectionInfo.seasonID = getQcBuyerbrandListParam.seasonID;
        getQCInspectionInfo.seasonName = getQcBuyerbrandListParam.seasonName;
        getQCInspectionInfo.supplierId = getTNAPurchaseOrderID.supplierID;
        getQCInspectionInfo.supplierName = getTNAPurchaseOrderID.supplierName;
        getQCInspectionInfo.styleID = getQcBuyerbrandListParam.styleID;
        getQCInspectionInfo.styleName = getTNAPurchaseOrderID.styleName;
        getQCInspectionInfo.styleNo = getTNAPurchaseOrderID.styleNo
        getQCInspectionInfo.TNAQCInspectionID = item.tnaqcInspectionID;
        getQCInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
        // getQCInspectionInfo.details = { tnaid: getTNAPurchaseOrderID.tnaid, supplierId: getTNAPurchaseOrderID.supplierID, purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID, idPoNos: getTNAPurchaseOrderID.idpono };
        // getQCInspectionInfo.TnaID = getTNAPurchaseOrderID.tnaid;
        //
        getQCInspectionInfo.details = { tnaid: item.tnaId, supplierId: getTNAPurchaseOrderID.supplierID, purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID, idPoNos: getTNAPurchaseOrderID.idpono };
        getQCInspectionInfo.TnaID = item.tnaId;
        getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getQCInspectionInfo.Isproduction = IsProduction;
        getQCInspectionInfo.Action = type;
        getQCInspectionInfo.Mode = "AddInspection";
        getQCInspectionInfo.IsAddorEditReinspection = item.inspectionType === 1 ? type + ' Inspection' : type + ' ReInspection';
        getQCInspectionInfo.ParentTask = "QA Internal Audit";
        getQCInspectionInfo.PageName = "Inspection Details";
        props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
    }
    async function AddQCFactoryForm(params) {

        let isvalid = true;
        isvalid = !!getQCfinalinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {

            let isvalid = false;
            await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, getQcBuyerbrandListParam.buyerID, getQcBuyerbrandListParam.brandID).then((res) => {
                if (res.data[0] !== undefined) {
                    if (res.data[0].offerQty) {
                        isvalid = true
                    }
                } else {
                    Nodify('Warning!', 'warning', 'Please enter tolerance in master for this buyer')
                }
            })
            if (isvalid) {
                let sizeandqtyfordisplay = "";
                let NewArr = [];
                NewArr = getQCfinalinspectionMulti.filter(x => x.isSelected === 1)
                NewArr.map((Purchaseorder) => {
                    if (sizeandqtyfordisplay === '') {
                        sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                    } else {
                        sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                    }
                })
                getQCInspectionInfo.buyerName = getQcBuyerbrandListParam.buyerName;
                getQCInspectionInfo.buyerID = getQcBuyerbrandListParam.buyerID;
                getQCInspectionInfo.brandID = getQcBuyerbrandListParam.brandID;
                getQCInspectionInfo.brandName = getQcBuyerbrandListParam.brandName;
                getQCInspectionInfo.seasonID = getQcBuyerbrandListParam.seasonID;
                getQCInspectionInfo.seasonName = getQcBuyerbrandListParam.seasonName;
                getQCInspectionInfo.supplierID = getTNAPurchaseOrderID.supplierID;
                getQCInspectionInfo.supplierName = getTNAPurchaseOrderID.supplierName;
                getQCInspectionInfo.styleID = getQcBuyerbrandListParam.styleID;
                getQCInspectionInfo.styleName = getTNAPurchaseOrderID.styleName;
                getQCInspectionInfo.styleNo = getTNAPurchaseOrderID.styleNo
                getQCInspectionInfo.multiselecteddatas = getQCfinalinspectionMulti;
                getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
                getQCInspectionInfo.details = { tnaid: getTNAPurchaseOrderID.tnaid, supplierId: getTNAPurchaseOrderID.supplierID, purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID, idPoNos: getTNAPurchaseOrderID.idpono };
                getQCInspectionInfo.TnaID = getTNAPurchaseOrderID.tnaid;
                getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
                getQCInspectionInfo.Isproduction = IsProduction;
                getQCInspectionInfo.Action = 'Add';
                getQCInspectionInfo.IsAddorEditReinspection = getQAInspectionPageView === 'AddInspection' ? 'Add Inspection' : 'ADD ReInspection';
                getQCInspectionInfo.Mode = getQAInspectionPageView;
                getQCInspectionInfo.ParentTask = "QA Internal Audit";
                props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
            }
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }
    }
    function handleCloseQAInspecView(index, subIndex, childIndex) {
        Input.IsQCinsectionFactory = 0;
        setInputFields(Input);
    }

    let Size = "120px"
    const tableHeader = [
        { name: "#", field: "", sortable: false, widthsize: '2%', left: 0, padding: "30px !important" },
        checkboxStates.PlanEndDate && ({ name: "Plan End Date", field: "planEndDate", sortable: true, widthsize: Size, left: 0, padding: "30px !important" }),
        checkboxStates.skuID && ({ name: "PO SKU ID", field: "purchaseOrderSkuID", sortable: true, widthsize: Size }),
        checkboxStates.styleName && ({ name: "Style Name", field: "styleName", sortable: true, widthsize: Size, }),
        checkboxStates.skuName && ({ name: "SKU Name", field: "skuName", sortable: true, width: "5%", widthsize: Size, left: "736px" }),
        checkboxStates.idNo && ({ name: "PO/ID No", field: "idpono", sortable: true, widthsize: Size, }),
        checkboxStates.FOPONo && ({ name: "Focus PO No", field: "foidpono", sortable: true, widthsize: Size, }),
        checkboxStates.skuImg && ({ name: "SKU Image", field: "skuImage", sortable: true, width: "4%", widthsize: Size, left: "858px" }),
        checkboxStates.supplier && ({ name: "Supplier", field: "supplierName", sortable: true, widthsize: Size, }),
        // checkboxStates.Factory && ({name: "Factory",field: "factory",sortable: true,width: "4%",}),
        //checkboxStates.styleNo && ({name: "Style No",field: "styleNo",sortable: true,width: "6%",}),
        checkboxStates.orderQty && ({ name: "Order Qty", field: "quantity", sortable: true, widthsize: Size, }),
        checkboxStates.exFac && ({ name: "Ex-Fac Date", field: "exFacDate", sortable: true, widthsize: Size, }),
        checkboxStates.inspectionStatus && ({ name: "Report Status", field: "reportStatus", className: "text-center", sortable: false, widthsize: Size, minWidth: "150px" }),
        checkboxStates.approvalStatus && ({ name: (Taskcallback !== 'PP Meeting' && Taskcallback !== 'In and Mid Line Inspection') ? "Approval Status" : 'Review Status', field: "reviewStatus", className: "text-center", sortable: false, widthsize: Size, minWidth: "200px" }),


        // checkboxStates.orderStatus && (  {      name: "Order Status",      field: "orderStatus",      sortable: true,      width: "5%",  }
        // checkboxStates.stsCreated && (  {      name: "	Status Created By",      field: "statusCreatedBy",      sortable: true,      width: "2%",      minWidth: "160px"  }

        // checkboxStates.currentStatus && (    {        name: "Current Status Comment",        field: "currentComments",        sortable: true,        width: "7%",        minWidth: "210px"    }),
        // checkboxStates.cmtCreated && (    {        name: "Comment Created By",        field: "commentsCreatedBy",        sortable: true,        width: "3%",        minWidth: "185px"    }),
        { name: "Action", field: "Action", className: "text-center fixed-column-header-Route", sortable: false, widthsize: Size ,display:  InternalComments && InternalComments.isAdd === 0 ? 'none' : ''}];
    let InspectionandApproval = [
        {
            value: 1,
            label: 'Report Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'Report Progressing',
            selected: false,

        },
        {
            value: 3,
            label: 'Report Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Report Under Review',
            selected: false,

        },
        {
            value: 5,
            label: 'Report Review Completed',
            selected: false,

        },
        , {
            value: 6,
            label: 'Not Start Report for SKU ID',
            selected: false,

        }, {
            value: 7,
            label: 'Report Started, but it in draft',
            selected: false,

        }, {
            value: 8,
            label: 'Report Saved',
            selected: false,
        }
        , {
            value: 9,
            label: 'Report Saved, but approval pending',
            selected: false,

        }
        , {
            value: 10,
            label: 'Report Approval Result is Approved',
            selected: false,

        }
    ]
    const GetSizeandQty = (PurchaseOrderSkuID, orderQty) => {
        setskuid('');
        ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
            if (response.data) {
                setsizeqtylist(response.data)
            }

        }).catch(() => { });
        setsizeqtypopup(true);
        setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }

    const handleAllOnSelect = (event, FieldName, page, total) => {

        let Pagecount = parseInt(page);
        if (event.target.checked) {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = true;
                })
                setIsAllSelected(true);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(true));

        } else {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = false;
                })
                setIsAllSelected(false);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(false));
        }
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateTNADate = [...stateTNADate];
        getStateDate[0].isShow = isShow;
        getStateExDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
    }
    const handleclick = () => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    function DateTNAchange(item) {

        stateTNADate[0].startDate = item[0].startDate;
        stateTNADate[0].endDate = item[0].endDate;
        setStateTNADate(item)
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        localStorage.setItem('ProdTNAStartDate', moment(stateTNADate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('ProdTNAEndDate', moment(stateTNADate[0].endDate).format('MM/DD/YYYY'))
        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));

    }
    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]

    const handleOnSelect = (event, FieldName, purchaseOrderSkuID) => {
        let isChecked = false;
        let indexes = comments.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));
    }
    async function handleExFactoryFormOpen(comment, newss, Taskid) {
        debugger
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        setTNAPurchaseOrderID(comment)
        await StyleService.GetStyleList(comment.styleID, IsProduction, 0, '01/01/1949', formattedDate).then((response) => {
            if (response.data) {
                setQcBuyerbrandListParam(response.data.item1[0])
            }
            let params = {
                Operation: 2,
                BuyerId: comment.buyerID, BrandId: comment.brandID, SeasonId: comment.seasonID,
                SupplierId: comment.supplierID, TaskId: Taskid, StyleId: comment.styleID,
                SkuId: 0, TaskOwnerIdList: comment.taskOwner, TaskHolderId: 0,
                tnaExFactoryId: comment.tnaExFactoryId, status: 1, selectedDependency: '', NewID: comment.skuSubID,
                Isproduction: IsProduction, TnaId: comment.tnaid,
            };
            setQCAddInspectionListParam(params);
            setskusingleID(comment.purchaseOrderSkuID)
            Input.IsQCinsectionFactory = 1;
            getQCInspectionInfo.TaskStatus = 1;
            getQCInspectionInfo.TaskType = Taskcallback;//is for Midline or pilotrun or set sizecheck
            setInputFields(Input);
            setQCInspectionInfo(getQCInspectionInfo);
        }).catch(() => { });
    }
    async function handleFinalInspecViewOpen(comment, Isfinal, TNAExFactoryId) {
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        showLoader();
        setTNAPurchaseOrderID(comment)
        Input.Finalview = Isfinal;
        // Input.Finalview = 0
        setInputFields(Input);
        await StyleService.GetStyleList(comment.styleID, IsProduction, 0, '01/01/1949', formattedDate).then((response) => {
            if (response.data) {
                setQcBuyerbrandListParam(response.data.item1[0])
            }
        }).catch(() => { });
        await QcInspection.GetTNAExfacQAReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues);
            hideLoader();
        })
        getQCInspectionInfo.TaskStatus = 2;
        getQCInspectionInfo.TaskType = Taskcallback;
        setQCInspectionInfo(getQCInspectionInfo);

        let params = {
            Operation: 2,
            BuyerId: comment.buyerID,
            BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID, TaskId: comment.taskDetailsID,
            StyleId: comment.styleID,
            SkuId: 0,
            TaskOwnerIdList: comment.taskOwner,
            TaskHolderId: 0,
            tnaExFactoryId: comment.tnaExFactoryId,
            status: 0,
            selectedDependency: '',
            //NewID is subID
            NewID: comment.skuSubID,
            Isproduction: IsProduction
        };
        setQCAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));
    }

    function DateExfaconchange(item) {
        stateExDate[0].startDate = item[0].startDate;
        stateExDate[0].endDate = item[0].endDate
        setStateExDate(item)
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
        }
        dispatch(LoadQADetailsreport(params));
    }

    const handleShow = () => setShow(true);

    const clearExDateSelect = () => {
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        setStateExDate([{ startDate: null, endDate: null, isShow: false }]);
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));
    }

    const handleTNADateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateTNADate = [...stateTNADate];
        let getStateExDate = [...stateExDate];
        getStateTNADate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateExDate[0].isShow = false;
        localStorage.setItem('ProdTNAStartDate', moment(getStateTNADate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('ProdTNAEndDate', moment(getStateTNADate[0].endDate).format('MM/DD/YYYY'))
        setStateDate(getStateDate);
        setStateTNADate(getStateTNADate);
    }

    const handleChangeOrdStatus = (value, field) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
        if (field == 'ReportApprovalFilter' && value != null) {
            setReportAndApprovalFilter(value);
            setReportStatussearch(value);
        }
        else {
            setReportStatussearch('')
            setReportAndApprovalFilter(null);
            setOrderStatusSearch(value);
        }
        //setInspectionAndApprovalSearch(value);

    }

    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateTNADate = [...stateTNADate];
        getStateExDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateDate(getStateDate);
        setStateExDate(getStateExDate);
    }
    const clearTNADateSelect = () => {
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        setStateTNADate([{ startDate: null, endDate: null, isShow: false }]);
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));
    }
    function delCallback(value) {
        if (value === true) {
            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();

            // Create a moment object from the current date and add one year
            const futureDate = moment(currentDate).add(1, 'year');

            // Format the future date
            const formattedDate = futureDate.format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction,
                TaskDetailsName: Taskcallback
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
            }
            dispatch(LoadQADetailsreport(params));
            setCommentpopup(false);
        } else {
            setCommentpopup(false);
        }
    }
    function delQaCallback(value) {
        if (value === true) {

            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();

            // Create a moment object from the current date and add one year
            const futureDate = moment(currentDate).add(1, 'year');

            // Format the future date
            const formattedDate = futureDate.format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction,
                TaskDetailsName: Taskcallback
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
            }
            dispatch(LoadQADetailsreport(params));
            Input.Finalview = 0;
            setInputFields(Input);
        } else {
            setIDQATask({ showPopupDelete: false, Params: [] });
        }
    }
    const clearDateSelect = () => {

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(LoadQADetailsreport(params));
    }


    const commentsData = useMemo(() => {

        //let computedComments = comments;
        let computedComments = comments.filter(o => 
            BuyerBrandDetails.some(({  brandId, isAllInfo, isMapped }) =>
              o.brandID === brandId && isAllInfo === 1 && isMapped === 0
            )
          );
        let drpvalue = orderStatus;

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                );
            }
            else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }

            setTotalItems(drpvalue && drpvalue.length);
        }

        if (getReportAndApprovalFilter) {
            if (getReportAndApprovalFilter.value === 1 || getReportAndApprovalFilter.value === 6) {//yet to start, not start report for SKUID
                computedComments = computedComments.filter((comment) => {
                    if (comment.draftCount == 0 && comment.passCount == 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 2) {//report progressing
                computedComments = computedComments.filter((comment) => {
                    if (comment.draftCount > 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 3) {//report completed
                computedComments = computedComments.filter((comment) => {
                    if (comment.passCount > 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 4) {//report under review
                computedComments = computedComments.filter((comment) => {
                    if (comment.pendingCount > 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 5) {//report review completed
                computedComments = computedComments.filter((comment) => {
                    if (comment.approvalCount > 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 7) {//report started but it is in draft
                computedComments = computedComments.filter((comment) => {
                    if (comment.orderStatus == 2 && comment.draftCount > 0) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 8 || getReportAndApprovalFilter.value === 9) {//report saved || report approval result is approved
                computedComments = computedComments.filter((comment) => {
                    if (comment.approvalRemarks != "" && comment.approvalRemarks != null) {
                        return comment;
                    }
                });
            }
            else if (getReportAndApprovalFilter.value === 9) {//report saved, but approval pending
                computedComments = computedComments.filter((comment) => {
                    if (comment.approvalRemarks == "" || comment.approvalRemarks == null) {
                        return comment;
                    }
                });
            }
        }

        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        // if (getIdPosearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.fpidpono.toLowerCase().includes(getIdPosearch.toLowerCase())
        //     );

        //     setTotalItems(computedComments.length);
        // }
        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        // if (stateDate[0].startDate && stateDate[0].endDate) {
        //     computedComments = computedComments.filter(comment =>
        //         new Date(moment(comment.poDate, format)) >= stateDate[0].startDate && new Date(moment(comment.poDate, format)) <= stateDate[0].endDate
        //     )
        // }
        setTotalItems(computedComments.length);
        let Exformat = "DD/MM/YYYY";
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = new Date(moment(stateExDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = new Date(moment(stateExDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (stateExDate[0].startDate && stateExDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.exFacDate, Exformat)) >= stateExDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateExDate[0].endDate
            )
        }
        setTotalItems(computedComments.length);

        //sorting table
        // if (sorting.field) {
        //     computedComments.map((x) => {
        //         x.purchaseOrderSkuID = x.purchaseOrderSkuID.toString();
        //     })
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        // }

        // if (sorting.field) {
        //       const reversed = sorting.order === 'asc' ? 1 : -1;
        //       computedComments = computedComments.sort((a, b) => {
        //         const valueA = a[sorting.field];
        //         const valueB = b[sorting.field];

        //         // Check if both values are numeric
        //         const isNumericA = !isNaN(valueA);
        //         const isNumericB = !isNaN(valueB);

        //         // Check if both values are valid date-time strings using moment
        //         const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
        //         const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();

        //         if (isNumericA && isNumericB) {
        //           // If both values are numeric, compare as numbers
        //           return reversed * (parseFloat(valueA) - parseFloat(valueB));
        //         } else if (isDateTimeA && isDateTimeB) {
        //           // If both values are valid date-time strings, compare as dates
        //           return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
        //         } else {
        //           // If one or both values are non-numeric and non-valid date-time strings, compare as strings
        //           return reversed * valueA.toString().localeCompare(valueB.toString());
        //         }
        //       });
        //     }
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
                const valueA = a[sorting.field];
                const valueB = b[sorting.field];

                // Check if both values are numeric
                const isNumericA = !isNaN(valueA);
                const isNumericB = !isNaN(valueB);

                // Check if both values are dates with time
                const isDateTimeA = !isNaN(Date.parse(valueA));
                const isDateTimeB = !isNaN(Date.parse(valueB));

                if (isNumericA && isNumericB) {
                    // If both values are numeric, compare as numbers
                    return reversed * (parseFloat(valueA) - parseFloat(valueB));
                } else if (isDateTimeA && isDateTimeB) {
                    // If both values are dates with time, compare based on the month
                    const parseDate = (dateString) => {
                        const [day, month, year] = dateString.split('/');
                        return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
                    };

                    const dateA = parseDate(valueA);
                    const dateB = parseDate(valueB);

                    return reversed * (dateA - dateB);
                }

                else {
                    // If one or both values are non-numeric and non-date with time, compare as strings
                    return reversed * valueA.toString().localeCompare(valueB.toString());
                }
            });
        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            comments,
            currentPage,
            // search,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateExDate,
            sorting,
            ITEMS_PER_PAGE,
            getReportAndApprovalFilter,
        ]);
    function DateRangechange(item) {

        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(stateDate)

        const updatedStateDate = [
            {
                ...stateDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateDate(updatedStateDate);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        localStorage.setItem('PodateStatedate', moment(stateDate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('Podateenddate', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
        let params = {
            IsProduction: IsProduction
            , PoStartDate: StartDate
            , PoEndDate: EndDate
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate

        }
      // dispatch(LoadQADetailsreport(params));
    }

    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {
            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }

        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }

        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            setIsProductionStatussearch(inputText);
        }


        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        }
    };

    const GetComments = async (supplierID, PurchaseOrderSkuID, Feildname) => {
        showLoader();
        let Exfaclist = [];
        setindex(0);
        setSubmitted(false)
        setPurchaseId(PurchaseOrderSkuID);
        setSupplierID(supplierID);
        setCommentpopup(true)
        let isChecked = false;
        let indexes = comments.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
        setindex(indexes);
        if (Feildname === 'Comments') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));

        await InspectionQCService.GetQAPurchaseOrderSkuReportComments(PurchaseOrderSkuID, Taskcallback).then(async (response) => {

            if (await response.data) {
                setreportcomments(await response.data.flReportCommentDetails)
                setFiles(await response.data.flReportfileupload)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
        await FinalinspectionService.GetFLCommentsReportExFactory(supplierID, 1, 0).then((res) => {

            if (res.data) {
                res.data.flexFacCommon.map((x, index) => {
                    Exfaclist.push({
                        value: x.value,
                        label: x.label
                    })
                })
                setCmtExFactoryList(Exfaclist);
            }
            hideLoader();
        })
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    const filevals = [...getFiles]
    const AppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "ReportStatus");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    let Index = comments.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
                    filevals.push({
                        reportManualFileId: 0,
                        fileName: res.data + "_" + file.name,
                        filePath: "Images/Report/" + file.name,
                        fileType: file.type,
                        reportIndexName: Index.toString(),
                        reportFileIndexName: getFiles.length.toString(),
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    // let Split = getFiles[0].FileName.split("_");
                    // getFiles[0].FileName = Split[1];
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    function handleCmtEXFactoryChanges(value) {

        setSelectedCommentExfactory(value)
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };

    function GetInspectionAndApprovalStatus(PurchaseOrderSkuIDOrInspectionID, statusType) {
        setskuid('');
        showLoader();
        setisInspectionStarted(0);
        InspectionQCService.GetInspectionandApprovalList(statusType, PurchaseOrderSkuIDOrInspectionID, Taskcallback).then((response) => {
            if (response.data && ((statusType === 1 || statusType === 2) && response.data.inspectionInfo.length > 0)) {
                setisInspectionStarted(1);
                setapprovalOrInspectionList(response.data.inspectionInfo);
            } else if (response.data && ((statusType == 3 || statusType === 4) && response.data.approvalInfo.length > 0)) {
                setisInspectionStarted(1);
                setapprovalOrInspectionList(response.data.approvalInfo);
            }
            else {
                setisInspectionStarted(0);
            }
            hideLoader();
        }).catch(() => {
            hideLoader();
        });
        setskuid({ skuID: PurchaseOrderSkuIDOrInspectionID, qrty: 0 })
        Setstatustype(statusType);
        SetinspectionAndApprovalpopup(true);
    }
    const handleClose = () => setShow(false);

    const handleFullClose = (Feildname, comment) => {
        if (selectedCommentExfactory) {
            selectedCommentExfactory.value = 0;
            selectedCommentExfactory.label = '';
            setSelectedCommentExfactory(selectedCommentExfactory);
        }
        let isChecked = false;
        if (Feildname === 'reportcmt') {
            isChecked = false;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: getindex
        };
        if (comment === undefined || comment === '') {
            setSubmitted(false);
        }
        if (getFiles.reportManualFileId === 0) {
            setFiles([]);
        }
        dispatch(UpdateCommentsreportViewCheckBox(params));
        setCommentpopup(false);
        setreportcomments([]);
        setEcomment('');


    }
    function RemoveComments(Commentitem) {
        let Param = {
            Operation: QAReportSkuComments,
            Id: Commentitem.commentsID,
        };
        setID({ showPopupDelete: true, Params: Param });
    }
    const handleSaveComment = (e, comment) => {
        if (comment === undefined || comment === '' || selectedCommentExfactory === undefined || selectedCommentExfactory.value === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {

            // var Data = [];
            var CommentInformationData = [];
            var ManualFileInformationData = [];
            let len = 0;
            //const PurchaseOrderSkuIDstatus = parseInt(getpurchaseId);
            let Selecteditems = commentsData.filter(x => x.supplierID === getSupplierID && x.isSelected === true)
            Selecteditems.map((item, index) => {
                if (item && item !== 0) {
                    len = len + 1;
                    CommentInformationData.push({
                        CommentIndex: len,
                        PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
                        CommentDetail: Ecomment,
                        IsStatus: 3,
                        SupplierInfo: selectedCommentExfactory.value,
                        Supplierfactory: selectedCommentExfactory.label

                    })
                    getFiles.filter(x => x.reportManualFileId === 0).map((x, indexs) => {
                        ManualFileInformationData.push({
                            ReportManualFileId: x.reportManualFileId,
                            PurchaseOrderSkuID: parseInt(item.purchaseOrderSkuID),
                            FileType: x.fileType,
                            FileName: x.fileName,
                            FilePath: x.filePath,
                            ReportIndexName: x.reportIndexName,
                            ReportFileIndexName: x.reportFileIndexName,
                            IsDeleted: x.IsDeleted
                        })
                    })
                }
            })

            var Data = { CreatedBy: currentUser.employeeinformationID, userLat: '0', userLong: '0', TaskType: Taskcallback, CommentInformation: CommentInformationData, ReportManualFileInformation: ManualFileInformationData };

            InspectionQCService.InsertUpdateQAreportComment(Data).then((res) => {
                if (res.data.outputResult === "1") {
                    //let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID);
                    //Nodify('Success!', 'success', "Comment Added for " + Selecteditems[0].supplierName + " Successfully");
                    Nodify('Success!', 'success', "Comment Added for Successfully");
                    setSubmitted(false);
                    setCommentpopup(false);
                    setEcomment('');
                    let params = {
                        isChecked: false, index: getindex
                    };
                    dispatch(UpdateCommentsreportViewCheckBox(params));
                    setIsAllSelected(false);
                    dispatch(UpdateCommentsreportViewAllCheckBox(false));
                    //let param = { Operation: 1, IsProduction: 1 }
                    let TNAStartDate = null;
                    let TNAEndDate = null;
                    if (moment(stateTNADate[0].startDate).isValid()) {
                        TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateTNADate[0].endDate).isValid()) {
                        TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    const currentDate = new Date();

                    // Create a moment object from the current date and add one year
                    const futureDate = moment(currentDate).add(1, 'year');

                    // Format the future date
                    const formattedDate = futureDate.format('MM/DD/YYYY');
                    let param = {
                        IsProduction: IsProduction,
                        TaskDetailsName: Taskcallback
                        , ExfacStartDate: '01/01/1970'
                        , ExfacEndDate: formattedDate
                        , TNAStartDate: TNAStartDate
                        , TNAEndDate: TNAEndDate
                    }
                    dispatch(LoadQADetailsreport(param));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

        //setEditedCommentIndex('');
    };
    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setEcomment(inputtext);

    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }
    const RemoveImages = async (index, files, ReportManualFileId) => {

        if (ReportManualFileId !== 0) {
            getFiles[index].IsDeleted = 1;
        } else {
            filevals.splice(index, 1);
        }
        setFiles(filevals);
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    return (
        <div className="page-body" style={{ background: "none" }}>
            <div class="row">
                <div class="col-xs-12 col-md-12" >
                    <div className="widget">
                        <div className="widget-body" style={{ boxShadow: "none", padding: "4px" }}>
                            <div className="widget">
                                <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="widget">
                                                <div className="widget-body no-padding">
                                                    <div className="widget-main ">
                                                        <div className="panel-group accordion" id="accordion">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading ">
                                                                    <h4 className="panel-title">
                                                                        <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                            Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOne" className="panel-collapse collapse in">
                                                                    <div className="panel-body border-red">
                                                                        <div className="row">
                                                                            <div className="col-md-12">

                                                                                <div className="col-md-2">
                                                                                    <label>Buyer</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Buyer'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Buyer'}
                                                                                            onChange={e => handleChange(e, 'Buyer')}
                                                                                            value={getBuyersearch}
                                                                                        />

                                                                                    </span>
                                                                                </div>


                                                                                <div className="col-md-2">
                                                                                    <label>Season</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Season'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Season'}
                                                                                            onChange={e => handleChange(e, 'Season')}
                                                                                            value={getSeasonsearch}
                                                                                        />

                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Brand</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Brand'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Brand'}
                                                                                            onChange={e => handleChange(e, 'Brand')}
                                                                                            value={getBrandsearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Quarter</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Quarter'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Quarter'}
                                                                                            onChange={e => handleChange(e, 'Quarter')}
                                                                                            value={getQuartersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <label>Supplier</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Supplier'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Supplier'}
                                                                                            onChange={e => handleChange(e, 'Supplier')}
                                                                                            value={getSuppliersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>PO/ID No.</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'PO/ID No'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'PO/ID No'}
                                                                                            onChange={e => handleChange(e, 'ID/NO')}
                                                                                            value={getIdPosearch}
                                                                                        />

                                                                                    </span>

                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="row">

                                                                            <div className="col-md-12">
                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>SKU Name</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'SKU Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'SKU Name'}
                                                                                            onChange={e => handleChange(e, 'SKUName')}
                                                                                            value={getSkuSearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>StyleName</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Style Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Style Name'}
                                                                                            onChange={e => handleChange(e, 'StyleName')}
                                                                                            value={getStyleNamesearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>

                                                                                <div className="col-md-2" >
                                                                                    <label>&nbsp;</label>
                                                                                    <label>Factory</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Factory'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Factory'}
                                                                                            onChange={e => handleChange(e, 'Factory')}
                                                                                            value={getFactorySearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2" ref={datePickerRefEx}>
                                                                                    <label>Ex-Fac Date</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            value={(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                                                    moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                            onClick={event => handleExDateSelect(event, !stateExDate[0].isShow)}
                                                                                            //onBlur={event => handleDateBlur()}
                                                                                            type="text"
                                                                                            className={'form-control adjustabledatapicker'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times" onClick={clearExDateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateExDate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                //onChange={item => setStateExDate([item.selection])}
                                                                                                onChange={item => DateExfaconchange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateExDate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker'}

                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2" ref={datePickerRefEntry}>
                                                                                    <label>Report Entry Date Filter</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                    moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                            type="text"
                                                                                            className={'form-control adjustabledatapicker'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateDate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                // onChange={item => setStateDate([item.selection])}
                                                                                                onChange={item => DateRangechange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateDate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker'}

                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Status</label>
                                                                                    <span className='input-icon icon-right' onClick={handleclick}>
                                                                                        <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                            options={orderStatus}
                                                                                            isClearable={true}
                                                                                            onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}
                                                                                            value={getOrderStatussearch}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="col-md-2">
                                                                                    <label>Report and Approval Filter</label>
                                                                                    <span className='input-icon icon-right' onClick={handleclick}>
                                                                                        <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                            options={InspectionandApproval}
                                                                                            isClearable={true}
                                                                                            onChange={e => handleChangeOrdStatus(e, 'ReportApprovalFilter')}

                                                                                            value={getReportStatussearch}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2"  ref={datePickerRefTNA}>
                                                                                    <label>TNA Date Range</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            value={(stateTNADate[0].startDate === null ? '' : moment(stateTNADate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                ((stateTNADate[0].endDate === null || !moment(stateTNADate[0].endDate).isValid()) ? '' :
                                                                                                    moment(stateTNADate[0].endDate).format('DD/MM/YYYY'))}
                                                                                            onClick={event => handleTNADateSelect(event, !stateTNADate[0].isShow)}
                                                                                            //onBlur={event => handleDateBlur()}
                                                                                            type="text"
                                                                                            className={'form-control news adjustabledatapicker'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times" onClick={clearTNADateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateTNADate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                // onChange={item => setStateExDate([item.selection])}
                                                                                                onChange={item => DateTNAchange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateTNADate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker'}


                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                {/* <div className="col-md-3">
                                                                            </div> */}
                                                                                <div className="col-md-2s">
                                                                                    <div className="col-md-1 margin-top-30">
                                                                                        <span className="input-icon icon-right">
                                                                                            <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                            >
                                                                                                <i className="fa fa-close"> Reset</i>
                                                                                            </button>
                                                                                        </span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    &nbsp;
                                                                </div>
                                                            </div>
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header>
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                    //</div>onClick={() => CheckBoxHandler(1)}
                                                    >
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="planEndDate"
                                                            checked={checkboxStates.PlanEndDate}
                                                            disabled
                                                        />
                                                        &nbsp;<label htmlFor="podate">PO Date</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                    //</div>onClick={() => CheckBoxHandler(21)}
                                                    >
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="purchaseOrderSkuID"
                                                            checked={checkboxStates.skuID}
                                                            disabled
                                                        />
                                                        &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                    </li>
                                                </div>

                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="supplier"
                                                            checked={checkboxStates.supplier}
                                                        />
                                                        &nbsp;<label htmlFor="supplier">Supplier </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="idNo"
                                                            checked={checkboxStates.idNo}
                                                        />
                                                        &nbsp;<label htmlFor="idNo">ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="styleName"
                                                            checked={checkboxStates.styleName}
                                                        />
                                                        &nbsp;<label htmlFor="styleName">Style Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="styleNo"
                                                            checked={checkboxStates.styleNo}
                                                        />
                                                        &nbsp;<label htmlFor="styleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="skuName"
                                                            checked={checkboxStates.skuName}
                                                        />
                                                        &nbsp;<label htmlFor="skuName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="skuImg"
                                                            checked={checkboxStates.skuImg}
                                                        />
                                                        &nbsp;<label htmlFor="skuImg">SKU Image</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="size"
                                                            checked={checkboxStates.size}
                                                        />
                                                        &nbsp;<label htmlFor="size">Size</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="orderQty"
                                                            checked={checkboxStates.orderQty}
                                                        />
                                                        &nbsp;<label htmlFor="orderQty">Order Qty</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="inspectionStatus"
                                                            checked={checkboxStates.inspectionStatus}
                                                        />
                                                        &nbsp;<label htmlFor="inspectionStatus">Report Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="approvalStatus"
                                                            checked={checkboxStates.approvalStatus}
                                                        />
                                                        &nbsp;<label htmlFor="approvalStatus">Review Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="exFac"
                                                            checked={checkboxStates.exFac}
                                                        />
                                                        &nbsp;<label htmlFor="exFac">Ex-Fac Date</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="orderStatus"
                                                            checked={checkboxStates.orderStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="orderStatus">Order Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="currentStatus"
                                                            checked={checkboxStates.currentStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="currentStatus">Comments</label>
                                                    </li>
                                                </div>


                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="stsCreated"
                                                            checked={checkboxStates.stsCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="stsCreated">Status Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="cmtCreated"
                                                            checked={checkboxStates.cmtCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="cmtCreated">Comment Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="category"
                                                            checked={checkboxStates.category}
                                                        />
                                                        &nbsp;<label htmlFor="category">Category</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Factory"
                                                            checked={checkboxStates.Factory}
                                                        />
                                                        &nbsp;<label htmlFor="Factory">Factory</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="FOPONo"
                                                            checked={checkboxStates.FOPONo}
                                                        />
                                                        &nbsp;<label htmlFor="FOPONo">FO PO No</label>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-lg-10">
                        </div>
                        <div className="col-md-2" style={{ textAlign: 'right' }}>
                            {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o  btn btn-success excelcomt"
                                    table={"table-to-xls"}
                                    filename={"InspectionDetailsList"}
                                    sheet="InspectionDetails"
                                    buttonText=""
                                />
                            )}&nbsp;
                            <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                            <label style={{ marginTop: '7px', marginLeft: "8px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                                <input type="checkbox" name="IsAllSelected"
                                    onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                    value={isAllSelected ? true : false}
                                    checked={isAllSelected ? true : false}
                                    className="selectallcheckbox"
                                />
                            </label>
                        </div>
                        <div className="col-md-12">
                            <div style={{ overflowX: "auto" }}>

                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer table-responsive" id="editabledatatable" style={{ width: "160vm" }}>
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                        Taskname={Taskcallback}
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment, i) => (
                                                <Fragment key={i}>
                                                    <tr key={comment.purchaseOrderSkuID}>
                                                        <td style={{ width: '25px' }}>
                                                            {i + 1}
                                                        </td>
                                                        {checkboxStates.PlanEndDate && <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyone "
                                                            title={comment.planEndDate}>
                                                            {comment.planEndDate}
                                                        </td>}


                                                        {checkboxStates.skuID && <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            //className="stickytwo "
                                                            title={comment.purchaseOrderSkuID}>
                                                            {comment.purchaseOrderSkuID}
                                                        </td>}
                                                        {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}

                                                        {checkboxStates.skuName && < td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyseven "
                                                            title={comment.skuName}> {
                                                                comment.skuName
                                                            }</td>}
                                                        {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                            {comment.idpono}
                                                        </td>}
                                                        {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                            {/* {comment.foidpono} */}

                                                            {comment.foidpono === "" ? "-" : comment.foidpono}
                                                        </td>}

                                                        {checkboxStates.skuImg && <td className="text-center"
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        >{
                                                                < SimpleReactLightbox >
                                                                    <SRLWrapper options={options}>
                                                                        {
                                                                            < img className="width_manual"
                                                                                src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath}
                                                                                alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)} />

                                                                        }

                                                                    </SRLWrapper>
                                                                </SimpleReactLightbox>
                                                                // comment.skuImage
                                                                // comment.skuImagePath
                                                            }</td>}
                                                        {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                        {checkboxStates.orderQty &&
                                                            <td className="cut_textCommentsreport " title={comment.quantity} >
                                                                <span>{comment.quantity}</span>
                                                                <span>
                                                                    <span className="btn btn-success" title="View Sizes" style={{ float: "right" }}
                                                                        onClick={() => GetSizeandQty(comment.purchaseOrderSkuID, comment.quantity)}
                                                                    >
                                                                        <i className="fa fa-eye"></i>
                                                                    </span>
                                                                </span>


                                                            </td>}
                                                        {checkboxStates.exFac && <td className="cut_textCommentsreport " title={comment.exFacDate}>{
                                                            comment.exFacDate
                                                        }</td>}

                                                        {/* {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.supplierName}> - </td>}


                                                    {checkboxStates.styleNo && <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>} */}

                                                        {/* {checkboxStates.size && <td className="cut_textCommentsreport " title={comment.size}>{
                                                    comment.size
                                                }</td>} */}


                                                        {checkboxStates.inspectionStatus && <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                            <table style={{ margin: "auto" }}>
                                                                {Taskcallback === 'PP Meeting' || Taskcallback === 'In and Mid Line Inspection' ? <tr>
                                                                    <td>
                                                                        <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                            {comment.draftCountEx}
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        <span title='Save' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                            {comment.passCountEx}
                                                                        </span>

                                                                    </td>
                                                                </tr> :
                                                                    <tr>
                                                                        <td>
                                                                            <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                {comment.draftCount}
                                                                            </span>
                                                                        </td>
                                                                        <td >
                                                                            <span title='Ok' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                {comment.passCount}
                                                                            </span>

                                                                        </td>
                                                                        <td>
                                                                            <span title='Ok with Comment' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                {comment.okwithCommentCount}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span title='Not Ok' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                                {comment.failCount}
                                                                            </span>
                                                                        </td>
                                                                    </tr>}
                                                            </table>
                                                        }</td>}

                                                        {checkboxStates.approvalStatus && <td className="cut_textCommentsreport " title={comment.approvalStatus}>{
                                                            // comment.approvalStatus
                                                            <table style={{ margin: "auto" }}>
                                                                {Taskcallback === 'PP Meeting' || Taskcallback === 'In and Mid Line Inspection' ? <tr>
                                                                    <td>
                                                                        <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 4)}>
                                                                            {comment.pendingCountEX}
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        <span title='Reviewed' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 4)}>
                                                                            {comment.approvalCountEx}
                                                                        </span>

                                                                    </td>
                                                                </tr> : <tr>
                                                                    <td>
                                                                        <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 3)}>
                                                                            {comment.pendingCount}
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        <span title='Approved' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 3)}>
                                                                            {comment.approvalCount}
                                                                        </span>

                                                                    </td>
                                                                    <td>
                                                                        <span title='Approved with comments' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 3)}>
                                                                            {comment.commercialApprovalCount}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span title='Rejected' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 3)}>
                                                                            {comment.rejectedCount}
                                                                        </span>
                                                                    </td>
                                                                </tr>}
                                                            </table>
                                                        }</td>}


                                                        {/* {checkboxStates.orderStatus && <td className={(comment.orderStatus == '1' || comment.orderStatus == '') ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                        comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                            'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                    'Yet to Start'}>
                                                        {(comment.orderStatus == '1' || comment.orderStatus == '') ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                            comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                'Yet to Start'
                                                        }
                                                    </td>}

                                                    {checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                        comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                    }</td>}
                                                    {/* {checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                        comment.currentComments === "" ? "-" : comment.currentComments
                                                    }</td>}
                                                    {checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                        comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                    }</td>} */}


                                                        {<td className="text-center fixed-column-header-CommentReport" style={{ width: "6%", textAlign: 'left',display: InternalComments && InternalComments.isView === 0 && InternalComments.isAdd === 0 ? 'none' : ''  }}>
                                                            <span style={{ padding: '2px' }}>
                                                                <span className="btn" title="Comments" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                    style={{
                                                                        background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                        color: 'white',
                                                                        padding: '3px 6px',
                                                                        borderRadius: '1px',
                                                                        border: comment.currentComments === '' ? '#427fed' : 'red',
                                                                        display: InternalComments && InternalComments.isAdd === 1 ? '' : 'none'
                                                                    }}
                                                                >
                                                                    <i className="fa fa-comment"></i>
                                                                </span>
                                                            </span>
                                                            &nbsp;
                                                            {comment.exManualCloseComment === '' ? < span style={{ padding: '2px' }}>
                                                                <button className="btn btn-xs btn-Report" type="button" title="Report Create" onClick={() => handleExFactoryFormOpen(comment, 1, comment.taskDetailsID)}
                                                                style={{ display: InternalComments && InternalComments.isAdd === 1 ? '' : 'none' }}
                                                                // style={{
                                                                //     background: comment.factory === '' || comment.factory === undefined || comment.factory === null ? '#609B4E' : 'red',
                                                                //     color: 'white',
                                                                //     padding: '3px 6px',
                                                                //     borderRadius: '1px',
                                                                //     border: comment.factory === '' || comment.factory === undefined || comment.factory === null ? '#609B4E' : 'red'
                                                                // }}
                                                                >
                                                                    <i class="fa fa-external-link"></i>
                                                                </button>

                                                            </span> : ''}
                                                            {
                                                                comment.isDraft === 1 ?
                                                                    <span style={{ padding: '2px' }}>
                                                                        <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"
                                                                        style={{ display: InternalComments && InternalComments.isView === 1 ? '' : 'none' }}

                                                                            // style={{
                                                                            //     background: comment.view || comment.view === undefined || comment.view === null === '' ? '#F6A230' : 'red',
                                                                            //     color: 'white',
                                                                            //     padding: '3px 6px',
                                                                            //     borderRadius: '1px',
                                                                            //     border: comment.view === '' || comment.view === undefined || comment.view === null ? '#F6A230' : 'red'
                                                                            // }}
                                                                            onClick={() => handleFinalInspecViewOpen(comment, 1, comment.tnaExFactoryId)}
                                                                        >
                                                                            <i class="fa fa-check-square"></i>
                                                                        </button>

                                                                    </span>
                                                                    : ''

                                                            }

                                                            &nbsp;
                                                            <label style={{ marginTop: '8px',display: InternalComments && InternalComments.isAdd === 1 ? '' : 'none' }}>
                                                                <input type="checkbox" name="IsSelected"
                                                                    onChange={event => (handleOnSelect(event, 'IsSelected', comment.purchaseOrderSkuID,))}
                                                                    value={comment.isSelected ? true : false}
                                                                    checked={comment.isSelected ? true : false}
                                                                    className="clearOpacity textdesign" />
                                                                <span className="text"></span>
                                                            </label>
                                                            &nbsp;

                                                        </td>}

                                                        {
                                                            Input.IsQCinsectionFactory === 1 ?
                                                                <Modal show={Input.IsQCinsectionFactory === 1} dialogClassName="CreatePopup" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecView()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                            ADD {Taskcallback} &nbsp; {"(Style Name - " + comment.styleName + ")"}

                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                            <li className="active">
                                                                                <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForQAInspection('AddInspection')}>
                                                                                    <h5>Add Inspection </h5>
                                                                                </a>
                                                                            </li>
                                                                            {
                                                                                Taskcallback !== 'In and Mid Line Inspection' && Taskcallback !== 'PP Meeting' ?
                                                                                    <li>
                                                                                        <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForQAInspection('ReInspection')}>
                                                                                            <h5>Re Inspection </h5>
                                                                                        </a>
                                                                                    </li> : ''}
                                                                        </ul>
                                                                        <div className="tab-content">
                                                                            {getQAInspectionPageView === 'AddInspection' ? <>
                                                                                <AddQCInspection props={props} skuSelectData={getskusingleID} QCfinalInspectionList={getAddQCInspectionListParam}
                                                                                    QCcommonSubmitApprovalParams={''} QCFinalInspectionCallback={QCFinalInspectionCallback} />
                                                                            </> :
                                                                                <><QAReInspection props={props} Reinspectionparams={getAddQCInspectionListParam}
                                                                                    FinalInspectionCallback={FinalInspectionCallback} /></>
                                                                            }
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        {
                                                                            <Button variant="success" onClick={() => AddQCFactoryForm()}>
                                                                                Save
                                                                            </Button>
                                                                        }
                                                                    </Modal.Footer>
                                                                </Modal> : ''
                                                        }
                                                        {
                                                            Input.Finalview === 1 ?
                                                                <Modal show={(Input.Finalview === 1)} dialogClassName="keyinfowidth" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecViewQA()} >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            {`${Taskcallback} List`}
                                                                        </Modal.Title>

                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        {
                                                                            getnewheaderdata.length !== 0 && getnewheaderdata.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        {/* <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                            ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                            <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5> */}

                                                                                        <div className="col-lg-12">
                                                                                            <div className="row style-row no_padding">
                                                                                                <div className="col-lg-11 style-itemRe">
                                                                                                    <div className="title-bg keyinfodata">
                                                                                                        <label><b>SKU ID</b></label><br />
                                                                                                        <span >{item.skuID}</span>
                                                                                                    </div>
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>PO/ID No.</b></label><br />
                                                                                                        <span >{item.idno}</span>

                                                                                                    </div>
                                                                                                    {getQcBuyerbrandListParam.buyerName === 'Focus International' ?

                                                                                                        <>
                                                                                                            <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                                <label htmlFor="BuyerInfo"><b>Ref No.</b></label><br />
                                                                                                                <span >{item.pono}</span>

                                                                                                            </div>
                                                                                                        </> : ''}
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>Style Name</b></label><br />
                                                                                                        <span >{item.styleName}</span>

                                                                                                    </div>
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>Style No.</b></label><br />
                                                                                                        <span >{item.styleNo}</span>

                                                                                                    </div>

                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>SKU Name</b></label><br />
                                                                                                        <span >{item.skuName}</span>

                                                                                                    </div>
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>PO Qty</b></label><br />
                                                                                                        <span >{item.poQty}</span>

                                                                                                    </div>
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>Supplier</b></label><br />
                                                                                                        <span >{item.supplierName}</span>

                                                                                                    </div>
                                                                                                    <div className="title-bg" style={{ marginLeft: "10px" }} >
                                                                                                        <label htmlFor="BuyerInfo"><b>Ex-Fac</b></label><br />
                                                                                                        <span >{moment(item.exFacdate).format('DD/MM/YYYY')}</span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    &nbsp;
                                                                        <table className="table table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    {/* <th>Report No. & Date</th> */}
                                                                                    <th>Entry No. & Created Date</th>
                                                                                    <th>Factory</th>
                                                                                    {/* <th>Product Qty</th> */}
                                                                                    {
                                                                                        Taskcallback === 'In and Mid Line Inspection' ?<th>Prod. Qty</th>:''
                                                                                    }
                                                                                    
                                                                                    <th>Created By</th>
                                                                                    <th>Report Status</th>
                                                                                    {Taskcallback === 'In and Mid Line Inspection' || Taskcallback ==='PP Meeting'  ? <th>Review Status</th>:''}
                                                                                    {Taskcallback ==='Size Set Check' || Taskcallback ==='Pilot Run'  ? <th>Approval Status</th>:''}
                                                                                    <th>Action</th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    getnewfinalReportlist.length !== 0 && getnewfinalReportlist.map((item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td >
                                                                                                        {item.reportNo}
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        {item.factory}
                                                                                                    </td>
                                                                                                    {
                                                                                                        Taskcallback === 'In and Mid Line Inspection' ?
                                                                                                        <td>
                                                                                                        {item.productQty}
                                                                                                    </td>:''
                                                                                                    }
                                                                                                    
                                                                                                    <td>
                                                                                                        {item.createdBy}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.reportStatus}
                                                                                                    </td>
                                                                                                    {Taskcallback === 'In and Mid Line Inspection' || Taskcallback ==='PP Meeting'  ? 
                                                                                                    <td>
                                                                                                        {item.reviewStatus}
                                                                                                    </td> :''}
                                                                                                    {Taskcallback ==='Size Set Check' || Taskcallback ==='Pilot Run'  ? <td> {item.approvalResult}</td> :''}
                                                                                                    <td style={{ width: "18%" }}>
                                                                                                        <button type="button"
                                                                                                            className="btn btn-xs btn-warning" title='Approval'
                                                                                                        // className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                                                        //</td>onClick={() => GetComments(comment, 'Comments', comment.approvalResult)}
                                                                                                        >
                                                                                                            <i className="fa fa-check"></i>
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                            onClick={() => handleQAInspecViewDetails(item, 'View')}>
                                                                                                            <i className="fa fa-eye "></i>
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                            onClick={() => handleQAInspecViewDetails(item, 'Edit')}>
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        {getnewfinalReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                            onClick={() => handleDeleteQAinspection(item, 'Delete')}
                                                                                                        >
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>
                                                                                                        }

                                                                                                    </td>

                                                                                                </tr>
                                                                                            </>

                                                                                        )

                                                                                    })
                                                                                }
                                                                            </tbody>

                                                                        </table>


                                                                    </Modal.Body>
                                                                </Modal>
                                                                : ""

                                                        }
                                                    </tr>
                                                </Fragment>
                                            ))

                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }
                                        {
                                            sizeqtypopup === true ?

                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                    {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Size and Qty
                                                        </Modal.Title>


                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                        <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>

                                                    </div>

                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>Size</th>
                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    sizeqtylist.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.sizes}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {(item.qtys != null && item.qtys != undefined && item.qtys != "" ? item.qtys : '-')}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-xs btn-danger" onClick={() => setsizeqtypopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            Commentpopup === true ?
                                                <Modal show={Commentpopup === true} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt', Ecomment)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                            Comments
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                <th>Supplier Factory<span className="text-danger">*</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td >
                                                                    <textarea
                                                                        rows="4"
                                                                        placeholder="Enter comments here..."
                                                                        autoFocus onFocus={onFocus} autoComplete="off"
                                                                        maxLength="500" name="EnterComment"
                                                                        id={"EnterComment"}
                                                                        value={Ecomment}
                                                                        onChange={event => handleChangeComment(event, "reportComment")}
                                                                        style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                    </textarea>
                                                                </td>
                                                                <td colSpan='2'>
                                                                    <Reactselect
                                                                        options={getCmtExFactoryList}
                                                                        value={selectedCommentExfactory}
                                                                        onChange={handleCmtEXFactoryChanges}
                                                                        isClearable={true}
                                                                        styles={Submitted && (selectedCommentExfactory === undefined || selectedCommentExfactory === null) ? styles : ''}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                        <tbody>
                                                                            <tr>
                                                                                <div className="col-sm-12 col-lg-12">
                                                                                    <div style={{ paddingLeft: 0 }}>
                                                                                        <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                                                                    </div>
                                                                                </div>
                                                                                < div className="col-sm-12 col-lg-12">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-8 col-lg-8">
                                                                                            <div className='form-group'>
                                                                                                <tr>
                                                                                                    <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png" ></input></td>
                                                                                                </tr>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 col-sm-12">
                                                                                    <div className="row no-margin">
                                                                                        {
                                                                                            getFiles && getFiles.length !== 0 ?
                                                                                                getFiles.filter(x => x.IsDeleted !== 1 && x.reportManualFileId === 0).map((files, fileindex) => (
                                                                                                    // manitem.Files.filter(x => x.IsDeleted === 0) &&
                                                                                                    <> {
                                                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                            <tr title={files.fileName}>
                                                                                                                <td className="cut_textInTNA">
                                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                                        {
                                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                        }
                                                                                                                    </span>  {files.fileName.substr(33)}
                                                                                                                </td>

                                                                                                                <td className="button_style_uploda">


                                                                                                                    {
                                                                                                                        files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                                                                                            <i className="btn btn-info fa fa-eye" title="View Image"

                                                                                                                                onClick={
                                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                                }></i>
                                                                                                                    }



                                                                                                                    &nbsp;


                                                                                                                    <p title='Delete File' onClick={(e) => RemoveImages(fileindex, files, files.reportManualFileId)}
                                                                                                                        className="btn btn-danger btn-xs delete">
                                                                                                                        <i className="fa fa-trash-o"></i>

                                                                                                                    </p>

                                                                                                                </td>
                                                                                                            </tr>



                                                                                                            {

                                                                                                                <div> {
                                                                                                                    isOpenimg && (

                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Report/' + getImagename}
                                                                                                                            alt="bg image"
                                                                                                                            onCloseRequest={
                                                                                                                                () => setIsOpenimg(false)
                                                                                                                            } />
                                                                                                                    )
                                                                                                                } </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    </>
                                                                                                ))
                                                                                                : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <Modal.Body style={{ overflow: "auto", height: "650px" }}>

                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                            <thead>
                                                                <tr>
                                                                    <th>Image</th>
                                                                    <th>Comments</th>
                                                                    <th>Supplier Factory</th>
                                                                    <th style={{ width: '16%' }}>Date</th>
                                                                    <th style={{ width: '17%' }}>Created By</th>
                                                                    <th style={{ width: '5%' }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    reportcomments.map((Commentitem, subChildIndex2) => {
                                                                        return (
                                                                            Commentitem.comments !== '' ?
                                                                                <tr>
                                                                                    <td>
                                                                                        {

                                                                                            (Commentitem.fileName && Commentitem.fileName !== '') && Commentitem.length !== 0 ?
                                                                                                <div>
                                                                                                    <tr title={Commentitem.fileName}>
                                                                                                        <td >
                                                                                                            {
                                                                                                                Commentitem.fileName !== null && Commentitem.fileName !== '' ?
                                                                                                                    < SimpleReactLightbox >
                                                                                                                        <SRLWrapper >
                                                                                                                            {
                                                                                                                                getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).map((x, index) => {
                                                                                                                                    return (
                                                                                                                                        < img className="width_manual" title={x.flFileName.substr(33)} style={{ display: index === 0 ? '' : 'none' }}
                                                                                                                                            src={x.flFileName === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Report/' + x.flFileName}
                                                                                                                                            alt={x.flFileName.substr(33)} />
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </SRLWrapper>
                                                                                                                    </SimpleReactLightbox>

                                                                                                                    : 'No file selected'


                                                                                                            }

                                                                                                        </td>
                                                                                                        <span className={getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 2 ? "label label-success" :
                                                                                                            getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 3 ? "label label-danger" :
                                                                                                                getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 4 ? "label label-info" :
                                                                                                                    getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 5 ? "label label-warning" :
                                                                                                                        getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 6 ? "label label-primary" :
                                                                                                                            getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length > 6 ? "label label-default" : ''

                                                                                                        } style={{ display: 'inline-block' }}>{getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length > 1 ?
                                                                                                            getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length : ''}</span>
                                                                                                    </tr>
                                                                                                </div>
                                                                                                : 'No file selected'

                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {Commentitem.flCommentsDetail.length === 0 ? 'No Record Found' : Commentitem.flCommentsDetail}
                                                                                    </td>
                                                                                    <td>
                                                                                        {Commentitem.factoryName === null ? 'TBD' : Commentitem.factoryName}
                                                                                    </td>
                                                                                    {/* <td>
                                        {Commentitem.isStatus === 2 ? "Major" : Commentitem.isStatus === 1 ? 'Critical' : "Minor"}
                                      </td> */}
                                                                                    <td>
                                                                                        {Commentitem.commentsDate.length === 0 ? 'No Record Found' : moment(Commentitem.commentsDate).format('DD/MM/YYYY')}
                                                                                    </td>
                                                                                    <td>
                                                                                        {Commentitem.createdBy.length === 0 ? 'No Record Found' : Commentitem.createdBy}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                                                                            <span>
                                                                                                <span className="btn btn-danger" title="Delete Comment"
                                                                                                    onClick={() => RemoveComments(Commentitem)}
                                                                                                >
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                                : ''
                                                                        );
                                                                    })

                                                                }
                                                                {
                                                                    reportcomments.length === 0 ?
                                                                        <tr>
                                                                            <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                                        </tr> : ''
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="success" onClick={(event) => handleSaveComment(event, Ecomment)}>
                                                            Save
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                : ''
                                        }
                                        {
                                            InspectionAndApprovalpopup === true ?

                                                <Modal show={InspectionAndApprovalpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => SetinspectionAndApprovalpopup(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>{(statusType === 1 || statusType === 2) ? 'Inspection Status' : (Taskcallback !== 'PP Meeting' && Taskcallback !== 'In and Mid Line Inspection') ? 'Approval Status' : 'Review Status'}
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>
                                                        <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                    </div>

                                                    {(statusType === 1 || statusType === 2) && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        {isInspectionStarted == 1 &&
                                                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "160px" }}>Report No.</th>
                                                                        <th style={{ width: "160px" }}>Inspection Status</th>
                                                                        <th style={{ width: "160px" }}>Offer Qty</th>
                                                                        {/* <th style={{ width: "350px" }}>Created By</th> */}
                                                                        <th style={{ width: "700px" }}>Created On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        approvalOrInspectionList.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.tnaFinalInspectionID}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.inspectionStatus} */}
                                                                                                {<span className={item.inspectionStatus === "Draft" ? "warning" : (item.inspectionStatus === "Not Ok") ? "danger" : "success"}><b>{item.inspectionStatus}</b></span>}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.offeredQuantity}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.createdBy} */}
                                                                                                {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                                {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>}
                                                        {isInspectionStarted === 0 &&
                                                            <h4 className="text-info"><b>Inspection not yet started</b></h4>
                                                        }
                                                    </Modal.Body>}
                                                    {(statusType == 3 || statusType == 4) && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        {isInspectionStarted == 1 &&
                                                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th style={{ width: "350px" }}>Created Date</th> */}
                                                                        <th style={{ width: "160px" }}>Report No.</th>
                                                                        <th style={{ width: "160px" }}>{(Taskcallback !== 'PP Meeting' && Taskcallback !== 'In and Mid Line Inspection') ? 'Approval Status' : 'Review Status'}</th>
                                                                        <th style={{ width: "160px" }}>Quantity</th>
                                                                        <th style={{ width: "700px" }}>Created On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        approvalOrInspectionList.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        {/* <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.createdDate != null ? item.createdDate : '-'}
                                                                                            </span>
                                                                                        </td> */}
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.finalInspectionID}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.inspectionStatus} */}
                                                                                                {<span className={(item.inspectionStatus === "Approved" || item.inspectionStatus === "Reviewed") ? "success" : (item.inspectionStatus === "Pending") ? "warning" : (item.inspectionStatus == "Rejected") ? "danger" : "primary"}><b>{item.inspectionStatus}</b></span>}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.offeredQuantity}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                                {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                                {/* {item.createdDate != null ? item.createdDate : '-'} {item.createdBy != null ? item.createdBy : '-'} */}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>}
                                                        {isInspectionStarted == 0 &&
                                                            <h4 className="text-info"><b>{(Taskcallback !== 'PP Meeting' && Taskcallback !== 'In and Mid Line Inspection') ? 'Approval not yet started' : 'Reviewed not yet started'}</b></h4>
                                                        }
                                                    </Modal.Body>}
                                                    <Modal.Footer>
                                                        <button className="btn btn-xs btn-danger" onClick={() => SetinspectionAndApprovalpopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                    </Modal.Footer>


                                                </Modal> : ""
                                        }
                                    </tbody>
                                    <table data-role="table" data-mode="columntoggle" id="table-to-xls"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>
                                                            :
                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''
                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {checkboxStates.Po === true ? <th style={{ width: '5%' }}> PO Date </th> : false}
                                                {checkboxStates.skuID === true ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                                                {checkboxStates.skuName === true ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                                                {checkboxStates.skuImg === true ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                                                {checkboxStates.supplier === true ? <th style={{ width: '4%' }}> Supplier </th> : false}
                                                {checkboxStates.Factory === true ? <th style={{ width: '6%' }}> Factory</th> : false}
                                                {checkboxStates.idNo === true ? <th style={{ width: '6%' }}> ID No</th> : false}
                                                {checkboxStates.FOPONo === true ? <th style={{ width: '6%' }}> FO PONo</th> : false}
                                                {checkboxStates.styleName === true ? <th style={{ width: '6%' }}> Style Name</th> : false}
                                                {checkboxStates.styleNo === true ? <th style={{ width: '6%' }}> Style No</th> : false}
                                                {checkboxStates.orderQty === true ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                                                {checkboxStates.inspectionStatus === true ? <th style={{ width: '5%' }}> Report Status</th> : false}
                                                {checkboxStates.approvalStatus === true ? <th style={{ width: '7%' }}> Review Status</th> : false}
                                                {checkboxStates.exFac === true ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                                                {checkboxStates.orderStatus === true ? <th style={{ width: '5%' }}> Order Status</th> : false}
                                                {checkboxStates.stsCreated === true ? <th style={{ width: '2%' }}>  Status Created By</th> : false}
                                                {checkboxStates.currentStatus === true ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                                                {checkboxStates.cmtCreated === true ? <th style={{ width: '2%' }}>  Comment Created By</th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.purchaseOrderSkuID}>
                                                        {/* {checkboxStates.PlanEndDate && <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyone "
                                                            title={comment.planEndDate}>
                                                            {comment.planEndDate}
                                                        </td>} */}


                                                        {checkboxStates.skuID && <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            //className="stickytwo "
                                                            title={comment.purchaseOrderSkuID}>
                                                            {comment.purchaseOrderSkuID}
                                                        </td>}
                                                        {checkboxStates.skuName && < td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyseven "
                                                            title={comment.skuName}> {
                                                                comment.skuName
                                                            }</td>}
                                                        {checkboxStates.skuImg && <td> {
                                                            <div className="text-right">
                                                                <img src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath} width='3%' height={'3%'} />
                                                            </div>

                                                        }</td>}
                                                        {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                        {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.supplierName}> - </td>}
                                                        {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                            {comment.idpono}
                                                        </td>}
                                                        {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                            {/* {comment.foidpono} */}

                                                            {comment.foidpono === "" ? "-" : comment.foidpono}
                                                        </td>}
                                                        {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}
                                                        {checkboxStates.styleNo && <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>}

                                                        {/* {checkboxStates.size && <td className="cut_textCommentsreport " title={comment.size}>{
                                                    comment.size
                                                }</td>} */}
                                                        {checkboxStates.orderQty &&
                                                            <td className="cut_textCommentsreport " title={comment.quantity} >
                                                                <span>{comment.quantity}</span>
                                                            </td>}
                                                        <td></td>
                                                        <td>    </td>
                                                        {checkboxStates.exFac && <td className="cut_textCommentsreport " title={comment.exFacDate}>{
                                                            comment.exFacDate
                                                        }</td>}

                                                        {checkboxStates.orderStatus && <td className={(comment.orderStatus == '1' || comment.orderStatus == '') ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                    comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                        'Yet to Start'}>
                                                            {(comment.orderStatus == '1' || comment.orderStatus == '') ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                    'Yet to Start'
                                                            }
                                                        </td>}

                                                        {checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                        }</td>}
                                                        {checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                            comment.currentComments === "" ? "-" : comment.currentComments
                                                        }</td>}
                                                        {checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                        }</td>}
                                                    </tr>

                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table>
                            </div>
                        </div>

                    </div>
                    <PageCount onPageCount={
                        value => {
                            setITEMS_PER_PAGE(value);
                            setCurrentPage(1);
                        }
                    } />
                    <Pagination total={totalItems}
                        itemsPerPage={
                            parseInt(ITEMS_PER_PAGE)
                        }
                        currentPage={currentPage}
                        onPageChange={
                            page => setCurrentPage(page)
                        } />
                    {
                        getID.showPopupDelete ? <SweetAlertPopup data={
                            getID.Params
                        }
                            deleteCallback={delCallback}
                            showpopup={true}
                            pageActionId={QAReportSkuComments}
                            Msg={"Comments deleted successfully"} /> : null
                    }
                    {
                        getIDQATask.showPopupDelete ? <SweetAlertPopup data={
                            getIDQATask.Params
                        }
                            deleteCallback={delQaCallback}
                            showpopup={true}
                            pageActionId={QAInspectionId}
                            Msg={"QA Inspection deleted successfully"} /> : null
                    }
                </div>
                {loader}
            </div >
        </div>
    )

};
export default InternalQAAuditDetails;