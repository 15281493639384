import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { loadSupplierList, getSkuAndStyleDetails } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { getOrderSamplesRequestViewList, clearSamplesDetailsViewSubRow, getReloadOrderSamplesSubmissionViewList, getOrderSamplesSubmissionViewList, getReloadOrderSamplesRequestViewList, getReloadOrderSamplesApprovalViewList } from "../../../../actions/orderProtoFitSms";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TnaService from "../../../../services/TNA/TNAService";
import ProtoFitSMSApproval from "../../../TNA/ProtoFitSMSApproval_Order";
import ProtoFitSMSSubmissionOrder from "../../../TNA/ProtoFitSMSSubmission_Order";
import { TNAManualCloseParent, TNAManualCloseChildLibDip, TNAManualCloseChildStrikeOff, TNAManualCloseChildTrim, TNAManualCloseChildProtoFit, TNAManualCloseChildSms, smsapprovalId, TNAManualCloseChildExFactory, TNAManualCloseChildStrikeoffTest, TNAManualCloseChildTrimTest, FinalinspectionID } from "../../../Common/PageActionNumber";

const ProtoFitViewSubmissionTab = (props) => {

  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [IsProtoExpanded, setIsProtoExpanded] = useState(false);
  const [IsFitExpanded, setIsFitExpanded] = useState(false);
  const [IsNonSampleExpanded, setIsNonSampleExpanded] = useState(false);
  const [IsSizeSampleExpanded, setIsSizeSampleExpanded] = useState(false);
  const [IsPreProductionExpanded, setIsPreProductionExpanded] = useState(false);
  const [IsShipmentExpanded, setIsShipmentExpanded] = useState(false);
  const [IsPhotoSampleExpanded, setIsPhotoSampleExpanded] = useState(false);
  ; const [getsID, setsID] = useState(0);
  const [getComment, setComment] = useState(false);
 
  const ordSampleList = useSelector((state) => state.orderProtoFitSms.ordSampleSubmissionList);
  let isLoadingProtoFitSms = useSelector((state) => state.orderProtoFitSms.isLoadingProtoFitSms);
  const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
  let GLDRequestStatusList = useSelector((state) => state.orderStrikeOff.OLDRequestStatusList);
  const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
  const [selectall, setselectall] = useState(false);
  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
  const [manualcloseheader, setmanualcloseheader] = useState("");
  const [showmaunalclose, setshowmaunalclose] = useState(false);
  const [TNAProtoFitId, setTNAProtoFitId] = useState();
  const [ParentTask, setParentTask] = useState('');
  const [ChildTask, setChildTask] = useState('');
  const [TaskStatus, setTaskStatus] = useState();
  const [TNAId, setTNAId] = useState();
  const [StyleId, setStyleId] = useState();
  const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
  const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
  const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });
  const [delteProtoFitSmsApproval, setdeleteProtoFitSmsApproval] = useState(false);
  const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
  const StaticTask = useSelector((state) => state.tna.StaticTask);
  const [editProtoFitSmsSubmission, seteditProtoFitSmsSubmission] = useState(false);
  const [delteProtoFitSmsSubmission, setdeleteProtoFitSmsSubmission] = useState(false);
  const [getexistsubmission, setexistsubmission] = useState(false);
  const [protofitsmsDisable, setprotofitsmsDisable] = useState(false);
  const [editProtoFitSmsApproval, seteditProtoFitSmsApproval] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [getTaskholder, setTaskholderlist] = useState([]);
  const [getnewlist, setnewlist] = useState();
  let BuyerId = 0;
  let BrandId = 0;
  let SeasonId = 0;

  //get props params
  if (props.props.location.state !== undefined) {

    BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
    BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
    SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

  }

  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  //let IsProduction = activeMenus.IsProduction;

  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

  let activeMenu;
  const menulist = useSelector((state) => state.auth.UserMenuList.item3);
  let info;
  //let isTablist = false;
 
  // Set 'info' based on IsProduction
  info = IsProduction === 1 ? 'Samples' : (IsProduction === 2 ? 'ProductionSample' : 'ProductionSample');
  activeMenu = menulist.filter(i => i.menuName === info);


  
  useEffect(() => {
    if (!isLoadingProtoFitSms) {
      hideLoader();
    } else {
      showLoader();
    }


  }, [isLoadingProtoFitSms])



  const [getSearchReqValues, setSearchReqValues] = useState({
    StatusId: 0,
    SupplierId: 0,
    SearchTxt: "",
    StyleName: "",
    StyleNo: "",
    IdPoNo: "",
    Samplecolor: "",
    SampleName: '',
    stateDate: [
      {
        startDate: null,
        endDate: new Date(""),
        key: 'selection',
        isShow: false
      }
    ]


  });

  const SearchReqValues = { ...getSearchReqValues };

  const [inputFields, setInputFields] = useState([{
    BuyerBrandSeasonName: '',
    styleName: '',
    StyleNo: '',
    Supplier: '',
    SubmittedDate: '',
    SendTo: "0",
    SendThrough: 0,
    EndCustomer: "",
    AWBName: "", AWBDate: "", AWBDateandNo: "",
    StrikeOffId: 0, StrikeoffCode: 0, StrikeoffName: "", Quality: "",
    StrikeoffDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
    SubmitStatus: '',
    GenralStrikeOffTNASubmissionAndApprovalId: 0,
    GeneralTNAStrikeOffSubmittedOption: [],
    ColorGroupId: 0
  }]);

  const values = [...inputFields]

  useEffect(() => {
    searchSamples();
  }, [])

  function ChkAllDepandenctTasksClose(arrDependency) {
    ;
    let IsAllTasksClosed = true;
    let InCompleteTasks = '';
    if (arrDependency && arrDependency.length > 0) {
      let CurrentChildvalues = [];
      values.map((Main, MainIndex) => {
        Main.SubTaskList.map((Child) => {
          Child.ChildTaskList.map((SubChild) => {
            CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: SubChild.ChildIndex, StatusId: SubChild.StatusID })
          })
        })
      })
      arrDependency.filter(x => x.DependencyID !== 0).map((depitem, i) => {
        if (CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName).length > 0) {
          if (CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName)[0].StatusId !== 3) {
            IsAllTasksClosed = false;
            let InCompleterow = CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName)[0];
            if (InCompleteTasks === '') {
              InCompleteTasks = InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex;
            }
            else {
              InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex)
            }
          }
        }
      })
    }

    return InCompleteTasks;
  }


  const ProtoFitAprovalCallback = (value, Operation) => {

    if (value === true) {
      setSampleAppModalpopup(false);
      setdeleteProtoFitSmsApproval(false);
      seteditProtoFitSmsApproval(false);

      ReloadSubmission();
      searchTrim();
      // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
    }



  }
  const handleSearchChange = (e, FieldName) => {

    let inputText = '';
    if (FieldName === 'SearchTxt' || FieldName === 'SampleName' || FieldName === 'SampleColor' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo') {
      if (e && e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
    } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
      if (e) {
        inputText = e.value
      }
    }

    if (FieldName === 'Status') {
      SearchReqValues.StatusId = inputText;
    }
    else if (FieldName === 'SupplierId') {
      SearchReqValues.SupplierId = inputText;
    } else if (FieldName === 'SearchTxt') {
      SearchReqValues.SearchTxt = inputText;
    } else if (FieldName === 'StyleName') {
      SearchReqValues.StyleName = inputText;
    }
    else if (FieldName === 'StyleNo') {
      SearchReqValues.StyleNo = inputText;
    }
    else if (FieldName === 'IdPoNo') {
      SearchReqValues.IdPoNo = inputText;
    }
    else if (FieldName === 'SampleColor') {
      SearchReqValues.Samplecolor = inputText;
    }
    else if (FieldName === 'SampleName') {
      SearchReqValues.SampleName = inputText;
    }
    setSearchReqValues(SearchReqValues);

  }
  const handledelete = (childid, Taskname) => {
   
    let Value = [];
    if (Taskname === 'Proto Sample Submission') {
      let Params = {
        Operation: 55,
        Id: childid,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {
          // setchkDataExists(true);
          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
        }
        else {
          setshowmaunalclose(false);
          // Value.TNAId = props.location.state.params.tnaid;
          Value.Id = ChildId.ChildId;
          setsID({ TNAManualClose: TNAManualCloseChildProtoFit, showPopupDelete: true, Params: Value });
        }
      })
    }
    else if (Taskname === "Fit Sample Submission") {
      let Params = {
        Operation: 56,
        Id: childid,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {
          // setchkDataExists(true);
          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
        }
        else {
          setshowmaunalclose(false);
          // Value.TNAId = props.location.state.params.tnaid;
          Value.Id = ChildId.ChildId;
          setsID({ TNAManualClose: TNAManualCloseChildProtoFit, showPopupDelete: true, Params: Value });
        }
      })
    }
    else if (Taskname === "SMS Submission"
      || Taskname === "Size Set Sample Submission"
      || Taskname === "Pre Production Sample Submission"
      || Taskname === "Shipment Sample Submission"
      || Taskname === "Photo Sample Submission") {
      let Params = {
        Operation: 57,
        Id: childid,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {
          // setchkDataExists(true);
          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
        }
        else {
          setshowmaunalclose(true);
          // Value.TNAId = props.location.state.params.tnaid;
          Value.Id = ChildId.ChildId;
          setsID({ TNAManualClose: TNAManualCloseChildSms, showPopupDelete: true, Params: Value });
        }
      })


    }
  }
  const DeleteCallback = (value) => {
    if (value === true) {
      setsID({ showPopupDelete: false, Params: [] });
      searchTrim();
    } else {
      setsID({ showPopupDelete: false, Params: [] })
      setshowmaunalclose(true);
    }

  }
  const handleChangeIsManualCloseComments = (event, feild) => {
    var manualclosedata = { ...Manualclosedata };
    let input = '';

    setComment(false);
    if (feild === "Comment") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      manualclosedata.ManualCloseComment = input;
    }
    else {
      manualclosedata.ManualCloseDate = event;
      // ManualCloseData.ManualCloseDate = event;
    }
    setManualclosedata(manualclosedata);
  }
  const CustomInputman = (props) => {

    return (
      <input
        className="form-control DatePickerCalenderHoliday min_width_Date"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        menuPosition="fixed"
        placeholder="DD/MM/YYYY"
        style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
      />
    )
  }

  const SelectAll = (event) => {
    let IsSelected = 0;

    if (event.target.checked === true) {
      IsSelected = 1;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 1;
      })
    }
    else {
      IsSelected = 0;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 0;
      })
    }
    setselectall(IsSelected);
  }

  const OnSelectRecords = (event, index) => {
    const value = [...MultiCloseDatas];

    if (event.target.checked === true) {
      value[index].isSelected = 1;
    }
    else {
      value[index].isSelected = 0;

    }

    let isSelectAll = 0;
    if (value) {
      isSelectAll = !!!value.find(d => d.isSelected === 0);
      isSelectAll = isSelectAll ? 1 : 0;
    }
    setselectall(isSelectAll);

    setMultiCloseDatas(value);
  }
  const SaveChildManulaCloseComments = (TaskName) => {



    if (Manualclosedata.ManualCloseComment === '' || Manualclosedata.ManualCloseComment === undefined) {
      setComment(true);
      Nodify('Warning!', 'warning', "Please enter comments");
    }
    else if (Manualclosedata.ManualCloseDate === '') {
      setComment(true);
      Nodify('Warning!', 'warning', "Please enter Close Date");
    }
    else {
      let ChildStatus = 0;
      if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        ChildStatus = 1;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
        ChildStatus = 2;

      } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
        TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
        TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

        ChildStatus = 3;

      } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
        TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
        ChildStatus = 4;
      }
      else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
        TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        ChildStatus = 5;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
        ChildStatus = 8;
      }
      else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
        ChildStatus = 9;
      }


      let strValue = "";
      let selected = 0;
      for (let data of MultiCloseDatas) {
        if (strValue === "") {
          if (data.isSelected === 1) {
            strValue = data.subTaskInfoId.toString();
            selected = selected + 1;
          }
        } else {
          if (data.isSelected === 1) {
            strValue = strValue + "," + data.subTaskInfoId;
          }
        }
      }
      if (selected === 0) {
        Nodify('Warning!', 'warning', "Select atleast one Task");
      }
      else {
        var ManualCloseData = { ...Manualclosedata };
        ManualCloseData.ManualCloseDate = new Date(Manualclosedata.ManualCloseDate);
        ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
        // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate);
        // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
        // .setDate(values[index].SubTaskList[subIndex].subFieldList[childIndex].ManualCloseDate.getDate() + 1);
        ManualCloseData.ManualCloseComment = Manualclosedata.ManualCloseComment;
        ManualCloseData.IdList = strValue;
        ManualCloseData.TaskStatus = ChildStatus;
        ManualCloseData.CreatedBy = currentUser.employeeinformationID;
        TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
          if (res.data.outputResult === "2") {
            Nodify('Success!', 'success', 'Task Manually Closed Successfully');
            setshowmaunalclose(false);

            ReloadSubmission();
            searchTrim();
            //  handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);


          }
          else if (res.data.outputResult === "0") {
            Nodify("Error!", "Error Occured!");
          }
        });
      }

    }
  }
  const resetSamples = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    SearchReqValues.StatusId = 0;
    SearchReqValues.SupplierId = 0;
    SearchReqValues.SearchTxt = "";
    SearchReqValues.StyleName = "";
    SearchReqValues.StyleNo = "";
    SearchReqValues.IdPoNo = "";
    SearchReqValues.Samplecolor = "";
    SearchReqValues.SampleName = "";
    setSearchReqValues(SearchReqValues);
    searchSamples();

  }


  const clearDateSelect = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    setSearchReqValues(SearchReqValues);
  }
  const handleDateSelect = (event, isShow) => {

    SearchReqValues.stateDate[0].isShow = isShow;
    setSearchReqValues(SearchReqValues);
  }

  const handlePopupDateselection = (item) => {

    SearchReqValues.stateDate = [item.selection];
    setSearchReqValues(SearchReqValues);
  }

  function convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }


  const searchSamples = () => {

    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 3,
      Samplecolor: SearchReqValues.Samplecolor,
      SampleName: SearchReqValues.SampleName,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getReloadOrderSamplesSubmissionViewList(params));
  }

  const ViewManulClose = async (TaskName, TaskType, TaskID, ChildId, Status, Comment, CommentDate) => {
    ;
   
    showLoader();
    setChildId({ ChildId: ChildId, Status: Status });
    var mandata = { ...Manualclosedata }
    mandata.ManualCloseComment = Comment;
    mandata.ManualCloseDate = new Date(CommentDate);
    setManualclosedata(mandata);
    setmanualcloseheader('View Submitted')

    let Operation;
    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
      Operation = 1;
    }
    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
      || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
      Operation = 3;
    }
    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
      || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
      Operation = 2;
    }
    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
      Operation = 4;
    }
    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
      Operation = 5;
    }
    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
      || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
      || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
      || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
      || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
      || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
      || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
      Operation = 6;
    }
    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
      Operation = 7;
    }
    else {
      Operation = 8;
    }


    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
      TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
      Operation: Operation, ParentIdOrSubChildId: ChildId
    };

    await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
      if (response.data) {
        let data = response.data.map((item) => {

          if (item.subTaskInfoId === ChildId) {
            item.isSelected = 1;

            return item;
          } else {
            return item;
          }
        });
        let isSelectAll = 0;
        if (data) {
          isSelectAll = !!!data.find(d => d.isSelected === 0);
          isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
        hideLoader();
      }
      hideLoader();

    }).catch(() => { hideLoader(); });
    setshowmaunalclose(true);
  }

  const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, buyerid, brandid, seasonid, supplierid) => {
    showLoader();
    setmanualcloseheader('Approval')
    var mandata = { ...Manualclosedata }
    mandata.ManualCloseComment = '';
    mandata.ManualCloseDate = '';
    setManualclosedata(mandata);
    let Operation;
    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
      Operation = 1;
    }
    else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
      Operation = 3;
    }
    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
      Operation = 2;
    }
    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
      Operation = 4;
    }
    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
      Operation = 5;
    }

    else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
      Operation = 6;
    }
    else if (TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
      Operation = 8;
    }
    else if (TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
      Operation = 9;
    }
    else if (TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
      Operation = 10;
    }
    else if (TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
      Operation = 11;
    }
    else {
      Operation = 7;

    }


    if (Operation !== 7) { // check submission exists for approvals
      await TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then(async (response) => {
        if (response.data.length !== 0) {

          setshowmaunalclose(true);

          //get manual close
          let Operation;
          if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
          }
          else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
          }
          else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
          }
          else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
            Operation = 4;
          }
          else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            Operation = 5;
          }
          //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
          else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
            Operation = 6;
          }
          else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
            Operation = 7;
          }
          else {
            Operation = 8;
          }


          let params = {
            BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
            SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
            Operation: Operation, ParentIdOrSubChildId: ChildId
          };

          await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
            if (response.data) {
              let data = response.data.map((item) => {

                if (item.subTaskInfoId === ChildId) {
                  item.isSelected = 1;

                  return item;
                } else {
                  return item;
                }
              });
              let isSelectAll = 0;
              if (data) {
                isSelectAll = !!!data.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
              }
              setselectall(isSelectAll);
              if (Status !== 3) {
                setMultiCloseDatas(data);
              }
              else {
                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
              }
              hideLoader();
            }
            hideLoader();

          }).catch(() => { hideLoader(); });
        }
        else {
          Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
          return false;
        }

      }).catch(() => { });
    }
    else {
      setshowmaunalclose(true);

      //get manual close
      let Operation;
      if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
        Operation = 1;
      }
      else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
        Operation = 3;
      }
      else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
        Operation = 2;
      }
      else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
        Operation = 4;
      }
      else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
        Operation = 5;
      }
      //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
      else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
        Operation = 6;
      }
      else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
        Operation = 7;
      }
      else {
        Operation = 8;
      }


      let params = {
        BuyerId: buyerid, BrandId: brandid, SeasonId: seasonid,
        SupplierId: supplierid, TaskType: TaskType, TaskNameId: TaskID,
        TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
        Operation: Operation, ParentIdOrSubChildId: ChildId
      };

      await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
        if (response.data) {
          let data = response.data.map((item) => {

            if (item.subTaskInfoId === ChildId) {
              item.isSelected = 1;

              return item;
            } else {
              return item;
            }
          });
          let isSelectAll = 0;
          if (data) {
            isSelectAll = !!!data.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
          }
          setselectall(isSelectAll);
          if (Status !== 3) {
            setMultiCloseDatas(data);
          }
          else {
            setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
          }
          hideLoader();
        }
        hideLoader();

      }).catch(() => { hideLoader(); });
    }

  };
  const OpenProtoFitSamplepopup = async (TNAId, TNAProtoFitId, index, subIndex, childIndex, ChildTaskName, ParentTask, Styleid, StatusId, arrDependency, intDependency) => {
    //check all depandent tasks closed
   
    let Params = {
      Operation: 51,
      Id: TNAProtoFitId,
    };
    await CommonService.CheckMappedItem(Params).then(res => {
      if (res.data.outputResult === "-2") {
        setprotofitsmsDisable(true)
      } else {
        setprotofitsmsDisable(false)
      }
    })
    //setnewlist()
    setTNAId(TNAId);
    setTNAProtoFitId(TNAProtoFitId);
    setChildTask(ChildTaskName);
    setParentTask(ParentTask);
    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    setStyleId(Styleid);
    setTaskStatus(StatusId);
    await TnaService.TaskHolderList(TNAId).then((response) => {
      if (response.data) {
        setnewlist(response.data);
        setSampleSubModalpopup(true);
      }
    }).catch(() => { });
    //}


  }

  const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex, ParentTask, StatusId, Operation, arrDependency, intDependency) => {
    //check all depandent tasks closed
    ;

    let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);
    if (ParentTask === 'Proto Sample Submission') {
      ParentTask = 'Proto Sample Approval'
    }
    else if (ParentTask === 'Fit Sample Submission') {
      ParentTask = 'Fit Sample Approval'
    }
    else if (ParentTask === 'Size Set Sample Submission') {
      ParentTask = 'Size Set Sample Approval'
    }
    else if (ParentTask === 'Pre Production Sample Submission') {
      ParentTask = 'Pre Production Sample Approval'
    }
    else if (ParentTask === 'Shipment Sample Submission') {
      ParentTask = 'Shipment Sample Approval'
    }
    else if (ParentTask === 'Photo Sample Submission') {
      ParentTask = 'Photo Sample Approval'
    }
    if (InCompleteTasks !== '' && intDependency === 0) {
      Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
    }
    //if depandent tasks closed then open popup
    else {
      TnaService.GetProtoFitSMSSubmission(Operation, TNAProtoFitId).then((response) => {

        if (response.data.length !== 0) {
          // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
          //     let Dependency = false;
          //     if (values[index].SelectedDependency) {
          //         let arrstatus = [];
          //         arrstatus = values[index].SelectedDependency.split(',');
          //         if (arrstatus.length > 0) {
          //             arrstatus.forEach(element => {
          //                 let val = [];
          //                 val = element.split('-');
          //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
          //                 if (valIndex !== 3) {
          //                     Dependency = true;
          //                 }
          //             });
          //         }
          //         if (Dependency && StatusId !== 3) {
          //             Nodify('Warning!', 'warning', "Please close dependency task");
          //         }
          //         else {
          //             setTNAProtoFitId(TNAProtoFitId);
          //             setParentTask(ParentTask);
          //             setTaskStatus(StatusId);
          //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          //             setdeleteProtoFitSmsApproval(false)
          //             setSampleAppModalpopup(true);
          //         }
          //     } else {
          //         setTNAProtoFitId(TNAProtoFitId);
          //         setParentTask(ParentTask);
          //         setTaskStatus(StatusId);
          //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          //         setdeleteProtoFitSmsApproval(false)
          //         setSampleAppModalpopup(true);
          //     }
          // }
          // else {
          setTNAProtoFitId(TNAProtoFitId);
          setParentTask(ParentTask);
          setTaskStatus(StatusId);
          setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          setdeleteProtoFitSmsApproval(false)
          setSampleAppModalpopup(true);
          // }
        }
        else {
          Nodify('Warning!', 'warning', "Submission not yet added for this task");
          return false;
        }

      }).catch(() => { });
    }

  }
  const handleprotofitApp = () => {
    setSampleAppModalpopup(false);
    seteditProtoFitSmsApproval(false);
    setdeleteProtoFitSmsApproval(false)
  }
  const handleprotoSubApp = () => {
    setSampleSubModalpopup(false);
    seteditProtoFitSmsSubmission(false);
    setdeleteProtoFitSmsSubmission(false);
  }
  const ReloadSubmission = () => {
    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }
    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 2,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };

    let approvalparams = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 3,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    let approvalparamstwo = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 4,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getReloadOrderSamplesSubmissionViewList(approvalparams));
    dispatch(getOrderSamplesRequestViewList(params));
    dispatch(getReloadOrderSamplesApprovalViewList(approvalparamstwo));

  }

  const searchTrim = () => {
    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 3,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    let approvalparams = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 2,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getOrderSamplesSubmissionViewList(params));
    dispatch(getReloadOrderSamplesRequestViewList(approvalparams));

    // let params = {
    //     BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
    //     BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
    //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
    // };
    // dispatch(getGeneralTrimDetailsViewList(params));
    // commonParentExpand();
  }

  const ProtoFitSMSSubmissionCallback = (value, Operation) => {



    if (value === true) {
      setSampleSubModalpopup(false);
      setdeleteProtoFitSmsSubmission(false);
      seteditProtoFitSmsSubmission(false);

      ReloadSubmission();
      searchTrim();
      // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
    }
  }

  return (

    <div className="widget-body">
      <div id="registration-form">
        <form>

          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-2">
                <label htmlFor="Status">
                  Style Name
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style Name'}
                    placeholder={'Style Name'}
                    value={SearchReqValues.StyleName}
                    onChange={e => handleSearchChange(e, 'StyleName')}
                  />
                </span>
              </div>

              <div className="col-md-2">
                <label htmlFor="Status">
                  Style No
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style No'}
                    placeholder={'Style No'}
                    value={SearchReqValues.StyleNo}
                    onChange={e => handleSearchChange(e, 'StyleNo')}
                  />
                </span>
              </div>
              <div className="col-md-2">
                <label htmlFor="Status">
                  Sample Color
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Sample Color'}
                    placeholder={'Sample Color'}
                    value={SearchReqValues.Samplecolor}
                    onChange={e => handleSearchChange(e, 'SampleColor')}
                  />
                </span>
              </div>




              <div className="col-md-2">
                <label htmlFor="Status">
                  ID/PO NO
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'ID/PO No'}
                    placeholder={'ID/PO NO'}
                    value={SearchReqValues.IdPoNo}
                    onChange={e => handleSearchChange(e, 'IdPoNo')}
                  />
                </span>

              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="SupplierID">
                    Sample Name
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'SampleName'}
                      placeholder={'SampleName'}
                      value={SearchReqValues.SampleName}
                      onChange={e => handleSearchChange(e, 'SampleName')}
                    />
                  </span>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="SupplierID">
                    Supplier
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <span className="input-icon icon-right">
                    <Reactselect className="basic-single" name="SupplierId"
                      id={
                        "SupplierId"
                      }
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={event => handleSearchChange(event, 'SupplierId')}
                      value={getSupplierList.filter(function (option) {
                        return option.value === SearchReqValues.SupplierId;

                      })}
                      options={getSupplierList}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                    ></Reactselect>

                  </span>
                </div>
              </div>

            </div>
          </div>
            <div className="col-sm-12">
            <div className="row">
              
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="Status">
                    Approval Status
                  </label>
                  <span className="input-icon icon-right">
                    <Reactselect className="basic-single" name="Status"
                      id={
                        "Status"
                      }
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={event => handleSearchChange(event, 'Status')}
                      value={GLDRequestStatusList.filter(function (option) {
                        return option.value === SearchReqValues.StatusId;
                      })}
                      options={GLDRequestStatusList}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                    ></Reactselect>
                  </span>
                </div>
              </div>
              <div className="col-md-2">

                <div className="form-group">
                <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                  <span className="input-icon icon-right">
                    <input
                      value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                      onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                      //onBlur={event => handleDateBlur()}
                      type="text"
                      className={'form-control OrdProtofit'}
                      placeholder="Select Date"
                    />
                    <i className="fa fa-times OrdProtofit" onClick={clearDateSelect} ></i>
                  </span>
                </div>


                {SearchReqValues.stateDate[0].isShow &&
                  <DateRangePicker
                    //onChange={item => setStateDate([item.selection])}
                    onChange={item => handlePopupDateselection(item)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={SearchReqValues.stateDate}
                    showDateDisplay={false}
                    direction="vertical"
                    className={'TNAReportDatePicker'}

                  />

                }

              </div>
              <div className="col-md-2" style={{ paddingTop: '29px' }}>

                <button type="button" className="btn btn-success" title="Search" onClick={event => searchSamples()}
                >
                  <i className="fa fa-search"></i>
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-danger" title="Reset" onClick={resetSamples}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div> 
            </div>

          </div>

          <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" >
              <thead>
                <tr>
                  <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "15px" }}> #</th>
                  <th className="col-action-or-other">Submitted Date</th>
                  <th className="col-action-or-other">Style Name</th>
                  <th className="col-action-or-other">SKU Name</th>
                  <th className="col-action-or-other">Style No</th>
                  <th className="col-action-or-other">ID/PO NO</th>
                  <th className="col-action-or-other">Supplier</th>
                  <th className="col-action-or-other">Sample Name</th>
                  <th className="col-action-or-other">Details (size & qty)</th>
                  <th className="col-action-or-other">Task Holder / Follower</th>
                  <th className="col-action-or-other">Approval Target</th>
                  <th className="col-action-or-other">AWB & Date</th>
                  <th className="col-action-or-other"  style={{ display: activeMenu && activeMenu[0].isEdit === 0 &&
                   activeMenu[0].isDelete === 0 ? 'none' : ''}}>Action</th>

                </tr>
              </thead>
              <tbody>
                <Fragment>



                  <>
                    {
                      ordSampleList.length > 0 ?
                        ordSampleList.map((subField, index) => (
                          <tr>
                            <td>
                              {index + 1}
                            </td>
                            <td>
                              {/* {subField.requestedDate} */}
                              {moment(subField.requestedDate).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              {subField.styleName}
                            </td>
                            <td>
                              {subField.sampleColor}
                            </td>
                            <td>
                              {subField.styleNo}
                            </td>
                            <td>
                              {subField.poNo}
                            </td>
                            <td>
                              {subField.supplierName}
                            </td>
                            <td>
                              {subField.sampleRequestName}
                            </td>
                            <td>
                              {subField.sizeQty}
                            </td>
                            <td>
                              {subField.follower}
                            </td>
                            <td>
                              {moment(subField.approvalTarget).format('DD/MM/YYYY')}
                            </td>
                            <td>

                              {
                                subField.awbDate === null ? "-" : subField.awbDate
                                  ||
                                  subField.awbDate === '' ? "-" : subField.awbDate
                              }
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {
                                (subField.manualCloseComment !== '' && subField.manualCloseComment !== null && subField.manualCloseComment !== undefined) && subField.statusID === 3 ?
                                  <button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="View Submitted Manual Close"
                                    onClick={() => ViewManulClose(subField.taskName, subField.taskTypeId, subField.taskId, subField.childId, subField.statusID,
                                      subField.manualCloseComment, subField.manualCloseDate)}
                                  >
                                    <i className="fa fa-user"></i>&nbsp;
                                    <i className="fa fa-close"></i>
                                  </button>
                                  : ''
                              }
                              &nbsp;
                              {subField.isShowApprovalButton === 1 && subField.manualCloseComment !== '' && (subField.approvalManualCloseComment === '' || subField.approvalManualCloseComment === null) ?
                                <Fragment>&nbsp;<button type="button" className={subField.approvalManualCloseComment === '' || subField.approvalManualCloseComment === null || subField.approvalManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Approval Manual Close"
                                  onClick={() => handleChildManualclose(subField.taskName, subField.taskType, subField.approvalTaskId, subField.approvalChildId, subField.statusID, subField.manualCloseComment)}
                                >
                                  <i className="fa fa-user"></i>&nbsp;
                                  <i className="fa fa-close"></i>
                                </button>
                                </Fragment>
                                : ''
                              }
                              &nbsp;




                              {
                                (subField.taskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || subField.taskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && subField.statusID === 3
                                  && (subField.childActualEndDate !== 'Manual' && subField.childActualEndDate !== 'Existing') && subField.manualCloseComment === '' ?
                                  <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                    onClick={() => OpenProtoFitSamplepopup(subField.tnaId, subField.childId, index,
                                      index, index, subField.sampleRequestName, subField.taskName, subField.tnaId, subField.statusID, subField.arrDependency, subField.intDependency)}
                                  >
                                    <i className="fa fa-eye "></i>
                                  </button> : ''
                              }
                              &nbsp;
                              {
                                subField.isShowApprovalButton === 1 && subField.manualCloseComment === '' ?
                                  <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                    onClick={() => OpenProtoFitApprovalpopup(subField.approvalChildId, index, index, index, subField.taskName, subField.approvalStatusID,
                                      subField.taskName === 'Proto Sample Submission' ? 7 : subField.taskName === 'Fit Sample Submission' ? 8 : 9, subField.arrDependency, subField.intDependency)}
                                  >
                                    <i className="fa fa-check"></i>
                                  </button> : ''
                              }

                              {
                                showmaunalclose === true ?
                                  <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowmaunalclose(false)}>
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        {manualcloseheader} Manual Close &nbsp;
                                        {manualcloseheader !== "Approval" ?
                                          <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(subField.childId, subField.taskName)}>
                                            <i className="fa fa-trash-o"></i>
                                          </button> : ''
                                        }

                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                        <thead>
                                          <tr>
                                            <th>Comments <span className="text-danger">*</span></th>
                                            <th>Close Date <span className="text-danger">*</span></th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          <tr>
                                            <td style={{ width: "600px" }} >
                                              <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled={manualcloseheader === 'View Submitted' ? true : false}
                                              >
                                              </textarea>
                                            </td>
                                            <td>
                                              <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                  "HolidayDate"
                                                }
                                                selected={
                                                  Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                                // isClearable={inputField.HolidayDate === '' ? false : true}
                                                disabled={manualcloseheader === 'View Submitted' ? true : false}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Style Name</th>
                                            <th>Style No</th>
                                            <th>PO/ID No</th>
                                            <th>Details</th>
                                            <th>Task Holder / Follower</th>
                                            <th>
                                              <label style={{ marginTop: '8px' }}>
                                                <input type="checkbox" name="IsSelected"

                                                  onChange={event => (SelectAll(event))}
                                                  checked={selectall === 1 ? true : false}
                                                  className="colored-blue clearOpacity" />
                                                <span className="text"></span>
                                              </label>&nbsp;&nbsp;&nbsp;
                                              {/* {StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency &&
                                                                                                                getSelectedDependencyMap(inputField.SelectedDependency) === false && subField.statusID !== 3 &&

                                                                                                                <button type="button" className={subField.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                    onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, subField.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, subField.ChildId, inputField.IsSingleTask)}
                                                                                                                >
                                                                                                                    <i className={subField.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                </button>
                                                                                                            } */}
                                            </th>

                                          </tr>
                                        </thead>
                                        <tbody>
                                          {

                                            MultiCloseDatas.map((item, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>{item.styleName}</td>
                                                    <td>{item.styleNo}</td>
                                                    <td>{item.idPoNo}</td>
                                                    <td>{item.details}</td>
                                                    <td>{item.taskOwnerNameList}</td>
                                                    <td className="Actionbutton fixed-column" style={{ display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                     activeMenu[0].isDelete === 0 ? 'none' : ''}}>
                                                      <div style={{ width: "80px" }}>
                                                        {/* {


                                                                                                                                    (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                    ) && */}
                                                        <label style={{ marginTop: '8px' }}>
                                                          <input type="checkbox" name="IsSelected"
                                                            onChange={event => (OnSelectRecords(event, index))}
                                                            checked={item.isSelected === 1 ? true : false}
                                                            className="colored-blue clearOpacity" />
                                                          <span className="text"></span>
                                                        </label>
                                                        {/* }&nbsp;&nbsp;&nbsp; */}

                                                        {/* {
                                                                                                                                    StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && subField.statusID !== 3 &&
                                                                                                                                }
                                                                                                                                    <button type="button" className={(item.dependencyStartstop === 1 || item.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                    // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, subField.intDependency, props.location.state.params.tnaid, inputField.TaskID, subField.ChildId, inputField.IsSingleTask)}
                                                                                                                                    >
                                                                                                                                        <i className={item.dependencyStartstop === 1 || item.isSelected === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                    </button>
                                                                                                                                     }  */}
                                                      </div>

                                                    </td>
                                                  </tr>
                                                </>

                                              )
                                            })
                                          }

                                        </tbody>
                                      </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      {
                                        manualcloseheader !== 'View Submitted' &&
                                        <Button variant="success" onClick={() => SaveChildManulaCloseComments(subField.taskName)}>
                                          Save
                                        </Button>


                                      }


                                    </Modal.Footer>
                                  </Modal> : ''
                              }
                            </td>



                          </tr>
                        ))
                        :
                        <tr><td colSpan="13" className='norecordfound'><span>No Records Found</span></td></tr>

                    }

                  </>



                </Fragment>
              </tbody>
            </table>
          </div>
        </form>

      </div>
      {
        getSampleSubModalpopup === true ?

          <Modal dialogClassName="commonwidth" show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {
                  TaskStatus === 3 && editProtoFitSmsSubmission === false ?
                    "View " : (editProtoFitSmsSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                }
                {ParentTask}
                {
                  TaskStatus === 3 ?
                    <>
                      <span title='Edit Form' onClick={() => seteditProtoFitSmsSubmission(true)}
                        style={{
                          pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '',
                          marginLeft: ParentTask === "Shipment Sample Submission" ? '620px' :
                            ParentTask === "Size Set Sample Submission" ? '620px' :
                              ParentTask === "Pre Production Sample Submission" ? '570px' : '800px'
                        }}
                        className="btn btn-info btn-xs edit">
                        <i className="fa fa-edit"></i>
                      </span>
                      &nbsp;
                      <span title='Delete Form'
                        style={{ pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '' }}
                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsSubmission(true)}>
                        <i className="fa fa-trash-o"></i>
                      </span>
                    </> : ''
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProtoFitSMSSubmissionOrder props={props}
                ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                TNAId={TNAId}
                TNAProtoFitId={TNAProtoFitId}
                ChildTask={ChildTask}
                ParentTask={ParentTask}
                getnewlist={getnewlist}
                StyleId={StyleId}
                TaskStatus={TaskStatus}
                editProtoFitSmsSubmission={editProtoFitSmsSubmission}
                delteProtoFitSmsSubmission={delteProtoFitSmsSubmission}
              ></ProtoFitSMSSubmissionOrder>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>

          </Modal> : ''
      }
      {
        getSampleAppModalpopup === true ?

          <Modal dialogClassName="commonwidth" show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {ParentTask}
                {/* {
                  TaskStatus === 3 && editProtoFitSmsApproval === false ?
                    "View " : editProtoFitSmsApproval === true ? 'Update ' : ''

                }
                //
                {
                  TaskStatus === 3 ?
                    <>
                      <span title='Edit Form' onClick={() => seteditProtoFitSmsApproval(true)}
                        className="btn btn-info btn-xs edit"
                        style={{
                          marginLeft: ParentTask === "Shipment Sample Approval" ? '620px' :
                            ParentTask === "Size Set Sample Approval" ? '620px'
                              : ParentTask === "Pre Production Sample Approval" ? '570px' : '800px'
                        }}>
                        <i className="fa fa-edit"></i>
                      </span>
                      &nbsp;
                      <span title='Delete Form'
                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsApproval(true)}>
                        <i className="fa fa-trash-o"></i>
                      </span>
                    </> : ''
                } */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProtoFitSMSApproval props={props}
                ProtoFitAprovalCallback={ProtoFitAprovalCallback}
                TNAId={TNAId}
                TNAProtoFitId={TNAProtoFitId}
                ChildTask={ChildTask}
                ParentTask={ParentTask}
                TaskStatus={TaskStatus}
                editProtoFitSmsApproval={editProtoFitSmsApproval}
                delteProtoFitSmsApproval={delteProtoFitSmsApproval}
              ></ProtoFitSMSApproval>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>

          </Modal> : ''
      }

      {getsID.showPopupDelete ? <SweetAlertPopup

        // data={''}
        data={
          getsID.Params
        }
        deleteCallback={DeleteCallback}
        showpopup={true}
        pageActionId={getsID.TNAManualClose}
        Msg={'Deleted Sucessfully'}
      /> : null}

    </div >

  )
}

export default ProtoFitViewSubmissionTab