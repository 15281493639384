import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import 'react-notifications-component/dist/theme.css';
import Form from "react-validation/build/form";
import Nodify from "../../../Common/ReactNotification";
import Reactselect from 'react-select';
import $ from "jquery";
import axios from "axios";
import DatePicker from "react-datepicker";
import ReactNotification from 'react-notifications-component';
import StyleService from "../../../../services/Style/styleService";
import GeneralService from "../../../../services/General/GeneralService";
import { Modal, Button } from "react-bootstrap";
import SearchRetain from "../../../Common/SearchRetain";
import SizeService from "../../../../services/Master/SizeService";
import { getTNAStrikeOffAlreadyMapList, updateStrikeoffMapList } from "../../../../actions/style";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-validation/build/select";
import Lightbox from "react-image-lightbox"
import strikeoffService from "../../../../services/Style/StrikeoffService";
import CollapsiblePanel from "../../../Common/CollapseExpandPanel";

const SamGenStrikeOffAddEdit = (props) => {



    const dispatch = useDispatch();
    // const StrikeOffViewDetailList = useSelector((state) => state.generalStrikeOff.generalStrikeOffViewDetailList);
    const StrikeOffViewDetailParams = useSelector((state) => state.generalStrikeOff.generalStrikeOffViewDetailParams);
    // let isLoadingGeneralStrikeOff = useSelector((state) => state.generalStrikeOff.isLoadingGeneralStrikeOff);

    const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state);
    const TNAStrikeOffMapList = reducerState.style.TNAStrikeOffMapList;
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    let SaveInfo = [];

    const emptyFields = [
        {
            Operation: 1,
            GenStrikeOffMainId: 0,
            BuyerId: 0,
            IsStoryMandatory: 0,
            IsThemeMandatory: 0,
            BrandId: 0,
            SeasonId: 0,
            ReceivedDate: '',
            StoryTheme: [{
                GenStrikeoffStoryThemeId: 0,
                StoryId: 0,
                ThemeId: 0,
                IndexName: '',
                Strikeoff: [{
                    GenStrikeoffId: 0,
                    StrikeoffId: 0,
                    StrikeoffCode: '',
                    StrikeoffName: '',
                    Quality: '',
                    PurchaseTypeName: '',
                    GenStrikeOffPurchaseType: 0,
                    GenStrikeOffApprovalType: 0,
                    Follower: '',
                    IsFolower: 0,
                    FollowerDisplay: '',
                    FollowerBuyer: '',
                    FollowerBuyerIds: '',
                    FollowerCube: '',
                    FollowerCubeIds: '',
                    Manual: 0,
                    Existing: 0,
                    IsStylemap: 0,
                    TrimSupplierName: '',
                    IsApprovalType: '',
                    SelectedMapRow: [],
                    IndexName: '',
                    StoryThemeIndexName: '',
                    CommonApprovalId: 0,
                    StyleMap: [{
                        GenStrikeOffStyleId: 0,
                        StyleName: '',
                        StoryThemeIndexName: '',
                        StrikeOffIndexName: '',
                        IndexName: '0'
                    }],
                    // PrintColor: [{
                    //     GenStrikeOffPrintColorID: 0,
                    //     StrikeOffID: 0,
                    //     StrikeOffPrintColorID: 0,
                    //     StoryThemeIndexName: '',
                    //     StrikeOffIndexName: '',
                    //     IndexName: ''
                    // }],
                    // Reference: [{
                    //     GenStrikeOffRefernceID: 0,
                    //     StrikeOffID: 0,
                    //     StrikeOffRefernceID: 0,
                    //     StyleStrikeOffGarmentSize: '',
                    //     StyleStrikeOffGarmentSizeEdit: '',
                    //     StoryThemeIndexName: '',
                    //     StrikeOffIndexName: '',
                    //     IndexName: ''

                    // }],
                    AllPrintColors: [],
                    AllPrintColorsInGeneral: [],
                    PrintColor: [],
                    AllReferenceList: [],
                    AllReferenceListInGeneral: [],
                    Reference: [],
                    ManualInfo: [{
                        GenStrikeOffManualId: 0,
                        StrikeOffId: 0,
                        ManualCloseDate: '',
                        ManualCloseComment: '',
                        StoryThemeIndexName: '',
                        StrikeOffIndexName: '',
                        IndexName: '',
                        IsDeleted: 0,
                        Files: []
                    }]

                }],


            }],
        }
    ];

    const selectAllOption = {
        value: "-1",
        label: "Select All Sizes"
    };

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [])
    // //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "StrikeOffList");
    }
    //End Enable Menu


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End
    // const { user: currentUser } = useSelector((state) => state.auth);
    const [inputFields, setInputFields] = useState(emptyFields);
    const [submitted, setSubmitted] = useState(false);
    const [getHeader, setHeader] = useState("Add");
    const history = useHistory();
    const selector = useSelector((state) => state);

    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    const [getBuyerList, setBuyerList] = useState([]);
    const [getSeasonList, setSeasonList] = useState([]);
    const [getBrandList, setBrandList] = useState([]);
    const [getStoryList, setStoryList] = useState([]);
    const [getThemeList, setThemeList] = useState([]);
    const [getFollowerCube, setFollowerCube] = useState([]);
    const [getFollowerBuyer, setFollowerBuyer] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getnewstyle, setnewstyle] = useState(0);


    const [Buttonvisible, SetButtonvisible] = useState("Show");
    const [Buttonview, SetButtonview] = useState("Hide");
    const [getDisableSave, setDisableSave] = useState(false);

    const [followerpopupvalidation, setfollowerpopupvalidation] = useState(false);

    const [selected, setselected] = useState(0);
    const [getAllStrikeoffList, setgetAllStrikeoffList] = useState([]);
    const [getApprovalList, setApprovalList] = useState([]);
    const [manualsubmit, setmanualsubmit] = useState(false);
    const [getSizeList, setSizeList] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const [comments, setComments] = useState([]);
    const [getStrikeoffList, setStrikeoffList] = useState([]);
    const [collapse, setCollapse] = useState(false);
    const [title, setTitle] = useState("Collapse All");
    const [icon, setIcon] = useState("fa fa-chevron-right");

    const [getPopupValue, setPopupValue] = useState([{ Notes: '', TargettoClose: '', CompletedOn: '', RemindMe: '' }]);
    const [getShow, setShow] = useState(false);
    const [ShowAddPrintColor, setShowAddPrintColor] = useState(false);
    const [ShowAddReference, setShowAddReference] = useState(false);
    const [AddPrintColorList, setAddPrintColorList] = useState([]);
    const [AddReferenceList, setAddReferenceList] = useState([]);
    const [submitteds, setSubmitteds] = useState(false);
    const [strikeoffforedit, setstrikeoffforedit] = useState();
    const [STindexforedit, setSTindexforedit] = useState();

    //new file state for get index in edit-->whch delete file update and add new file it will cause error
    const [newFilestate, setNewFileState] = useState([])

    const [existingStrikeoffList, setexistingStrikeoffList] = useState([]);

    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 160
            : $(window).width() + 50
        : $(window).width() + 100;

    let styles1 = {
        control: styles1 => ({ ...styles1, width: "350px" }),

    }

    let styles2 = {
        control: styles2 => ({ ...styles2, width: "350px", border: '1px solid red' }),
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    let Buyerstyles1 = {
        control: Buyerstyles1 => ({ ...Buyerstyles1, width: "350px" }),
        menuPortal: base => ({ ...base, zIndex: 999 })
    }
    let Buyerstyles2 = {
        control: Buyerstyles2 => ({ ...Buyerstyles2, width: "350px", border: '1px solid red' }),
        menuPortal: base => ({ ...base, zIndex: 999 })
    }

    let Storystyles1 = {
        control: Storystyles1 => ({ ...Storystyles1, width: "350px" }),
        menuPortal: base => ({ ...base, zIndex: 99 })
    }
    let Storystyles2 = {
        control: Storystyles2 => ({ ...Storystyles2, width: "350px", border: '1px solid red' }),
        menuPortal: base => ({ ...base, zIndex: 99 })
    }

    const collapseAll = () => {
        setCollapse(!collapse);
        setIcon(state => {
            return state === "fa fa-chevron-right"
                ? "fa fa-chevron-down"
                : "fa fa-chevron-right";
        });
        setTitle(state => {
            return state === "Expand All" ? "Collapse All" : "Expand All";
        });
    };
    const values = [...inputFields];
    useEffect(() => {

        let Msg;
        let Type = 'success'; // default, info, warning
        let title = 'Success!';
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            if (state1.params.message === 'Add') {
                Msg = 'Strikeoff added successfully.';
            } else {
                return;
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });

        strikeoffService.GetStrikeOffList(0).then((response) => {
            if (response.data) {
                setStrikeoffList(response.data);
            }
        }).catch(() => { });

        SizeService.LoadSizeList().then((response) => {
            if (response.data) {
                let list = response.data;
                // setSizeList([selectAllOption, ...list]);
                setSizeList(list);
            }
        }).catch(() => { });


    }, []);

    useEffect(() => {
       ;
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {
                   
                    //setBuyerList(response.data);
                    setBuyerList(response.data.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));

                    if (props.location.state.params) {
                        values[0].BuyerId = props.location.state.params.buyerId;
                        var BuyerInfo = response.data.filter(x => x.value === values[0].BuyerId);
                        values[0].IsStoryMandatory = BuyerInfo[0].isStoryMandatory;
                        values[0].IsThemeMandatory = BuyerInfo[0].isThemeMandatory;
                        LoadBrandSeasonStoryTheme(props.location.state.params.buyerId)
                        values[0].BrandId = props.location.state.params.brandId;
                        values[0].SeasonId = props.location.state.params.seasonId;
                        setInputFields(values);
                    }
                }
            })
            .catch(() => { });

        StyleService.LoadSeason(1, 0)
            .then((response) => {
                if (response.data) {
                    setSeasonList(response.data);
                }
            })
            .catch(() => { });
        strikeoffService.LoadApprovalTypeList().then((response) => {
            if (response.data) {
                setApprovalList(response.data);
            }
        }).catch(() => { });
        if (props.location.state !== undefined) {
            if (props.location.state.params && props.location.state.params.genStrikeOffId !== 0 && props.location.state.params.genStrikeOffId !== undefined) {
                setHeader("Edit")
                GeneralService.GetSelectedGenStrikeoff(props.location.state.params.genStrikeOffId).then((response) => {
                    if (response.data) {
                        let mainDetails = response.data.genStrikeOffMainDetails[0];
                        let storyThemeListInfo = response.data.genStrikeOffStoryThemeDetails[0];
                        let genStrikeOffDetails = response.data.genStrikeOffDetails[0];
                        let genStrikeOffStyleDetails = response.data.genStrikeOffStyleDetails;
                        let genStrikeOffRefernceDetails = response.data.genStrikeOffRefernceDetails;
                        let genStrikeOffLogoDetails = response.data.genStrikeOffLogoDetails;
                        let genStrikeOffManualCommentDetails = response.data.genStrikeOffManualCommentDetails[0];
                        let genStrikeOffManualFileUploadDetails = response.data.genStrikeOffManualFileUploadDetails;
                        let commonApproval = response.data.commonApprovalDetails[0];
                        values[0].Operation = 1;
                        values[0].GenStrikeOffMainId = mainDetails.genStrikeoffMainId;
                        values[0].BuyerId = mainDetails.buyerId;

                        values[0].BrandId = mainDetails.brandId;
                        values[0].SeasonId = mainDetails.seasonId;
                        values[0].ReceivedDate = new Date(mainDetails.recievedDate);

                        LoadBrandSeasonStoryTheme(values[0].BuyerId);
                        strikeoffService.LoadStrikeOffBuyerBrandList(values[0].BuyerId, values[0].BrandId).then((response) => {
                            if (response.data) {
                                setgetAllStrikeoffList(response.data);
                            }
                        }).catch(() => { });
                        if (values[0].BuyerId !== 0 && values[0].BrandId !== 0) {
                            strikeoffService.GetStrikeOffBuyerBrandPrintColorList(values[0].BuyerId, values[0].BrandId).then((response) => {
                                if (response.data) {

                                    setComments(response.data);
                                }
                            }).catch(() => { });

                        }


                        values[0].StoryTheme[0].GenStrikeoffStoryThemeId = storyThemeListInfo.genStrikeOffStoryThemeId;
                        values[0].StoryTheme[0].StoryId = storyThemeListInfo.storyId;
                        values[0].StoryTheme[0].ThemeId = storyThemeListInfo.themeId;
                        values[0].StoryTheme[0].IndexName = storyThemeListInfo.indexName;
                        values[0].StoryTheme[0].Strikeoff[0].GenStrikeoffId = genStrikeOffDetails.genStrikeOffId;
                        values[0].StoryTheme[0].Strikeoff[0].StrikeoffId = genStrikeOffDetails.strikeoffId;
                        //  var Pantonetype = getPantoneType.filter(x => x.pantoneTypeID === parseInt(genStrikeOffStyleDetails.pantoneType));

                        values[0].StoryTheme[0].Strikeoff[0].StrikeoffName = genStrikeOffDetails.strikeoffName;
                        values[0].StoryTheme[0].Strikeoff[0].Quality = genStrikeOffDetails.quality;
                        values[0].StoryTheme[0].Strikeoff[0].PurchaseTypeName = genStrikeOffDetails.purchaseTypeName;
                        if (genStrikeOffDetails.purchaseTypeName === "Local") {
                            values[0].StoryTheme[0].Strikeoff[0].GenStrikeOffPurchaseType = 3;
                        }
                        else if (genStrikeOffDetails.purchaseTypeName === "Buyer Nominated") {
                            values[0].StoryTheme[0].Strikeoff[0].GenStrikeOffPurchaseType = 1;
                        }
                        // values[0].StoryTheme[0].Strikeoff[0].GenStrikeOffPurchaseType = genStrikeOffDetails.PurchaseTypeName;
                        values[0].StoryTheme[0].Strikeoff[0].GenStrikeOffApprovalType = genStrikeOffDetails.approvalTypeId;
                        // values[0].StoryTheme[0].Strikeoff[0].IsFolower = genStrikeOffDetails.isFolower;
                        values[0].StoryTheme[0].Strikeoff[0].Follower = genStrikeOffDetails.follower;
                        values[0].StoryTheme[0].Strikeoff[0].FollowerDisplay = genStrikeOffDetails.followerName;
                        values[0].StoryTheme[0].Strikeoff[0].FollowerBuyer = genStrikeOffDetails.followerBuyer;
                        values[0].StoryTheme[0].Strikeoff[0].FollowerCube = genStrikeOffDetails.followerCube;
                        values[0].StoryTheme[0].Strikeoff[0].TrimSupplierName = genStrikeOffDetails.trimSupplierName;
                        values[0].StoryTheme[0].Strikeoff[0].IndexName = genStrikeOffDetails.indexName;
                        values[0].StoryTheme[0].Strikeoff[0].StoryThemeIndexName = genStrikeOffDetails.storyThemeIndexName;
                        setstrikeoffforedit(genStrikeOffDetails.indexName);
                        setSTindexforedit(genStrikeOffDetails.storyThemeIndexName);
                        strikeoffService.GetStrikeOffLogoInformationList(parseInt(genStrikeOffDetails.strikeoffId)).then((response) => {

                            if (response.data) {

                                let returnList = response.data.filter(x => x.strikeoffLogoName !== null);
                                values[0].StoryTheme[0].Strikeoff[0].AllPrintColors = (returnList);

                                strikeoffService.GetStrikeOffReferenceList(parseInt(genStrikeOffDetails.strikeoffId)).then((response) => {
                                    if (response.data) {
                                        values[0].StoryTheme[0].Strikeoff[0].AllReferenceList = (response.data);

                                    }
                                }).catch(() => { });
                            }
                        }).catch(() => { });

                        GeneralService.GetStrikeOffReferenceListInGeneral(parseInt(genStrikeOffDetails.strikeoffId)).then((result) => {
                            if (result.data) {
                                values[0].StoryTheme[0].Strikeoff[0].AllReferenceListInGeneral = (result.data);

                            }
                        }).catch(() => { });

                        GeneralService.GetStrikeOffLogoInformationListInGeneral(parseInt(genStrikeOffDetails.strikeoffId)).then((result1) => {
                            if (result1.data) {
                                values[0].StoryTheme[0].Strikeoff[0].AllPrintColorsInGeneral = (result1.data);

                            }
                        }).catch(() => { });

                        GeneralService.LoadFollowerCubeBuyerList(values[0].BuyerId, currentUser.employeeinformationID).then((response) => {
                            if (response.data) {

                                let arrcube = genStrikeOffDetails.followerCube.split(',');
                                let arrValcube = [];
                                for (let size of arrcube) {
                                    let res = response.data.followerCubeDropdown.filter(x => x.value === parseInt(size));
                                    if (res && res.length > 0) {
                                        arrValcube && arrValcube.push({
                                            value: res[0].value,
                                            label: res[0].label
                                        });
                                    }

                                }

                                let arrbuyer = genStrikeOffDetails.followerBuyer.split(',');
                                let arrValbuyer = [];
                                for (let size of arrbuyer) {
                                    let res = response.data.followerBuyerDropdown.filter(x => x.value === parseInt(size));
                                    arrValbuyer.push({
                                        value: res[0].value,
                                        label: res[0].label
                                    });
                                }

                                values[0].StoryTheme[0].Strikeoff[0].FollowerCubeIds = arrValcube;
                                values[0].StoryTheme[0].Strikeoff[0].FollowerBuyerIds = arrValbuyer;


                            }
                        });


                        if (genStrikeOffStyleDetails.length !== 0) {
                            values[0].StoryTheme[0].Strikeoff[0].StyleMap = [];
                            genStrikeOffStyleDetails.forEach(element => {
                                values[0].StoryTheme[0].Strikeoff[0].StyleMap.push({
                                    GenStrikeOffStyleId: element.genStrikeOffStyleId,
                                    StyleName: element.styleName,
                                    StoryThemeIndexName: element.storyThemeIndexName,
                                    StrikeOffIndexName: element.strikeOffIndexName,
                                    IndexName: element.indexName

                                })
                            });

                        }

                        if (genStrikeOffRefernceDetails.length !== 0) {
                            let arrVal = [];
                            genStrikeOffRefernceDetails.filter(x => x.genStrikeOffRefernceId === props.location.state.params.genStrikeOffRefernceID).forEach((element, index) => {
                                values[0].StoryTheme[0].Strikeoff[0].Reference.push({
                                    genStrikeOffRefernceId: element.genStrikeOffRefernceId,
                                    strikeOffReferenceID: element.strikeOffRefernceId,
                                    height: element.height,
                                    width: element.width,
                                    sizeName: element.sizeName,
                                    StyleStrikeOffGarmentSize: element.strikeOffGarmentSizeId,
                                    StyleStrikeOffGarmentSizeEdit: '',
                                    StoryThemeIndexName: element.storyThemeIndexName,
                                    StrikeOffIndexName: element.strikeOffIndexName,
                                    IndexName: element.indexName
                                })
                                if (genStrikeOffRefernceDetails[index].strikeOffGarmentSizeId !== null &&
                                    genStrikeOffRefernceDetails[index].strikeOffGarmentSizeId !== '') {
                                    let arr = genStrikeOffRefernceDetails[index].strikeOffGarmentSizeId.split(',');
                                    for (let size of arr) {
                                        let res = props.location.state.SizeList.filter(x => x.value === parseInt(size));
                                        arrVal.push({
                                            value: res[0].value,
                                            label: res[0].label
                                        });
                                    }
                                    values[0].StoryTheme[0].Strikeoff[0].Reference[index].StyleStrikeOffGarmentSizeEdit = arrVal;
                                }



                            })

                        }

                        if (genStrikeOffLogoDetails.length !== 0) {
                            genStrikeOffLogoDetails.filter(x => x.genStrikeOffLogoId === props.location.state.params.genStrikeOffLogoID).forEach(element => {
                                values[0].StoryTheme[0].Strikeoff[0].PrintColor.push({
                                    genStrikeoffLogoId: element.genStrikeOffLogoId,
                                    colorGroupID: element.strikeOffPrintColorGroupId,
                                    strikeoffLogoPath: element.strikeoffLogoPath,
                                    indexName: element.indexName,
                                    strikeOffID: element.strikeOffId,
                                    StoryThemeIndexName: element.storyThemeIndexName,
                                    StrikeOffIndexName: element.strikeOffIndexName,
                                    IndexName: element.indexName
                                })
                            })
                        }

                        if (genStrikeOffManualCommentDetails && genStrikeOffManualCommentDetails.length !== 0) {
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].GenStrikeOffManualId = genStrikeOffManualCommentDetails.genStrikeOffManualId;
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].ManualCloseComment = genStrikeOffManualCommentDetails.manualCloseComment;
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].ManualCloseDate = genStrikeOffManualCommentDetails.manualCloseDate;
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].StoryThemeIndexName = genStrikeOffManualCommentDetails.storyThemeIndexName;
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].StrikeOffIndexName = genStrikeOffManualCommentDetails.strikeOffIndexName;
                            values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].IndexName = genStrikeOffManualCommentDetails.indexName;
                            // values[0].StoryTheme[0].Program[0].IsManualInfo[0].CheckSave = true;


                        }
                        if (genStrikeOffManualFileUploadDetails && genStrikeOffManualFileUploadDetails.length !== 0) {
                            genStrikeOffManualFileUploadDetails.map((fileDetail, fileIndex) => {
                                values[0].StoryTheme[0].Strikeoff[0].ManualInfo[0].Files.push({
                                    genStrikeoffManulFileId: fileDetail.genStrikeOffManualFilelId,
                                    fileType: fileDetail.fileType,
                                    fileName: fileDetail.fileName,
                                    filePath: fileDetail.filePath,
                                    StoryThemeIndexName: fileDetail.storyThemeIndexName,
                                    StrikeOffIndexName: fileDetail.strikeOffIndexName,
                                    IndexName: fileDetail.indexName
                                })
                            })

                        }
                        if (commonApproval && commonApproval.length !== 0) {
                            values[0].StoryTheme[0].Strikeoff[0].SelectedMapRow.push({
                                SeasonName: commonApproval.seasonName,
                                ApprovalDate: commonApproval.approvalDate,
                                Details: commonApproval.details,
                                CommonApprovalId: commonApproval.commonApprovalId,
                                ApprovedBy: commonApproval.approvedBy

                            })

                        }
                        setInputFields(values);

                    }
                }).catch(() => { });
            }
            if (props.location.state.params) {
                values[0].BuyerId = props.location.state.params.buyerId;
                LoadBrandSeasonStoryTheme(props.location.state.params.buyerId)
                values[0].BrandId = props.location.state.params.brandId;
                values[0].SeasonId = props.location.state.params.seasonId;

                strikeoffService.LoadStrikeOffBuyerBrandList(props.location.state.params.buyerId,
                    props.location.state.params.brandId).then((response) => {
                        if (response.data) {
                            setgetAllStrikeoffList(response.data);
                        }
                    }).catch(() => { });

                strikeoffService.GetStrikeOffBuyerBrandPrintColorList(values[0].BuyerId, values[0].BrandId).then((response) => {
                    if (response.data) {

                        setComments(response.data);
                    }
                }).catch(() => { });

                setInputFields(values);
            }
            else {
                getStrikeoffList[0].BuyerID = props.location.state.params.buyerId;
                getStrikeoffList[0].BuyerBrandID = props.location.state.params.brandId;
                strikeoffService.LoadBrandThemeStoryEndCustomer(6, 0).then((response) => {
                    if (response.data) {
                        let respected_Brand = response.data.filter(x => (x.buyerID === parseInt(props.location.state.params.buyerId)) && (x.buyerMapID === 1));
                        setStrikeoffList(respected_Brand);



                    }
                }).catch(() => { });
                setStrikeoffList(getStrikeoffList);
            }
        }
    }, []);

    function LoadBrandSeasonStoryTheme(ID) {
        if (!isNaN(ID)) {
            StyleService.LoadBrandThemeStoryEndCustomer(5, parseInt(ID)).then((response) => {
                if (response.data) {
                    let BuyerInfoList = response.data;
                    let respected_Brand = BuyerInfoList.filter(x => x.buyerMapID === 1);
                    setBrandList(respected_Brand);
                    let respected_Story = BuyerInfoList.filter(x => x.buyerMapID === 3);
                    setStoryList(respected_Story);
                    let respected_Theory = BuyerInfoList.filter(x => x.buyerMapID === 4);
                    setThemeList(respected_Theory);

                }
            }).catch(() => { });



            GeneralService.LoadFollowerCubeBuyerList(ID, currentUser.employeeinformationID).then((response) => {
                if (response.data) {
                    setFollowerCube(response.data.followerCubeDropdown);
                    setFollowerBuyer(response.data.followerBuyerDropdown);

                }
            });
        }
    }






    const handleAddFields = (operation, index, STindex) => {
        let IsValid = true;
        let IsValidStrikeoff = true;
        if (values[index].StoryTheme[STindex].StoryId !== 0 && values[index].StoryTheme[STindex].ThemeId !== 0) {

            values[index].StoryTheme[STindex].Strikeoff.forEach(element1 => {
                if (element1.StrikeOffId === 0 || element1.FollowerDisplay === '') {
                    IsValidStrikeoff = false;

                }
            })

            if (IsValidStrikeoff) {
                let Emptylist = {};
                var copy = values[index].StoryTheme[STindex];
                if (operation === "Add") {
                    Emptylist = {
                        GenStrikeoffStoryThemeId: 0,
                        StoryId: 0,
                        ThemeId: 0,
                        IndexName: '',
                        Strikeoff: [{
                            GenStrikeoffId: 0,
                            StrikeoffId: 0,
                            StrikeoffCode: '',
                            StrikeoffName: '',
                            Quality: '',
                            PurchaseTypeName: '',
                            GenStrikeOffPurchaseType: 0,
                            GenStrikeOffApprovalType: 0,
                            Follower: '',
                            IsFolower: 0,
                            FollowerDisplay: '',
                            FollowerBuyer: '',
                            FollowerBuyerIds: '',
                            FollowerCube: '',
                            FollowerCubeIds: '',
                            Manual: 0,
                            Existing: 0,
                            IsStylemap: 0,
                            TrimSupplierName: '',
                            IsApprovalType: '',
                            SelectedMapRow: [],
                            IndexName: '',
                            StoryThemeIndexName: '',
                            CommonApprovalId: 0,
                            StyleMap: [{
                                GenStrikeOffStyleId: 0,
                                StyleName: '',
                                StoryThemeIndexName: '',
                                StrikeOffIndexName: '',
                                IndexName: ''
                            }],
                            // PrintColor: [{
                            //     GenStrikeOffPrintColorID: 0,
                            //     StrikeOffID: 0,
                            //     StrikeOffPrintColorID: 0,
                            //     StoryThemeIndexName: '',
                            //     StrikeOffIndexName: '',
                            //     IndexName: ''
                            // }],
                            // Reference: [{
                            //     GenStrikeOffRefernceID: 0,
                            //     StrikeOffID: 0,
                            //     StrikeOffRefernceID: 0,
                            //     StyleStrikeOffGarmentSize: '',
                            //     StyleStrikeOffGarmentSizeEdit: '',
                            //     StoryThemeIndexName: '',
                            //     StrikeOffIndexName: '',
                            //     IndexName: ''

                            // }],
                            PrintColor: [],
                            Reference: [],
                            ManualInfo: [{
                                GenStrikeOffManualId: 0,
                                StrikeOffId: 0,
                                ManualCloseDate: '',
                                ManualCloseComment: '',
                                StoryThemeIndexName: '',
                                StrikeOffIndexName: '',
                                IndexName: '',
                                IsDeleted: 0,
                                Files: []
                            }]
                        }],
                    }
                }
                else {
                    let CopyStrikeoff = [];

                    copy.Strikeoff.forEach((item, index) => {
                        CopyStrikeoff.push({
                            GenStrikeoffId: 0,
                            StrikeoffId: item.StrikeoffId,
                            StrikeoffCode: item.StrikeoffCode,
                            StrikeoffName: item.StrikeoffName,
                            Quality: item.Quality,
                            PurchaseTypeName: item.PurchaseTypeName,
                            GenStrikeOffPurchaseType: item.GenStrikeOffPurchaseType,
                            GenStrikeOffApprovalType: item.GenStrikeOffApprovalType,
                            Follower: item.Follower,
                            IsFolower: item.IsFolower,
                            FollowerDisplay: item.FollowerDisplay,
                            FollowerBuyer: item.FollowerBuyer,
                            FollowerBuyerIds: item.FollowerBuyerIds,
                            FollowerCube: item.FollowerCube,
                            FollowerCubeIds: item.FollowerCubeIds,
                            Manual: item.Manual,
                            Existing: item.Existing,
                            IsStylemap: item.IsStylemap,
                            TrimSupplierName: item.TrimSupplierName,
                            IsApprovalType: item.IsApprovalType,
                            SelectedMapRow: item.SelectedMapRow,
                            IndexName: '',
                            StoryThemeIndexName: '',
                            CommonApprovalId: item.CommonApprovalId,
                            StyleMap: [],
                            PrintColor: item.PrintColor,
                            Reference: [],
                            ManualInfo: []
                        })

                        item.Reference.forEach((refitem, refindex) => {
                            CopyStrikeoff[index].Reference.push({
                                genStrikeOffRefernceId: 0,
                                strikeOffReferenceID: refitem.strikeOffReferenceID,
                                height: refitem.height,
                                width: refitem.width,
                                sizeName: refitem.sizeName,
                                StyleStrikeOffGarmentSize: refitem.StyleStrikeOffGarmentSize,
                                StyleStrikeOffGarmentSizeEdit: refitem.StyleStrikeOffGarmentSizeEdit

                            })
                        })

                        item.ManualInfo.forEach((manitem, index) => {
                            CopyStrikeoff[index].ManualInfo.push({
                                GenStrikeOffManualId: 0,
                                StrikeOffId: manitem.StrikeOffId,
                                ManualCloseDate: manitem.ManualCloseDate,
                                ManualCloseComment: manitem.ManualCloseComment,
                                Files: []
                            })

                            manitem.Files.forEach(fileitem => {
                                CopyStrikeoff[index].ManualInfo[index].Files.push({
                                    genStrikeOffManualFilelId: 0,
                                    fileName: fileitem.name,
                                    filePath: "Images/TNAImages/" + fileitem.name,
                                    fileType: fileitem.type,
                                })
                            })
                        })

                        item.StyleMap.forEach((stlitem, index) => {
                            CopyStrikeoff[index].StyleMap.push({
                                GenStrikeOffStyleId: 0,
                                StyleName: stlitem.StyleName

                            })
                        })
                    })



                    Emptylist = {
                        GenStrikeoffStoryThemeId: copy.GenStrikeoffStoryThemeId,
                        StoryId: copy.StoryId,
                        ThemeId: copy.ThemeId,
                        IndexName: copy.IndexName,
                        Strikeoff: CopyStrikeoff
                    }

                }
                values[index].StoryTheme.splice(STindex + 1, 0, Emptylist);
                setSubmitted(false);
                setInputFields(values);
            }
            else {
                Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields in pantone section.");
                setSubmitted(true);
                return false;
            }
        }
        else {
            Nodify('Warning!', 'warning', "Please fill all mandatory(*) fields.");
            setSubmitted(true);
            IsValid = false;
        }
    }

    const handleRemoveFields = (index, subindex) => {
        values[index].StoryTheme.splice(subindex, 1);
        setInputFields(values);

    };
    const handleAddFieldsStrikeoff = (operation, index, stindex, soindex) => {
        setSubmitted(false);
        let isvalid = true;

        var copy = values[index].StoryTheme[stindex].Strikeoff[soindex];
        values[index].StoryTheme[stindex].Strikeoff.forEach(element1 => {
            if (element1.StrikeoffId === 0
                || element1.FollowerDisplay === '') {
                isvalid = false;

            }
        })
        if (isvalid === false) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            let EmptyList = {};
            if (operation === "Add") {
                EmptyList = {
                    GenStrikeoffId: 0,
                    StrikeoffId: 0,
                    StrikeoffCode: 0,
                    StrikeoffName: '',
                    Quality: '',
                    PurchaseTypeName: '',
                    GenStrikeOffPurchaseType: '',
                    GenStrikeOffApprovalType: 0,
                    Follower: '',
                    IsFolower: 0,
                    FollowerDisplay: '',
                    FollowerBuyer: '',
                    FollowerBuyerIds: '',
                    FollowerCube: '',
                    FollowerCubeIds: '',
                    Manual: 0,
                    Existing: 0,
                    IsStylemap: 0,
                    TrimSupplierName: '',
                    IsApprovalType: '',
                    SelectedMapRow: [],
                    IndexName: '',
                    StoryThemeIndexName: '',
                    CommonApprovalId: 0,
                    StyleMap: [{
                        GenStrikeOffStyleId: 0,
                        StyleName: '',
                        StoryThemeIndexName: '',
                        StrikeOffIndexName: '',
                        IndexName: ''
                    }],

                    PrintColor: [],
                    Reference: [],
                    ManualInfo: [{
                        GenStrikeOffManualId: 0,
                        StrikeOffId: 0,
                        ManualCloseDate: '',
                        ManualCloseComment: '',
                        StoryThemeIndexName: '',
                        StrikeOffIndexName: '',
                        IndexName: '',
                        IsDeleted: 0,
                        Files: []
                    }]

                }
            }
            else {
                let CopyReference = [];
                let CopyManual = [];
                let CopyStyleMap = [];

                copy.Reference.forEach((refitem, index) => {
                    CopyReference.push({
                        genStrikeOffRefernceId: 0,
                        strikeOffReferenceID: refitem.strikeOffReferenceID,
                        height: refitem.height,
                        width: refitem.width,
                        sizeName: refitem.sizeName,
                        StyleStrikeOffGarmentSize: refitem.StyleStrikeOffGarmentSize,
                        StyleStrikeOffGarmentSizeEdit: refitem.StyleStrikeOffGarmentSizeEdit

                    })
                })

                copy.ManualInfo.forEach((manitem, index) => {
                    CopyManual.push({
                        GenStrikeOffManualId: 0,
                        StrikeOffId: manitem.StrikeOffId,
                        ManualCloseDate: manitem.ManualCloseDate,
                        ManualCloseComment: manitem.ManualCloseComment,
                        Files: []
                    })

                    manitem.Files.forEach(fileitem => {
                        CopyManual[index].Files.push({
                            genStrikeOffManualFilelId: 0,
                            fileName: fileitem.name,
                            filePath: "Images/TNAImages/" + fileitem.name,
                            fileType: fileitem.type,
                        })
                    })
                })

                copy.StyleMap.forEach((stlitem, index) => {
                    CopyStyleMap.push({
                        GenStrikeOffStyleId: 0,
                        StyleName: stlitem.StyleName

                    })
                })

                EmptyList = {
                    GenStrikeoffId: 0,
                    StrikeoffId: copy.StrikeoffId,
                    StrikeoffCode: copy.StrikeoffCode,
                    StrikeoffName: copy.StrikeoffName,
                    Quality: copy.Quality,
                    PurchaseTypeName: copy.PurchaseTypeName,
                    GenStrikeOffPurchaseType: copy.GenStrikeOffPurchaseType,
                    GenStrikeOffApprovalType: copy.GenStrikeOffApprovalType,
                    Follower: copy.Follower,
                    IsFolower: copy.IsFolower,
                    FollowerDisplay: copy.FollowerDisplay,
                    FollowerBuyer: copy.FollowerBuyer,
                    FollowerBuyerIds: copy.FollowerBuyerIds,
                    FollowerCube: copy.FollowerCube,
                    FollowerCubeIds: copy.FollowerCubeIds,
                    Manual: copy.Manual,
                    Existing: copy.Existing,
                    IsStylemap: copy.IsStylemap,
                    TrimSupplierName: copy.TrimSupplierName,
                    IsApprovalType: copy.IsApprovalType,
                    SelectedMapRow: copy.SelectedMapRow,
                    IndexName: '',
                    StoryThemeIndexName: '',
                    CommonApprovalId: copy.CommonApprovalId,
                    // StyleMap: [{
                    //     GenStrikeOffStyleId: 0,
                    //     StyleName: '',
                    //     StoryThemeIndexName: '',
                    //     StrikeOffIndexName: '',
                    //     IndexName: ''
                    // }],
                    StyleMap: CopyStyleMap,
                    PrintColor: copy.PrintColor,
                    Reference: CopyReference,
                    ManualInfo: CopyManual
                    // ManualInfo: [{
                    //     GenStrikeOffManualId: 0,
                    //     StrikeOffId: 0,
                    //     ManualCloseDate: '',
                    //     ManualCloseComment: '',
                    //     StoryThemeIndexName: '',
                    //     StrikeOffIndexName: '',
                    //     IndexName: '',
                    //     Files: []
                    // }]

                }
            }
            values[index].StoryTheme[stindex].Strikeoff.splice(soindex + 1, 0, EmptyList);
            setInputFields(values);
        }

    }
    const handleRemoveFieldsStrikeoff = (index, stindex, soindex) => {
        values[index].StoryTheme[stindex].Strikeoff.splice(soindex, 1);
        setInputFields(values);
    }

    const handleAddPrintColor = (index, stindex, soindex, pcindex) => {

        let CurrePrintColorList = values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor;
        let AllPrintColor = values[index].StoryTheme[stindex].Strikeoff[soindex].AllPrintColors;
        let AllPrintColorsInGeneral = values[index].StoryTheme[stindex].Strikeoff[soindex].AllPrintColorsInGeneral;
        if (AllPrintColor.length === AllPrintColorsInGeneral.length) {
            Nodify("Warning!", "warning", "All PrintColors already added for this Strikeoff.");
        }
        else {
            setShowAddPrintColor(true);
            let BalList = AllPrintColor.filter((page1) => !CurrePrintColorList.find(page2 => page1.colorGroupID === page2.colorGroupID))
            setAddPrintColorList(BalList);
        }

    }

    const handleRemovePrintColor = (index, stindex, soindex, pcindex, Id) => {
        let filter = values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor.filter(obj => obj.colorGroupID !== Id)
        values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor = filter;
        setInputFields(values);
    }

    const handleAddRef = (index, stindex, soindex, rindex) => {
        //  strikeOffReferenceID

        let CurreReferenceList = values[index].StoryTheme[stindex].Strikeoff[soindex].Reference;
        let AllReferenceList = values[index].StoryTheme[stindex].Strikeoff[soindex].AllReferenceList;
        let AllReferenceListInGeneral = values[index].StoryTheme[stindex].Strikeoff[soindex].AllReferenceListInGeneral;
        if (AllReferenceList.length === AllReferenceListInGeneral.length) {
            Nodify("Warning!", "warning", "All References already added for this Strikeoff.");
        }
        else {
            setShowAddReference(true);
            let BalList = AllReferenceList.filter((page1) => !CurreReferenceList.find(page2 => page1.strikeOffReferenceID === page2.strikeOffReferenceID))
            setAddReferenceList(BalList);
        }
    }

    const handleRemoveRef = (index, stindex, soindex, rindex, Id) => {

        let filter = values[index].StoryTheme[stindex].Strikeoff[soindex].Reference.filter(obj => obj.strikeOffReferenceID !== Id)
        values[index].StoryTheme[stindex].Strikeoff[soindex].Reference = filter;
        setInputFields(values);
    }

    const OnSelectAddReference = (event, index) => {
        var values = [...AddReferenceList];
        if (event.target.checked === true) {
            values[index].isSelected = 1;
        } else {
            values[index].isSelected = 0;
        }
        setAddReferenceList(values);

    }

    const OnSelectAddPrintColors = (event, index) => {
        var values = [...AddPrintColorList];
        if (event.target.checked === true) {
            values[index].isSelected = 1;
        } else {
            values[index].isSelected = 0;
        }
        setAddPrintColorList(values);

    }

    const AddReference = (index, stindex, soindex, refindex) => {

        let FileteredRecords = AddReferenceList.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            FileteredRecords.forEach((refitem) => {
                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference.push({
                    genStrikeOffRefernceId: 0,
                    strikeOffReferenceID: refitem.strikeOffReferenceID,
                    height: parseInt(refitem.height),
                    width: parseInt(refitem.width),
                    sizeName: refitem.sizeName,
                    StyleStrikeOffGarmentSize: '',
                    StyleStrikeOffGarmentSizeEdit: ''
                })
            })
            setInputFields(values);
            setShowAddReference(false);
        }
    }

    const AddPrintColor = (index, stindex, soindex, refindex) => {

        let FileteredRecords = AddPrintColorList.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            FileteredRecords.forEach((refitem) => {
                values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor.push({
                    genStrikeoffLogoId: 0,
                    colorGroupID: refitem.colorGroupID,
                    strikeoffLogoPath: refitem.strikeoffLogoPath,
                    indexName: refitem.indexName,
                    strikeOffID: refitem.strikeOffID
                })
            })
            setInputFields(values);
            setShowAddPrintColor(false);
        }
    }



    const handlechange = (e, index, feild) => {
        if (e === null) {
            values[index][feild] = 0;
        }
        else {
            if (e.length === 0) {
                values[index][feild] = 0;
            }
            else {
                if (feild !== "ReceivedDate") {
                    values[index][feild] = e.value;
                    if (feild === "BuyerId") {
                        LoadBrandSeasonStoryTheme(e.value);
                        values[index].IsStoryMandatory = e.isStoryMandatory;
                        values[index].IsThemeMandatory = e.isThemeMandatory;
                    }
                }
                else {
                    values[index][feild] = e;
                }
            }

        }
        strikeoffService.LoadStrikeOffBuyerBrandList(values[0].BuyerId, values[0].BrandId).then((response) => {
            if (response.data) {
                setgetAllStrikeoffList(response.data);
            }
        }).catch(() => { });
        if (values[0].BuyerId !== 0 && values[0].BrandId !== 0) {
            strikeoffService.GetStrikeOffBuyerBrandPrintColorList(values[0].BuyerId, values[0].BrandId).then((response) => {
                if (response.data) {

                    setComments(response.data);
                }
            }).catch(() => { });

        }
        setInputFields(values);

    }



    const ShowCritical = (notes, targettoclose, completedon, remindme) => {
        setShow(true);
        getPopupValue[0].Notes = notes;
        getPopupValue[0].TargettoClose = targettoclose;
        getPopupValue[0].CompletedOn = completedon;
        getPopupValue[0].RemindMe = remindme;
        setPopupValue(getPopupValue);
    }
    const handleClose = () => {
        setShow(false);
    };


    const handleChangeStoryTheme = (e, index, subchild, feild) => {
        if (e === null) {
            values[index].StoryTheme[subchild][feild] = 0;
        }
        else {

            if (e.length === 0) {
                values[index].StoryTheme[subchild][feild] = 0;
            }
            else {
                values[index].StoryTheme[subchild][feild] = e.value;
            }
        }
        setInputFields(values);
    }

    const showCubeFollowePopup = (index, childindex, subchildindex) => {
        if (values[0].BuyerId === 0) {
            Nodify("Warning!", "warning", "Please Select Buyer");
            // setSubmitted(true);
            return false;
        }
        else {
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].IsFolower = 1;
            setInputFields(values);
        }
    }
    const hideCubeFollowePopup = (index, childindex, subchildindex) => {
        let followerDisplay = values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerDisplay;
        if (followerDisplay == "" || followerDisplay == null || followerDisplay == undefined) {
            setfollowerpopupvalidation(false);
        }
        values[index].StoryTheme[childindex].Strikeoff[subchildindex].IsFolower = 0;
        values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds = "";
        values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds = ""

        setInputFields(values);
    }
    const handleChangeFollower = (event, index, childindex, subchildindex, feild) => {
        if (feild === "Buyer") {
            if (event.length === 0) {
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyer = "";
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds = "";
            } else {
                let strValue = "";
                for (let Sku of event) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyer = strValue;
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds = event;
            }
        }
        else {
            if (event.length === 0) {
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCube = "";
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds = "";
            } else {
                let strValue = "";
                for (let Sku of event) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCube = strValue;
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds = event;
            }
        }


        setInputFields(values);
    }

    const handleSaveFollower = (index, childindex, subchildindex) => {
        if (values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds === "" ||
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds === "") {
            Nodify('Warning!', 'warning', "Please select atleast one Cube and Buyer");
            setfollowerpopupvalidation(true);
            return false;
        }
        else {
            let followebuyers = '';
            let followecubes = '';
            let followebuyersdisplay = '';
            let followecubesdisplay = '';
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds &&
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerBuyerIds.forEach(element => {
                    if (followebuyers === '') {
                        followebuyersdisplay = element.label;
                        followebuyers = element.value;
                    }
                    else {
                        followebuyersdisplay = followebuyersdisplay + ',' + element.label;
                        followebuyers = followebuyers + ',' + element.value;
                    }
                })
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds &&
                values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerCubeIds.forEach(element => {
                    if (followecubes === '') {
                        followecubesdisplay = element.label;
                        followecubes = element.value;
                    }
                    else {
                        followecubesdisplay = followecubesdisplay + ',' + element.label;
                        followecubes = followecubes + ',' + element.value;
                    }
                })
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].Follower = followecubes + "," + followebuyers;
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].FollowerDisplay = followecubesdisplay + "," + followebuyersdisplay;
            values[index].StoryTheme[childindex].Strikeoff[subchildindex].IsFolower = 0;
        }
        setInputFields(values);

    }



    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const CustomInput = (props) => {
        return (
            <>
                <input
                    className="form-control DatePickerCalenderHoliday"
                    onClick={props.onClick}
                    value={props.value}
                    type="text"
                    readOnly={true}
                    placeholder="DD/MM/YYYY"
                    style={{ width: '250px', border: submitted && props.value === '' ? '1px solid red' : '' }}
                />
            </>
        )
    }

    const viewtoEdit = (e) => {
        SetButtonvisible("Edit");
        setHeader("Edit");
        SetButtonview("Hide");
    }
    const PageRedirect = (Func, redirect) => {
        props.history.push({
            pathname: "/SamGenlStrikeOffList",
            state: {
                message: Func
            }
        });

        if (redirect === 'Add') {
            let buyerbrandseason = {
                buyerId: StrikeOffViewDetailParams.BuyerId,
                brandId: StrikeOffViewDetailParams.BrandId,
                seasonId: StrikeOffViewDetailParams.SeasonId,
                Redirecttomaster: true

            };
            props.location.state = { params: buyerbrandseason };
            props.history.push('/AddStrikeOff', { params: buyerbrandseason });
        }
    }


    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/SamGenlStrikeOffList')
        } else {
            window.location.reload();
        }
    }



    const showManualClose = (index, stindex, soindex, GenStrikeoffMainId) => {
        setNewFileState([]);
        let FileDeleteId = [];
        const values = [...inputFields];
        values[index].StoryTheme[stindex].Strikeoff[soindex].Manual = 1;
        var newfileid = values.filter(x => x.GenStrikeOffMainId === GenStrikeoffMainId)
        newfileid[0].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.map((x, i) => {
            FileDeleteId.push({
                genStrikeOffManualFilelId: x.genStrikeoffManulFileId,
                fileName: x.fileName,
                filePath: x.filePath,
                fileType: x.fileType,
                strikeOffIndexName: x.StrikeOffIndexName,
                storyThemeIndexName: x.StoryThemeIndexName,
                indexName: x.IndexName,
                IsDeleted: 0
            })
            setNewFileState(FileDeleteId)
        })
        setInputFields(values);

    }


    const handleCloseManualPopup = (index, stindex, soindex) => {
        //values[index].Manual = 0;
        if (getHeader !== "Edit") {
            if (values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved === 1) {
                values[index].StoryTheme[stindex].Strikeoff[soindex].Manual = 0
            }
            else {
                values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = '';
                values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files = [];
                values[index].StoryTheme[stindex].Strikeoff[soindex].Manual = 0;
                //values[0].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffApprovalType = 0;
            }
        } else {
            values[index].StoryTheme[stindex].Strikeoff[soindex].Manual = 0;
        }

        setInputFields(values);
    };

    const ClearManual = (index, stindex, soindex) => {
        if (getHeader !== "Edit") {
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseDate = '';
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = '';
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files = [];
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved = 0
        } else {
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseDate = '';
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = '';
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files = [];
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved = 0
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsDeleted = 1;
        }
        // values[index].ApprovalTypeId = 0;
        setInputFields(values);
    }

    const handleChangeIsManualCloseComments = (event, index, stindex, soindex, feild, subindex) => {
        //  const values = [...inputFields];

        let input = '';

        if (feild === 'Comment') {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[0].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = input;
        } else {
            values[0].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseDate = event;
        }
        setInputFields(values);

    }


    const getTNAStrikeoffMapList = (index, BuyerID, BrandID, strikeOffId) => {

        let params = { StyleID: 0, BuyerID: BuyerID, BrandID: BrandID, StrikeOffId: strikeOffId }
        dispatch(getTNAStrikeOffAlreadyMapList(params))
            .then(isSuccess => {
                if (isSuccess) {
                    // if (selected && selected !== 0) {
                    const indexVal = TNAStrikeOffMapList.findIndex(obj => obj.commonApprovalId === selected);
                    dispatch(updateStrikeoffMapList(indexVal, 1));
                    values[index].ExitingApprovalTypePop = 1;
                    setInputFields(values);
                    // }
                    //  else {
                    // Nodify("Warning!", "warning", "This data not approved");
                    //    }

                } else {
                    Nodify("Warning!", "warning", "This data not approved");
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleSelectedChange = (event, index, stindex, soindex, feild) => {
        setSubmitted(false);
       
        if (values[0].SeasonId === 0) {
            Nodify('Warning!', 'warning', "Select Season.");
        }
        else if ((values[0].IsStoryMandatory === 1 || values[0].IsThemeMandatory === 1) && (values[0].StoryTheme[stindex].StoryId === 0 || values[0].StoryTheme[stindex].ThemeId === 0)) {
            Nodify('Warning!', 'warning', "Select Story & Theme.");
        }
        else {
            if (feild === "StrikeoffId") {
                if (event !== null) {
                    let ValueExistinDb = [];
                    let Params = {};
                    Params.BuyerId = values[0].BuyerId;
                    Params.BrandId = values[0].BrandId;
                    Params.SeasonId = values[0].SeasonId;
                    Params.StoryId = values[0].StoryTheme[stindex].StoryId;
                    Params.ThemeId = values[0].StoryTheme[stindex].ThemeId;
                    Params.StrikeOffId = event.value;
                    Params.IsProduction = IsProduction
                    GeneralService.CheckGeneralStrikeoffDuplicate(Params).then((response) => {
                        if (response.data) {
                            ValueExistinDb = response.data;

                            let ValueExist = values[index].StoryTheme[stindex].Strikeoff.filter(x => x.StrikeoffId === parseInt(event.value));

                            if (ValueExist.length === 0 && ValueExistinDb.length === 0) {
                                values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor = [];
                                values[index].StoryTheme[stindex].Strikeoff[soindex].RefGarmentSizeList = [];
                                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference = [];
                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffId = 0;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffName = '';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffCode = '';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].Quality = '';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].PurchaseTypeName = '';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffPurchaseType = 0;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].TrimSupplierName = '';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].IsApprovalType = '0';
                                values[index].StoryTheme[stindex].Strikeoff[soindex].ApprovalTypeId = 0;
                                //  showLoader();

                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffId = event.value;
                                if (getStrikeoffList.length === 0) {
                                    strikeoffService.GetStrikeOffList(0).then((response) => {
                                        if (response.data) {
                                            setStrikeoffList(response.data);
                                        }
                                    }).catch(() => { });
                                }
                                let respected_StrikeOff = getStrikeoffList.filter(x => x.strikeoffID === parseInt(event.value));
                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffName = respected_StrikeOff[0].strikeOffName;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffCode = respected_StrikeOff[0].strikeOffCode;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffPurchaseType = respected_StrikeOff[0].purchaseType;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].Quality = respected_StrikeOff[0].quality;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].TrimSupplierName = respected_StrikeOff[0].trimSupplierName;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].PurchaseTypeName = respected_StrikeOff[0].purchaseTypeName;
                                values[index].StoryTheme[stindex].Strikeoff[soindex].IsApprovalType = respected_StrikeOff[0].purchaseType === 3 ? "1" : "0";

                                strikeoffService.GetStrikeOffLogoInformationList(parseInt(event.value)).then((response) => {
                                    if (response.data) {

                                        let returnList = response.data.filter(x => x.strikeoffLogoName !== null);
                                        values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor = (returnList);
                                        values[index].StoryTheme[stindex].Strikeoff[soindex].AllPrintColors = (returnList);


                                        strikeoffService.GetStrikeOffReferenceList(parseInt(event.value)).then((response) => {
                                            if (response.data) {
                                                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference = (response.data);
                                                values[index].StoryTheme[stindex].Strikeoff[soindex].AllReferenceList = (response.data);
                                                setInputFields(values);

                                            }
                                        }).catch(() => { });
                                    }
                                }).catch(() => { });

                            }
                            else {
                                Nodify('Warning!', 'warning', "Strike Off already Exists.");
                            }
                        }
                    }).catch(() => { });



                }
                else {
                    values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffId = 0;
                    values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffName = '';
                    values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffCode = '';
                    values[index].StoryTheme[stindex].Strikeoff[soindex].Quality = '';
                    values[index].StoryTheme[stindex].Strikeoff[soindex].PurchaseTypeName = '';
                    //  values[index].PurchaseType = 0;
                    values[index].StoryTheme[stindex].Strikeoff[soindex].TrimSupplierName = '';
                    values[index].StoryTheme[stindex].Strikeoff[soindex].IsApprovalType = '0';
                    values[index].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffApprovalType = 0;
                    values[index].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffPurchaseType = 0;
                    values[index].StoryTheme[stindex].Strikeoff[soindex].PrintColor = [];
                    // values[index].StoryTheme[stindex].Strikeoff[soindex].MapColorSKUList = [];
                    values[index].StoryTheme[stindex].Strikeoff[soindex].RefGarmentSizeList = [];
                    values[index].StoryTheme[stindex].Strikeoff[soindex].Reference = [];
                    setInputFields(values);
                }
            }
            else {
                values[index].StoryTheme[stindex].Strikeoff[soindex].GenStrikeOffApprovalType = parseInt(event.target.value);
                if (getHeader !== "Edit") {
                    if (values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment !== '' &&
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].GenStrikeOffManualId === 0) {
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = '';
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved = 0;
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo.map((x) => {
                            x.Files = [];
                        })

                    }
                } else {
                    if (values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment !== '' &&
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].GenStrikeOffManualId !== 0 &&
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo.length > 0) {
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment = '';
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsDeleted = 1;
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved = 0
                        values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo.map((x) => {
                            x.Files = [];
                        })
                    }
                }
                setInputFields(values);
            }
        }
        //setInputFields(values);
    }



    const AppendFiles = async (e, index, stindex, soindex) => {
        const file = e.target.files[0];
        var filevals = [];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNA");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.push({
                        genStrikeOffManualFilelId: 0,
                        fileName: file.name,
                        filePath: "Images/TNAImages/" + file.name,
                        fileType: file.type,
                        strikeOffIndexName: getHeader === "Edit" ? strikeoffforedit : soindex.toString(),
                        storyThemeIndexName: getHeader === "Edit" ? STindexforedit : stindex.toString(),
                        indexName: values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.length.toString(),
                        IsDeleted: 0
                    })
                    // values[index].IsManual[0].Files = filevals;
                    setInputFields(values);
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveFiles = async (e, index, stindex, soindex, fileindex, GenStrikeOffManualFileId, StrikeOffIndexName, StrikeOffFileIndex) => {
        if (getHeader === "Edit") {
            const Mainindexs = values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.filter(x => x.strikeOffIndexName === StrikeOffIndexName)
            let storeindex = newFilestate.filter(x => x.genStrikeOffManualFilelId !== 0 && x.IsDeleted !== 1)
            if (storeindex.length === Mainindexs.length) {
                newFilestate[fileindex].IsDeleted = 1;
                values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files = newFilestate
            } else {
                const indexs = values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.findIndex(obj => obj.genStrikeOffManualFilelId === GenStrikeOffManualFileId && obj.indexName === StrikeOffFileIndex)
                values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files[indexs].IsDeleted = 1;
            }
        } else {
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files.splice(fileindex, 1);
            // setFiles(values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files);
            // values[index].IsManual.Files = values[index].IsManual[0].Files;
            values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files = values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].Files;

        }
        setInputFields(values);
    }



    const handleSaveManual = (index, stindex, soindex) => {
        if (values[index].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].ManualCloseComment === '') {
            // values[index].ApprovalTypeId = 0;
            Nodify('Warning!', 'warning', 'Comment Should be fill');
            setmanualsubmit(true);
        }
        else {
            values[0].StoryTheme[stindex].Strikeoff[soindex].ManualInfo[0].IsSaved = 1;
            values[index].StoryTheme[stindex].Strikeoff[soindex].Manual = 0;
        }
        setInputFields(values);
    };

    const handleCloseExistMapPopup = (index, stindex, soindex) => {
        values[index].StoryTheme[stindex].Strikeoff[soindex].Existing = 0;
        setInputFields(values);

    };

    const OnSelectRecords = (event, index) => {

        let selected;
        // TNAStrikeOffMapList.forEach((element, index) => {
        //     element.isSelected = 0;
        // })
        if (event.target.checked === true) {
            selected = 1;
        } else {
            selected = 0;
        }
        dispatch(updateStrikeoffMapList(index, selected));
        // setInputFields(values);
        // ProgramCallback(values);
    }



    const saveStrikeoffMap = (index, stindex, soindex) => {

        let FileteredRecords = TNAStrikeOffMapList.filter(x => x.isSelected === 1);
        if (FileteredRecords.length === 0) {
            Nodify("Warning!", "warning", "Select atleast one row.");
        }
        else {
            values[index].StoryTheme[stindex].Strikeoff[soindex].CommonApprovalId = FileteredRecords[0].commonApprovalId;
            values[index].StoryTheme[stindex].Strikeoff[soindex].Existing = 0;
            values[index].StoryTheme[stindex].Strikeoff[soindex].SelectedMapRow = FileteredRecords;
            setInputFields(values);
        }

        // ProgramCallback(values);
    }

    const handleRefChange = (event, index, stindex, soindex, refindex) => {
        setSubmitted(false);
        if (event.length === 0) {
            values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSize = "";
            values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSizeEdit = "";
        } else {
            let checkAll = event.filter(x => x.value === "-1");
            let Values = [];
            if (checkAll && checkAll.length !== 0) {
                getSizeList.forEach(element => {
                    if (element.value !== "-1") {
                        Values.push(element);
                    }
                });
                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSizeEdit = Values;
                let strValue = '';
                for (let size of Values) {
                    if (strValue === '') { strValue = size.value.toString() }
                    else { strValue = strValue + "," + size.value }
                }
                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSize = strValue;
            } else {
                let strValue = "";
                for (let Sku of event) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSize = strValue;
                values[index].StoryTheme[stindex].Strikeoff[soindex].Reference[refindex].StyleStrikeOffGarmentSizeEdit = event;
            }
        }
        setInputFields(values);
    }




    const OpenStyleMapPopup = (index, stindex, soindex) => {
       
        if (values[index].StoryTheme[stindex].Strikeoff[soindex].StrikeoffId === 0) {
            Nodify('Warning!', 'warning', "Please Select Strike Off");
        }
        else {
            setnewstyle(1)
            //values[index].StoryTheme[stindex].Strikeoff[soindex].IsStylemap = 1;
            setInputFields(values);
        }

    }
    const CloseStyleMapPopup = (index, stindex, soindex) => {
       ;
        setnewstyle(0)
        if (values[index].StoryTheme[stindex].Strikeoff[soindex].IsStylemap !== 1) {
            setSubmitteds(false);
            //values[index].StoryTheme[stindex].Strikeoff[soindex].IsStylemap = 0;
            values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.find(d => d.StyleName = "")
            // Strikeoff[soindex].StyleMap.find(d => d.StyleName.trim() === "")
        }

        setInputFields(values);

    }

    const handleStyleMapChange = (event, index, stindex, soindex, smindex) => {
        values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName = event.target.value;
        setInputFields(values);

    }

    const handleRemoveFieldsStyleMap = (index, stindex, soindex, smindex) => {
        values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.splice(smindex, 1)
        setInputFields(values);
    }

    const CheckDuplicate = (index, stindex, soindex, smindex) => {

        const values = [...inputFields];

        let StyleMap = values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName;
        if (StyleMap) {

            //let hasDBStyle = !!values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.find(d => d.StyleName.trim().toLowerCase() === values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName.trim().toLowerCase());
            // if (hasDBStyle) {
            //     values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName = "";
            //     setInputFields(values);
            //     setSubmitteds(true);
            //     Nodify('Warning!', 'warning', 'This StyleName is already exist.');
            //     return false;


            // }
            // if (ExistingList) {
            //     for (var i = 0; i < ExistingList.length; i++) {
            //         if (ExistingList[i].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName.trim().toLowerCase() === values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName.trim().toLowerCase()) {
            //             values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName = '';
            //             setInputFields(values);
            //             Nodify('Warning!', 'warning', "This StyleName is already exist.");
            //             return false;


            //         }

            //     }

            // }
            //  for (var i = 0; i < (inputFields.length); i++) {
            // if (i !== index) {


            let valuesIndexName = values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].IndexName;
            let hasStyle = !!values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.find(d => d.StyleName.trim().toLowerCase() === values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName.trim().toLowerCase() && d.IndexName !== valuesIndexName);
            if (hasStyle) {
                values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName = "";
                setInputFields(values);
                setSubmitteds(true);
                Nodify('Warning!', 'warning', 'This Style Name is already exist.');
                return false;


            }
        }


        // if (inputFields) {
        //     let isStylelist = !values.StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].find(d => d.StyleName.trim().toLowerCase() === values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName.trim().toLowerCase());
        //     if (isStylelist) {

        //         Nodify('Warning!', 'warning', 'This StyleName is already exist.');
        //         return false;
        //     }
        // }



    }

    const handleAddFieldsStyleMap = (index, stindex, soindex, smindex) => {
        const values = [...inputFields];
        if (values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap[smindex].StyleName !== '') {
            values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.push({
                GenStrikeOffStyleId: 0,
                StyleName: '',
                StoryThemeIndexName: '',
                StrikeOffIndexName: '',
                IndexName: (parseInt(smindex) + 1).toString()
            });
            setInputFields(values);
            setSubmitteds(false);


        }
        else {
            Nodify('Warning!', 'warning', 'Please select Style Name.');
            setSubmitteds(true);

        }


    }

    const handleSaveStyleMap = (index, stindex, soindex) => {
       ;
        //values[index].StoryTheme[stindex].Strikeoff[soindex].IsStylemap = 1;

        let SavePopup = !!values[index].StoryTheme[stindex].Strikeoff[soindex].StyleMap.find(d => d.StyleName.trim() === "")
        if (SavePopup) {
            Nodify("Warning!", "warning", "Please enter Style Name");
            setSubmitteds(true);
            return false;

        }
        else {
            setnewstyle(0)
            values[index].StoryTheme[stindex].Strikeoff[soindex].IsStylemap = 1;
            setInputFields(values);

        }

    }



    const SaveGeneralStrikeoff = () => {
        let Isvalid = true;
        let RefCount = 0;
        let PrintColorCount = 0;
        let IsvalidStoryId = true;
        let IsvalidThemeId = true;
        let IsvalidManaual = true;
        let Params = {};
        if (values[0].BuyerId === 0 || values[0].BrandId === 0 || values[0].SeasonId === 0 || values[0].ReceivedDate === '') {
            Isvalid = false;
        }
        values[0].StoryTheme.forEach(element => {
            if (element.StoryId === 0 || element.ThemeId === 0) {
                Isvalid = false;
            }

            element.Strikeoff.forEach(element1 => {
                if (element1.StrikeoffId === 0 || element1.FollowerDisplay === '' ||
                    (element1.GenStrikeOffPurchaseType === 3 && element1.GenStrikeOffApprovalType === 0)) {
                    Isvalid = false;
                }
                if (values[0].IsStoryMandatory === 1 && element.StoryId === 0) {
                    IsvalidStoryId = false;
                }
                if (values[0].IsThemeMandatory === 1 && element.ThemeId === 0) {
                    IsvalidThemeId = false;
                }
                element1.ManualInfo.forEach((element2, ind2) => {
                    if (element1.GenStrikeOffApprovalType === 2 && element2.ManualCloseComment === '') {
                        IsvalidManaual = false;
                    }
                })
                RefCount = element1.Reference.length;
                PrintColorCount = element1.PrintColor.length;
            })


        })

        if (Isvalid === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else if (RefCount === 0) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "References should not be empty");
            return false;
        }
        else if (PrintColorCount === 0) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "Please Add atleast one Print Color");
            return false;
        }
        else if (IsvalidStoryId === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', 'Please select a story for this buyer.');
            return false;
        }
        else if (IsvalidThemeId === false) {
            setSubmitted(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', 'Please select a theme for this buyer.');
            return false;
        }
        else if (IsvalidManaual === false) {
            setmanualsubmit(true);
            setDisableSave(false);
            Nodify('Warning!', 'warning', "Manual data should be fill when approval type is Manual")
        }
        else {
            Params = {
                Operation: parseInt(values[0].Operation),
                BuyerId: parseInt(values[0].BuyerId),
                BrandId: parseInt(values[0].BrandId),
                SeasonId: parseInt(values[0].SeasonId),
                ReceivedDate: values[0].ReceivedDate,
                GenStrikeOffMainId: parseInt(values[0].GenStrikeOffMainId),
                StrikeoffStoryThemeDetails: [], GenStrikeoffDetails: [], GenStrikeoffReferenceDetails: [], GenStrikeoffLogoDetails: [],
                GenStrikeoffManualCommentDetails: [], GenStrikeoffManualFileUploadDetails: [], GenStrikeoffStyleDetails: []
            }
            values[0].StoryTheme.forEach((element, stindex) => {
                Params.StrikeoffStoryThemeDetails.push({
                    GenStrikeoffStoryThemeId: parseInt(element.GenStrikeoffStoryThemeId),
                    StoryId: parseInt(element.StoryId),
                    ThemeId: parseInt(element.ThemeId),
                    IndexName: getHeader === "Edit" ? element.IndexName : stindex.toString()
                })

                element.Strikeoff.forEach((element1, pindex) => {
                    Params.GenStrikeoffDetails.push({
                        GenStrikeoffId: parseInt(element1.GenStrikeoffId),
                        StrikeoffId: parseInt(element1.StrikeoffId),
                        ApprovalTypeId: parseInt(element1.GenStrikeOffApprovalType),
                        Follower: element1.Follower,
                        FollowerBuyer: element1.FollowerBuyer,
                        FollowerCube: element1.FollowerCube, IsManual: parseInt(element1.Manual),
                        StoryThemeIndexName: getHeader === "Edit" ? element1.StoryThemeIndexName : stindex.toString(),
                        IndexName: getHeader === "Edit" ? element1.IndexName : pindex.toString(),
                        CommonApprovalId: parseInt(element1.CommonApprovalId)
                    })

                    element1.Reference.forEach((element5, cindex) => {
                        Params.GenStrikeoffReferenceDetails.push({
                            GenStrikeoffReferenceId: parseInt(element5.genStrikeOffRefernceId),
                            StrikeoffId: element1.StrikeoffId,
                            StrikeoffReferenceId: parseInt(element5.strikeOffReferenceID),
                            StrikeoffGramentSize: element5.StyleStrikeOffGarmentSize,
                            StoryThemeIndexName: parseInt(element5.genStrikeOffRefernceId) !== 0 ? element5.StoryThemeIndexName
                                : parseInt(element1.GenStrikeoffId) !== 0 ? element1.StoryThemeIndexName : stindex.toString(),
                            StrikeOffIndexName: parseInt(element5.genStrikeOffRefernceId) !== 0 ? element5.StrikeOffIndexName :
                                parseInt(element1.GenStrikeoffId) !== 0 ? element1.IndexName : pindex.toString(),
                            IndexName: parseInt(element5.genStrikeOffRefernceId) !== 0 ? element5.IndexName : cindex.toString()


                        })
                    })

                    element1.PrintColor.forEach((element2, cindex) => {
                        Params.GenStrikeoffLogoDetails.push({
                            GenStrikeoffLogoId: parseInt(element2.genStrikeoffLogoId),
                            StrikeOffID: element1.StrikeoffId,
                            StrikeoffPrintColorGroupId: parseInt(element2.colorGroupID),
                            StoryThemeIndexName: parseInt(element2.genStrikeoffLogoId) !== 0 ? element2.StoryThemeIndexName
                                : parseInt(element1.GenStrikeoffId) !== 0 ? element1.StoryThemeIndexName : stindex.toString(),
                            StrikeOffIndexName: parseInt(element2.genStrikeoffLogoId) !== 0 ? element2.StrikeOffIndexName :
                                parseInt(element1.GenStrikeoffId) !== 0 ? element1.IndexName : pindex.toString(),
                            IndexName: parseInt(element2.genStrikeoffLogoId) !== 0 ? element2.IndexName : cindex.toString()


                        })
                    })



                    element1.ManualInfo.forEach((element3, Mindex) => {
                        Params.GenStrikeoffManualCommentDetails.push({
                            GenStrikeoffManualId: parseInt(element3.GenStrikeOffManualId),
                            StrikeoffId: element1.StrikeoffId,
                            ManualCloseComment: element3.ManualCloseComment,
                            StoryThemeIndexName: stindex.toString(),
                            StrikeOffIndexName: pindex.toString(),
                            IndexName: Mindex.toString(),
                            IsDeleted: element3.IsDeleted
                        })
                        if (element3.Files) {
                            if (element3.Files.length !== 0) {
                                element3.Files.forEach((f, k, i) => {
                                    let genStrikeoffManulFileId = (f.genStrikeoffManulFileId) === undefined ? 0 : parseInt(f.genStrikeoffManulFileId);
                                    let fileType = f.fileType === undefined ? "" : (f.fileType).toString();
                                    let fileName = f.fileName === undefined ? "" : (f.fileName).toString();
                                    let filePath = f.filePath === undefined ? "" : (f.filePath).toString();
                                    let storyThemeIndexName = ((f.storyThemeIndexName) === undefined || (f.storyThemeIndexName) === "") ? stindex.toString() : (f.storyThemeIndexName).toString();
                                    let strikeOffIndexName = ((f.strikeOffIndexName) === undefined || (f.strikeOffIndexName) === "") ? pindex.toString() : (f.strikeOffIndexName).toString();
                                    let IsDeleted = f.IsDeleted === undefined ? 0 : f.IsDeleted

                                    Params.GenStrikeoffManualFileUploadDetails.push({
                                        GenStrikeoffManulFileId: genStrikeoffManulFileId,
                                        StrikeoffId: element1.StrikeoffId,
                                        FileType: fileType,
                                        FileName: fileName,
                                        FilePath: filePath,
                                        StoryThemeIndexName: getHeader === "Edit" ? STindexforedit : storyThemeIndexName,
                                        StrikeOffIndexName: getHeader === "Edit" ? strikeoffforedit : strikeOffIndexName,
                                        IndexName: k.toString(),
                                        IsDeleted: IsDeleted
                                    })
                                })
                                // StyleProgramManualFileInformation = man.Files
                            }
                        }

                    })

                    element1.StyleMap.forEach((element4, cindex) => {
                        Params.GenStrikeoffStyleDetails.push({
                            GenStrikeOffStyleId: parseInt(element4.GenStrikeOffStyleId),
                            StrikeoffId: element1.StrikeoffId,
                            StyleName: element4.StyleName,
                            StoryThemeIndexName: parseInt(element4.GenStrikeOffStyleId) !== 0 ? element4.StoryThemeIndexName
                                : parseInt(element1.GenStrikeoffId) !== 0 ? element1.StoryThemeIndexName : stindex.toString(),
                            StrikeOffIndexName: parseInt(element4.GenStrikeOffStyleId) !== 0 ? element4.StrikeOffIndexName :
                                parseInt(element1.GenStrikeoffId) !== 0 ? element1.IndexName : pindex.toString(),
                            IndexName: parseInt(element4.GenStrikeOffStyleId) !== 0 ? element4.IndexName : cindex.toString()
                        })
                    })




                })
            })
            // For Isproduction Check
            Params.IsProduction = IsProduction;

            GeneralService.InsertGeneralStrikeoff(Params).then((res) => {
                var page = "Remove";
                SearchRetain(page);
                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }

            })


        }

        //values[0].StoryTheme
    }


    return (
        <>
            {
                <div className="page-body">
                    <ReactNotification />
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12">

                            <div className="widget flat radius-bordered">
                                <div className="widget-header heading_box_style">
                                    <h3 className="heading_style_common">{getHeader} General Strike Off </h3>
                                    <div className="buttons-preview" align="right">
                                        <a className="btn btn-success" type="button" onClick={() => PageRedirect('', 'Add')}><i class="fa fa-plus"></i>
                                            &nbsp;  Add Master
                                        </a>
                                        <a className="btn btn-primary" 
                                       onClick={() => ResetOperation("Back")}
                                        // href="/SamGenlStrikeOffList"
                                        >
                                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                        </a>
                                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                            <i className="fa fa-times"></i>&nbsp;Reset
                                        </span>
                                        {Buttonvisible !== "View" &&
                                            (<button type="submit" className="btn btn-success" onClick={(e) => SaveGeneralStrikeoff(e)} disabled={getDisableSave}>

                                                <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                            </button>)
                                        }
                                        {Buttonvisible === "View" && activeMenu[0].isView === 1 ? '' :
                                            activeMenu[0].isView === 0 ? '' :
                                                (<button type="submit" style={{ display: activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                                    <i className="fa fa-edit"></i>&nbsp;Edit
                                                </button>)
                                        }
                                    </div>
                                </div>
                                <div className="widget-body">
                                    <div id="registration-form">
                                        <Form>
                                            {
                                                <div className="row">
                                                    {
                                                        inputFields.map((item, index) => (
                                                            <Fragment key={`${item}~${index}`}>
                                                                <Form role="form">

                                                                    <div className="col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Buyer">
                                                                                        Buyer
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Buyer"
                                                                                            id={
                                                                                                "Buyer"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            //isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'BuyerId')}
                                                                                            value={getBuyerList.filter(function (option) {
                                                                                                return option.value === item.BuyerId;
                                                                                            })}
                                                                                            options={getBuyerList}
                                                                                            styles={submitted && item.BuyerId === 0 ? Buyerstyles2 : Buyerstyles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}

                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="SupplierID">
                                                                                        Brand
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Brand"
                                                                                            id={
                                                                                                "Brand"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            //isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'BrandId')}
                                                                                            value={getBrandList.filter(function (option) {
                                                                                                return option.value === item.BrandId;
                                                                                            })}
                                                                                            options={getBrandList}
                                                                                            styles={submitted && item.BrandId === 0 ? styles2 : styles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Name">
                                                                                        Season
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <Reactselect className="basic-single" name="Season"
                                                                                            id={
                                                                                                "Season"
                                                                                            }
                                                                                            classNamePrefix="react-select"
                                                                                            isLoading={false}
                                                                                            //isClearable={true}
                                                                                            isSearchable={true}
                                                                                            onChange={event => handlechange(event, index, 'SeasonId')}
                                                                                            value={getSeasonList.filter(function (option) {
                                                                                                return option.value === item.SeasonId;
                                                                                            })}

                                                                                            options={getSeasonList}
                                                                                            styles={submitted && item.SeasonId === 0 ? styles2 : styles1}
                                                                                            isDisabled={getHeader === "Edit" ? true : false}
                                                                                        ></Reactselect>

                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Name">
                                                                                        Received Date
                                                                                        <span className="text-danger">*</span>
                                                                                    </label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                                                            id={
                                                                                                "ReceivedDate" + index
                                                                                            }
                                                                                            selected={
                                                                                                item.ReceivedDate
                                                                                            }
                                                                                            onChange={event => handlechange(event, index, "ReceivedDate")}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            dropdownMode="scroll"
                                                                                            autoComplete="off"
                                                                                            customInput={<CustomInput />}
                                                                                            style={{ border: submitted && item.ReceivedDate === '' ? '1px solid red' : '' }}
                                                                                            disabled={getHeader === "Edit" ? true : false}
                                                                                        />

                                                                                    </span>
                                                                                </div>
                                                                            </div>


                                                                        </div>



                                                                        {
                                                                            item.StoryTheme.map((STitem, STindex) => {
                                                                                return (

                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Story" + STindex}>
                                                                                                        Story  {
                                                                                                            item.IsStoryMandatory === 1 ?
                                                                                                                <span className="text-danger">*</span> : ''
                                                                                                        }
                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <Reactselect className="basic-single" name="StoryID"
                                                                                                            id={
                                                                                                                "StoryID"
                                                                                                            }
                                                                                                            // isDisabled={false}
                                                                                                            classNamePrefix="react-select"
                                                                                                            isLoading={false}
                                                                                                            //isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getStoryList}
                                                                                                            // value={getStyleList.StoryID}
                                                                                                            value={getStoryList.filter(function (option) {
                                                                                                                return option.value === STitem.StoryId;
                                                                                                            })}
                                                                                                            onChange={event => handleChangeStoryTheme(event, index, STindex, 'StoryId')}
                                                                                                            // onBlur={() => CheckDuplicateStoryTheme(index, STindex)}
                                                                                                            menuPortalTarget={document.body}
                                                                                                            // styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                                                                            styles={submitted && item.IsStoryMandatory === 1 && STitem.StoryId === 0 ? Storystyles2 : Storystyles1}
                                                                                                        // isDisabled={getHeader === "Edit" ? true : false}

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3">
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor={"Theme" + STindex}>
                                                                                                        Theme   {
                                                                                                            item.IsThemeMandatory === 1 ?
                                                                                                                <span className="text-danger">*</span> : ''
                                                                                                        }
                                                                                                    </label>
                                                                                                    <span className="input-icon icon-right">
                                                                                                        <Reactselect className="basic-single" name="ThemeID"
                                                                                                            id={
                                                                                                                "ThemeID"
                                                                                                            }
                                                                                                            classNamePrefix="react-select"
                                                                                                            // isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            //isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            options={getThemeList}
                                                                                                            // value={getStyleList.ThemeID}
                                                                                                            value={getThemeList.filter(function (option) {
                                                                                                                return option.value === STitem.ThemeId;
                                                                                                            })}
                                                                                                            onChange={event => handleChangeStoryTheme(event, index, STindex, 'ThemeId')}
                                                                                                            // onBlur={() => CheckDuplicateStoryTheme(index, STindex)}
                                                                                                            styles={submitted && item.IsThemeMandatory === 1 && STitem.ThemeId === 0 ? styles2 : styles1}

                                                                                                        //  isDisabled={getHeader === "Edit" ? true : false}

                                                                                                        ></Reactselect>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* {
                                                                                                getHeader === "Add" && (
                                                                                                    <div className="col-lg-3" style={{ marginTop: "36px" }}>
                                                                                                        {item.StoryTheme.length !== 1 && (
                                                                                                            <span >
                                                                                                                <button type="button" className="btn  btn-xs  btn-danger" title="Delete StoryTheme"
                                                                                                                    onClick={event => handleRemoveFields(index, STindex)}>
                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        )}
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            item.StoryTheme.length === (STindex + 1) && (
                                                                                                                <span>
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="Add StoryTheme details"
                                                                                                                        onClick={
                                                                                                                            () => handleAddFields('Add', index, STindex)
                                                                                                                        }>
                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                    </button>
                                                                                                                </span>
                                                                                                            )
                                                                                                        }
                                                                                                        &nbsp;

                                                                                                        <span className="btn btn-primary" title="Copy StoryTheme" onClick={() => handleAddFields('Copy', index, STindex)}>
                                                                                                            <i className="fa fa-copy"></i>
                                                                                                        </span>


                                                                                                    </div>
                                                                                                )
                                                                                            } */}

                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col-lg-12">
                                                                                                <div className="dataTables_wrapper no-footer" style={{ width: "1700px", overflowX: "auto" }}>
                                                                                                    {
                                                                                                        inputFields[0].StoryTheme[0].Strikeoff.length > 0 ?
                                                                                                            STitem.Strikeoff &&
                                                                                                            STitem.Strikeoff.map((SOitem, SOindex) => {
                                                                                                                return (
                                                                                                                    <>

                                                                                                                        <div className="widget flat radius-bordered col-lg-11">
                                                                                                                            <CollapsiblePanel title={
                                                                                                                                SOitem.StrikeoffId === 0 ? 'Pick Strike Off' : SOitem.StrikeoffName
                                                                                                                            } collapse={SOitem.StrikeoffId === 0 ? false : collapse}
                                                                                                                            >

                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                                                    <thead>
                                                                                                                                        <tr>
                                                                                                                                            {/* <span class="btn btn-success" onClick={() => PageRedirect('', 'Add')}><i class="fa fa-plus"></i></span> &nbsp; */}
                                                                                                                                            <th style={{ width: "19%" }}>  Select Strike Off Code <span className="text-danger">*</span>
                                                                                                                                            </th>
                                                                                                                                            <th>Strike Off Name</th>
                                                                                                                                            <th>Quality</th>
                                                                                                                                            <th>Purchase Type</th>
                                                                                                                                            {SOitem.GenStrikeOffPurchaseType === 1 && (
                                                                                                                                                <th>Trim Supplier</th>
                                                                                                                                            )}

                                                                                                                                            {SOitem.GenStrikeOffPurchaseType === 3 && (
                                                                                                                                                <th>Approval Type <span className="text-danger">*</span>
                                                                                                                                                    {
                                                                                                                                                        SOitem.GenStrikeOffApprovalType === 2 ?
                                                                                                                                                            (SOitem.ManualInfo[0].ManualCloseComment === '' ?
                                                                                                                                                                <button type="button" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} className="btn btn-xs btn-darkorange" title="Manual"
                                                                                                                                                                    onClick={() => showManualClose(index, STindex, SOindex, item.GenStrikeOffMainId)}
                                                                                                                                                                >
                                                                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                                                                </button>
                                                                                                                                                                :
                                                                                                                                                                <button type="button" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} className="btn btn-xs btn-success" title="View Manual Data"
                                                                                                                                                                    onClick={() => showManualClose(index, STindex, SOindex, item.GenStrikeOffMainId)}
                                                                                                                                                                >
                                                                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                                                                </button>)
                                                                                                                                                            :
                                                                                                                                                            SOitem.GenStrikeOffApprovalType === 3 ?

                                                                                                                                                                // (item.IsManual[0].ManualCloseComment === '' ?
                                                                                                                                                                <button type="button" className="btn btn-xs btn-darkorange" title="Existing Approval"
                                                                                                                                                                    onClick={() => getTNAStrikeoffMapList(index, item.BuyerId, item.BrandId, SOitem.StrikeoffId)}
                                                                                                                                                                >
                                                                                                                                                                    <i class="fa fa-retweet" aria-hidden="true"></i>&nbsp;
                                                                                                                                                                    {/* <i className="fa fa-close"></i> */}
                                                                                                                                                                </button>
                                                                                                                                                                : ''


                                                                                                                                                    }
                                                                                                                                                </th>
                                                                                                                                            )}
                                                                                                                                            <th>Follower<span className="text-danger">*</span></th>
                                                                                                                                            <th>StyleMap</th>


                                                                                                                                        </tr>
                                                                                                                                    </thead>
                                                                                                                                    <tbody>
                                                                                                                                        {

                                                                                                                                            <>
                                                                                                                                                <tr>
                                                                                                                                                    <td>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <Reactselect className="basic-single"
                                                                                                                                                                name="StrikeOffId"
                                                                                                                                                                // id={"StrikeOffIdPicfromMaster"}
                                                                                                                                                                // isDisabled={false}
                                                                                                                                                                isLoading={false}
                                                                                                                                                                isClearable={true}
                                                                                                                                                                isSearchable={true}
                                                                                                                                                                options={getAllStrikeoffList}
                                                                                                                                                                value={getAllStrikeoffList.filter(function (option) {
                                                                                                                                                                    return option.value === SOitem.StrikeoffId;
                                                                                                                                                                })}
                                                                                                                                                                onChange={(event) => handleSelectedChange(event, index, STindex, SOindex, 'StrikeoffId')}
                                                                                                                                                                menuPlacement="auto"
                                                                                                                                                                autoFocus
                                                                                                                                                                //menuPosition="fixed"
                                                                                                                                                                menuPortalTarget={document.body}
                                                                                                                                                                //styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                                                                                                                                styles={(!submitted) || (SOitem.StrikeoffId && SOitem.StrikeoffId !== 0) ? Storystyles1 : Storystyles2}
                                                                                                                                                                // styles={submitted && SOitem.StrikeoffId === 0 ? styles : ''}
                                                                                                                                                                isDisabled={getHeader === "Edit" ? true : false}

                                                                                                                                                            ></Reactselect>
                                                                                                                                                        </span>
                                                                                                                                                    </td>
                                                                                                                                                    <td>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input placeholder="Strike Off Name"
                                                                                                                                                                id="StrikeOffName"
                                                                                                                                                                name="StrikeOffName"
                                                                                                                                                                type="text"
                                                                                                                                                                autoComplete="off"
                                                                                                                                                                maxLength="50"
                                                                                                                                                                className={'form-control'}
                                                                                                                                                                value={SOitem.StrikeoffName}
                                                                                                                                                                disabled
                                                                                                                                                            />
                                                                                                                                                        </span>
                                                                                                                                                    </td>
                                                                                                                                                    <td>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input placeholder="Quality"
                                                                                                                                                                id="Quality"
                                                                                                                                                                name="Quality"
                                                                                                                                                                type="text"
                                                                                                                                                                autoComplete="off"
                                                                                                                                                                maxLength="50"
                                                                                                                                                                className={'form-control'}
                                                                                                                                                                value={SOitem.Quality}
                                                                                                                                                                disabled
                                                                                                                                                            />
                                                                                                                                                        </span>
                                                                                                                                                    </td>
                                                                                                                                                    <td>
                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <input placeholder="Purchase Type"
                                                                                                                                                                id="PurchaseTypeName"
                                                                                                                                                                name="PurchaseTypeName"
                                                                                                                                                                type="text"
                                                                                                                                                                autoComplete="off"
                                                                                                                                                                maxLength="50"
                                                                                                                                                                className={'form-control'}
                                                                                                                                                                value={SOitem.PurchaseTypeName}
                                                                                                                                                                disabled
                                                                                                                                                            />
                                                                                                                                                        </span>
                                                                                                                                                    </td>
                                                                                                                                                    {(SOitem.GenStrikeOffPurchaseType === 1) && (
                                                                                                                                                        <td>
                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                <input placeholder="Trim Supplier Name"
                                                                                                                                                                    id="TrimSupplierName"
                                                                                                                                                                    name="TrimSupplierName"
                                                                                                                                                                    type="text"
                                                                                                                                                                    autoComplete="off"
                                                                                                                                                                    maxLength="50"
                                                                                                                                                                    className={'form-control'}
                                                                                                                                                                    value={SOitem.TrimSupplierName}
                                                                                                                                                                    disabled
                                                                                                                                                                />
                                                                                                                                                            </span>
                                                                                                                                                        </td>
                                                                                                                                                    )}
                                                                                                                                                    {SOitem.GenStrikeOffPurchaseType === 3 && (
                                                                                                                                                        <td>
                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                <Select
                                                                                                                                                                    id="ApprovalTypeId"
                                                                                                                                                                    name="ApprovalTypeId"
                                                                                                                                                                    value={SOitem.GenStrikeOffApprovalType}
                                                                                                                                                                    onChange={(event) => handleSelectedChange(event, index, STindex, SOindex, 'ApprovalTypeId')}
                                                                                                                                                                    type="text"
                                                                                                                                                                    autoComplete="off"
                                                                                                                                                                    maxLength="50"
                                                                                                                                                                    className={'form-control'}
                                                                                                                                                                    style={
                                                                                                                                                                        {
                                                                                                                                                                            border: submitted && SOitem.GenStrikeOffApprovalType === 0 ? '1px solid red' : ''
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                >
                                                                                                                                                                    <option value="0">- Select -</option>
                                                                                                                                                                    {
                                                                                                                                                                        getApprovalList.map(item => (
                                                                                                                                                                            <option key={
                                                                                                                                                                                item.id
                                                                                                                                                                            }
                                                                                                                                                                                value={
                                                                                                                                                                                    item.id
                                                                                                                                                                                }>
                                                                                                                                                                                {
                                                                                                                                                                                    item.name
                                                                                                                                                                                }
                                                                                                                                                                            </option>
                                                                                                                                                                        ))
                                                                                                                                                                    }
                                                                                                                                                                </Select>
                                                                                                                                                            </span>


                                                                                                                                                            {
                                                                                                                                                                SOitem.Manual === 1 && (
                                                                                                                                                                    <>
                                                                                                                                                                        <Modal show={SOitem.Manual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(index, STindex, SOindex)}>
                                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                                <Modal.Title>
                                                                                                                                                                                    General Strike Off: Manual Approval Information
                                                                                                                                                                                </Modal.Title>
                                                                                                                                                                            </Modal.Header>
                                                                                                                                                                            <Modal.Body>

                                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                                                    {

                                                                                                                                                                                        SOitem.ManualInfo.map((manitem, k) => {
                                                                                                                                                                                            return (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    <thead>
                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <th>Comments <span className="text-danger">*</span>
                                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-danger pull-right"
                                                                                                                                                                                                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                                                                                                                                    onClick={event => ClearManual(index, STindex, SOindex)}
                                                                                                                                                                                                                    title="Clear Critical" ><i className="fa fa-trash-o"></i>
                                                                                                                                                                                                                </button></th>


                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </thead>
                                                                                                                                                                                                    <tbody>

                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <td  >
                                                                                                                                                                                                                <textarea
                                                                                                                                                                                                                    style={{ width: "100%", border: manualsubmit && manitem.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                                                                                                                                                                                    rows="3"
                                                                                                                                                                                                                    placeholder="Enter Comments here..."
                                                                                                                                                                                                                    autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                                                                                    maxLength="500" name="EnterRemarks"
                                                                                                                                                                                                                    id={"EnterRemarks"}
                                                                                                                                                                                                                    value={manitem.ManualCloseComment}
                                                                                                                                                                                                                    onChange={event => handleChangeIsManualCloseComments(event, index, STindex, SOindex, "Comment", k)}


                                                                                                                                                                                                                >

                                                                                                                                                                                                                </textarea>
                                                                                                                                                                                                            </td>

                                                                                                                                                                                                        </tr>

                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <td colSpan={2}>
                                                                                                                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">

                                                                                                                                                                                                                    <tbody>


                                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                                                                                                <div style={{ paddingLeft: 0 }}>
                                                                                                                                                                                                                                    <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type:Image Only)</span></span>
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                            <div className="col-sm-12 col-lg-12">
                                                                                                                                                                                                                                <div className="row">
                                                                                                                                                                                                                                    <div className="col-sm-8 col-lg-8">
                                                                                                                                                                                                                                        <div className='form-group'>
                                                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                                                <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e, index, STindex, SOindex)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                                                                                                                                                                                            </tr>
                                                                                                                                                                                                                                        </div>
                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                                                                                                                                                <div className="row no-margin">
                                                                                                                                                                                                                                    {
                                                                                                                                                                                                                                        manitem.Files &&
                                                                                                                                                                                                                                            manitem.Files.length !== 0 ?
                                                                                                                                                                                                                                            manitem.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                                                                                                    <tr title={files.fileName}>
                                                                                                                                                                                                                                                        <td className="cut_textInTNA">
                                                                                                                                                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                            </span>  {files.fileName}
                                                                                                                                                                                                                                                        </td>

                                                                                                                                                                                                                                                        <td className="button_style_uploda">
                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                           
                                                                                                                                                                                                                                                            {
                                                                                                                                                                                                                                                                files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                                                                        style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                                                                        onClick={
                                                                                                                                                                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                                                                        }></i>
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                             &nbsp;
                                                                                                                                                                                                                                                            {Buttonview !== "View" &&
                                                                                                                                                                                                                                                                <p style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete File' onClick={(e) => RemoveFiles(e, index, STindex, SOindex, fileindex, files.genStrikeOffManualFilelId, files.strikeOffIndexName, files.indexName)}
                                                                                                                                                                                                                                                                    className="btn btn-danger btn-xs delete">
                                                                                                                                                                                                                                                                    <i className="fa fa-trash-o"></i>

                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                        </td>
                                                                                                                                                                                                                                                    </tr>
                                                                                                                                                                                                                                                    {

                                                                                                                                                                                                                                                        <div> {
                                                                                                                                                                                                                                                            isOpenimg && (

                                                                                                                                                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                                                                    alt="bg image"
                                                                                                                                                                                                                                                                    onCloseRequest={
                                                                                                                                                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                                                                                                                                                    } />
                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                        } </div>
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                                            ))
                                                                                                                                                                                                                                            : ''
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                            </div>
                                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                                    </tbody>


                                                                                                                                                                                                                </table>
                                                                                                                                                                                                            </td>
                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </tbody>
                                                                                                                                                                                                </>
                                                                                                                                                                                            )
                                                                                                                                                                                        })
                                                                                                                                                                                    }



                                                                                                                                                                                </table>


                                                                                                                                                                            </Modal.Body>
                                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                                {

                                                                                                                                                                                    <Button variant="success" onClick={() => handleSaveManual(index, STindex, SOindex)}>
                                                                                                                                                                                        Save
                                                                                                                                                                                    </Button>


                                                                                                                                                                                }


                                                                                                                                                                            </Modal.Footer>
                                                                                                                                                                        </Modal>
                                                                                                                                                                    </>
                                                                                                                                                                )
                                                                                                                                                            }

                                                                                                                                                            {
                                                                                                                                                                SOitem.Existing === 1 ?
                                                                                                                                                                    <Modal show={SOitem.Existing === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseExistMapPopup(index)}>
                                                                                                                                                                        <Modal.Header closeButton>
                                                                                                                                                                            <Modal.Title>
                                                                                                                                                                                Existing Approval Information
                                                                                                                                                                            </Modal.Title>
                                                                                                                                                                        </Modal.Header>
                                                                                                                                                                        <Modal.Body>

                                                                                                                                                                            <table className="table table-bordered">
                                                                                                                                                                                <thead>
                                                                                                                                                                                    <tr>
                                                                                                                                                                                        <th>Season Name</th>
                                                                                                                                                                                        <th>Approval Date</th>
                                                                                                                                                                                        <th>Details</th>
                                                                                                                                                                                        <th>Approved By</th>
                                                                                                                                                                                        <th>
                                                                                                                                                                                        </th>


                                                                                                                                                                                    </tr>
                                                                                                                                                                                </thead>
                                                                                                                                                                                <tbody>
                                                                                                                                                                                    {
                                                                                                                                                                                        TNAStrikeOffMapList && TNAStrikeOffMapList.length !== 0 ?
                                                                                                                                                                                            TNAStrikeOffMapList.map((Exiting, ExitingIndex) => {
                                                                                                                                                                                                return (
                                                                                                                                                                                                    <>
                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <td>{Exiting.seasonName}</td>
                                                                                                                                                                                                            <td>{Exiting.approvalDate}</td>
                                                                                                                                                                                                            <td>{Exiting.details}</td>
                                                                                                                                                                                                            <td>{Exiting.approvedBy}</td>
                                                                                                                                                                                                            <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                        <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                                            onChange={event => (OnSelectRecords(event, ExitingIndex))}
                                                                                                                                                                                                                            checked={Exiting.isSelected === 1 ? true : false}
                                                                                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                                                                                        <span className="text"></span>
                                                                                                                                                                                                                    </label>

                                                                                                                                                                                                                </div>

                                                                                                                                                                                                            </td>
                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </>

                                                                                                                                                                                                )
                                                                                                                                                                                            }) :
                                                                                                                                                                                            <tr>
                                                                                                                                                                                                <td colSpan={4} className='norecordfound'>
                                                                                                                                                                                                    <div align="center" > <span>No Records Found</span></div>
                                                                                                                                                                                                </td>
                                                                                                                                                                                            </tr>
                                                                                                                                                                                    }


                                                                                                                                                                                </tbody>
                                                                                                                                                                            </table>
                                                                                                                                                                        </Modal.Body>
                                                                                                                                                                        <Modal.Footer>
                                                                                                                                                                            <Button variant="success"
                                                                                                                                                                                onClick={() => saveStrikeoffMap(index)}
                                                                                                                                                                            >
                                                                                                                                                                                Save
                                                                                                                                                                            </Button>

                                                                                                                                                                        </Modal.Footer>
                                                                                                                                                                    </Modal> : ''
                                                                                                                                                            }


                                                                                                                                                        </td>
                                                                                                                                                    )}
                                                                                                                                                    <td >
                                                                                                                                                        {(SOitem.FollowerDisplay != null && SOitem.FollowerDisplay != undefined && SOitem.FollowerDisplay != "") && <span>
                                                                                                                                                            <input type="text"
                                                                                                                                                                value={SOitem.FollowerDisplay}
                                                                                                                                                                title={SOitem.FollowerDisplay}
                                                                                                                                                                onClick={() => showCubeFollowePopup(index, STindex, SOindex)}
                                                                                                                                                                className={'form-control'}
                                                                                                                                                                style={{ width: "100%", float: "left", border: submitted && !SOitem.FollowerDisplay ? '1px solid red' : '' }}
                                                                                                                                                            />
                                                                                                                                                        </span>}
                                                                                                                                                        {(SOitem.FollowerDisplay == null || SOitem.FollowerDisplay == undefined || SOitem.FollowerDisplay == "") &&
                                                                                                                                                            <span
                                                                                                                                                                className={'form-control'}
                                                                                                                                                                style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !SOitem.FollowerDisplay ? '1px solid red' : '' }}
                                                                                                                                                                onClick={() => showCubeFollowePopup(index, STindex, SOindex)}>
                                                                                                                                                                <span style={{ color: "rgb(0, 166, 90)" }}>Cube</span>&nbsp;-&nbsp;
                                                                                                                                                                <span style={{ color: "rgb(11, 9, 146)" }}>Buyer</span>
                                                                                                                                                            </span>
                                                                                                                                                        }
                                                                                                                                                        {SOitem.IsFolower === 1 &&
                                                                                                                                                            <Modal show={SOitem.IsFolower === 1} size="md" backdrop="static" keyboard={false} onHide={() => hideCubeFollowePopup(index, STindex, SOindex)}>
                                                                                                                                                                <Modal.Header closeButton>
                                                                                                                                                                    <Modal.Title>
                                                                                                                                                                        Follower
                                                                                                                                                                    </Modal.Title>
                                                                                                                                                                </Modal.Header>
                                                                                                                                                                <Modal.Body>

                                                                                                                                                                    <div className="row">
                                                                                                                                                                        <div className="col-md-8">
                                                                                                                                                                            <div className='form-group'>
                                                                                                                                                                                <label htmlFor={"Cube"} >Cube <span className="text-danger">*</span></label>
                                                                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                                                                    <Reactselect className="basic-single" name={"Cube"}
                                                                                                                                                                                        isDisabled={false}
                                                                                                                                                                                        isLoading={false}
                                                                                                                                                                                        isClearable={true}
                                                                                                                                                                                        isSearchable={true}
                                                                                                                                                                                        isMulti
                                                                                                                                                                                        onChange={event => handleChangeFollower(event, index, STindex, SOindex, "Cube")}
                                                                                                                                                                                        options={getFollowerCube}
                                                                                                                                                                                        // value={getFollowerCube.filter(function (option) {
                                                                                                                                                                                        //     return option.value === ProgItem.FollowerCubeIds;
                                                                                                                                                                                        // })}
                                                                                                                                                                                        value={SOitem.FollowerCubeIds}
                                                                                                                                                                                        styles={followerpopupvalidation && SOitem.FollowerCubeIds === '' ? styles2 : styles1}
                                                                                                                                                                                    >  </Reactselect>

                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>


                                                                                                                                                                    <div className="row">
                                                                                                                                                                        <div className="col-md-8">
                                                                                                                                                                            <div className='form-group'>
                                                                                                                                                                                <label htmlFor={"Cube"} >Buyer <span className="text-danger">*</span></label>
                                                                                                                                                                                <span className='input-icon icon-right'>
                                                                                                                                                                                    <Reactselect className="basic-single" name={"Cube"}
                                                                                                                                                                                        isDisabled={false}
                                                                                                                                                                                        isLoading={false}
                                                                                                                                                                                        isClearable={true}
                                                                                                                                                                                        isSearchable={true}
                                                                                                                                                                                        isMulti
                                                                                                                                                                                        onChange={event => handleChangeFollower(event, index, STindex, SOindex, "Buyer")}
                                                                                                                                                                                        options={getFollowerBuyer}
                                                                                                                                                                                        // value={getFollowerBuyer.filter(function (option) {
                                                                                                                                                                                        //     return option.value === ProgItem.FollowerBuyerIds;
                                                                                                                                                                                        // })}
                                                                                                                                                                                        value={SOitem.FollowerBuyerIds}
                                                                                                                                                                                        styles={followerpopupvalidation && SOitem.FollowerBuyerIds === '' ? styles2 : styles1}
                                                                                                                                                                                    >  </Reactselect>

                                                                                                                                                                                </span>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </Modal.Body>
                                                                                                                                                                <Modal.Footer>
                                                                                                                                                                    <Button variant="success" onClick={() => handleSaveFollower(index, STindex, SOindex)}>
                                                                                                                                                                        Save
                                                                                                                                                                    </Button>

                                                                                                                                                                </Modal.Footer>
                                                                                                                                                            </Modal>
                                                                                                                                                        }
                                                                                                                                                    </td>
                                                                                                                                                    <td>
                                                                                                                                                        <button type="button" className="btn btn-xs btn-primary" title="Approval Form"
                                                                                                                                                            onClick={() => OpenStyleMapPopup(index, STindex, SOindex)}
                                                                                                                                                        >Map &nbsp;
                                                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                                                        </button>
                                                                                                                                                        {getnewstyle === 1 &&
                                                                                                                                                            <Modal show={getnewstyle === 1} size="md" backdrop="static" keyboard={false} onHide={() => CloseStyleMapPopup(index, STindex, SOindex)}>
                                                                                                                                                                <Modal.Header closeButton>
                                                                                                                                                                    <Modal.Title>
                                                                                                                                                                        Add Styles
                                                                                                                                                                    </Modal.Title>
                                                                                                                                                                </Modal.Header>
                                                                                                                                                                <Modal.Body>
                                                                                                                                                                    {
                                                                                                                                                                        SOitem.StyleMap &&
                                                                                                                                                                        SOitem.StyleMap.map((smitem, smindex) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <div className="row">
                                                                                                                                                                                    <div className="col-md-8">
                                                                                                                                                                                        <div className='form-group'>

                                                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                                                <input placeholder="Style Name"
                                                                                                                                                                                                    id="StyleName"
                                                                                                                                                                                                    name="StyleName"
                                                                                                                                                                                                    type="text"
                                                                                                                                                                                                    autoComplete="off"
                                                                                                                                                                                                    maxLength="50"
                                                                                                                                                                                                    className={'form-control'}
                                                                                                                                                                                                    value={smitem.StyleName}
                                                                                                                                                                                                    onBlur={() => CheckDuplicate(index, STindex, SOindex, smindex)}
                                                                                                                                                                                                    onChange={(event) => handleStyleMapChange(event, index, STindex, SOindex, smindex)}
                                                                                                                                                                                                    style={{ border: submitteds && !smitem.StyleName ? '1px solid red' : '' }}
                                                                                                                                                                                                    autoFocus
                                                                                                                                                                                                />

                                                                                                                                                                                            </span>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    {SOitem.StyleMap.length !== 1 && (
                                                                                                                                                                                        <span >
                                                                                                                                                                                            <button type="button" className="btn  btn-xs  btn-danger" title="Delete Styles"
                                                                                                                                                                                                onClick={event => handleRemoveFieldsStyleMap(index, STindex, SOindex, smindex)}>
                                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                                            </button>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    )}
                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                    {
                                                                                                                                                                                        SOitem.StyleMap.length === (smindex + 1) && (
                                                                                                                                                                                            <span>
                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-success" title="Add Styles"
                                                                                                                                                                                                    onClick={
                                                                                                                                                                                                        () => handleAddFieldsStyleMap(index, STindex, SOindex, smindex)
                                                                                                                                                                                                    }>
                                                                                                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                                                                                                </button>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        )
                                                                                                                                                                                    }
                                                                                                                                                                                </div>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }




                                                                                                                                                                </Modal.Body>
                                                                                                                                                                <Modal.Footer>
                                                                                                                                                                    <Button variant="success" onClick={() => handleSaveStyleMap(index, STindex, SOindex)}>
                                                                                                                                                                        Save
                                                                                                                                                                    </Button>

                                                                                                                                                                </Modal.Footer>
                                                                                                                                                            </Modal>
                                                                                                                                                        }
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                                {
                                                                                                                                                    SOitem.ManualInfo.map((man, j) => {
                                                                                                                                                        return (
                                                                                                                                                            man.ManualCloseComment !== '' && SOitem.GenStrikeOffApprovalType === 2 ?
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="10">
                                                                                                                                                                        <span><b>Manual Comment :</b> {man.ManualCloseComment}</span>
                                                                                                                                                                        {/* <span style={{ color: '#427fed' }}>Date : {new Date(man.ManualCloseDate).toLocaleDateString()}</span> */}
                                                                                                                                                                        <tr>
                                                                                                                                                                            <td>
                                                                                                                                                                                {
                                                                                                                                                                                    man.Files &&
                                                                                                                                                                                        man.Files.length !== 0 ?
                                                                                                                                                                                        SaveInfo === "Add" ?
                                                                                                                                                                                            man.Files.map((files, fileindex) => (
                                                                                                                                                                                                <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                                                                                    <tr title={files.fileName}>
                                                                                                                                                                                                        File:
                                                                                                                                                                                                        <td className="cut_textInTNA">
                                                                                                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                                }
                                                                                                                                                                                                            </span>  {files.fileName}
                                                                                                                                                                                                        </td>

                                                                                                                                                                                                        <td>

                                                                                                                                                                                                            {
                                                                                                                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                        style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                                                                                                                        onClick={
                                                                                                                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                        }></i>
                                                                                                                                                                                                            }
                                                                                                                                                                                                        </td>
                                                                                                                                                                                                    </tr>
                                                                                                                                                                                                    {

                                                                                                                                                                                                        <div> {
                                                                                                                                                                                                            isOpenimg && (

                                                                                                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                    alt="bg image"
                                                                                                                                                                                                                    onCloseRequest={
                                                                                                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                                                                                                    } />
                                                                                                                                                                                                            )
                                                                                                                                                                                                        } </div>
                                                                                                                                                                                                    }
                                                                                                                                                                                                </div>
                                                                                                                                                                                            ))
                                                                                                                                                                                            :
                                                                                                                                                                                            man.Files.filter(x => x.IsDeleted !== 1).map((files, fileindex) => (
                                                                                                                                                                                                <div className="col-md-2 file_list_uploads fixed_width">
                                                                                                                                                                                                    <tr title={files.fileName}>
                                                                                                                                                                                                        File:
                                                                                                                                                                                                        <td className="cut_textInTNA" >
                                                                                                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                                                }
                                                                                                                                                                                                            </span>  {files.fileName}
                                                                                                                                                                                                        </td>

                                                                                                                                                                                                        <td>

                                                                                                                                                                                                            {
                                                                                                                                                                                                                files.fileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                                        onClick={
                                                                                                                                                                                                                            event => ViewFile(event, files.fileName)
                                                                                                                                                                                                                        }></i>
                                                                                                                                                                                                            }
                                                                                                                                                                                                        </td>
                                                                                                                                                                                                    </tr>
                                                                                                                                                                                                    {

                                                                                                                                                                                                        <div> {
                                                                                                                                                                                                            isOpenimg && (

                                                                                                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                                                    alt="bg image"
                                                                                                                                                                                                                    onCloseRequest={
                                                                                                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                                                                                                    } />
                                                                                                                                                                                                            )
                                                                                                                                                                                                        } </div>
                                                                                                                                                                                                    }
                                                                                                                                                                                                </div>
                                                                                                                                                                                            ))
                                                                                                                                                                                        : ''


                                                                                                                                                                                }
                                                                                                                                                                            </td>
                                                                                                                                                                        </tr>
                                                                                                                                                                        <span>

                                                                                                                                                                        </span>

                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                                : ''



                                                                                                                                                        );


                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                                {
                                                                                                                                                    SOitem.SelectedMapRow.map((man, j) => {
                                                                                                                                                        return (
                                                                                                                                                            SOitem.GenStrikeOffApprovalType === 3 && man.isSelected === 1 ?
                                                                                                                                                                <tr>
                                                                                                                                                                    <td colSpan="10">
                                                                                                                                                                        <span><b>Mapped Strikeoff Detail </b> &nbsp;</span>
                                                                                                                                                                        <br />
                                                                                                                                                                        <span><b>Season Name : </b> {man.seasonName} &nbsp;</span>
                                                                                                                                                                        <span><b>Approval Date : </b>  {new Date(man.approvalDate).toLocaleDateString()}&nbsp;</span>
                                                                                                                                                                        <span><b>Details : </b> {man.details}&nbsp;</span>
                                                                                                                                                                        <span><b>Approved By : </b> {man.approvedBy}&nbsp;</span>

                                                                                                                                                                    </td>
                                                                                                                                                                </tr>
                                                                                                                                                                : ''



                                                                                                                                                        );


                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                                <tr>
                                                                                                                                                    <td colSpan={SOitem.GenStrikeOffPurchaseType === 1 || SOitem.GenStrikeOffPurchaseType === 3 ? "7" : "6"}>
                                                                                                                                                        <div>
                                                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                                                                                <thead>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <th>Height</th>
                                                                                                                                                                        <th>Width</th>
                                                                                                                                                                        <th>UOM</th>
                                                                                                                                                                        <th>Garment Size</th>
                                                                                                                                                                        <th>Action</th>
                                                                                                                                                                    </tr>
                                                                                                                                                                </thead>
                                                                                                                                                                <tbody>
                                                                                                                                                                    {
                                                                                                                                                                        SOitem.Reference.map((refInfo, refindex) => (
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td>{refInfo.height}</td>
                                                                                                                                                                                <td>{refInfo.width}</td>
                                                                                                                                                                                <td>{refInfo.sizeName}</td>

                                                                                                                                                                                <td> <Reactselect className="basic-single"
                                                                                                                                                                                    name="SizeId"
                                                                                                                                                                                    id={"SizeId"}
                                                                                                                                                                                    isDisabled={false}
                                                                                                                                                                                    isLoading={false}
                                                                                                                                                                                    isClearable={true}
                                                                                                                                                                                    isSearchable={true}
                                                                                                                                                                                    options={getSizeList}
                                                                                                                                                                                    value={refInfo.StyleStrikeOffGarmentSizeEdit}
                                                                                                                                                                                    isMulti
                                                                                                                                                                                    onChange={event => handleRefChange(event, index, STindex, SOindex, refindex)}
                                                                                                                                                                                    //menuPlacement="auto"
                                                                                                                                                                                    styles={submitted && refInfo.StyleStrikeOffGarmentSizeEdit === '' ? styles2 : styles1}
                                                                                                                                                                                // menuPosition="fixed"

                                                                                                                                                                                ></Reactselect></td>
                                                                                                                                                                                <td>
                                                                                                                                                                                    {SOitem.Reference.length !== 1 && (
                                                                                                                                                                                        <span >
                                                                                                                                                                                            <button type="button" className="btn  btn-xs  btn-danger" title="Delete StoryTheme"
                                                                                                                                                                                                onClick={event => handleRemoveRef(index, STindex, SOindex, refindex, refInfo.strikeOffReferenceID)}>
                                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                                            </button>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    )}
                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                    {
                                                                                                                                                                                        SOitem.Reference.length === (refindex + 1) && (
                                                                                                                                                                                            <span>
                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-success" title="Add StoryTheme details"
                                                                                                                                                                                                    onClick={
                                                                                                                                                                                                        () => handleAddRef(index, STindex, SOindex, refindex)
                                                                                                                                                                                                    }>
                                                                                                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                                                                                                </button>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        )
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        ShowAddReference === true ?
                                                                                                                                                                                            <Modal show={ShowAddReference === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setShowAddReference(false)}>
                                                                                                                                                                                                <Modal.Header closeButton>
                                                                                                                                                                                                    <Modal.Title>
                                                                                                                                                                                                        Add Reference
                                                                                                                                                                                                    </Modal.Title>
                                                                                                                                                                                                </Modal.Header>
                                                                                                                                                                                                <Modal.Body>

                                                                                                                                                                                                    <table className="table table-bordered">
                                                                                                                                                                                                        <thead>
                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                <th>Height</th>
                                                                                                                                                                                                                <th>Width</th>
                                                                                                                                                                                                                <th>UOM</th>
                                                                                                                                                                                                                <th>Select</th>


                                                                                                                                                                                                            </tr>
                                                                                                                                                                                                        </thead>
                                                                                                                                                                                                        <tbody>
                                                                                                                                                                                                            {
                                                                                                                                                                                                                AddReferenceList.length !== 0 ?
                                                                                                                                                                                                                    AddReferenceList.map((item, itemindex) => {
                                                                                                                                                                                                                        return (
                                                                                                                                                                                                                            <>
                                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                                    <td>{item.height}</td>
                                                                                                                                                                                                                                    <td>{item.width}</td>
                                                                                                                                                                                                                                    <td>{item.sizeName}</td>
                                                                                                                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                                                                    onChange={event => (OnSelectAddReference(event, itemindex))}
                                                                                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                                                                                            </label>

                                                                                                                                                                                                                                        </div>

                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                </tr>



                                                                                                                                                                                                                            </>

                                                                                                                                                                                                                        )
                                                                                                                                                                                                                    }) :
                                                                                                                                                                                                                    <tr>
                                                                                                                                                                                                                        <td colSpan={4} className='norecordfound'>
                                                                                                                                                                                                                            <div align="center"> <span>No Records Found</span></div>
                                                                                                                                                                                                                        </td>
                                                                                                                                                                                                                    </tr>
                                                                                                                                                                                                            }



                                                                                                                                                                                                        </tbody>
                                                                                                                                                                                                    </table>
                                                                                                                                                                                                    <br />

                                                                                                                                                                                                </Modal.Body>
                                                                                                                                                                                                <Modal.Footer>
                                                                                                                                                                                                    <Button variant="success"
                                                                                                                                                                                                        onClick={() => AddReference(index, STindex, SOindex, refindex)}
                                                                                                                                                                                                    >
                                                                                                                                                                                                        Add
                                                                                                                                                                                                    </Button>

                                                                                                                                                                                                </Modal.Footer>
                                                                                                                                                                                            </Modal> : ''
                                                                                                                                                                                    }
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>

                                                                                                                                                                        ))
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        SOitem.Reference.length === 0 ?
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                                                                            </tr> : ''
                                                                                                                                                                    }
                                                                                                                                                                </tbody>
                                                                                                                                                            </table>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                                <tr>
                                                                                                                                                    <td colSpan={SOitem.GenStrikeOffPurchaseType === 1 || SOitem.GenStrikeOffPurchaseType === 3 ? "7" : "6"}>
                                                                                                                                                        <div>
                                                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                                                                                <thead>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <th style={{ width: "19%" }}>Image</th>
                                                                                                                                                                        <th>Pantone/Swatch Details</th>
                                                                                                                                                                        <th>Action</th>
                                                                                                                                                                    </tr>
                                                                                                                                                                </thead>
                                                                                                                                                                <tbody>
                                                                                                                                                                    {
                                                                                                                                                                        SOitem.PrintColor.map((logoInfo, colorindex) => (
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td><img alt="img" className="width_manual" src={window.$APIBaseURL + "Images/Style/Strikeoff/" + logoInfo.strikeoffLogoPath}
                                                                                                                                                                                    onClick={
                                                                                                                                                                                        () => setIsOpen(window.$APIBaseURL + 'Images/Style/Strikeoff/' + logoInfo.strikeoffLogoPath)
                                                                                                                                                                                    }
                                                                                                                                                                                ></img></td>

                                                                                                                                                                                <td>
                                                                                                                                                                                    <table className="table_inside" style={{ width: "auto" }}>
                                                                                                                                                                                        <tbody>
                                                                                                                                                                                            {
                                                                                                                                                                                                comments.filter(x => x.indexName === logoInfo.indexName && x.strikeOffID === logoInfo.strikeOffID).map((color, index_Value) => (
                                                                                                                                                                                                    <>
                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            {
                                                                                                                                                                                                                color.pantoneTypeName !== 'Others' &&

                                                                                                                                                                                                                <td>
                                                                                                                                                                                                                    <div style={{ background: color.pantoneColor, marginLeft: "5px", verticalAlign: "bottom", width: '120px', display: "inline-block", height: '34px', borderRadius: '2px', marginBottom: "2px" }}></div>
                                                                                                                                                                                                                </td>
                                                                                                                                                                                                            }
                                                                                                                                                                                                           
                                                                                                                                                                                                            {
                                                                                                                                                                                                                color.pantoneTypeName === 'Others' &&

                                                                                                                                                                                                                <td className="trwidth_manual">
                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        < img className="width_manual"
                                                                                                                                                                                                                            src={color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor}
                                                                                                                                                                                                                            alt="Logo"

                                                                                                                                                                                                                            onClick={() => setIsOpen(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor)}
                                                                                                                                                                                                                        //onClick={() => OpenSlider(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + color.pantoneColor)}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    }</td>
                                                                                                                                                                                                            }
                                                                                                                                                                                                            
                                                                                                                                                                                                             <td style={{textAlign:"center"}}>{color.pantoneCode}   {color.pantoneTypeName}</td>

                                                                                                                                                                                                            {(color.notes !== '' || color.targettoClose != null || color.completedOn !== null || color.remindMe !== 0) && (
                                                                                                                                                                                                                <td>
                                                                                                                                                                                                                    <i class="fa fa-exclamation-triangle" style={{ color: "red" }}
                                                                                                                                                                                                                        onClick={() => ShowCritical(color.notes, color.targettoClose, color.completedOn, color.remindMe)}></i>
                                                                                                                                                                                                                </td>
                                                                                                                                                                                                            )}

                                                                                                                                                                                                            {getShow &&
                                                                                                                                                                                                                <Modal show={getShow} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClose()}>
                                                                                                                                                                                                                    <Modal.Header closeButton>
                                                                                                                                                                                                                        <Modal.Title>Critical</Modal.Title>
                                                                                                                                                                                                                    </Modal.Header>
                                                                                                                                                                                                                    <Modal.Body>
                                                                                                                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                                                                                                            <thead>
                                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                                    <th colSpan="4" style={{ textAlign: "center" }}>Critical
                                                                                                                                                                                                                                    </th>
                                                                                                                                                                                                                                </tr>
                                                                                                                                                                                                                            </thead>
                                                                                                                                                                                                                            <tbody>
                                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                                    <td colSpan="4">
                                                                                                                                                                                                                                        <textarea
                                                                                                                                                                                                                                            placeholder="Enter comments here..."
                                                                                                                                                                                                                                            maxLength="2000"
                                                                                                                                                                                                                                            value={
                                                                                                                                                                                                                                                getPopupValue[0].Notes
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                            disabled
                                                                                                                                                                                                                                            rows="4" style={{ width: "100%" }}></textarea>
                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                </tr>
                                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                                    <td>Target to Close</td>
                                                                                                                                                                                                                                    <td>
                                                                                                                                                                                                                                        <input
                                                                                                                                                                                                                                            type="text"
                                                                                                                                                                                                                                            autoComplete="off"
                                                                                                                                                                                                                                            maxLength="50"
                                                                                                                                                                                                                                            className={'form-control'}
                                                                                                                                                                                                                                            value={getPopupValue[0].TargettoClose}
                                                                                                                                                                                                                                            disabled
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                    <td>Remind Me</td>
                                                                                                                                                                                                                                    <td>
                                                                                                                                                                                                                                        <input
                                                                                                                                                                                                                                            type="text"
                                                                                                                                                                                                                                            autoComplete="off"
                                                                                                                                                                                                                                            maxLength="50"
                                                                                                                                                                                                                                            className={'form-control'}
                                                                                                                                                                                                                                            value={getPopupValue[0].RemindMe === 1 ? "Current Date Reaches Target Date" : getPopupValue[0].RemindMe === 2 ? "One Time in a Day, until complete" : ""}
                                                                                                                                                                                                                                            disabled
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                </tr>
                                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                                    <td>Completed On</td>
                                                                                                                                                                                                                                    <td>
                                                                                                                                                                                                                                        <input
                                                                                                                                                                                                                                            type="text"
                                                                                                                                                                                                                                            autoComplete="off"
                                                                                                                                                                                                                                            maxLength="50"
                                                                                                                                                                                                                                            className={'form-control'}
                                                                                                                                                                                                                                            value={getPopupValue[0].CompletedOn}
                                                                                                                                                                                                                                            disabled
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                </tr>
                                                                                                                                                                                                                            </tbody>
                                                                                                                                                                                                                        </table>
                                                                                                                                                                                                                    </Modal.Body>
                                                                                                                                                                                                                    <Modal.Footer>
                                                                                                                                                                                                                        <Button variant="danger" onClick={() => handleClose()}>
                                                                                                                                                                                                                            Close
                                                                                                                                                                                                                        </Button>
                                                                                                                                                                                                                    </Modal.Footer>
                                                                                                                                                                                                                </Modal>
                                                                                                                                                                                                            }

                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </>
                                                                                                                                                                                                ))
                                                                                                                                                                                            }
                                                                                                                                                                                        </tbody>
                                                                                                                                                                                    </table>
                                                                                                                                                                                </td>
                                                                                                                                                                                <td>

                                                                                                                                                                                    {SOitem.PrintColor.length !== 1 && (
                                                                                                                                                                                        <span >
                                                                                                                                                                                            <button type="button" className="btn  btn-xs  btn-danger" title="Delete StoryTheme"
                                                                                                                                                                                                onClick={event => handleRemovePrintColor(index, STindex, SOindex, colorindex, logoInfo.colorGroupID)}>
                                                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                                                            </button>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    )}
                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                    {
                                                                                                                                                                                        SOitem.PrintColor.length === (colorindex + 1) && (
                                                                                                                                                                                            <span>
                                                                                                                                                                                                <button type="button" className="btn btn-xs btn-success" title="Add StoryTheme details"
                                                                                                                                                                                                    onClick={
                                                                                                                                                                                                        () => handleAddPrintColor(index, STindex, SOindex, colorindex)
                                                                                                                                                                                                    }>
                                                                                                                                                                                                    <i className="fa fa-plus"></i>
                                                                                                                                                                                                </button>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        )
                                                                                                                                                                                    }
                                                                                                                                                                                    {ShowAddPrintColor === true &&
                                                                                                                                                                                        <Modal show={ShowAddPrintColor === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setShowAddPrintColor(false)}>
                                                                                                                                                                                            <Modal.Header closeButton>
                                                                                                                                                                                                <Modal.Title>Add PrintColor</Modal.Title>
                                                                                                                                                                                            </Modal.Header>
                                                                                                                                                                                            <Modal.Body>
                                                                                                                                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                                                                                                                                                    <thead>
                                                                                                                                                                                                        <tr>
                                                                                                                                                                                                            <th style={{ width: "19%" }}>Image</th>
                                                                                                                                                                                                            <th>Pantone/Swatch Details</th>
                                                                                                                                                                                                            <th>Select</th>
                                                                                                                                                                                                        </tr>
                                                                                                                                                                                                    </thead>
                                                                                                                                                                                                    <tbody>
                                                                                                                                                                                                        {
                                                                                                                                                                                                            AddPrintColorList.map((logoInfo, colorindex) => (
                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                    <td><img alt="img" className="width_manual" src={window.$APIBaseURL + "Images/Style/Strikeoff/" + logoInfo.strikeoffLogoPath}
                                                                                                                                                                                                                        onClick={
                                                                                                                                                                                                                            () => setIsOpen(window.$APIBaseURL + 'Images/Style/Strikeoff/' + logoInfo.strikeoffLogoPath)
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                    ></img></td>

                                                                                                                                                                                                                    <td>
                                                                                                                                                                                                                        <table className="table_inside" style={{ width: "auto" }}>
                                                                                                                                                                                                                            <tbody>
                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                    comments.filter(x => x.indexName === logoInfo.indexName && x.strikeOffID === logoInfo.strikeOffID).map((color, index_Value) => (
                                                                                                                                                                                                                                        <>
                                                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                                                <td>{color.pantoneCode}   {color.pantoneTypeName}</td>
                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    color.pantoneTypeName !== 'Others' &&

                                                                                                                                                                                                                                                    <td>
                                                                                                                                                                                                                                                        <div style={{ background: color.pantoneColor, marginLeft: "5px", verticalAlign: "bottom", width: '120px', display: "inline-block", height: '34px', borderRadius: '2px', marginBottom: "2px" }}></div>
                                                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                {
                                                                                                                                                                                                                                                    color.pantoneTypeName === 'Others' &&

                                                                                                                                                                                                                                                    <td className="trwidth_manual">
                                                                                                                                                                                                                                                        {
                                                                                                                                                                                                                                                            < img className="width_manual"
                                                                                                                                                                                                                                                                src={color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor}
                                                                                                                                                                                                                                                                alt="Logo"

                                                                                                                                                                                                                                                                onClick={() => setIsOpen(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + color.pantoneColor)}
                                                                                                                                                                                                                                                            //onClick={() => OpenSlider(color.pantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + color.pantoneColor)}
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                        }</td>
                                                                                                                                                                                                                                                }





                                                                                                                                                                                                                                            </tr>
                                                                                                                                                                                                                                        </>
                                                                                                                                                                                                                                    ))
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            </tbody>
                                                                                                                                                                                                                        </table>
                                                                                                                                                                                                                    </td>
                                                                                                                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                                                    onChange={event => (OnSelectAddPrintColors(event, colorindex))}
                                                                                                                                                                                                                                    checked={logoInfo.isSelected === 1 ? true : false}
                                                                                                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                                                                            </label>

                                                                                                                                                                                                                        </div>

                                                                                                                                                                                                                    </td>


                                                                                                                                                                                                                </tr>

                                                                                                                                                                                                            ))
                                                                                                                                                                                                        }
                                                                                                                                                                                                        {
                                                                                                                                                                                                            AddPrintColorList.length === 0 ?
                                                                                                                                                                                                                <tr>
                                                                                                                                                                                                                    <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                                                                                                                </tr> : ''
                                                                                                                                                                                                        }
                                                                                                                                                                                                    </tbody>

                                                                                                                                                                                                </table>
                                                                                                                                                                                            </Modal.Body>
                                                                                                                                                                                            <Modal.Footer>
                                                                                                                                                                                                <Button variant="success"
                                                                                                                                                                                                    onClick={() => AddPrintColor(index, STindex, SOindex, colorindex)}
                                                                                                                                                                                                >
                                                                                                                                                                                                    Add
                                                                                                                                                                                                </Button>
                                                                                                                                                                                            </Modal.Footer>
                                                                                                                                                                                        </Modal>
                                                                                                                                                                                    }

                                                                                                                                                                                    {/* <button type="button" className="btn  btn-xs  btn-danger" title="Delete Color"
                                                                                                                                                                                            onClick={
                                                                                                                                                                                                () => ClearColorInfo(index, STindex, SOindex, colorindex)
                                                                                                                                                                                            }>
                                                                                                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                                                                                                        </button> */}
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>

                                                                                                                                                                        ))
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        SOitem.PrintColor.length === 0 ?
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                                                                                                                                            </tr> : ''
                                                                                                                                                                    }
                                                                                                                                                                </tbody>
                                                                                                                                                            </table>

                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </>


                                                                                                                                        }
                                                                                                                                    </tbody>
                                                                                                                                </table>

                                                                                                                            </CollapsiblePanel>
                                                                                                                        </div>
                                                                                                                        <div> {
                                                                                                                                                isOpen && (

                                                                                                                                                    <Lightbox mainSrc={isOpen}
                                                                                                                                                        alt="bg image"
                                                                                                                                                        onCloseRequest={
                                                                                                                                                            () => setIsOpen(false)
                                                                                                                                                        } />
                                                                                                                                                )

                                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="col-lg-1">
                                                                                                                            {
                                                                                                                                getHeader === "Add" && (
                                                                                                                                    <div className="">
                                                                                                                                        {STitem.Strikeoff.length !== 1 && (
                                                                                                                                            <span >
                                                                                                                                                <button type="button" className="btn  btn-xs  btn-danger" title="Delete Strikeoff"
                                                                                                                                                    onClick={event => handleRemoveFieldsStrikeoff(index, STindex, SOindex)}>
                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                </button>
                                                                                                                                            </span>
                                                                                                                                        )}
                                                                                                                                        &nbsp;
                                                                                                                                        {
                                                                                                                                            STitem.Strikeoff.length === (SOindex + 1) && (
                                                                                                                                                <span>
                                                                                                                                                    <button type="button" className="btn btn-xs btn-success" title="Add Strikeoff details"
                                                                                                                                                        onClick={
                                                                                                                                                            () => handleAddFieldsStrikeoff('Add', index, STindex, SOindex)
                                                                                                                                                        }>
                                                                                                                                                        <i className="fa fa-plus"></i>
                                                                                                                                                    </button>
                                                                                                                                                </span>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        &nbsp;

                                                                                                                                        {/* <span className="btn btn-primary" title="Copy StoryTheme" onClick={() => handleAddFieldsStrikeoff('Copy', index, STindex, SOindex)}>
                                                                                                                                            <i className="fa fa-copy"></i>
                                                                                                                                        </span> */}


                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                )
                                                                                                            })



                                                                                                            :
                                                                                                            <>
                                                                                                                {

                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                        <thead className="text-center">
                                                                                                                            <tr>
                                                                                                                                <th className="text-center">Pantone Type</th>
                                                                                                                                <th className="text-center">Pantone Code / Swatch Name</th>
                                                                                                                                <th className="text-center">Color / Swatch Image</th>
                                                                                                                                <th className="text-center">Ref. Color Name</th>
                                                                                                                                <th className="text-center ">Item (Fabric / Yarn)</th>
                                                                                                                                <th className="text-center">Process Type</th>
                                                                                                                                <th className="text-center">Approval Type</th>
                                                                                                                                <th className="text-center">Follower</th>
                                                                                                                                <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            <tr><td colSpan="6" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                        </tbody>
                                                                                                                    </table>


                                                                                                                }

                                                                                                            </>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>




                                                                                    </>

                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            </Fragment>
                                                        ))
                                                    }

                                                </div >
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                                <a className="btn btn-primary" href="/SamGenlStrikeOffList">
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </a>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                {Buttonvisible !== "View" &&
                                    (<button type="submit" className="btn btn-success" onClick={(e) => SaveGeneralStrikeoff(e)} disabled={getDisableSave}>

                                        <i className="fa fa-check right"></i> &nbsp;{getHeader === 'Add' ? 'Save' : 'Update'}
                                    </button>)
                                }
                                {Buttonvisible === "View" && activeMenu[0].isView === 1 ? '' :
                                    activeMenu[0].isView === 0 ? '' :
                                        (<button type="submit" style={{ display: activeMenu[0].isView === 0 ? '' : 'none' }} className="btn btn-info" onClick={viewtoEdit}>
                                            <i className="fa fa-edit"></i>&nbsp;Edit
                                        </button>)
                                }
                            </div>
                        </div>
                    </div>

                </div >




            }
        </>

    )
}
export default SamGenStrikeOffAddEdit;
