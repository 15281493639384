import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Redirect } from 'react-router-dom';
// import InternalQAAuditDetails from './InspectionQCDetails'
// import InternalQAAuditInspectionEntry from './InspectionQCInspectionEntry'
// import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import TestingDetails from './TestingDetails';
import TestEntries from './TestEntries';
import Retest from './Retest';
import moment from "moment";
import { GetTestDetailsReportList } from "../../../actions/tna";
// import { LoadQADetailsreport } from '../../../../actions/inspectionQC'
const YarnTesting = ({ props, TaskValue, StyleNameOptions }) => {
    var defaultStartDate = new Date();
    const [getTabName, setTabName] = useState("idTestingDetail1");
    var defaultStartDate = new Date();
    const [getExFacStartDate, setExFacStartDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            // startDate: moment().subtract(90, "days").format("DD/MM/YYYY"),
            //endDate: moment().subtract(1, "days").add(1, "days").format("DD/MM/YYYY"),
            key: 'selection',
            isShow: false
        }
    ]);
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const dispatch = useDispatch();

    useEffect(() => {
       
        // setTabName("TestDetails")
        // ActivateDetailsTab()
        if (props.location.state != null && props.location.state.params.tabName == "To do Re Test") {
            ActivateReTestTab('idTestingReTest1');
            }
        else if (props.location.state != null && props.location.state.params.tabName == "Testing Entry") {
            ActivateEntryTab('idTestingEntry1');
            }
        else if(props.location.state == null || 
            props.location.state.params.tabName == "Testing Details" ||
            props.location.state.params.tabName == undefined){
            ActivateDetailsTab('idTestingDetail1');
          }
        }, [props.location.state])

    const ActivateDetailsTab = (tabid) => {
        
        document.getElementById("liTestingDetails1").className = "active";
        var elementEntry = document.getElementById("liTestingEntry1");
        elementEntry.classList.remove("active");
        var elementReTest = document.getElementById("liTestingReTest1");
        elementReTest.classList.remove("active");
        document.getElementById("idTestingDetail1").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingEntry1").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest1").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }
    const ActivateEntryTab = (tabid) => {
       
        document.getElementById("liTestingEntry1").className = "active";
        var elementDetails = document.getElementById("liTestingDetails1");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingReTest1");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail1").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry1").className = "tab-pane col-lg-12 active";
        document.getElementById("idTestingReTest1").className = "tab-pane col-lg-12";
        setTabName(tabid);
    }

    const ActivateReTestTab = (tabid) => {
        
        document.getElementById("liTestingReTest1").className = "active";
        var elementDetails = document.getElementById("liTestingDetails1");
        elementDetails.classList.remove("active");
        var elementEntry = document.getElementById("liTestingEntry1");
        elementEntry.classList.remove("active");
        document.getElementById("idTestingDetail1").className = "tab-pane col-lg-12";
        document.getElementById("idTestingEntry1").className = "tab-pane col-lg-12";
        document.getElementById("idTestingReTest1").className = "tab-pane col-lg-12 active";
        setTabName(tabid);
    }

    // useEffect(() => {

    //     // showLoader();
    //     dispatch(GetTestDetailsReportList(IsProduction
    //         , TaskValue
    //         , moment(getExFacStartDate[0].startDate).subtract(90, "days").format("DD/MM/YYYY")
    //         , moment(getExFacStartDate[0].endDate).subtract(1, "days").add(1, "days").format("DD/MM/YYYY")
    //         , moment(getExFacStartDate[0].startDate).subtract(90, "days").format("DD/MM/YYYY")
    //         , moment(getExFacStartDate[0].endDate).subtract(1, "days").add(1, "days").format("DD/MM/YYYY")
    //         , 0));

    // }, [TaskValue, IsProduction])


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable max-width-view-port">
                        <ul className="nav nav-tabs nav-justified" id="myTab5" >
                            <li className="active" id="liTestingDetails1">
                                <a data-toggle="tab" href="#idTestingDetail1" id="aidTestingDetail"
                                    onClick={() => ActivateDetailsTab("idTestingDetail1")}>
                                    <h5>Testing Details</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingEntry1" >
                                <a data-toggle="tab" href="#idTestingEntry1" id="aidTestingEntry"
                                    onClick={() => ActivateEntryTab("idTestingEntry1")}
                                >
                                    <h5>Testing  Entry</h5>
                                </a>
                            </li>
                            <li className="" id="liTestingReTest1" >
                                <a data-toggle="tab" href="#idTestingReTest1" id="aidTestingReTest"
                                    onClick={() => ActivateReTestTab("idTestingReTest1")}
                                >
                                    <h5>To do Re Test</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content Dashboard" style={{ padding: "0px" }}>
                            <div id="idTestingDetail1" className="" style={{ padding: "0px" }} >
                                {getTabName === "idTestingDetail1" && <TestingDetails props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingEntry1" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingEntry1" && <TestEntries props={props} Taskcallback={TaskValue} />}
                            </div>
                            <div id="idTestingReTest1" className="" style={{ padding: "0px" }}>
                                {getTabName === "idTestingReTest1" && <Retest props={props} Taskcallback={TaskValue} />}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </Fragment>
    )
}

export default YarnTesting