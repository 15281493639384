import React, { useState, useEffect, useMemo, useRef ,Fragment} from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment, { max } from "moment";
import { LoadCommentsReportDetails, UpdateCommentsreportViewAllCheckBox, UpdateCommentsreportViewCheckBox } from "../../actions/reports";
import { useDispatch, useSelector } from "react-redux";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import SearchRetain from "../Common/SearchRetain";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
//import { Pagination, Search, PageCount, ReactSearch } from "../Datatable";
import CommentReportPageCount from "../Datatable/PageCount/CommentreportPgcount";
import CmtreportPaginationComponent from "../Datatable/Pagination/CmtReportpagination";
import TableHeader from '../Datatable/Header/commetstyle'
//import CommentReportService from '../../services/Report/ReportService'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReportService from "../../services/Report/ReportService";
import * as XLSX from 'xlsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { data, event } from "jquery";
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { ReportSkuComments, ReportSkuOrderStatus, ReportSkuExfactory } from "../Common/PageActionNumber";
import UserLocation from "../Master/UserLocation";
const CommentStyle = (props) => {
  const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
  const [currentPage, setCurrentPage] = useState(1);
  const [getSeasonList, setSeasonList] = useState();
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
  const comments = [];
  const [getImagename, setImagename] = useState();
  const [isOpenimg, setIsOpenimg] = useState(false);
  const [getBuyersearch, setBuyerSearch] = useState("");
  const [getSeasonsearch, setSeasonSearch] = useState("");
  const [getBrandsearch, setBrandSearch] = useState("");
  const [getQuartersearch, setQuarterSearch] = useState("");
  const [getIdPosearch, setIdPoSearch] = useState("");
  const [getSuppliersearch, setsupplierSearch] = useState("");
  const [getEndCustomersearch, setEndCustomerSearch] = useState("");
  const [getCategorysearch, setCategorySearch] = useState("");
  const [getStyleNamesearch, setStyleNameSearch] = useState("");
  const [getStyleNosearch, setStyleNoSearch] = useState("");
  const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
  const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
  const [getOrderStatussearch, setOrderStatusSearch] = useState("");
  const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
  const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
  const [getExFacID, setExFacID] = useState({ showPopupDelete: false, Params: {} });
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [getexcelhtml, setexcelhtml] = useState(false);
  const [getName, setName] = useState('Export to Excel');
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [Submitted, setSubmitted] = useState(false);
  const [EXSubmitted, setEXSubmitted] = useState(false);
  const [EXSubmittedValue, setEXSubmittedValue] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 })
  const [status, setstatus] = useState([]);
  const [selectstatus, setselectstatus] = useState({ value: 3, label: "Minor" });
  const [getHideID, setHideID] = useState({
    Po: true, season: true, Quarter: true, brand: true, customer: true, supplier: true, factory: true, idNo: true,
    styleName: true, styleNo: true, skuName: true, skuImg: true, size: true, orderQty: true, exFac: true, orderStatus: true,
    currentStatus: true, cubeEmp: true, buyerEmp: true, stsCreated: true, cmtCreated: true, skuID: true, category: true
  });

  // User Location
  const [getIsShowGeoLocModal, setgetIsShowGeoLocModal] = useState(false);
  const [getCurrentLocation, setCurrentLocation] = useState({
    Latitude: '',
    Longitude: ''
  });
  // FOR COMMENTS
  const [Commentpopup, setCommentpopup] = useState(false);
  const [getComment, setComment] = useState([])
  const [getpurchaseId, setPurchaseId] = useState("0");
  const [getSupplierID, setSupplierID] = useState("0")
  const [getindex, setindex] = useState(0);
  const [getExfacindex, setExfacindex] = useState(0);
  const [reportcomments, setreportcomments] = useState([])
  const [getExFactoryList, setExFactoryList] = useState([])
  const [getCmtExFactoryList, setCmtExFactoryList] = useState([])
  const [Ecomment, setEcomment] = useState();
  const [ExfacList, setExfacList] = useState([]);
  //End Commentsk
  const [getFiles, setFiles] = useState([])
  //For OrderStatus
  const [selectedOption, setSelectedOptions] = useState({ value: 0, label: '-Select Order Status-' })
  const [selectedExfactory, setSelectedExfactory] = useState()
  const [selectedCommentExfactory, setSelectedCommentExfactory] = useState()
  const [showmodal, setShowmodal] = useState(false);
  const [getExfactoryModal, setExfactoryModal] = useState(false);
  const [getorderStatus, setorderStatus] = useState([]);
  const [getordindex, setOrdindex] = useState(0);
  //End OrdStatus

  const [show, setShow] = useState(false);
  const [sizeqtypopup, setsizeqtypopup] = useState(false);
  const [sizeqtylist, setsizeqtylist] = useState([]);

  // PO/season/Quarter
  //const [getHideID, setHideID] = useState({ Po: true, season: true, Quarter: true });
  const Values = [...getComment]
  const OrdStatus = { ...showmodal }
  const options = {
    thumbnails: {
      showThumbnails: false,
    }
  }
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }

  var defaultStartDate = new Date();
  const [stateDate, setStateDate] = useState([
    {
      startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false
    }
  ]);
  var defaultExStartDate = new Date();
  const [stateExDate, setStateExDate] = useState([
    {
      startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
      endDate: new Date(),
      key: 'selection',
      isShow: false,

    }
  ]);

  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const reducerState = useSelector((state) => state);
  const { user: currentUser } = useSelector((state) => state.auth);
  let activeMenu;
  const menulist = useSelector((state) => state.auth.UserMenuList.item3);
  activeMenu = menulist.filter(i => i.menuName === "CommentsReport")




  const CommentReport = reducerState.report.CommentsReportList.item1 !== undefined ? reducerState.report.CommentsReportList.item1 : reducerState.report.CommentsReportList;

  const isLoadingReport = reducerState.report.isLoadingReport;

  if (CommentReport.length > 0) {

    for (let index = 0; index < CommentReport.length; index++) {
      // // Sorting Problem while Reset button
      // CommentReport[index].currentComments = CommentReport[index].currentComments === null ? '' : CommentReport[index].currentComments;
      // CommentReport[index].commentsCreatedBy = CommentReport[index].commentsCreatedBy === null ? '' : CommentReport[index].commentsCreatedBy;
      // //CommentReport[index].currentComments = CommentReport[index].currentComments === null ? '' : CommentReport[index].currentComments;
      // CommentReport[index].purchaseOrderSkuID = CommentReport[index].purchaseOrderSkuID === null ? '' : CommentReport[index].purchaseOrderSkuID;
      // CommentReport[index].orderStatus = CommentReport[index].orderStatus === null ? '' : CommentReport[index].orderStatus;
      // CommentReport[index].factory = CommentReport[index].factory === null ? '' : CommentReport[index].factory;


      CommentReport[index].supplierName = CommentReport[index].supplierName === null ? '-' : CommentReport[index].supplierName;
      let Parent = CommentReport[index];
      for (let i = 0; i < reducerState.report.CommentsReportList.item2.length; i++) {
        let Child = reducerState.report.CommentsReportList.item2[i];
        if (Child.purchaseOrderID === Parent.purchaseOrderID && Child.indexName === Parent.indexName) {
          Parent.styleName = Child.styleName; Parent.styleNo = Child.styleNo; Parent.categoryName = Child.categoryName;
          Parent.buyerEmployee = Child.buyerEmployee; Parent.cubeEmployee = Child.cubeEmployee;
        }

      }

    }

  }

  useEffect(() => {

    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    setIsProductionStatussearch(Defaultvalue);
    localStorage.setItem('value', (1));

    //setIsProductionStatussearch()

  }, []);

  const Isproduction = JSON.parse(localStorage.getItem("value"));
  useEffect(() => {
    if (!isLoadingReport) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingReport]);

  useEffect(() => {
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: 1, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }, [dispatch]);
  // useEffect()
  useEffect(() => {

    ReportService.GetReportStatusList(0).then((res) => {
      if (res.data) {
        setstatus(res.data)
      }
    })
  }, [])


  const tableHeader = [
    {
      name: "#",
      field: "",
      sortable: false,
      //width: '5%',
       widthsize:'2%',
    },
    getHideID.Po === true ? {
      name: "PO Date",
      field: "poDate",
      sortable: true,
      //width: '5%',

    } : false,
    getHideID.skuID === true ? {
      name: "SKU ID",
      field: "purchaseOrderSkuID",
      sortable: true,
      //width: '2%',

    } : false,

    getHideID.season === true ? {
      name: "Season",
      field: "seasonName",
      sortable: true,
      //width: '3%',


    } : false,

    getHideID.Quarter === true ? {
      name: "Quarter",
      field: "quarterName",
      sortable: true,
      //width: '1%',


    } : false,

    getHideID.brand === true ? {
      name: "Brand",
      field: "brandName",
      sortable: true,
      //width: '5%',


    } : false,

    getHideID.customer === true ? {
      name: "End Customer",
      field: "customerName",
      sortable: true,
      //width: '5%',


    } : false,

    getHideID.supplier === true ?
      {
        name: "Supplier",
        field: "supplierName",
        sortable: true,
        //width: '5%',


      } : false,
    getHideID.factory === true ?
      {
        name: "Factory",
        field: "factory",
        sortable: true,
        //width: '5%',
      } : false,
    getHideID.idNo === true ?
      {
        name: "PO/ID No",
        field: "idpono",
        sortable: true,
        //width: '4%',

      } : false,
    getHideID.category === true ?
      {
        name: "Category",
        field: "categoryName",
        sortable: true,
        //width: '4%',

      } : false,
    getHideID.styleName === true ?
      {
        name: "Style Name",
        field: "styleName",
        sortable: true,
        //width: '7%',

      } : false,
    getHideID.styleNo === true ?
      {
        name: "Style No",
        field: "styleNo",
        sortable: true,
        //width: '7%',

      } : false,
    getHideID.skuName === true ?
      {
        name: "SKU Name",
        field: "skuName",
        sortable: true,
        // width: '5%',


      } : false,
    getHideID.skuImg === true ?
      {
        name: "SKU Image",
        field: "skuImage",
        sortable: true,
        //width: '4%',

      } : false,
    getHideID.size === true ?
      {
        name: "Size",
        field: "size",
        sortable: true,
        //width: '5%',

      } : false,
    getHideID.orderQty === true ?
      {
        name: "Order Qty",
        field: "quantity",
        sortable: true,
        //width: '5%',

      } : false,
    getHideID.exFac === true ?
      {
        name: "Ex-Fac Date",
        field: "exFacDate",
        sortable: true,
        //width: '4%',

      } : false,
    getHideID.cubeEmp === true ?
      {
        name: "Cube Follower",
        field: "cubeEmployee",
        sortable: true,
        //width: '5%',

      } : false,
    getHideID.buyerEmp === true ?
      {
        name: "Buyer Follower",
        field: "buyerEmployee",
        sortable: true,
        //width: '5%',

      } : false,
    getHideID.orderStatus === true ?
      {
        name: "Order Status",
        field: "orderStatus",
        sortable: true,
        //width: '5%',

      } : false,
    getHideID.stsCreated === true ?
      {
        name: "Status Created By ",
        field: "statusCreatedBy",
        sortable: true,
        //width: '2%',

      } : false,

    getHideID.currentStatus === true ?
      {
        name: "Current Status Comment",
        field: "currentComments",
        sortable: true,
        // width: '5%',

      } : false,
    getHideID.cmtCreated === true ?
      {
        name: "Comment Created By",
        field: "commentsCreatedBy",
        sortable: true,
        // width: '2%',

      } : false,
    {
      name: "Action",
      field: "Action",
      className: "text-center fixed-column-header-Route",
      sortable: false,
      //widthsize: "7%"
    }
  ]


  let orderStatus = [
    // {
    //   value: 0,
    //   label: 'Select Order Status',
    //   selected: false,

    // },
    {
      value: 1,
      label: 'Yet to Start',
      selected: false,

    },
    {
      value: 2,
      label: 'In-Progress',
      selected: false,

    },
    {
      value: 3,
      label: 'Completed',
      selected: false,

    },
    {
      value: 4,
      label: 'Hold',
      selected: false,

    },
    {
      value: 5,
      label: 'Cancel',
      selected: false,

    }
  ]

  let IsProductionStatus = [
    {
      value: 1,
      label: 'Sampling',
      selected: true,

    },
    {
      value: 2,
      label: 'Production',
      selected: false,

    }
  ]

  const handleDateSelect = (event, isShow) => {

    let getStateExDate = [...stateExDate];
    let getStateDate = [...stateDate];
    getStateDate[0].isShow = isShow;
    getStateExDate[0].isShow = false;
    setStateExDate(getStateExDate);
    setStateDate(getStateDate);
  }
  const handleExDateSelect = (event, isShow) => {

    let getStateExDate = [...stateExDate];
    let getStateDate = [...stateDate];
    getStateExDate[0].isShow = isShow;
    getStateDate[0].isShow = false;
    setStateExDate(getStateExDate);
    setStateDate(getStateDate);
  }
  const clearDateSelect = () => {

    var defaultStartDate = new Date();
    const emptyStateDate = [
      {
        startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }];
    setStateDate(emptyStateDate);
    var defaultStartDate = new Date();
    const newemptyStateDate = [
      {
        startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }];
    var defaultExStartDate = new Date();
    const NewemptyStateExDate = ([
      {

        startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }
    ]);

    let StartDate = null;
    let EndDate = null;
    if (moment(newemptyStateDate[0].startDate).isValid()) {
      StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(newemptyStateDate[0].endDate).isValid()) {
      EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(NewemptyStateExDate[0].startDate).isValid()) {
      StartExDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(NewemptyStateExDate[0].endDate).isValid()) {
      EndExDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: Isproduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }
  const clearExDateSelect = () => {

    var defaultExStartDate = new Date();
    const emptyStateExDate = ([
      {
        startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }
    ]);
    setStateExDate(emptyStateExDate);
    var defaultStartDate = new Date();
    const newemptyStateDate = [
      {
        startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }];
    var defaultExStartDate = new Date();
    const NewemptyStateExDate = ([
      {

        startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }
    ]);

    let StartDate = null;
    let EndDate = null;
    if (moment(newemptyStateDate[0].startDate).isValid()) {
      StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(newemptyStateDate[0].endDate).isValid()) {
      EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(NewemptyStateExDate[0].startDate).isValid()) {
      StartExDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(NewemptyStateExDate[0].endDate).isValid()) {
      EndExDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: Isproduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }
  function DateExfaconchange(item) {
    stateExDate[0].startDate = item[0].startDate;
    stateExDate[0].endDate = item[0].endDate
    setStateExDate(item)

    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }
  function DateRangechange(item) {
    stateDate[0].startDate = item[0].startDate;
    stateDate[0].endDate = item[0].endDate
    setStateDate(item)

    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }

  const commentsData = useMemo(() => {


    let computedComments = CommentReport;
    let drpvalue = orderStatus;

    if (getBuyersearch) {
      computedComments = computedComments.filter(comment =>
        comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
      )
      setTotalItems(computedComments.length);
    }

    if (getStyleNamesearch) {
      computedComments = computedComments.filter(comment =>
        comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
      )
      setTotalItems(computedComments.length);
    }
    if (getStyleNosearch) {
      computedComments = computedComments.filter(comment =>
        comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
      )
      setTotalItems(computedComments.length);
    }
    if (getSeasonsearch) {
      computedComments = computedComments.filter(comment =>
        comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }


    if (getBrandsearch) {
      computedComments = computedComments.filter(comment =>
        comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }
    if (getQuartersearch) {
      computedComments = computedComments.filter(comment =>
        comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }
    if (getSuppliersearch) {
      computedComments = computedComments.filter(comment =>
        comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }

    if (getEndCustomersearch) {
      computedComments = computedComments.filter(comment =>
        comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }
    if (getCategorysearch) {
      computedComments = computedComments.filter(comment =>
        comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }
    if (getBuyerEmpsearch) {
      computedComments = computedComments.filter(comment =>
        comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }
    if (getCubeEmpsearch) {
      computedComments = computedComments.filter(comment =>
        comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
      );
      setTotalItems(computedComments.length);
    }

    if (getOrderStatussearch) {


      if (getOrderStatussearch.value === 1) {
        computedComments = computedComments.filter(comment =>
          comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
          // || parseInt(comment.orderStatus) === getOrderStatussearch.value
        );
      } else {
        computedComments = computedComments.filter(comment =>
          parseInt(comment.orderStatus) === getOrderStatussearch.value
        );
      }

      setTotalItems(drpvalue && drpvalue.length);
    }

    // setIsProductionStatussearch('1');

    //  if (getIsProductionStatussearch) {
    //   if (getIsProductionStatussearch.value === 1) {
    //     computedComments = computedComments.filter(comment =>
    //       comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
    //       // || parseInt(comment.orderStatus) === getIsProductionStatussearch.value
    //     );
    //   } else {
    //     computedComments = computedComments.filter(comment =>
    //       parseInt(comment.orderStatus) === getIsProductionStatussearch.value
    //     );
    //   }

    //   setTotalItems(drpvalue && drpvalue.length);
    // }

    if (getIdPosearch) {
      computedComments = computedComments.filter(comment =>
        comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
      );

      setTotalItems(computedComments.length);
    }
    let format = "DD/MM/YYYY";
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }

    if (stateDate[0].startDate && stateDate[0].endDate) {
      computedComments = computedComments.filter(comment =>
        new Date(moment(comment.poDate, format)) >= stateDate[0].startDate && new Date(moment(comment.poDate, format)) <= stateDate[0].endDate
      )
    }
    setTotalItems(computedComments.length);
    let Exformat = "DD/MM/YYYY";
    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = new Date(moment(stateExDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = new Date(moment(stateExDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }

    if (stateExDate[0].startDate && stateExDate[0].endDate) {
      computedComments = computedComments.filter(comment =>
        new Date(moment(comment.exFacDate, Exformat)) >= stateExDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateExDate[0].endDate
      )
    }
    setTotalItems(computedComments.length);

    //sorting table
   ;
    if (sorting.field) {
      computedComments.map((x) => {
        x.purchaseOrderSkuID = x.purchaseOrderSkuID.toString();
      })
      const reversed = sorting.order === "asc" ? 1 : -1;
      // if(sorting.field === 'quantity')
      // {
      //   //computedComments = [...computedComments].sort((a, b) => a - b);
      //   computedComments = computedComments.sort((a, b) => {
      //     const valueA = parseFloat(a[sorting.field]);
      //     const valueB = parseFloat(b[sorting.field]);

      //     // Assuming sorting.field contains strings representing numbers
      //     return reversed * (valueA - valueB);
      //   });

      // }
      // else
      // {
      //   computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

      // }

      computedComments = computedComments.sort((a, b) => {
        const valueA = a[sorting.field];
        const valueB = b[sorting.field];

        // Check if values are numeric
        const isNumericA = !isNaN(valueA);
        const isNumericB = !isNaN(valueB);

        if (isNumericA && isNumericB) {
          // If both values are numeric, compare as numbers
          return reversed * (parseFloat(valueA) - parseFloat(valueB));
        } else {
          // If one or both values are non-numeric, compare as strings
          return reversed * valueA.toString().localeCompare(valueB.toString());
        }
      });





    }


    // Current Page slice
    let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    if (records.length === 0 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);

    }

    // Current Page slice
    return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
  },
    [
      CommentReport,
      currentPage,
      // search,
      getBuyersearch,
      getSeasonsearch,
      getBrandsearch,
      getQuartersearch,
      getIdPosearch,
      getSuppliersearch,
      getEndCustomersearch,
      getCategorysearch,
      getStyleNamesearch,
      getStyleNosearch,
      getBuyerEmpsearch,
      getCubeEmpsearch,
      getOrderStatussearch,
      getIsProductionStatussearch,
      stateDate,
      stateExDate,
      sorting,
      ITEMS_PER_PAGE,
    ]);
  const resetStyle = () => {
    setBuyerSearch('');
    setQuarterSearch('');
    setSeasonSearch('');
    setBrandSearch('');
    setsupplierSearch('');
    setIdPoSearch('');
    setsupplierSearch('');
    setEndCustomerSearch('');
    setCategorySearch('');
    setStyleNameSearch('');
    setStyleNoSearch('');
    setBuyerEmpSearch('');
    setCubeEmpSearch('');
    setOrderStatusSearch('');
    setIsProductionStatussearch('');

    var defaultStartDate = new Date();
    const emptyStateDate = [
      {
        startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }];
    var defaultExStartDate = new Date();
    const emptyStateExDate = ([
      {
        startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
        endDate: new Date(),
        key: 'selection',
        isShow: false
      }
    ]);
    setStateDate(emptyStateDate);
    setStateExDate(emptyStateExDate);
    //let value = { label: "Sampling", selected: true, value: 1 }
    setIsProductionStatussearch(getIsProductionStatussearch);
    let StartDate = null;
    let EndDate = null;
    if (moment(emptyStateDate[0].startDate).isValid()) {
      StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(emptyStateDate[0].endDate).isValid()) {
      EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(emptyStateExDate[0].startDate).isValid()) {
      StartExDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(emptyStateExDate[0].endDate).isValid()) {
      EndExDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let value = getIsProductionStatussearch.label === "Production" ? 2 : 1;
    let params = { Operation: 1, IsProduction: value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));
  }
  const handleclick = () => {

    let getStateDate = [...stateDate];
    let getStateExDate = [...stateExDate];
    getStateExDate[0].isShow = false;
    getStateDate[0].isShow = false;
    setStateExDate(getStateExDate);
    setStateDate(getStateDate);

  }


  const handleChangeOrdStatus = (value) => {
    setOrderStatusSearch(value);
  }
  const handleChangeIsProductionStatus = (value) => {
    stateDate[0].startDate = stateDate[0].startDate;
    stateDate[0].endDate = new Date()
    setStateDate(stateDate)
    stateExDate[0].startDate = stateExDate[0].startDate;
    stateExDate[0].endDate = new Date()
    setStateExDate(stateExDate)
    if (value === null) {
      value = { label: "Sampling", selected: true, value: 1 }
      localStorage.setItem('value', (1));
    } else {
      localStorage.setItem('value', (value.value));
    }
    setIsProductionStatussearch(value);
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: value.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    dispatch(LoadCommentsReportDetails(params));

  }

  function handleChange(e, filterName) {


    let inputText = '';
    if (filterName === 'Buyer' && filterName !== '') {

      // let values = [...getBuyersearch];
      if (e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
      // values= inputText;
      setBuyerSearch(inputText);
    }
    else if (filterName === 'StyleNo') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setStyleNoSearch(inputText);
    }
    else if (filterName === 'StyleName') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setStyleNameSearch(inputText);
    }
    else if (filterName === 'Season') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setSeasonSearch(inputText);
    }
    else if (filterName === 'Brand') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setBrandSearch(inputText);
    }
    else if (filterName === 'ID/NO') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setIdPoSearch(inputText);
    }
    else if (filterName === 'Category') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setCategorySearch(inputText);
    }
    else if (filterName === 'Supplier') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setsupplierSearch(inputText);
    }
    else if (filterName === 'EndCustomer') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setEndCustomerSearch(inputText);
    }
    else if (filterName === 'OrderStatus') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setOrderStatusSearch(inputText);
    }
    else if (filterName === 'IsProductionStatus') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
        // let params = { Operation: 1 ,IsProduction: inputText}
        // dispatch(LoadCommentsReportDetails(params));
      }
      setIsProductionStatussearch(inputText);
    }
    else if (filterName === 'BuyerEmp') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setBuyerEmpSearch(inputText);
    }
    else if (filterName === 'CubeEmp') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setCubeEmpSearch(inputText);
    }

    else if (filterName === 'Quarter') {
      if (e.target.value.trim()) {
        inputText = e.target.value;
      }
      setQuarterSearch(inputText);
    }
  };

  //Check Box functionality start
  const CheckBoxHandler = (value) => {
    if (value === 1) {
      getHideID.Po
        ? setHideID({
          Po: false,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: true,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 2) {
      getHideID.season
        ? setHideID({
          Po: getHideID.Po,
          season: false,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: true,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 3) {
      getHideID.Quarter
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: false,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: true,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 4) {
      getHideID.brand
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: false,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: true,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 5) {
      getHideID.customer
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: false,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: true,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 6) {
      getHideID.supplier
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: false,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: true,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 7) {
      getHideID.idNo
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: false,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: true,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 8) {
      getHideID.styleName
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: false,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: true,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 9) {
      getHideID.styleNo
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: false,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: true,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 10) {
      getHideID.skuName
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: false,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: true,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 11) {
      getHideID.skuImg
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: false,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: true,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 12) {
      getHideID.size
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: false,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: true,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 13) {
      getHideID.orderQty
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: false,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: true,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 14) {
      getHideID.exFac
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: false,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: true,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 15) {
      getHideID.orderStatus
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: false,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: true,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 16) {
      getHideID.currentStatus
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: false,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: true,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 17) {
      getHideID.cubeEmp
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: false,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: true,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 18) {
      getHideID.buyerEmp
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: false,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: true,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 19) {
      getHideID.stsCreated
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: false,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: true,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 20) {
      getHideID.cmtCreated
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: false,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: true,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 21) {
      getHideID.skuID
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: false,
          category: getHideID.category,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: true,
          category: getHideID.category,
          factory: getHideID.factory
        });
    }
    if (value === 22) {
      getHideID.category
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: false,
          factory: getHideID.factory
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: true,
          factory: getHideID.factory
        });
    }
    if (value === 23) {
      getHideID.factory
        ? setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: false
        })
        : setHideID({
          Po: getHideID.Po,
          season: getHideID.season,
          Quarter: getHideID.Quarter,
          brand: getHideID.brand,
          customer: getHideID.customer,
          supplier: getHideID.supplier,
          idNo: getHideID.idNo,
          styleName: getHideID.styleName,
          styleNo: getHideID.styleNo,
          skuName: getHideID.skuName,
          skuImg: getHideID.skuImg,
          size: getHideID.size,
          orderQty: getHideID.orderQty,
          exFac: getHideID.exFac,
          orderStatus: getHideID.orderStatus,
          currentStatus: getHideID.currentStatus,
          cubeEmp: getHideID.cubeEmp,
          buyerEmp: getHideID.buyerEmp,
          stsCreated: getHideID.stsCreated,
          cmtCreated: getHideID.cmtCreated,
          skuID: getHideID.skuID,
          category: getHideID.category,
          factory: true
        });
    }
  };

  function ExfacClose(Feildname) {
    setEXSubmittedValue(false);
    if (selectedExfactory) {
      selectedExfactory.label = '';
      selectedExfactory.value = 0;
      setSelectedExfactory(selectedExfactory);
    }
    setEXSubmitted(false);

    let isChecked = false;
    if (Feildname === 'Exfactory') {
      isChecked = false;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: getExfacindex
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));
    setExfactoryModal(false);
  }

  const StatusClose = (Feildname) => {
    let isChecked = false;
    if (Feildname === 'ordStatus') {
      isChecked = false;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: getordindex
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));
    setShowmodal(false);
    setSubmitted(false);
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChanges = (value) => {
    setSelectedOptions(value);
    // setComment(Values)
  };
  function handleEXFactoryChanges(value) {
    setSelectedExfactory(value)
    setEXSubmitted(false);
    setEXSubmittedValue(false);
  }
  async function GetExfactory(supplierID, purchaseOrderSkuID, index, Feildname) {
    showLoader();
    let Exfaclist = [];
    setExfactoryModal(true);
    setSupplierID(supplierID);
    setPurchaseId(purchaseOrderSkuID);
    let isChecked = false;
    let indexes = CommentReport.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
    setExfacindex(indexes);
    if (Feildname === 'Exfactory') {
      isChecked = true;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: indexes
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));
    await ReportService.GetCommentsReportExFactory(supplierID, 1, 0).then((res) => {

      if (res.data) {
        res.data.exFacCommon.map((x, index) => {
          Exfaclist.push({
            value: x.value,
            label: x.label
          })
        })
        setExFactoryList(Exfaclist);
      }
      hideLoader();
    })
    await ReportService.GetCommentsReportExFactory(supplierID, 2, purchaseOrderSkuID).then((res) => {

      if (res.data) {
        setExfacList(res.data.exFactoryItemList);
      }
      hideLoader();
    })
  }

  const GetStatus = (PurchaseOrderSkuID, index, Feildname) => {
    showLoader();
    setPurchaseId('');
    setOrdindex(0);
    setShowmodal(true);
    setPurchaseId(PurchaseOrderSkuID);
    let isChecked = false;
    let indexes = CommentReport.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
    setOrdindex(indexes)
    let ordStatus = { ...selectedOption }

    if (Feildname === 'OrderStatus') {
      isChecked = true;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: indexes
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));
    ReportService.GetPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 2).then((response) => {
      if (response.data) {
        setorderStatus(response.data.reportStatus)
        let LabelColor = '';
        ordStatus.label = <><span style={{ color: "green" }}><b>-Select Order Status-</b></span></>
        ordStatus.value = 0;
        //ordStatus.label = '-Select Order Status-';
        setSelectedOptions(ordStatus)
      }
      else {
        setSelectedOptions(ordStatus)
      }
      hideLoader();
    }).catch(() => { hideLoader(); });

  }
  const GetComments = async (supplierID, PurchaseOrderSkuID, index, Feildname) => {
    setSubmitted(false);
    showLoader();
    let Exfaclist = [];
    setindex(0);
    setPurchaseId(PurchaseOrderSkuID);
    setSupplierID(supplierID);
    setCommentpopup(true)
    let isChecked = false;
    let indexes = CommentReport.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
    setindex(indexes);
    if (Feildname === 'Comments') {
      isChecked = true;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: indexes
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));

    await ReportService.GetPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 1).then(async (response) => {

      if (await response.data) {
        setreportcomments(await response.data.reportCommentDetails)
        setFiles(await response.data.reportfileupload)
      }
      hideLoader();
    }).catch(() => { hideLoader(); });
    await ReportService.GetCommentsReportExFactory(supplierID, 1, 0).then((res) => {

      if (res.data) {
        res.data.exFacCommon.map((x, index) => {
          Exfaclist.push({
            value: x.value,
            label: x.label
          })
        })
        setCmtExFactoryList(Exfaclist);
      }
      hideLoader();
    })

  }

  const GetSizeandQty = (PurchaseOrderSkuID, orderQty) => {
    setskuid('');
    ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {

      if (response.data) {
        setsizeqtylist(response.data)
      }

    }).catch(() => { });
    setsizeqtypopup(true);
    setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
  }


  const Drag = (props) => {
    return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
  }
  const handleFullClose = (Feildname, comment) => {
    if (selectedCommentExfactory) {
      selectedCommentExfactory.value = 0;
      selectedCommentExfactory.label = '';
      setSelectedCommentExfactory(selectedCommentExfactory);
    }
    let isChecked = false;
    if (Feildname === 'reportcmt') {
      isChecked = false;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: getindex
    };
    if (comment === undefined || comment === '') {
      setSubmitted(false);
    }
    if (getFiles.reportManualFileId === 0) {
      setFiles([]);
    }
    dispatch(UpdateCommentsreportViewCheckBox(params));
    setCommentpopup(false);
    setreportcomments([]);
    setEcomment('');


  }
  const onFocus = (e, field) => {
    var val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }
  // const EditComments = (CmdIndex, Value, SubmitFor) => {
  //   Values.EnterComment = Value;
  //   setEditedCommentIndex(CmdIndex);
  //   setComment(Values);
  // }
  // const RemoveComments = (subChildIndex2) => {
  //   Values.reportComments.splice(subChildIndex2, 1);
  // }
  const handleStatusChanges = (e) => {

    setselectstatus(e)
  }
  function handleCmtEXFactoryChanges(value) {

    setSelectedCommentExfactory(value)
  }
  const handleChangeComment = (e) => {
    setSubmitted(false);
    let inputtext = "";
    if (e.target.value.trim() && e.target.value.trim() !== '') {
      inputtext = e.target.value;
      setSubmitted(true);
    }
    else {
      //setSubmitted(true);
    }
    setEcomment(inputtext);

  }
  const ViewFile = (event, filename) => { // event.preventDefault();

    // let arr;
    // arr = filename.split(',')
    // for (let sku of arr) {
    //
    // }
    setIsOpenimg(true);
    setImagename(filename);
  }
  const filevals = [...getFiles]
  const RemoveImages = async (index, files, ReportManualFileId) => {

    if (ReportManualFileId !== 0) {
      getFiles[index].IsDeleted = 1;
    } else {
      filevals.splice(index, 1);
    }
    setFiles(filevals);
  }

  const AppendFiles = async e => {

    const file = e.target.files[0];
    if (file !== null && file !== '' && file !== undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!validImageTypes.includes(fileType)) {
        Nodify('Warning!', 'warning', 'Invalid file format selected');
        $('#FileUpload').val("");
      }
      else {
        const formData = new FormData();
        formData.append("FormFile", file);
        formData.append("FileName", file.name);
        formData.append("Page", "ReportStatus");
        try {
          const res = await axios.post(window.$APIBaseURL + "api/file", formData);
          let Index = CommentReport.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
          filevals.push({
            reportManualFileId: 0,
            fileName: res.data + "_" + file.name,
            filePath: "Images/Report/" + file.name,
            fileType: file.type,
            reportIndexName: Index.toString(),
            reportFileIndexName: getFiles.length.toString(),
            IsDeleted: 0
          })
          setFiles(filevals);
          // let Split = getFiles[0].FileName.split("_");
          // getFiles[0].FileName = Split[1];
          $('#FileUpload').val("");
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }


  function pagecountval(val) {

    if (val) {
      CommentReport.map(x => {
        x.isSelected = false;
      })
      setIsAllSelected(false);

      dispatch(UpdateCommentsreportViewAllCheckBox(false));
    }
  }
  function paginationcalback(val) {

    if (val) {
      CommentReport.map(x => {
        x.isSelected = false;
      })
      setIsAllSelected(false);

      dispatch(UpdateCommentsreportViewAllCheckBox(false));
    }
    setCurrentPage(val)
  }

  async function delStatusCallback(value) {

    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    let ordStatus = { ...selectedOption }
    if (value) {
      await ReportService.GetPurchaseOrderSkuReportComments(getpurchaseId, 2).then((response) => {
        if (response) {
          if (response.data) {
            setorderStatus(response.data.reportStatus)
            ordStatus.value = 0;
            ordStatus.label = 'Select Order Status';
            setSelectedOptions(ordStatus)
          }
          else {
            setSelectedOptions(ordStatus)
          }
        } if (response.data.reportStatus.length === 0) {
          setShowmodal(false)
        }
      }).catch(() => { });
      dispatch(LoadCommentsReportDetails(params));
    } else {
      setOrdStatusID({ showPopupDelete: false, Params: [] });
    }
  }

  async function delExfaccallback(value) {
    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    if (value) {
      await ReportService.GetCommentsReportExFactory(getSupplierID, 2, getpurchaseId).then((res) => {

        if (res.data) {
          setExfacList(res.data.exFactoryItemList);
        }
        if (res.data.exFactoryItemList.length === 0) {
          setExfactoryModal(false);
        }
      })
      dispatch(LoadCommentsReportDetails(params));
    } else {
      setExFacID({ showPopupDelete: false, Params: [] });
    }
  }

  async function delCallback(value) {

    let StartDate = null;
    let EndDate = null;
    if (moment(stateDate[0].startDate).isValid()) {
      StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    }
    if (moment(stateDate[0].endDate).isValid()) {
      EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    }

    let StartExDate = null;
    let EndExDate = null;
    if (moment(stateExDate[0].startDate).isValid()) {
      StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(stateExDate[0].endDate).isValid()) {
      EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    let params = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
    if (value) {

      await ReportService.GetPurchaseOrderSkuReportComments(getpurchaseId, 1).then(async (response) => {
        if (response.data) {
          setreportcomments(response.data.reportCommentDetails)
          setFiles(response.data.reportfileupload)
        }
        if (response.data.reportCommentDetails.length === 0) {
          setCommentpopup(false)
        }
      }).catch(() => { });

      dispatch(LoadCommentsReportDetails(params));
    } else {
      setID({ showPopupDelete: false, Params: [] });
    }
  }

  function OpenMappopup(data, index) {
    var CurrentLatLong = { ...getCurrentLocation };
    CurrentLatLong.Latitude = data.latitude;
    CurrentLatLong.Longitude = data.longitude;
    setCurrentLocation(CurrentLatLong);
    setgetIsShowGeoLocModal(true)
  }
  function handleGeoLocClose() {
    setgetIsShowGeoLocModal(false)
  }

  function callback(value) {

  }


  function RemoveFactory(Commentitem) {

    let Param = {
      Operation: ReportSkuExfactory,
      Id: Commentitem.supplierInfoID,
    };
    setExFacID({ showPopupDelete: true, Params: Param });
  }

  function RemoveOrderStatus(Commentitem) {

    let Param = {
      Operation: ReportSkuOrderStatus,
      Id: Commentitem.statusId,
    };
    setOrdStatusID({ showPopupDelete: true, Params: Param });
  }

  function RemoveComments(Commentitem) {

    let Param = {
      Operation: ReportSkuComments,
      Id: Commentitem.commentsID,
    };
    setID({ showPopupDelete: true, Params: Param });
  }

  function handleSaveExfactory() {
    setEXSubmittedValue(false);
    var ExFactoryInformationData = [];
    let Isvalid = true;
    let len = 0;
    if (selectedExfactory === undefined || selectedExfactory.value === 0) {
      Isvalid = false;
      setEXSubmitted(true);
      setEXSubmittedValue(true);
      Nodify('Warning!', 'warning', "Please fill the Mandatory(*) fields");
    }
    if (Isvalid) {
      let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID);
      Selecteditems.map(item => {
        if (item && item !== 0) {
          len = len + 1;
          ExFactoryInformationData.push({
            CommentIndex: len,
            PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
            SupplierInfo: selectedExfactory.value,
            AddressTypeName: selectedExfactory.label
          })
        }
      })
      var Data = { CreatedBy: currentUser.employeeinformationID, userLat: '0', userLong: '0', ExFactoryInformation: ExFactoryInformationData };

      ReportService.InsertUpdatereportExfactory(Data).then((res) => {
        if (res.data.outputResult === "1") {
          let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID);
          Nodify('Success!', 'success', "Ex-Factory for " + Selecteditems[0].supplierName + " Added Successfully");
          setExfactoryModal(false);
          let params = {
            isChecked: false, index: getExfacindex
          };
          dispatch(UpdateCommentsreportViewCheckBox(params));
          setIsAllSelected(false);
          setSelectedExfactory("");
          dispatch(UpdateCommentsreportViewAllCheckBox(false));

          let ISproduc = getIsProductionStatussearch;
          let StartDate = null;
          let EndDate = null;
          if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
          }
          if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
          }

          let StartExDate = null;
          let EndExDate = null;
          if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
          }
          if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
          }
          let param = { Operation: 1, IsProduction: getIsProductionStatussearch.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
          dispatch(LoadCommentsReportDetails(param));

        }

        else if (res.data.outputResult === "-2") {
          Nodify('Warning!', 'warning', "Error Occured");
        }
      })
    }
  }

  const handleSaveStatus = () => {
    const ordStatus = { ...selectedOption }
    var StatusInformationData = [];
    let len = 0;
    let isvalid = true;
    if (ordStatus.value === 0) {
      Nodify('Warning!', 'warning', "please select the order status");
      setSubmitted(true)
      isvalid = false;
    }
    if (isvalid) {
      setSubmitted(false)
      let Selecteditems = commentsData.filter(x => x.isSelected === true)
      Selecteditems.map(item => {
        if (item && item !== 0) {
          len = len + 1;
          StatusInformationData.push({
            CommentIndex: len,
            PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
            Status: ordStatus.value
          })
        }
      })
      var Data = { CreatedBy: currentUser.employeeinformationID, StatusInformation: StatusInformationData };

      ReportService.InsertUpdatereportOrderStatus(Data).then((res) => {

        if (res.data.outputResult === "1") {

          Nodify('Success!', 'success', "Order Status saved successfully");
          // setSelectedOptions(ordStatus);
          setShowmodal(false);
          let params = {
            isChecked: false, index: getordindex
          };
          dispatch(UpdateCommentsreportViewCheckBox(params));
          setIsAllSelected(false);
          dispatch(UpdateCommentsreportViewAllCheckBox(false));
          let ISproduc = getIsProductionStatussearch;
          let StartDate = null;
          let EndDate = null;
          if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
          }
          if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
          }

          let StartExDate = null;
          let EndExDate = null;
          if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
          }
          if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
          }
          let param = { Operation: 1, IsProduction: ISproduc.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
          dispatch(LoadCommentsReportDetails(param));

        }

        else if (res.data.outputResult === "-2") {
          Nodify('Warning!', 'warning', "Error Occured");
        }
        //setComment(Values);
      })
    }
  }
  const handleSaveComment = (e, comment) => {

    if (comment === undefined || comment === '' || selectedCommentExfactory === undefined || selectedCommentExfactory.value === 0) {
      setSubmitted(true);
      Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
      return false;
    } else {
      // var Data = [];
      var CommentInformationData = [];
      var ManualFileInformationData = [];
      let len = 0;
      //const PurchaseOrderSkuIDstatus = parseInt(getpurchaseId);
      let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID)
      Selecteditems.map((item, index) => {
        if (item && item !== 0) {
          len = len + 1;
          CommentInformationData.push({
            CommentIndex: len,
            PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
            CommentDetail: Ecomment,
            IsStatus: selectstatus.value,
            SupplierInfo: selectedCommentExfactory.value,
            Supplierfactory: selectedCommentExfactory.label
          })
          getFiles.filter(x => x.reportManualFileId === 0).map((x, indexs) => {
            ManualFileInformationData.push({
              ReportManualFileId: x.reportManualFileId,
              PurchaseOrderSkuID: parseInt(item.purchaseOrderSkuID),
              FileType: x.fileType,
              FileName: x.fileName,
              FilePath: x.filePath,
              ReportIndexName: x.reportIndexName,
              ReportFileIndexName: x.reportFileIndexName,
              IsDeleted: x.IsDeleted
            })
          })
        }
      })

      var Data = { CreatedBy: currentUser.employeeinformationID, userLat: '0', userLong: '0', CommentInformation: CommentInformationData, ReportManualFileInformation: ManualFileInformationData };

      ReportService.InsertUpdatereportComment(Data).then((res) => {

        if (res.data.outputResult === "1") {
          let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID);
          Nodify('Success!', 'success', "Comment Added for " + Selecteditems[0].supplierName + " Successfully");
          setSubmitted(false);
          setCommentpopup(false);
          setEcomment('');
          let params = {
            isChecked: false, index: getindex
          };
          dispatch(UpdateCommentsreportViewCheckBox(params));
          setIsAllSelected(false);
          dispatch(UpdateCommentsreportViewAllCheckBox(false));
          //let param = { Operation: 1, IsProduction: 1 }
          let ISproduc = getIsProductionStatussearch;
          let StartDate = null;
          let EndDate = null;
          if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
          }
          if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
          }

          let StartExDate = null;
          let EndExDate = null;
          if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
          }
          if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
          }
          let param = { Operation: 1, IsProduction: ISproduc.value, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
          setSelectedCommentExfactory("");
          dispatch(LoadCommentsReportDetails(param));
        } else if (res.data.outputResult === "-2") {
          Nodify('Warning!', 'warning', "Error Occured");
        }
        //setComment(Values);

      })

    }

    //setEditedCommentIndex('');
  };
  // const columns = [{
  //   title: "PO Date",
  //   field: "poDate",

  // },
  // {
  //   title: "SKU ID",
  //   field: "purchaseOrderSkuID",

  // },
  // {
  //   title: "Season",
  //   field: "seasonName",

  // }, {
  //   title: "Quarter",
  //   field: "quarterName",

  // },
  // {
  //   title: "Brand",
  //   field: "brandName",

  // },
  // {
  //   title: "Customer",
  //   field: "customerName",
  // },
  // {
  //   title: "Supplier",
  //   field: "supplierName",

  // }, {
  //   title: "ID No",
  //   field: "idpono",

  // }, {
  //   title: "Style Name",
  //   field: "styleName",

  // },
  // {
  //   title: "Style No",
  //   field: "styleNo",

  // },

  // {
  //   title: "SKU Name",
  //   field: "skuName",

  // },
  // {
  //   title: "SKU Image",
  //   field: "skuImagePath",

  // },
  // {
  //   title: "Size",
  //   field: "size",

  // },
  // {
  //   title: "Order Qty",
  //   field: "quantity",

  // },
  // {
  //   title: "Ex-Fac Date",
  //   field: "exFacDate",

  // },
  // {
  //   title: "Cube Follower",
  //   field: "cubeEmployee",

  // },
  // {
  //   title: "Buyer Follower",
  //   field: "buyerEmployee",

  // },
  // {
  //   title: "Order Status",
  //   field: "orderStatus",

  // },
  // {
  //   title: "Status Createdby",
  //   field: "statusCreatedBy",

  // },
  // {
  //   title: "Current Status Comment",
  //   field: "currentComments",

  // },
  // {
  //   title: "Comment Createdby",
  //   field: "commentsCreatedBy",

  // }
  // ]
  const handleOnSelect = (event, FieldName, index, purchaseOrderSkuID) => {
    let isChecked = false;
    let indexes = CommentReport.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
    if (event.target.checked) {
      isChecked = true;
    } else {
      setIsAllSelected(false);
    }
    let params = {
      isChecked: isChecked, index: indexes
    };
    dispatch(UpdateCommentsreportViewCheckBox(params));
  }
  const handleAllOnSelect = (event, FieldName, page, total) => {

    let Pagecount = parseInt(page);
    if (event.target.checked) {
      for (let i = 0; i < Pagecount; i++) {
        commentsData.map(x => {
          x.isSelected = true;
        })
        setIsAllSelected(true);
      }
      dispatch(UpdateCommentsreportViewAllCheckBox(true));

    } else {
      for (let i = 0; i < Pagecount; i++) {
        commentsData.map(x => {
          x.isSelected = false;
        })
        setIsAllSelected(false);
      }
      dispatch(UpdateCommentsreportViewAllCheckBox(false));
    }
  }

  // let ExcelValues = []
  // const DownloadExcel = () => {
  //
  //   commentsData.map(comment => {
  //     ExcelValues.push({
  //       PoDate: comment.poDate,
  //       SKUID: comment.purchaseOrderSkuID,
  //       SeasonName: comment.seasonName,
  //       quarterName: comment.quarterName,
  //       BrandName: comment.brandName,
  //       Customer: comment.customerName,
  //       Supplier: comment.supplierName,
  //       IDPONo: comment.idpono,
  //       StyleName: comment.styleName,
  //       StyleNo: comment.styleNo,
  //       SkuName: comment.skuName,
  //       SkuImage: <img src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath} width='4%' height={'4%'} />,
  //       Size: comment.size,
  //       OrderQty: comment.quantity,
  //       ExFacDate: comment.exFacDate,
  //       CubeFollower: comment.cubeEmployee,
  //       BuyerFollower: comment.buyerEmployee,
  //       OrderStatus: comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
  //         comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
  //           'Yet to Start',
  //       StatusCreatedby: comment.statusCreatedBy,
  //       CurrentStatusComment: comment.currentComments,
  //       CommentCreatedby: comment.commentsCreatedBy
  //     })

  //   })

  //   const newdata = ExcelValues.map(comment => {
  //     delete comment.tableData
  //     return comment
  //   })
  //   const worksheet = XLSX.utils.json_to_sheet(newdata)
  //   const Workbook = XLSX.utils.book_new()
  //   // var imageId1 = worksheet.addImage({
  //   //   filename: ExcelValues[0].SkuImage,
  //   //   extension: 'jpeg',
  //   // });
  //   XLSX.utils.book_append_sheet(Workbook, worksheet, 'CommentReportList')
  //   //Buffer
  //   XLSX.write(Workbook, { bookType: 'xlsx', type: 'buffer' })
  //   //Binary strnig
  //   XLSX.write(Workbook, { bookType: 'xlsx', type: 'binary' })
  //   //download
  //   XLSX.writeFile(Workbook, 'CommentReportList.xlsx')
  // }
  return (
    <div className={(props.props !== undefined && props.props.location.pathname === "/Dashboard") ? '' : "page-body"}>
      <ReactNotification />
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div className="widget">
            <div className="widget-header">
              <span className="widget-caption">Comments Report List (91 Days)
                &nbsp;
                {/* <button className="btn btn-success float-right" title="Export to Excel"
                  //currentTableRef={tableRef.current}
                  columns={columns}
                  data={ExcelValues}
                  onClick={() => DownloadExcel()}

                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </button> */}



              </span> {commentsData.length !== 0 && (
                <ReactHTMLTableToExcel
                  id="test-table-s-button"
                  className="fa fa-file-excel-o fa-fa-file-excelcomt"
                  table={"table-to-xls"}
                  filename={"CommentreportList"}
                  sheet="CommentReport"
                  buttonText=" Export to Excel"
                />)}
            </div>
            <div className="widget-body">
              <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget">
                      <div className="widget-body no-padding">
                        <div className="widget-main ">
                          <div className="panel-group accordion" id="accordion">
                            <div className="panel panel-default">
                              <div className="panel-heading ">
                                <h4 className="panel-title">
                                  <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                    Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                  </a>
                                </h4>
                              </div>
                              <div id="collapseOne" className="panel-collapse collapse in">
                                <div className="panel-body border-red">
                                  <div className="row">
                                    <div className="col-md-12">

                                      <div className="col-md-2">
                                        <label>Buyer</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Buyer'}
                                            maxlength={"50"}
                                            placeholder={'Buyer'}
                                            onChange={e => handleChange(e, 'Buyer')}
                                            value={getBuyersearch}
                                          />

                                        </span>
                                      </div>


                                      <div className="col-md-2">
                                      <label>Season</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'Season'}
                                            maxlength={"50"}
                                            placeholder={'Season'}
                                            onChange={e => handleChange(e, 'Season')}
                                            value={getSeasonsearch}
                                          />

                                        </span>



                                      </div>
                                      <div className="col-md-2">
                                      <label>Brand</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'Brand'}
                                            maxlength={"50"}
                                            placeholder={'Brand'}
                                            onChange={e => handleChange(e, 'Brand')}
                                            value={getBrandsearch}
                                          />
                                        </span>

                                      </div>
                                      <div className="col-md-2">
                                      <label>Quarter</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Quarter'}
                                            maxlength={"50"}
                                            placeholder={'Quarter'}
                                            onChange={e => handleChange(e, 'Quarter')}
                                            value={getQuartersearch}
                                          />
                                        </span>
                                      </div>
                                      <div className="col-md-2">
                                      <label>Supplier</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'Supplier'}
                                            maxlength={"50"}
                                            placeholder={'Supplier'}
                                            onChange={e => handleChange(e, 'Supplier')}
                                            value={getSuppliersearch}
                                          />
                                        </span>


                                      </div>

                                      <div className="col-md-2">
                                      <label>PO/ID No.</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'PO/ID No'}
                                            maxlength={"50"}
                                            placeholder={'PO/ID No'}
                                            onChange={e => handleChange(e, 'ID/NO')}
                                            value={getIdPosearch}
                                          />

                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  &nbsp;
                                  <div className="row">
                                    <div className="col-md-12">

                                      <div className="col-md-2">
                                      <label>End Customer</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'End Customer'}
                                            maxlength={"50"}
                                            placeholder={'End Customer'}
                                            onChange={e => handleChange(e, 'EndCustomer')}
                                            value={getEndCustomersearch}
                                          />
                                        </span>

                                      </div>
                                      <div className="col-md-2">
                                      <label>Category</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Category'}
                                            maxlength={"50"}
                                            placeholder={'Category'}
                                            onChange={e => handleChange(e, 'Category')}
                                            value={getCategorysearch}
                                          />

                                        </span>
                                      </div>

                                      <div className="col-md-2">
                                      <label>Style Name</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Style Name'}
                                            maxlength={"50"}
                                            placeholder={'Style Name'}
                                            onChange={e => handleChange(e, 'StyleName')}
                                            value={getStyleNamesearch}
                                          />
                                        </span>

                                      </div>

                                      <div className="col-md-2">
                                      <label>Style No</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className=" StyleList form-control"
                                            title={'Style No'}
                                            maxlength={"50"}
                                            placeholder={'Style No'}
                                            onChange={e => handleChange(e, 'StyleNo')}
                                            value={getStyleNosearch}
                                          />

                                        </span>



                                      </div>
                                      <div className="col-md-2">
                                      <label>Buyer Follower</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Buyer Follower'}
                                            maxlength={"50"}
                                            placeholder={'Buyer Follower'}
                                            onChange={e => handleChange(e, 'BuyerEmp')}

                                            value={getBuyerEmpsearch}
                                          />
                                        </span>
                                        {/* <span className='input-icon icon-right'>
                                    <Reactselect className="basic-single" name="GenderID"
                                    maxlength={"50"}
                                    placeholder={"Buyer Emp"}
                                      onChange={e => handleChange(e, 'BuyerEmp')}
                                      value={getBuyerEmpsearch}
                                    ></Reactselect>
                                  </span> */}
                                      </div>
                                      <div className="col-md-2">
                                      <label>Cube Follower</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            type="text"
                                            className="StyleList form-control"
                                            title={'Cube Follower'}
                                            maxlength={"50"}
                                            placeholder={'Cube Follower'}
                                            onChange={e => handleChange(e, 'CubeEmp')}

                                            value={getCubeEmpsearch}
                                          />
                                        </span>

                                      </div>


                                    </div>
                                  </div>


                                  &nbsp;

                                  {/* <div className="row">
                                    <div className="col-md-12">



                                    </div>
                                  </div>
                                  &nbsp; */}
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="col-md-2">
                                        <label>PO Date</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                              ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                            type="text"
                                            className={'form-control styledatapicker'}
                                            placeholder="Select Date"

                                          />
                                          <i className="fa fa-times style" onClick={clearDateSelect} ></i>
                                        </span>


                                        <span className='input-icon icon-right mb-5'>
                                          <br />
                                          {stateDate[0].isShow &&
                                            <DateRangePicker
                                              //onChange={item => setStateDate([item.selection])}
                                              onChange={item => DateRangechange([item.selection])}
                                              showSelectionPreview={true}
                                              moveRangeOnFirstSelection={false}
                                              months={2}
                                              ranges={stateDate}
                                              showDateDisplay={false}
                                              direction="vertical"
                                              className={'StyleDateRangePicker'}

                                            />

                                          }
                                        </span>
                                      </div>
                                      <div className="col-md-2">
                                        <label>Ex-Fac Date</label>
                                        <span className="input-icon icon-right">
                                          <input
                                            value={(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                              ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}
                                            onClick={event => handleExDateSelect(event, !stateExDate[0].isShow)}
                                            //onBlur={event => handleDateBlur()}
                                            type="text"
                                            className={'form-control styledatapicker'}
                                            placeholder="Select Date"

                                          />
                                          <i className="fa fa-times style" onClick={clearExDateSelect} ></i>
                                        </span>


                                        <span className='input-icon icon-right mb-5'>
                                          <br />
                                          {stateExDate[0].isShow &&
                                            <DateRangePicker
                                              //onChange={item => setStateExDate([item.selection])}
                                              onChange={item => DateExfaconchange([item.selection])}
                                              showSelectionPreview={true}
                                              moveRangeOnFirstSelection={false}
                                              months={2}
                                              ranges={stateExDate}
                                              showDateDisplay={false}
                                              direction="vertical"
                                              className={'StyleDateRangePicker'}

                                            />

                                          }
                                        </span>
                                      </div>
                                      <div className="col-md-2">
                                        <label>Status</label>
                                        <span className='input-icon icon-right' onClick={handleclick}>
                                          <Reactselect className="basic-single reportstatus" name="GenderID"
                                            options={orderStatus}
                                            isClearable={true}
                                            onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}
                                            value={getOrderStatussearch}
                                          ></Reactselect>
                                        </span>
                                      </div>
                                      <div className="col-md-2">
                                        <label>Sampling / Production</label>
                                        <span className='input-icon icon-right' onClick={handleclick}>
                                          <Reactselect className="basic-single reportstatus" name="IsProductionStatusID"
                                            options={IsProductionStatus}
                                            isClearable={true}
                                            onChange={e => handleChangeIsProductionStatus(e, 'IsProductionStatus')}
                                            value={getIsProductionStatussearch}
                                          ></Reactselect>
                                        </span>
                                      </div>
                                      <div className="col-md-1">
                                        <div className="col-md-1 margin-top-30" style={{ marginLeft: "1px" }}>
                                          <span className="input-icon icon-right">
                                            <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                            >
                                              <i className="fa fa-close"> Reset</i>
                                            </button>
                                          </span>
                                        </div>


                                      </div>
                                      <div className="col-md-11">
                                      </div>

                                    </div>
                                  </div>
                                  &nbsp;
                                </div>
                              </div>
                            </div>

                            &nbsp;


                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>







              </div>
            </div>
          </div>

          <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
            <Modal.Header closeButton>
              <Modal.Title>Show and Hide Header</Modal.Title>
            </Modal.Header>
            {/* , overflowY: "auto" */}
            <Modal.Body style={{ maxHeight: "70vh" }}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="row">
                      <div className="col-lg-4">
                        <li className="listmodal disabled" onClick={() => CheckBoxHandler(1)}> <input className="hidecheckbox" type="checkbox" name="podate" checked={getHideID.Po} /> &nbsp;<label htmlFor="podate">PO Date</label> </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(21)}> <input className="hidecheckbox" type="checkbox" name="podate" checked={getHideID.skuID} /> &nbsp;<label htmlFor="skuid">SKU ID</label> </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(2)} >  <input className="hidecheckbox" type="checkbox" name="season" checked={getHideID.season} /> &nbsp;<label htmlFor="season">Season</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(3)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.Quarter} /> &nbsp;<label htmlFor="quarter">Quarter</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(4)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.brand} /> &nbsp;<label htmlFor="brand">Brand</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(5)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.customer} /> &nbsp;<label htmlFor="customer">End Customer</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(6)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.supplier} /> &nbsp;<label htmlFor="supplier">Supplier </label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(7)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.idNo} /> &nbsp;<label htmlFor="idNo">ID No</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal disabled" onClick={() => CheckBoxHandler(8)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.styleName} /> &nbsp;<label htmlFor="styleName">Style Name</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal disabled" onClick={() => CheckBoxHandler(9)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.styleNo} /> &nbsp;<label htmlFor="styleNo">Style No</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal disabled" onClick={() => CheckBoxHandler(10)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.skuName} /> &nbsp;<label htmlFor="skuName">SKU Name</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal disabled" onClick={() => CheckBoxHandler(11)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.skuImg} /> &nbsp;<label htmlFor="skuImg">SKU Image</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(12)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.size} /> &nbsp;<label htmlFor="size">Size</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(13)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.orderQty} /> &nbsp;<label htmlFor="orderQty">Order Qty</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(14)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.exFac} /> &nbsp;<label htmlFor="exFac">Ex-Fac Date</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(15)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.orderStatus} /> &nbsp;<label htmlFor="orderStatus">Order Status</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(16)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.currentStatus} /> &nbsp;<label htmlFor="currentStatus">Comments</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(17)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.cubeEmp} /> &nbsp;<label htmlFor="cubeEmp">Cube Follower</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(18)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.buyerEmp} /> &nbsp;<label htmlFor="buyerEmp">Buyer Follower</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(19)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.stsCreated} /> &nbsp;<label htmlFor="Createdby">Status Created By </label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(20)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.cmtCreated} /> &nbsp;<label htmlFor="Createdby">Comment Created By</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(22)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.category} /> &nbsp;<label htmlFor="Createdby">Category</label>  </li>
                      </div>
                      <div className="col-lg-4">
                        <li className="listmodal" onClick={() => CheckBoxHandler(23)}>  <input className="hidecheckbox" type="checkbox" name="quarter" checked={getHideID.factory} /> &nbsp;<label htmlFor="Createdby">Factory</label>  </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="row" style={{ marginTop: '25px' }}>
            <div className="col-lg-11">
              <div className="card">
                <label className="alert alert-info comment-report-note"><b>Note : </b>Factory for all those similar suppliers will be updated by using the multiselect check box option on top right of the grid. Single Factory alone will be updated by using the respective Check box.</label>
              </div>
            </div>

            <div className="col-lg-1 button-group" >
              <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
              <label style={{ marginTop: '7px', marginLeft: "34px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                <input type="checkbox" name="IsAllSelected"
                  onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                  value={isAllSelected ? true : false}
                  checked={isAllSelected ? true : false}
                  className="selectallcheckbox"
                />

                {/* style={{ margin: "1rem -7rem 1rem 12rem" }} */}
                {/* <span className="text">Select All</span> */}
              </label>
            </div>


            <div className="col-md-12">
              <div style={{ overflow: "auto" }}>
                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer"
                  // id="table-to-xls"
                  style={{ width: "200%" }}>
                  {/* <thead>
                    <tr>
                      {getHideID.Po ? <th style={{ width: '5%' }}> PO Date </th> : false}
                      {getHideID.skuID ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                      {getHideID.season ? <th style={{ width: '3%' }}> Season</th> : false}
                      {getHideID.Quarter ? <th style={{ width: '1%' }}> Quarter</th> : false}
                      {getHideID.brand ? <th style={{ width: '5%' }}> Brand</th> : false}
                      {getHideID.customer ? <th style={{ width: '5%' }}> Customer</th> : false}
                      {getHideID.supplier ? <th style={{ width: '5%' }}> Supplier </th> : false}
                      {getHideID.idNo ? <th style={{ width: '4%' }}> ID No</th> : false}
                      {getHideID.styleName ? <th style={{ width: '7%' }}> Style Name</th> : false}
                      {getHideID.styleNo ? <th style={{ width: '7%' }}> Style No</th> : false}
                      {getHideID.skuName ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                      {getHideID.skuImg ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                      {getHideID.size ? <th style={{ width: '5%' }}> Size</th> : false}
                      {getHideID.orderQty ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                      {getHideID.exFac ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                      {getHideID.cubeEmp ? <th style={{ width: '5%' }}> Cube Follower</th> : false}
                      {getHideID.buyerEmp ? <th style={{ width: '5%' }}> Buyer Follower</th> : false}
                      {getHideID.orderStatus ? <th style={{ width: '5%' }}> Order Status</th> : false}
                      {getHideID.stsCreated ? <th style={{ width: '2%' }}>  Status Createdby</th> : false}
                      {getHideID.currentStatus ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                      {getHideID.cmtCreated ? <th style={{ width: '2%' }}>  Comment Createdby</th> : false}
                      <th style={{ width: '4%' }} className="text-center fixed-column-header-Route">Action</th>
                    </tr>
                  </thead> */}
                  <TableHeader headers={tableHeader}
                    onSorting={
                      (field, order) => setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {
                      commentsData.map((comment, index) => (
                        <Fragment key={index}>
                        <tr key={comment.purchaseOrderSkuID}>
                        {getHideID.Po && <td style={{width:'25px'}} >{
                           index+1
                          }</td>}
                          {getHideID.Po && <td className="cut_textCommentsreport" title={comment.poDate}>{
                            comment.poDate
                          }</td>}
                          {getHideID.skuID && <td className="cut_textCommentsreport" title={comment.purchaseOrderSkuID}>{
                            comment.purchaseOrderSkuID
                          }</td>}
                          {getHideID.season && <td className="cut_textCommentsreport" title={comment.seasonName}>{comment.seasonName}</td>}
                          {getHideID.Quarter && <td className="cut_textCommentsreport" title={comment.quarterName}>{
                            comment.quarterName
                          }</td>}
                          {getHideID.brand && <td className="cut_textCommentsreport" title={comment.brandName}>{
                            comment.brandName
                          }</td>}
                          {getHideID.customer && <td className="cut_textCommentsreport" title={comment.customerName}>{comment.customerName}</td>}
                          {getHideID.supplier && <td className="cut_textCommentsreport" title={comment.supplierName}>{comment.supplierName}</td>}
                          {getHideID.factory && <td className="cut_textCommentsreport" title={comment.factory === '' || comment.factory === null ? "TBD" : comment.factory}
                            style={{ color: (comment.factory === '' || comment.factory === null) ? '#E74C3C' : '' }}>
                            {comment.factory === '' || comment.factory === null ? "TBD" : comment.factory}</td>}
                          {getHideID.idNo && <td className="cut_textCommentsreport" title={comment.idpono}>
                            {comment.idpono}
                          </td>}
                          {getHideID.category && <td className="cut_textCommentsreport" title={comment.categoryName}>{
                            comment.categoryName
                          }</td>}
                          {getHideID.styleName && <td className="cut_textCommentsreport" title={comment.styleName}>{comment.styleName}</td>}
                          {getHideID.styleNo && <td className="cut_textCommentsreport" title={comment.styleNo}>{comment.styleNo}</td>}
                          {getHideID.skuName && < td className="cut_textCommentsreport" title={comment.skuName}> {
                            comment.skuName
                          }</td>}
                          {getHideID.skuImg && <td className="text-center">{
                            < SimpleReactLightbox >
                              <SRLWrapper options={options}>
                                {
                                  < img className="width_manual"
                                    src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath}
                                    alt={comment.skuImage === "" ? "" : comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                  />

                                }

                              </SRLWrapper>
                            </SimpleReactLightbox>
                            // comment.skuImage
                            // comment.skuImagePath
                          }</td>}
                          {getHideID.size && <td className="cut_textCommentsreport" title={comment.size}>{
                            comment.size
                          }</td>}
                          {getHideID.orderQty &&
                            <td className="cut_textCommentsreport" title={comment.quantity}>{
                              comment.quantity
                            }
                              <span>
                                <span className="btn btn-success" title="View Sizes" style={{ float: "right", display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} onClick={() => GetSizeandQty(comment.purchaseOrderSkuID, comment.quantity)}
                                >
                                  <i className="fa fa-eye"></i>
                                </span>
                              </span>
                            </td>}
                          {getHideID.exFac && <td className="cut_textCommentsreport" title={comment.exFacDate}>{
                            comment.exFacDate
                          }</td>}
                          {getHideID.cubeEmp && <td className="cut_textCommentsreport" title={comment.cubeEmployee}>{
                            comment.cubeEmployee
                          }</td>}
                          {getHideID.buyerEmp && <td className="cut_textCommentsreport" title={comment.buyerEmployee}>{
                            comment.buyerEmployee
                          }</td>}

                          {getHideID.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                              'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                  'Yet to Start'}>
                            {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                              comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                'Yet to Start'
                            }
                          </td>}
                          {/* {getHideID.orderStatus && <td>
                            {comment.orderStatus === '' ? "-" : comment.orderStatus}
                          </td>} */}
                          {getHideID.stsCreated && <td className="cut_textCommentsreport" title={comment.statusCreatedBy}>{
                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                          }</td>}
                          {getHideID.currentStatus && <td className="cut_textCommentsreport" title={comment.currentComments}>{
                            comment.currentComments === "" ? "-" : comment.currentComments
                          }</td>}
                          {getHideID.cmtCreated && <td className="cut_textCommentsreport" title={comment.commentsCreatedBy}>{
                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                          }</td>}

                          {<td className="text-center fixed-column-header-CommentReport">
                            <span style={{ padding: '2px', width: '192px' }}>
                              <span className="btn" title="Comments" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, index, 'Comments')}
                                style={{
                                  background: comment.currentComments === '' ? '#427fed' : 'red',
                                  color: 'white',
                                  padding: '3px 6px',
                                  borderRadius: '1px',
                                  border: comment.currentComments === '' ? '#427fed' : 'red'
                                }}
                              >
                                <i className="fa fa-comment"></i>
                              </span>
                            </span>
                            &nbsp;
                            <span style={{ padding: '2px' }}>
                              <span className="btn" title="Order Status" onClick={() => GetStatus(comment.purchaseOrderSkuID, index, "OrderStatus")}
                                style={{
                                  background: comment.orderStatus === '' ? '#A47BE0' : 'red',
                                  color: 'white',
                                  padding: '3px 6px',
                                  borderRadius: '1px',
                                  border: comment.orderStatus === '' ? '#A47BE0' : 'red'
                                }}
                              >
                                <i className="fa fa-shopping-cart"></i>
                              </span>

                            </span>
                            &nbsp;
                            <span style={{ padding: '2px' }}>
                              <span title="Ex-Factory" onClick={() => GetExfactory(comment.supplierID, comment.purchaseOrderSkuID, index, 'Exfactory')}
                                style={{
                                  background: comment.factory === '' || comment.factory === null ? '#5BA76A' : 'red',
                                  color: 'white',
                                  padding: '3px 6px',
                                  borderRadius: '1px',
                                  border: comment.factory === '' || comment.factory === null ? '#5BA76A' : 'red',
                                  position: 'relative', top: '2px'
                                }}>
                                <i className="fa fa-random"></i>
                              </span>
                            </span>
                            &nbsp;
                            <label style={{ marginTop: '8px' }}>
                              <input type="checkbox" name="IsSelected"
                                onChange={event => (handleOnSelect(event, 'IsSelected', index, comment.purchaseOrderSkuID,))}
                                value={comment.isSelected ? true : false}
                                checked={comment.isSelected ? true : false}
                                className="clearOpacity textdesign" />
                              <span className="text"></span>
                            </label>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;

                          </td>}
                        </tr>
                        </Fragment>
                      ))

                    }
                    {
                      sizeqtypopup === true ?

                        <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                          <Modal.Header closeButton>
                            <Modal.Title>Size and Qty </Modal.Title>
                          </Modal.Header>
                          <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                            <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                            <div className="col-lg-6">

                            </div>
                            <div className="col-lg-2">
                              <h5>Total : {getskuid.qrty}</h5>
                            </div>

                          </div>
                          <Modal.Body style={{ overflow: "auto", height: "350px" }} align="center">
                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                              <thead>
                                <tr>
                                  <th style={{ width: "350px" }}>Size</th>
                                  <th style={{ width: "160px" }}>Qty</th>
                                </tr>

                              </thead>
                              <tbody>
                                {
                                  sizeqtylist.map((item, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            <span className="input-icon icon-right">
                                              {item.sizes}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="input-icon icon-right">
                                              {item.qtys === "" || item.qtys === null ? "-" : item.qtys}
                                            </span>
                                          </td>
                                        </tr>
                                      </>

                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <button className="btn btn-default btn-danger" onClick={() => setsizeqtypopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                          </Modal.Footer>

                        </Modal> : ''
                    }
                    {
                      totalItems === 0 ?
                        <tr>
                          <td colSpan="25" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                    }

                  </tbody>

                </table>


                <table data-role="table" data-mode="columntoggle" id="table-to-xls"
                  className="table table-striped table-bordered table-hover dataTable no-footer"
                  style={{ width: "200%", display: 'none' }}>
                  <thead>
                    {
                      <div style={{ display: 'inline-block' }}>
                        {
                          (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                            <>
                              <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                  moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                              < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                  moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label>
                            </>


                            :

                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                              <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                  moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                              :

                              (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                  ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                    moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                        }
                      </div>
                    }
                    <tr>
                      {getHideID.Po === true ? <th style={{ width: '5%' }}> PO Date </th> : false}
                      {getHideID.skuID === true ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                      {getHideID.season === true ? <th style={{ width: '3%' }}> Season</th> : false}
                      {getHideID.Quarter === true ? <th style={{ width: '1%' }}> Quarter</th> : false}
                      {getHideID.brand === true ? <th style={{ width: '5%' }}> Brand</th> : false}
                      {getHideID.customer === true ? <th style={{ width: '5%' }}>End Customer</th> : false}
                      {getHideID.supplier === true ? <th style={{ width: '5%' }}> Supplier </th> : false}
                      {getHideID.factory === true ? <th style={{ width: '5%' }}> Factory </th> : false}
                      {getHideID.idNo === true ? <th style={{ width: '4%' }}> ID No</th> : false}
                      {getHideID.category === true ? <th style={{ width: '7%' }}>Category</th> : false}
                      {getHideID.styleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                      {getHideID.styleNo === true ? <th style={{ width: '7%' }}> Style No</th> : false}
                      {getHideID.skuName === true ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                      {getHideID.skuImg === true ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                      {getHideID.size === true ? <th style={{ width: '5%' }}> Size</th> : false}
                      {getHideID.orderQty === true ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                      {getHideID.exFac === true ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                      {getHideID.cubeEmp === true ? <th style={{ width: '5%' }}> Cube Follower</th> : false}
                      {getHideID.buyerEmp === true ? <th style={{ width: '5%' }}> Buyer Follower</th> : false}
                      {getHideID.orderStatus === true ? <th style={{ width: '5%' }}> Order Status</th> : false}
                      {getHideID.stsCreated === true ? <th style={{ width: '2%' }}>  Status Created By</th> : false}
                      {getHideID.currentStatus === true ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                      {getHideID.cmtCreated === true ? <th style={{ width: '2%' }}>  Comment Created By</th> : false}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      commentsData.map(comment => (
                        <tr key={comment.purchaseOrderSkuID}>
                          {getHideID.Po && <td className="" title={comment.poDate}>{
                            comment.poDate
                          }</td>}
                          {getHideID.skuID && <td className="cut_textCommentsreport" title={comment.purchaseOrderSkuID}>{
                            comment.purchaseOrderSkuID
                          }</td>}
                          {getHideID.season && <td className="cut_textCommentsreport" title={comment.seasonName}>{comment.seasonName}</td>}
                          {getHideID.Quarter && <td className="cut_textCommentsreport" title={comment.quarterName}>{
                            comment.quarterName
                          }</td>}
                          {getHideID.brand && <td className="cut_textCommentsreport" title={comment.brandName}>{
                            comment.brandName
                          }</td>}
                          {getHideID.customer && <td className="cut_textCommentsreport" title={comment.customerName}>{comment.customerName}</td>}
                          {getHideID.supplier && <td className="cut_textCommentsreport" title={comment.supplierName}>{comment.supplierName} </td>}
                          {getHideID.factory && <td className="cut_textCommentsreport" style={{ color: (comment.factory === '' || comment.factory === null) ? '#E74C3C' : '' }}
                            title={comment.factory}>{comment.factory === '' || comment.factory === null ? "TBD" : comment.factory}</td>}
                          {getHideID.idNo && <td className="cut_textCommentsreport" title={comment.iDPONO}>
                            {comment.idpono}
                          </td>}
                          {getHideID.category && <td className="cut_textCommentsreport" title={comment.categoryName}>{
                            comment.categoryName
                          }</td>}
                          {getHideID.styleName && <td className="cut_textCommentsreport" title={comment.styleName}>{comment.styleName}</td>}
                          {getHideID.styleNo && <td className="cut_textCommentsreport" title={comment.styleNo}>{comment.styleNo}</td>}
                          {getHideID.skuName && < td className="cut_textCommentsreport" title={comment.skuName}> {
                            comment.skuName
                          }</td>}
                          {getHideID.skuImg && <td>{
                            <img src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath} width='4%' height={'4%'} />
                          }</td>}
                          {getHideID.size && <td className="cut_textCommentsreport" title={comment.size}>{
                            comment.size
                          }</td>}
                          {getHideID.orderQty &&
                            <td className="cut_textCommentsreport" title={comment.quantity}>{
                              comment.quantity
                            }</td>}
                          {getHideID.exFac && <td className="cut_textCommentsreport" title={comment.exFacDate}>{
                            comment.exFacDate
                          }</td>}
                          {getHideID.cubeEmp && <td className="cut_textCommentsreport" title={comment.cubeEmployee}>{
                            comment.cubeEmployee
                          }</td>}
                          {getHideID.buyerEmp && <td className="cut_textCommentsreport" title={comment.buyerEmployee}>{
                            comment.buyerEmployee
                          }</td>}

                          {getHideID.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                              'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                  'Yet to Start'}>
                            {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                              comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                'Yet to Start'
                            }
                          </td>}
                          {/* {getHideID.orderStatus && <td>
                            {comment.orderStatus === '' ? "-" : comment.orderStatus}
                          </td>} */}
                          {getHideID.stsCreated && <td className="cut_textCommentsreport" title={comment.statusCreatedBy}>{
                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                          }</td>}
                          {getHideID.currentStatus && <td className="text-center cut_textCommentsreport" title={comment.currentComments}>{
                            comment.currentComments === "" ? "-" : comment.currentComments
                          }</td>}
                          {getHideID.cmtCreated && <td className="cut_textCommentsreport" title={comment.commentsCreatedBy}>{
                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                          }</td>}
                        </tr>
                      ))

                    }
                    {
                      totalItems === 0 ?
                        <tr>
                          <td colSpan="23" className='norecordfound'><span>No Records Found</span></td>
                        </tr> : ''
                    }

                  </tbody>
                </table>

                {/* Ex-Factory */}
                <Modal show={getExfactoryModal === true} backdrop="static" onHide={() => ExfacClose('Exfactory')} size="md" >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Factory
                    </Modal.Title>

                  </Modal.Header>
                  <div className="row" style={{ marginBottom: "12px", marginLeft: "0px" }}>

                    <div className="col-lg-11" style={{ width: "94%" }}>

                      <label width='10px' className="ComtText"> Supplier Factory<span className="text-danger">*</span></label>
                      <Reactselect
                        options={getExFactoryList}
                        value={selectedExfactory}
                        onChange={handleEXFactoryChanges}
                        styles={EXSubmitted && selectedExfactory === undefined || selectedExfactory === null || EXSubmittedValue === true ? styles : ''}
                      />


                    </div>

                  </div>
                  <Modal.Body style={{ overflow: "auto", height: "650px" }}>


                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">

                      <thead>

                        <tr>
                          <th style={{ width: '10%' }}>Supplier Factory</th>
                          <th style={{ width: '9%' }}>Date</th>
                          <th style={{ width: '13%' }}>Created By</th>
                          <th style={{ width: '5%' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          ExfacList.map((Commentitem, subChildIndex2) => {
                            return (
                              Commentitem.status !== '' ?
                                <tr>
                                  <td>
                                    {Commentitem.addressTypeName}
                                  </td>
                                  <td>
                                    {moment(Commentitem.createdDate).format('DD/MM/YYYY')}
                                  </td>
                                  <td>
                                    {Commentitem.createdBy}
                                  </td>
                                  <td>
                                    <span style={{
                                      padding: '2px', position: 'relative', right: '29px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right"
                                    }}>
                                      <span>
                                        <span className="btn btn-success" title="User Location"
                                          onClick={() => OpenMappopup(Commentitem, subChildIndex2)}
                                        >
                                          <i className="fa fa-map-marker"></i>
                                        </span>
                                        &nbsp;
                                        <span className="btn btn-danger" title="Delete Factory"
                                          onClick={() => RemoveFactory(Commentitem)}
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                : ''
                            );
                          })
                        }
                        {
                          ExfacList.length === 0 ?
                            <tr>
                              <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                            </tr> : ''
                        }

                      </tbody>
                    </table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={(event) => handleSaveExfactory(event)}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
                {
                  <Modal show={getIsShowGeoLocModal} size="xl" backdrop="static" keyboard={false} onHide={() => handleGeoLocClose()}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        User Loctaion
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="googlemodelsize">
                      <div>
                        <UserLocation LatLng={getCurrentLocation} parentCallback={callback} />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>


                    </Modal.Footer>
                  </Modal>
                }
                {/* OrderStatus */}
                <Modal show={showmodal === true} backdrop="static" onHide={() => StatusClose('ordStatus')} size="md" >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Order Status
                    </Modal.Title>
                  </Modal.Header>
                  <div className="row" style={{ marginBottom: "5px", marginLeft: "0px" }}>


                    <div className="col-lg-11" style={{ width: "97%", marginTop: "5px" }}>

                      <Reactselect
                        options={orderStatus}
                        value={selectedOption}
                        onChange={handleChanges}
                        styles={Submitted && selectedOption.value === 0 ? styles : ''}
                      />

                    </div>
                  </div>
                  <Modal.Body style={{ overflow: "auto", height: "650px" }}>
                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                      <thead>
                        <tr>
                          <th>Order Status</th>
                          <th style={{ width: '16%' }}>Date</th>
                          <th style={{ width: '17%' }}>Created By</th>
                          <th style={{ width: '5%' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          getorderStatus.map((Commentitem, subChildIndex2) => {
                            return (
                              Commentitem.status !== '' ?
                                <tr>
                                  <td>
                                    {Commentitem.status}
                                  </td>
                                  <td>
                                    {moment(Commentitem.statusModifiedDate).format('DD/MM/YYYY')}
                                  </td>
                                  <td>
                                    {Commentitem.createdBy}
                                  </td>
                                  <td>
                                    <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                      <span>
                                        <span className="btn btn-danger" title="Delete Order Status"
                                          onClick={() => RemoveOrderStatus(Commentitem)}
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </span>
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                : ''
                            );
                          })
                        }
                        {
                          getorderStatus.length === 0 ?
                            <tr>
                              <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                            </tr> : ''
                        }
                      </tbody>
                    </table>
                    {/* //<label htmlFor="status">Status</label>
                    <Reactselect
                      options={orderStatus}
                      value={selectedOption}
                      onChange={handleChanges} */}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={(event) => handleSaveStatus(event)}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
                {
                  Commentpopup === true ?
                    <Modal show={Commentpopup === true} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt', Ecomment)}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Comments
                        </Modal.Title>
                      </Modal.Header>
                      <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                        <thead>
                          <tr>
                            <th>Comments<span className="text-danger">*</span></th>
                            <th>Supplier Factory<span className="text-danger">*</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td >
                              <textarea
                                rows="4"
                                placeholder="Enter comments here..."
                                autoFocus onFocus={onFocus} autoComplete="off"
                                maxLength="500" name="EnterComment"
                                id={"EnterComment"}
                                value={Ecomment}
                                onChange={event => handleChangeComment(event, "reportComment")}
                                style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                              </textarea>
                            </td>
                            <td colSpan='2'>
                              {/* <label width='40px' className="ComtText"> Supplier Factory<span className="text-danger">*</span></label> */}
                              <Reactselect
                                options={getCmtExFactoryList}
                                value={selectedCommentExfactory}
                                onChange={handleCmtEXFactoryChanges}
                                styles={Submitted && selectedCommentExfactory === undefined || selectedCommentExfactory === null ? styles : ''}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6}>
                              <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                <tbody>
                                  <tr>
                                    <div className="col-sm-12 col-lg-12">
                                      <div style={{ paddingLeft: 0 }}>
                                        <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                      </div>
                                    </div>
                                    < div className="col-sm-12 col-lg-12">
                                      <div className="row">
                                        <div className="col-sm-8 col-lg-8">
                                          <div className='form-group'>
                                            <tr>
                                              <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png" ></input></td>
                                            </tr>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                      <div className="row no-margin">
                                        {
                                          getFiles && getFiles.length !== 0 ?
                                            getFiles.filter(x => x.IsDeleted !== 1 && x.reportManualFileId === 0).map((files, fileindex) => (
                                              // manitem.Files.filter(x => x.IsDeleted === 0) &&
                                              <> {
                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                  <tr title={files.fileName}>
                                                    <td className="cut_textInTNA">
                                                      <span style={{ marginRight: '7px' }}>
                                                        {
                                                          files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                            files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                              <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                              files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                        }
                                                      </span>  {files.fileName.substr(33)}
                                                    </td>

                                                    <td className="button_style_uploda">
                                                      {
                                                        files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                          <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                          <i className="btn btn-info fa fa-eye" title="View Image"

                                                            onClick={
                                                              event => ViewFile(event, files.fileName)
                                                            }></i>
                                                      }

                                                      &nbsp;


                                                      <p title='Delete File' onClick={(e) => RemoveImages(fileindex, files, files.reportManualFileId)}
                                                        className="btn btn-danger btn-xs delete">
                                                        <i className="fa fa-trash-o"></i>

                                                      </p>

                                                    </td>
                                                  </tr>



                                                  {

                                                    <div> {
                                                      isOpenimg && (

                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/Report/' + getImagename}
                                                          alt="bg image"
                                                          onCloseRequest={
                                                            () => setIsOpenimg(false)
                                                          } />
                                                      )
                                                    } </div>
                                                  }
                                                </div>
                                              }
                                              </>
                                            ))
                                            : ''
                                        }
                                      </div>
                                    </div>
                                  </tr>
                                </tbody>
                              </table>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <Modal.Body style={{ overflow: "auto", height: "650px" }}>

                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Comments</th>
                              <th>Supplier Factory</th>
                              <th style={{ width: '16%' }}>Date</th>
                              <th style={{ width: '17%' }}>Created By</th>
                              <th style={{ width: '5%' }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              reportcomments.map((Commentitem, subChildIndex2) => {
                                return (
                                  Commentitem.comments !== '' ?
                                    <tr>
                                      <td>
                                        {

                                          (Commentitem.fileName && Commentitem.fileName !== '') && Commentitem.length !== 0 ?
                                            <div>
                                              <tr title={Commentitem.fileName}>
                                                <td >
                                                  {
                                                    Commentitem.fileName !== null && Commentitem.fileName !== '' ?
                                                      < SimpleReactLightbox >
                                                        <SRLWrapper >
                                                          {
                                                            getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).map((x, index) => {
                                                              return (
                                                                < img className="width_manual" title={x.fileName.substr(33)} style={{ display: index === 0 ? '' : 'none' }}
                                                                  src={x.fileName === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Report/' + x.fileName}
                                                                  alt={x.fileName.substr(33)} />
                                                              )
                                                            })
                                                          }
                                                        </SRLWrapper>
                                                      </SimpleReactLightbox>

                                                      : 'No file selected'


                                                  }

                                                </td>
                                                <span className={getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length === 2 ? "label label-success" :
                                                  getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length === 3 ? "label label-danger" :
                                                    getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length === 4 ? "label label-info" :
                                                      getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length === 5 ? "label label-warning" :
                                                        getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length === 6 ? "label label-primary" :
                                                          getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length > 6 ? "label label-default" : ''

                                                } style={{ display: 'inline-block' }}>{getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length > 1 ?
                                                  getFiles.filter(x => x.reportManualFileId !== 0 && x.commentsID === Commentitem.commentsID).length : ''}</span>
                                              </tr>
                                            </div>
                                            : 'No file selected'

                                        }
                                      </td>
                                      <td>
                                        {Commentitem.commentsDetail.length === 0 ? 'No Record Found' : Commentitem.commentsDetail}
                                      </td>
                                      <td>
                                        {Commentitem.factoryName === null ? 'TBD' : Commentitem.factoryName}
                                      </td>
                                      {/* <td>
                                        {Commentitem.isStatus === 2 ? "Major" : Commentitem.isStatus === 1 ? 'Critical' : "Minor"}
                                      </td> */}
                                      <td>
                                        {Commentitem.commentsDate.length === 0 ? 'No Record Found' : moment(Commentitem.commentsDate).format('DD/MM/YYYY')}
                                      </td>
                                      <td>
                                        {Commentitem.createdBy.length === 0 ? 'No Record Found' : Commentitem.createdBy}
                                      </td>
                                      <td>
                                        <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                          <span>
                                            <span className="btn btn-danger" title="Delete Comment"
                                              onClick={() => RemoveComments(Commentitem)}
                                            >
                                              <i className="fa fa-trash-o"></i>
                                            </span>
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    : ''
                                );
                              })

                            }
                            {
                              reportcomments.length === 0 ?
                                <tr>
                                  <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                </tr> : ''
                            }
                          </tbody>
                        </table>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={(event) => handleSaveComment(event, Ecomment)}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    : ''
                }
              </div>

            </div>


          </div>

        </div>
        {
          getExFacID.showPopupDelete ? <SweetAlertPopup data={
            getExFacID.Params
          }
            deleteCallback={delExfaccallback}
            showpopup={true}
            pageActionId={ReportSkuExfactory}
            Msg={"Ex-Factory deleted successfully"} /> : null
        }
        {
          getID.showPopupDelete ? <SweetAlertPopup data={
            getID.Params
          }
            deleteCallback={delCallback}
            showpopup={true}
            pageActionId={ReportSkuComments}
            Msg={"Comments deleted successfully"} /> : null
        }
        {
          getOrdStatusID.showPopupDelete ? <SweetAlertPopup data={
            getOrdStatusID.Params
          }
            deleteCallback={delStatusCallback}
            showpopup={true}
            pageActionId={ReportSkuOrderStatus}
            Msg={"Order Status deleted successfully"} /> : null
        }
        <CommentReportPageCount callbackcount={pagecountval} onPageCount={
          value => {
            setITEMS_PER_PAGE(value);
            setCurrentPage(1);
          }

        } />
        <CmtreportPaginationComponent total={totalItems}
          itemsPerPage={
            parseInt(ITEMS_PER_PAGE)
          }
          //callbackcount={cmtpagecountval}
          currentPage={currentPage}
          onPageChange={
            page => paginationcalback(page)
          } />
      </div>
      {loader}
    </div >
  )
}

export default CommentStyle